import { applyMiddleware, compose, createStore } from 'redux'
import rootReducer from './reducers/rootReducer'
import { thunk } from 'redux-thunk'
import { createMiddleware } from 'redux-api-middleware'
import { persistStore } from 'redux-persist-immutable'
import {
  apiSideEffectsMiddleware,
  errorHandlerMiddleware,
  eventTrackingMiddleware,
  signOutMiddleware,
  notificationMiddleware,
  viewInitializationMiddleware,
  electronEventMiddleware,
  reactNativeEventMiddleware,
} from './middlewares'
import { customApiMiddleware } from './common/src/middlewares'
import { LOCAL_STORAGE_PREFIX } from './common/src/environment'
import { onboardingMiddleware } from './middlewares/onboardingMiddleware'

let persistor

const persistorGetter = () => persistor

const middlewares = [
  thunk,
  createMiddleware(),
  customApiMiddleware,
  eventTrackingMiddleware,
  onboardingMiddleware,
  viewInitializationMiddleware,
  apiSideEffectsMiddleware,
  notificationMiddleware,
  errorHandlerMiddleware(persistorGetter),
  electronEventMiddleware,
  reactNativeEventMiddleware,
  signOutMiddleware(persistorGetter),
]

const persistConfig = {
  keyPrefix: LOCAL_STORAGE_PREFIX,
  whitelist: ['ui', 'users', 'filters', 'projects', 'onboarding', 'location'],
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(rootReducer, composeEnhancer(applyMiddleware(...middlewares)))

persistor = persistStore(store, persistConfig)
