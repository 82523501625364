import React, { memo, useCallback, useReducer } from 'react'
import { useSelector } from 'react-redux'
import { Label } from '../../components/text/Text'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import {
  FullScreenModalContainer,
  FullScreenModalSection,
  FullScreenModalSectionBody,
  FullScreenModalSectionHeader,
} from '../modal/FullScreenModalContainer'
import { Spinner } from '../../components/basic/Loading'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { getMatchParameterInteger } from '../../common/src/utils'
import { stateHelper } from '../../common/src/helpers'
import { UserCollectionCell } from '../../components/users/usersCollection/UserCollectionCell'
import { Downloads } from '../../components/projectTemplates/Downloads'
import { useInstantiateCustomTemplateAdvanced } from '../../hooks/projectTemplateHooks'
import { useDatePickerStrings } from '../../hooks/dateHooks'
import { useTranslation } from 'react-i18next'
import { LocalizedDatePicker } from '../../components/pickers/LocalizedDatePicker'
import { useCustomProjectTemplate } from '@/queries/projectTemplates'

const ACTION_TYPE = {
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  SET_LOADING: 'SET_LOADING',
  SET_STARTING_DATE: 'SET_STARTING_DATE',
}

const createInitialState = () => {
  return {
    loading: false,
    errorMessage: false,
    startingDate: new Date(),
  }
}

export const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case ACTION_TYPE.SET_ERROR_MESSAGE:
      state.errorMessage = payload
      break
    case ACTION_TYPE.SET_LOADING:
      state.loading = payload
      state.errorMessage = ''
      break
    case ACTION_TYPE.SET_STARTING_DATE:
      state.startingDate = payload
      break
    default:
      break
  }
  return { ...state }
}

const FieldLabel = ({ children }) => <Label className="mt-3">{children}</Label>

export const NewProjectFromCustomTemplateModal = memo(({ history, location, match, ...rest }) => {
  const [state, localDispatch] = useReducer(reducer, createInitialState())
  const { loading, errorMessage, startingDate } = state
  const instantiateCustomTemplate = useInstantiateCustomTemplateAdvanced()
  const datePickerStrings = useDatePickerStrings()()
  const { t } = useTranslation()

  const templateID = getMatchParameterInteger(match, 'template_id')
  const { data: template, isLoading: fetching } = useCustomProjectTemplate(templateID)
  const creatorUser = useSelector(state => stateHelper.getUserWithEmail(state, template?.creator_username))

  const onSelectStartingDate = useCallback(date => {
    localDispatch({ type: ACTION_TYPE.SET_STARTING_DATE, payload: date })
  }, [])

  const onCreate = useCallback(
    projectID => {
      const path = getRelativeURLKeepingQuerySearch.matrixForProjectId(projectID)
      history.push(path)
    },
    [history]
  )

  const create = useCallback(async () => {
    localDispatch({ type: ACTION_TYPE.SET_LOADING, payload: true })
    const response = await instantiateCustomTemplate({
      templateID,
      startingDate,
      notify: false,
      delayToOpen: 150,
      onCreate,
    })
    localDispatch({ type: ACTION_TYPE.SET_LOADING, payload: false })

    const { error } = response
    if (error) {
      localDispatch({
        type: ACTION_TYPE.SET_ERROR_MESSAGE,
        payload: t('project_template.created_unsuccessfully'),
      })
    }
  }, [instantiateCustomTemplate, templateID, startingDate, onCreate, t])

  return (
    <FullScreenModalContainer bodyClassName={`!max-w-md ${fetching ? 'justify-center' : ''}`} {...rest}>
      {fetching && <Spinner size="large" />}
      {!fetching && (
        <FullScreenModalSection>
          <FullScreenModalSectionHeader>
            <div>{template.name}</div>
          </FullScreenModalSectionHeader>
          <FullScreenModalSectionBody>
            {template.notes && (
              <>
                <FieldLabel>Notes</FieldLabel>
                <div>{template.notes}</div>
              </>
            )}
            {!!template.downloads && (
              <>
                <FieldLabel>{t('project_template.downloads')}</FieldLabel>
                <Downloads count={template.downloads} className="mt-2" />
              </>
            )}
            <FieldLabel>{t('project_template.creator')}</FieldLabel>
            <UserCollectionCell user={creatorUser} className="mt-2" />
            <FieldLabel>{t('project_template.starting_date')}</FieldLabel>
            <LocalizedDatePicker
              strings={datePickerStrings}
              placeholder={t('project_template.starting_date_placeholder')}
              value={startingDate}
              onSelectDate={onSelectStartingDate}
              className="mt-2"
            />
            <div className="mt-5 h-8">
              {loading ? (
                <Spinner size="medium" />
              ) : (
                <PrimaryButton onClick={create} className="w-full">
                  {t('project_template.instantiate_template')}
                </PrimaryButton>
              )}
            </div>
            {errorMessage && <div className="mt-2 text-pm-negative">{errorMessage}</div>}
          </FullScreenModalSectionBody>
        </FullScreenModalSection>
      )}
    </FullScreenModalContainer>
  )
})
