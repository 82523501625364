import React from 'react'
import { ProjectDetailView } from '../project/ProjectDetail'
import { ItemDetailView } from '../ItemDetail/ItemDetail'
import { GraphResourceDetails } from '../graphDetails/GraphResourceDetails'
import { RIGHT_PANEL } from '../../actions/uiActions'
import { useRightPanel } from '../../hooks/PMHooks'

const detailViewComponent = {
  [RIGHT_PANEL.GRAPH_RESOURCE]: GraphResourceDetails,
  [RIGHT_PANEL.ITEM]: ItemDetailView,
  [RIGHT_PANEL.PROJECT]: ProjectDetailView,
}

export const RightDetailView = ({ excludeProjectPanel = false, ...props }) => {
  const rightPanel = useRightPanel()
  const Component =
    !excludeProjectPanel || rightPanel !== RIGHT_PANEL.PROJECT ? detailViewComponent[rightPanel] : GraphResourceDetails
  return <Component {...props} />
}
