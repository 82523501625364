import { useCallback, useMemo } from 'react'
import { cn } from '@appfluence/classnames'
import { Virtuoso } from 'react-virtuoso'

export const VirtualizedList = ({
  rows,
  rowRenderer,
  noRowsRenderer,
  placeholder,
  className,
  innerListRef,
  endReached,
  increaseViewportBy,
  computeItemKey,
}) => {
  const noRowsRendererByDefault = useCallback(() => {
    return <div className="text-center text-pm-neutral-secondary">{placeholder}</div>
  }, [placeholder])

  const noRowsRendererIfNeeded = useMemo(() => {
    if (placeholder) {
      return noRowsRendererByDefault
    }
    return noRowsRenderer
  }, [noRowsRenderer, noRowsRendererByDefault, placeholder])

  return (
    <div className={cn('flex-1 overflow-hidden', className)}>
      {!!rows.length && (
        <Virtuoso
          itemContent={rowRenderer}
          data={rows}
          scrollerRef={innerListRef}
          endReached={endReached}
          {...(increaseViewportBy && { increaseViewportBy })}
          {...(computeItemKey && { computeItemKey })}
        />
      )}
      {!rows.length && noRowsRendererIfNeeded()}
    </div>
  )
}
