import _ from 'lodash'
import { DynamicPlatformConfig } from '../config/dynamicPlatformConfig'
import { SessionTokenManager } from '../SessionTokenManager'
import { USE_COOKIE } from '../environment'
import { stateHelper } from './index'
import { objToQueryParamsString } from './functionalUtils'
import { VERSION, NAME } from '../environment'

/**
 * Append PM Params to an URL. Util for redux-api-middleware
 */
export const appendQueryParamsToURL =
  (url, params = {}) =>
  state => {
    params.pm_version = DynamicPlatformConfig.getVersion() || VERSION
    params.pm_platform = DynamicPlatformConfig.getName() || NAME
    const token = SessionTokenManager.get()
    if (token) {
      params.oauth_consumer_key = token
    } else if (!USE_COOKIE) {
      params.oauth_consumer_key = stateHelper.getAccessToken(state) || ''
    }
    const paramsString = objToQueryParamsString(params)
    return url + '?' + paramsString
  }
/**
 * Common used method, that append headers  like access token to a given baseHeaders. Util for redux-api-middleware
 */
export const extendPMHeaders =
  (headers = {}) =>
  _state => {
    // note: we do not use Bearer authorization since it's not working properly on serverside with files endpoint
    // so this code is moved to appendQueryParamsToURL
    // let's keep this fn since it's useful to intercept headers, and maybe we need it in the future again
    return headers
  }
//TODO document REACT_APP_USE_COOKIE and wrap it, to avoid typos. (Add tests too)
export const getCredentialsConfig = (omit = false) => {
  const token = SessionTokenManager.get()
  if (token) {
    return 'omit'
  }
  return USE_COOKIE && !omit ? 'same-origin' : 'omit'
}
export const withDefaultParams =
  (fn, defParams) =>
  (params, ...args) => {
    // Use pickBy for removing keys with value null or undefined
    params = _.pickBy({ ...defParams, ...params })
    return fn(params, ...args)
  }
