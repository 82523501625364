import styled from 'styled-components'

export const BaseFlexContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`

export const Row = styled(BaseFlexContainer)`
  flex-direction: row;
`

export const Column = styled(BaseFlexContainer)`
  flex-direction: column;
`

export const FlexContainer = styled(BaseFlexContainer)`
  justify-content: ${p => p['justify-content'] ?? 'center'};
  align-items: ${p => p['align-items'] ?? 'center'};
  flex-direction: ${p => (p.row ? 'row' : 'column')};
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const BaseFlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`
