import { setBadgeCount } from '@/utils/ExpoCommunication'
import { useAttentionNeededInSession } from '../common/src/hooks/itemHooks'
import { useEffect } from 'react'

declare global {
  interface Navigator {
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/setAppBadge
    setAppBadge?: (count: number) => Promise<void>
  }
}

export const useSyncExternalBadgeCount = () => {
  const alertsCount = useAttentionNeededInSession().size

  useEffect(() => {
    try {
      // wrapped as it might throw
      // Failed to execute 'setAppBadge' on 'Navigator': Illegal invocation
      navigator.setAppBadge?.(alertsCount)?.catch(_ => {})
    } catch (_err) {}
    setBadgeCount(alertsCount)
  }, [alertsCount])
}
