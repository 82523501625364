import _ from 'lodash'
import { useContext, useEffect, useRef } from 'react'
import { useDispatch, useStore } from 'react-redux'
import { matchPath, useHistory } from 'react-router'
import { useToastController } from '@fluentui/react-components'
import { linkSubject, SOURCES } from '../../reactions/linkSubject'
import { getItemLink, getProjectLink, PM_API_RESOURCE_TYPE } from '../../common/src/constants'
import { selectNotification } from '../../actions/uiActions'
import {
  getPathnameKeepingQuerySearch,
  getRelativePathToMatrixBasedOnMode,
  getRelativeURLKeepingQuerySearch,
  RELATIVE_URL_BY_ROUTE_ID,
} from '../../helpers/routeHelper'
import { simulateLinkClick } from '../../common/src/utils'
import { itemHelper, stateHelper, teamsHelper } from '../../common/src/helpers'
import { manageItem, manageProject } from '../../helpers/manageObjectHelper'
import { OBJECT_ERROR_NAME, OBJECT_ERROR_STATUS } from '../../common/src/errors/errors'
import { ViewSizeContext } from '../../contexts'
import { ROUTE_ID } from '../../routes/routeIdList'
import { scrollToItem } from '../../reactions/'
import { getPreferredMatrixViewMode } from '../../selectors/uiSelectors'
import { useDeepLinkExecutor } from '../../utils/teams'
import { getCurrentSection } from '../../common/src/helpers/URLHelper'
import { useTranslation } from 'react-i18next'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent, EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { getNotification as getNotificationFromAPI } from '../../common/src/actions/notificationsAPI'
import notificationSound from '../../resources/sound/notification.ogg'
import { FluentToast } from '../toast/FluentToast'
import { useTeamsConfig } from '../../queries/config'
import { useRouteId } from '@/hooks/useRouteId'

const scrollIfPossible = item => {
  setTimeout(() => {
    scrollToItem.next(item)
  }, 500)
}

const createAudioPlayer = src => {
  const notificationAudio = new Audio(src)
  notificationAudio.loop = false
  notificationAudio.volume = 0.5
  return async () => {
    try {
      await notificationAudio.play()
    } catch (err) {
      console.error(err)
    }
  }
}

const playNotificationSound = createAudioPlayer(notificationSound)

const useWebPush = history => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (navigator?.serviceWorker) {
      navigator.serviceWorker.onmessage = event => {
        if (!event?.data?.type) {
          return
        }
        switch (event.data.type) {
          case 'notification_received':
            {
              playNotificationSound()
            }
            break
          case 'notification_clicked':
            {
              dispatch(
                dispatchEvent(AMPLITUDE_ACTION_TYPES.OPEN_WEB_PUSH, {
                  status: EVENT_EXTRA.WEB_PUSH.STATUS.ALREADY_OPENED,
                })
              )
              const path = event.data.payload.relativePath
              history.push(path + window.location.search)
            }
            break
          case 'new_inapp_notification':
            {
              const notificationUrl = event.data.payload.url
              const notificationId = _.nth(notificationUrl.split('/'), -2)
              dispatch(getNotificationFromAPI(notificationId))
              dispatch(selectNotification(notificationId))
            }
            break
        }
      }
    }
  }, [dispatch, history])
}

export const LinkManager = () => {
  const store = useStore()
  const teamsConfig = useTeamsConfig()
  const executeDeepLink = useDeepLinkExecutor()
  const history = useHistory()
  const routeId = useRouteId()
  const { narrowWidth } = useContext(ViewSizeContext)
  const subscriptionRef = useRef(null)
  const { t } = useTranslation()
  const { dispatchToast } = useToastController()

  useWebPush(history)

  //subscribe once
  useEffect(() => {
    const subscription = linkSubject.subscribe((...args) => {
      subscriptionRef.current(...args)
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [subscriptionRef])

  const navigateToSingleProject = id => {
    const path = getRelativeURLKeepingQuerySearch.singleProjectViewForID(id)
    history.push(path)
  }

  const navigateToSingleItem = id => {
    const path = getRelativeURLKeepingQuerySearch.singleItemViewForID(id)
    history.push(path)
  }

  const navigateToMatrixPath = (projectID, itemID) => {
    const mode = getPreferredMatrixViewMode(store.getState())
    return getRelativePathToMatrixBasedOnMode(mode)(projectID, itemID)
  }

  const navigateToMatrix = (projectID, itemID, action = 'push') => {
    const path = navigateToMatrixPath(projectID, itemID)
    if (action === 'replace') {
      history.replace(path)
    } else {
      history.push(path)
    }
  }

  const navigateToInbox = (id, action = 'push') => {
    const path = getRelativeURLKeepingQuerySearch.inboxWithItem(id)
    if (action === 'replace') {
      history.replace(path)
    } else {
      history.push(path)
    }
  }

  const triggerDefaultItemNavigation = ({ item, isInbox, id }, action = 'push') => {
    if (isInbox) {
      navigateToInbox(id, action)
    } else {
      navigateToMatrix(itemHelper.getProjectIdd(item), id, action)
    }
  }

  const customManageProject = ({ store, id, t }) => {
    return manageProject({ store, id, t }).then(manageProjectErrorByDefault)
  }

  const manageProjectErrorByDefault = props => {
    if (props.isError) {
      let message = t(`invalid_object.${PM_API_RESOURCE_TYPE.PROJECT}.not_found_title`)
      const error = props.payload.error
      const name = error.name
      if (name === OBJECT_ERROR_NAME) {
        // ObjectError
        const status = error.status
        if (status === OBJECT_ERROR_STATUS.DELETED) {
          message = error.statusText
        }
      } else if (error.status) {
        // ApiError
        const status = error.status
        switch (status) {
          case 401:
          case 403:
            message = t(`invalid_object.${PM_API_RESOURCE_TYPE.PROJECT}.access_denied_subtitle`)
            break
          case 404:
            message = t(`invalid_object.${PM_API_RESOURCE_TYPE.PROJECT}.not_found_title`)
            break
          case 410:
            message = t(`invalid_object.${PM_API_RESOURCE_TYPE.PROJECT}.deleted_title`)
            break
          default:
            break
        }
      }

      dispatchToast(<FluentToast>{message}</FluentToast>, {
        intent: 'warning',
      })
    }
    return props
  }

  const customManageItem = ({ store, id, t }) => {
    return manageItem({ store, id, t }).then(manageItemErrorByDefault)
  }

  const manageItemErrorByDefault = props => {
    if (props.isError) {
      let message = t(`invalid_object.${PM_API_RESOURCE_TYPE.ITEM}.not_found_title`)
      const error = props.payload.error
      const name = error.name
      if (name === OBJECT_ERROR_NAME) {
        // ObjectError
        const status = error.status
        if (status === OBJECT_ERROR_STATUS.DELETED) {
          message = error.statusText
        }
      } else if (error.status) {
        // ApiError
        const status = error.status
        switch (status) {
          case 401:
          case 403:
            message = t(`invalid_object.${PM_API_RESOURCE_TYPE.ITEM}.access_denied_subtitle`)
            break
          case 404:
            message = t(`invalid_object.${PM_API_RESOURCE_TYPE.ITEM}.not_found_title`)
            break
          case 410:
            message = t(`invalid_object.${PM_API_RESOURCE_TYPE.ITEM}.deleted_title`)
            break
          default:
            break
        }
      }

      dispatchToast(<FluentToast>{message}</FluentToast>, {
        intent: 'warning',
      })
    }
    return props
  }

  //so that it's aware of js context data
  // eslint-disable-next-line react-compiler/react-compiler
  subscriptionRef.current = linkObject => {
    const { urlData, source, internalRelativePath } = linkObject
    if (internalRelativePath) {
      const itemRegex = /^\/item\//
      let target = internalRelativePath
      if (itemRegex.test(internalRelativePath)) {
        target = `/redirect${target}`
      }
      const to = getPathnameKeepingQuerySearch(target)
      history.push(to)
      return
    }
    const { id, type } = urlData
    if (!id) {
      return
    }
    const state = store.getState()

    if (source === SOURCES.PROJECT_CREATION) {
      customManageProject({ store, id, t }).then(({ isError, payload }) => {
        if (isError) return
        const { id } = payload
        navigateToMatrix(id)
      })
      return false
    } else if (source === SOURCES.BROWSER_NOTIFICATION || source === SOURCES.REDIRECT_VIEW) {
      const navAction = source === SOURCES.REDIRECT_VIEW ? 'replace' : 'push'
      if (type === PM_API_RESOURCE_TYPE.ITEM) {
        customManageItem({ store, id, t }).then(({ isError, payload }) => {
          if (isError) {
            const path = getRelativeURLKeepingQuerySearch.homeSection('alerts')
            history.replace(path)
            return
          }
          triggerDefaultItemNavigation(payload, navAction)
        })
      } else if (type === PM_API_RESOURCE_TYPE.PROJECT) {
        customManageProject({ store, id, t }).then(({ isError, payload }) => {
          if (isError) {
            const path = getRelativeURLKeepingQuerySearch.homeSection('alerts')
            history.replace(path)
            return
          }
          const { id } = payload
          navigateToMatrix(id, undefined, navAction)
        })
      }
      return false
    } else if (source === SOURCES.RESTORE_ITEMS) {
      navigateToMatrix(id)
      return false
    } else if (source === SOURCES.WINDOW_OPEN) {
      navigateToSingleItem(id)
      return false
    }

    const handleOneProject = ({ readOnly }) => {
      const relativeURL = RELATIVE_URL_BY_ROUTE_ID[routeId]
      // Get previous project id from current pathname:
      const previousProjectID = +matchPath(history.location.pathname, {
        path: relativeURL(':pid').pathname,
      })?.params.pid
      if (type === PM_API_RESOURCE_TYPE.ITEM) {
        customManageItem({ store, id, t }).then(({ isError, payload }) => {
          if (isError) return
          const { id, item } = payload
          const projectID = itemHelper.getProjectIdd(item)
          if (narrowWidth) {
            // ROUTE_ID.SINGLE_ITEM looks more logical, but in that case you cannot navigate back,
            // since we lose the top navigation bar
            const rid = readOnly ? ROUTE_ID.SINGLE_ITEM_READ_ONLY : ROUTE_ID.ITEM_APP
            const forcedRelativeURL = RELATIVE_URL_BY_ROUTE_ID[rid]
            history.push(forcedRelativeURL(id))
          } else if (projectID === previousProjectID) {
            history.push(relativeURL(projectID, id))
            if (routeId === ROUTE_ID.MATRIX_ONE || routeId === ROUTE_ID.MATRIX_READ_ONLY) {
              scrollIfPossible(item)
            }
          } else {
            const link = getItemLink(id)
            simulateLinkClick(link, true)
          }
        })
      } else if (type === PM_API_RESOURCE_TYPE.PROJECT) {
        if (narrowWidth) {
          const relativeURL = RELATIVE_URL_BY_ROUTE_ID[ROUTE_ID.SINGLE_PROJECT]
          history.push(relativeURL(id))
        } else if (id === previousProjectID) {
          history.push(relativeURL(id))
        } else {
          const link = getProjectLink(id)
          simulateLinkClick(link, true)
        }
      }
    }

    switch (routeId) {
      case ROUTE_ID.GROWTH:
      case ROUTE_ID.CALENDAR_TEAMS:
        {
          customManageItem({ store, id, t }).then(({ isError, payload }) => {
            if (isError) return
            const { id } = payload
            const relativeURL = narrowWidth
              ? RELATIVE_URL_BY_ROUTE_ID[ROUTE_ID.ITEM_APP]
              : getRelativeURLKeepingQuerySearch.teamsCalendar
            const path = relativeURL(id)
            history.push(path)
          })
        }
        break
      case ROUTE_ID.FEED_TEAMS:
        {
          if (type === PM_API_RESOURCE_TYPE.ITEM) {
            customManageItem({ store, id, t }).then(({ isError, payload }) => {
              if (isError) return
              const { id } = payload
              const path = getRelativeURLKeepingQuerySearch.teamsFeed(id)
              history.push(path)
            })
          } else if (type === PM_API_RESOURCE_TYPE.PROJECT) {
            customManageProject({ store, id, t }).then(({ isError, payload }) => {
              if (isError) return
              const { id } = payload
              const link = teamsHelper.getDeeplinkWithAppInfo(teamsConfig).toProject(id)
              executeDeepLink(link)
            })
          }
        }
        break
      case ROUTE_ID.ONE_ON_ONE:
      case ROUTE_ID.ONE_ON_ONE_HUB:
      case ROUTE_ID.ONE_ON_ONE_APP:
      case ROUTE_ID.SEARCH:
      case ROUTE_ID.SEARCH_APP:
        {
          if (type === PM_API_RESOURCE_TYPE.ITEM) {
            customManageItem({ store, id, t }).then(({ isError, payload }) => {
              if (isError) return
              const { id, item } = payload
              const projectID = itemHelper.getProjectIdd(item)
              if (source === SOURCES.MOVE_ITEM_MODAL) {
                history.push(navigateToMatrixPath(projectID, id))
                scrollIfPossible(item)
                return
              }
              if (routeId === ROUTE_ID.SEARCH_APP || routeId === ROUTE_ID.SEARCH) {
                history.push(RELATIVE_URL_BY_ROUTE_ID[routeId](id))
                return
              }
              // Get current 1on1 collaborator from pathname:
              const match = matchPath(history.location.pathname, {
                path: `${RELATIVE_URL_BY_ROUTE_ID[routeId]().pathname}:collaboratorID`,
              })
              history.push(RELATIVE_URL_BY_ROUTE_ID[routeId](match?.params.collaboratorID ?? 'null', id))
            })
          } else if (type === PM_API_RESOURCE_TYPE.PROJECT) {
            customManageProject({ store, id, t }).then(({ isError, payload }) => {
              if (isError) return
              const { id } = payload
              // Get current itemID from pathname:
              const match = matchPath(history.location.pathname, {
                path: '/*/:id',
              })
              const selectedItemId = +match?.params.id
              const selectedItem = !isNaN(selectedItemId) ? stateHelper.getItem(state, selectedItemId) : null
              if (selectedItem && itemHelper.belongsToProjectWithId(selectedItem, id)) {
                const itemId = itemHelper.getId(selectedItem)
                navigateToMatrix(id, itemId)
              } else {
                navigateToMatrix(id)
              }
            })
          }
        }
        break
      case ROUTE_ID.SINGLE_PROJECT:
      case ROUTE_ID.SINGLE_ITEM:
        {
          if (type === PM_API_RESOURCE_TYPE.ITEM) {
            customManageItem({ store, id, t }).then(({ isError, payload }) => {
              if (isError) return
              const { id } = payload
              navigateToSingleItem(id)
            })
          } else if (type === PM_API_RESOURCE_TYPE.PROJECT) {
            customManageProject({ store, id, t }).then(({ isError, payload }) => {
              if (isError) return
              const { id } = payload
              navigateToSingleProject(id)
            })
          }
        }
        break
      case ROUTE_ID.CALENDAR_READ_ONLY:
      case ROUTE_ID.GANTT_READ_ONLY:
      case ROUTE_ID.MATRIX_READ_ONLY:
      case ROUTE_ID.LIST_READ_ONLY:
      case ROUTE_ID.KANBAN_READ_ONLY:
        handleOneProject({ readOnly: true })
        break
      case ROUTE_ID.CALENDAR_ONE:
      case ROUTE_ID.GANTT_ONE:
      case ROUTE_ID.FEED_ONE:
      case ROUTE_ID.REPORTS_ONE:
      case ROUTE_ID.MATRIX_ONE:
      case ROUTE_ID.LIST_ONE:
      case ROUTE_ID.KANBAN_ONE:
        handleOneProject({ readOnly: false })
        break
      case ROUTE_ID.PROJECTS:
        if (type === PM_API_RESOURCE_TYPE.PROJECT) {
          customManageProject({ store, id, t }).then(({ isError, payload }) => {
            if (isError) return
            const { id } = payload
            navigateToMatrix(id)
          })
        }
        break
      case ROUTE_ID.GLOBAL_REPORTS_APP:
        {
          if (type === PM_API_RESOURCE_TYPE.ITEM) {
            customManageItem({ store, id, t }).then(({ isError, payload }) => {
              if (isError) return
              const { id, item } = payload
              const projectID = itemHelper.getProjectIdd(item)
              if (narrowWidth) {
                const relativeURL = RELATIVE_URL_BY_ROUTE_ID[ROUTE_ID.ITEM_APP]
                history.push(relativeURL(id))
              } else if (source === SOURCES.MOVE_ITEM_MODAL) {
                const projectID = itemHelper.getProjectIdd(item)
                const matrixAppRelativeURL = RELATIVE_URL_BY_ROUTE_ID[ROUTE_ID.MATRIX_APP]
                history.push(matrixAppRelativeURL(projectID, id))
                scrollIfPossible(item)
              } else {
                const relativeURL = RELATIVE_URL_BY_ROUTE_ID[routeId]
                history.push(relativeURL(projectID, id))
                scrollIfPossible(item)
              }
            })
          }
        }
        break
      case ROUTE_ID.HOME:
      case ROUTE_ID.CALENDAR_APP:
      case ROUTE_ID.GANTT_APP:
      case ROUTE_ID.FEED_APP:
      case ROUTE_ID.REPORTS_APP:
      case ROUTE_ID.MATRIX_APP:
      case ROUTE_ID.LIST_APP:
      case ROUTE_ID.KANBAN_APP:
      case ROUTE_ID.GLOBAL_FEED_APP:
      case ROUTE_ID.GLOBAL_CALENDAR_APP:
      case ROUTE_ID.GLOBAL_GANTT_APP:
      case ROUTE_ID.INBOX:
      case ROUTE_ID.PROJECT_APP:
      case ROUTE_ID.ITEM_APP:
        {
          if (type === PM_API_RESOURCE_TYPE.ITEM) {
            customManageItem({ store, id, t }).then(({ isError, payload }) => {
              if (isError) return
              const { id, item, isInbox } = payload
              const projectID = itemHelper.getProjectIdd(item)
              if (narrowWidth) {
                const relativeURL = RELATIVE_URL_BY_ROUTE_ID[ROUTE_ID.ITEM_APP]
                history.push(relativeURL(id))
              } else if (routeId === ROUTE_ID.HOME) {
                const section = linkObject.section ?? getCurrentSection()
                const relativeURL = RELATIVE_URL_BY_ROUTE_ID[routeId]
                history.push(relativeURL(section, id))
              } else if (isInbox && ![SOURCES.CALENDAR, SOURCES.GANTT].includes(source)) {
                navigateToInbox(id)
              } else if (source === SOURCES.MOVE_ITEM_MODAL) {
                const projectID = itemHelper.getProjectIdd(item)
                const matrixAppRelativeURL = RELATIVE_URL_BY_ROUTE_ID[ROUTE_ID.MATRIX_APP]
                history.push(matrixAppRelativeURL(projectID, id))
                scrollIfPossible(item)
              } else if (
                source === SOURCES.CHAT ||
                source === SOURCES.ITEM_RESOURCES ||
                source === SOURCES.INAPP_NOTIFICATION
              ) {
                navigateToMatrix(projectID, id)
                scrollIfPossible(item)
              } else {
                const relativeURL = RELATIVE_URL_BY_ROUTE_ID[routeId]
                history.push(relativeURL(projectID, id))
              }
            })
          } else if (type === PM_API_RESOURCE_TYPE.PROJECT) {
            customManageProject({ store, id, t }).then(({ isError, payload }) => {
              if (isError) return
              const { id } = payload
              if (routeId === ROUTE_ID.HOME && linkObject.section) {
                navigateToMatrix(id)
              } else if (narrowWidth) {
                const relativeURL = RELATIVE_URL_BY_ROUTE_ID[ROUTE_ID.PROJECT_APP]
                history.push(relativeURL(id))
              } else {
                navigateToMatrix(id)
              }
            })
          }
        }
        break
      case ROUTE_ID.EFFORT_PLANNING:
        if (type === PM_API_RESOURCE_TYPE.ITEM) {
          customManageItem({ store, id, t }).then(({ isError, payload }) => {
            if (isError) return
            const { item } = payload
            history.push(RELATIVE_URL_BY_ROUTE_ID[ROUTE_ID.EFFORT_PLANNING](itemHelper.getId(item)))
          })
        } else if (type === PM_API_RESOURCE_TYPE.PROJECT) {
          customManageProject({ store, id, t }).then(({ isError, payload }) => {
            if (isError) return
            const { id } = payload
            navigateToMatrix(id)
          })
        }
        break
      default:
        break
    }
  }

  return false
}
