import { useCallback, useMemo } from 'react'
import { cn } from '@appfluence/classnames'
import { Button, Dropdown, Option } from '@fluentui/react-components'
import { SearchSettings } from '@/components/BundledIcons'
import { useMergeState } from '../../../common/src/hooks/enhancedHooks'
import { itemHelper } from '../../../common/src/helpers'
import { useSelector } from 'react-redux'
import { useTeamMap } from '../../../common/src/hooks/usersHooks'
import { isInitializedRequestForPMResource } from '../../../common/src/selectors/requestStateSelectors'
import { PM_API_RESOURCES } from '../../../common/src/constants'
import { Loading } from '../../../components/basic/Loading'
import { useTranslation } from 'react-i18next'
import { ItemVirtualizedList } from '../../../components/list/ItemVirtualizedList'
import { GhostItemCell } from '../../../components/itemCell/GhostItemCell'
import { VIEW_KEYS, TOTALS, keyToDate, ModalTopBar, TopBar } from './common'
import { DataTable } from './EffortTable'
import { useDatePickerStrings } from '../../../hooks/dateHooks'
import { useCreateItemModal } from '@/hooks/useCreateItemModal'
import { EVENT_EXTRA } from '../../../common/src/eventTracking/amplitudeEvents'
import {
  useTableData,
  useFetchDueItemsInInterval,
  useItemsInInterval,
  useTimeIntervalForDay,
} from '../../../hooks/workloadHooks'
import { LocalizedDatePicker } from '../../../components/pickers/LocalizedDatePicker'
import { SettingsModal } from './settings/Settings'
import { AddItemButtonWithTooltip } from '../../../components/buttons/AddItemButtonWithTooltip'
import { getRelativeURL } from '../../../helpers/routeHelper'
import { useHistory } from 'react-router'
import { useSelectedItem } from '../../../hooks/PMHooks'
import { BackButton, ForwardButton } from '../../../components/buttons/NavButtons'
import { sortBy } from '@/utils/arrayUtils'
import './EffortPlanning.scss'

const AddButton = AddItemButtonWithTooltip

const useSortedTeam = () => {
  const team = useTeamMap()
  return useMemo(() => {
    return team.sortBy((v, k) => k)
  }, [team])
}

const useLoadingCollaborators = () =>
  !useSelector(s => isInitializedRequestForPMResource(s, PM_API_RESOURCES.COLLABORATORS))

const useViewOptions = () => {
  const { t } = useTranslation()
  return useMemo(() => {
    return [
      {
        key: VIEW_KEYS.TASKS,
        text: t('effort_planning.number_of_items'),
      },
      {
        key: VIEW_KEYS.EFFORT,
        text: t('effort_planning.effort_hours'),
      },
      {
        key: VIEW_KEYS.HOURS,
        text: t('effort_planning.hours'),
      },
    ]
  }, [t])
}

export const EffortPlanning = () => {
  const [state, setState] = useMergeState(() => ({
    defEffort: 60,
    defHours: 1,
    viewKey: VIEW_KEYS.TASKS,
    displayDetails: [],
    showSettings: false,
    day: new Date(),
  }))
  const datePickerStrings = useDatePickerStrings()()
  const { range, start, end } = useTimeIntervalForDay(state.day)
  const selectedItem = useSelectedItem()
  const { t } = useTranslation()
  const loadingCollaborators = useLoadingCollaborators()
  const viewOptions = useViewOptions()
  const onOptionChange = useCallback(
    (_evt, data) => {
      setState({ viewKey: data.selectedOptions[0] })
    },
    [setState]
  )
  const createItemModal = useCreateItemModal()
  const loadingItems = useFetchDueItemsInInterval(start, end)
  const loading = loadingItems || loadingCollaborators
  const items = useItemsInInterval([start, end])
  const team = useSortedTeam()
  const history = useHistory()

  const onSelectItem = useCallback(
    item => {
      history.push(getRelativeURL.effortApp(itemHelper.getId(item)))
    },
    [history]
  )

  const table = useTableData({ team, items, days: range, state })
  const onClickCell = useCallback(
    (row, col, cellData) => {
      if (cellData.items.size === 0) {
        createItemModal({
          mode: EVENT_EXTRA.CREATE_ITEM.MODE.PLANNING_EFFORT,
          displayProjectSelector: true,
          dueDate: (col !== TOTALS && keyToDate(col)) || undefined,
          ownerEmail: (row !== TOTALS && row) || undefined,
          quadrant: 0,
          open: true,
          completion: onSelectItem,
        })
      } else {
        setState({ displayDetails: [row, col] })
      }
    },
    [createItemModal, onSelectItem, setState]
  )
  const onChangeDate = useCallback(
    d => {
      setState({ day: d })
    },
    [setState]
  )
  const dismissItemList = useCallback(
    (row, col) => {
      setState({ displayDetails: [] })
    },
    [setState]
  )
  const goToDay = d => () => {
    const next = new Date(state.day)
    next.setDate(next.getDate() + d) //todo DateFNS
    onChangeDate(next)
  }
  const goToNextWeek = goToDay(7)
  const goToPrevWeek = goToDay(-7)
  const [row, col] = state.displayDetails
  const elementsUnsorted = table?.[row]?.[col]?.items.toArray() || []
  const elements = sortBy(elementsUnsorted, itemHelper.getDueTimestamp)
  const handleOnClickCreate = () => {
    createItemModal({
      mode: EVENT_EXTRA.CREATE_ITEM.MODE.PLANNING_EFFORT,
      displayProjectSelector: true,
      dueDate: (col !== TOTALS && keyToDate(col)) || undefined,
      ownerEmail: (row !== TOTALS && row) || undefined,
      quadrant: 0,
      open: true,
      completion: onSelectItem,
    })
  }
  const openSettings = () => setState({ showSettings: true })
  const closeSettings = () => setState({ showSettings: false })
  let modalContent = undefined
  if (state.showSettings) {
    modalContent = <SettingsModal team={team} dismiss={closeSettings} />
  } else if (state.displayDetails.length) {
    modalContent = (
      <ItemListModal
        elements={elements}
        onSelectItem={onSelectItem}
        selectedItem={selectedItem}
        handleOnClickCreate={handleOnClickCreate}
        dismissItemList={dismissItemList}
      />
    )
  }
  const body = loading ? (
    <Loading label={t('general.loading')} />
  ) : (
    <DataTable range={range} team={team} table={table} viewKey={state.viewKey} onClickCell={onClickCell} />
  )
  const selectedOption = viewOptions.find(o => o.key === state.viewKey)
  return (
    <div
      className={cn('relative flex h-full w-full flex-col', !!modalContent && 'overflow-hidden')}
      data-automation-id="planning-effort-view"
    >
      <TopBar className="no-height">
        <Button
          onClick={openSettings}
          disabled={loading}
          icon={<SearchSettings />}
          appearance="subtle"
          className="!mr-1"
          aria-label="Settings button"
        />
        <div className="inline-div">
          <span>{t('date.date')}</span>
          <LocalizedDatePicker
            value={state.day}
            showClearButton={false}
            onSelectDate={onChangeDate}
            showWeekNumbers
            showMonthPickerAsOverlay
            disabled={loading}
            placeholder={t('general.select_date')}
            ariaLabel={t('general.select_date')}
            strings={datePickerStrings}
          />
        </div>
        <div className="-ml-2">
          <BackButton onClick={goToPrevWeek} disabled={loading} />
          <ForwardButton onClick={goToNextWeek} disabled={loading} />
        </div>
        <div className="inline-div">
          <span>{t('general.mode')}</span>
          <Dropdown
            className="!w-44 !min-w-44"
            selectedOptions={[selectedOption.key]}
            onOptionSelect={onOptionChange}
            disabled={loading}
            value={selectedOption.text}
          >
            {viewOptions.map(o => (
              <Option key={o.key} value={o.key}>
                {o.text}
              </Option>
            ))}
          </Dropdown>
        </div>
      </TopBar>
      {modalContent || body}
    </div>
  )
}

const ItemListModal = ({ dismissItemList, handleOnClickCreate, elements, onSelectItem, selectedItem }) => {
  const { t } = useTranslation()
  return (
    <>
      <ModalTopBar>
        <BackButton onClick={dismissItemList}>{t('general.go_back')}</BackButton>
        <AddButton text={t('item.create_item')} onClick={handleOnClickCreate} />
      </ModalTopBar>
      <ItemVirtualizedList
        items={elements}
        loading={false}
        shimmerComponent={GhostItemCell}
        noRowsRenderer={() => null}
        onSelectItem={onSelectItem}
        selectedItem={selectedItem}
      />
    </>
  )
}
