import { memo } from 'react'
import { Text } from '../text/Text'
import { MeComponent } from '../users/MeComponent'
import { cn } from '@appfluence/classnames'

const defaultStyles = {
  title: null,
  leftContainer: null,
  rightContainer: null,
}

export const TopRightBar = memo(
  ({
    inApp = true,
    title,
    leftSubcomponents,
    rightSubcomponents,
    styles = defaultStyles,
    promotionCallout,
    shouldLoadTitle = true,
    className,
    ...rest
  }) => {
    return (
      <div
        className={cn(
          'flex h-8 items-center justify-start border-0 border-b border-solid border-pm-neutral-light px-3 py-1 text-pm-black',
          className
        )}
        {...rest}
      >
        {!inApp && <MeComponent />}
        {title && shouldLoadTitle && (
          <Text variant="large" block style={styles.title} className="hidden font-semibold text-pm-black md:block">
            {title}
          </Text>
        )}
        <div className="relative flex min-w-0 items-center" style={styles.leftContainer}>
          {leftSubcomponents}
        </div>
        <div className="relative flex min-w-0 flex-1 items-center justify-end gap-1" style={styles.rightContainer}>
          {rightSubcomponents}
          {promotionCallout}
        </div>
      </div>
    )
  }
)
