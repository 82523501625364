import { useCallback } from 'react'
import { VirtualizedList } from './VirtualizedList'

export const ShimmerVirtualizedList = ({
  rows,
  rowRenderer,
  shimmerComponent: Shimmer,
  loading,
  shimmersCount = 25,
  noRowsRenderer,
  computeItemKey,
  endReached,
  increaseViewportBy,
  innerListRef,
  placeholder,
}) => {
  const customRowRenderer = useCallback(
    index => {
      const found = !!rows[index]
      if (!found) {
        return <Shimmer />
      }
      return rowRenderer(index)
    },
    [rows, rowRenderer, Shimmer]
  )
  const rowsWithShimmers = loading ? [...rows, ...Array(shimmersCount).fill(null)] : rows

  return (
    <VirtualizedList
      rows={rowsWithShimmers}
      rowRenderer={customRowRenderer}
      noRowsRenderer={noRowsRenderer}
      computeItemKey={computeItemKey}
      endReached={endReached}
      increaseViewportBy={increaseViewportBy}
      innerListRef={innerListRef}
      placeholder={placeholder}
    />
  )
}
