import _ from 'lodash'
import styled from 'styled-components'
import { useCallback, useEffect, useMemo, useReducer } from 'react'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import { SimpleModal } from '../../components/modal/SimpleModal'
import { TextField } from '@fluentui/react/lib/TextField'
import { ComboBox } from '@fluentui/react/lib/ComboBox'
import { Toggle } from '@fluentui/react/lib/Toggle'
import { SafeTimeInput } from '../../components/input/SafeTimeInput'
import { dateToAllDay } from '../../common/src/helpers/dateHelper'
import { mergeStyleSets } from '@fluentui/react/lib/Styling'
import { useDatePickerStrings, useDidChangeDate, useDidChangeTime } from '../../hooks/dateHooks'
import { meetingHelper } from '../../common/src/helpers'
import { useTranslation } from 'react-i18next'
import { LocalizedDatePicker } from '../../components/pickers/LocalizedDatePicker'
import { differenceInMinutes, differenceInDays, intlFormatDistance, startOfHour, addMinutes } from 'date-fns'
import { datefns } from '../../utils/datefns'

const componentName = 'MeetingModal'

const modalStyles = {
  title: {
    fontWeight: 'bold',
  },
}

const classNames = {
  title: `${componentName}-title`,
  datesSection: `${componentName}-datesSection`,
  date: `${componentName}-date`,
  time: `${componentName}-time`,
  duration: `${componentName}-duration`,
  allDay: `${componentName}-allDay`,
}

export const getClassNames = _.memoize(() => {
  return mergeStyleSets({
    title: [classNames.title, { marginTop: '12px' }],
    datesSection: [
      classNames.datesSection,
      {
        marginTop: '12px',
        display: 'flex',
        alignItems: 'flex-start',
      },
    ],
    date: [classNames.date, {}],
    time: [classNames.time, { marginLeft: '12px' }],
    duration: [classNames.duration],
    allDay: [classNames.allDay],
  })
})

const SToggle = styled(Toggle)`
  margin-left: 12px;
  margin-bottom: 0;
`

export const MeetingModal = ({ meeting, onChangeMeeting, readOnly = true, isOpen, ...rest }) => {
  const [state, localDispatch] = useReducer(reducer, createInitialState())
  const { t } = useTranslation()
  const datePickerStringsGetter = useDatePickerStrings()
  const { loading, title, startDate, endDate, allDay, errorMessage, isValid, wasModified } = state
  const isCreating = !meeting
  const classNames = getClassNames()

  useEffect(() => {
    const meetingID = meetingHelper.getID(meeting)
    if (meetingID && isOpen) {
      localDispatch({ type: ACTION_TYPE.INIT, payload: { meeting } })
    }
  }, [isOpen, meeting])

  const onChangeTitle = useCallback((ev, value) => {
    localDispatch({ type: ACTION_TYPE.SET_TITLE, payload: value })
  }, [])

  const didChangeDate = useDidChangeDate()
  const didChangeTime = useDidChangeTime()

  const onChangeAllDay = useCallback((ev, checked) => {
    localDispatch({ type: ACTION_TYPE.SET_ALL_DAY, payload: checked })
  }, [])

  const onChangeStartDate = useCallback(date => {
    localDispatch({ type: ACTION_TYPE.SET_START_DATE, payload: date })
  }, [])

  const onChangeEndDate = useCallback(date => {
    localDispatch({ type: ACTION_TYPE.SET_END_DATE, payload: date })
  }, [])

  const didChangeStartDate = useMemo(() => {
    return didChangeDate(startDate, onChangeStartDate)
  }, [didChangeDate, startDate, onChangeStartDate])

  const didChangeEndDate = useMemo(() => {
    return didChangeDate(endDate, onChangeEndDate)
  }, [didChangeDate, endDate, onChangeEndDate])

  const onChangeStartDateTime = useMemo(() => {
    return didChangeTime(startDate, onChangeStartDate)
  }, [didChangeTime, startDate, onChangeStartDate])

  const onChangeEndDateTime = useMemo(() => {
    return didChangeTime(endDate, onChangeEndDate)
  }, [didChangeTime, endDate, onChangeEndDate])

  const onChangeDuration = useCallback((ev, checked) => {}, [])

  const startPickerStrings = useMemo(() => {
    return datePickerStringsGetter(null, endDate)
  }, [datePickerStringsGetter, endDate])
  const endPickerStrings = useMemo(() => {
    return datePickerStringsGetter(startDate, null)
  }, [datePickerStringsGetter, startDate])
  const startTimeValue = useMemo(() => datefns.format('HH:mm')(startDate), [startDate])
  const endTimeValue = useMemo(() => datefns.format('HH:mm')(endDate), [endDate])

  const duration = useMemo(() => {
    if (allDay) {
      const days = differenceInDays(endDate, startDate) + 1
      return t('date.unit.day', { count: days })
    }

    return intlFormatDistance(endDate, startDate)
  }, [startDate, endDate, allDay, t])

  const onUpdate = useCallback(() => {
    onChangeMeeting()
  }, [onChangeMeeting])

  const onCreate = useCallback(() => {
    onChangeMeeting()
  }, [onChangeMeeting])

  const durationOptions = useMemo(() => {
    return _.map(_.range(6), r => {
      const minutes = (r + 1) * 30
      const text =
        minutes < 60 ? t('date.unit.minute', { count: minutes }) : t('date.unit.hour', { count: minutes / 60 })
      return {
        key: `${minutes}`,
        text,
      }
    })
  }, [t])

  const actionButtonTitle = isCreating ? t('general.create') : t('general.update')
  const modalTitle = isCreating ? t('meeting.create_meeting') : t('meeting.update_meeting')

  const button = (
    <PrimaryButton
      id={`${componentName}_actionButton`}
      key={`${componentName}_actionButton`}
      onClick={isCreating ? onCreate : onUpdate}
      disabled={isCreating ? !isValid : !(isValid && wasModified)}
    >
      {actionButtonTitle}
    </PrimaryButton>
  )
  return (
    <SimpleModal
      title={modalTitle}
      loading={loading}
      errorMessage={errorMessage}
      buttons={button}
      closeButtonProps={{
        text: t('general.cancel'),
      }}
      styles={modalStyles}
      isOpen={isOpen}
      {...rest}
    >
      <TextField
        label={t('meeting.title')}
        value={title}
        placeholder={t('meeting.title_placeholder')}
        onChange={onChangeTitle}
        disabled={readOnly || loading}
        className={classNames.title}
      />
      <div className={classNames.datesSection}>
        <ComboBox
          label={t('meeting.duration_title')}
          text={duration}
          options={durationOptions}
          onChange={onChangeDuration}
          disabled={readOnly || loading || allDay}
          className={classNames.duration}
        />
        <SToggle
          label={t('meeting.all_day_title')}
          checked={allDay}
          onChange={onChangeAllDay}
          disabled={readOnly || loading}
          className={classNames.allDay}
        />
      </div>
      <div className={classNames.datesSection}>
        <LocalizedDatePicker
          label={t('meeting.start_date_title')}
          strings={startPickerStrings}
          placeholder={t('meeting.start_date_placeholder')}
          value={startDate}
          maxDate={endDate}
          onSelectDate={didChangeStartDate}
          disabled={readOnly || loading}
          className={classNames.date}
        />
        {!allDay && (
          <SafeTimeInput
            label={t('meeting.time_title')}
            value={startTimeValue}
            onChange={onChangeStartDateTime}
            disabled={readOnly || loading}
            className={classNames.time}
          />
        )}
      </div>
      <div className={classNames.datesSection}>
        <LocalizedDatePicker
          label={t('meeting.end_date_title')}
          strings={endPickerStrings}
          placeholder={t('meeting.end_date_placeholder')}
          value={endDate}
          minDate={startDate}
          onSelectDate={didChangeEndDate}
          disabled={readOnly || loading}
          className={classNames.date}
        />
        {!allDay && (
          <SafeTimeInput
            label={t('meeting.time_title')}
            value={endTimeValue}
            onChange={onChangeEndDateTime}
            disabled={readOnly || loading}
            className={classNames.time}
          />
        )}
      </div>
    </SimpleModal>
  )
}

const defaultDuration = 30 // Minutes
const createInitialState = () => {
  const now = new Date()
  const startDate = startOfHour(now)
  const endDate = addMinutes(startDate, defaultDuration)
  const reminderDate = addMinutes(startDate, -15)

  return {
    loading: false,
    errorMessage: false,
    name: '',
    allDay: false,
    startDate,
    endDate,
    reminderDate,

    isValid: false,
    wasModified: false,
  }
}

const ACTION_TYPE = {
  INIT: 'INIT',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  SET_LOADING: 'SET_LOADING',

  SET_TITLE: 'SET_TITLE',

  SET_START_DATE: 'SET_START_DATE',
  SET_END_DATE: 'SET_END_DATE',
  SET_ALL_DAY: 'SET_ALL_DAY',
  SET_REMINDER_DATE: 'SET_REMINDER_DATE',
}

const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case ACTION_TYPE.INIT:
      {
        const { meeting } = payload
        state.title = meetingHelper.getTitle(meeting)
        state.startDate = meetingHelper.getStartDate(meeting)
        state.endDate = meetingHelper.getEndDate(meeting)
        state.allDay = meetingHelper.isAllDay(meeting)
        state.reminderDate = meetingHelper.getReminderDate(meeting)
      }
      break
    case ACTION_TYPE.SET_ERROR_MESSAGE:
      state.errorMessage = payload
      break
    case ACTION_TYPE.SET_LOADING:
      state.loading = payload
      state.errorMessage = ''
      break
    case ACTION_TYPE.SET_TITLE:
      {
        state.title = action.payload
      }
      break
    case ACTION_TYPE.SET_ALL_DAY: {
      state.allDay = action.payload
      if (state.allDay) {
        state.startDate = dateToAllDay(state.startDate)
        state.endDate = dateToAllDay(state.endDate)
      } else if (differenceInMinutes(state.endDate, state.startDate) < defaultDuration) {
        const now = new Date()
        const newStartDate = new Date(state.startDate.getTime())
        newStartDate.setHours(now.getHours(), 0, 0, 0)
        const newEndDate = new Date(newStartDate.getTime())
        newEndDate.setMinutes(newEndDate.getMinutes() + defaultDuration)
        state.startDate = newStartDate
        state.endDate = newEndDate
      }
      break
    }
    case ACTION_TYPE.SET_START_DATE:
      state.startDate = action.payload
      break
    case ACTION_TYPE.SET_END_DATE:
      state.endDate = action.payload
      break

    default:
      break
  }
  state.wasModified = type !== ACTION_TYPE.INIT
  state.isValid = !_.isEmpty(state.title)

  return { ...state }
}
