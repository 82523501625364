import { FC } from 'react'
import { RecentProjectsSection } from '../../components/project/RecentProjectsSection'
import { HomeInfoCard } from './HomeInfoCard'
import type { Section } from './HomeConstants'
import { isFreemium as isFreemiumSelector } from '../../common/src/selectors/authSelectors'
import { useSelector } from 'react-redux'
import { FreemiumQuotaCard } from '../../components/FreemiumQuotaCard'

interface IHomeHeaderProps {
  section: Section
}

export const HomeHeader: FC<IHomeHeaderProps> = ({ section }) => {
  const isFreemium = useSelector(isFreemiumSelector)

  return (
    <div className="mb-2 flex max-h-[7.3rem] w-full flex-row flex-wrap items-center justify-start gap-2 overflow-hidden">
      <HomeInfoCard />
      {isFreemium && <FreemiumQuotaCard />}
      <RecentProjectsSection />
    </div>
  )
}
