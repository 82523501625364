import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { isApptest } from '@/helpers/queryParamsHelper'

export const key = (key: string) => (isApptest() ? `apptest_${key}` : `app_${key}`)

const lastReviewRequestTimestampAtom = atomWithStorage(key('lastReviewRequestTimestamp'), '')
export const lastReviewRequestAtom = atom(
  get => (get(lastReviewRequestTimestampAtom) ? new Date(get(lastReviewRequestTimestampAtom)) : null),
  (get, set, date: Date) => set(lastReviewRequestTimestampAtom, date.toISOString())
)

export const hideItemDetailsAtom = atomWithStorage(key('hideItemDetails'), false)

export const hideConfirmationDeleteItemAtom = atomWithStorage(key('hideConfirmationDeleteItem'), false)
export const hideConfirmationDeleteItemsAtom = atomWithStorage(key('hideConfirmationDeleteItems'), false)
export const hideConfirmationMarkItemsAsCompletedAtom = atomWithStorage(
  key('hideConfirmationMarkItemsAsCompleted'),
  false
)
export const hideConfirmationUploadFileAtom = atomWithStorage(key('hideConfirmationUploadFile'), false)
