export const quadrantsColorsTemplatesKeys = {
  classic: 'classic',
  classic1: 'classic1',
  grape: 'grape',
  ocean: 'ocean',
  aqua: 'aqua',
  gold: 'gold',
  ocre: 'ocre',
  rust: 'rust',
  paper: 'paper',
  charcoal: 'charcoal',
}

export const quadrantsColorsTemplatesByKey = {
  [quadrantsColorsTemplatesKeys.classic]: {
    key: quadrantsColorsTemplatesKeys.classic,
    quadrantColors: [
      { r: 92, g: 164, b: 191, a: 1 },
      { r: 162, g: 198, b: 212, a: 1 },
      { r: 162, g: 198, b: 212, a: 1 },
      { r: 218, g: 236, b: 234, a: 1 },
    ],
  },
  [quadrantsColorsTemplatesKeys.classic1]: {
    key: quadrantsColorsTemplatesKeys.classic1,
    quadrantColors: [
      { r: 220, g: 242, b: 172, a: 1 },
      { r: 198, g: 232, b: 255, a: 1 },
      { r: 122, g: 192, b: 110, a: 1 },
      { r: 255, g: 255, b: 255, a: 1 },
    ],
  },
  [quadrantsColorsTemplatesKeys.grape]: {
    key: quadrantsColorsTemplatesKeys.grape,
    quadrantColors: [
      { r: 145, g: 124, b: 202, a: 1 },
      { r: 190, g: 164, b: 225, a: 1 },
      { r: 190, g: 164, b: 225, a: 1 },
      { r: 225, g: 223, b: 253, a: 1 },
    ],
  },
  [quadrantsColorsTemplatesKeys.aqua]: {
    key: quadrantsColorsTemplatesKeys.aqua,
    quadrantColors: [
      { r: 153, g: 215, b: 184, a: 1 },
      { r: 193, g: 241, b: 210, a: 1 },
      { r: 193, g: 241, b: 210, a: 1 },
      { r: 225, g: 251, b: 225, a: 1 },
    ],
  },
  [quadrantsColorsTemplatesKeys.gold]: {
    key: quadrantsColorsTemplatesKeys.gold,
    quadrantColors: [
      { r: 200, g: 165, b: 72, a: 1 },
      { r: 225, g: 207, b: 124, a: 1 },
      { r: 225, g: 207, b: 124, a: 1 },
      { r: 233, g: 233, b: 202, a: 1 },
    ],
  },
  [quadrantsColorsTemplatesKeys.ocre]: {
    key: quadrantsColorsTemplatesKeys.ocre,
    quadrantColors: [
      { r: 225, g: 153, b: 83, a: 1 },
      { r: 232, g: 188, b: 156, a: 1 },
      { r: 232, g: 188, b: 156, a: 1 },
      { r: 238, g: 217, b: 198, a: 1 },
    ],
  },
  [quadrantsColorsTemplatesKeys.rust]: {
    key: quadrantsColorsTemplatesKeys.rust,
    quadrantColors: [
      { r: 225, g: 126, b: 112, a: 1 },
      { r: 224, g: 175, b: 170, a: 1 },
      { r: 224, g: 175, b: 170, a: 1 },
      { r: 242, g: 223, b: 220, a: 1 },
    ],
  },
  [quadrantsColorsTemplatesKeys.paper]: {
    key: quadrantsColorsTemplatesKeys.paper,
    quadrantColors: [
      { r: 217, g: 217, b: 217, a: 1 },
      { r: 230, g: 230, b: 217, a: 1 },
      { r: 230, g: 230, b: 217, a: 1 },
      { r: 230, g: 230, b: 230, a: 1 },
    ],
  },
  [quadrantsColorsTemplatesKeys.charcoal]: {
    key: quadrantsColorsTemplatesKeys.charcoal,
    quadrantColors: [
      { r: 102, g: 102, b: 102, a: 1 },
      { r: 153, g: 153, b: 153, a: 1 },
      { r: 204, g: 204, b: 204, a: 1 },
      { r: 254, g: 252, b: 255, a: 1 },
    ],
  },
}
