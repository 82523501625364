import { createAction } from 'redux-api-middleware'
import _ from 'lodash'
import { itemHelper, projectHelper, tagHelper } from '../helpers'
import { PM_API_RESOURCE_TYPE, PM_API_RESOURCES, PM_API_URLS } from '../constants'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'
import {
  appendQueryParamsToURL,
  extendPMHeaders,
  getCredentialsConfig,
  withDefaultParams,
} from '../helpers/requestHelper'
import { validateTag } from '../helpers/stringHelper'

const DEFAULT_PARAMETERS = {
  ALL_TAGS: {
    limit: 1000,
  },
}

export const ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove',
}

const actionTagForObject = ({ action, tagName, object }) => {
  if (action !== ACTIONS.ADD && action !== ACTIONS.REMOVE) {
    const type = 'WRONG_ACTION'
    console.warn(type)
    return { type }
  }
  const isAdding = action === ACTIONS.ADD
  let apiResource = isAdding ? PM_API_RESOURCES.TAG_ADD : PM_API_RESOURCES.TAG_REMOVE
  let resourceURI = null
  let types = null
  const tag = { name: tagName }
  if (projectHelper.isProject(object)) {
    resourceURI = projectHelper.getResourceURI(object)
    const projectID = projectHelper.getIdd(object)
    types = generateActionTypesForPMAPI(apiResource, { projectID, tag })
  } else if (itemHelper.isItem(object)) {
    resourceURI = itemHelper.getResourceURI(object)
    const itemID = itemHelper.getId(object)
    types = generateActionTypesForPMAPI(apiResource, { itemID, tag })
  } else {
    const type = 'WRONG_OBJECT'
    console.warn(type)
    return { type }
  }

  if (resourceURI) {
    const _url = PM_API_URLS.TAG
    const body = JSON.stringify({ action, object: resourceURI, name: tagName })
    return createAction({
      endpoint: appendQueryParamsToURL(_url),
      credentials: getCredentialsConfig(),
      method: 'POST',
      types: types,
      headers: extendPMHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      }),
      body,
    })
  } else {
    const type = 'WRONG_TAG_ADD'
    console.warn(type)
    return { type }
  }
}

export const addTag = ({ tagName, object }) => actionTagForObject({ action: ACTIONS.ADD, tagName, object })

export const removeTag = ({ tagName, object }) => actionTagForObject({ action: ACTIONS.REMOVE, tagName, object })

export const renameTag = ({ tag, newTagName, model = PM_API_RESOURCE_TYPE.PROJECT }) => {
  const tagID = tagHelper.getID(tag)
  if (
    !tagID ||
    tagID <= 0 ||
    (model !== PM_API_RESOURCE_TYPE.PROJECT && model !== PM_API_RESOURCE_TYPE.ITEM) ||
    !validateTag(newTagName)
  ) {
    const type = 'WRONG_RENAME_TAG'
    console.warn(type)
    return { type }
  }
  const models = [model]
  const _url = _.template(PM_API_URLS.RENAME_TAG)({ id: tagID })
  const body = JSON.stringify({ new_tag: newTagName, models })
  return createAction({
    endpoint: appendQueryParamsToURL(_url),
    credentials: getCredentialsConfig(),
    method: 'POST',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.RENAME_TAG, {
      oldTag: tag.toJS(),
      newTag: { name: newTagName },
      model,
    }),
    headers: extendPMHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
    }),
    body,
  })
}

// Starred && Unstarred
export const addStarredTag = ({ object }) => addTag({ tagName: tagHelper.STARRED_TAG_NAME, object })

export const removeStarredTag = ({ object }) => removeTag({ tagName: tagHelper.STARRED_TAG_NAME, object })

// Archive && Unarchive
export const addArchiveTag = ({ object }) => addTag({ tagName: tagHelper.ARCHIVED_TAG_NAME, object })

export const removeArchiveTag = ({ object }) => removeTag({ tagName: tagHelper.ARCHIVED_TAG_NAME, object })

export const getItemTags = withDefaultParams(params => {
  return createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.ITEM_TAGS, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.ITEM_TAGS),
    headers: extendPMHeaders(),
  })
}, DEFAULT_PARAMETERS.ALL_TAGS)

export const getProjectTags = withDefaultParams(params => {
  return createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.PROJECT_TAGS, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.PROJECT_TAGS),
    headers: extendPMHeaders(),
  })
}, DEFAULT_PARAMETERS.ALL_TAGS)
