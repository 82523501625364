import { fromJS, is, Set } from 'immutable'
import _ from 'lodash'
import { PROJECTS_REDUCER_KEYS as KEYS } from './projectsReducerKeys'
import {
  API_EVENT,
  isPMApiDetailedAction,
  parseApiActionType,
  PERSIST_REHYDRATE_ACTION_TYPE,
} from '../helpers/reducerHelper'
import { PM_API_RESOURCES } from '../constants'
import { ACTIONS as WS_ACTIONS } from '../websocket/wsActions'
import { projectHelper, sharingRequestHelper, projectMembershipHelper } from '../helpers'
import { ACTIONS as PROJECT_ACTIONS } from '../actions/projectActions'
import { ACTIONS as COMBINED_API_ACTIONS } from '../actions/combinedAPI'
import { GENERIC_ACTION_TYPE } from '../actions/genericActions'
import * as commonReducerLogic from './commonReducerLogic'

const base = fromJS({
  [KEYS.BY_ID]: {},
  [KEYS.MODIFIED]: {},
  [KEYS.UNSYNCED]: [],
  [KEYS.ACCESS_DENIED]: {},
  [KEYS.CENTER_BY_ID]: {},
  [KEYS.READ_ONLY]: Set(),
})

const addTag = commonReducerLogic.addTagCreator(KEYS.BY_ID, projectHelper.KEYS.TAGS)
const removeTag = commonReducerLogic.removeTagCreator(KEYS.BY_ID, projectHelper.KEYS.TAGS)

const storeSingleJSProject = (state, project) => {
  const { idd } = project
  const isModified = !!state.getIn([KEYS.MODIFIED, idd])
  if (isModified) {
    return state
  }

  if (project.state === projectHelper.STATE.NORMAL) {
    //todo check previous project versionId
    return state.mergeIn([KEYS.BY_ID, idd], fromJS(project))
  } else {
    return state.deleteIn([KEYS.BY_ID, idd])
  }
}

const storeProjects = (state, projects) => {
  return state.withMutations(_st => {
    _.each(projects, project => {
      storeSingleJSProject(_st, project)
    })
    return _st
  })
}

//Summaries must not overwrite full projects
const storeSummaries = (state, projects) => {
  return state.withMutations(_st => {
    let upcomming = new Set()
    _.each(projects, project => {
      const idd = project.idd
      upcomming = upcomming.add(idd)
      const isNew = !_st.getIn([KEYS.BY_ID, idd])
      if (isNew) {
        _st.setIn([KEYS.BY_ID, idd], fromJS(project))
      }
    })
    const readOnly = state.get(KEYS.READ_ONLY)
    const stored = _st.get(KEYS.BY_ID).keySeq().toSet()
    const toDelete = stored.subtract(upcomming).subtract(readOnly)
    _.each(toDelete.toArray(), idd => _st.deleteIn([KEYS.BY_ID, idd]))
    return _st
  })
}

const updateIndexes = (state, projectsOnlyWithIdAndIndex) => {
  return state.withMutations(_st => {
    _.each(projectsOnlyWithIdAndIndex, idAndIndex => {
      const projectID = idAndIndex[projectHelper.KEYS.IDD]
      if (_st.getIn([KEYS.BY_ID, projectID])) {
        const index = idAndIndex[projectHelper.KEYS.INDEX]
        _st.setIn([KEYS.BY_ID, projectID, projectHelper.KEYS.INDEX], index)
      }
    })
    return _st
  })
}

const applyRequest = (state, request, userUri, onlyForStatus = sharingRequestHelper.STATUS.ACCEPTED) => {
  const status = sharingRequestHelper.getStatus(request)
  if (onlyForStatus !== status) {
    return state
  }

  if (!userUri) {
    return state
  }

  if (status !== sharingRequestHelper.STATUS.ACCEPTED && status !== sharingRequestHelper.STATUS.REMOVE) {
    return state
  }

  const projectID = sharingRequestHelper.getProjectID(request)
  if (!projectID) {
    return state
  }

  let project = state.getIn([KEYS.BY_ID, projectID])
  if (!project) {
    return state
  }
  let ownersURIsSet = projectHelper.getOwnersURIs(project).toSet()
  if (status === sharingRequestHelper.STATUS.ACCEPTED) {
    ownersURIsSet = ownersURIsSet.add(userUri)
  } else {
    ownersURIsSet = ownersURIsSet.remove(userUri)
  }

  project = project.set(projectHelper.KEYS.OWNER, ownersURIsSet.toList())
  return state.setIn([KEYS.BY_ID, projectID], project)
}

const applyRoleUpdate = (state, user, projectID, role) => {
  if (!user || !_.isNumber(role) || !projectID) {
    return state
  }

  let project = state.getIn([KEYS.BY_ID, projectID])
  if (!project) {
    return state
  }

  let allMemberships = projectHelper.getMemberships(project)

  if (role === projectMembershipHelper.PROJECT_ROLE.ADMIN || role === projectMembershipHelper.PROJECT_ROLE.ADD) {
    allMemberships = projectHelper.setUserRoleInMemberships(project, user, role)
  } else return state

  project = project.set(projectHelper.KEYS.MEMBERSHIPS, allMemberships)
  return state.setIn([KEYS.BY_ID, projectID], project)
}

const applyInboxAddresses = (state, projectID, addresses) => {
  if (!projectID || !addresses) {
    return state
  }
  let project = state.getIn([KEYS.BY_ID, projectID])
  project = project.set(projectHelper.KEYS.INBOX_ADDRESSES, addresses)
  return state.setIn([KEYS.BY_ID, projectID], project)
}

export const projects = (state = base, action) => {
  const { type, payload, meta } = action
  const apiAction = parseApiActionType(type)
  if (isPMApiDetailedAction(apiAction)) {
    const { resource, event } = apiAction
    if (event === API_EVENT.START) {
      switch (resource) {
        case PM_API_RESOURCES.SHARING_REQUEST_PUT:
        case PM_API_RESOURCES.SHARING_REQUEST_POST:
          {
            const { request, userUri } = meta
            state = applyRequest(state, request, userUri, sharingRequestHelper.STATUS.REMOVE)
          }
          break
      }
    } else if (event === API_EVENT.SUCCESS) {
      switch (resource) {
        case PM_API_RESOURCES.ALL_PROJECT_SUMMARIES:
          state = storeSummaries(state, payload.objects)
          break
        case PM_API_RESOURCES.ALL_PROJECTS:
          state = storeProjects(state, payload.objects)
          break
        case PM_API_RESOURCES.PROJECT:
        case PM_API_RESOURCES.PROJECT_PUT:
        case PM_API_RESOURCES.PROJECT_POST:
        case PM_API_RESOURCES.INSTANTIATE_PROJECT_TEMPLATES:
          {
            const project = payload
            if (meta?.isReadOnly) {
              state = state.set(KEYS.READ_ONLY, state.get(KEYS.READ_ONLY).add(project.idd))
            }
            state = storeSingleJSProject(state, project)
          }
          break
        case PM_API_RESOURCES.TAG_ADD:
          {
            const { projectID, tag } = meta
            if (projectID) {
              state = addTag(state, projectID, tag)
            }
          }
          break
        case PM_API_RESOURCES.TAG_REMOVE:
          {
            const { projectID, tag } = meta
            if (projectID) {
              state = removeTag(state, projectID, tag)
            }
          }
          break
        case PM_API_RESOURCES.SORT_PROJECTS:
          {
            state = updateIndexes(state, payload)
          }
          break
        case PM_API_RESOURCES.SHARING_REQUEST_PUT:
        case PM_API_RESOURCES.SHARING_REQUEST_POST:
          {
            const request = fromJS(payload)
            const { userUri } = meta
            state = applyRequest(state, request, userUri, sharingRequestHelper.STATUS.ACCEPTED)
          }
          break
        case PM_API_RESOURCES.UPDATE_PROJECT_ROLE:
          {
            const { user, projectID, role } = meta
            if (user && projectID && role !== undefined) {
              state = applyRoleUpdate(state, user, projectID, role)
            }
          }
          break
        case PM_API_RESOURCES.INBOX_ADDRESSES: {
          const { projectID } = meta
          state = applyInboxAddresses(state, projectID, payload)
        }
      }
    } else if (API_EVENT.ERROR === event) {
      if (resource === PM_API_RESOURCES.PROJECT) {
        const { status } = payload
        if (status === 401 || status === 403 || status === 404 || status === 410) {
          const idd = action.meta
          state = state.setIn([KEYS.ACCESS_DENIED, idd], true)
        }
      }
    }
  } else {
    switch (type) {
      case PROJECT_ACTIONS.SET_PROJECT_CENTER:
        {
          const { serverID, center } = payload
          state = state.setIn([KEYS.CENTER_BY_ID, serverID], center)
        }
        break
      case WS_ACTIONS.PROJECT_RECEIVED:
        {
          const project = payload
          state = storeSingleJSProject(state, project)
        }
        break
      case COMBINED_API_ACTIONS.MARK_PROJECT_AS_MODIFIED:
        {
          const project = action.payload
          state = state.setIn([KEYS.MODIFIED, projectHelper.getIdd(project)], project)
        }
        break
      case COMBINED_API_ACTIONS.MARK_PROJECT_AS_MODIFIED_IN_MODEL:
        {
          const project = action.payload
          const projectId = projectHelper.getIdd(project)
          state = state.setIn([KEYS.MODIFIED, projectId], project)
          state = state.setIn([KEYS.BY_ID, projectId], project)
        }
        break
      case COMBINED_API_ACTIONS.REMOVE_PROJECT_FROM_MODIFIED:
        {
          const project = action.payload
          const id = projectHelper.getIdd(project)
          const path = [KEYS.MODIFIED, id]
          const existingProject = state.getIn(path)
          if (is(project, existingProject)) {
            state = state.deleteIn(path)
          }
        }
        break
      case GENERIC_ACTION_TYPE.CLEAR_ALL:
        state = base
        break
      case PERSIST_REHYDRATE_ACTION_TYPE:
        if (payload.projects) {
          state = state.setIn([KEYS.CENTER_BY_ID], payload.projects.get(KEYS.CENTER_BY_ID))
        }
        break
    }
  }

  return state
}
