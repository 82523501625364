import { PM_API_RESOURCES, PM_API_URLS } from '../constants'
import { createAction } from 'redux-api-middleware'
import { appendQueryParamsToURL, extendPMHeaders, getCredentialsConfig } from '../helpers/requestHelper'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'
import _ from 'lodash'

export const getCustomFilters = (params = {}) => {
  const url = PM_API_URLS.CUSTOM_FILTERS
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.CUSTOM_FILTERS),
    headers: extendPMHeaders(),
  })
}

export const postCustomFilter = (customFilter, params = {}) => {
  const url = PM_API_URLS.CUSTOM_FILTERS
  const body = JSON.stringify(customFilter.toJS())
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method: 'POST',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.CUSTOM_FILTERS_POST),
    headers: extendPMHeaders(),
    body,
  })
}

export const deleteCustomFilter = (customFilterId, params = {}) => {
  const url = _.template(PM_API_URLS.CUSTOM_FILTER)({ id: customFilterId })
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method: 'DELETE',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.CUSTOM_FILTERS_DELETE, { customFilterId }),
    headers: extendPMHeaders(),
  })
}
