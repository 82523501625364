import { createGraphResourceBase } from './graphResourceBase'
import { ICON_NAMES } from '../../msIcons'
import { GRAPH_RESOURCE_TYPE } from './graphResourceBase'

const helper = {}

helper.create = file => {
  return createGraphResourceBase(file, {
    name: file.name || 'No name',
    icon: ICON_NAMES.OneDriveLogo,
    iconColor: '#0078d4',
    link: file.webUrl,
    type: GRAPH_RESOURCE_TYPE.FILE,
  })
}

helper.isFile = m => m.type === GRAPH_RESOURCE_TYPE.FILE

export default helper
