import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { putRejectingSharingRequest } from '../../common/src/actions/sharingRequestsAPI'
import { acceptSharingRequestDownloadingProject } from '../../common/src/actions/combinedAPI'
import { InvitationCell } from './InvitationCell'
import { SimpleModal } from '../modal/SimpleModal'
import { List } from '@fluentui/react/lib/List'
import { API_EVENT, isPMApiDetailedAction, parseApiActionType } from '../../common/src/helpers/reducerHelper'
import { useSharingRequestsForMe } from '../../common/src/hooks/sharingRequestsHooks'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { useRouter } from '../../hooks/useRouterHook'
import { useMergeState } from '../../common/src/hooks/enhancedHooks'
import { useTranslation } from 'react-i18next'

const PendingInvitationsModal_ = ({
  acceptSharingRequestDownloadingProject,
  putRejectingSharingRequest,
  onDismiss,
  ...rest
}) => {
  const invitations = useSharingRequestsForMe()
  const { t } = useTranslation()
  const [state, setState] = useMergeState({
    loading: false,
    errorMessage: null,
  })
  const { history } = useRouter()

  const dismissIfItWasLastInvitation = useCallback(() => {
    if (invitations && invitations.count() === 1) {
      onDismiss()
    }
  }, [invitations, onDismiss])

  const onAcceptedRequestForProjectID = useCallback(
    projectID => {
      const path = getRelativeURLKeepingQuerySearch.matrixForProjectId(projectID)
      history.push(path)

      onDismiss()
    },
    [history, onDismiss]
  )

  const onAcceptResponse = useCallback(
    response => {
      const { type, meta } = response
      const apiAction = parseApiActionType(type)
      if (isPMApiDetailedAction(apiAction)) {
        const { event } = apiAction
        if (event === API_EVENT.SUCCESS) {
          const { projectID } = meta
          onAcceptedRequestForProjectID(projectID)
        }
      }
      setState({
        loading: false,
        errorMessage: null,
      })

      dismissIfItWasLastInvitation()
    },
    [onAcceptedRequestForProjectID, setState, dismissIfItWasLastInvitation]
  )

  const onAccept = useCallback(
    request => {
      acceptSharingRequestDownloadingProject(request).then(onAcceptResponse)
      setState({
        loading: true,
        errorMessage: null,
      })
    },
    [acceptSharingRequestDownloadingProject, setState, onAcceptResponse]
  )

  const onRejectResponse = useCallback(() => {
    setState({
      loading: false,
      errorMessage: null,
    })

    dismissIfItWasLastInvitation()
  }, [setState, dismissIfItWasLastInvitation])

  const onReject = useCallback(
    request => {
      putRejectingSharingRequest(request).then(onRejectResponse)
      setState({
        loading: true,
        errorMessage: null,
      })
    },
    [putRejectingSharingRequest, setState, onRejectResponse]
  )

  const onRenderItem = useCallback(
    request => {
      return <InvitationCell invitation={request} onAccept={onAccept} onReject={onReject} />
    },
    [onAccept, onReject]
  )

  const { loading, errorMessage } = state
  const invitationsArray = invitations.toArray()
  return (
    <SimpleModal
      title={t('invites.new_project_invites')}
      loading={loading}
      errorMessage={errorMessage}
      onDismiss={onDismiss}
      {...rest}
    >
      <List items={invitationsArray} onRenderCell={onRenderItem} />
    </SimpleModal>
  )
}

export const PendingInvitationsModal = connect(undefined, dispatch =>
  bindActionCreators(
    {
      putRejectingSharingRequest,
      acceptSharingRequestDownloadingProject,
    },
    dispatch
  )
)(PendingInvitationsModal_)
