import { useEffect, useState } from 'react'
import * as onboardingActions from '../../actions/onboardingActions'
import * as queryParamsHelper from '../../helpers/queryParamsHelper'
import { Redirect } from 'react-router'
import { getPathnameKeepingQuerySearch, getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { useLastRememberedLocation } from '../../hooks/locationHooks'
import { useDispatch, useSelector } from 'react-redux'
import { useConsumeDeepLink } from '../../hooks/msTeamsHooks'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { setDidConsumeDeepLink } from '../../common/src/actions/teamsActions'
import { getCachedNumberOfProjects } from '../../selectors/uiSelectors'
import { Spinner } from '@fluentui/react-components'

export const IndexRedirect = () => {
  const dispatch = useDispatch()
  const nProjects = useSelector(getCachedNumberOfProjects)
  const rememberedPath = useLastRememberedLocation()
  const isOnboarding = queryParamsHelper.showOnboarding()
  const { waiting, link, enableOnboarding, type } = useConsumeDeepLink()
  const [stuck, setStuck] = useState(false)

  useEffect(() => {
    if (!link) {
      return
    }
    if (enableOnboarding) {
      dispatch(onboardingActions.setIsCancelled(false))
    }
    if (type) {
      dispatch(
        dispatchEvent(AMPLITUDE_ACTION_TYPES.OPEN_TEAMS_DEEPLINK, {
          type,
        })
      )
      dispatch(setDidConsumeDeepLink())
    }
  }, [link, enableOnboarding, type, dispatch])

  useEffect(() => {
    // This will happen if the remembered path is actually this same view
    // TODO: if we make NestedRoutes pass down the `exact` parameter, this could be removed
    if (!waiting && !link && rememberedPath) {
      const timer = setTimeout(() => setStuck(true), 500)
      return () => clearTimeout(timer)
    }
  }, [link, rememberedPath, waiting])

  if (stuck) {
    return <Redirect to={getRelativeURLKeepingQuerySearch.homeSection()} />
  }

  if (waiting) {
    return false
  }

  if (link) {
    return <Redirect to={link} />
  }

  if (rememberedPath && rememberedPath !== '/') {
    return <Redirect to={getPathnameKeepingQuerySearch(rememberedPath)} />
  }

  if (nProjects === undefined) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner />
      </div>
    )
  }

  if (nProjects === 1 || isOnboarding) {
    // If user only has the default "My Project", "Team Project", take them there
    return <Redirect to={getRelativeURLKeepingQuerySearch.redirectToFirstProject()} />
  }

  return <Redirect to={getRelativeURLKeepingQuerySearch.homeSection()} />
}
