import React, { Suspense } from 'react'
import styled from 'styled-components'
import { VERSION } from '../common/src/environment'
import { useTranslation } from 'react-i18next'
import { Loading } from '../components/basic/Loading'
import { Link } from 'react-router-dom'

const Changelog = React.lazy(() => import('./version/Changelog'))

const Block = styled.div`
  margin: 12px;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`

export const VersionView = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <Block>
        <p>
          {VERSION} <Link to="/">Open</Link>
        </p>
        <p>Lang: {t('general.languageId')}</p>
        <div>
          <Suspense fallback={<Loading />}>
            <Changelog />
          </Suspense>
        </div>
      </Block>
    </Container>
  )
}
