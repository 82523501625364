import React, { Suspense } from 'react'
import styled from 'styled-components'
import { Header } from './Header'
import { Content } from './Content'
import { ExtraCommonInfo } from './content/ExtraCommonInfo'
import { useSelectedGraphItem } from '../../hooks/PMHooks'
import { Loading } from '../../components/basic/Loading'
import { IVIEW_PLACEHOLDER_TYPES } from '../../components/placeholder/IViewPlaceholderTypes'
import { LazyIViewPlaceholder } from '../../components/placeholder/LazyIViewPlaceholder'

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  color: ${p => p.theme.palette.neutralDark};
`

const Title = styled.span`
  font-size: 20px;
  font-weight: 400;
  margin: 12px 0 24px 0;
`

const placeholder = {
  title: 'No selected resource',
  message: 'Resources represent tasks, events, emails, or documents, from Microsoft 365 or Priority Matrix.',
  type: IVIEW_PLACEHOLDER_TYPES.SELECTION,
}

export const GraphResourceDetails = ({ onItemCreated }) => {
  const resource = useSelectedGraphItem()
  if (!resource) {
    return (
      <Suspense fallback={<Loading />}>
        <LazyIViewPlaceholder {...placeholder} />
      </Suspense>
    )
  }
  const name = resource.name || 'no resource'
  return (
    <Container>
      <Header resource={resource} />
      <Title>{name}</Title>
      <Content resource={resource} />
      <ExtraCommonInfo resource={resource} onItemCreated={onItemCreated} />
    </Container>
  )
}
