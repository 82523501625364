import React from 'react'
import { safeCallFn } from '../../common/src/helpers/functionalUtils'
import { ContainerPanel } from '../../components/panels/ContainerPanel'
import { ProjectsFiltersView } from './ProjectsFiltersView'
import { useTranslation } from 'react-i18next'

export const ProjectsFiltersPanel = ({ onOpened, mode, ...rest }) => {
  const { t } = useTranslation()
  const localOnOpened = () => {
    safeCallFn(onOpened)
  }
  const nextProps = {
    ...rest,
    headerText: t('project_filters.header_title'),
    onOpened: localOnOpened,
  }

  return (
    <ContainerPanel {...nextProps}>
      <ProjectsFiltersView mode={mode} />
    </ContainerPanel>
  )
}
