import React, { useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { cn } from '@appfluence/classnames'
import { MicrosoftDateTimeTimeZone } from '../../../common/src/helpers/dateHelper'
import { ActionButton } from '@fluentui/react/lib/Button'
import { graphEventHelper } from '../../../common/src/helpers'
import { ICON_NAMES } from '../../../common/src/msIcons'
import { getMeeting, MEETING_SOURCES } from '../../../common/src/actions/meetingsAPI'
import { useDispatch } from 'react-redux'
import { useAsyncEffect } from '../../../common/src/hooks/useAsyncEffect'
import { getRelativeURLKeepingQuerySearch } from '../../../helpers/routeHelper'
import { useExtendedHistory } from '../../../hooks/useExtendedHistory'
import { TextField } from '@fluentui/react/lib/TextField'
import { formatDate } from '../../../utils/datefns'

const Text = styled.span`
  font-size: 13px;
  color: ${p => p.theme.palette.neutralDark};
  margin-bottom: 20px;
`

const NotesContainer = styled.div`
  order: ${p => p.order || 'unset'};
  margin-top: 20px;
`

const Notes = styled(TextField).attrs({
  label: 'Notes',
  multiline: true,
  readOnly: true,
  rows: 10,
})``

const MeetingButton = styled(ActionButton).attrs(p => ({
  iconProps: {
    iconName: ICON_NAMES.TeamsLogo,
    style: {
      color: p.theme.palette.teams,
    },
  },
  target: '_blank',
  text: 'Open Meeting',
}))``

const PMMeetingButton = styled(ActionButton).attrs({
  iconProps: {
    iconName: ICON_NAMES.Edit,
  },
  text: 'Edit Agenda',
})``

const Compo = ({ resource: calendarEvent }) => {
  const { start, end } = calendarEvent.resource
  const { history } = useExtendedHistory()
  const [pmMeeting, setPMMeeting] = useState({})
  const getId = () => _.get(pmMeeting, 'id', 0)
  const getNotes = () => _.get(pmMeeting, 'plain_notes', '')
  const dispatch = useDispatch()
  const meetingURL = graphEventHelper.getJoinUrl(calendarEvent)
  const cancelledCN = cn({ pm_hidden: !calendarEvent?.resource?.isCancelled }, 'text-pm-negative')
  const notesCN = cn({ pm_hidden: !getId() })
  useAsyncEffect(
    async isMounted => {
      setPMMeeting({})
      if (meetingURL) {
        const id = graphEventHelper.getICalUId(calendarEvent)
        const res = await dispatch(getMeeting(id, { source: MEETING_SOURCES.MSFT }))
        if (!res.error && isMounted()) {
          setPMMeeting(res.payload)
        }
      }
    },
    [calendarEvent]
  )
  const [startText, endText] = _.map([start, end], d => {
    const date = MicrosoftDateTimeTimeZone.toDate(d)
    return formatDate('LongNormal')(date)
  })
  const text = `This event starts on ${startText}, and ends on ${endText}.`
  const meetingButton = !!meetingURL && <MeetingButton href={meetingURL} />
  const onClickPMMeetingButton = () => history.push(getRelativeURLKeepingQuerySearch.meeting(getId()))

  const pmMeetingButton = !!meetingURL && <PMMeetingButton disabled={!getId()} onClick={onClickPMMeetingButton} />
  return (
    <>
      <Text>
        <span className={cancelledCN}>CANCELLED. </span>
        {text}
      </Text>
      {pmMeetingButton}
      {meetingButton}
      <NotesContainer order="200" className={notesCN}>
        <Notes value={getNotes()} />
      </NotesContainer>
    </>
  )
}

export const EventContent = React.memo(Compo)

EventContent.propTypes = {
  resource: PropTypes.object.isRequired,
}
