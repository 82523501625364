import {
  API_EVENT,
  isPMApiDetailedAction,
  parseApiActionType,
  PERSIST_REHYDRATE_ACTION_TYPE,
} from '../helpers/reducerHelper'
import { PM_API_RESOURCES } from '../constants'
import { GENERIC_ACTION_TYPE } from '../actions/genericActions'
import { Map, Record } from 'immutable'
import { CUSTOM_FILTERS_REDUCER_KEYS as KEYS } from './customFiltersReducerKeys'
import _ from 'lodash'
import customFilterHelper from '../helpers/customFilterHelper'

const Base = Record({
  [KEYS.BY_ID]: Map(),
})

const storeCustomFilters = (state, customFilters) => {
  return state.withMutations(_st => {
    _st.setIn([KEYS.BY_ID], Map())
    _.each(customFilters, customFilter => {
      _st.setIn([KEYS.BY_ID, customFilter.id], customFilterHelper.create(customFilter))
    })
    return _st
  })
}

const rehydrate = customFilters => {
  const byId = customFilters.get(KEYS.BY_ID)
  const mappedById = byId.map(f => customFilterHelper.create(f))
  customFilters = customFilters.set(KEYS.BY_ID, mappedById)
  return Base(customFilters)
}

export const customFilters = (state = Base(), action) => {
  const { type, payload, meta } = action
  const apiAction = parseApiActionType(type)
  if (isPMApiDetailedAction(apiAction)) {
    const { resource, event } = apiAction
    if (event === API_EVENT.SUCCESS) {
      switch (resource) {
        case PM_API_RESOURCES.CUSTOM_FILTERS:
          state = storeCustomFilters(state, payload)
          break
        case PM_API_RESOURCES.CUSTOM_FILTERS_POST:
          state = state.setIn([KEYS.BY_ID, payload.id], customFilterHelper.create(payload))
          break
        case PM_API_RESOURCES.CUSTOM_FILTERS_DELETE:
          state = state.deleteIn([KEYS.BY_ID, meta.customFilterId])
          break
      }
    }
  } else {
    switch (type) {
      case PERSIST_REHYDRATE_ACTION_TYPE:
        {
          if (payload.customFilters) {
            state = rehydrate(payload.customFilters)
          }
        }
        break
      case GENERIC_ACTION_TYPE.CLEAR_ALL:
        {
          state = Base()
        }
        break
    }
  }

  return state
}
