import _ from 'lodash'

export const getLinkFromEvent = evt => {
  const { target } = evt
  // https://stackoverflow.com/questions/39245488/event-path-undefined-with-firefox-and-vue-js
  const path = evt.path || (evt.composedPath && evt.composedPath())
  const directHref = target.href
  if (directHref) {
    return directHref
  }
  const anchor = _.find(path, element => element.href)
  return _.get(anchor, 'href')
}
