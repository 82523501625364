import _ from 'lodash'
import { fromJS, OrderedSet, Set } from 'immutable'
import { ACTIONS, HOME_DROPDOWN_OPTIONS } from '../actions/uiActions'
import { API_EVENT, generateActionTypeString, PERSIST_REHYDRATE_ACTION_TYPE } from '../common/src/helpers/reducerHelper'
import { GENERIC_ACTION_TYPE } from '../common/src/actions/genericActions'
import { UI_KEYS as KEYS, VIEWPORT } from './uiKeys'
import { APIS, PM_API_RESOURCES } from '../common/src/constants'
import { RECENTLY_VISITED_LIST_SIZE } from '../environment'
import { NAME } from '../common/src/environment'
import * as queryParamsHelper from '../helpers/queryParamsHelper'
import { ItemDisplayMode, ProjectDisplayMode } from '@/constants/ui'
import { isMobile } from '@/common/src/helpers/platformHelper'
import { CalendarColorName } from '@/views/calendar/PMCalendar.Common'

const LAST_SEEN_SUCCESS = generateActionTypeString(
  APIS.PM,
  PM_API_RESOURCES.ALL_COMMENTS_WITH_ITEM_SUMMARY,
  API_EVENT.SUCCESS
)
const RECENTLY_VISITED_SUCCESS = generateActionTypeString(
  APIS.PM,
  PM_API_RESOURCES.RECENTLY_VISITED_COMMENTS,
  API_EVENT.SUCCESS
)

const PROJECT_SUMMARIES_SUCCESS = generateActionTypeString(
  APIS.PM,
  PM_API_RESOURCES.ALL_PROJECT_SUMMARIES,
  API_EVENT.SUCCESS
)

const commentsToLastSeenArray = _.flow([_.partial(_.map, _, c => c.item_summary.id), _.reverse])

const base = fromJS({
  [KEYS.IS_FIRST_LAUNCH]: true,
  [KEYS.SELECTION]: {
    [KEYS.SELECTION_MEETING]: void 0,
    [KEYS.SELECTION_NOTIFICATION]: undefined,
  },
  [KEYS.RECENT]: {
    [KEYS.RECENT_ITEMS]: OrderedSet(),
  },
  [KEYS.COMMENTS_NOT_SEND]: {},
  [KEYS.IS_STATE_REHYDRATED]: false,
  [KEYS.PREFERS_MATRIX_MODE]: undefined,
  [KEYS.VIEWPORT_SCALE]: queryParamsHelper.isEmbeddedOnIos() ? 1.1 : 1,
  [KEYS.ONE_ON_ONE]: {},
  [KEYS.PER_SESSION]: {
    [KEYS.DID_TOGGLE_MEETING_ITEM]: false,
  },
  [KEYS.GROWTH_INVITED_EMAIL_SET]: Set(),
  [KEYS.SHOWED_COMPLETED_ITEM_HELP]: false,
  [KEYS.SHOW_TRIAL_MESSAGE_BAR]: true,
  [KEYS.NUMBER_OF_PROJECTS]: undefined,
  [KEYS.HOME_AGENDA_MODE]: HOME_DROPDOWN_OPTIONS.OWNED,
  [KEYS.HOME_MY_ITEMS_MODE]: HOME_DROPDOWN_OPTIONS.OWNED,
  [KEYS.ITEM_MULTIPLE_SELECTION_ON]: false,
  [KEYS.MULTIPLE_SELECTED_ITEMS]: Set(),
  [KEYS.PREFERS_DARK_MODE]: 'system',
  [KEYS.LAST_DUE_TIMESTAMP_CREATED]: undefined,
  [KEYS.LAST_START_TIMESTAMP_CREATED]: undefined,
  [KEYS.AI_MESSAGE_DISMISSED]: false,
  [KEYS.PM_PLATFORM]: NAME,
  [KEYS.PROJECTS_PANEL_SHOW_ITEM_COUNTS]: false,
  [KEYS.PROJECTS_DISPLAY_MODE]: isMobile() ? ProjectDisplayMode.Grid : ProjectDisplayMode.List,
  [KEYS.WEBINAR_REMINDER_ENABLED]: true,
  [KEYS.LAST_WEBINAR_ID_REMINDED]: undefined,
  [KEYS.DISMISS_TRIAL_MESSAGE_BAR_ISO_TIMESTAMP]: '1970-01-01T00:00:00.000Z',
  [KEYS.ITEMS_DISPLAY_MODE]: ItemDisplayMode.Default,
  [KEYS.CALENDAR_COLOR_MAPPING]: {},
  [KEYS.ITEMS_SHOW_AVATARS]: true,
})

const recentPath = [KEYS.RECENT, KEYS.RECENT_ITEMS]

const keysToAvoidPersisting = Set([
  KEYS.PER_SESSION,
  KEYS.SHOW_TRIAL_MESSAGE_BAR,
  KEYS.ITEM_MULTIPLE_SELECTION_ON,
  KEYS.MULTIPLE_SELECTED_ITEMS,
])

const handlers = {
  [PERSIST_REHYDRATE_ACTION_TYPE]: (state, { payload }) => {
    if (payload.ui) {
      state = state.mergeWith((prev, next, key) => {
        if (keysToAvoidPersisting.has(key)) {
          return prev
        }
        if (key === KEYS.CALENDAR_COLOR_MAPPING) {
          //solve conflict due to different shape. 2024/03/18
          const sample = next.valueSeq().first()
          const isUpdated = typeof sample?.display === 'boolean'
          if (!isUpdated) return prev
        }
        return next
      }, payload.ui)
    }
    state = state.set(KEYS.IS_STATE_REHYDRATED, true)
    return state.setIn(recentPath, OrderedSet())
  },
  [GENERIC_ACTION_TYPE.CLEAR_ALL]: () => {
    return base.set(KEYS.IS_STATE_REHYDRATED, true)
  },
  [ACTIONS.SET_IS_FIRST_LAUNCH]: state => {
    return state.set(KEYS.IS_FIRST_LAUNCH, false)
  },
  [ACTIONS.TOGGLE_ITEM_IN_MEETING_NOTES]: state => {
    return state.setIn([KEYS.PER_SESSION, KEYS.DID_TOGGLE_MEETING_ITEM], true)
  },
  [ACTIONS.ADD_ITEM_ID_TO_RECENTLY_VISITED]: (state, { payload }) => {
    const itemId = payload
    let recentItems = state.getIn(recentPath)
    recentItems = recentItems.delete(itemId).add(itemId).takeLast(RECENTLY_VISITED_LIST_SIZE)
    return state.setIn(recentPath, recentItems)
  },
  [ACTIONS.SELECT_MEETING]: (state, { payload: id }) => {
    return state.setIn([KEYS.SELECTION, KEYS.SELECTION_MEETING], id)
  },
  [PROJECT_SUMMARIES_SUCCESS]: (state, { payload }) => {
    const n = payload?.objects?.length ?? 0
    state = state.set(KEYS.NUMBER_OF_PROJECTS, n)
    if (n > 0) {
      state = assignCalendarColorsToIncomingProjects(state, payload.objects)
    }
    return state
  },
  [LAST_SEEN_SUCCESS]: (state, { payload }) => {
    const { objects } = payload
    const lastSeen = commentsToLastSeenArray(objects)
    const idSet = OrderedSet(lastSeen).takeLast(RECENTLY_VISITED_LIST_SIZE)
    return state.setIn(recentPath, idSet)
  },
  [RECENTLY_VISITED_SUCCESS]: (...a) => handlers[LAST_SEEN_SUCCESS](...a),
  [ACTIONS.UPDATE_COMMENT_NOT_SEND]: (state, { payload }) => {
    const { text, itemID } = payload
    const keyPath = [KEYS.COMMENTS_NOT_SEND, itemID]
    return text ? state.setIn(keyPath, text) : state.removeIn(keyPath)
  },
  [ACTIONS.INCREASE_VIEWPORT_SCALE]: state => {
    const current = state.get(KEYS.VIEWPORT_SCALE)
    const next = current + VIEWPORT.RATE
    return state.set(KEYS.VIEWPORT_SCALE, _.min([next, VIEWPORT.MAX]))
  },
  [ACTIONS.DECREASE_VIEWPORT_SCALE]: state => {
    const current = state.get(KEYS.VIEWPORT_SCALE)
    const next = current - VIEWPORT.RATE
    return state.set(KEYS.VIEWPORT_SCALE, _.max([next, VIEWPORT.MIN]))
  },
  [ACTIONS.SET_ONE_ON_ONE_LAST_COLLABORATOR]: (state, { payload }) => {
    const { location, email } = payload
    return state.setIn([KEYS.ONE_ON_ONE, location], email)
  },
  [ACTIONS.SET_MATRIX_VIEW_MODE]: (state, { payload }) => {
    const { mode } = payload
    return state.setIn([KEYS.PREFERS_MATRIX_MODE], mode)
  },
  [ACTIONS.INVITE_GROWTH_USER]: (state, { payload }) => {
    const { email } = payload
    const emailSet = state.getIn([KEYS.GROWTH_INVITED_EMAIL_SET])
    const updatedSet = emailSet.add(email)
    return state.setIn([KEYS.GROWTH_INVITED_EMAIL_SET], updatedSet)
  },
  [ACTIONS.SHOW_COMPLETED_ITEM_HELP]: state => {
    return state.setIn([KEYS.SHOWED_COMPLETED_ITEM_HELP], true)
  },
  [ACTIONS.SELECT_NOTIFICATION]: (state, { payload: id }) => {
    return state.setIn([KEYS.SELECTION, KEYS.SELECTION_NOTIFICATION], id)
  },
  [ACTIONS.SET_SHOW_TRIAL_MESSAGE_BAR]: (state, { payload: display }) => {
    return state.setIn([KEYS.SHOW_TRIAL_MESSAGE_BAR], display)
  },
  [ACTIONS.SET_HOME_AGENDA_MODE]: (state, { payload: mode }) => {
    return state.setIn([KEYS.HOME_AGENDA_MODE], mode)
  },
  [ACTIONS.SET_HOME_MY_ITEMS_MODE]: (state, { payload: mode }) => {
    return state.setIn([KEYS.HOME_MY_ITEMS_MODE], mode)
  },
  [ACTIONS.SET_ITEM_SELECTED]: (state, { payload }) => {
    const { itemId, selected } = payload
    const itemsSet = state.getIn([KEYS.MULTIPLE_SELECTED_ITEMS])
    const updatedSet = selected ? itemsSet.add(itemId) : itemsSet.delete(itemId)
    return state.setIn([KEYS.MULTIPLE_SELECTED_ITEMS], updatedSet)
  },
  [ACTIONS.SET_ITEMS_SELECTED]: (state, { payload }) => {
    const { itemIds } = payload
    return state.setIn([KEYS.MULTIPLE_SELECTED_ITEMS], new Set(itemIds))
  },
  [ACTIONS.CLEAR_MULTIPLE_SELECTION]: state => {
    return state.setIn([KEYS.MULTIPLE_SELECTED_ITEMS], new Set())
  },
  [ACTIONS.SET_MULTIPLE_SELECTION_ON]: (state, { payload: on }) => {
    return state.setIn([KEYS.ITEM_MULTIPLE_SELECTION_ON], on)
  },
  [ACTIONS.SET_PREFERS_DARK_MODE]: (state, { payload: prefersDarkMode }) => {
    return state.setIn([KEYS.PREFERS_DARK_MODE], prefersDarkMode)
  },
  [ACTIONS.SET_LAST_DUE_TIMESTAMP_CREATED]: (state, { payload: date }) => {
    return state.setIn([KEYS.LAST_DUE_TIMESTAMP_CREATED], date)
  },
  [ACTIONS.SET_LAST_START_TIMESTAMP_CREATED]: (state, { payload: date }) => {
    return state.setIn([KEYS.LAST_START_TIMESTAMP_CREATED], date)
  },
  [ACTIONS.DISMISS_AI_MESSAGE]: state => {
    return state.setIn([KEYS.AI_MESSAGE_DISMISSED], true)
  },
  [ACTIONS.SET_PM_PLATFORM]: (state, { payload: platform }) => {
    return state.setIn([KEYS.PM_PLATFORM], platform)
  },
  [ACTIONS.SET_PROJECTS_PANEL_SHOW_ITEM_COUNTS]: (state, { payload: show }) => {
    return state.setIn([KEYS.PROJECTS_PANEL_SHOW_ITEM_COUNTS], show)
  },
  [ACTIONS.SET_WEBINAR_REMINDER_ENABLED]: (state, { payload: enabled }) => {
    return state.setIn([KEYS.WEBINAR_REMINDER_ENABLED], enabled)
  },
  [ACTIONS.SET_LAST_WEBINAR_ID_REMINDED]: (state, { payload: id }) => {
    return state.setIn([KEYS.LAST_WEBINAR_ID_REMINDED], id)
  },
  [ACTIONS.DISMISS_TRIAL_MESSAGE_BAR]: state => {
    return state.setIn([KEYS.DISMISS_TRIAL_MESSAGE_BAR_ISO_TIMESTAMP], new Date().toISOString())
  },
  [ACTIONS.SET_PROJECTS_DISPLAY_MODE]: (state, { payload: mode }) => {
    return state.setIn([KEYS.PROJECTS_DISPLAY_MODE], mode)
  },
  [ACTIONS.SET_ITEMS_DISPLAY_MODE]: (state, { payload: mode }) => {
    return state.setIn([KEYS.ITEMS_DISPLAY_MODE], mode)
  },
  [ACTIONS.SET_CALENDAR_INFO_FOR_PROJECT]: (state, { payload: { idd, ...rest } }) => {
    const prev = state.getIn([KEYS.CALENDAR_COLOR_MAPPING, idd])
    return state.setIn([KEYS.CALENDAR_COLOR_MAPPING, idd], { ...prev, ...rest })
  },
  [ACTIONS.SET_CALENDAR_INFO_DISPLAY_ALL]: (state, { payload: { display } }) => {
    const prevMap = state.getIn([KEYS.CALENDAR_COLOR_MAPPING])
    const nextMap = prevMap.withMutations(all => {
      for (const k of all.keys()) {
        const next = { ...all.get(k), display }
        all.set(k, next)
      }
    })
    return state.setIn([KEYS.CALENDAR_COLOR_MAPPING], nextMap)
  },
  [ACTIONS.SET_ITEMS_SHOW_AVATARS]: (state, { payload: show }) => {
    return state.setIn([KEYS.ITEMS_SHOW_AVATARS], show)
  },
}

const assignCalendarColorsToIncomingProjects = (state, projects) => {
  const incommingSet = new Set([...projects.map(p => p.idd), 0])

  let colorMap = state.get(KEYS.CALENDAR_COLOR_MAPPING)
  const currentSet = colorMap.keySeq().toSet()
  const pendingSet = incommingSet.subtract(currentSet)

  const counts = _.countBy(colorMap.valueSeq().toArray())
  colorMap = colorMap.withMutations(cm => {
    Object.values(CalendarColorName).forEach(c => {
      if (!counts[c]) {
        counts[c] = 0
      }
    })
    pendingSet.forEach(id => {
      const [nextColor, colorCount] = _.minBy(_.toPairs(counts), pair => pair[1])
      counts[nextColor] = colorCount + 1
      cm.set(id, {
        color: nextColor,
        display: true,
      })
    })
  })

  state = state.set(KEYS.CALENDAR_COLOR_MAPPING, colorMap)
  return state
}

export const ui = (state = base, action) => {
  const fn = handlers[action.type]
  return fn ? fn(state, action) : state
}
