import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { redirectSubject } from '../../reactions'
import * as queryParamsHelper from '../../helpers/queryParamsHelper'
import { REDIRECT } from '../../common/src/environment'

const StyledIframe = styled.iframe`
  display: none;
`

// Need to open the link in an iframe,
// since Firefox aborts pending request when a navigation triggered
export const RedirectIframe = ({ children }) => {
  const [url, setURL] = useState()

  // Use useLayoutEffect because need to create subscription
  // before children are rendered
  useLayoutEffect(() => {
    const subscription = redirectSubject.subscribe(url => {
      // Temporarily disabling redirection to native. Check .env
      const redirect = REDIRECT && !queryParamsHelper.hasNoRedirect()
      if (redirect) {
        setURL(url)
      }
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [])

  return (
    <>
      <StyledIframe src={url} />
      {children}
    </>
  )
}
