import { combineReducers } from 'redux-immutable'
import {
  associatedLinks,
  auth,
  filters,
  graphResources,
  items,
  meetings,
  projects,
  request,
  search,
  sharingRequests,
  tags,
  teams,
  users,
  notifications,
  customFilters,
} from '../common/src/reducers'
import { ui } from './uiReducer'
import { location } from './locationReducer'
import { onboarding } from './onboardingReducer'
import { workload } from './workloadReducer'

const rootReducer = combineReducers({
  auth,
  users,
  items,
  projects,
  ui,
  request,
  search,
  teams,
  tags,
  sharingRequests,
  filters,
  graphResources,
  meetings,
  associatedLinks,
  onboarding,
  location,
  workload,
  notifications,
  customFilters,
})

export default rootReducer
