import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Spinner,
  Switch,
} from '@fluentui/react-components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutateProjectSubscriptions, useProjectSubscriptions } from '../../queries/projectSubscriptions'

export type ProjectSubscriptionsModalProps = {
  projectId: number
  isOpen: boolean
  onSave: () => void
  onClose: () => void
}

export const ProjectSubscriptionsModal = ({ projectId, isOpen, onSave, onClose }: ProjectSubscriptionsModalProps) => {
  const { data: subscriptions, isFetching } = useProjectSubscriptions(projectId, isOpen)
  const [subscriptionsState, setSubscriptionsState] = useState(subscriptions)
  const { isPending: postLoading, isError, mutate } = useMutateProjectSubscriptions()
  const { t } = useTranslation()
  const loading = postLoading || isFetching

  useEffect(() => {
    setSubscriptionsState(subscriptions)
  }, [projectId, subscriptions])

  const toggles = !subscriptionsState
    ? null
    : (Object.keys(subscriptionsState) as Array<keyof typeof subscriptionsState>).map(key => {
        return (
          <Switch
            key={key}
            checked={subscriptionsState[key]}
            label={t('project_subscriptions_modal.toggle_labels.' + key)}
            onChange={(evt, data) => {
              setSubscriptionsState(prev => (!prev ? undefined : { ...prev, [key]: data.checked }))
            }}
            disabled={loading}
          />
        )
      })

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(ev, data) => {
        if (!data.open) onClose()
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle className="flex gap-4">
            {t('project_subscriptions_modal.title')} {loading && <Spinner size="small" />}
          </DialogTitle>
          <DialogContent>
            <span>{t('project_subscriptions_modal.subtitle')}</span>
            <div className="mx-auto mt-2 flex w-fit flex-col">{toggles}</div>
            {isError && <span className="text-pm-negative">{t('project_subscriptions_modal.error')}</span>}
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary" onClick={onClose} disabled={loading}>
                {t('general.close')}
              </Button>
            </DialogTrigger>
            <Button
              appearance="primary"
              onClick={() => {
                if (!subscriptionsState) return
                mutate({ projectId, subscriptions: subscriptionsState }, { onSuccess: onSave })
              }}
              disabled={
                loading ||
                !subscriptionsState ||
                !subscriptions ||
                (Object.keys(subscriptionsState) as Array<keyof typeof subscriptionsState>).every(
                  key => subscriptionsState[key] === subscriptions[key]
                )
              }
            >
              {t('general.save')}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
