export const quadrantColorObjects = [
  {
    name: 'Morning sky',
    color: { r: 158, g: 218, b: 229, alpha: 1.0 },
  },
  {
    name: 'Pearskin green',
    color: { r: 219, g: 219, b: 141, alpha: 1.0 },
  },
  {
    name: 'Battleship grey',
    color: { r: 199, g: 199, b: 199, alpha: 1.0 },
  },
  {
    name: 'Pink-shirt pink',
    color: { r: 247, g: 182, b: 210, alpha: 1.0 },
  },
  {
    name: 'Cocoa brown',
    color: { r: 196, g: 156, b: 148, alpha: 1.0 },
  },
  {
    name: 'Jacaranda purple',
    color: { r: 197, g: 176, b: 213, alpha: 1.0 },
  },
  {
    name: 'Red-shirt red',
    color: { r: 225, g: 122, b: 120, alpha: 1.0 },
  },
  {
    name: 'Golf Green',
    color: { r: 122, g: 193, b: 108, alpha: 1.0 },
  },
  {
    name: 'Carrot orange',
    color: { r: 225, g: 157, b: 90, alpha: 1.0 },
  },
  {
    name: 'Night sky',
    color: { r: 144, g: 169, b: 202, alpha: 1.0 },
  },
  {
    name: 'Bright sky',
    color: { r: 109, g: 204, b: 218, alpha: 1.0 },
  },
  {
    name: 'Marsh green',
    color: { r: 205, g: 204, b: 93, alpha: 1.0 },
  },
  {
    name: 'Claymore grey',
    color: { r: 162, g: 162, b: 162, alpha: 1.0 },
  },
  {
    name: 'Bubblegum pink',
    color: { r: 237, g: 151, b: 202, alpha: 1.0 },
  },
  {
    name: 'Post brown',
    color: { r: 168, g: 120, b: 110, alpha: 1.0 },
  },
  {
    name: 'Pastel purple',
    color: { r: 173, g: 139, b: 201, alpha: 1.0 },
  },
  {
    name: 'Boxcar red',
    color: { r: 237, g: 102, b: 93, alpha: 1.0 },
  },
  {
    name: 'Leaf green',
    color: { r: 103, g: 191, b: 92, alpha: 1.0 },
  },
  {
    name: 'Orange orange',
    color: { r: 255, g: 158, b: 74, alpha: 1.0 },
  },
  {
    name: 'Chalk blue',
    color: { r: 114, g: 158, b: 206, alpha: 1.0 },
  },
]
