import React, { useCallback } from 'react'
import { Panel, PanelType } from '@fluentui/react/lib/Panel'
import _ from 'lodash'
import styled from 'styled-components'
import { cn } from '@appfluence/classnames'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { IconButton } from '@fluentui/react/lib/Button'
import { BackButton } from '../buttons/NavButtons'
import { PanelTitle as Title } from '../title/PanelTitle'

const stylesByDefault = {
  main: {
    overflow: 'hidden', // Firefox fix: avoid double scroll
  },
  navigation: {
    alignItems: 'center',
  },
  content: {
    padding: '0 16px 20px 16px',
    display: 'flex',
    flexDirection: 'column',
  },
}

const Header = styled.div`
  display: flex;
  align-content: center;
  flex: 1;
  padding-left: 16px;
  padding-right: 16px;

  justify-content: space-between;
  &.narrow {
    justify-content: flex-start;
  }
`

const CloseButton = styled(IconButton).attrs({
  iconProps: { iconName: 'Cancel' },
})`
  margin-left: 8px;
`

export const ContainerPanel = ({
  headerText,
  type = PanelType.smallFixedFar,
  isLightDismiss = true,
  children,
  styles,
  onDismiss,
  ...rest
}) => {
  const narrow = useNarrowWidth()

  const onRenderNavigationContent = useCallback(() => {
    const className = cn({ narrow })
    return (
      <Header className={className}>
        {narrow && <BackButton className="!mr-2" onClick={onDismiss} />}
        <Title>{headerText}</Title>
        {!narrow && <CloseButton onClick={onDismiss} />}
      </Header>
    )
  }, [narrow, headerText, onDismiss])

  const localDismiss = useCallback(
    evt => {
      onDismiss?.(evt)
    },
    [onDismiss]
  )

  const finalStyles = _.merge({}, stylesByDefault, styles)
  return (
    <Panel
      type={type}
      styles={finalStyles}
      isLightDismiss={isLightDismiss}
      onDismiss={localDismiss}
      onRenderNavigationContent={onRenderNavigationContent}
      {...rest}
    >
      {children}
    </Panel>
  )
}
