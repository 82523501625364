import { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { cn } from '@appfluence/classnames'
import { MainLayout } from './MainLayout'
import { MainHeader } from './MainHeader'
import { getRelativeURLKeepingQuerySearch, isInApp } from '../../helpers/routeHelper'
import { itemHelper, projectHelper } from '../../common/src/helpers'
import * as queryParamsHelper from '../../helpers/queryParamsHelper'
import MediaQuery from 'react-responsive'
import { DefaultButton, IconButton } from '@fluentui/react/lib/Button'
import { ICON_NAMES } from '../../common/src/msIcons'
import { MobileBackButton } from '../mobileNavBar/MobileNavBarButton'
import { useTranslation } from 'react-i18next'

const ButtonsContainer = styled.div``

const WebButton = styled(DefaultButton).attrs({})`
  margin-right: 12px;
  height: 25px;
  padding: 0 8px;
`

const WebIcon = styled(IconButton).attrs({
  iconProps: { iconName: ICON_NAMES.Home },
  styles: { icon: { color: 'white' } },
})``

const NativeButton = styled(DefaultButton).attrs({})`
  margin-right: 12px;
  height: 25px;
  padding: 0 8px;
`

const NativeIcon = styled(IconButton).attrs({
  iconProps: { iconName: ICON_NAMES.OpenInNewWindow },
  styles: { icon: { color: 'white' } },
})``

const getPathForObject = object => {
  if (!object) {
    return getRelativeURLKeepingQuerySearch.projects()
  } else if (projectHelper.isProject(object)) {
    const idd = projectHelper.getIdd(object)
    return getRelativeURLKeepingQuerySearch.matrixForProjectId(idd)
  } else if (itemHelper.isItem(object)) {
    const id = itemHelper.getId(object)
    return getRelativeURLKeepingQuerySearch.redirectToResource('item', id)
  }
}

const getProtocolLinkForObject = object => {
  if (!object) {
    return null
  } else if (projectHelper.isProject(object)) {
    return projectHelper.getProtocolLink(object)
  } else if (itemHelper.isItem(object)) {
    return itemHelper.getProtocolLink(object)
  }
}

export const SingleLayout = ({ main, object, history, hide_chrome = false, readOnly = false }) => {
  const { pathname } = history.location

  const inApp = isInApp(pathname)
  const hideHeader = queryParamsHelper.shouldHideHeader()
  const isEmbedded = queryParamsHelper.isEmbedded()
  const { t } = useTranslation()

  const path = getPathForObject(object)
  const onClickLogo = useMemo(() => {
    if (!path || readOnly) {
      return undefined
    }
    return () => {
      history.push(path)
    }
  }, [history, path, readOnly])

  const protocolLink = getProtocolLinkForObject(object)
  const renderButton = useCallback(
    wide => {
      if (!protocolLink) {
        return false
      }

      const nativeCN = cn({
        pm_hidden: queryParamsHelper.isEmbeddedOnTeams(),
      })

      const target = isEmbedded ? '_blank' : '_self'
      return (
        <ButtonsContainer>
          {wide ? (
            <>
              <WebButton title={t('single_project_view.web_tooltip')} onClick={onClickLogo}>
                {t('single_project_view.web_title')}
              </WebButton>
              <NativeButton
                title={t('single_project_view.native_tooltip')}
                href={protocolLink}
                target={target}
                className={nativeCN}
              >
                {t('single_project_view.native_title')}
              </NativeButton>
            </>
          ) : (
            <>
              <MobileBackButton />
              {!readOnly && <WebIcon title={t('single_project_view.web_tooltip')} onClick={onClickLogo} />}
              <NativeIcon href={protocolLink} target={target} className={nativeCN} />
            </>
          )}
        </ButtonsContainer>
      )
    },
    [protocolLink, isEmbedded, t, onClickLogo, readOnly]
  )

  const header =
    !inApp && !hideHeader && !hide_chrome ? (
      <MainHeader onClickLogo={onClickLogo}>
        <MediaQuery minWidth={600}>{renderButton}</MediaQuery>
      </MainHeader>
    ) : undefined

  return <MainLayout header={header} main={main} fixed={true} />
}
