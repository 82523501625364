import { memo, Suspense, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { itemHelper } from '../../common/src/helpers'
import { isInitializedRequestForPMResource } from '../../common/src/selectors/requestStateSelectors'
import { PM_API_RESOURCES } from '../../common/src/constants'
import { Loading } from '../../components/basic/Loading'
import { useRandomValueFromArray } from '../../hooks/arrayHooks'
import { isEmbeddedOnTeams } from '../../helpers/queryParamsHelper'
import { BasicItemList } from '../../components/item/basicList/BasicItemList'
import { useTranslatedPlaceholders } from '../../hooks/translationHooks'
import { LazyIViewPlaceholder } from '../../components/placeholder/LazyIViewPlaceholder'
import { useSelectedItemId } from '../../hooks/PMHooks'
import { useAttentionNeededQuery } from '@/queries/items'

const HomeAlerts_ = ({ onSelectItem, items, setForceAlertId }) => {
  useAttentionNeededQuery()
  const loading = useSelector(state => !isInitializedRequestForPMResource(state, PM_API_RESOURCES.ATTENTION_NEEDED))
  const selectedItemId = useSelectedItemId()

  const placeholders = useTranslatedPlaceholders('alerts.placeholder_', 7)
  const validPlaceholders = useMemo(() => {
    const allPlaceholders = placeholders.map((p, i) => (i === 6 ? { ...p, type: 3 } : p))
    return !isEmbeddedOnTeams() ? allPlaceholders.slice(0, -1) : allPlaceholders
  }, [placeholders])
  const placeholder = useRandomValueFromArray(validPlaceholders)

  const customOnSelectItem = useCallback(
    item => {
      const id = itemHelper.getId(item)
      setForceAlertId(id)
      onSelectItem(item)
    },
    [setForceAlertId, onSelectItem]
  )

  if (!loading && !items.length) {
    return (
      <Suspense fallback={<Loading />}>
        <LazyIViewPlaceholder {...placeholder} />
      </Suspense>
    )
  }

  return (
    <BasicItemList
      items={items}
      selectedItemId={selectedItemId}
      onClickItem={customOnSelectItem}
      loading={loading}
      shouldShowProject
    />
  )
}

export const HomeAlerts = memo(HomeAlerts_)
