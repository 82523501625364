import { ActionButton, IconButton, PrimaryButton } from '@fluentui/react/lib/Button'
import { TextField } from '@fluentui/react/lib/TextField'
import { SUBSCRIBE_CALENDAR_URL } from '../../common/src/constants'
import { useTranslation } from 'react-i18next'
import { TooltipHost } from '@/components/tooltip/TooltipHost'

const calendarLinkTextFieldStyles = {
  field: {
    cursor: 'pointer',
  },
}

const getCalendarLinkButtonStyles = ({ loading }) => ({
  root: {
    cursor: loading ? 'progress' : 'pointer',
  },
})

const calendarInfoButtonStyles = {
  flexContainer: {
    justifyContent: 'center',
  },
}

const CopyLinkTooltipHost = ({ children, ...rest }) => {
  const { t } = useTranslation()
  return (
    <TooltipHost content={t('project_detail.copy_calendar_link')} {...rest}>
      {children}
    </TooltipHost>
  )
}

export const CalendarLinkSection = ({ getCalendarLink, calendarLink, copyCalendarLink, isLoadingCalendarLink }) => {
  const { t } = useTranslation()
  return (
    <div className="mb-2 flex flex-col">
      {calendarLink ? (
        <div className="flex">
          <CopyLinkTooltipHost>
            <IconButton iconProps={{ iconName: 'Copy' }} onClick={copyCalendarLink} />
          </CopyLinkTooltipHost>
          <CopyLinkTooltipHost styles={{ root: { flex: 1 } }}>
            <TextField value={calendarLink} readOnly styles={calendarLinkTextFieldStyles} onClick={copyCalendarLink} />
          </CopyLinkTooltipHost>
        </div>
      ) : (
        <PrimaryButton
          iconProps={{ iconName: 'Calendar' }}
          onClick={getCalendarLink}
          styles={getCalendarLinkButtonStyles({ loading: isLoadingCalendarLink })}
        >
          {t('project_detail.get_calendar_link')}
        </PrimaryButton>
      )}
      <ActionButton
        iconProps={{ iconName: 'Info' }}
        href={SUBSCRIBE_CALENDAR_URL}
        target="_blank"
        styles={calendarInfoButtonStyles}
      >
        {t('project_detail.subscribe_calendar_link')}
      </ActionButton>
    </div>
  )
}
