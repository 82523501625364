import React, { useCallback, useContext } from 'react'
import styled from 'styled-components'
import { cn } from '@appfluence/classnames'
import { LeftPanelButton } from './LeftPanelButton'
import { useSharingRequestsForMe } from '../../common/src/hooks/sharingRequestsHooks'
import { InvitationsModalContext } from '../../contexts'

const Container = styled(LeftPanelButton)`
  &.hidden {
    display: none;
  }
`

export const LeftPanelPendingRequestsButton = ({ className, history, ...rest }) => {
  const count = useSharingRequestsForMe().size
  const invitationsModalContext = useContext(InvitationsModalContext)

  const onClick = useCallback(() => {
    invitationsModalContext.setIsOpen(true)
  }, [invitationsModalContext])

  const containerClassName = cn(className, { hidden: count === 0 })
  return (
    <>
      <Container count={count} onClick={onClick} className={containerClassName} {...rest} />
    </>
  )
}
