import { Accordion } from '../../components/accordion/Accordion'
import { customFilterHelper } from '../../common/src/helpers'
import { setCustomFilter } from '../../common/src/actions/filtersActions'
import { deleteCustomFilter } from '../../common/src/actions/customFiltersAPI'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCustomFiltersMap } from '../../common/src/selectors/customFiltersSelectors'
import { useTranslation } from 'react-i18next'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent as trackEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { Button, Input, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Tooltip } from '@fluentui/react-components'
import { ChevronDownRegular } from '@fluentui/react-icons'
import { Delete, Save } from '@/components/BundledIcons'

export const ItemCustomFilters = ({ onSaveCustomFilter, filtersType, sortTypeBlacklist, className }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const customFilters = useSelector(getCustomFiltersMap)
  const [customFilterName, setCustomFilterName] = useState('')
  const saveCustomFilter = () => {
    onSaveCustomFilter(customFilterName)
    setCustomFilterName('')
    dispatch(trackEvent(AMPLITUDE_ACTION_TYPES.CREATE_CUSTOM_FILTER))
  }
  const handleOpenChange = isOpen => {
    if (isOpen) {
      dispatch(trackEvent(AMPLITUDE_ACTION_TYPES.OPEN_CUSTOM_FILTERS))
    }
  }
  return (
    <Accordion title={t('item_filters.custom_filters.title')} onOpenChange={handleOpenChange} className={className}>
      <div className="mb-1 flex flex-wrap gap-x-2">
        {customFilters.toArray().map(filter => {
          const incompatible = sortTypeBlacklist?.includes(customFilterHelper.getSort(filter))
          return (
            <span key={customFilterHelper.getId(filter)} className="flex items-center">
              <Tooltip
                content={incompatible ? t('item_filters.custom_filters.incompatible') : undefined}
                visible={incompatible ? undefined : false}
                relationship="description"
              >
                <Button
                  appearance="transparent"
                  disabled={incompatible}
                  onClick={() => {
                    dispatch(setCustomFilter(filter, filtersType))
                    dispatch(trackEvent(AMPLITUDE_ACTION_TYPES.SET_CUSTOM_FILTER))
                  }}
                  className="!min-w-0 !px-2"
                >
                  {customFilterHelper.getName(filter)}
                </Button>
              </Tooltip>
              <div className="h-3 w-[1px] bg-pm-neutral-light" />
              <Menu>
                <MenuTrigger>
                  <Button appearance="transparent" size="small" icon={<ChevronDownRegular />} />
                </MenuTrigger>
                <MenuPopover>
                  <MenuList>
                    <MenuItem
                      icon={<Delete />}
                      onClick={() => {
                        dispatch(deleteCustomFilter(customFilterHelper.getId(filter)))
                      }}
                    >
                      {t('general.delete')}
                    </MenuItem>
                  </MenuList>
                </MenuPopover>
              </Menu>
            </span>
          )
        })}
      </div>
      <div className="flex gap-1 pb-2">
        <Input
          className="flex-1"
          placeholder={t('item_filters.custom_filters.textfield_placeholder')}
          value={customFilterName}
          onChange={(e, data) => setCustomFilterName(data.value)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              saveCustomFilter()
            }
          }}
        />
        <Tooltip content={t('general.save')} relationship="label">
          <Button appearance="transparent" icon={<Save />} onClick={saveCustomFilter} />
        </Tooltip>
      </div>
    </Accordion>
  )
}
