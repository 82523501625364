import _ from 'lodash'
import platform from 'platform'

export const isMobile = () => {
  const family = platform.os.family
  return _.includes(family, 'iOS') || _.includes(family, 'Android') || _.includes(family, 'Windows Phone')
}

export const isIOS = () => {
  const family = platform.os.family
  return _.includes(family, 'iOS')
}

export const isDesktopSafari = () => platform.name === 'Safari'
export const isInternetExplorer = () => platform.name === 'IE'
