import React, { forwardRef, memo } from 'react'
import { UserCollectionCell } from '../../components/users/usersCollection/UserCollectionCell'
import { useTranslation } from 'react-i18next'
import { cn } from '@appfluence/classnames'

export const OneOnOnePersona = memo(
  forwardRef(({ readOnly = false, className, ...rest }, ref) => {
    const { t } = useTranslation()
    return (
      <UserCollectionCell
        placeholder={t('one_on_one.user_selection_header')}
        readOnly={readOnly}
        className={cn(
          'box-border rounded-sm border border-solid border-pm-neutral-secondary p-1 hover:bg-pm-neutral-lighter',
          readOnly && 'hover:border-none hover:bg-transparent',
          className
        )}
        ref={ref}
        {...rest}
      />
    )
  })
)
