import {
  Tag,
  TagPicker,
  TagPickerControl,
  TagPickerGroup,
  TagPickerInput,
  TagPickerList,
  TagPickerOption,
  type TagPickerProps,
} from '@fluentui/react-components'
import { useEffect, useState } from 'react'
import { useTags } from '@/queries/tags'

export type SuggestionsTagPickerProps = {
  selectedTags: string[]
  disabled?: boolean
  onAddTag: (tag: string) => void
  onRemoveTag: (tag: string) => void
  className?: string
  type?: 'item' | 'project'
  autoFocus?: boolean
}

export const SuggestionsTagPicker = ({
  selectedTags,
  disabled,
  onAddTag,
  onRemoveTag,
  className,
  type = 'item',
  autoFocus,
}: SuggestionsTagPickerProps) => {
  const [query, setQuery] = useState('')
  const trimmedQuery = query.trim()
  const { data: allTags, hasNextPage, fetchNextPage, isFetching } = useTags(type)

  const filteredTags = allTags?.filter(
    tag => tag.name.toLowerCase().includes(trimmedQuery.toLowerCase()) && !selectedTags.includes(tag.name)
  )

  useEffect(() => {
    if (!isFetching && hasNextPage) {
      fetchNextPage().then()
    }
  }, [fetchNextPage, hasNextPage, isFetching])

  const onOptionSelect: TagPickerProps['onOptionSelect'] = (e, data) => {
    const f = data.selectedOptions.includes(data.value) ? onAddTag : onRemoveTag
    f(data.value)
    setQuery('')
  }

  return (
    <TagPicker onOptionSelect={onOptionSelect} selectedOptions={selectedTags} disabled={disabled}>
      <TagPickerControl autoFocus={autoFocus} className={className}>
        <TagPickerGroup>
          {selectedTags.map(tag => (
            <Tag key={tag} shape="rounded" value={tag}>
              {tag}
            </Tag>
          ))}
        </TagPickerGroup>
        <TagPickerInput
          aria-label="Select Tags"
          value={query}
          onChange={event => setQuery(event.target.value)}
          onBlur={event => {
            const trimmedValue = event.target.value.trim()
            if (trimmedValue && !selectedTags.includes(trimmedValue)) {
              onAddTag(trimmedValue)
              setQuery('')
            }
          }}
        />
      </TagPickerControl>
      <TagPickerList>
        {!!trimmedQuery &&
          !selectedTags.includes(trimmedQuery) &&
          !filteredTags?.some(tag => tag.name === trimmedQuery) && (
            <TagPickerOption value={trimmedQuery}>{trimmedQuery}</TagPickerOption>
          )}
        {filteredTags?.map(tag => (
          <TagPickerOption key={tag.id} value={tag.name}>
            {tag.name}
          </TagPickerOption>
        ))}
      </TagPickerList>
    </TagPicker>
  )
}
