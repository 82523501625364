import { ITEMS_SORT_TYPE } from '@/common/src/actions/filtersActions'

export const ITEM_COLUMN_KEYS = {
  SELECT: 'SELECT',
  QUADRANT: ITEMS_SORT_TYPE.QUADRANT,
  ICON: ITEMS_SORT_TYPE.ICON,
  OWNER: ITEMS_SORT_TYPE.OWNER_USERNAME,
  NAME: ITEMS_SORT_TYPE.NAME,
  PROGRESS: ITEMS_SORT_TYPE.COMPLETION,
  LAST_MODIFIED_TIMESTAMP: ITEMS_SORT_TYPE.LAST_MODIFIED_TIMESTAMP,
  DUE_DATE: ITEMS_SORT_TYPE.DUE_DATE,
  START_DATE: ITEMS_SORT_TYPE.START_DATE,
  EFFORT: ITEMS_SORT_TYPE.EFFORT,
  COMPLETION_DATE: ITEMS_SORT_TYPE.COMPLETION_DATE,
  CREATOR: ITEMS_SORT_TYPE.CREATOR_USERNAME,
  CREATION_DATE: ITEMS_SORT_TYPE.CREATION_DATE,
} as const
