import { useEffect, useRef } from 'react'

export const useTimeout = () => {
  const _timer = useRef(null)
  const clear = () => {
    if (_timer.current) {
      clearTimeout(_timer.current)
      _timer.current = null
    }
  }
  const set = (fn, delay) => {
    clear()
    _timer.current = setTimeout(() => {
      fn()
      clear()
    }, delay)
  }
  const isSet = () => !!_timer.current
  //with the next effect we clear the timeout when the component is unmounted
  useEffect(() => () => clear(), [])
  return { clear, set, isSet }
}
