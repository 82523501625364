import _ from 'lodash'
import stringHash from 'string-hash'
import { registerApnsNotifications, registerGcmNotifications } from './common/src/actions/pushNotificationAPI'
import { isInitializedRequestForPMResource } from './common/src/selectors/requestStateSelectors'
import { PM_API_RESOURCES } from './common/src/constants'
import { linkSubject, SOURCES } from './reactions/linkSubject'

export const registerPublicMethods = store => {
  window.registerAndroidPushNotifications = ({ token, deviceId, name }) => {
    const state = store.getState()
    const isTokenSent = isInitializedRequestForPMResource(state, PM_API_RESOURCES.DEVICE_GCM_POST)
    if (isTokenSent) {
      return false
    }
    const uniqInteger = _.isInteger(deviceId) ? deviceId : stringHash(deviceId)
    store.dispatch(
      registerGcmNotifications({
        token,
        deviceId: _.toString(uniqInteger),
        name,
      })
    )
    return true
  }

  window.registerIosPushNotifications = ({ token, deviceId, name }) => {
    const state = store.getState()
    const isTokenSent = isInitializedRequestForPMResource(state, PM_API_RESOURCES.DEVICE_APNS_POST)
    if (isTokenSent) {
      return false
    }
    store.dispatch(
      registerApnsNotifications({
        token,
        deviceId, // it's supposed to be an UUID
        name,
      })
    )
    return true
  }

  window.openItem = id => {
    linkSubject.next({
      urlData: {
        id,
        type: 'item',
      },
      source: SOURCES.WINDOW_OPEN,
    })
  }
}
