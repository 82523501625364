import React from 'react'
import styled from 'styled-components'
import { Image } from '@fluentui/react/lib/Image'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 4px 0 4px;
`

const Icon = styled(Image).attrs({
  height: 16,
  width: 16,
})``

const Text = styled.span`
  vertical-align: middle;
  display: inline-block;
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const UserGroupsOption = ({ imageSrc, text, ...rest }) => {
  const textStyle = imageSrc ? { marginLeft: '4px' } : null
  return (
    <Container {...rest}>
      {imageSrc && <Icon src={imageSrc} />}
      <Text style={textStyle}>{text}</Text>
    </Container>
  )
}
