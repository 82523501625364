import { useNarrowWidth } from '../../../hooks/useNarrowWidth'
import { useRouteId } from '../../../hooks/useRouteId'
import { ROUTE_ID } from '../../../routes/routeIdList'
import { isInternetExplorer } from '../../../common/src/helpers/platformHelper'
import { IENotSupportedView } from '../../IENotSupportedView'
import { ReportsMobilePlaceholder } from '../../reports/ReportsMobilePlaceholder'
import { RightDetailView } from '../../rightDetailView/RightDetailView'
import { MainLayout } from '../../../components/layout/MainLayout'
import { EffortPlanning } from './EffortPlanning'

export const EffortPlanningView = () => {
  const narrowWidth = useNarrowWidth()
  const routeId = useRouteId()
  const displayOptionsMenu = routeId !== ROUTE_ID.CALENDAR_TEAMS
  const components = {
    main: isInternetExplorer() ? (
      <IENotSupportedView />
    ) : narrowWidth ? (
      <ReportsMobilePlaceholder />
    ) : (
      <EffortPlanning />
    ),
    rightSide: <RightDetailView displayOptionsMenu={displayOptionsMenu} />,
  }
  return <MainLayout {...components} />
}
