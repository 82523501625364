import { useMemo } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { useMeEmail } from '../../common/src/hooks/usersHooks'
import { BaseFlexRow } from '../../components/layout/FlexContainer'
import { GrowthPersonaCard } from '../../components/card/GrowthPersona/GrowthPersonaCard'
import { GhostGrowthPersonaCard } from '../../components/card/GrowthPersona/GhostGrowthPersonaCard'
import { parseEmail } from '../../common/src/helpers/stringHelper'
import { GrowthList } from './GrowthList'
import { sortBy } from '@/utils/arrayUtils'

const Container = styled.div`
  min-height: 100%;
  width: 100%;
  margin: 0;
`

const CardsSection = styled(BaseFlexRow)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 380px));
  grid-gap: 1rem;
  justify-content: space-around;
`

const getBaseGroups = () => ({
  inPM: [],
  sameDomain: [],
  others: [],
})

const useGroupGraphCollaborators = ({ graphCollaborators, meEmail }) => {
  return useMemo(() => {
    const myDomain = parseEmail(meEmail)?.domain
    const sameDomain = e => parseEmail(e)?.domain === myDomain
    const groups = graphCollaborators.reduce((acc, collab, email) => {
      const pmId = collab?.get('id')
      const isPmUser = !!pmId
      const graphProfile = collab.get('graphProfile')
      if (graphProfile && isPmUser) {
        acc.inPM.push(collab)
      } else if (sameDomain(email)) {
        acc.sameDomain.push(collab)
      } else {
        acc.others.push(collab)
      }
      return acc
    }, getBaseGroups())
    return _.mapValues(groups, arr => {
      return sortBy(arr, col => {
        const graphProfile = col.get('graphProfile')
        return _.toLower(graphProfile.get('mail'))
      })
    })
  }, [graphCollaborators, meEmail])
}

const useGroupPMCollaborators = ({ collaborators, meEmail }) => {
  return useMemo(() => {
    const groups = collaborators.reduce((acc, collab) => {
      const teammate = collab?.get('is_teammate') || collab?.get('teammate')
      if (teammate) {
        acc.inPM.push(collab)
      } else {
        acc.others.push(collab)
      }
      return acc
    }, getBaseGroups())
    return _.mapValues(groups, arr => {
      return sortBy(arr, collab => -collab.get('last_login'))
    })
  }, [collaborators])
}

export const GrowthCardCollection = ({ graphCollaborators, collaborators, loading = true }) => {
  const meEmail = useMeEmail()

  if (loading || graphCollaborators.size) {
    return <GraphCardCollection graphCollaborators={graphCollaborators} loading={loading} meEmail={meEmail} />
  }
  return <PMCardCollection collaborators={collaborators} meEmail={meEmail} />
}

const cardMapper = meEmail => collaborator => {
  const email = collaborator?.get('email')
  return <GrowthPersonaCard key={email} collaborator={collaborator} isMe={email === meEmail} />
}

const GraphCardCollection = ({ graphCollaborators, loading, meEmail }) => {
  const groups = useGroupGraphCollaborators({ graphCollaborators, meEmail })
  const ghostMapper = idx => <GhostGrowthPersonaCard key={idx} />
  const cardArray = loading ? _.map(_.range(10), ghostMapper) : _.map(groups.inPM, cardMapper(meEmail))
  return (
    <Container>
      <CardsSection>{cardArray}</CardsSection>
      <GrowthList title="Your domain" collaborators={groups.sameDomain} />
      <GrowthList title="Others" collaborators={groups.others} />
    </Container>
  )
}

const PMCardCollection = ({ collaborators, meEmail }) => {
  const groups = useGroupPMCollaborators({ collaborators, meEmail })
  const cardArray = _.map(groups.inPM, cardMapper(meEmail))
  return (
    <Container>
      <CardsSection>{cardArray}</CardsSection>
      <GrowthList title="Others" collaborators={groups.others} />
    </Container>
  )
}
