import _ from 'lodash'

export const isPrivate = s => s.getIn(['config', 'public']) === false
export const isCustom = s => s.getIn(['config', 'custom']) === true
export const isGovSso = s => s.getIn(['config', 'gov_sso']) === true
export const isCommercialSso = s => s.getIn(['config', 'commercial_sso']) === true
export const requireSso = s => s.getIn(['config', 'forced_sso']) === true
export const hasPublicSaml = s => s.getIn(['config', 'public_saml']) === true
export const microsoftAadBackend = s => s.getIn(['config', 'microsoft_aad_backend'])
export const infoUrl = s => s.getIn(['config', 'info_url'])
export const themeColor = s => s.getIn(['config', 'theme_color'])
export const displayName = s => s.getIn(['config', 'app_display_name'])
export const displayNameShort = s => s.getIn(['config', 'app_display_name_short'])
export const hipaa = s => s.getIn(['config', 'hipaa'])
export const taskConnectors = s => s.getIn(['config', 'task_connectors'], true)
export const teamsAppId = s => s.getIn(['config', 'teams_app_id'])
export const teamsPublisher = s => s.getIn(['config', 'teams_publisher'])
export const canUseAI = s => s.getIn(['config', 'ai'])
export const editCommentLimitMinutes = s => s.getIn(['config', 'edit_comment_limit_minutes'])
export const secretInbox = s => s.getIn(['config', 'inbox']) ?? true

const DEFAULT_INACTIVITY_LOGOUT = 600

export const inactivityLogout = s => {
  const inactivity = s.getIn(['config', 'inactivity_logout'])
  if (inactivity) return _.parseInt(inactivity)
  if (hipaa(s)) {
    return DEFAULT_INACTIVITY_LOGOUT
  }
  return 0
}
