import _ from 'lodash'
import { useSelector } from 'react-redux'
import { itemHelper, stateHelper } from '../../common/src/helpers'
import { useEffect, useMemo, useState } from 'react'
import { checkOwnershipByMode } from './checkOwnershipByMode'
import { datefns } from '../../utils/datefns'
import { dateToTimestampInSeconds } from '../../common/src/helpers/dateHelper'

export const getTimeInterval = now => {
  const end = _.flow([datefns.add({ days: 7 }), datefns.endOfDay])(now)
  const start = _.flow([datefns.add({ months: -1 }), datefns.startOfDay])(now)
  return [start, end]
}

const useItemsInInterval = (interval, mode) => {
  const allItems = useSelector(stateHelper.getAllItemsMap)
  const meEmail = useSelector(stateHelper.getMeEmail)
  const [start, end] = _.map(interval, d => dateToTimestampInSeconds(d))
  const inInterval = x => _.inRange(x, start, end)
  const checkOwnership = checkOwnershipByMode[mode]
  return allItems.filter(i => {
    const due = itemHelper.getDueTimestamp(i)
    return checkOwnership(i, meEmail) && inInterval(due) && itemHelper.getState(i) === itemHelper.STATE.UNFINISHED
  })
}

export const useAgendaItems = mode => {
  const [now, setNow] = useState(() => new Date())

  // Update every 5 minutes
  useEffect(() => {
    const interval = setInterval(
      () => {
        setNow(new Date())
      },
      5 * 60 * 1000 // 5 minutes
    )

    return () => clearInterval(interval)
  }, [])
  const interval = useMemo(() => getTimeInterval(now), [now])
  return { items: useItemsInInterval(interval, mode), now, interval }
}
