import { SEARCH_REDUCER_KEYS as KEYS } from '../reducers/searchKeys'
import { stateHelper } from '../helpers'

export const getSearchedItemsSet = (state, section = KEYS.SECTION_SEARCH, objectsType = KEYS.ITEMS) => {
  return state.getIn([KEYS.REDUCER, section, objectsType, KEYS.SET])
}

export const getSearchedItems = (state, section) => {
  const set = getSearchedItemsSet(state, section)
  return stateHelper.getItemsFromIDs(state, set)
}

export const getSearchedProjectsSet = (state, section = KEYS.SECTION_SEARCH, objectsType = KEYS.PROJECTS) => {
  return state.getIn([KEYS.REDUCER, section, objectsType, KEYS.SET])
}

export const getSearchedProjects = (state, section) => {
  const set = getSearchedProjectsSet(state, section)
  return stateHelper.getProjectsFromIDs(state, set)
}
