import { fromJS } from 'immutable'
import { API_EVENT, generateActionTypeString } from '../helpers/reducerHelper'
import { APIS, PM_API_RESOURCES } from '../constants'

const GET_CONFIG_SUCCESS = generateActionTypeString(APIS.PM, PM_API_RESOURCES.CONFIG, API_EVENT.SUCCESS)

const BASE = fromJS({})

export const config = (state = BASE, { type, payload }) => {
  if (type === GET_CONFIG_SUCCESS) {
    state = fromJS(payload)
  }
  return state
}
