import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

export const useTranslatedPlaceholders = (prefix, rangeLength) => {
  const { t } = useTranslation()
  return React.useMemo(() => {
    const getKey = i => `${prefix}${i}.`
    return _.range(rangeLength).map(i => ({
      title: t(getKey(i) + 'title'),
      message: t(getKey(i) + 'message'),
    }))
  }, [prefix, rangeLength, t])
}
