import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import { Card, CardContainer } from './common'
import { RecentItemsSection } from './RecentItemsSection'
import { FlexContainer } from '../../layout/FlexContainer'
import { markGrowthUserAsInvited } from '../../../actions/uiActions'
import { isEmailMarkedAsInvitedInGrowthView } from '../../../selectors/uiSelectors'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../../common/src/eventTracking/amplitudeEvents'
import { CustomPersona, OneOnOne, Teams, useInviteUser } from '../../../views/growth/GrowthCommon'

const Content = styled.div`
  margin-top: 20px;
  flex: 1;
`

const BottomRow = styled.div`
  height: 24px;
  max-height: 24px;
  display: flex;
  flex-direction: row-reverse;
  align-items: end;
`

const GrowthGraphPersonaCard = ({ style, collaborator, isMe = false }) => {
  const dispatch = useDispatch()
  const pmId = collaborator?.get('id')
  const isPmUser = !!pmId
  const graphProfile = collaborator.get('graphProfile')
  const email = graphProfile.get('mail')
  const alreadyInvited = useSelector(isEmailMarkedAsInvitedInGrowthView(email))
  const invite = useInviteUser(dispatch)
  const clickInvite = async () => {
    dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.GROWTH_INVITE_USER))
    dispatch(markGrowthUserAsInvited(email))
    await invite(email)
  }
  let content
  if (isPmUser) {
    content = <RecentItemsSection pmUserId={pmId} />
  } else {
    content = (
      <FlexContainer>
        {alreadyInvited ? (
          <span>User already invited</span>
        ) : (
          <PrimaryButton onClick={clickInvite}>Invite</PrimaryButton>
        )}
      </FlexContainer>
    )
  }
  return (
    <CardContainer style={style}>
      <Card>
        <CustomPersona collaborator={collaborator} />
        <Content>{content}</Content>
        {!isMe && (
          <BottomRow>
            <Teams email={email} />
            {isPmUser && <OneOnOne email={email} />}
          </BottomRow>
        )}
      </Card>
    </CardContainer>
  )
}

const GrowthPersonaCard_ = ({ style, collaborator, isMe = false }) => {
  const pmId = collaborator?.get('id')
  const isPmUser = !!pmId
  const graphProfile = collaborator.get('graphProfile')
  if (graphProfile) {
    return <GrowthGraphPersonaCard style={style} collaborator={collaborator} isMe={isMe} />
  }
  const email = collaborator.get('email')
  return (
    <CardContainer style={style}>
      <Card>
        <CustomPersona collaborator={collaborator} />
        <Content>
          <RecentItemsSection pmUserId={pmId} />
        </Content>
        {!isMe && <BottomRow>{isPmUser && <OneOnOne email={email} />}</BottomRow>}
      </Card>
    </CardContainer>
  )
}

export const GrowthPersonaCard = React.memo(GrowthPersonaCard_)
