import { useEffect, useLayoutEffect, useRef } from 'react'
import _ from 'lodash'
import { URLHelper } from '../../common/src/helpers'
import { PM_API_RESOURCE_TYPE, PM_API_RESOURCES } from '../../common/src/constants'
import * as queryParamsHelper from '../../helpers/queryParamsHelper'
import { decodeEmailComponent, getOneOnOneCreatorQueryParam } from '../../helpers/queryParamsHelper'
import { useMeEmail } from '../../common/src/hooks/usersHooks'
import { setOneOnOneLastCollaborator } from '../../actions/uiActions'
import { useDispatch, useSelector } from 'react-redux'
import { oneOnOneView as oneOnOneViewInit } from '../../actions/viewInitializationActions'
import { RELATIVE_URL_BY_ROUTE_ID } from '../../helpers/routeHelper'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { validateEmail } from '../../common/src/helpers/stringHelper'
import { getDidLoadTeamsContext } from '../../common/src/selectors/teamsSelectors'
import {
  isErrorRequestForPMResource,
  isInitializedRequestForPMResource,
} from '../../common/src/selectors/requestStateSelectors'

export const baseState = {
  selectedCollaborator: null,
  isOpenNewItemPanel: false,
  mode: '',
  loading: false,
  loadingGraph: false,
}

const LOCAL_ACTION_TYPES = {
  SELECT_COLLABORATOR: 'SELECT_COLLABORATOR',
  SHOW_ITEM_PANEL_FROM_TOP_BAR: 'SHOW_ITEM_PANEL_FROM_TOP_BAR',
  SHOW_ITEM_PANEL_FROM_HEADER: 'SHOW_ITEM_PANEL_FROM_HEADER',
  HIDE_NEW_ITEM_PANEL: 'HIDE_NEW_ITEM_PANEL',

  SET_LOADING: 'SET_LOADING',
  SET_LOADING_GRAPH: 'SET_LOADING_GRAPH',
}

export const localReducer = (state, { type, payload }) => {
  switch (type) {
    case LOCAL_ACTION_TYPES.SELECT_COLLABORATOR: {
      const email = payload.collaborator
      if (validateEmail(email)) {
        state = { ...state, selectedCollaborator: email, text: payload.text }
      }
      break
    }
    case LOCAL_ACTION_TYPES.SHOW_ITEM_PANEL_FROM_HEADER:
      state = {
        ...state,
        mode: EVENT_EXTRA.CREATE_ITEM.MODE.ONE_ON_ONE_HEADER,
        isOpenNewItemPanel: true,
      }
      break
    case LOCAL_ACTION_TYPES.SHOW_ITEM_PANEL_FROM_TOP_BAR:
      state = {
        ...state,
        mode: EVENT_EXTRA.CREATE_ITEM.MODE.ONE_ON_ONE_TOP_BAR,
        isOpenNewItemPanel: true,
      }
      break
    case LOCAL_ACTION_TYPES.HIDE_NEW_ITEM_PANEL:
      state = { ...state, isOpenNewItemPanel: false }
      break
    case LOCAL_ACTION_TYPES.SET_LOADING:
      state = { ...state, loading: payload }
      break
    case LOCAL_ACTION_TYPES.SET_LOADING_GRAPH:
      state = { ...state, loadingGraph: payload }
      break
    default:
      console.warn('Unknown action type')
  }
  return state
}

export const localActions = {
  selectCollaborator: (collaborator, text = '') => ({
    type: LOCAL_ACTION_TYPES.SELECT_COLLABORATOR,
    payload: { collaborator, text },
  }),
  showNewItemPanelFromTobBar: () => ({
    type: LOCAL_ACTION_TYPES.SHOW_ITEM_PANEL_FROM_TOP_BAR,
  }),
  showNewItemPanelFromHeader: () => ({
    type: LOCAL_ACTION_TYPES.SHOW_ITEM_PANEL_FROM_HEADER,
  }),
  hideNewItemPanel: () => ({
    type: LOCAL_ACTION_TYPES.HIDE_NEW_ITEM_PANEL,
  }),
  setLoading: loading => ({
    type: LOCAL_ACTION_TYPES.SET_LOADING,
    payload: loading,
  }),
  setLoadingGraph: loading => ({
    type: LOCAL_ACTION_TYPES.SET_LOADING_GRAPH,
    payload: loading,
  }),
}

export const useMessageListener = selectItem => {
  useEffect(() => {
    const onMessage = event => {
      const text = event.data
      const resourceType = URLHelper.resourceURIParser(text)
      if (resourceType && resourceType.type === PM_API_RESOURCE_TYPE.ITEM && resourceType.id) {
        selectItem(resourceType.id)
      }
    }
    window.addEventListener('message', onMessage)
    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [selectItem])
}

const getMatchEmail = match => {
  const email = _.get(match, 'params.email')
  return email && decodeEmailComponent(email)
}

const getLocationText = () => {
  const sp = new URLSearchParams(window.location.search)
  return sp.get('text') || ''
}

export const useMountEffect = ({ match, localDispatch }) => {
  const meEmail = useMeEmail()
  const dispatch = useDispatch()
  const text = getLocationText()
  const isInit = useSelector(state => isInitializedRequestForPMResource(state, PM_API_RESOURCES.MS_COLLABORATORS))
  const isError = useSelector(state => isErrorRequestForPMResource(state, PM_API_RESOURCES.MS_COLLABORATORS))
  const shouldInitView = isInit || isError
  const isInitialized = useRef(false)

  useEffect(() => {
    if (shouldInitView && !isInitialized.current) {
      isInitialized.current = true
      dispatch(oneOnOneViewInit())
      const email = getMatchEmail(match)
      const creatorEmail = getOneOnOneCreatorQueryParam()
      const selectedEmail = creatorEmail && email === meEmail ? creatorEmail : email
      if (selectedEmail) {
        localDispatch(localActions.selectCollaborator(selectedEmail, text))
      }
    }
  }, [dispatch, localDispatch, match, meEmail, shouldInitView, text])
}

export const useMatchUrl = ({ collaborator, history, text, routeId, itemId }) => {
  useLayoutEffect(() => {
    if (collaborator) {
      const relativeURL = RELATIVE_URL_BY_ROUTE_ID[routeId]
      const to = relativeURL(collaborator, itemId)
      const search = new URLSearchParams(to.search)
      if (text) {
        search.set('text', text)
      } else {
        search.delete('text')
      }
      to.search = '?' + search.toString()
      history.replace(to)
    }
  }, [collaborator, history, itemId, routeId, text])
}

export const useWatchCollaborator = ({ collaborator, appLocation }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (collaborator) {
      dispatch(setOneOnOneLastCollaborator(collaborator, appLocation))
    }
  }, [appLocation, collaborator, dispatch])
}

export const useReadLastRememberedCollaborator = ({ match, collaborator, localDispatch }) => {
  const isEmbeddedOnTeams = queryParamsHelper.isEmbeddedOnTeams()
  const didLoadTeamsContext = useSelector(getDidLoadTeamsContext)
  useEffect(() => {
    if (isEmbeddedOnTeams && !didLoadTeamsContext) {
      return
    }
    const email = getMatchEmail(match)
    // Avoid selecting last remembered collaborator if the url contains one
    if (collaborator && !email) {
      localDispatch(localActions.selectCollaborator(collaborator))
    }
  }, [isEmbeddedOnTeams, didLoadTeamsContext, collaborator, match, localDispatch])
}
