import React, { useEffect, useState } from 'react'
import { AutoFocusTextField } from '../textFields/AutoFocusTextField'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import { SimpleModal } from './SimpleModal'
import { KEY_CODE } from '../../common/src/helpers/keyboardHelper'
import { stringHelper } from '../../common/src/helpers'
import { extenders } from '../../style/teamsExtenders'
import styled from 'styled-components'
import { TextField } from '@fluentui/react/lib/TextField'
import { ErrorText } from '../text/ErrorText'
import { useTranslation } from 'react-i18next'

const LinkTextField = extenders.textField(
  styled(TextField)`
    margin-bottom: 12px;
  `
)

export const DependencyLinkModal = ({ previousLink, onChange, onDismiss, ...rest }) => {
  const [state, setState] = useState({ ...previousLink })
  const [badLink, setBadLink] = useState(false)
  const [badName, setBadName] = useState(false)
  const { name, link } = state
  const { t } = useTranslation()

  useEffect(() => {
    if (previousLink) {
      setState({ ...previousLink })
    }
  }, [previousLink])

  const onChangeName = (ev, newName) => setState({ ...state, name: newName })

  const onChangeLink = (ev, newLink) => setState({ ...state, link: newLink })

  const onDismissed = () => {
    setState({ ...previousLink })
    setBadLink(false)
    setBadName(false)
  }

  const onDone = () => {
    setBadLink(false)
    setBadName(false)
    if (name && name.trim()) {
      if (stringHelper.validateURL(link)) {
        onChange(state)
        onDismiss()
      } else {
        setBadLink(true)
      }
    } else {
      setBadName(true)
    }
  }

  const onKeyPress = event => {
    if (event.charCode === KEY_CODE.ENTER) {
      event.preventDefault()
      event.stopPropagation()
      onDone()
    }
  }

  const button = (
    <PrimaryButton key={'done'} onClick={onDone}>
      {t('general.done')}
    </PrimaryButton>
  )

  return (
    <SimpleModal
      title={t('links_section.enter_resource')}
      buttons={button}
      onDismiss={onDismiss}
      onDismissed={onDismissed}
      {...rest}
    >
      <AutoFocusTextField
        onChange={onChangeName}
        value={name}
        onKeyPress={onKeyPress}
        label={t('links_section.name')}
      />
      {!!badName && <ErrorText>{t('links_section.valid_resource')}</ErrorText>}
      <LinkTextField
        onChange={onChangeLink}
        value={link}
        onKeyPress={onKeyPress}
        placeholder="https://..."
        label="Link: "
      />
      {!!badLink && <ErrorText>{t('links_section.valid_link')}</ErrorText>}
    </SimpleModal>
  )
}
