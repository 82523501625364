import { useDispatch, useSelector } from 'react-redux'
import { getLastWebinarIdReminded, getWebinarReminderEnabled } from '../selectors/uiSelectors'
import { useEffect } from 'react'
import { useWebinars } from '../queries/webinars'
import { Button, ToastTrigger, useToastController } from '@fluentui/react-components'
import { FluentToast } from './toast/FluentToast'
import { setLastWebinarIdReminded, setWebinarReminderEnabled } from '../actions/uiActions'
import { useTranslation } from 'react-i18next'
import { captureLinkClick } from '@/utils/externalLinkHandler'

export const WebinarReminder = () => {
  const webinarReminderEnabled = useSelector(getWebinarReminderEnabled) as boolean
  const lastWebinarIdReminded = useSelector(getLastWebinarIdReminded) as undefined | number
  const { data: webinars } = useWebinars(webinarReminderEnabled)
  const dispatch = useDispatch()
  const { dispatchToast } = useToastController()
  const { t } = useTranslation()

  useEffect(() => {
    if (!webinars || !webinarReminderEnabled) return
    // If a webinar starts in the next hour or has started in the past 15 minutes, show a toast
    const now = new Date().getTime()
    const webinar = webinars.find(webinar => {
      const diff = +webinar.event_datetime * 1000 - now
      return diff < 60 * 60 * 1000 && diff > -(15 * 60 * 1000)
    })
    if (!webinar || webinar.id === lastWebinarIdReminded) return
    dispatchToast(
      <FluentToast
        footer={
          <div className="flex flex-col gap-1">
            <ToastTrigger>
              <Button
                as="a"
                appearance="primary"
                href={webinar.join_link || webinar.registration_link}
                target="_blank"
                onClick={captureLinkClick()}
              >
                {t(webinar.join_link ? 'webinar_reminder.join' : 'webinar_reminder.register')}
              </Button>
            </ToastTrigger>
            <ToastTrigger>
              <Button
                appearance="secondary"
                onClick={() => {
                  dispatch(setWebinarReminderEnabled(false))
                }}
              >
                {t('webinar_reminder.never_show_again')}
              </Button>
            </ToastTrigger>
          </div>
        }
      >
        {+webinar.event_datetime * 1000 - now < 0
          ? t('webinar_reminder.title_past')
          : t('webinar_reminder.title', {
              webinarName: webinar.topic,
              webinarTime: new Date(+webinar.event_datetime * 1000).toLocaleTimeString(undefined, {
                hour: '2-digit',
                minute: '2-digit',
              }),
            })}
      </FluentToast>,
      { timeout: -1 }
    )
    dispatch(setLastWebinarIdReminded(webinar.id))
  }, [dispatch, dispatchToast, lastWebinarIdReminded, t, webinarReminderEnabled, webinars])

  return false
}
