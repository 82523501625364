import { itemHelper } from '../../common/src/helpers'
import { useCallback } from 'react'
import { ShimmerVirtualizedList } from '../list/ShimmerVirtualizedList'
import { ItemCell } from './ItemCell'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { GhostItemCell } from './GhostItemCell'

export const ItemVirtualizedList = ({
  items,
  addedItems,
  selectedItem,
  onSelectItem,
  onAddItem,
  noRowsRenderer,
  loading,
  endReached,
  increaseViewportBy,
}) => {
  const narrow = useNarrowWidth()

  const selectedItemID = itemHelper.getId(selectedItem)
  const rowRenderer = useCallback(
    index => {
      const item = items[index]
      const serverID = itemHelper.getId(item)
      const selected = serverID === selectedItemID
      const added = !!addedItems?.has(serverID)
      return (
        <ItemCell
          item={item}
          onClick={onSelectItem}
          onAdd={onAddItem}
          selected={selected}
          added={added}
          narrow={narrow}
        />
      )
    },
    [items, onSelectItem, onAddItem, selectedItemID, narrow, addedItems]
  )

  return (
    <ShimmerVirtualizedList
      rows={items}
      rowRenderer={rowRenderer}
      shimmerComponent={GhostItemCell}
      computeItemKey={(idx, item) => itemHelper.getId(item) ?? `idx-${idx}`}
      noRowsRenderer={noRowsRenderer}
      loading={loading}
      endReached={endReached}
      increaseViewportBy={increaseViewportBy}
    />
  )
}
