import { createGraphResourceBase } from './graphResourceBase'
import { ICON_NAMES } from '../../msIcons'
import { GRAPH_RESOURCE_TYPE } from './graphResourceBase'

const helper = {}

helper.create = message => {
  return createGraphResourceBase(message, {
    name: message.subject || message.bodyPreview || 'No subject',
    icon: ICON_NAMES.OutlookLogo,
    iconColor: '#0072C6',
    link: message.webLink,
    descriptionText: message.bodyPreview,
    type: GRAPH_RESOURCE_TYPE.MESSAGE,
  })
}

helper.isMessage = m => m.type === GRAPH_RESOURCE_TYPE.MESSAGE

helper.getHTMLBody = message => {
  const body = message.resource.body
  if (!body) {
    return void 0
  }
  const { content, contentType } = body
  if (contentType === 'html') {
    return content
  }
  return void 0
}

helper.getBodyPreview = message => message.resource.bodyPreview

helper.sanitizeDivLinks = body => {
  const div = document.createElement('div')
  div.innerHTML = body
  div.querySelectorAll('a').forEach(function (elem) {
    elem.setAttribute('target', '_blank')
  })
  return div.outerHTML
}

export default helper
