import { Button, MessageBar, MessageBarBody, MessageBarActions } from '@fluentui/react-components'
import { useSelector } from 'react-redux'
import { stateHelper } from '../../common/src/helpers'

export const LookbackLimitBanner = () => {
  const payingUser: boolean | undefined = useSelector(stateHelper.currentUserIsPaying)

  return (
    <MessageBar intent="info" className="mx-4 mt-2 !min-h-fit">
      <MessageBarBody>
        {payingUser
          ? 'With your current plan, you cannot see comments older than this. To see the full history, upgrade to our business class plan.'
          : 'In your current plan, you cannot see comments older than this. To see the full history, upgrade now. It takes one minute.'}
      </MessageBarBody>
      <MessageBarActions>
        <Button as="a" href={payingUser ? '/accounts/req_business?chat' : '/checkout?refer=chat-limit'} target="_blank">
          {payingUser ? 'More info' : 'See my options'}
        </Button>
      </MessageBarActions>
    </MessageBar>
  )
}
