import ReconnectingWebSocket from 'reconnecting-websocket'
import { PM_API_RESOURCE_TYPE, PM_WS_URLS } from '../constants'
import { resourceURIParser } from '../helpers/URLHelper'
import { SessionTokenManager } from '../SessionTokenManager'
import { USE_WS } from '../environment'
import { ACTIONS } from './wsActions'

let ws = null

const EVENT_NAME = {
  MESSAGE: 'message',
  ON_OPEN: 'onopen',
  ON_CLOSE: 'onclose',
}

const RECONNECT_THRESHOLD = 10 * 60 * 1000

const getURL = () => {
  if (SessionTokenManager.get()) {
    return PM_WS_URLS.CHANGES + '?oauth_consumer_key=' + SessionTokenManager.get()
  } else {
    return PM_WS_URLS.CHANGES
  }
}

export const initializeWs = async store => {
  if (!USE_WS) {
    return
  }

  let lastActivity = new Date()

  ws = new ReconnectingWebSocket(getURL(), null, {
    //debug: true
  })

  ws.addEventListener(EVENT_NAME.MESSAGE, e => {
    lastActivity = new Date()
    const obj = JSON.parse(e.data)
    if (!('resource_uri' in obj)) {
      return
    }
    const resourceURI = obj.resource_uri
    const URI = resourceURIParser(resourceURI)
    const type = URI.type

    const resourceMapping = {
      [PM_API_RESOURCE_TYPE.COMMENT]: ACTIONS.COMMENT_RECEIVED,
      [PM_API_RESOURCE_TYPE.ITEM]: ACTIONS.ITEM_RECEIVED,
      [PM_API_RESOURCE_TYPE.PROJECT]: ACTIONS.PROJECT_RECEIVED,
      [PM_API_RESOURCE_TYPE.SHARING_REQUEST]: ACTIONS.SHARING_REQUEST_RECEIVED,
      [PM_API_RESOURCE_TYPE.NOTIFICATION]: ACTIONS.NOTIFICATION_RECEIVED,
    }

    const actionType = resourceMapping[type]

    if (actionType) {
      store.dispatch({ type: actionType, payload: obj })
    }
  })

  let numberOfSessions = 0
  ws.onopen = e => {
    numberOfSessions++
    if (numberOfSessions > 1) {
      store.dispatch({
        type: ACTIONS.RECONNECTION,
      })
    }
    console.log('connect')
    lastActivity = new Date()
  }

  ws.onclose = e => {
    console.log('close')
  }

  window.addEventListener('focus', () => {
    const diff = new Date() - lastActivity
    const connectionNotOpened = ws.readyState > ws.OPEN
    const tooOld = diff > RECONNECT_THRESHOLD
    const shouldReconnect = connectionNotOpened || tooOld
    if (shouldReconnect) {
      ws.reconnect()
    }
  })
}
