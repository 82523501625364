import _ from 'lodash'
import { fromJS } from 'immutable'
import {
  getFollowedItemsIdSet,
  getInboxPlus,
  getItem,
  getItemsInProject,
  getItemSummariesInProject,
  postItem,
  postItems,
  putItem,
  putItems,
} from './itemsAPI'
import { getAllProjects, getAllProjectSummaries, getProject, putProject } from './projectsAPI'
import {
  functionalUtils,
  itemHelper,
  projectHelper,
  sharingRequestHelper,
  stateHelper,
  reminderHelper,
} from '../helpers'
import { API_EVENT, parseApiActionType } from '../helpers/reducerHelper'
import { PM_API_RESOURCES } from '../constants'
import { getSharingRequests, putAcceptingSharingRequest } from './sharingRequestsAPI'
import {
  isNeededRequestForPMResource,
  isPendingRequestForPMResource,
  requestStatusForPMResource,
} from '../selectors/requestStateSelectors'
import { requestNotNeeded } from './generalActions'
import { putReminder, postReminder } from './remindersAPI'
import { getAllNotifications } from './notificationsAPI'
import { getProjectSubscriptions } from './projectSubscriptionsAPI'
import { getCustomFilters } from './customFiltersAPI'

export const ACTIONS = {
  MARK_ITEM_AS_MODIFIED: 'MARK_ITEM_AS_MODIFIED',
  MARK_ITEM_AS_MODIFIED_IN_MODEL: 'MARK_ITEM_AS_MODIFIED_IN_MODEL',
  MARK_ITEMS_AS_MODIFIED_IN_MODEL: 'MARK_ITEMS_AS_MODIFIED_IN_MODEL',
  REMOVE_ITEM_FROM_MODIFIED: 'REMOVE_ITEM_FROM_MODIFIED',
  MARK_PROJECT_AS_MODIFIED: 'MARK_PROJECT_AS_MODIFIED',
  MARK_PROJECT_AS_MODIFIED_IN_MODEL: 'MARK_PROJECT_AS_MODIFIED_IN_MODEL',
  REMOVE_PROJECT_FROM_MODIFIED: 'REMOVE_PROJECT_FROM_MODIFIED',
}

export const fetchItemAndProject = id => async dispatch => {
  try {
    const response = await dispatch(getItemIfNeeded(id))
    if (response.error) {
      return response
    }
    const item = fromJS(response.payload)
    const projectID = itemHelper.getProjectIdd(item)
    if (projectID) {
      return await dispatch(
        getProjectIfNeeded({
          projectID,
          memberships: true,
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
}

export const sendItem =
  (item, modifyInModel = false) =>
  async (dispatch, getState) => {
    if (itemHelper.isItem(item)) {
      const id = itemHelper.getId(item)
      if (id > 0) {
        const state = getState()
        const old = stateHelper.getItem(state, id)
        const vid = old.get(itemHelper.KEYS.VERSION_ID)
        if (vid > item.get(itemHelper.KEYS.VERSION_ID)) {
          //ensure we use an updated version id in order to avoid problems with notes
          item = item.set(itemHelper.KEYS.VERSION_ID, vid)
        }
        try {
          dispatch(markItemAsModifiedInModel(item))
          return await dispatch(putItem(item))
        } catch (err) {
          console.log('Error modifying item')
        }
      }
    }
  }

export const sendItems =
  (items, modifyInModel = false) =>
  async (dispatch, getState) => {
    const state = getState()
    const newItems = items.map(item => {
      const id = itemHelper.getId(item)
      const old = stateHelper.getItem(state, id)
      const vid = old.get(itemHelper.KEYS.VERSION_ID)
      if (vid > item.get(itemHelper.KEYS.VERSION_ID)) {
        //ensure we use an updated version id in order to avoid problems with notes
        return item.set(itemHelper.KEYS.VERSION_ID, vid)
      }
      return item
    })
    try {
      dispatch(markItemsAsModifiedInModel(newItems))
      return await dispatch(putItems(newItems))
    } catch (err) {
      console.error('Error modifying items')
    }
  }

export const sendProject = project => async dispatch => {
  if (projectHelper.isProject(project)) {
    const id = projectHelper.getIdd(project)
    if (id > 0) {
      try {
        dispatch(removeProjectFromModified(project))
        const response = await dispatch(putProject(project))
        const acType = parseApiActionType(response.type)
        if (acType.event !== API_EVENT.SUCCESS) {
          dispatch(markProjectAsModified(project))
        }
        return response
      } catch (err) {
        console.log('Error modifying project')
      }
    }
  }
}

export const sendReminder = reminder => async dispatch => {
  const reminder_id = reminderHelper.getId(reminder)
  if (reminder_id > 0) {
    try {
      return await dispatch(putReminder(reminder))
    } catch (err) {
      console.log('Error modifying reminder')
    }
  }
}

export const sendPostReminder = reminder => async dispatch => {
  try {
    return await dispatch(postReminder(reminder))
  } catch (err) {
    console.log('Error posting reminder')
  }
}

// TODO Save items we're POSTing
export const markItemAsModified = item => {
  return {
    type: ACTIONS.MARK_ITEM_AS_MODIFIED,
    payload: item,
  }
}

export const markItemAsModifiedInModel = item => {
  return {
    type: ACTIONS.MARK_ITEM_AS_MODIFIED_IN_MODEL,
    payload: item,
  }
}

export const markItemsAsModifiedInModel = items => {
  return {
    type: ACTIONS.MARK_ITEMS_AS_MODIFIED_IN_MODEL,
    payload: items,
  }
}

export const removeItemFromModified = item => {
  return {
    type: ACTIONS.REMOVE_ITEM_FROM_MODIFIED,
    payload: item,
  }
}

export const markProjectAsModified = project => {
  return {
    type: ACTIONS.MARK_PROJECT_AS_MODIFIED,
    payload: project,
  }
}

export const markProjectAsModifiedInModel = project => {
  return {
    type: ACTIONS.MARK_PROJECT_AS_MODIFIED_IN_MODEL,
    payload: project,
  }
}

export const removeProjectFromModified = project => {
  return {
    type: ACTIONS.REMOVE_PROJECT_FROM_MODIFIED,
    payload: project,
  }
}

export const getAllProjectsIfNotPending =
  (params = {}) =>
  (dispatch, getState) => {
    const state = getState()
    const isPending = isPendingRequestForPMResource(state, PM_API_RESOURCES.ALL_PROJECTS)
    return isPending ? Promise.resolve(requestNotNeeded) : dispatch(getAllProjects(params))
  }

export const getAllProjectsIfNeeded =
  (params = {}) =>
  (dispatch, getState) => {
    const state = getState()
    const isNeeded = isNeededRequestForPMResource(state, PM_API_RESOURCES.ALL_PROJECTS)
    return isNeeded ? dispatch(getAllProjects(params)) : Promise.resolve(requestNotNeeded)
  }

export const getAllProjectSummariesIfNotPending =
  (params = {}) =>
  (dispatch, getState) => {
    const state = getState()
    const isPending = isPendingRequestForPMResource(state, PM_API_RESOURCES.ALL_PROJECT_SUMMARIES)
    return isPending ? Promise.resolve(requestNotNeeded) : dispatch(getAllProjectSummaries(params))
  }

export const getAllProjectSummariesIfNecessary =
  (params = {}) =>
  (dispatch, getState) => {
    const state = getState()
    const isNeeded = isNeededRequestForPMResource(state, PM_API_RESOURCES.ALL_PROJECT_SUMMARIES)
    return isNeeded ? dispatch(getAllProjectSummaries(params)) : Promise.resolve(requestNotNeeded)
  }

export const getNextInboxPlusPageIfNecessary = () => getNextPageIfNecessary(PM_API_RESOURCES.INBOX_PLUS, getInboxPlus)

export const getProjectIfNotPending =
  (params = {}) =>
  (dispatch, getState) => {
    const { projectID, memberships, fastProject } = params
    const state = getState()
    const isPending = isPendingRequestForPMResource(state, PM_API_RESOURCES.PROJECT, projectID)
    return isPending ? Promise.resolve(requestNotNeeded) : dispatch(getProject(projectID, memberships, fastProject))
  }

export const getProjectSubscriptionsIfNotPending =
  (params = {}) =>
  (dispatch, getState) => {
    const { projectID } = params
    const state = getState()
    const isPending = isPendingRequestForPMResource(state, PM_API_RESOURCES.PROJECT_SUBSCRIPTIONS, projectID)
    return isPending ? Promise.resolve(requestNotNeeded) : dispatch(getProjectSubscriptions(projectID))
  }

export const getItemIfNeeded =
  (id, params = {}) =>
  (dispatch, getState) => {
    const state = getState()
    const isNeeded = isNeededRequestForPMResource(state, PM_API_RESOURCES.ITEM, id)
    return isNeeded ? dispatch(getItem(id, params)) : Promise.resolve(requestNotNeeded)
  }

export const getProjectIfNeeded =
  (params = {}) =>
  (dispatch, getState) => {
    const { projectID, memberships, fastProject } = params
    const state = getState()
    const isNeeded = isNeededRequestForPMResource(state, PM_API_RESOURCES.PROJECT, projectID)
    return isNeeded ? dispatch(getProject(projectID, memberships, fastProject)) : Promise.resolve(requestNotNeeded)
  }

export const getItemInProjectIfNecessary =
  (params = {}, projectId) =>
  (dispatch, getState) => {
    const state = getState()
    const isNeeded = isNeededRequestForPMResource(state, PM_API_RESOURCES.ITEMS_IN_PROJECT, projectId)
    return isNeeded ? dispatch(getItemsInProject(params, projectId)) : Promise.resolve(requestNotNeeded)
  }

export const getItemSummariesInProjectIfNecessary =
  (params = {}, projectId) =>
  (dispatch, getState) => {
    const state = getState()
    const isNeeded = isNeededRequestForPMResource(state, PM_API_RESOURCES.ITEM_SUMMARIES_IN_PROJECT, projectId)
    return isNeeded ? dispatch(getItemSummariesInProject(params, projectId)) : Promise.resolve(requestNotNeeded)
  }

export const getFollowedItemsInProjectIfNecessary = projectId => (dispatch, getState) => {
  const state = getState()
  const isNeeded = isNeededRequestForPMResource(state, PM_API_RESOURCES.FOLLOWED_ITEMS, projectId)
  return isNeeded ? dispatch(getFollowedItemsIdSet(projectId)) : Promise.resolve(requestNotNeeded)
}

export const getAllNotificationsIfNeeded =
  (params = {}) =>
  (dispatch, getState) => {
    const state = getState()
    const isNeeded = isNeededRequestForPMResource(state, PM_API_RESOURCES.NOTIFICATIONS)
    return isNeeded ? dispatch(getAllNotifications(params)) : Promise.resolve(requestNotNeeded)
  }

export const getAllCustomFiltersIfNeeded =
  (params = {}) =>
  (dispatch, getState) => {
    const state = getState()
    const isNeeded = isNeededRequestForPMResource(state, PM_API_RESOURCES.CUSTOM_FILTERS)
    return isNeeded ? dispatch(getCustomFilters(params)) : Promise.resolve(requestNotNeeded)
  }

/**
 * Note: fetchMethod should accept one parameter: the queryParams as obj
 */
const getNextPageIfNecessary = (resource, fetchMethod) => (dispatch, getState) => {
  const state = getState()
  const status = requestStatusForPMResource(state, resource)
  if (!status) {
    return dispatch(fetchMethod())
  }
  if (isPendingRequestForPMResource(state, resource)) {
    return void 0
  }
  const nextString = status.get('next')
  if (!nextString || !_.startsWith(nextString, '?')) {
    return void 0
  }
  const next = functionalUtils.queryParamsStringToObj(nextString)
  return dispatch(fetchMethod(next))
}

export const getSharingRequestsIfNotPending = userID => (dispatch, getState) => {
  const state = getState()
  const isPending = isPendingRequestForPMResource(state, PM_API_RESOURCES.ALL_SHARING_REQUESTS)
  return isPending ? Promise.resolve(requestNotNeeded) : dispatch(getSharingRequests(userID))
}

export const acceptSharingRequestDownloadingProject = request => async dispatch => {
  try {
    const response = await dispatch(putAcceptingSharingRequest(request))
    if (response.error) {
      return response
    }
    const projectID = sharingRequestHelper.getProjectID(request)
    if (projectID) {
      return await dispatch(getProject(projectID, true))
    }
  } catch (e) {
    console.log(e)
  }
}

export const getNewItemMovingItemTo = (item, project, quadrant) => async (dispatch, getState) => {
  const state = getState()
  const { newItem, isCopy, deleteOld } = stateHelper.getNewItemMovingItemTo(state, item, project, quadrant)
  try {
    const method = isCopy ? await postItem : putItem
    const response = await dispatch(method(newItem))
    if (response.error) {
      return response
    }
    if (deleteOld) {
      const itemToDelete = item.set(itemHelper.KEYS.STATE, itemHelper.STATE.DELETED)
      dispatch(putItem(itemToDelete))
    }

    return response
  } catch (e) {
    console.log(e)
  }
}

export const getNewItemsMovingItemsTo = (items, project, quadrant) => async (dispatch, getState) => {
  const state = getState()
  const toPut = []
  const toPost = []
  const toDelete = []
  for (const item of items) {
    const { newItem, isCopy, deleteOld } = stateHelper.getNewItemMovingItemTo(state, item, project, quadrant)
    if (isCopy) {
      toPost.push(newItem)
    } else {
      toPut.push(newItem)
    }
    if (deleteOld) {
      toDelete.push(item.set(itemHelper.KEYS.STATE, itemHelper.STATE.DELETED))
    }
  }
  try {
    let putResponse, postResponse
    if (toPut.length > 0) {
      putResponse = await dispatch(putItems(toPut))
      if (putResponse.error) {
        return putResponse
      }
    }
    if (toPost.length > 0) {
      postResponse = await dispatch(postItems(toPost))
      if (postResponse.error) {
        return postResponse
      }
    }
    if (toDelete.length > 0) {
      dispatch(putItems(toDelete))
    }

    return putResponse ?? postResponse
  } catch (e) {
    console.log(e)
  }
}
