import _ from 'lodash'
import tagHelper from './tagHelper'
import itemHelper from './itemHelper'
import { PROJECTS_MODE_KEYS, TAGS_MODE_KEYS } from '../actions/filtersActions'

export const emailPredicateGenerator = (owners, noOwner = false) => {
  if (noOwner) return inputOwner => !inputOwner
  const ownersArray = owners.toJS()
  if (!ownersArray || ownersArray.length === 0) {
    return () => true
  }
  return inputOwner => {
    inputOwner = inputOwner.toLowerCase()
    return ownersArray.map(owner => owner.toLowerCase()).includes(inputOwner)
  }
}

export const textPredicateGenerator = target => {
  const trimmedTarget = target?.toLowerCase().trim()
  if (!trimmedTarget) {
    return () => true
  }
  const words = trimmedTarget.split(/\s+/)
  return title => {
    const trimmedTitle = title.toLowerCase().trim()
    return words.every(word => trimmedTitle.includes(word))
  }
}

export const isFollowedPredicateGenerator = (active, meEmail) => {
  if (!active || !meEmail) {
    return () => true
  }
  return i => itemHelper.isFollower(i, meEmail) || !!itemHelper.getFollowing(i)
}

export const datePredicateGenerator = (minDate, maxDate) => {
  if (!(minDate || maxDate)) {
    return () => true
  }

  return date => {
    if (!date) {
      return false
    }

    if (minDate && maxDate) {
      return date >= minDate && date <= maxDate
    } else if (minDate) {
      return date >= minDate
    } else {
      // maxDate
      return date < maxDate // Next day at 00:00
    }
  }
}

export const tagsPredicateGenerator = (tagNamesTarget, mode = TAGS_MODE_KEYS.INCLUDE) => {
  if (!tagNamesTarget || tagNamesTarget.size === 0) {
    return () => true
  }
  const includeMode = mode === TAGS_MODE_KEYS.INCLUDE

  return tags => {
    const tagNames = tags.map(tag => tagHelper.getName(tag))
    // Match any tag
    return tagNamesTarget.some(tagName => {
      const result = tagNames.some(t => t === tagName)
      return includeMode ? result : !result
    })
  }
}

export const projectItemPredicateGenerator = (projectTargetIds, mode) => {
  if (!projectTargetIds?.size) {
    return () => true
  }
  return mode === PROJECTS_MODE_KEYS.EXCLUDE ? p => !projectTargetIds.includes(p) : p => projectTargetIds.includes(p)
}
