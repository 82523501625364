import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Button,
  Spinner,
  useToastController,
} from '@fluentui/react-components'
import { useCallback, useState } from 'react'
import { duplicateProject as duplicateProjectAPI } from '../common/src/actions/projectsAPI'
import { getRelativeURLKeepingQuerySearch } from '../helpers/routeHelper'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { FluentToast } from './toast/FluentToast'

export const DuplicateProjectDialog = ({ open, setOpen, project }) => {
  const dispatch = useDispatch()
  const { dispatchToast } = useToastController()
  const history = useHistory()
  const [step, setStep] = useState('select')
  const [newProjectId, setNewProjectId] = useState(0)
  const { t } = useTranslation()
  const loading = step === 'loading'

  const duplicateProject = useCallback(
    async (copyFinishedItems = true) => {
      setStep('loading')
      const result = await dispatch(duplicateProjectAPI(project, copyFinishedItems))
      const idd = result?.payload?.idd
      if (idd) {
        setNewProjectId(idd)
        setStep('success')
      } else {
        setStep('select')
        setOpen(false)
        dispatchToast(<FluentToast>{t('project.duplicate.failure')}</FluentToast>, {
          intent: 'error',
        })
      }
    },
    [dispatch, dispatchToast, project, setOpen, t]
  )

  return (
    <Dialog open={open} onOpenChange={(event, data) => setOpen(data.open)}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle className="flex gap-4">
            <span>{t('project.duplicate.select.title')}</span>
            {loading && <Spinner size="small" />}
          </DialogTitle>
          <DialogContent>
            {t(step === 'success' ? 'project.duplicate.success.message' : 'project.duplicate.select.message')}
          </DialogContent>
          <DialogActions>
            {(step === 'select' || loading) && (
              <>
                <Button
                  disabled={loading}
                  appearance="primary"
                  onClick={() => duplicateProject(false)}
                  className="w-max"
                >
                  {t('project.duplicate.select.only_open')}
                </Button>
                <Button disabled={loading} appearance="primary" onClick={duplicateProject} className="w-max">
                  {t('project.duplicate.select.include_finished')}
                </Button>
              </>
            )}
            {step === 'success' && (
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="primary"
                  onClick={() => {
                    const path = getRelativeURLKeepingQuerySearch.matrixForProjectId(newProjectId)
                    history.push(path)
                    setStep('select')
                  }}
                >
                  {t('project.duplicate.success.go_to_project')}
                </Button>
              </DialogTrigger>
            )}
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary" onClick={() => setStep('select')}>
                {t('general.cancel')}
              </Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
