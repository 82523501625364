import { Record, List } from 'immutable'
import { toDate } from './dateHelper'
import userGroupMembershipHelper from './userGroupMembershipHelper'

const KEYS = {
  NAME: 'name',
  GROUP_LOGO: 'group_logo',
  CREATION_DATE: 'creation_date',
  MEMBERSHIPS: 'memberships',
  DELETED: 'deleted',
  DESCRIPTION: 'description',
  ID: 'id',
  FULL_TEAM: 'is_full_team',
  PRIVATE: 'is_private',
}

const Base = Record({
  id: 0,
  creation_date: '0',
  deleted: false,
  description: '',
  group_logo: null,
  is_full_team: false,
  is_private: false,
  name: '',
  resource_uri: null,
  memberships: [],
})

let helper = {}
helper.create = ({ memberships, ...rest }) => {
  const mappedMemberships = List(memberships).map(x => new userGroupMembershipHelper.create(x))
  return Base({ memberships: mappedMemberships, ...rest })
}

helper.getName = group => group.get(KEYS.NAME)
helper.getLogo = group => group.get(KEYS.GROUP_LOGO)
helper.getCreationDate = group => toDate(group.get(KEYS.CREATION_DATE))
helper.getMemberships = group => group.get(KEYS.MEMBERSHIPS)
helper.isDeleted = group => group.get(KEYS.DELETED)
helper.getDescription = group => group.get(KEYS.DESCRIPTION)
helper.getId = group => group.get(KEYS.ID)
helper.isFullTeam = group => group.get(KEYS.FULL_TEAM)
helper.isPrivate = group => group.get(KEYS.PRIVATE)
helper.getUsers = group => {
  const memberships = helper.getMemberships(group)
  return memberships.map(membership => userGroupMembershipHelper.getUser(membership))
}

export default helper
