import React, { useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona'
import { graphProfilePictureUrlUsingProxy, PM_API_RESOURCES } from '../../common/src/constants'
import { IconButton } from '@fluentui/react/lib/Button'
import { ICON_NAMES } from '../../common/src/msIcons'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { getDeepLinkToOpenChat, useDeepLinkExecutor } from '../../utils/teams'
import { teamsHelper, userHelper } from '../../common/src/helpers'
import { fromJS } from 'immutable'
import { postItem } from '../../common/src/actions/itemsAPI'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  isErrorRequestForPMResource,
  isNeededRequestForPMResource,
  isPendingRequestForPMResource,
} from '../../common/src/selectors/requestStateSelectors'
import { useDispatch } from 'react-redux'
import { useTeamsConfig } from '../../queries/config'

export const Title = styled.h2`
  margin: 15px 0 0 0;
`

const SPersona = styled(Persona).attrs({
  styles: {
    primaryText: {
      fontWeight: 'bolder',
    },
  },
  size: PersonaSize.size48,
})``

const CustomPersona_ = ({ collaborator }) => {
  if (!collaborator) {
    return false
  }
  const graphProfile = collaborator.get('graphProfile')
  const { name, email, id, avatarUrl } = graphProfile ? graphToPersona(collaborator) : pmUserToPersona(collaborator)
  return <SPersona size={PersonaSize.size48} text={name} secondaryText={email} key={id} imageUrl={avatarUrl} />
}

const graphToPersona = collaborator => {
  const graphProfile = collaborator.get('graphProfile')
  const id = graphProfile.get('id')
  return {
    name: graphProfile.get('displayName'),
    email: graphProfile.get('mail'),
    avatarUrl: id ? graphProfilePictureUrlUsingProxy(id) : '',
    id,
  }
}

const pmUserToPersona = collaborator => {
  return {
    name: userHelper.getFullName(collaborator),
    email: userHelper.getEmail(collaborator),
    id: userHelper.getID(collaborator),
    avatarUrl: userHelper.getAvatarURL(collaborator),
  }
}

export const CustomPersona = React.memo(CustomPersona_)

CustomPersona.propTypes = {
  collaborator: PropTypes.object.isRequired,
}

export const Teams = ({ email }) => {
  const dispatch = useDispatch()
  const executeDeepLink = useDeepLinkExecutor()
  const onClick = () => {
    dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.GROWTH_OPEN_TEAMS_CONVERSATION))
    const link = getDeepLinkToOpenChat({ emails: email })
    executeDeepLink(link)
  }
  const iconProps = {
    iconName: ICON_NAMES.TeamsLogo,
    styles: {
      root: {
        color: 'var(--color-teams)',
        fontSize: 18,
      },
    },
  }

  return <IconButton title="Open a chat with this person" iconProps={iconProps} onClick={onClick} />
}

Teams.propTypes = {
  email: PropTypes.string.isRequired,
}

const OneOnOneIconProps = {
  iconName: 'People',
  styles: {
    root: {
      fontSize: 18,
    },
  },
}

export const OneOnOne = ({ email }) => {
  const dispatch = useDispatch()
  const teamsConfig = useTeamsConfig()
  const executeDeepLink = useDeepLinkExecutor()
  const onClick = () => {
    dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.GROWTH_OPEN_ONE_ON_ONE))
    const link = teamsHelper.getDeeplinkWithAppInfo(teamsConfig).toOneOnOne(email)
    executeDeepLink(link)
  }
  return <IconButton title="Open One on One" iconProps={OneOnOneIconProps} onClick={onClick} />
}

OneOnOne.propTypes = {
  email: PropTypes.string.isRequired,
}

export const useInviteUser = dispatch => {
  const { t } = useTranslation()
  return useCallback(
    email => {
      const immutableItem = fromJS({
        name: t('growth.invite.item_name'),
        descriptionText: t('growth.invite.item_notes'),
        owner_username: email,
      })
      return dispatch(postItem(immutableItem))
    },
    [t, dispatch]
  )
}

export const useLoadingMsCollaborators = (graphCollaboratorsCount = 0) => {
  const pendingMsCollaborators = useSelector(state =>
    isPendingRequestForPMResource(state, PM_API_RESOURCES.MS_COLLABORATORS)
  )
  const needMsCollaborators = useSelector(state =>
    isNeededRequestForPMResource(state, PM_API_RESOURCES.MS_COLLABORATORS)
  )
  const error = useSelector(state => isErrorRequestForPMResource(state, PM_API_RESOURCES.MS_COLLABORATORS))
  return {
    pending: pendingMsCollaborators,
    loading: (pendingMsCollaborators || needMsCollaborators) && !graphCollaboratorsCount && !error,
  }
}
