import React, { memo, useCallback } from 'react'
import { List } from '@fluentui/react/lib/List'
import { HeaderUsersListCell } from './HeaderUsersListCell'
import { UsersListCell } from './UsersListCell'
import { USER_OPTION_TYPE } from '../../../helpers/usersOptionsHelpers'
import { useMe } from '@/common/src/hooks/usersHooks'
import { userHelper } from '@/common/src/helpers'

export const UsersList = memo(
  ({ usersOptions, project, onSelectUser, onlyClickableMe = false, onShowError, myselfSecondaryText, ...rest }) => {
    const readOnly = !onSelectUser
    const me = useMe()

    const createOnSelect = useCallback(
      user => () => {
        onSelectUser(user)
      },
      [onSelectUser]
    )

    const onRenderCell = useCallback(
      option => {
        const { itemType, key, text, user, infoLink } = option
        if (itemType === USER_OPTION_TYPE.HEADER) {
          return <HeaderUsersListCell key={key} text={text} infoLink={infoLink} />
        } else {
          const userListCellProps = {
            user,
            readOnly,
            project,
            onShowError,
            highlightAdmin: true,
          }
          if (userHelper.getID(me) === userHelper.getID(user) && myselfSecondaryText) {
            userListCellProps.personaProps = { secondaryText: { children: myselfSecondaryText } }
          }
          if (!readOnly && (!onlyClickableMe || userHelper.getID(me) === userHelper.getID(user))) {
            userListCellProps.onClick = createOnSelect(user)
          }
          return <UsersListCell {...userListCellProps} />
        }
      },
      [readOnly, project, onShowError, me, myselfSecondaryText, onlyClickableMe, createOnSelect]
    )

    return <List items={usersOptions} onRenderCell={onRenderCell} {...rest} />
  }
)
