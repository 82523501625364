import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import { GhostItemCell } from '../../itemCell/GhostItemCell'

const ListContainer = styled.div`
  position: relative;
  flex: 1 1 auto;
  overflow: auto;

  @media print {
    overflow: visible;
  }
`

const LocalList = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: auto;
  padding-bottom: 50px;
  right: 0;
  left: 0;

  @media print {
    overflow: visible;
  }
`

export const StaticItemList = ({ rows, rowRenderer, noRowsRenderer, loading, className }) => {
  let elements
  if (loading) {
    elements = _.map(_.range(8), i => <GhostItemCell key={i} />)
  } else if (_.isEmpty(rows)) {
    return noRowsRenderer()
  } else {
    elements = _.map(rows, rowRenderer)
  }

  return (
    <ListContainer className={className}>
      <LocalList>{elements}</LocalList>
    </ListContainer>
  )
}

StaticItemList.propTypes = {
  rows: PropTypes.array,
  rowRenderer: PropTypes.func.isRequired,
  noRowsRenderer: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string,
}
