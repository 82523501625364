import {
  API_EVENT,
  isPMApiDetailedAction,
  parseApiActionType,
  PERSIST_REHYDRATE_ACTION_TYPE,
} from '../helpers/reducerHelper'
import { GENERIC_ACTION_TYPE } from '../actions/genericActions'
import { fromJS } from 'immutable'
import { PROJECTS_SUBSCRIPTIONS_REDUCER_KEYS as KEYS } from './projectSubscriptionsReducerKeys'
import { PM_API_RESOURCES } from '../constants'

export const base = fromJS({
  [KEYS.BY_ID]: {},
})

export const projectSubscriptions = (state = base, action) => {
  const { type, payload } = action
  const apiAction = parseApiActionType(type)
  if (isPMApiDetailedAction(apiAction)) {
    const { resource, event } = apiAction
    if (event === API_EVENT.SUCCESS) {
      const handler = successHandlers[resource]
      if (handler) {
        state = handler(state, action)
      }
    }
  } else {
    switch (type) {
      case GENERIC_ACTION_TYPE.CLEAR_ALL:
        {
          state = base
        }
        break
      case PERSIST_REHYDRATE_ACTION_TYPE:
        {
          if (payload.projectSubscriptions) {
            state = state.merge(payload.projectSubscriptions)
          }
        }
        break
    }
  }
  return state
}

const successHandlers = {
  [PM_API_RESOURCES.PROJECT_SUBSCRIPTIONS]: (state, { payload, meta }) => {
    const { subscriptions } = payload
    return state.setIn([KEYS.BY_ID, meta.projectId], fromJS(subscriptions))
  },
}
