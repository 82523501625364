/**
 *  Utils to interact with immutable items
 **/
import _ from 'lodash'
import { pickPredicate, safeMapFnCreator } from './immutableHelpers'
import { toDate } from './dateHelper'
let helper = {}

helper.KEYS = {
  REMINDER: 'reminder',
  REMINDER_DATE: 'reminder_date',
  REMINDER_FREQUENCY: 'repeat_after',
  REMINDER_ALL_FOLLOWERS: 'remind_all_followers',
  REMINDER_NOTES: 'notes',
  REMINDER_CREATION_DATE: 'creation_date',
  TIMESTAMP: 'timestamp',
  REMINDER_ENABLED: 'enabled',
  ID: 'id',
}

helper.FREQUENCY_TYPE = {
  ONCE: 'Once',
  NEVER: 'Never',
  DAILY: 'Daily',
  WEEKDAYS: 'Weekdays',
  WEEKLY: 'Weekly',
  BIWEEKLY: 'Biweekly',
  FOUR_WEEKLY: '4-weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  SEMESTERLY: 'Semesterly',
  YEARLY: 'Yearly',
}

helper.REMINDER_FREQUENCY_TYPE = {
  1: helper.FREQUENCY_TYPE.ONCE,
  2: helper.FREQUENCY_TYPE.DAILY,
  3: helper.FREQUENCY_TYPE.WEEKLY,
  4: helper.FREQUENCY_TYPE.BIWEEKLY,
  5: helper.FREQUENCY_TYPE.MONTHLY,
  6: helper.FREQUENCY_TYPE.YEARLY,
  7: helper.FREQUENCY_TYPE.WEEKDAYS,
}

helper.getId = r => r.get(helper.KEYS.ID)

helper.getReminderAllFollowers = r => r.get(helper.KEYS.REMINDER_ALL_FOLLOWERS)
helper.getReminderNotes = r => r.get(helper.KEYS.REMINDER_NOTES)

helper.prepareReminderForPut = r => {
  const obj = r.filter(pickPredicate(...PUT_WHITELIST)).toJS()
  return JSON.stringify(obj)
}

helper.getReminderFrequency = reminder => {
  const freq = reminder.get(helper.KEYS.REMINDER_FREQUENCY)
  if (!freq) {
    return helper.FREQUENCY_TYPE.NEVER
  }
  return helper.REMINDER_FREQUENCY_TYPE[freq]
}

helper.getTimestamp = (r, type = helper.KEYS.TIMESTAMP) => {
  const timestamp = r.get(type)
  return timestamp ? timestamp : 0
}

helper.getReminderTimestamp = r => {
  const timestamp = r.get(helper.KEYS.REMINDER_DATE)
  return timestamp ? timestamp : 0
}

helper.getReminderDate = r => toDate(helper.getReminderTimestamp(r))

helper.getReminderEnabled = r => r.get(helper.KEYS.REMINDER_ENABLED)

helper = _.mapValues(helper, f => (_.isFunction(f) ? safeMapFnCreator(f) : f))
export default helper

const PUT_WHITELIST = [
  'id',
  'reminder',
  'reminder_date',
  'repeat_after',
  'remind_all_followers',
  'enabled',
  'item',
  'notes',
  'resource_uri',
]
