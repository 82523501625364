import React from 'react'
import styled from 'styled-components'
import { projectHelper } from '../../common/src/helpers'
import { useSelectedProject } from '../../hooks/PMHooks'

const Title = styled.span`
  font-size: 17px;
  font-weight: 300;
`

export const SelectedProjectTitle = ({ ...rest }) => {
  const project = useSelectedProject()
  const name = (project && projectHelper.getName(project)) || ''
  return <Title {...rest}>{name}</Title>
}
