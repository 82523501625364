import React from 'react'
import { cn } from '@appfluence/classnames'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Icon } from '@fluentui/react/lib/Icon'
import { ICON_NAMES } from '../../common/src/msIcons'
import { useExtendedHistory } from '../../hooks/useExtendedHistory'
import { TooltipHost } from '@/components/tooltip/TooltipHost'
import { useId } from '../../hooks/useId'

export const MobileNavBarButton = styled(Icon)`
  color: ${p => p.theme.palette.always_white};
  font-size: large;
  padding: 12px;
  cursor: pointer;
  transition: background 0.1s ease-out;

  &:active {
    background: #005a9e;
    transition: none;
  }

  &.hidden {
    visibility: hidden;
  }
`

const MobileNavBarButtonContainerDiv = styled.div`
  color: ${p => p.theme.palette.always_white};

  & i {
    color: ${p => p.theme.palette.always_white};
  }

  &.disabled i {
    color: #c4c4c4;
  }

  padding: 12px;
  transition: background 0.1s ease-out;
  cursor: pointer;
  app-region: no-drag;

  &.disabled {
    cursor: default;
  }

  &:not(.disabled):active {
    background: #005a9e;
    transition: none;
  }
`

export const MobileNavBarButtonContainer = React.memo(({ tooltip, disabled, className, ...rest }) => {
  const id = useId('tooltip')
  const containerClassName = cn(className, { disabled })
  const main = <MobileNavBarButtonContainerDiv className={containerClassName} {...rest} />
  if (tooltip) {
    return (
      <TooltipHost className={rest.className} content={tooltip} id={id}>
        {main}
      </TooltipHost>
    )
  }
  return main
})

MobileNavBarButtonContainer.propTypes = {
  tooltip: PropTypes.string,
}

const MobileBackButton_ = props => {
  const { history, count: historyCount } = useExtendedHistory()
  const onClickBack = () => history.goBack()
  const backClassName = cn({
    hidden: !historyCount,
  })
  return (
    <MobileNavBarButton onClick={onClickBack} iconName={ICON_NAMES.ChromeBack} className={backClassName} {...props} />
  )
}

export const MobileBackButton = React.memo(MobileBackButton_)
