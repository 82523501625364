import React, { memo } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { Shimmer, ShimmerElementType } from '@fluentui/react/lib/Shimmer'
import { FlexRow } from '../layout/FlexContainer'

const Container = styled.div`
  height: 46px;
`

const SubContainer = styled(FlexRow)`
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
`

const Circle = styled(Shimmer).attrs({
  shimmerElements: [
    {
      type: ShimmerElementType.circle,
      height: 28,
    },
  ],
})`
  margin-right: 5px;
`

const Line = styled(Shimmer).attrs({
  shimmerElements: [
    {
      type: ShimmerElementType.line,
      height: 12,
    },
  ],
})`
  flex: 1;
`

export const GhostItemCell = memo(({ shimmerColors, ...rest }) => {
  const [width] = React.useState(() => _.random(25, 90) + '%')
  return (
    <Container {...rest}>
      <SubContainer>
        <Circle shimmerColors={shimmerColors} />
        <Line shimmerColors={shimmerColors} width={width} />
      </SubContainer>
    </Container>
  )
})
