import React from 'react'
import styled from 'styled-components'
import { Icon } from '@fluentui/react/lib/Icon'
import { cn } from '@appfluence/classnames'
import { RIGHT_PANEL } from '../../../actions/uiActions'
import { useRightPanel, useSelectedProjectId } from '../../../hooks/PMHooks'

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${p => p.size + 'px'};
  width: ${p => p.size + 'px'};
  border: 2px solid ${p => p.theme.palette.white};
  border-radius: 50px;
  box-sizing: content-box;

  &.isText {
    background: ${p => p.theme.palette.neutralLighter};
  }

  &.isIcon {
    background: ${p => p.theme.palette.themePrimary};
  }
`

const Title = styled.div`
  font-size: 8px;
  line-height: 8px;
  color: ${p => p.theme.palette.black};
`

const PlusIcon = styled(Icon)`
  font-size: ${p => p.size * 0.5 + 'px'};
  color: ${p => p.theme.palette.white};
`

const ExtraText = styled.p`
  color: ${p => p.theme.palette.themePrimary};
  margin: 0 0 0 5px;
`

export const ExtraUserCollectionCell = ({ text, iconName, size = 24, className, extraText, ...rest }) => {
  const selectedProjectIdIsValid = !isNaN(useSelectedProjectId())
  const rightPanel = useRightPanel()
  const isProyectDetails = selectedProjectIdIsValid && rightPanel === RIGHT_PANEL.PROJECT
  if (isProyectDetails && !text) {
    size = size * 1.4 //The add button on a proyect is 40% bigger
  }
  if (!text && !iconName) {
    return false
  }
  const containerClassName = cn(className, { isText: !!text, isIcon: !!iconName })
  return (
    <div className={cn('flex items-center', !rest.disabled && 'cursor-pointer')}>
      <IconContainer size={size} className={containerClassName} {...rest}>
        {text ? <Title>{text}</Title> : <PlusIcon size={size} iconName={iconName} />}
      </IconContainer>
      {extraText && <ExtraText>{extraText}</ExtraText>}
    </div>
  )
}
