import React from 'react'
import _ from 'lodash'
import { Dropdown } from '@fluentui/react'
import { Dropdown as DropdownV9, Option } from '@fluentui/react-components'
import { QuadrantMenuItem } from '../menus/QuadrantMenuItem'

const styles = {
  callout: {
    minWidth: '300px',
  },
  dropdownItem: {
    overflowX: 'hidden',
  },
}

const quadrantsOptions = _.map([0, 1, 2, 3], q => ({ key: q }))

export const QuadrantsDropdown = ({
  project,
  quadrant = 0,
  onQuadrantChanged,
  enableEmpty = false,
  textEmpty = '',
  disabled: _disabled,
  ...rest
}) => {
  const _onChange = (ev, option) => {
    const { key } = option
    onQuadrantChanged(key)
  }
  const options = enableEmpty ? [{ key: -1, text: textEmpty }, ...quadrantsOptions] : quadrantsOptions

  const disabled = _disabled ?? !project

  const onRenderOption = option => {
    const { key } = option
    if (key === -1) {
      return option.text
    }
    return <QuadrantMenuItem project={project} quadrant={key} className="p-0" />
  }

  const onRenderTitle = options => {
    const option = _.first(options)
    return onRenderOption(option)
  }

  return (
    <Dropdown
      options={options}
      onChange={_onChange}
      onRenderOption={onRenderOption}
      onRenderTitle={onRenderTitle}
      selectedKey={quadrant}
      styles={styles}
      disabled={disabled}
      {...rest}
    />
  )
}

export const QuadrantsDropdownV9 = ({
  project,
  quadrant = 0,
  onQuadrantChanged,
  enableEmpty = false,
  textEmpty = '',
  disabled = false,
  ...rest
}) => {
  const options = enableEmpty ? [-1, 0, 1, 2, 3] : [0, 1, 2, 3]
  return (
    <DropdownV9
      button={quadrant === -1 ? textEmpty : <QuadrantMenuItem project={project} quadrant={quadrant} className="p-0" />}
      onOptionSelect={(event, data) => onQuadrantChanged(+data.selectedOptions[0])}
      selectedOptions={[`${quadrant}`]}
      disabled={disabled}
      {...rest}
    >
      {options.map(q => (
        <Option key={q} value={`${q}`}>
          {q === -1 ? textEmpty : <QuadrantMenuItem project={project} quadrant={q} className="p-0" />}
        </Option>
      ))}
    </DropdownV9>
  )
}
