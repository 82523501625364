import { cn } from '@appfluence/classnames'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AllProducts, StoreProduct } from '../../integrations/electron'
import { StaticLoadingView } from '../LoadingView'
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown'
import { Button, Text, Spinner } from '@fluentui/react-components'
import { BackButton } from '../../components/buttons/NavButtons'
import { Link } from 'react-router-dom'

interface IState {
  products: StoreProduct[]
  loading: boolean
  selectedKey: string
  purchaseButtonDisabled: boolean
}

interface ISubscriptionOption extends IDropdownOption {
  key: string
}

const defState: IState = {
  products: [],
  loading: true,
  purchaseButtonDisabled: false,
  selectedKey: '',
}

const useProducts = () => {
  const [state, setState] = useState<IState>(defState)
  useEffect(() => {
    window.electronAPI?.getProducts(AllProducts).then(products =>
      setState(s => ({
        ...s,
        loading: false,
        products,
        selectedKey: products[0]?.productIdentifier,
      }))
    )
  }, [])

  return {
    state,
    selectOption: (opt: ISubscriptionOption) => setState(s => ({ ...s, selectedKey: opt.key })),
    disablePurchaseButton: () => setState(s => ({ ...s, purchaseButtonDisabled: true })),
  }
}

export const useOptions = (products: StoreProduct[]): ISubscriptionOption[] => {
  return useMemo(
    () =>
      products.map(p => {
        return {
          key: p.productIdentifier,
          text: p.localizedTitle,
        }
      }),
    [products]
  )
}

export const InAppPurchase = () => {
  const { state, selectOption, disablePurchaseButton } = useProducts()
  const { t } = useTranslation()
  const { products, loading, selectedKey, purchaseButtonDisabled } = state
  const options = useOptions(products)
  const selectedProduct = useMemo(() => {
    return products.find(p => p.productIdentifier === selectedKey)
  }, [selectedKey, products])
  if (loading) {
    return <StaticLoadingView />
  }
  const onChange = (_event: any, item: any): void => {
    selectOption(item as ISubscriptionOption)
  }
  const onClick = () => {
    window.electronAPI?.purchaseProduct(selectedKey)
    disablePurchaseButton()
  }
  const spinnerCN = cn('transition duration-700 ease-in-out', {
    'opacity-0': !purchaseButtonDisabled,
    'h-0': !purchaseButtonDisabled,
  })
  const buttonCN = cn({
    pm_hidden: purchaseButtonDisabled,
  })
  return (
    <div className="m-8 flex flex-col items-center justify-start">
      <div className="absolute left-0 top-4 m-2">
        <Link to="/">
          <BackButton>{t('general.go_back')}</BackButton>
        </Link>
      </div>
      <Text className="mb-6 text-lg">{t('inapp.title')}</Text>
      <Dropdown
        options={options}
        selectedKey={selectedKey}
        className="w-44"
        label={t('inapp.select_plan')}
        onChange={onChange}
      />
      <Text className="m-8">
        <p>{selectedProduct?.localizedDescription}</p>
        <p>
          {t('inapp.price')}: {selectedProduct?.formattedPrice}
        </p>
        <p>
          {`${t('inapp.subscription_period')}: ${selectedProduct?.subscriptionPeriod?.numberOfUnits} ${selectedProduct
            ?.subscriptionPeriod?.unit}`}
        </p>
      </Text>

      <Spinner size="tiny" className={spinnerCN} label={t('inapp.wait_store')} />
      <Button appearance="primary" className={buttonCN} onClick={onClick} disabled={purchaseButtonDisabled}>
        {t('inapp.purchase')}
      </Button>
    </div>
  )
}
