import { Field, Select } from '@fluentui/react-components'
import { itemHelper, reminderHelper } from '../../common/src/helpers'
import { useTranslation } from 'react-i18next'

// Map every possible frequency type to a translation key (date.frequency.never, date.frequency.once, etc.)
export const FREQUENCY_TRANSLATION_KEYS = {
  [itemHelper.FREQUENCY_TYPE.NEVER]: 'never',
  [reminderHelper.FREQUENCY_TYPE.ONCE]: 'once',
  [itemHelper.FREQUENCY_TYPE.DAILY]: 'daily',
  [itemHelper.FREQUENCY_TYPE.WEEKDAYS]: 'weekdays',
  [itemHelper.FREQUENCY_TYPE.WEEKLY]: 'weekly',
  [itemHelper.FREQUENCY_TYPE.BIWEEKLY]: 'biweekly',
  [itemHelper.FREQUENCY_TYPE.FOUR_WEEKLY]: 'four_weekly',
  [itemHelper.FREQUENCY_TYPE.MONTHLY]: 'monthly',
  [itemHelper.FREQUENCY_TYPE.BIMONTHLY]: 'bimonthly',
  [itemHelper.FREQUENCY_TYPE.QUARTERLY]: 'quarterly',
  [itemHelper.FREQUENCY_TYPE.SEMESTERLY]: 'semesterly',
  [itemHelper.FREQUENCY_TYPE.YEARLY]: 'yearly',
  [itemHelper.FREQUENCY_TYPE.FIXED_WEEK]: 'fixed_week',
}

// Default options that will be shown if no frequencyKeys are passed to the FrequencyDropdown
const DEFAULT_FREQUENCY_OPTION_KEYS = [
  itemHelper.FREQUENCY_TYPE.NEVER,
  itemHelper.FREQUENCY_TYPE.DAILY,
  itemHelper.FREQUENCY_TYPE.WEEKDAYS,
  itemHelper.FREQUENCY_TYPE.WEEKLY,
  itemHelper.FREQUENCY_TYPE.BIWEEKLY,
  itemHelper.FREQUENCY_TYPE.FOUR_WEEKLY,
  itemHelper.FREQUENCY_TYPE.MONTHLY,
  itemHelper.FREQUENCY_TYPE.BIMONTHLY,
  itemHelper.FREQUENCY_TYPE.QUARTERLY,
  itemHelper.FREQUENCY_TYPE.SEMESTERLY,
  itemHelper.FREQUENCY_TYPE.YEARLY,
  itemHelper.FREQUENCY_TYPE.FIXED_WEEK,
]

export const FrequencyDropdown = ({ selectedKey, onChange, frequencyKeys = DEFAULT_FREQUENCY_OPTION_KEYS, label }) => {
  const { t } = useTranslation()
  const onChangeLocal = (ev, data) => {
    onChange(data.value)
  }

  return (
    <Field label={label} className="w-full">
      <Select value={selectedKey} onChange={onChangeLocal}>
        {frequencyKeys.map(key => (
          <option key={key} value={key}>
            {t(`date.frequency.${FREQUENCY_TRANSLATION_KEYS[key]}`)}
          </option>
        ))}
      </Select>
    </Field>
  )
}
