import { fromJS, Set } from 'immutable'
import { KEYS } from './workloadKeys'
import { ACTION_TYPES } from '../actions/workloadActions'

const BASE_STATE = fromJS({
  [KEYS.TAG_BLACKLIST]: Set(['onhold']),
  [KEYS.USERNAME_BLACKLIST]: Set(),
  [KEYS.SHOW_COMPLETED]: true,
})

const handlers = {
  [ACTION_TYPES.TOGGLE_USERNAME_STATUS]: (state, { payload }) => {
    const { username, status } = payload
    let bl = state.get(KEYS.USERNAME_BLACKLIST)
    bl = status ? bl.delete(username) : bl.add(username)
    return state.set(KEYS.USERNAME_BLACKLIST, bl)
  },
  [ACTION_TYPES.SET_BLACKLIST]: (state, { payload }) => {
    const { usernameSet } = payload
    return state.set(KEYS.USERNAME_BLACKLIST, usernameSet)
  },
  [ACTION_TYPES.SET_SHOW_COMPLETED]: (state, { payload }) => {
    const { showCompleted } = payload
    return state.set(KEYS.SHOW_COMPLETED, showCompleted)
  },
}

export const workload = (state = BASE_STATE, action) => {
  return handlers[action.type]?.(state, action) ?? state
}
