import React, { useEffect } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const useMainLoading = () => {
  useEffect(() => {
    const element = document.getElementById('mainLoading')
    element.classList.add('loadingContainer--hide')
  }, [])
}

export const MainLoadingView = ({ children, loading = false }) => {
  useMainLoading()
  const content = !loading && children
  return <Container>{content}</Container>
}
