import _ from 'lodash'
import Color from 'color'

export const adjustColorForBackground = (color, threshold = 0, isDarkTheme = false) => {
  let colorObj = Color(color).hsl()
  const lightness = colorObj.color[2]
  if (lightness < 0.1) {
    colorObj.color[2] = 40 + colorObj.color[2] * 1.5
  } else if (lightness < 0.4) {
    colorObj.color[2] += colorObj.color[2] * 1.2
  }

  colorObj.color[2] += threshold
  if (isDarkTheme) {
    colorObj.color[2] = (100 - colorObj.color[2]) * 1.1
  }

  return colorObj.rgb().string()
}

const LIGHTNESS_DIFF = 20
export const adjustColorForHeader = baseColor => {
  const baseColorObj = Color(baseColor)
  let colorObj = Color(adjustColorForBackground(baseColor)).hsl()
  const lightness = colorObj.color[2]
  const lightnessAlternatives = [lightness - LIGHTNESS_DIFF, lightness + LIGHTNESS_DIFF]
  const colorAlternatives = _.map(lightnessAlternatives, l => {
    const c = Color(colorObj).hsl() //clone
    c.color[2] = l
    return c
  })
  colorObj = _.maxBy(colorAlternatives, c => {
    return c.contrast(baseColorObj)
  })
  //console.log('contrast', colorObj.contrast(baseColorObj))
  return colorObj.rgb().string()
}

export const bestTextColorForBackgroundColor = background => {
  return Color('white').contrast(Color(background)) > 4.5 ? 'white' : 'black'
}

export const arrayToRGBA = a => `rgba(${a[0]}, ${a[1]}, ${a[2]}, ${a[3]})`

export const quadrantColorStringToArray = c => {
  if (_.isString(c)) {
    const json = c.replace('{', '[').replace('}', ']')
    try {
      const colors = JSON.parse(json)
      return _.map(colors, (v, k) => (k < 3 ? _.round(v * 255) : v))
    } catch {
      return [1, 1, 1, 1]
    }
  }
  return [1, 1, 1, 1]
}

export const quadrantColorStringFromObject = color => {
  const { r = 0.5, g = 0.5, b = 0.5, alpha = 1 } = color
  const decimalLimit = 5
  const normalizedNumber = n => (n / 255).toFixed(decimalLimit)
  return `{${normalizedNumber(r)}, ${normalizedNumber(g)}, ${normalizedNumber(b)}, ${alpha.toFixed(decimalLimit)}}`
}
