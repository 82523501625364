import React from 'react'
import { RouteWithId } from './routeWithID'
import { RouteListener } from '../components/routeListener/routeListener'

export const PublicRoute = ({ component: Component, render, ...rest }) => {
  const _render = props => {
    if (Component) {
      return <Component {...props} />
    } else if (render) {
      return render(props)
    }
  }
  return (
    <>
      <RouteListener routeId={rest.routeId} />
      <RouteWithId render={_render} {...rest} />
    </>
  )
}
