import React from 'react'
import { cn } from '@appfluence/classnames'
import { BasicCard } from '../../components/card/BasicCard'
import { InfoIconCallout } from '../../components/info/InfoIconCallout'
import { ICON_NAMES } from '../../common/src/msIcons'
import { useTranslation } from 'react-i18next'
import { SERVER_URLS } from '../../common/src/constants'
import { useSelector } from 'react-redux'
import { graphStateHelper } from '../../common/src/helpers'
import { TooltipHost } from '@/components/tooltip/TooltipHost'

export const HomeInfoCard = () => {
  const { t } = useTranslation()
  const isGraphEnabled = useSelector(graphStateHelper.isGraphEnabled)
  const cardClassname = cn('h-28 !p-4 box-border', {
    pm_hidden: isGraphEnabled,
  })
  const calloutClassname = cn({
    pm_hidden: isGraphEnabled,
  })
  const calloutLink = {
    title: t('general.connect'),
    onClick: () => {
      const href = `${SERVER_URLS.GRAPH_PERMISSIONS}?next=${window.location.pathname}`
      window.open(href, '_blank')
    },
  }
  return (
    <BasicCard className={cardClassname}>
      <TooltipHost content={t('office.connect_outlook_calendar')}>
        <InfoIconCallout
          title={t('office.outlook_calendar_callout_title')}
          message={t('office.outlook_calendar_callout_message')}
          className={calloutClassname}
          link={calloutLink}
          iconSize={16}
          animate={false}
          calloutMaxWidth={300}
          iconName={ICON_NAMES.OutlookLogo}
        />
      </TooltipHost>
    </BasicCard>
  )
}
