import type { Project } from '@/types'
import { forwardRef } from 'react'
import { QuadrantSelector } from '../input/quadrant/QuadrantSelector'
import { projectHelper } from '@/common/src/helpers'
import { useSelector } from 'react-redux'
import { getPreferredMatrixViewMode } from '@/selectors/uiSelectors'
import { Link } from 'react-router-dom'
import { getRelativePathToMatrixBasedOnMode } from '@/helpers/routeHelper'
import type { ProjectCountersWithOverdue } from '@/queries/projectCounters'
import { cn } from '@/modules/classnames'

interface IProps {
  project: Project
  counts?: ProjectCountersWithOverdue
  className?: string
}

export const ProjectGridCell = forwardRef<HTMLAnchorElement, IProps>(({ project, counts, className }, ref) => {
  const mode = useSelector(getPreferredMatrixViewMode)
  const projectIdd = projectHelper.getIdd(project)
  const name = projectHelper.getName(project)
  return (
    <Link
      ref={ref}
      to={getRelativePathToMatrixBasedOnMode(mode)(projectIdd)}
      className={cn(
        'box-border flex h-[7.7rem] w-[5.5rem] cursor-pointer flex-col items-center rounded-md p-2 no-underline hover:bg-neutral-200 dark:hover:bg-neutral-700',
        className
      )}
    >
      <QuadrantSelector className="m-1" size={40} project={project} readOnly counters={counts} />
      <span className="overflow-wrap-anywhere line-clamp-3 text-center text-black dark:text-white">{name}</span>
    </Link>
  )
})
