import React from 'react'
import { cn } from '@appfluence/classnames'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import welcomeSVG from '../../resources/images/illustrations/undraw_welcome_3gvl.svg'
import { useTranslation } from 'react-i18next'

export const OneOnOneHubPlaceholderText = () => {
  const narrow = useNarrowWidth()
  const { t } = useTranslation()
  return (
    <div className="flex-centered-container flex-column text-centered">
      <h1>{t('one_on_one_hub.welcome_placeholder_0.welcome')}</h1>
      <div>
        <img src={welcomeSVG} className={cn('h-60', narrow && 'max-h-36')} alt="Welcome" />
      </div>
      <h5>{t('one_on_one_hub.welcome_placeholder_0.by')}</h5>
      <p className="ms-sm12 ms-md8 text-justified">
        <b>{t('one_on_one_hub.welcome_placeholder_0.title')}</b> {t('one_on_one_hub.welcome_placeholder_0.message_0')}
      </p>
      <p className="ms-sm12 ms-md8 text-justified">{t('one_on_one_hub.welcome_placeholder_0.message_1')}</p>
    </div>
  )
}
