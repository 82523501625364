/**
 *  Utils to interact with immutable (project & item) tag
 **/
import _ from 'lodash'
import { safeMapFnCreator } from './immutableHelpers'

let _export = {}

_export.STARRED_TAG_NAME = 'starred'
_export.ARCHIVED_TAG_NAME = 'archived'
_export.READONLY_TAG_NAME = 'read_only'
_export.AI_TAG_NAME = 'ai'

_export.SPECIAL_PROJECTS_TAGS = [_export.ARCHIVED_TAG_NAME]
_export.SPECIAL_ITEMS_TAGS = [_export.STARRED_TAG_NAME]

_export.KEYS = {
  ID: 'id',
  NAME: 'name',
}

_export.getID = t => t.get(_export.KEYS.ID)
_export.getName = t => t.get(_export.KEYS.NAME)

export default _.mapValues(_export, f => (_.isFunction(f) ? safeMapFnCreator(f) : f))
