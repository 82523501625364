import { ItemFromCSV } from './types/item'
import { createContext, useContext } from 'react'

type PrintItemsContext = {
  printItems: (itemList: ItemFromCSV[]) => void
}
export const PrintItemsContext = createContext<PrintItemsContext | null>(null)
export const usePrintItems = () => {
  const object = useContext(PrintItemsContext)
  if (!object) throw new Error('usePrintItems must be used within a PrintItemsContext.Provider')
  return object
}

type IconModalProps = {
  currentIcon: string
  onChangeIcon: (newIcon: string) => void
}
type ChangeIconContext = {
  openModal: (modalProps: IconModalProps) => void
}
export const ChangeIconContext = createContext<ChangeIconContext | null>(null)
export const useChangeIcon = () => {
  const object = useContext(ChangeIconContext)
  if (!object) throw new Error('useChangeIcon must be used within a ChangeIconContext.Provider')
  return object
}

export type ItemDatePanelResult = {
  startDate?: Date | null
  dueDate?: Date | null
  allDay?: boolean | null
  frequency?: string | null
  untilDate?: Date | null
}
export type OnSaveItemDates = (data: ItemDatePanelResult) => void

type ItemDatesPanelContext = {
  openPanel: (onSaveDates: OnSaveItemDates) => void
}

export const ItemDatesPanelContext = createContext<ItemDatesPanelContext | null>(null)
export const useItemDatesPanel = () => {
  const object = useContext(ItemDatesPanelContext)
  if (!object) throw new Error('useItemDates must be used within a ItemDatesContext.Provider')
  return object
}

type OwnerPanelContext = {
  openPanel: (onSaveOwner: (newOwner: string) => void, project: any) => void
}

export const OwnerPanelContext = createContext<OwnerPanelContext | null>(null)
export const useOwnerPanel = () => {
  const object = useContext(OwnerPanelContext)
  if (!object) throw new Error('useOwnerPanel must be used within a OwnerPanelContext.Provider')
  return object
}
