import React, { memo } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { Shimmer, ShimmerElementType } from '@fluentui/react/lib/Shimmer'
import { QuadrantSelector } from '../input/quadrant/QuadrantSelector'
import { FlexColumn, FlexRow } from '../layout/FlexContainer'

const Container = styled(FlexRow)`
  align-items: center;
  justify-content: flex-start;
  padding: 4px 8px;
`

const Main = styled(FlexColumn)`
  height: 43px;
  justify-content: center;
  flex: 1;
  min-width: 1px;
  padding-left: 10px;
`

const SShimmer = styled(Shimmer).attrs({
  shimmerElements: [
    {
      type: ShimmerElementType.line,
      height: 12,
    },
  ],
})``

const NameShimmer = styled.div`
  padding-top: 2px;
  padding-bottom: 2px;
`

const DetailsShimmer = styled.div`
  padding-top: 2px;
  padding-bottom: 2px;
`

export const GhostProjectCell = memo(({ shimmerColors }) => {
  const firstWidth = _.random(25, 100) + '%'
  const secondWidth = _.random(10, 90) + '%'
  return (
    <Container>
      <QuadrantSelector size={32} />
      <Main>
        <NameShimmer>
          <SShimmer shimmerColors={shimmerColors} width={firstWidth} />
        </NameShimmer>
        <NameShimmer shimmerColors={shimmerColors} width={firstWidth} />
        <DetailsShimmer>
          <SShimmer shimmerColors={shimmerColors} width={secondWidth} />
        </DetailsShimmer>
      </Main>
    </Container>
  )
})
