import React, { memo } from 'react'
import { MainLayout } from '../../components/layout/MainLayout'
import { FeedView } from './FeedView'
import { RightDetailView } from '../rightDetailView/RightDetailView'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { ProjectLevelTopBar } from '../project/ProjectLevelTopBar'
import { projectHelper } from '../../common/src/helpers'
import { TopRightBar } from '../../components/layout/TopRightBar'
import { useTranslation } from 'react-i18next'

const navigationForProject = getRelativeURLKeepingQuerySearch.feedForProjectId

export const Feed = memo(({ project, projectSelectorEnabled = true, items }) => {
  const projectID = project ? projectHelper.getIdd(project) : 0 // o is global
  const { t } = useTranslation()
  const content = {
    main: <FeedView projectID={projectID} />,
    rightSide: <RightDetailView />,
  }

  if (projectID) {
    content.topBar = (
      <ProjectLevelTopBar
        getNavigationURLForProjectId={navigationForProject}
        key={'ProjectMatrixViewTopBar'}
        project={project}
        projectSelectorEnabled={projectSelectorEnabled}
        readOnly={false}
        items={items}
      />
    )
  } else {
    content.topBar = <TopRightBar title={t('common_localized_strings.global_feed')} />
  }
  return <MainLayout {...content} />
})
