import { createAction } from 'redux-api-middleware'
import _ from 'lodash'
import { PM_API_RESOURCES, PM_API_URLS } from '../constants'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'
import { createPaginatedAPIAction } from '../helpers/paginationHelper'
import { appendQueryParamsToURL, extendPMHeaders, getCredentialsConfig } from '../helpers/requestHelper'

export const getMe = () =>
  createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.ME),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.ME),
    headers: extendPMHeaders(),
  })

export const getCollaborators = createPaginatedAPIAction((params = {}) => {
  params.limit = 1000
  return createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.COLLABORATORS, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.COLLABORATORS),
    headers: extendPMHeaders(),
  })
})
export const getCollaboratorsDebounced = _.debounce(getCollaborators, 250, { maxWait: 500, leading: true })

export const isLoggedIn = () => {
  return async dispatch => {
    try {
      const types = generateActionTypesForPMAPI(PM_API_RESOURCES.IS_LOGGED_IN)
      const successType = types[1]
      //manually parse response https://github.com/agraboso/redux-api-middleware#success-type-descriptors
      successType.payload = (action, state, res) => {
        return res.text()
      }
      const r = await dispatch(
        createAction({
          endpoint: appendQueryParamsToURL(PM_API_URLS.IS_LOGGED_IN),
          credentials: getCredentialsConfig(),
          method: 'GET',
          types,
          headers: extendPMHeaders(),
        })
      )
      if (r.payload === 'yes') {
        return true
      } else {
        throw false
      }
    } catch (e) {
      throw false
    }
  }
}

export const getPlatforms = () =>
  createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.PLATFORMS),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.PLATFORMS),
    headers: extendPMHeaders(),
  })

export const addCollaborator = email => {
  const emails = encodeURIComponent(email)
  const body = `emails=${emails}`
  return createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.COLLABORATORS),
    credentials: getCredentialsConfig(),
    method: 'POST',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.COLLABORATORS),
    headers: extendPMHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    }),
    body,
  })
}

export const getMsCollaborators = (params = {}) => {
  return createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.MS_COLLABORATORS, {
      paginate: 1, //must append this parameter
      ...params,
    }),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.MS_COLLABORATORS),
    headers: extendPMHeaders(),
  })
}

export const getAllMsCollaborators =
  (params = {}) =>
  async (dispatch, getState) => {
    const reqParams = {
      limit: 30,
      ...params,
    }
    let finished = false
    while (!finished) {
      const res = await dispatch(getMsCollaborators(reqParams))
      const token = _.get(res, 'payload.meta.nextSkipToken')
      finished = !token
      reqParams.skipToken = token
    }
  }

export const addOneOnOneHubCollaborator = email => {
  const emails = encodeURIComponent(email)
  const body = `emails=${emails}`
  return createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.ONE_ON_ONE_HUB_COLLABORATORS),
    credentials: getCredentialsConfig(),
    method: 'POST',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.ONE_ON_ONE_HUB_COLLABORATORS),
    headers: extendPMHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    }),
    body,
  })
}

export const getLicenseStatus = () => {
  const types = generateActionTypesForPMAPI(PM_API_RESOURCES.LICENSE_STATUS)
  const params = {
    cb: _.random(1e6),
  }
  return createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.LICENSE_STATUS, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types,
    headers: extendPMHeaders(),
  })
}
