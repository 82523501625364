import { fromJS, OrderedSet } from 'immutable'
import {
  API_EVENT,
  isPMApiDetailedAction,
  parseApiActionType,
  PERSIST_REHYDRATE_ACTION_TYPE,
} from '../helpers/reducerHelper'
import { GENERIC_ACTION_TYPE } from '../actions/genericActions'
import { MEETING_REDUCER_KEYS as KEYS } from './meetingReducerKeys'
import { PM_API_RESOURCES } from '../constants'
import _ from 'lodash'

const base = fromJS({
  [KEYS.BY_ID]: {},
  [KEYS.ITEMS_SET_BY_ID]: {},
  [KEYS.TEAMS_TAB_BY_ID]: {},
})

export const meetings = (state = base, action) => {
  const { type, payload } = action
  const apiAction = parseApiActionType(type)
  if (isPMApiDetailedAction(apiAction)) {
    const { resource, event } = apiAction
    if (event === API_EVENT.SUCCESS) {
      const handler = successHandlers[resource]
      if (handler) {
        state = handler(state, action)
      }
    }
  } else {
    switch (type) {
      case GENERIC_ACTION_TYPE.CLEAR_ALL:
        {
          state = base
        }
        break
      case PERSIST_REHYDRATE_ACTION_TYPE:
        {
          if (payload.meeting) {
            state = state.merge(payload.meeting)
          }
        }
        break
    }
  }
  return state
}

const successHandlers = {
  [PM_API_RESOURCES.MEETINGS]: (state, { payload }) => {
    const { objects: meetings } = payload
    return state.withMutations(_st => {
      const storeSingle = m => storeSingleMeeting(_st, m)
      _.each(meetings, storeSingle)
      return _st
    })
  },
  [PM_API_RESOURCES.MEETING]: (state, { payload: meeting }) => storeSingleMeeting(state, meeting),
  [PM_API_RESOURCES.NEAREST_MEETING]: (state, { payload: meeting }) => storeSelectedMeeting(state, meeting),
  [PM_API_RESOURCES.MEETING_ITEMS]: (state, { meta, payload }) => {
    const { meetingID } = meta
    const { objects: items } = payload
    if (!meetingID || _.isEmpty(items)) {
      return state
    }
    const set = state.getIn([KEYS.ITEMS_SET_BY_ID, meetingID]) || OrderedSet()
    const modifiedSet = set.withMutations(s => {
      _.each(items, i => s.add(i.id))
    })
    return state.setIn([KEYS.ITEMS_SET_BY_ID, meetingID], modifiedSet)
  },
  [PM_API_RESOURCES.TAB]: (state, { meta, payload }) => {
    if (!meta) {
      return state
    }
    const { id } = meta
    const meeting = payload.pm_meeting
    if (meeting?.id) {
      state = storeSingleMeeting(state, meeting)
      payload.pm_meeting = meeting.resource_uri
    }
    return state.setIn([KEYS.TEAMS_TAB_BY_ID, id], fromJS(payload))
  },
  [PM_API_RESOURCES.TAB_PUT]: (state, { meta, payload }) => {
    const { id } = meta
    const meeting = payload.pm_meeting
    if (meeting?.id) {
      state = storeSingleMeeting(state, meeting)
      payload.pm_meeting = meeting.resource_uri
    }
    return state.mergeIn([KEYS.TEAMS_TAB_BY_ID, id], fromJS(payload))
  },
}

const storeSingleMeeting = (state, meeting) => {
  const id = _.get(meeting, 'id')
  if (id) {
    return state.setIn([KEYS.BY_ID, id], fromJS(meeting))
  }
  return state
}

const storeSelectedMeeting = (state, meeting) => {
  const id = _.get(meeting, 'id')
  if (id) {
    return state.setIn([KEYS.SELECTED], fromJS(meeting))
  }
  return state
}
