import React from 'react'
import _ from 'lodash'
import { withRouter } from 'react-router'
import { projectHelper } from '../../common/src/helpers'
import { Dropdown } from '@fluentui/react'
import { mapValuesToJsArray } from '../../common/src/helpers/immutableHelpers'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { useProjectsMap } from '../../common/src/hooks/projectHooks'
import { renderProjectOption } from './renderProjectOption'
import { useSelectedProject } from '../../hooks/PMHooks'
import { FILTER_REDUCER_KEYS } from '@/common/src/reducers/filtersKeys.js'

const projectToOption = p => ({
  key: p.get('idd'),
  text: p.get('name'),
  data: { project: p },
})
const _sort = p => _.toLower(p.get('name'))

const styles = {
  root: {
    display: 'flex',
    minWidth: '1px',
  },
  dropdown: {
    width: '100%',
  },
  title: {
    borderStyle: 'none',
    fontSize: '17px',
    fontWeight: 300,
  },
  callout: {
    minWidth: '250px',
  },
  dropdownItem: {
    overflowX: 'hidden',
  },
}

const ProjectLevelDropdown_ = ({
  history,
  getNavigationURLForProjectId = getRelativeURLKeepingQuerySearch.matrixForProjectId,
  ...rest
}) => {
  const project = useSelectedProject()
  const projectsMap = useProjectsMap(true, FILTER_REDUCER_KEYS.PROJECTS_PANEL)

  const options = React.useMemo(() => {
    if (!project) {
      return false
    }
    const pidd = projectHelper.getIdd(project)
    let fullProjectsMap = projectsMap
    if (!projectsMap.has(pidd)) {
      //current is archived
      fullProjectsMap = projectsMap.set(pidd, project)
    }
    return mapValuesToJsArray(fullProjectsMap, projectToOption, void 0, _sort)
  }, [project, projectsMap])

  if (!project) {
    return false
  }
  const navigateToProjectWithId = idd => {
    const path = getNavigationURLForProjectId(idd)
    history.push(path)
  }
  const onChange = (ev, option) => {
    const idd = option.key
    navigateToProjectWithId(idd)
  }
  const selectedProjectId = projectHelper.getIdd(project)
  return (
    <Dropdown
      id={'projectView_titleDropdown'}
      options={options}
      onChange={onChange}
      selectedKey={selectedProjectId}
      styles={styles}
      onRenderOption={renderProjectOption}
      {...rest}
    />
  )
}

export const ProjectLevelDropdown = withRouter(ProjectLevelDropdown_)
