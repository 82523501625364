import React, { FC, Ref } from 'react'
import styled from 'styled-components'
import { cn } from '@appfluence/classnames'
import { AttributePlaceholder } from '../placeholder/AttributePlaceholder'
import { ICON_NAMES } from '../../common/src/msIcons'
import { useTranslation } from 'react-i18next'

const DatesRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: -7px;

  cursor: pointer;
  &.readOnly {
    cursor: inherit;
  }
`

const DateText = styled(AttributePlaceholder)`
  font-size: 12px;
  ${p => (p.overdue ? 'color: ' + p.theme.palette.negative : 'color: ' + p.theme.palette.themePrimary)}
`

const Name = styled.span`
  width: 30px;
  font-size: 10px;
  margin-right: 5px;
  color: ${p => p.theme.palette.neutralTertiary};
`

interface IProps {
  name: string
  text: string
  overdue?: boolean
  checkDue?: boolean
  onClick: () => void
  readOnly?: boolean
  styles: any
  className?: string
  iconName?: string
  containerRef: Ref<HTMLDivElement>
  children?: React.ReactNode
}

export const BasicDateSection: FC<IProps> = ({
  readOnly = false,
  onClick,
  styles = {},
  className = '',
  name,
  text,
  overdue = false,
  iconName = ICON_NAMES.DateTime,
  containerRef,
  children,
}) => {
  const readOnlyClassName = cn({ readOnly })
  const { t } = useTranslation()
  return (
    <div ref={containerRef} style={styles.root} className={cn('flex flex-row items-start', className)}>
      <DatesRow className={readOnlyClassName} onClick={readOnly ? undefined : onClick}>
        {name && <Name>{name}</Name>}
        {text ? (
          <DateText name={text} overdue={overdue} />
        ) : (
          <AttributePlaceholder id="dateSection_placeholderDate" name={t('date.set')} iconName={iconName} />
        )}
      </DatesRow>
      {children}
    </div>
  )
}
