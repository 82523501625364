import { useEffect, FC } from 'react'
import { FlexContainer } from '../components/layout/FlexContainer'
import { Spinner } from '../components/basic/Loading'
import { useExtendedHistory } from '../hooks/useExtendedHistory'
import { getRelativeURLKeepingQuerySearch } from '../helpers/routeHelper'

const useRedirectHome = (redirect: boolean) => {
  const { history } = useExtendedHistory()
  useEffect(() => {
    if (redirect) {
      setTimeout(() => {
        const path = getRelativeURLKeepingQuerySearch.homeRedirect()
        history.push(path)
      }, 4000)
    }
  }, [history, redirect])
}

type ViewCreator = (redirect: boolean) => FC<Record<string, never>>

const viewCreator: ViewCreator = redirect => () => {
  useRedirectHome(redirect)
  return (
    <FlexContainer>
      <Spinner size="large" />
    </FlexContainer>
  )
}

export const LoadingView = viewCreator(true)
export const StaticLoadingView = viewCreator(false)
