import styled from 'styled-components'
import { cn } from '@appfluence/classnames'
import { BasicCard } from '../../components/card/BasicCard'

const centeredColumnStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}

export const rowStyles = {
  cell: centeredColumnStyles,
  checkCell: centeredColumnStyles,
}

const Card = styled(BasicCard)`
  padding: 0;
  margin: 12px 0;
`

export const SectionHeader = props => (
  <div {...props} className={cn(props.className, 'flex items-center justify-between')} />
)

export const SectionTitle = props => <div {...props} className={cn(props.className, 'ml-3.5 text-sm font-semibold')} />

export const Placeholder = props => (
  <div {...props} className={cn(props.className, 'mx-2 pb-3 text-pm-neutral-secondary')} />
)

export const ResourcesSectionCard = ({ children, className }) => <Card className={className}>{children}</Card>
