import { FC, ReactElement } from 'react'
import { Menu, MenuItem, MenuProps, Button, MenuPopover, MenuList, MenuTrigger } from '@fluentui/react-components'
import { MoreHorizontalRegular } from '@fluentui/react-icons'

interface MenuItem {
  key: string
  onClick?: () => void
  text: string
  icon?: ReactElement
  style?: any
  disabled?: boolean
}

interface IProps extends MenuProps {
  menuProps: {
    items: MenuItem[]
  }
}

export const OptionsMenuButton: FC<IProps> = ({ menuProps, ...rest }) => {
  return (
    <Menu {...rest}>
      <MenuTrigger disableButtonEnhancement>
        <Button icon={<MoreHorizontalRegular />} />
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          {menuProps.items.map(item => {
            const { key, ...restItem } = item
            return (
              <MenuItem key={key} {...restItem}>
                {item.text}
              </MenuItem>
            )
          })}
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
