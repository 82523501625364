import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { getTeamsState } from '../common/src/selectors/teamsSelectors'
import { meetingsSelectors, teamsSelectors } from '../common/src/selectors'
import { TEAMS_KEYS } from '../common/src/reducers/teamsKeys'
import { isEmbeddedOnTeams } from '../helpers/queryParamsHelper'
import { getRelativeURLKeepingQuerySearch } from '../helpers/routeHelper'
import { teamsHelper } from '../common/src/helpers'
import { useMemo } from 'react'
import { getTeamsTab } from '../common/src/actions/teamsTabAPI'
import { queryParamsHelper } from '../helpers'
import { useFetch } from '../common/src/hooks/networkingHooks'

const TYPES = teamsHelper.SUB_ENTITY_TYPES

export const useConsumeDeepLink = () => {
  const teamsState = useSelector(getTeamsState)
  const needsContext = isEmbeddedOnTeams()
  if (!needsContext) {
    return {
      waiting: false,
    }
  }
  const didLoadContext = teamsState.get(TEAMS_KEYS.DID_LOAD_CONTEXT)
  if (!didLoadContext) {
    return {
      waiting: true,
    }
  }
  const subEntityId = teamsState.get(TEAMS_KEYS.SUB_ENTITY_ID)
  const data = getSubEntityData(subEntityId)
  return {
    waiting: false,
    link: getRedirectionLink(data),
    enableOnboarding: shouldEnableOnboarding(data),
    type: data.type,
  }
}

const isValidSubEntityId = subEntityId => _.isString(subEntityId) && subEntityId.length

const getSubEntityData = subEntityId => {
  if (!isValidSubEntityId(subEntityId)) {
    return {}
  }
  const [type, ...rest] = _.split(subEntityId, '-')
  const selection = _.join(rest, '-')
  return { type, selection }
}

const shouldEnableOnboarding = ({ type }) => type === TYPES.ONBOARDING

const getRedirectionLink = ({ type, selection }) => {
  switch (type) {
    case TYPES.ITEM:
      return getRelativeURLKeepingQuerySearch.redirectToResource(TYPES.ITEM, selection)
    case TYPES.PROJECT:
      return getRelativeURLKeepingQuerySearch.redirectToResource(TYPES.PROJECT, selection)
    case TYPES.ONE_ON_ONE:
      return getRelativeURLKeepingQuerySearch.oneOnOneApp(selection)
    case TYPES.ONBOARDING: {
      const to = getRelativeURLKeepingQuerySearch.redirectToFirstProject()
      const search = new URLSearchParams(to.search)
      search.set('onboarding', 1)
      to.search = '?' + search.toString()
      return to
    }
    case TYPES.SEARCH: {
      const href = getRelativeURLKeepingQuerySearch.searchApp()
      const [_type, preset] = _.split(selection, '-')
      const url = new URL(href)
      url.searchParams.set(queryParamsHelper.QUERY_PARAMS.FILTER_PRESET, preset)
      return url.toString()
    }
    default:
      return ''
  }
}

export const useTabInfo = context => {
  const tabId = context?.page?.entityId
  const tab = useSelector(meetingsSelectors.getTeamsTab(tabId))
  const dispatch = useDispatch()
  const fetchTab = useMemo(() => {
    return !tab && tabId ? () => dispatch(getTeamsTab(tabId)) : undefined
  }, [tab, tabId, dispatch])
  const { loading, error } = useFetch(fetchTab)
  return { tab, loading, error }
}

export const useIsInMeeting = () => {
  const context = useSelector(teamsSelectors.getRawContext)
  const isSidePanel = context?.page?.frameContext === 'sidePanel'
  return isSidePanel || queryParamsHelper.isInMeeting()
}
