import { AUTH_KEYS } from '../reducers/authKeys'

export const isAuthenticated = state => state.getIn([AUTH_KEYS.REDUCER, AUTH_KEYS.AUTHENTICATED])
export const wasSignedOut = state => state.getIn([AUTH_KEYS.REDUCER, AUTH_KEYS.SIGNED_OUT])
export const hasLicense = state => state.getIn([AUTH_KEYS.REDUCER, AUTH_KEYS.LICENSE_STATUS, 'licensed'])
export const isOnTrial = state => state.getIn([AUTH_KEYS.REDUCER, AUTH_KEYS.LICENSE_STATUS, 'on_trial'])
export const daysLeftOnTrial = state => state.getIn([AUTH_KEYS.REDUCER, AUTH_KEYS.LICENSE_STATUS, 'days_left_on_trial'])
export const accountNumberOfUsers = state =>
  state.getIn([AUTH_KEYS.REDUCER, AUTH_KEYS.LICENSE_STATUS, 'account', 'num_users'])
export const isPaying = state => state.getIn([AUTH_KEYS.REDUCER, AUTH_KEYS.LICENSE_STATUS, 'paying'])
export const admin = state => state.getIn([AUTH_KEYS.REDUCER, AUTH_KEYS.LICENSE_STATUS, 'account', 'admin'])
export const isFreemium = state => hasLicense(state) && !isPaying(state)
export const quotaUsage = state => state.getIn([AUTH_KEYS.REDUCER, AUTH_KEYS.LICENSE_STATUS, 'usage'])
export const freeTierLimit = state => state.getIn([AUTH_KEYS.REDUCER, AUTH_KEYS.LICENSE_STATUS, 'free_tier'])
export const hasLimits = state => {
  const trial = isOnTrial(state)
  const freemium = isFreemium(state)
  return !trial && freemium
}

export const canCreateItem = state => {
  if (!hasLimits(state)) {
    return true
  }
  const limits = freeTierLimit(state)
  const quota = quotaUsage(state)
  const key = 'items'

  return quota.get(key) < limits.get(`max_${key}`)
}
