import { useMemo, useState } from 'react'
import { projectHelper } from '../../common/src/helpers'
import { ComboBox, SelectableOptionMenuItemType } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { renderProjectOption } from './renderProjectOption'
import { sortBy } from '@/utils/arrayUtils'
import { useProjectsMap } from '@/common/src/hooks/projectHooks.js'
import { FILTER_REDUCER_KEYS } from '@/common/src/reducers/filtersKeys.js'

const styles = {
  callout: {
    minWidth: '300px',
  },
  dropdownItem: {
    overflowX: 'hidden',
  },
}

export const ProjectsDropdown = ({
  enableInbox = false,
  enableKeepSame = false,
  projectHeader,
  selectedProject,
  projects: givenProjects,
  onProjectChanged,
  ...rest
}) => {
  const projectsMap = useProjectsMap(true, FILTER_REDUCER_KEYS.PROJECTS_PANEL)
  const projects = useMemo(() => givenProjects || projectsMap.toArray(), [givenProjects, projectsMap])
  const { t } = useTranslation()
  const projectsOptions = useMemo(() => {
    const options = []
    if (enableKeepSame) {
      options.push({
        key: -1,
        text: t('item.multiple_selection.move.keep_same_project'),
      })
    }
    if (enableInbox) {
      options.push({
        key: 0,
        text: t('left_panel.inbox_button_name'),
      })

      options.push({
        key: `divider-inbox`,
        itemType: SelectableOptionMenuItemType.Divider,
      })

      options.push({
        key: 'projectHeader',
        text: t('left_panel.projects_button_name'),
        itemType: SelectableOptionMenuItemType.Header,
      })
    }
    const sortedProjects = sortBy(projects, projectHelper.sortDescriptorByName)
    const projectsOptions = sortedProjects.map(p => ({
      key: projectHelper.getIdd(p),
      text: projectHelper.getName(p),
      data: { project: p },
    }))
    options.push(...projectsOptions)
    return options
  }, [enableKeepSame, enableInbox, projects, t])

  const [searchQuery, setSearchQuery] = useState('')

  const filteredOptions = useMemo(() => {
    if (!searchQuery) {
      return projectsOptions
    }
    return projectsOptions.filter(o => !o.text || o.text.toLowerCase().includes(searchQuery.toLowerCase()))
  }, [projectsOptions, searchQuery])

  const onChange = (ev, option) => {
    onProjectChanged(option.key === -1 ? 'keep' : option.data?.project)
  }

  const selectedKey = selectedProject === 'keep' ? -1 : selectedProject ? projectHelper.getIdd(selectedProject) : 0
  return (
    <ComboBox
      allowFreeInput
      options={filteredOptions}
      autoComplete="on"
      onInputValueChange={text => setSearchQuery(text)}
      selectedKey={selectedKey}
      onChange={onChange}
      styles={styles}
      onRenderOption={renderProjectOption}
      openOnKeyboardFocus
      {...rest}
    />
  )
}
