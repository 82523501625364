import { intervalToDuration } from 'date-fns/fp'
import type { Duration } from 'date-fns'
import { Badge, type BadgeProps } from '@fluentui/react-components'
import { cn } from '@/modules/classnames'

export type CompactDueDateProps = {
  dueDate?: Date
  allDay: boolean
  className?: string
  size?: BadgeProps['size']
}

function calculateDuration(start: Date, end: Date) {
  if (start > end) {
    return 'past'
  } else {
    return intervalToDuration({ start, end })
  }
}

function compactDurationString(duration: Duration | 'past', allDay: boolean) {
  if (duration === 'past') {
    return '!'
  } else if (duration.years) {
    return `${duration.years}y`
  } else if (duration.months) {
    return `${duration.months}m`
  } else if (duration.days) {
    return `${duration.days}d`
  } else if (duration.hours) {
    return `${duration.hours}h`
  } else if (duration.minutes) {
    return `${duration.minutes}m`
  } else {
    return allDay ? 'today' : 'now'
  }
}

export const CompactDueDate = ({ dueDate, allDay, className, size }: CompactDueDateProps) => {
  if (!dueDate) return null

  const now = new Date()

  if (allDay) {
    dueDate = new Date(dueDate)
    dueDate.setHours(0, 0, 0, 0)
    now.setHours(0, 0, 0, 0)
  }

  const duration = calculateDuration(now, dueDate)
  const compactDuration = compactDurationString(duration, allDay)
  const isDue = duration === 'past'
  const millisecondsToDueDate = dueDate.getTime() - now.getTime()
  const isInNext2Days = millisecondsToDueDate < 2 * 24 * 60 * 60 * 1000
  const isInNextWeek = millisecondsToDueDate < 7 * 24 * 60 * 60 * 1000

  return (
    <Badge
      className={cn(isDue && '!text-sm', !isDue && isInNext2Days && '!bg-orange-500', className)}
      color={isDue ? 'danger' : isInNext2Days ? 'severe' : isInNextWeek ? 'warning' : 'informative'}
      size={size}
    >
      {compactDuration}
    </Badge>
  )
}
