import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { ICON_NAMES } from '../../common/src/msIcons'
import { setIsCancelled } from '../../actions/onboardingActions'
import { OnboardingMainView } from './OnboardingMainView'
import { currentStepSelector, isCancelledSelector, stepsCountSelector } from '../../selectors/onboardingSelectors'
import { OnboardingCompletedStepView } from './OnboardingCompletedStepView'
import { onboardingSubject } from '../../reactions'
import { useCurrentStepKey } from '../../hooks/onboardingHooks'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent as trackEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { IconButton } from '@fluentui/react/lib/Button'
import { TooltipHost } from '@/components/tooltip/TooltipHost'
import { useTranslation } from 'react-i18next'
import { usePrevious } from '@/common/src/hooks/enhancedHooks'

// It was impossible to implement animations
// with dynamic height.
const containerVariants = {
  hidden: {
    opacity: 1,
    scale: 0,
    x: -150,
    y: 200,
  },
  main: {
    height: '288px',
    opacity: 1,
    scale: 1,
    x: 0,
    y: 0,
    transition: {
      duration: 0.5,
      delay: 0.3,
      when: 'beforeChildren',
      staggerChildren: 0.3,
    },
  },
  completed: {
    height: '210px',
    opacity: 1,
    scale: 1,
    x: 0,
    y: 0,
    transition: {
      duration: 0.5,
      delay: 0.3,
      when: 'beforeChildren',
      staggerChildren: 0.3,
    },
  },
}

const Container = styled(motion.div).attrs({
  variants: containerVariants,
  initial: 'hidden',
})`
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 3000000;
  width: 256px;
  font-size: 14px;

  background: ${p => p.theme.palette.themePrimary};
  border-radius: 5px;
`

const TopBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 4px 4px 0;
`

const SubContainer = styled.div`
  padding: 0 20px 20px;
`

const CloseIcon = styled(IconButton).attrs(p => ({
  iconProps: { iconName: ICON_NAMES.Cancel },
  styles: {
    root: {
      color: p.theme.palette.white,
    },
  },
}))`
  float: right;
`

export const OnboardingView = memo(({ ...rest }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const stepsCount = useSelector(stepsCountSelector)
  const currentStep = useSelector(currentStepSelector)
  const currentKey = useCurrentStepKey()
  const previousStep = usePrevious(currentStep)
  const isCancelled = useSelector(isCancelledSelector)
  const [showPreviousCompletedStep, setShowPreviousCompletedStep] = useState(false)
  const timeoutRef = useRef(0)

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }, [currentStep])

  useEffect(() => {
    if (previousStep < currentStep) {
      setShowPreviousCompletedStep(true)
      timeoutRef.current = setTimeout(() => {
        setShowPreviousCompletedStep(false)

        if (currentStep === stepsCount) {
          dispatch(setIsCancelled(true))
        }
      }, 5000)
    }
  }, [currentStep, dispatch, previousStep, setShowPreviousCompletedStep, stepsCount])

  useEffect(() => {
    if (!isCancelled) {
      onboardingSubject.next(currentKey)
    }
  }, [isCancelled, currentKey])

  const onClose = useCallback(() => {
    dispatch(setIsCancelled(true))
    dispatch(trackEvent(AMPLITUDE_ACTION_TYPES.WEB_APP_ONBOARDING_CANCELLED))
  }, [dispatch])

  const variant = useMemo(() => {
    if (isCancelled) {
      return 'hidden'
    }
    return showPreviousCompletedStep ? 'completed' : 'main'
  }, [showPreviousCompletedStep, isCancelled])

  const closeText = t('onboarding.close_button_tooltip')

  return (
    <Container animate={variant} {...rest}>
      <TopBar>
        <TooltipHost content={closeText}>
          <CloseIcon onClick={onClose} ariaLabel={closeText} />
        </TooltipHost>
      </TopBar>
      <SubContainer>
        {showPreviousCompletedStep ? <OnboardingCompletedStepView step={previousStep} /> : <OnboardingMainView />}
      </SubContainer>
    </Container>
  )
})
