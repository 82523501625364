import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { cn } from '@appfluence/classnames'
import { Icon } from '@fluentui/react/lib/Icon'
import { PROJECT_MODE, projectModesProps } from '../../utils/projectModesUtils'
import { TooltipHost } from '@/components/tooltip/TooltipHost'
import { useTranslation } from 'react-i18next'

const displayName = 'ProjectModeSegmented'

const Container = styled.div`
  display: flex;
  border: 1px ${p => p.theme.palette.themePrimary} solid;
`

const tooltipStyles = p => ({
  root: {
    ':not(:last-child)': {
      borderRight: `1px ${p.theme.palette.themePrimary} solid`,
    },
  },
})

const SIcon = styled(Icon)`
  display: flex;
  align-items: center;
  color: ${p => p.theme.palette.themePrimary};
  fill: ${p => p.theme.palette.themePrimary};
  cursor: pointer;
  padding: 2px 8px;
  width: 14px;
  max-height: 15px;

  &.selected {
    background: ${p => p.theme.palette.themePrimary};
    color: ${p => p.theme.palette.white};
    fill: ${p => p.theme.palette.white};
  }
`

const ProjectModeSegmented_ = ({ selectedMode = PROJECT_MODE.MATRIX, onClick, className, readOnly = false }) => {
  const { t } = useTranslation()
  const buttonsItems = _.map(projectModesProps(t), p => {
    p.key = `${displayName}_${p.mode}Button`
    return p
  })
  const filteredItems = readOnly ? _.filter(buttonsItems, item => item.readOnly === readOnly) : buttonsItems
  const onChange = mode => () => onClick(mode)
  const getClassName = view => cn({ selected: view === selectedMode })
  return (
    <Container className={className}>
      {filteredItems.map(item => {
        return (
          <TooltipHost content={item.text} key={item.key} styles={tooltipStyles}>
            {' '}
            <SIcon
              id={item.key}
              key={item.key}
              iconName={item.iconName}
              ariaLabel={item.text}
              className={getClassName(item.mode)}
              onClick={onChange(item.mode)}
            />
          </TooltipHost>
        )
      })}
    </Container>
  )
}
export const ProjectModeSegmented = React.memo(ProjectModeSegmented_)
