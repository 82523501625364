import userHelper from './userHelper'
import sharingRequestHelper from './sharingRequestHelper'
import _ from 'lodash'
import { Map } from 'immutable'

export const userFrom = (pickerItem, isAdmin = false) => {
  let user = undefined
  if (_.isString(pickerItem)) {
    if (!_.isEmpty(pickerItem)) {
      user = Map({
        [userHelper.KEYS.EMAIL]: pickerItem,
      })
    }
  } else if (userHelper.isUser(pickerItem)) {
    user = pickerItem
  } else if (sharingRequestHelper.isSharingRequest(pickerItem)) {
    user = userHelper.fromSharingRequest(pickerItem)
    user = user.set(userHelper.KEYS.IS_INVITED, true)
  }

  if (user) {
    user = isAdmin ? user.set(userHelper.KEYS.IS_ADMIN, isAdmin) : user
  }
  return user
}
