import { useSelector } from 'react-redux'
import { graphStateHelper } from '../common/src/helpers'
import { getSearchedItems } from '../common/src/selectors/searchSelectors'
import { getItemsTextFilter } from '../common/src/selectors/filtersSelectors'
import { FILTER_REDUCER_KEYS } from '../common/src/reducers/filtersKeys'
import { useDeferredValue } from 'react'
import { useSelectedItem } from '../hooks/PMHooks'

export const searchViewContainerGenerator = Component => props => {
  const text = useSelector(state => getItemsTextFilter(state, FILTER_REDUCER_KEYS.SEARCH))
  const searchedItemsSync = useSelector(getSearchedItems)
  const searchedItems = useDeferredValue(searchedItemsSync)
  const searchedGraphItems = useSelector(state => graphStateHelper.getAllByText(state, text))
  const selectedItemSync = useSelectedItem()
  const selectedItem = useDeferredValue(selectedItemSync)
  return (
    <Component
      {...props}
      searchedItems={searchedItems}
      searchedGraphItems={searchedGraphItems}
      selectedItem={selectedItem}
    />
  )
}
