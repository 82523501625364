import type { JSX } from 'react'
import { Button, ButtonProps } from '@fluentui/react-components'
import { ArrowLeft, ArrowRight } from '../BundledIcons'

const createButton = (icon: JSX.Element) => (props: ButtonProps) => {
  return <Button icon={icon} {...props} appearance="transparent" />
}

export const BackButton = createButton(<ArrowLeft />)
export const ForwardButton = createButton(<ArrowRight />)
