import { TextField } from '@fluentui/react/lib/TextField'
import { formatDate } from '../../../utils/datefns'

export const PlannerTaskContent = ({ resource }) => {
  const {
    resource: { plan, percentComplete, dueDateTime, startDateTime },
  } = resource
  const progress = !percentComplete ? 'Not started' : percentComplete === 100 ? 'Completed' : 'In progress'
  let description = `» Progress: ${progress}`
  description += startDateTime ? `\n\n» Start Date: ${formatDate('LongNormal')(startDateTime)}` : ''
  description += dueDateTime ? `\n\n» Due Date: ${formatDate('LongNormal')(dueDateTime)}` : ''
  description += '\n\n'
  return (
    <>
      <TextField value={description} label={`Plan: ${plan}`} borderless multiline readOnly autoAdjustHeight />
    </>
  )
}
