import { memo, useCallback, useMemo } from 'react'
import { ContainerDrawer } from '@/components/panels/ContainerDrawer'
import { PanelView } from '../PanelView'
import { ItemRecurrencePanelPart } from './ItemRecurrencePanelPart'
import { LocalizedDatePickerV9 } from '../../pickers/LocalizedDatePicker'
import { useDidChangeDate } from '../../../hooks/dateHooks'
import { useTranslation } from 'react-i18next'
import { TimePickerCombo } from '../../timepicker/TimePickerCombo'
import { Button, Switch } from '@fluentui/react-components'

const DatePanelView = memo(
  ({
    startDate,
    dueDate,
    allDay,
    onChangeStartDate,
    onChangeDueDate,
    onToggleAllDay,
    frequency,
    untilDate,
    onChangeFrequency,
    onChangeUntilDate,
    onSave,
    ...rest
  }) => {
    const onClearDate = useCallback(() => {
      onChangeStartDate()
      onChangeDueDate()
    }, [onChangeStartDate, onChangeDueDate])
    const { t } = useTranslation()

    const didChangeDateHandlerCreator = useDidChangeDate()

    const didChangeStartDate = useMemo(() => {
      return didChangeDateHandlerCreator(startDate, onChangeStartDate)
    }, [didChangeDateHandlerCreator, startDate, onChangeStartDate])

    const didChangeDueDate = useMemo(() => {
      return didChangeDateHandlerCreator(dueDate, onChangeDueDate)
    }, [didChangeDateHandlerCreator, dueDate, onChangeDueDate])

    const onChangeAllDay = useCallback(
      (ev, data) => {
        onToggleAllDay(data.checked)
      },
      [onToggleAllDay]
    )

    const headerChildren = (
      <Button appearance="transparent" disabled={!(startDate || dueDate)} onClick={onClearDate} className="!min-w-0">
        {t('general.clear')}
      </Button>
    )
    return (
      <PanelView headerChildren={headerChildren} {...rest}>
        <LocalizedDatePickerV9 label={t('date.start_date')} value={startDate} onSelectDate={didChangeStartDate} />
        {!allDay && startDate && <TimePickerCombo value={startDate} onChange={onChangeStartDate} />}
        <LocalizedDatePickerV9 label={t('date.due_date')} value={dueDate} onSelectDate={didChangeDueDate} />
        {!allDay && dueDate && <TimePickerCombo value={dueDate} onChange={onChangeDueDate} />}
        {!!onToggleAllDay && <Switch label={t('date.all_day')} checked={allDay} onChange={onChangeAllDay} />}
        {dueDate && (
          <ItemRecurrencePanelPart
            frequency={frequency}
            untilDate={untilDate}
            onChangeFrequency={onChangeFrequency}
            onChangeUntilDate={onChangeUntilDate}
          />
        )}
        {onSave && (
          <Button appearance="primary" onClick={onSave}>
            {t('general.save')}
          </Button>
        )}
      </PanelView>
    )
  }
)

export const ItemDatesPanel = memo(
  ({
    headerText,
    startDate,
    dueDate,
    allDay,
    onChangeStartDate,
    onChangeDueDate,
    onToggleAllDay,
    frequency,
    untilDate,
    onChangeFrequency,
    onChangeUntilDate,
    onDismiss,
    onSave,
    isOpen,
  }) => {
    const { t } = useTranslation()
    const ht = headerText ?? t('date.edit_dates')
    return (
      <ContainerDrawer headerText={ht} onDismiss={onDismiss} isOpen={isOpen}>
        <DatePanelView
          startDate={startDate}
          dueDate={dueDate}
          allDay={allDay}
          onChangeStartDate={onChangeStartDate}
          onChangeDueDate={onChangeDueDate}
          onToggleAllDay={onToggleAllDay}
          frequency={frequency}
          untilDate={untilDate}
          onChangeFrequency={onChangeFrequency}
          onChangeUntilDate={onChangeUntilDate}
          onSave={onSave}
        />
      </ContainerDrawer>
    )
  }
)
