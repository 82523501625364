import React from 'react'

export const ItemDetailContext = React.createContext()
export const AlertModalContext = React.createContext()
export const NewItemModalContext = React.createContext()
export const InvitationsModalContext = React.createContext()
export const MoveItemModalContext = React.createContext()
export const MoveItemsModalContext = React.createContext()
export const RouteIdContext = React.createContext()
export const ViewSizeContext = React.createContext()
export const OneOnOneContext = React.createContext()
export const HistoryContext = React.createContext()
export const LocationForRoutesContext = React.createContext()
export const FileContext = React.createContext()
export const ItemLinkPanelContext = React.createContext()
export const MainLayoutMainContext = React.createContext()
