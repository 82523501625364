import _ from 'lodash'
import { createAction, RSAA } from 'redux-api-middleware'
import { PM_API_RESOURCE_TYPE, PM_API_RESOURCES, PM_API_URLS } from '../constants'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'
import { stateHelper, userHelper } from '../helpers'
import { appendQueryParamsToURL, extendPMHeaders, getCredentialsConfig } from '../helpers/requestHelper'
import { resourceURICreator } from '../helpers/URLHelper'
import { isInitializedRequestForPMResource } from '../selectors/requestStateSelectors'

export const getComment = (id, params = {}) => {
  const url = _.template(PM_API_URLS.COMMENT)({ id })
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(url, params),
      credentials: getCredentialsConfig(),
      method: 'GET',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.COMMENT, { itemID: id }),
      headers: extendPMHeaders(),
    },
  }
}

export const markItemsAsReadInAPI = (idList, discard = true) => {
  const url = PM_API_URLS.MARK_ITEMS_AS_READ
  const body = JSON.stringify({
    item_ids: idList,
  })
  return createAction({
    endpoint: url,
    credentials: getCredentialsConfig(),
    method: 'POST',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.ITEM_COMMENTS, { itemIDs: idList, discard }),
    headers: extendPMHeaders(),
    body,
  })
}

export const getCommentsForItemID = (id, count = 1, discard = false) => {
  const url = _.template(PM_API_URLS.ITEM_COMMENTS)({ id })
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(url, { limit: count }),
      credentials: getCredentialsConfig(),
      method: 'GET',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.ITEM_COMMENTS, { itemID: id, discard }),
      headers: extendPMHeaders(),
    },
  }
}

const isCommentEmpty = _.flow(_.trim, _.isEmpty)
export const sendComment = ({ itemID, text }) => {
  if (!itemID) {
    return { type: 'INVALID_ITEM_ID_TO_SEND_COMMENT' }
  }

  if (isCommentEmpty(text)) {
    return { type: 'INVALID_COMMENT_TO_SEND' }
  }

  const comment = {
    text,
    item: resourceURICreator(1, PM_API_RESOURCE_TYPE.ITEM, itemID),
  }

  const body = JSON.stringify(comment)
  return createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.POST_COMMENT),
    credentials: getCredentialsConfig(),
    method: 'POST',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.COMMENT, { itemID }),
    headers: extendPMHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
    }),
    body,
  })
}

const commonRecentItems =
  resourceName =>
  ({ limit = 25, authorId, return_item_summary = true, returntags = true }) => {
    const params = {
      limit,
      return_item_summary,
      returntags,
    }
    const url = _.template(PM_API_URLS[resourceName])({ id: authorId })
    return {
      [RSAA]: {
        endpoint: appendQueryParamsToURL(url, params),
        credentials: getCredentialsConfig(),
        method: 'GET',
        types: generateActionTypesForPMAPI(PM_API_RESOURCES[resourceName], { params }),
        headers: extendPMHeaders(),
      },
    }
  }

export const getLastSeenCommentsWithItemSummary = commonRecentItems('RECENTLY_VISITED_COMMENTS')
export const getLastTypedCommentsWithItemSummary = commonRecentItems('RECENTLY_TYPED_COMMENTS')

export const getLastSeenCommentsForCurrentUser =
  (limit = 20) =>
  (dispatch, getState) => {
    const state = getState()
    const isMeAlreadyFetched = isInitializedRequestForPMResource(state, PM_API_RESOURCES.ME)
    const me = stateHelper.getMe(state)
    const authorId = userHelper.getID(me)
    if (!isMeAlreadyFetched) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(dispatch(getLastSeenCommentsForCurrentUser(limit)))
        }, 200)
      })
    }
    if (!authorId) {
      return
    }
    return dispatch(getLastSeenCommentsWithItemSummary({ authorId, limit }))
  }
