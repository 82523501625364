import { getSentry } from '../getSentry'
import _ from 'lodash'
import amplitude from 'amplitude-js'
import posthog from 'posthog-js'
import { API_EVENT, parseApiActionType } from '../common/src/helpers/reducerHelper'
import { APIS, PM_API_RESOURCES } from '../common/src/constants'
import { AMPLITUDE_ACTION_TYPES, EVENTS, SESSION } from '../common/src/eventTracking/amplitudeEvents'
import { track, TRACKING_ACTION_TYPES } from '../common/src/actions/trackingAPI'
import { setTrackedUser, trackEvent } from '../common/src/utils'
import * as ENVIRONMENT from '../common/src/environment'
import { reverseObj } from '../common/src/helpers/functionalUtils'
import * as queryParamsHelper from '../helpers/queryParamsHelper'
import { ReplaySubject } from 'rxjs'
import { isElectronApp } from '../integrations/electron'

const AMPLITUDE_KNOWN_ACTION_TYPES = reverseObj(AMPLITUDE_ACTION_TYPES)
const TRACKING_KNOWN_ACTION_TYPES = reverseObj(TRACKING_ACTION_TYPES)
const Sentry = getSentry()

const EventTrackingSubject = new ReplaySubject()
const startTracking = _.once(() => {
  EventTrackingSubject.subscribe(({ amplitudeEventName, payload }) => {
    trackEvent(amplitudeEventName, payload)
  })
})

const computeWebappFlavour = async sessionData => {
  if (sessionData[SESSION.TEAMS]) return 'teams'
  if (sessionData[SESSION.OUTLOOK]) return 'outlook'
  if (sessionData[SESSION.ANDROID_WEBVIEW]) return 'android'
  if (sessionData[SESSION.IOS_WEBVIEW]) return 'ios'
  if (sessionData[SESSION.ELECTRON]) return (await window.electronAPI?.getPlatformInfo?.())?.platform
  return 'web'
}

export const eventTrackingMiddleware = store => next => action => {
  if (!ENVIRONMENT.TRACK_EVENTS) {
    return next(action)
  }
  const { type, payload } = action
  const analyticsActionName = AMPLITUDE_KNOWN_ACTION_TYPES[type]
  if (analyticsActionName) {
    const amplitudeEventName = EVENTS[analyticsActionName]
    EventTrackingSubject.next({ amplitudeEventName, payload })
    return
  }

  const trackingActionName = TRACKING_KNOWN_ACTION_TYPES[type]
  if (trackingActionName) {
    const { dispatch } = store
    dispatch(track(payload))
    return
  }

  const apiAction = parseApiActionType(type)
  if (apiAction) {
    const { api, resource, event } = apiAction
    if (api === APIS.PM && event === API_EVENT.SUCCESS) {
      if (resource === PM_API_RESOURCES.ME) {
        const asyncStartTracking = async () => {
          const { email, id, paying_single, paying_team, user_profile } = payload
          const collaborators = user_profile?.collaborators?.length ?? 0
          Sentry.setUser({ email, id })
          setTrackedUser(email)

          const userData = {
            [SESSION.PAYING_SINGLE]: paying_single,
            [SESSION.PAYING_TEAM]: paying_team,
            [SESSION.COLLABORATORS]: collaborators,
          }

          const sessionData = {
            [SESSION.TEAMS]: queryParamsHelper.isEmbeddedOnTeams(),
            [SESSION.ELECTRON]: isElectronApp(),
            [SESSION.OUTLOOK]: queryParamsHelper.isEmbeddedOnOutlook(),
            [SESSION.ANDROID_WEBVIEW]: queryParamsHelper.isEmbeddedOnAndroid(),
            [SESSION.IOS_WEBVIEW]: queryParamsHelper.isEmbeddedOnIos(),
            [SESSION.REACT_NATIVE]: queryParamsHelper.isEmbeddedOnMobile(),
            [SESSION.EMBEDDED]: queryParamsHelper.isEmbedded(),
          }

          sessionData[SESSION.WEBAPP_FLAVOUR] = await computeWebappFlavour(sessionData)

          const amplitudeData = {
            ...userData,
            ...sessionData,
          }

          const identify = new amplitude.Identify()
          Object.entries(amplitudeData).forEach(([key, value]) => identify.set(key, value))
          amplitude.getInstance().identify(identify)

          posthog.identify(email, {
            id,
            ...userData,
          })

          posthog.register({
            ...sessionData,
            $app_version: ENVIRONMENT.VERSION,
            platform: ENVIRONMENT.NAME,
          })

          const blacklistedPath = checkBlackList()
          if (!blacklistedPath) {
            trackEvent(EVENTS.APP_STARTED, {
              pathname: window.location.pathname,
            })
          }
          startTracking()
        }

        asyncStartTracking()
      }
    }
  }
  return next(action)
}

/**
 * List of initial URLs that we do not want to track in amplitude to avoid event pollution
 */
const APP_STARTED_BLACKLIST = [
  /index\/one_on_one(?!_hub)/, //one on one inside windows is opened in background
]

const checkBlackList = () => APP_STARTED_BLACKLIST.some(urlRegExp => urlRegExp.test(window.location.pathname))
