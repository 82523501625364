import { useStore } from 'react-redux'
import { useInfiniteQuery } from '@tanstack/react-query'
import { appendQueryParamsToURL, getCredentialsConfig } from '@/common/src/helpers/requestHelper'
import type { PaginatedResponse } from '@/types/apiTypes'
import _ from 'lodash'

export type Tag = {
  id: number
  name: string
  resource_uri: string
  slug: string
}

export const useTags = (type: 'project' | 'item') => {
  const store = useStore()
  return useInfiniteQuery({
    queryKey: ['tags', type],
    queryFn: async ({ pageParam }) => {
      const response = await fetch(
        appendQueryParamsToURL(`/api/v2/${type}/tags/`, {
          limit: 300,
          offset: pageParam,
        })(store.getState()),
        {
          credentials: getCredentialsConfig(),
        }
      )
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return (await response.json()) as PaginatedResponse<Tag>
    },
    staleTime: 1000 * 60 * 60,
    getNextPageParam: lastPage => (lastPage.meta.next ? lastPage.meta.offset + lastPage.meta.limit : undefined),
    initialPageParam: 0,
    select: data =>
      _.uniqBy(
        data.pages.flatMap(page => page.objects),
        'name'
      ).sort((a, b) => a.name.localeCompare(b.name)),
  })
}
