import React from 'react'
import { ViewSizeContext } from '../../contexts'
import { useMediaQuery } from 'react-responsive'

const RESPONSIVE_LAYOUT_WIDTH_THRESHOLD = 769 // Not to include the smallest iPad

const querySettings = {
  minWidth: RESPONSIVE_LAYOUT_WIDTH_THRESHOLD,
}

const Content = ({ children }) => {
  const narrowWidth = !useMediaQuery(querySettings)
  const contextValue = { narrowWidth }
  return <ViewSizeContext.Provider value={contextValue}>{children}</ViewSizeContext.Provider>
}

export const ViewSizeDetector = ({ children }) => {
  return <Content>{children}</Content>
}
