import { useCallback, useState } from 'react'
import _ from 'lodash'
import { motion } from 'framer-motion'
import { Button } from '@fluentui/react-components'
import { Text } from '../../components/text/Text'
import { PM_API_RESOURCE_TYPE } from '../../common/src/constants'
import { useMeEmail } from '../../common/src/hooks/usersHooks'
import { getRequestAccess } from '../../common/src/actions/sharingRequestsAPI'
import { useDispatch } from 'react-redux'
import { getSignOut } from '../../common/src/actions/authAPI'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { useTranslation } from 'react-i18next'
import { DocumentLock32Filled, MailCheckmarkRegular } from '@fluentui/react-icons'

const Message = ({ children }) => (
  <Text block className="my-4">
    {children}
  </Text>
)

export const AccessDenied = ({ serverID, objectType }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [showCheck, setShowCheck] = useState(false)
  const email = useMeEmail()
  const isProject = objectType === PM_API_RESOURCE_TYPE.PROJECT

  const onReceiveRequestAccess = response => {
    const hasAccess = _.get(response, ['payload', 'has_access'])
    if (hasAccess) {
      // Forcing download of the accepted project
      // Add timeout to wait that events are tracked
      setTimeout(() => {
        window.location.reload()
      }, 2500)
    }
  }

  const onRequestAccess = useCallback(() => {
    if (!isProject) {
      return
    }
    dispatch(getRequestAccess(serverID)).then(onReceiveRequestAccess)
    setShowCheck(true)

    dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.REQUEST_ACCESS_PROJECT))
  }, [isProject, dispatch, serverID])

  const signOut = useCallback(async () => {
    dispatch(getSignOut())
  }, [dispatch])

  return (
    <div className="flex h-full w-full items-center justify-around">
      <div className="max-w-[600px] p-9 text-justify">
        <div className="my-8 inline-flex items-center">
          <DocumentLock32Filled />
          <Text block variant="xLarge" className="text-[32px]">
            {t('access_denied.title')}
          </Text>
        </div>
        <Message>
          {t('access_denied.message', {
            element: isProject ? t('general.project').toLowerCase() : t('general.item').toLowerCase(),
          })}
        </Message>
        <Message>{t('general.you_are_signed_in_as', { email })}</Message>
        {!showCheck && (
          <div className="mt-4 pt-4">
            {isProject && (
              <Button appearance="primary" className="!mr-3" onClick={onRequestAccess}>
                {t('access_denied.request')}
              </Button>
            )}
            <Button onClick={signOut}>{t('general.sign_out')}</Button>
          </div>
        )}
        {showCheck && (
          <>
            <Message>{t('access_denied.checking_access')}</Message>
            <div className="mt-10 flex h-[100px] w-full items-center justify-center">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ fontSize: 100, opacity: 1 }}
                transition={{ ease: 'easeOut', duration: 0.7 }}
              >
                <MailCheckmarkRegular className="text-green-600" />
              </motion.div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
