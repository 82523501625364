import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Divider,
  Select,
  Switch,
} from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { useId } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getItemsDisplayMode, getItemsShowAvatars, getPrefersDarkMode } from '@/selectors/uiSelectors'
import {
  type DarkModePreference,
  setItemsDisplayMode,
  setItemsShowAvatars,
  setPrefersDarkMode,
} from '@/actions/uiActions'
import { useNarrowWidth } from '@/hooks/useNarrowWidth'
import { ItemDisplayMode } from '@/constants/ui'

export type AppearanceSettingsDialogProps = {
  open: boolean
  onClose: () => void
}

const SectionTitle = ({ children }: { children: React.ReactNode }) => (
  <h3 className="mb-2 mt-4 text-base font-semibold">{children}</h3>
)

export const AppearanceSettingsDialog = ({ open, onClose }: AppearanceSettingsDialogProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedPreferredMode = useSelector(getPrefersDarkMode)
  const modeDropdownId = useId()
  const itemDisplayModeDropdownId = useId()

  const selectedItemMode = useSelector(getItemsDisplayMode)
  const narrowWidth = useNarrowWidth()
  const displayedSelectedMode =
    selectedItemMode === ItemDisplayMode.Default
      ? narrowWidth
        ? ItemDisplayMode.Compact
        : ItemDisplayMode.Standard
      : selectedItemMode

  const itemsShowAvatars = useSelector(getItemsShowAvatars)

  return (
    <Dialog
      open={open}
      onOpenChange={(event, data) => {
        if (!data.open) onClose()
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t('appearance_settings.title')}</DialogTitle>
          <DialogActions>
            <DialogTrigger>
              <Button appearance="primary">{t('general.save')}</Button>
            </DialogTrigger>
          </DialogActions>
          <DialogContent>
            <div>
              <SectionTitle>{t('appearance_settings.theme_section_title')}</SectionTitle>
              <Select
                id={modeDropdownId}
                value={selectedPreferredMode}
                onChange={(event, data) => dispatch(setPrefersDarkMode(data.value as DarkModePreference))}
                className="max-w-56"
              >
                <option value="light">☀️ {t('appearance_settings.theme_light')}</option>
                <option value="dark">🌙 {t('appearance_settings.theme_dark')}</option>
                <option value="system">🖥️ {t('appearance_settings.theme_system')}</option>
              </Select>
            </div>
            <Divider className="mb-3 mt-4" />
            <div>
              <SectionTitle>{t('appearance_settings.items_section_title')}</SectionTitle>
              <label htmlFor={itemDisplayModeDropdownId} className="mb-1 block">
                {t('appearance_settings.items_mode_label')}
              </label>
              <Select
                id={itemDisplayModeDropdownId}
                value={displayedSelectedMode}
                onChange={(event, data) => dispatch(setItemsDisplayMode(data.value))}
                className="max-w-56"
              >
                <option value={ItemDisplayMode.Compact}>{t('appearance_settings.items_mode_compact')}</option>
                <option value={ItemDisplayMode.Standard}>{t('appearance_settings.items_mode_standard')}</option>
              </Select>
              <Switch
                checked={itemsShowAvatars}
                onChange={(ev, data) => dispatch(setItemsShowAvatars(data.checked))}
                label={t('appearance_settings.show_avatar_label')}
                className="mt-2"
              />
            </div>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
