import type { PMChatMessageProps } from './PMChatMessage'
import { getImageUrl } from './utils'

const MAX_IMAGE_HEIGHT = 240

export type ImageMessageProps = {
  comment: PMChatMessageProps['comment']
}

export const ImageMessage = ({ comment }: ImageMessageProps) => {
  if (!comment.height || !comment.width) return null
  const imageURL = getImageUrl(comment)
  if (!imageURL) return null
  const imageHeight = Math.round(comment.height > MAX_IMAGE_HEIGHT ? MAX_IMAGE_HEIGHT : comment.height)
  const imageWidth = Math.round(
    comment.height > MAX_IMAGE_HEIGHT ? (comment.width * 240) / comment.height : comment.width
  )

  return (
    <a href={imageURL} target="_blank" rel="noreferrer" className="h-fit">
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img className="h-auto max-w-full" height={imageHeight} width={imageWidth} src={imageURL} loading="lazy" />
    </a>
  )
}
