import { getRemember } from '../helpers/queryParamsHelper'
import { useSelector } from 'react-redux'
import * as LOCATION from '../reducers/locationKeys'
import { dateHelper } from '../common/src/helpers'

export const useLastRememberedLocation = () => {
  const periodInSeconds = getRemember()
  const storedData = useSelector(state => state.getIn([LOCATION.REDUCER_NAME]))
  const timestamp = storedData.get(LOCATION.LOCATION_KEYS.TIMESTAMP)
  if (!periodInSeconds || !timestamp) {
    return null
  }
  const now = dateHelper.dateToTimestampInSeconds(new Date())
  const isValid = now - timestamp < periodInSeconds
  if (isValid) {
    return storedData.get(LOCATION.LOCATION_KEYS.PATH)
  }
  return null
}
