import React, { memo, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { Dropdown, DropdownMenuItemType } from '@fluentui/react/lib/Dropdown'
import { stringHelper } from '../../common/src/helpers'
import { UserGroupsOption } from './UserGroupsOption'
import { useTranslation } from 'react-i18next'
import { useUserGroups } from '../../queries/userGroups'

const UserGroupsTitle = styled(UserGroupsOption)``

const ReadOnlyUserGroups = styled(UserGroupsOption)`
  height: 32px;
  line-height: 32px;
`

const emptyUserGroupKey = 'None'
const emptyUserGroupSeparatorOption = { key: 'divider_0', itemType: DropdownMenuItemType.Divider }

export const UserGroupsDropdown = memo(
  ({ selectedID, readOnly = false, emptyPlaceholderTranslationKey = 'users.no_associated_group', ...rest }) => {
    const { t } = useTranslation()
    const { data: userGroups } = useUserGroups()
    const emptyUserGroupOption = useMemo(
      () => ({ key: emptyUserGroupKey, text: t(emptyPlaceholderTranslationKey), groupID: null }),
      [emptyPlaceholderTranslationKey, t]
    )

    const userGroupOptions = useMemo(() => {
      const options = []
      options.push(emptyUserGroupOption)
      options.push(emptyUserGroupSeparatorOption)
      const groups =
        userGroups
          ?.map(group => ({ key: group.id, groupID: group.id, text: group.name, logo: group.group_logo }))
          .sort((a, b) => stringHelper.sortAfterToLower(a.text, b.text)) ?? []
      options.push(...groups)
      return options
    }, [emptyUserGroupOption, userGroups])

    const onRenderOption = useCallback(({ key, text, logo }) => {
      return <UserGroupsOption key={key} imageSrc={logo} text={text} />
    }, [])

    const onRenderTitle = useCallback(options => {
      const option = options[0]
      const { key, text, logo } = option
      return <UserGroupsTitle key={key} imageSrc={logo} text={text} />
    }, [])

    const selectedKey = selectedID || emptyUserGroupKey

    if (readOnly) {
      const option = _.find(userGroupOptions, option => option.key === selectedKey) || emptyUserGroupOption
      const { key, text, logo } = option
      return <ReadOnlyUserGroups key={key} imageSrc={logo} text={text} {...rest} />
    }

    return (
      <Dropdown
        selectedKey={selectedKey}
        options={userGroupOptions}
        onRenderOption={onRenderOption}
        onRenderTitle={onRenderTitle}
        {...rest}
      />
    )
  }
)
