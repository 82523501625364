import React, { memo, useMemo } from 'react'
import Color from 'color'
import { FocusZone } from '@fluentui/react/lib/FocusZone'
import { ContainerPanel } from '../ContainerPanel'
import { PanelView } from '../PanelView'
import { ColorCell } from './ColorCell'

const ColorsPanelView = memo(({ colorObjects, onChangeColor, onDismiss, ...rest }) => {
  const colorElements = useMemo(() => {
    const createCellOnClick = colorObject => () => {
      onChangeColor && onChangeColor(colorObject)
      onDismiss()
    }
    return colorObjects.map((itemObj, index) => {
      const colorObject = Color(itemObj.color)
      const colorHex = colorObject.hex()
      return <ColorCell color={colorHex} onClick={createCellOnClick(colorObject.object())} key={index} />
    })
  }, [colorObjects, onChangeColor, onDismiss])

  return (
    <PanelView {...rest}>
      <FocusZone>
        <div className="flex flex-row flex-wrap w-full">{colorElements}</div>
      </FocusZone>
    </PanelView>
  )
})

export const ColorsPanel = memo(
  ({ headerText = 'Select a color', colorObjects, onChangeColor, onDismiss, ...rest }) => {
    return (
      <ContainerPanel headerText={headerText} onDismiss={onDismiss} {...rest}>
        <ColorsPanelView colorObjects={colorObjects} onChangeColor={onChangeColor} onDismiss={onDismiss} />
      </ContainerPanel>
    )
  }
)
