import React from 'react'
import styled from 'styled-components'
import { Icon } from '@fluentui/react/lib/Icon'
import { ICON_NAMES } from '../../common/src/msIcons'

const Container = styled.div`
  color: #de3d01;
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: 600;
  flex: 1;
`

const Text = styled.span`
  margin-left: 4px;
`

export const Office365Title = React.memo(() => {
  return (
    <Container>
      <Icon iconName={ICON_NAMES.OfficeLogo} />
      <Text>Microsoft 365</Text>
    </Container>
  )
})
