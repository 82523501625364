import React from 'react'
import styled from 'styled-components'
import { Icon } from '@fluentui/react/lib/Icon'
import { Text } from '../text/Text'
import { sharingRequestHelper } from '../../common/src/helpers'
import { useTranslation } from 'react-i18next'
import { FlexColumn, FlexRow } from '../layout/FlexContainer'

const Title = Text

const Subtitle = styled(Text).attrs({
  variant: 'small',
})`
  margin-top: 4px;
`

const Action = styled(Icon)`
  font-size: 16px;
  margin-left: 8px;
  cursor: pointer;
  color: ${p => p.theme.palette.themePrimary};
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 8px;
`

const InfoContainer = FlexColumn

const ActionsContainer = FlexRow

export const InvitationCell = ({ invitation, showActions = true, onAccept, onReject, className }) => {
  const projectName = sharingRequestHelper.getProjectName(invitation)
  const sourceUsername = sharingRequestHelper.getSourceUsername(invitation)
  const { t } = useTranslation()

  const cancelOnClick = () => {
    onReject(invitation)
  }
  const acceptOnClick = () => {
    onAccept(invitation)
  }

  return (
    <Container className={className}>
      <InfoContainer>
        <Title>{projectName}</Title>
        <Subtitle>{t('invites.by') + sourceUsername}</Subtitle>
      </InfoContainer>
      {showActions && (
        <ActionsContainer>
          <Action iconName={'Cancel'} onClick={cancelOnClick} />
          <Action iconName={'Accept'} onClick={acceptOnClick} />
        </ActionsContainer>
      )}
    </Container>
  )
}
