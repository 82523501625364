import React, { useState, useMemo, useCallback } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { graphStateHelper, stateHelper } from '../../common/src/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { MainLayout } from '../../components/layout/MainLayout'
import { GrowthUserPanel } from './GrowthUserPanel'
import { GrowthCardCollection } from './GrowthCardCollection'
import { TeamsHeader } from '../../components/layout/TeamsHeader'
import { useMergeState } from '../../common/src/hooks/enhancedHooks'
import { BaseFlexRow } from '../../components/layout/FlexContainer'
import { useMeEmail } from '../../common/src/hooks/usersHooks'
import { DefaultButton, IconButton } from '@fluentui/react/lib/Button'
import { SERVER_URLS } from '../../common/src/constants'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { ICON_NAMES } from '../../common/src/msIcons'
import { Title, useLoadingMsCollaborators } from './GrowthCommon'
import { useTranslation } from 'react-i18next'
import { useInitializeMsCollaborators } from '../../hooks/PMHooks'
import { SearchBox } from '@fluentui/react/lib/SearchBox'
import { useDebouncedHandler } from '../../hooks/hooks'
import { UserGroupsDropdown } from '../../components/dropdown/UserGroupsDropdown'
import { useUserGroups } from '../../queries/userGroups'

const MainContainer = styled.div`
  padding: 0 40px 40px 40px;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  background: ${p => p.theme.palette.neutralLighterAlt};
  display: flex;
  flex-direction: column;
`

const HeaderRow = styled(BaseFlexRow)`
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;

  & > .right {
    align-items: center;
    justify-self: flex-end;
  }

  & > .separator {
    flex: 1;
  }
`

const CollectionRow = styled(BaseFlexRow)`
  flex: 1;
  min-height: 1px;
`

const PermissionsRow = styled(BaseFlexRow)`
  justify-content: space-between;
`

const Info = styled(IconButton).attrs({
  iconProps: {
    iconName: ICON_NAMES.InfoSolid,
  },
  href: 'https://appfluence.com/help/article/collaborators-i-dont-recognize/',
  target: '_blank',
})`
  margin-left: 8px;
  font-size: larger;
`

const IDENTITY = i => i
const useSearchBox = () => {
  const [searchText, searchSetter] = useState('')
  const onChangeSearch = useDebouncedHandler(IDENTITY, searchSetter)
  return {
    onChangeSearch,
    searchText,
  }
}

const useUserGroup = () => {
  const [selectedUserGroupId, setter] = useState()
  const onChangeUserGroup = useCallback(
    (event, option) => {
      const { groupID } = option
      setter(groupID)
    },
    [setter]
  )
  return { selectedUserGroupId, onChangeUserGroup }
}

const useFilteredCollaborators = (searchText, userGroupId) => {
  const { data: userGroups } = useUserGroups()
  const userGroupList = useMemo(() => userGroups ?? [], [userGroups])
  const isEmailInSelectedUserGroup = useMemo(() => {
    if (!userGroupId) {
      return () => true
    }
    const userGroup = userGroupList.find(group => group.id === userGroupId)
    if (!userGroup) {
      return () => true
    }
    const users = userGroup.memberships.map(membership => membership.user.email.toLowerCase())
    return e => users.some(userEmail => userEmail === e)
  }, [userGroupId, userGroupList])
  const filterCollaborator = useCallback(
    c => {
      const email = _.toLower(c.get('email') || '')
      if (!isEmailInSelectedUserGroup(email)) {
        return false
      }
      const fullName = _.toLower(c.get('full_name') || '')
      return _.includes(email, searchText) || _.includes(fullName, searchText)
    },
    [searchText, isEmailInSelectedUserGroup]
  )
  const allGraphCollaborators = useSelector(stateHelper.getGraphCollaboratorsMap)
  const allCollaborators = useSelector(stateHelper.getCollaboratorsMap)
  const collaborators = useMemo(() => {
    return allCollaborators.filter(filterCollaborator)
  }, [filterCollaborator, allCollaborators])
  const graphCollaborators = useMemo(() => {
    return allGraphCollaborators.filter(filterCollaborator)
  }, [filterCollaborator, allGraphCollaborators])
  return {
    collaborators,
    graphCollaborators,
  }
}

export const GrowthView = () => {
  const { t } = useTranslation()
  useInitializeMsCollaborators()
  const { onChangeSearch, searchText } = useSearchBox()
  const dispatch = useDispatch()
  const email = useMeEmail()
  const { selectedUserGroupId, onChangeUserGroup } = useUserGroup()
  const placeholderMessage = `${t('general.you_are_signed_in_as', { email })}. ${t(
    'office.pls_grant_read_only_access'
  )}`
  const { collaborators, graphCollaborators } = useFilteredCollaborators(searchText, selectedUserGroupId)
  const isGraphEnabled = useSelector(graphStateHelper.isGraphEnabled)
  const { pending, loading } = useLoadingMsCollaborators(graphCollaborators.size)
  const graphPermissionLink = `${SERVER_URLS.GRAPH_PERMISSIONS}?next=${window.location.pathname}`
  const onClickInvite = () => dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.GROWTH_GRANT_PERMISSIONS))
  const [state, setState] = useMergeState({
    open: false,
    user: undefined,
  })
  const onDismiss = () => setState({ open: false })
  const main = (
    <MainContainer>
      <HeaderRow>
        <Title>{t('growth.view.title')}</Title>
        <div className="separator"></div>
        <SearchBox onChanged={onChangeSearch} className="right" />
        <UserGroupsDropdown
          selectedID={selectedUserGroupId}
          className="right"
          onChange={onChangeUserGroup}
          emptyPlaceholderTranslationKey="growth.user_group_placeholder"
        />
      </HeaderRow>
      <BaseFlexRow>
        <p>
          {t('growth.view.subtitle')}
          <Info />
        </p>
      </BaseFlexRow>
      {!pending && !isGraphEnabled && (
        <PermissionsRow>
          <p>{placeholderMessage}</p>
          <DefaultButton href={graphPermissionLink} onClick={onClickInvite}>
            {t('office.grant_access_to_collaborators')}
          </DefaultButton>
        </PermissionsRow>
      )}
      <CollectionRow>
        <GrowthCardCollection collaborators={collaborators} graphCollaborators={graphCollaborators} loading={loading} />
        <GrowthUserPanel isOpen={state.open} onDismiss={onDismiss} collaborator={state.user} />
      </CollectionRow>
    </MainContainer>
  )

  const components = {
    header: <TeamsHeader productName="My Team" loading={pending} />,
    main,
  }

  return <MainLayout {...components} />
}
