import { ITEM_COLUMN_KEYS } from '@/components/table/itemColumnKeys'
import { atomWithStorage, useAtomCallback } from 'jotai/utils'
import { key as atomKey } from '@/atoms/index'
import { useCallback } from 'react'

const DEFAULT_COLUMNS: { [key: string]: boolean | 'responsive' } = {
  [ITEM_COLUMN_KEYS.OWNER]: true,
  [ITEM_COLUMN_KEYS.PROGRESS]: 'responsive',
  [ITEM_COLUMN_KEYS.LAST_MODIFIED_TIMESTAMP]: 'responsive',
  [ITEM_COLUMN_KEYS.DUE_DATE]: true,
  [ITEM_COLUMN_KEYS.START_DATE]: false,
  [ITEM_COLUMN_KEYS.QUADRANT]: 'responsive',
  [ITEM_COLUMN_KEYS.ICON]: 'responsive',
  [ITEM_COLUMN_KEYS.EFFORT]: false,
  [ITEM_COLUMN_KEYS.COMPLETION_DATE]: false,
  [ITEM_COLUMN_KEYS.CREATOR]: false,
  [ITEM_COLUMN_KEYS.CREATION_DATE]: false,
} as const

export const itemTableColumnsAtoms = Object.fromEntries(
  Object.entries(DEFAULT_COLUMNS).map(([key, defaultValue]) => [
    key,
    atomWithStorage(atomKey(`itemTableColumns_${key}`), defaultValue),
  ])
)

export const useResetItemTableColumns = () => {
  return useAtomCallback(
    useCallback((get, set) => {
      Object.entries(DEFAULT_COLUMNS).forEach(([key, defaultValue]) => {
        set(itemTableColumnsAtoms[key], defaultValue)
      })
    }, [])
  )
}
