import { fromJS, Map, Set } from 'immutable'
import _ from 'lodash'
import { GRAPH_RESOURCES_REDUCER_KEYS as KEYS } from './graphResourcesReducerKeys'
import {
  graphMessageHelper,
  graphEventHelper,
  graphFileHelper,
  graphOutlookTaskHelper,
  graphPlannerTaskHelper,
} from '../helpers'
import { API_EVENT, parseApiActionType } from '../helpers/reducerHelper'
import { APIS, GRAPH_RESOURCES, PM_API_RESOURCES } from '../constants'
import { GRAPH_RESOURCE_TYPE, plannerWebLink } from '../helpers/graph/graphResourceBase'
import { GENERIC_ACTION_TYPE } from '../actions/genericActions'

const unauthorizedErrorCode = 401
const forbiddenErrorCode = 403

const base = fromJS({
  [KEYS.BY_ID]: {},
  [KEYS.BY_TEXT]: {},
  [KEYS.BY_USER]: {},
  [KEYS.ENABLED]: true,
})

const getGraphResourceMapGivenArray = (array, creator) => {
  const vanillaMap = _.chain(array).keyBy('id').mapValues(creator).value()
  return Map(vanillaMap)
}

const updateSetInPath = (state, parsedMap, key, value) => {
  const path = [key, value]
  const incomingSet = parsedMap.keySeq().toSet()
  const currentSet = state.getIn(path) || Set()
  const nextSet = currentSet.union(incomingSet)
  return state.setIn(path, nextSet)
}

const storeParsedMapUsingMeta = (state, parsedMap, meta) => {
  state = state.mergeIn([KEYS.BY_ID], parsedMap)
  const { username, text } = meta
  const _updateSetInPath = _.partial(updateSetInPath, state, parsedMap)
  if (username) {
    state = _updateSetInPath(KEYS.BY_USER, username)
  }
  if (text || text === '') {
    state = _updateSetInPath(KEYS.BY_TEXT, text)
  }
  return state
}

const successHandler = {
  [GRAPH_RESOURCES.OUTLOOK_EMAILS]: (state, { payload, meta }) => {
    const elements = getGraphResourceMapGivenArray(payload.value, graphMessageHelper.create)
    return storeParsedMapUsingMeta(state, elements, meta)
  },
  [GRAPH_RESOURCES.CALENDAR_EVENTS]: (state, { payload, meta }) => {
    const elements = getGraphResourceMapGivenArray(payload.value, graphEventHelper.create)
    return storeParsedMapUsingMeta(state, elements, meta)
  },
  [GRAPH_RESOURCES.DRIVE_FILES]: (state, { payload, meta }) => {
    const elements = getGraphResourceMapGivenArray(payload.value, graphFileHelper.create)
    return storeParsedMapUsingMeta(state, elements, meta)
  },
  [GRAPH_RESOURCES.SHARED_FILES]: (state, { payload, meta }) => {
    const files = getGraphResourceMapGivenArray(payload.value, graphFileHelper.create)
    state = storeParsedMapUsingMeta(state, files, meta)
    files.forEach((file, id) => {
      const element = Map({ [id]: file })
      const {
        resource: { createdBy, lastModifiedBy },
      } = file
      const createdByEmail = _.get(createdBy, 'user.email')
      const modifiedByEmail = _.get(lastModifiedBy, 'user.email')
      if (createdByEmail) {
        state = updateSetInPath(state, element, KEYS.BY_USER, createdByEmail)
      }
      if (modifiedByEmail && modifiedByEmail !== createdByEmail) {
        state = updateSetInPath(state, element, KEYS.BY_USER, modifiedByEmail)
      }
    })
    return state
  },
  [GRAPH_RESOURCES.OUTLOOK_TASKS]: (state, { payload, meta }) => {
    const elements = getGraphResourceMapGivenArray(payload.value, graphOutlookTaskHelper.create)
    return storeParsedMapUsingMeta(state, elements, meta)
  },
  [GRAPH_RESOURCES.PLANNER_TASKS]: (state, { payload, meta }) => {
    const { plan, users, graphMeId, domain } = meta
    const tasks = getGraphResourceMapGivenArray(payload.value, graphPlannerTaskHelper.create)
    tasks.forEach((task, id) => {
      task.resource.plan = plan
      task = task.set('link', _.template(plannerWebLink)({ domain, id }))
      const element = Map({ [id]: task })
      // Save tasks in reducer by id (for selection) and by text='' (for Search view)
      state = storeParsedMapUsingMeta(state, element, { text: '' })
      const {
        resource: { assignments, createdBy },
      } = task
      const creatorId = createdBy && createdBy.user && createdBy.user.id
      if ((!_.isEmpty(assignments) && assignments[graphMeId]) || (creatorId && creatorId === graphMeId)) {
        for (const assignment in assignments) {
          const username = users.get(assignment)
          state = updateSetInPath(state, element, KEYS.BY_USER, username)
        }
        if (creatorId !== graphMeId) {
          const username = users.get(creatorId)
          state = updateSetInPath(state, element, KEYS.BY_USER, username)
        }
      }
    })
    return state
  },
}

const setGraphEnabledByErrorCode = (state, { payload }, { resource, api }) => {
  const code = _.get(payload, 'response.code')
  //TODO check more errors. Meeting maybe?
  const needCheck = api === APIS.GRAPH_PROXY || resource === PM_API_RESOURCES.MS_COLLABORATORS
  if (needCheck) {
    if (code === forbiddenErrorCode || code === unauthorizedErrorCode) {
      state = state.set(KEYS.ENABLED, false)
    }
  }
  return state
}

export const graphResources = (state = base, action) => {
  const { type, payload } = action
  const apiAction = parseApiActionType(type)
  if (apiAction) {
    const { api, resource, event } = apiAction
    if (api === APIS.GRAPH_PROXY && event === API_EVENT.SUCCESS) {
      const handler = successHandler[resource]
      if (handler) {
        state = handler(state, action)
      }
    } else if (event === API_EVENT.ERROR) {
      state = setGraphEnabledByErrorCode(state, action, apiAction)
    }
  } else if (type === GRAPH_RESOURCE_TYPE.PLANNER_TASK) {
    const { meta } = action
    const { text } = meta
    if (text) {
      return storeParsedMapUsingMeta(state, payload.elements, meta)
    }
  } else if (type === GRAPH_RESOURCE_TYPE.RESET_GRAPH || type === GENERIC_ACTION_TYPE.CLEAR_ALL) {
    state = base
  }
  return state
}
