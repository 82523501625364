import { ItemsFiltersPanel } from './ItemsFiltersPanel'
import { SearchFiltersView } from './SearchFiltersView'
import { FILTER_REDUCER_KEYS } from '../../common/src/reducers/filtersKeys'

export const SearchFiltersPanel = ({ filtersType = FILTER_REDUCER_KEYS.SEARCH, ...rest }) => {
  return (
    <ItemsFiltersPanel {...rest}>
      <SearchFiltersView filtersType={filtersType} />
    </ItemsFiltersPanel>
  )
}
