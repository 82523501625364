import { fromJS } from 'immutable'
import {
  API_EVENT,
  isPMApiDetailedAction,
  parseApiActionType,
  PERSIST_REHYDRATE_ACTION_TYPE,
} from '../helpers/reducerHelper'
import { PM_API_RESOURCE_TYPE, PM_API_RESOURCES } from '../constants'
import { tagHelper } from '../helpers'
import _ from 'lodash'
import { ACTIONS as WS_ACTIONS } from '../websocket/wsActions'
import { GENERIC_ACTION_TYPE } from '../actions/genericActions'
import { TAGS_REDUCER_KEYS as KEYS } from './tagsReducerKeys'

const base = fromJS({
  [KEYS.PROJECT_TAGS_BY_NAME]: {},
  [KEYS.ITEM_TAGS_BY_NAME]: {},
})

const applyTags = (state, key, tags) => {
  return state.withMutations(_st => {
    _.each(tags, tag => {
      const { name } = tag
      _st.setIn([key, name], fromJS(tag))
    })
    return _st
  })
}

const removeTags = (state, key, tags) => {
  return state.withMutations(_st => {
    _.each(tags, tag => {
      const { name } = tag
      _st.setIn([key, name])
    })
    return _st
  })
}

const getKeyFromMeta = ({ projectID, itemID, model }) => {
  if (projectID || model === PM_API_RESOURCE_TYPE.PROJECT) {
    return KEYS.PROJECT_TAGS_BY_NAME
  } else if (itemID || model === PM_API_RESOURCE_TYPE.ITEM) {
    return KEYS.ITEM_TAGS_BY_NAME
  }
  return null
}

export const tags = (state = base, action) => {
  const { type, payload, meta } = action
  const apiAction = parseApiActionType(type)
  if (isPMApiDetailedAction(apiAction)) {
    const { resource, event } = apiAction
    if (event === API_EVENT.SUCCESS) {
      switch (resource) {
        case PM_API_RESOURCES.ALL_PROJECTS:
          {
            const tags = _.flatMap(payload.objects, project => project.tags)
            const uniqueTags = _.uniqBy(tags, tagHelper.KEYS.NAME)
            state = applyTags(state, KEYS.PROJECT_TAGS_BY_NAME, uniqueTags)
          }
          break
        case PM_API_RESOURCES.PROJECT:
          {
            const project = payload
            const { tags } = project
            state = applyTags(state, KEYS.PROJECT_TAGS_BY_NAME, tags)
          }
          break
        case PM_API_RESOURCES.ITEM_TAGS:
          {
            const tags = payload.objects
            const uniqueTags = _.uniqBy(tags, tagHelper.KEYS.NAME)
            state = applyTags(state, KEYS.ITEM_TAGS_BY_NAME, uniqueTags)
          }
          break
        case PM_API_RESOURCES.PROJECT_TAGS:
          {
            const tags = payload.objects
            const uniqueTags = _.uniqBy(tags, tagHelper.KEYS.NAME)
            state = applyTags(state, KEYS.PROJECT_TAGS_BY_NAME, uniqueTags)
          }
          break
        case PM_API_RESOURCES.ALL_ITEMS:
        case PM_API_RESOURCES.ITEMS_IN_PROJECT:
        case PM_API_RESOURCES.RELATED_ITEMS:
        case PM_API_RESOURCES.ITEM_SUMMARIES:
        case PM_API_RESOURCES.ITEM_SUMMARIES_IN_PROJECT:
        case PM_API_RESOURCES.SEARCH:
          {
            const tags = _.flatMap(payload.objects, item => item.tags)
            const uniqueTags = _.uniqBy(tags, tagHelper.KEYS.NAME)
            state = applyTags(state, KEYS.ITEM_TAGS_BY_NAME, uniqueTags)
          }
          break
        case PM_API_RESOURCES.ITEM:
        case PM_API_RESOURCES.ITEM_PUT:
        case PM_API_RESOURCES.ITEM_POST:
          {
            const item = payload
            state = applyTags(state, KEYS.ITEM_TAGS_BY_NAME, item.tags)
          }
          break
        case PM_API_RESOURCES.ITEMS_PUT:
        case PM_API_RESOURCES.ITEMS_POST:
          {
            const tags = _.flatMap(payload, item => item.tags)
            const uniqueTags = _.uniqBy(tags, tagHelper.KEYS.NAME)
            state = applyTags(state, KEYS.ITEM_TAGS_BY_NAME, uniqueTags)
          }
          break
        case PM_API_RESOURCES.TAG_ADD:
          {
            const { tag } = meta
            const key = getKeyFromMeta(meta)
            state = key ? applyTags(state, key, [tag]) : state
          }
          break
        case PM_API_RESOURCES.TAG_REMOVE:
          {
            const { tag } = meta
            const key = getKeyFromMeta(meta)
            state = key ? removeTags(state, key, [tag]) : state
          }
          break
        case PM_API_RESOURCES.RENAME_TAG:
          {
            const { oldTag, newTag } = meta
            const key = getKeyFromMeta(meta)
            if (key) {
              state = removeTags(state, key, [oldTag])
              state = applyTags(state, key, [newTag])
            }
          }
          break
      }
    }
  } else {
    switch (type) {
      case PERSIST_REHYDRATE_ACTION_TYPE:
        {
          if (payload.tags) {
            state = state.merge(payload.tags)
          }
        }
        break
      case GENERIC_ACTION_TYPE.CLEAR_ALL:
        {
          state = base
        }
        break
      case WS_ACTIONS.PROJECT_RECEIVED:
        {
          const project = payload
          const { tags } = project
          state = applyTags(state, KEYS.PROJECT_TAGS_BY_NAME, tags)
        }
        break
      case WS_ACTIONS.ITEM_RECEIVED:
        {
          const item = action.payload
          const { tags } = item
          state = applyTags(state, KEYS.ITEM_TAGS_BY_NAME, tags)
        }
        break
    }
  }
  return state
}
