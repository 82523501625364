import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { TextField } from '@fluentui/react/lib/TextField'
import { useComponentDidMount } from '../../common/src/hooks/enhancedHooks'

const AutoFocusTextField_ = (props, ref) => {
  const inputRef = useRef(null)
  useComponentDidMount(() => {
    setTimeout(() => {
      if (inputRef.current?.focus) {
        inputRef.current.focus()
      }
    }, 100)
  })
  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputRef.current?.focus) {
        inputRef.current.focus()
      }
    },
    blur: () => {
      if (inputRef.current?.blur) {
        inputRef.current.blur()
      }
    },
  }))
  return <TextField componentRef={inputRef} {...props} />
}

export const AutoFocusTextField = forwardRef(AutoFocusTextField_)
