import { useSelector } from 'react-redux'
import { teamsSelectors, authSelectors } from '@/common/src/selectors'
import { isEmbeddedOnIos } from '@/helpers/queryParamsHelper'
import { useConfig } from '@/queries/config'

export const useShouldDisplayUpgrade = () => {
  const { data: config } = useConfig()
  const isTeamsMobile = useSelector(teamsSelectors.isMobileHost)
  const paying = useSelector(authSelectors.isPaying)
  const isIosApp = isEmbeddedOnIos()
  const review = config?.review ?? false
  const iOSHideCondition = isIosApp && review
  const hide = paying !== false || isTeamsMobile || iOSHideCondition
  return !hide
}
