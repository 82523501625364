/**
 *  Utils to interact with immutable public project template
 **/

import _ from 'lodash'
import { safeMapFnCreator } from './immutableHelpers'
import { resourceURIParser } from './URLHelper'
import { PM_API_RESOURCE_TYPE } from '../constants'

let helper = {}

const KEYS = {
  ID: 'id',
  NAME: 'name',
  NOTES: 'short_description',
  DESCRIPTION: 'description',
  DOWNLOADS: 'downloads',
  RESOURCE_URI: 'resource_uri',

  KEY: 'key',
  CATEGORY: 'category',
  PROJECT_RESOURCE_URI: 'project',
  READONLY: 'readonly',
  IMAGE: 'image',
  IMAGE_URL: 'image_url',
}
helper.KEYS = KEYS

helper.isPublicProjectTemplate = t => {
  const resourceUri = helper.getResourceURI(t)
  const r = resourceURIParser(resourceUri)
  return r && r.type === PM_API_RESOURCE_TYPE.PUBLIC_PROJECT_TEMPLATE
}

helper.getID = t => t.get(KEYS.ID)
helper.getName = t => t.get(KEYS.NAME)
helper.getNotes = t => t.get(KEYS.NOTES)
helper.getDescription = t => t.get(KEYS.DESCRIPTION)
helper.getDownloads = t => t.get(KEYS.DOWNLOADS)
helper.getResourceURI = t => t.get(KEYS.RESOURCE_URI)
helper.getReadonlyUrl = t => t.get(KEYS.READONLY)

helper.getKey = t => t.get(KEYS.KEY)
helper.getCategory = t => t.get(KEYS.CATEGORY)
helper.getProjectResourceURI = t => t.get(KEYS.PROJECT_RESOURCE_URI)
helper.getImage = t => t.get(KEYS.IMAGE) || t.get(KEYS.IMAGE_URL)

helper.sortDescriptorByName = t => _.toLower(helper.getName(t))
helper.sortDescriptorByDownloads = t => helper.getDownloads(t)

helper.ALL_CATEGORY = 'all'

helper = _.mapValues(helper, f => (_.isFunction(f) ? safeMapFnCreator(f) : f))
export default helper
