import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { tagHelper, stateHelper } from '../helpers'
import {
  createItemFilter,
  createItemSortDescriptor,
  getParamsForItemsFilters,
  createTagFilter,
} from '../helpers/itemsFiltersHelper'
import {
  createProjectFilter,
  createProjectSortDescriptor,
  PROJECTS_SORT_PREDICATES,
} from '../helpers/projectsFiltersHelper'
import {
  getItemsFilters,
  getItemsGroupByFilter,
  getProjectsFilters,
  getProjectsTagsFilter,
} from '../selectors/filtersSelectors'
import { useProjectsMap } from '@/common/src/hooks/projectHooks'
import { GROUP_BY, PROJECTS_SORT_TYPE } from '@/common/src/actions/filtersActions'

// Projects

export const useProjectFilter = mode => {
  const filters = useSelector(state => getProjectsFilters(state, mode))
  return useMemo(() => createProjectFilter(filters), [filters])
}

export const useFilteredProjects = projects => {
  const filter = useProjectFilter()
  return projects && projects.filter(filter)
}

export const useProjectSortDescriptor = mode => {
  const filters = useSelector(state => getProjectsFilters(state, mode))
  return useMemo(() => createProjectSortDescriptor(filters), [filters])
}

export const useShowArchivedProjects = () => {
  const tags = useSelector(getProjectsTagsFilter)
  return useMemo(() => {
    return tags.some(t => t === tagHelper.ARCHIVED_TAG_NAME)
  }, [tags])
}

// Items

export const useItemFilter = filtersType => {
  const filters = useSelector(state => getItemsFilters(state, filtersType))
  const meEmail = useSelector(stateHelper.getMeEmail)
  return useMemo(() => createItemFilter(filters, meEmail), [filters, meEmail])
}

export const useFilteredItems = (items, filtersType) => {
  const filter = useItemFilter(filtersType)
  return items && items.filter(filter)
}

export const useItemSortDescriptor = filtersType => {
  const filters = useSelector(state => getItemsFilters(state, filtersType))
  return useMemo(() => createItemSortDescriptor(filters), [filters])
}

export const useParamsForItemsFilter = filtersType => {
  const filters = useSelector(state => getItemsFilters(state, filtersType))
  const groupByProject = useSelector(state => getItemsGroupByFilter(state, filtersType))
  const projects = useProjectsMap()
  const sortedProjects = useMemo(
    () =>
      groupByProject !== GROUP_BY.NONE ? projects.sort(PROJECTS_SORT_PREDICATES[PROJECTS_SORT_TYPE.NAME]) : undefined,
    [groupByProject, projects]
  )
  return useMemo(() => getParamsForItemsFilters(filters, {}, sortedProjects), [filters, sortedProjects])
}

export const useStarredItems = items => {
  const tagName = useMemo(() => ['starred'], [])
  const filter = useMemo(() => createTagFilter(tagName), [tagName])
  return items && items.filter(filter)
}
