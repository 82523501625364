import _ from 'lodash'
import { createAction } from 'redux-api-middleware'
import { PM_API_RESOURCES, PM_API_URLS } from '../constants'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'
import { reminderHelper } from '../helpers'
import { appendQueryParamsToURL, extendPMHeaders, getCredentialsConfig } from '../helpers/requestHelper'

export const getReminder = (reminderID, params = {}) => {
  const _url = _.template(PM_API_URLS.REMINDER)({ id: reminderID })
  const _params = {
    returnfollowers: 1,
    ...params,
  }
  const types = generateActionTypesForPMAPI(PM_API_RESOURCES.REMINDER, { reminderID })
  return createAction({
    endpoint: appendQueryParamsToURL(_url, _params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: types,
    headers: extendPMHeaders(),
  })
}

export const putReminder = reminder => {
  const reminderID = reminderHelper.getId(reminder)
  if (reminderID) {
    const hash = reminder.hashCode()
    const url = _.template(PM_API_URLS.REMINDER)({ id: reminderID })
    const body = reminderHelper.prepareReminderForPut(reminder)
    return createAction({
      endpoint: appendQueryParamsToURL(url),
      credentials: getCredentialsConfig(),
      method: 'PUT',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.REMINDER_PUT, { reminderID, hash }),
      headers: extendPMHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      }),
      body,
    })
  } else {
    const type = 'REMINDER_WITH_NO_ID'
    console.warn(type)
    return { type }
  }
}

export const postReminder = reminder => {
  if (reminder) {
    const date = reminder['reminder_date']
    if (!date) {
      const ERROR = 'REMINDER_HAS_NO_DATE'
      console.warn(ERROR)
      return { type: ERROR }
    }
    const body = JSON.stringify(reminder)
    return createAction({
      endpoint: appendQueryParamsToURL(PM_API_URLS.ALL_REMINDERS),
      credentials: getCredentialsConfig(),
      method: 'POST',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.REMINDER_POST),
      headers: extendPMHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      }),
      body,
    })
  } else {
    const noReminder = 'NO_REMINDER_TO_POST'
    console.warn(noReminder)
    return { type: noReminder }
  }
}
