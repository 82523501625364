import { Map } from 'immutable'
import { LOCATION_ACTION_TYPES, LOCATION_KEYS as KEYS } from './locationKeys'
import { PERSIST_REHYDRATE_ACTION_TYPE } from '../common/src/helpers/reducerHelper'

const base = Map({
  [KEYS.PATH]: '',
  [KEYS.TIMESTAMP]: 0,
})

const handlers = {
  [LOCATION_ACTION_TYPES.SET_LOCATION]: (state, { payload }) => {
    const { timestamp, path } = payload
    return state.withMutations(s => {
      s.set(KEYS.PATH, path)
      s.set(KEYS.TIMESTAMP, timestamp)
    })
  },
  [PERSIST_REHYDRATE_ACTION_TYPE]: (state, { payload }) => {
    if (payload.location) {
      return state.merge(payload.location)
    }
    return state
  },
}

export const location = (state = base, action) => {
  const { type } = action
  const fn = handlers[type]
  return fn ? fn(state, action) : state
}
