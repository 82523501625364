import React from 'react'
import _ from 'lodash'
import { userHelper } from '../../common/src/helpers'
import { Dropdown } from '@fluentui/react/lib/Dropdown'
import { useOwnersForProject } from '../../common/src/hooks/usersHooks'
import { UserCollectionCell } from '../users/usersCollection/UserCollectionCell'

const styles = {
  callout: {
    minWidth: '300px',
  },
  dropdownItem: {
    overflowX: 'hidden',
  },
}

export const OwnersOfProjectDropdown = ({ project, user, onUserChanged, ...rest }) => {
  const users = useOwnersForProject(project)
  const selectedUserID = userHelper.getID(user)
  const usersArray = _.toArray(users)
  const usersOptions = usersArray.map(u => ({
    key: userHelper.getID(u),
    text: <UserCollectionCell user={u} />,
    user: u,
  }))

  const onChange = (ev, option) => {
    const selectedUser = option.user
    onUserChanged(selectedUser)
  }

  return (
    <Dropdown
      options={usersOptions}
      onRenderPlaceholder={() => <UserCollectionCell className="mt-0.5" user={user} />}
      onChange={onChange}
      styles={styles}
      selectedKeys={selectedUserID}
      {...rest}
    />
  )
}
