import { memo } from 'react'
import { cn } from '@appfluence/classnames'
import { useDispatch } from 'react-redux'
import { Ribbon } from './Ribbon'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { useTranslation } from 'react-i18next'
import { openPurchaseExperienceAction } from '../../utils/teams'
import { useShouldDisplayUpgrade } from '@/hooks/userHooks'

const UpgradeRibbon_ = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const shouldDisplayUpgrade = useShouldDisplayUpgrade()
  const containerClassName = cn({ pm_hidden: !shouldDisplayUpgrade })

  const onClick = () => {
    dispatch(openPurchaseExperienceAction(EVENT_EXTRA.TRIAL_UPGRADE_PRESSED.MODE.RIBBON))
  }

  return (
    <div className={containerClassName}>
      <Ribbon onClick={onClick}>{t('general.upgrade')}</Ribbon>
    </div>
  )
}

export const UpgradeRibbon = memo(UpgradeRibbon_)
