import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useShowCompleted, useUsernameBlacklist } from '../../../../hooks/workloadHooks'
import {
  setBlacklist,
  setBlacklistUserGroup,
  toggleUsernameStatus,
  setShowCompleted,
} from '../../../../actions/workloadActions'
import { Set } from 'immutable'
import { getAvatarURIForEmail } from '../../../../common/src/constants'
import { Checkbox } from '@fluentui/react/lib/Checkbox'
import { Avatar, ModalTopBar } from '../common'
import { BackButton } from '../../../../components/buttons/NavButtons'
import { Row } from '../../../../components/layout/FlexContainer'
import { TagPicker } from '@fluentui/react/lib/Pickers'
import { useUserGroups } from '../../../../queries/userGroups'
import { ActionButton } from '@fluentui/react/lib/Button'
import { Accordion } from '../../../../components/accordion/Accordion'
import { motion } from 'framer-motion'
import { UserGroupCalloutMessage } from '../../../../components/users/UserGroupCalloutMessage'

const UsersRow = styled(Row)`
  overflow-x: auto;
  overflow-y: hidden;
  flex-direction: column;
  flex-wrap: wrap;
`

const UserGroupsRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  row-gap: 0;
  column-gap: 8px;
  flex-wrap: wrap;
`

const SettingsUserCellContainer = styled.div`
  display: flex;
  min-width: 300px;
  max-width: 300px;
  height: 3em;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

const SmallEmail = styled.span`
  color: ${p => p.theme.palette.neutralSecondary};
  font-size: 83%;
`

const NameAndEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const EmailAndName = memo(({ name, fullName, email }) => {
  return (
    <SettingsUserCellContainer>
      {name ? (
        <NameAndEmailContainer>
          <span>{fullName}</span>
          <SmallEmail>{email}</SmallEmail>
        </NameAndEmailContainer>
      ) : (
        <span>{email}</span>
      )}
    </SettingsUserCellContainer>
  )
})

const SettingsUserCell = ({ user, usernameBlacklist, dispatch }) => {
  const email = user.get('username')
  const name = user.get('first_name')
  const fullName = name + ' ' + user.get('last_name')
  const avatarSrc = getAvatarURIForEmail(email)
  const checked = !usernameBlacklist.has(email)
  const onChange = (evt, val) => {
    dispatch(toggleUsernameStatus(email, val))
  }

  return (
    <SettingsUserCellContainer>
      <Checkbox onChange={onChange} checked={checked} />
      <Avatar src={avatarSrc} />
      <EmailAndName name={name} fullName={fullName} email={email} />
    </SettingsUserCellContainer>
  )
}

const SettingsUserGroupButton = ({ userGroup, team, dispatch }) => {
  const iconProps = {
    imageProps: {
      src: userGroup.group_logo,
      height: 16,
      width: 16,
    },
  }

  const handleClick = () => {
    dispatch(setBlacklistUserGroup(userGroup, team))
  }

  return (
    <motion.div whileTap={{ scale: 0.9 }} style={{ display: 'inline-block', width: 'fit-content' }}>
      <ActionButton onClick={handleClick} iconProps={iconProps}>
        {userGroup.name}
      </ActionButton>
    </motion.div>
  )
}

const TitleRow = styled(Row)`
  height: 2em;
  align-items: center;
  gap: 20px;
`

const SpanButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

const SAccordion = styled(Accordion)`
  margin: 20px 0 0;
`

const AccordionTitleText = styled.h3`
  margin: 0;
`

export const SettingsModal = ({ team, dismiss }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const usernameBlacklist = useUsernameBlacklist()
  const isEmpty = !usernameBlacklist.size
  const { data: unorderedUserGroups } = useUserGroups()
  const userGroups = useMemo(
    () => unorderedUserGroups?.sort((a, b) => a.name.localeCompare(b.name)),
    [unorderedUserGroups]
  )
  const handleToggleAll = () => {
    if (isEmpty) {
      const allSet = team.keySeq().toSet()
      dispatch(setBlacklist(allSet))
    } else {
      dispatch(setBlacklist(Set()))
    }
  }
  const userCells = team
    .toList()
    .map(u => <SettingsUserCell user={u} key={u.get('id')} usernameBlacklist={usernameBlacklist} dispatch={dispatch} />)
    .toArray()
  const userCount = team.size - usernameBlacklist.size

  const userGroupsButtons = userGroups?.map(userGroup => (
    <SettingsUserGroupButton userGroup={userGroup} dispatch={dispatch} team={team} key={userGroup.id} />
  ))

  const userGroupsCalloutMessage = (
    <>
      <UserGroupCalloutMessage />
      <br />
      {t('effort_planning.filter_user_group_info')}
    </>
  )

  return (
    <div className="mx-4 flex flex-col overflow-auto">
      <ModalTopBar>
        <BackButton onClick={dismiss} />
      </ModalTopBar>
      <TitleRow>
        <h3>{t('general.excluded_tags')}</h3>
      </TitleRow>
      <p>{t('effort_planning.excluded_tags_tooltip')}</p>
      <MockTagSelector />
      <ToggleCompleted />
      <SAccordion
        titleTextComponent={<AccordionTitleText>{t('general.user_groups')}</AccordionTitleText>}
        title={t('general.user_groups')}
        calloutMessage={userGroupsCalloutMessage}
      >
        <UserGroupsRow>{userGroupsButtons}</UserGroupsRow>
      </SAccordion>
      <TitleRow>
        <h3>
          {t('general.users')} ({userCount})
        </h3>
        <SpanButton onClick={handleToggleAll}>{isEmpty ? t('general.clear_all') : t('general.select_all')}</SpanButton>
      </TitleRow>
      <UsersRow>{userCells}</UsersRow>
    </div>
  )
}

const ToggleContainer = styled.div`
  margin-top: 2em;
`

const ToggleCompleted = () => {
  const checked = useShowCompleted()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const onChange = () => dispatch(setShowCompleted(!checked))
  return (
    <ToggleContainer>
      <Checkbox onChange={onChange} checked={checked} label={t('effort_planning.show_completed')} />
    </ToggleContainer>
  )
}

const TagSelectorContainer = styled.div`
  max-width: 20em;
  max-height: 3em;
`

const MockTagSelector = () => {
  const selectedItems = useMemo(() => {
    return [
      {
        key: 'onhold',
        name: 'onhold',
      },
    ]
  }, [])
  return (
    <TagSelectorContainer>
      <TagPicker disabled selectedItems={selectedItems} />
    </TagSelectorContainer>
  )
}
