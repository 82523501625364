import { useCallback, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { itemHelper, projectHelper, stateHelper } from '../../common/src/helpers'
import { useFetch } from '../../common/src/hooks/networkingHooks'
import { projectHandlerInApp as init } from '../../actions/viewInitializationActions'
import { getMatchParameterInteger } from '../../common/src/utils'
import { useItemSortDescriptor } from '../../common/src/hooks/filtersHooks'
import { applyCurrentFiltersToItems } from '../../common/src/selectors/filtersSelectors'
import { StaticItemList } from '../../components/list/staticItemList/StaticItemList'
import { useRowRenderer } from '../home/commonRowrenderer'
import { Button, Spinner } from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { BASIC_ITEM_LIST_TYPES } from '../home/HomeConstants'
import { useReactToPrint } from 'react-to-print'
import { useTranslation } from 'react-i18next'
import { PrintableComponent } from '../../components/PrintableComponent'
import { FlexRow } from '../../components/layout/FlexContainer'
import { QuadrantSelector } from '../../components/input/quadrant/QuadrantSelector'
import { Print } from '../../components/BundledIcons'
import { META_KEY, ShortcutHelp } from '../../components/shortcutHelp/ShortcutHelp'
import { isPrintHotKey, useHotkey } from '../../hooks/useHotkey'

export const ProjectPrint = ({ match }) => {
  const listRef = useRef()
  const dispatch = useDispatch()
  const pid = getMatchParameterInteger(match, 'pid')
  const project = useSelector(state => stateHelper.getProject(state, pid))
  const sortFn = useItemSortDescriptor()
  const items = useSelector(state => applyCurrentFiltersToItems(state, stateHelper.getItemsInProject(state, project)))
  const sortedItems = useMemo(() => items?.sort(sortFn), [items, sortFn])
  const fetchMethod = useCallback(() => dispatch(init(pid)), [dispatch, pid])
  const { loading } = useFetch(fetchMethod)
  const rowRenderer = useRowRenderer({ displayAdd: false, showProject: false })
  const handlePrint = useReactToPrint({
    content: () => listRef.current,
  })
  const { t } = useTranslation()
  useHotkey(isPrintHotKey, handlePrint)

  // Create a list of rows with quadrant headers
  const rows = useMemo(() => {
    const rows = []
    const itemsByQuadrant = new Map([
      [0, []],
      [1, []],
      [2, []],
      [3, []],
    ])
    sortedItems?.forEach(item => {
      const quadrant = itemHelper.getQuadrant(item)
      itemsByQuadrant.get(quadrant).push(item)
    })
    itemsByQuadrant.forEach((items, quadrant) => {
      rows.push({
        type: BASIC_ITEM_LIST_TYPES.HEADER,
        text: (
          <FlexRow>
            <QuadrantSelector className="mr-2.5" size={24} quadrant={quadrant} project={project} />
            {projectHelper.getQuadrantName(project, quadrant)}
          </FlexRow>
        ),
      })
      rows.push(...items)
    })
    return rows
  }, [sortedItems, project])

  return (
    <div className="flex h-full flex-col items-center">
      <Tooltip relationship="description" content={<ShortcutHelp keys={[META_KEY, 'P']} />}>
        <Button appearance="primary" className="!my-3" onClick={handlePrint} icon={<Print />} disabled={loading}>
          {t('meeting.print_button_title')}
        </Button>
      </Tooltip>
      <PrintableComponent className="h-full w-full" ref={listRef} pageName={projectHelper.getName(project)}>
        <StaticItemList
          className="h-full"
          rows={rows}
          rowRenderer={rowRenderer}
          noRowsRenderer={() => <Spinner />}
          loading={loading}
        />
      </PrintableComponent>
    </div>
  )
}
