import _ from 'lodash'
import { resourceURICreator } from '../common/src/helpers/URLHelper'
import { PM_API_RESOURCE_TYPE } from '../common/src/constants'
import { BASE_URL } from '../common/src/constants'

export const getRichElementValue = (node, lines, line, selNode, selOffset) => {
  const nodeType = node.nodeType
  if (nodeType === 3) {
    // TEXT
    const value = node.nodeValue
    if (selNode === node) {
      line.push(value.substr(0, selOffset) + '\x01' + value.substr(selOffset))
    } else {
      line.push(value)
    }
    return
  }
  if (nodeType !== 1) {
    // NON-ELEMENT
    return
  }
  const isSelected = selNode === node
  const tagName = node.tagName
  const isBlock = tagName === 'DIV' || tagName === 'P'
  if ((isBlock && line.length) || tagName === 'BR') {
    lines.push(line.join(''))
    line.splice(0, line.length)
  } else if (tagName === 'IMG') {
    if (node.alt) {
      line.push(node.alt)
    }
  }
  if (isSelected && !selOffset) {
    line.push('\x01')
  }
  let curChild = node.firstChild
  while (curChild) {
    getRichElementValue(curChild, lines, line, selNode, selOffset)
    curChild = curChild.nextSibling
  }
  if (isSelected && selOffset) {
    line.push('\x01')
  }
  if (isBlock && line.length) {
    lines.push(line.join(''))
    line.splice(0, line.length)
  }
}

export const getRichValue = field => {
  if (!field) {
    return ''
  }
  let lines = []
  let line = []

  getRichElementValue(field, lines, line)
  if (line.length) {
    lines.push(line.join(''))
  }

  let value = lines.join('\n')
  value = value.replace(/\u00A0/g, ' ')

  return value
}

const replaceUsers = field => {
  _.each(field.querySelectorAll('b'), mention => {
    if (mention.dataset.userId) {
      const userId = mention.dataset.userId
      const userUri = resourceURICreator(1, PM_API_RESOURCE_TYPE.USER, userId)
      mention.innerHTML = BASE_URL.slice(0, -1) + userUri
    }
  })
  return field
}

const createElement = innerHTML => {
  const e = document.createElement('div')
  e.innerHTML = innerHTML
  return e
}

export const getSafeComment = _.flow(createElement, replaceUsers, getRichValue, _.trim)
export const isCommentEmpty = _.flow(getSafeComment, _.isEmpty)
