import { useSelector } from 'react-redux'
import { itemHelper, stateHelper } from '@/common/src/helpers'
import { Suspense, useCallback, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { dateToTimestampInSeconds } from '@/common/src/helpers/dateHelper'
import { useDispatch } from 'react-redux'
import { useFilteredItems, useParamsForItemsFilter } from '@/common/src/hooks/filtersHooks'
import { ITEM_FILTER_SECTION_KEY } from '@/common/src/reducers/filtersKeys'
import { lazy } from 'react'
import { retry } from '@/common/src/retry'
import { Loading } from '@/components/basic/Loading'
import { addMonths } from 'date-fns'
import { getAllItemSummaries } from '@/common/src/actions/itemsAPI'
import { ItemList, DateInterval } from '@/types'

const Gantt = lazy(() => retry(() => import('./v2/Gantt')))

const FILTERS_KEY = ITEM_FILTER_SECTION_KEY.GLOBAL_GANTT

const getDefaultInterval = (): DateInterval => {
  const now = new Date()
  return [addMonths(now, -6), addMonths(now, 6)]
}

const useFetchData = ({ interval, queryInterval }: InnerState) => {
  const dispatch = useDispatch()
  const baseParams = useParamsForItemsFilter(FILTERS_KEY)
  const innerKey = queryInterval.map(d => d.getTime()).join('-')

  const params = {
    ...baseParams,
    dueDate__gt: dateToTimestampInSeconds(queryInterval[0]),
    dueDate__lt: dateToTimestampInSeconds(queryInterval[1]),
    dueDate__gte: undefined,
    state__lte: 1,
    order_by: undefined,
    limit: 300,
    returnlinks: 1,
  }

  return useQuery({
    queryKey: ['gantt-items', innerKey],
    queryFn: () => {
      return dispatch(getAllItemSummaries(params, 'gantt'))
    },
    enabled: !!interval,
  })
}

interface InnerState {
  interval: DateInterval
  queryInterval: DateInterval
  fetchedInterval: DateInterval
}

const useGanttData = () => {
  const [innerState, setDateRange] = useState<InnerState>(() => ({
    interval: getDefaultInterval(),
    fetchedInterval: getDefaultInterval(),
    queryInterval: getDefaultInterval(),
  }))
  const { interval } = innerState
  const { isFetching } = useFetchData(innerState)
  const allItems = useSelector(stateHelper.getAllItemsMap)
  const afterFirstFilter: ItemList = useFilteredItems(allItems, FILTERS_KEY)

  const filtered = useMemo(() => {
    return afterFirstFilter.filter(i => {
      const due = itemHelper.getDueDate(i) ?? new Date(0)
      return due > interval[0] && due < interval[1]
    }) as ItemList
  }, [afterFirstFilter, interval])

  const projects = useSelector(stateHelper.getAllProjects)
  const onChangeRenderInterval = useCallback(
    (nextRenderInterval: DateInterval) => {
      setDateRange(current => {
        const i = nextRenderInterval
        let queryInterval: DateInterval = current.queryInterval
        const fetchedInterval: DateInterval = current.fetchedInterval
        if (i[0] < fetchedInterval[0]) {
          queryInterval = [i[0], current.fetchedInterval[0]]
          fetchedInterval[0] = i[0]
        } else if (fetchedInterval[1] < i[1]) {
          queryInterval = [current.fetchedInterval[1], i[1]]
          fetchedInterval[1] = i[1]
        }
        return {
          interval: nextRenderInterval,
          fetchedInterval,
          queryInterval,
        }
      })
    },
    [setDateRange]
  )

  return {
    items: filtered,
    projects,
    onChangeRenderInterval,
    isFetching,
    interval,
  }
}

export const PMGlobalGantt = () => {
  const { items, projects, onChangeRenderInterval, isFetching, interval } = useGanttData()
  const project = projects?.first()

  if (!isFetching && !items.size) {
    return <div className="text-center">No items to display</div>
  }

  return (
    <Suspense fallback={<Loading />}>
      <Gantt
        items={items}
        projects={projects}
        project={project}
        readOnly={false}
        global
        interval={interval}
        onChangeRenderInterval={onChangeRenderInterval}
        isFetching={isFetching}
      />
    </Suspense>
  )
}
