import { useEffect } from 'react'
import _ from 'lodash'
import { bufferCount, filter, fromEvent, map } from 'rxjs'
import { targetIsKindOfTextInput } from '../common/src/helpers/eventHelpers'

const generateKonamiSubscription = cheatCode => {
  const validate = _.negate(targetIsKindOfTextInput)
  return fromEvent(document, 'keydown').pipe(
    filter(validate),
    map(p => _.get(p, 'key')),
    bufferCount(cheatCode.length, 1),
    map(b => _.join(b, '')),
    filter(s => s === cheatCode)
  )
}

export const useKonamiCheatCode = (cheatCode, callback) => {
  useEffect(() => {
    const subscription = generateKonamiSubscription(cheatCode).subscribe(callback)
    return () => {
      subscription.unsubscribe()
    }
  }, [cheatCode, callback])
}

const generateShortcutSubscription = (keyCode, modifiers = []) => {
  const checkModifiers = evt => {
    if (_.isEmpty(modifiers)) {
      return true
    }
    return _.filter(modifiers, m => !!_.get(evt, m)).length === modifiers.length
  }
  const checkIsNotTextInput = _.negate(targetIsKindOfTextInput)
  const validate = evt => checkIsNotTextInput(evt) && checkModifiers(evt)

  return fromEvent(document, 'keydown').pipe(
    filter(validate),
    map(p => _.get(p, 'keyCode')),
    filter(s => s === keyCode)
  )
}

export const useShortcut = (keyCode, modifiers = [], callback) => {
  useEffect(() => {
    const subscription = generateShortcutSubscription(keyCode, modifiers).subscribe(callback)
    return () => {
      subscription.unsubscribe()
    }
  }, [keyCode, modifiers, callback])
}
