import { Spinner as MSSpinner } from '@fluentui/react-components'
import { FlexContainer as Container } from '../layout/FlexContainer'

export const Spinner = MSSpinner

export const Loading = ({ label = '', className = '' }) => {
  return (
    <Container className={className}>
      <Spinner size="large" label={label} />
    </Container>
  )
}
