import { Suspense, useState } from 'react'
import { useSelector } from 'react-redux'
import { isPendingRequestForPMResource } from '../../common/src/selectors/requestStateSelectors'
import { PM_API_RESOURCES } from '../../common/src/constants'
import { Loading } from '../../components/basic/Loading'
import { useRandomValueFromArray } from '../../hooks/arrayHooks'
import { BasicItemList } from '../../components/item/basicList/BasicItemList'
import { useTranslatedPlaceholders } from '../../hooks/translationHooks'
import { LazyIViewPlaceholder } from '../../components/placeholder/LazyIViewPlaceholder'
import { useSelectedItemId } from '../../hooks/PMHooks'
import { itemHelper, stateHelper } from '@/common/src/helpers'

export const HomeRecents = ({ onSelectItem, items }) => {
  const placeholders = useTranslatedPlaceholders('home.recent.placeholder_', 3)
  const loading = useSelector(state => isPendingRequestForPMResource(state, PM_API_RESOURCES.RECENTLY_VISITED_COMMENTS))
  const selectedItemId = useSelectedItemId()
  const allItems = useSelector(stateHelper.getAllItemsMap)

  const [previousOrder, setPreviousOrder] = useState([])

  const previousIds = new Set(previousOrder.map(item => itemHelper.getId(item)))
  const currentIds = new Set(items.map(item => itemHelper.getId(item)))

  const hasSameIds = currentIds.size === previousIds.size && [...currentIds].every(id => previousIds.has(id))

  const sortedItems = (() => {
    if (hasSameIds) {
      return previousOrder.map(prevItem => {
        const id = itemHelper.getId(prevItem)
        return allItems.get(id)
      })
    } else {
      setPreviousOrder(items)
      return items
    }
  })()

  const placeholder = useRandomValueFromArray(placeholders)
  if (!sortedItems.length && !loading) {
    return (
      <Suspense fallback={<Loading />}>
        <LazyIViewPlaceholder {...placeholder} />
      </Suspense>
    )
  }

  return (
    <BasicItemList
      items={sortedItems}
      selectedItemId={selectedItemId}
      onClickItem={onSelectItem}
      loading={loading}
      shouldShowProject
    />
  )
}
