import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Switch } from 'react-router'
import { MainLoadingView } from '../views/LoadingView/MainLoadingView'
import { MoveItemModalContext, MoveItemsModalContext, RouteIdContext } from '../contexts'
import { LinkManager } from '../components/linkManager/linkManager'
import { RedirectIframe } from '../components/iframe/RedirectIframe'
import { MoveItemModal } from '../components/modal/MoveItemModal'
import { routes } from './routes'
import { NestedRoute } from './nestedRoutes'
import { trackSource } from '../common/src/actions/trackingAPI'
import { CreateItemModalSingleton } from '../views/modal/createItem/CreateItemModalSingleton'
import { ViewSizeDetector } from '../components/generic/ViewSizeDetector'
import { HistoryManager } from '../components/managers/HistoryManager'
import { InactivityManager } from '../components/managers/InactivityManager'
import { ViewportManager } from '../components/generic/ViewportManager'
import { InvitationsModalSingleton } from '../components/invitations/InvitationsModalSingleton'
import { FileManager } from '../components/managers/FileManager'
import { isStateRehydrated } from '../selectors/uiSelectors'
import { useMergeState } from '../common/src/hooks/enhancedHooks'
import { AlertModalSingleton } from '../views/modal/AlertModalSingleton'
import { ShortcutListener } from '../components/ShortcutListener'
import { ItemLinkPanelSingleton } from '../components/panels/item/ItemLinkPanelSingleton'
import { MoveItemsModal } from '../components/modal/MoveItemsModal'
import { useNarrowWidth } from '../hooks/useNarrowWidth'
import { Toaster } from '@fluentui/react-components'
import { PrintItemsModalSingleton } from '../components/modal/PrintItemsModal'
import { IconSelectorModalSingleton } from '../views/modal/IconSelectorModalSingleton'
import { DatesPanelSingleton } from '../views/modal/DatesPanelSingleton'
import { OwnerPanelSingleton } from '../views/modal/OwnerPanelSingleton'
import { WebinarReminder } from '../components/WebinarReminder'
import { EmbeddedHooksManager } from '@/components/EmbeddedHooksManager'
import { ShareIntentListener } from '@/components/ShareIntentListener'
import { CenterTitleManager } from '@/components/managers/ParamsManager'
import { useAttentionNeededQuery } from '@/queries/items'

// We ensure the store is rehydrated before rendering
const rehydrateContainer = connect(state => ({ rehydrated: isStateRehydrated(state) }))
const RehydrateSafety = rehydrateContainer(({ rehydrated, children }) => rehydrated && children)
const renderedRoutes = _.map(routes, (_data, i) => NestedRoute({ key: i, data: _data }))

const MyToaster = () => {
  const narrow = useNarrowWidth()
  return (
    <Toaster
      pauseOnHover
      pauseOnWindowBlur
      offset={{ horizontal: narrow ? 0 : 36, vertical: narrow ? 60 : 30 }}
      position={narrow ? 'bottom' : 'bottom-start'}
    />
  )
}

export const RouterContent = ({ loading }) => {
  const dispatch = useDispatch()
  const [moveItemModalState, setMoveItemModalState] = useMergeState({
    isDisplayed: false,
    item: null,
    initProject: null,
    initQuadrant: 0,
    onMoveItem: null,
  })
  const moveItemContextValue = useMemo(
    () => ({ moveItemModalState, setMoveItemModalState }),
    [moveItemModalState, setMoveItemModalState]
  )
  const [moveItemsModalState, setMoveItemsModalState] = useMergeState({
    isDisplayed: false,
    items: null,
    initProject: null,
    initQuadrant: 0,
    onMoveItem: null,
  })
  const moveItemsContextValue = useMemo(
    () => ({ moveItemsModalState, setMoveItemsModalState }),
    [moveItemsModalState, setMoveItemsModalState]
  )

  const [routeId, setRouteId] = useState(0)
  const routeIdContextValue = useMemo(() => ({ routeId, setRouteId }), [routeId])

  // Track the first visit
  // in each session using referrer
  useEffect(() => {
    dispatch(trackSource())
  }, [dispatch])

  // initial reqs
  useAttentionNeededQuery()

  return (
    <MainLoadingView loading={loading}>
      <RehydrateSafety>
        <RouteIdContext.Provider value={routeIdContextValue}>
          <MoveItemModalContext.Provider value={moveItemContextValue}>
            <MoveItemsModalContext.Provider value={moveItemsContextValue}>
              <ViewportManager>
                <ViewSizeDetector>
                  <MyToaster />
                  <RedirectIframe>
                    <HistoryManager>
                      <CenterTitleManager>
                        <InactivityManager>
                          <FileManager>
                            <AlertModalSingleton>
                              <ItemLinkPanelSingleton>
                                <CreateItemModalSingleton>
                                  <InvitationsModalSingleton>
                                    <PrintItemsModalSingleton>
                                      <IconSelectorModalSingleton>
                                        <DatesPanelSingleton>
                                          <OwnerPanelSingleton>
                                            <WebinarReminder />
                                            <ShareIntentListener />
                                            <ShortcutListener />
                                            <LinkManager />
                                            <EmbeddedHooksManager />
                                            <MoveItemModal
                                              item={moveItemModalState.item}
                                              initProject={moveItemModalState.initProject}
                                              initQuadrant={moveItemModalState.initQuadrant}
                                              isOpen={moveItemModalState.isDisplayed}
                                              onMoveItem={moveItemModalState.onMoveItem}
                                              onDismiss={() => setMoveItemModalState({ isDisplayed: false })}
                                            />
                                            <MoveItemsModal
                                              items={moveItemsModalState.items}
                                              initProject={moveItemsModalState.initProject}
                                              initQuadrant={moveItemsModalState.initQuadrant}
                                              isOpen={moveItemsModalState.isDisplayed}
                                              onMoveItem={moveItemsModalState.onMoveItem}
                                              onDismiss={() => setMoveItemsModalState({ isDisplayed: false })}
                                            />
                                            <Switch>{renderedRoutes}</Switch>
                                          </OwnerPanelSingleton>
                                        </DatesPanelSingleton>
                                      </IconSelectorModalSingleton>
                                    </PrintItemsModalSingleton>
                                  </InvitationsModalSingleton>
                                </CreateItemModalSingleton>
                              </ItemLinkPanelSingleton>
                            </AlertModalSingleton>
                          </FileManager>
                        </InactivityManager>
                      </CenterTitleManager>
                    </HistoryManager>
                  </RedirectIframe>
                </ViewSizeDetector>
              </ViewportManager>
            </MoveItemsModalContext.Provider>
          </MoveItemModalContext.Provider>
        </RouteIdContext.Provider>
      </RehydrateSafety>
    </MainLoadingView>
  )
}
