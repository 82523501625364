import React from 'react'
import styled from 'styled-components'
import { ActionButton } from '@fluentui/react/lib/Button'
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble'
import { ICON_NAMES } from '../../common/src/msIcons'
import { useId } from '../../hooks/useId'
import { useMe } from '../../common/src/hooks/usersHooks'
import { useTranslation } from 'react-i18next'

const Text = styled.span`
  color: ${p => p.theme.palette.neutralLight};
  & a {
    color: ${p => p.theme.palette.neutralLight};
    font-weight: bold;
  }
`

const SecretInbox_ = ({ className }) => {
  const [show, setShow] = React.useState(false)
  const me = useMe()
  const id = useId('secret_inbox_')
  const { t } = useTranslation()
  const target = '#' + id
  const iconProps = {
    iconName: ICON_NAMES.MailForward,
  }
  const onDismiss = () => setShow(false)
  const onClick = () => setShow(true)
  const mail = me?.get('secret_inbox')
  return (
    <>
      <ActionButton className={className} iconProps={iconProps} onClick={onClick} id={id}>
        {t('inbox.secret_inbox_title')}
      </ActionButton>
      {show && (
        <TeachingBubble target={target} onDismiss={onDismiss} isWide headline={t('inbox.secret_inbox_title')}>
          <Text>
            {t('inbox.secret_inbox_message_1')} (<a href={`mailto:${mail}`}>{mail}</a>)
            {t('inbox.secret_inbox_message_2')}
          </Text>
        </TeachingBubble>
      )}
    </>
  )
}

export const SecretInboxButton = React.memo(SecretInbox_)
