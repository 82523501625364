import React from 'react'
import styled from 'styled-components'
import { Icon } from '@fluentui/react/lib/Icon'

const PlaceholderContainer = styled.span`
  display: flex;
  align-items: center;
  height: 24px;
  color: ${p => p.theme.palette.neutralTertiary};
  margin: 2px;
  font-size: 12px;
`

const StyledIcon = styled(Icon)`
  margin-right: 2px;
`

export const AttributePlaceholder = ({ name, iconName, ...rest }) => {
  return (
    <PlaceholderContainer {...rest}>
      {iconName && <StyledIcon iconName={iconName} />}
      {name}
    </PlaceholderContainer>
  )
}
