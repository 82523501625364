import React, { forwardRef, memo, useCallback, useMemo } from 'react'
import { cn } from '@appfluence/classnames'
import styled, { useTheme } from 'styled-components'
import meetingHelper from '../../common/src/helpers/meetingHelper'
import { dateStringRelativeTo, getDateColor } from '../../common/src/helpers/dateHelper'
import { UsersCollection } from '../../components/users/usersCollection/UsersCollection'
import { formatDate } from '../../utils/datefns'

const Name = styled.div`
  display: -webkit-box;
  flex: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;

  color: ${p => p.theme.palette.black};
  user-select: none;
  text-decoration: none;

  &.narrow {
    -webkit-line-clamp: 1;
  }
`

const DateContainer = styled.div`
  font-size: 10px;
  color: ${p => p.color ?? p.theme.palette.neutralDark};
`

const Center = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  align-self: center;
`

// Container
const CellContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  position: relative;
  background: ${p => p.theme.palette.white};
  padding: 8px;
  box-sizing: border-box;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      background: ${p => p.theme.palette.neutralLight};
    }
  }

  &.selected {
    background: ${p => p.theme.palette.themeTertiary};
  }
`

export const MeetingCell = memo(
  forwardRef(({ meeting, onClick, onAdd, selected = false, narrow = false, ...rest }, ref) => {
    const handleClick = useCallback(
      event => {
        // Need to do this in order to avoid
        // triggering doubleClick on quadrant
        event.stopPropagation()
        onClick(meeting)
      },
      [onClick, meeting]
    )
    const theme = useTheme()

    const id = meetingHelper.getID(meeting)
    const title = meetingHelper.getTitle(meeting)
    const startDate = meetingHelper.getStartDate(meeting)
    const endDate = meetingHelper.getEndDate(meeting)
    const [startText, endText] = useMemo(() => {
      const startText = startDate ? formatDate('ShortenedNormal')(startDate) : ''
      const endText = startDate && endDate ? dateStringRelativeTo(startDate, endDate) : ''
      return [startText, endText]
    }, [startDate, endDate])

    const dateColor = getDateColor({
      startDate,
      endDate,
      positiveColor: theme.palette.affirmative,
      negativeColor: theme.palette.negative,
    })
    const participants = useMemo(() => (meeting ? meetingHelper.getParticipants(meeting).toArray() : []), [meeting])

    const cellClass = cn({ selected })
    const narrowClassName = cn({ narrow })
    return (
      <CellContainer onClick={handleClick} className={cellClass} aria-describedby={id} ref={ref} {...rest}>
        <Center>
          <Name className={narrowClassName}>{title}</Name>
          <DateContainer color={dateColor}>
            {!!startText && <span style={{ color: dateColor }}>{startText}</span>}
            {!!startText && !!endText && <span> to </span>}
            {!!endText && <span style={{ color: dateColor }}>{endText}</span>}
          </DateContainer>
        </Center>
        <UsersCollection max={narrow ? 2 : 5} users={participants} showAddCell={false} readOnly={true} />
      </CellContainer>
    )
  })
)
