export const GENERIC_ACTION_TYPE = {
  INIT_ALL: 'GENERIC/INIT_ALL',
  CLEAR_ALL: 'GENERIC/CLEAR_ALL',
}

export const initAll = () => ({
  type: GENERIC_ACTION_TYPE.INIT_ALL,
})

export const clearAll = () => ({
  type: GENERIC_ACTION_TYPE.CLEAR_ALL,
})
