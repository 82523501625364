import { useToastController } from '@fluentui/react-components'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FluentToast } from '../components/toast/FluentToast'

export const useShowToastDidCopyLinkToClipboard = () => {
  const { dispatchToast } = useToastController()
  const { t } = useTranslation()
  return useCallback(() => {
    dispatchToast(<FluentToast>{t('general.link_copy_to_clipboard')}</FluentToast>, {
      intent: 'success',
    })
  }, [dispatchToast, t])
}
