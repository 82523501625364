import { useStore } from 'react-redux'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { appendQueryParamsToURL, getCredentialsConfig } from '../common/src/helpers/requestHelper'
import type { ProjectSubscriptions } from '../types/projectSubscriptions'

export const useProjectSubscriptions = (projectId: number, enabled = true) => {
  const store = useStore()

  return useQuery({
    queryKey: ['projects', projectId, 'subscriptions'],
    queryFn: async () => {
      const response = await fetch(
        appendQueryParamsToURL(`/api/v2/project/${projectId}/subscriptions/`)(store.getState()),
        {
          credentials: getCredentialsConfig(),
        }
      )
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const json = await response.json()
      if (!json.subscriptions) {
        throw new Error('JSON has no "subscriptions" field')
      }
      return json.subscriptions as ProjectSubscriptions
    },
    enabled,
  })
}

type ProjectSubscriptionsMutationVars = {
  projectId: number
  subscriptions: ProjectSubscriptions
}
export const useMutateProjectSubscriptions = () => {
  const store = useStore()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ projectId, subscriptions }: ProjectSubscriptionsMutationVars) => {
      const response = await fetch(
        appendQueryParamsToURL(`/api/v2/project/${projectId}/subscriptions/`)(store.getState()),
        {
          method: 'POST',
          credentials: getCredentialsConfig(),
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(subscriptions),
        }
      )
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const json = await response.json()
      if (!json.subscriptions) {
        throw new Error('JSON has no "subscriptions" field')
      }
      return json.subscriptions as ProjectSubscriptions
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['projects', variables.projectId, 'subscriptions'], data)
    },
  })
}
