import { itemHelper } from '../../common/src/helpers'
import { IconCell } from './IconCell'
import '../../resources/images/icons/sprite.css'
import { cn } from '@appfluence/classnames'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { useTranslation } from 'react-i18next'
import { useChangeIcon } from '../../typedContexts'

export const IconDetail = ({ item, onChange, readOnly = false }) => {
  const { t } = useTranslation()
  const iconName = itemHelper.getIconNameSafelyWithoutExtension(item)
  const { openModal } = useChangeIcon()

  const onChangeIcon = iconName => {
    onChange(iconName)
  }

  const onClick = () => {
    !readOnly && openModal({ currentIcon: iconName, onChangeIcon })
  }

  let buttonTooltip = ''
  if (!readOnly) {
    const noIcon = iconName === itemHelper.EMPTY_ICON_WITHOUT_EXTENSION
    buttonTooltip = noIcon ? t('item.icon.add_icon') : t('item.icon.change_icon')
  }

  return (
    <Tooltip content={buttonTooltip} relationship="label">
      <IconCell
        icon={iconName}
        side={24}
        aria-label={buttonTooltip}
        onClick={onClick}
        className={cn(!readOnly && 'cursor-pointer')}
        iconProps={{ id: 'iconDetail_iconCell' }}
      />
    </Tooltip>
  )
}
