import { createGraphResourceBase } from './graphResourceBase'
import { ICON_NAMES } from '../../msIcons'
import { GRAPH_RESOURCE_TYPE } from './graphResourceBase'

const helper = {}

helper.create = task => {
  task.lastModifiedDateTime = task.createdDateTime // Planner tasks don't have timestamp
  return createGraphResourceBase(task, {
    name: task.title || 'No name',
    icon: ICON_NAMES.PlannerLogo,
    iconColor: '#407c3e',
    link: 'https://tasks.office.com',
    // link: "https://tasks.office.com/appfluence.onmicrosoft.com/en-us/Home/Task/aXLNzF4S1UOJvWQb_IYcsGUAF8JA?Type=TaskLink"
    type: GRAPH_RESOURCE_TYPE.PLANNER_TASK,
  })
}

helper.isPlannerTask = m => m.type === GRAPH_RESOURCE_TYPE.PLANNER_TASK

export default helper
