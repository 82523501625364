import { useDispatch } from 'react-redux'
import { useProjectFilter } from './filtersHooks'
import { useCallback } from 'react'
import _ from 'lodash'
import { fromJS } from 'immutable'
import {
  createCustomTemplateFromProject,
  instantiateCustomProjectTemplate,
  instantiatePublicProjectTemplate,
} from '../actions/projectTemplatesAPI'
import { OBJECT_ERROR_STATUS, ObjectError } from '../errors/errors'
import { setDefaultFilterProjects } from '../actions/filtersActions'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent as trackEvent, EVENT_EXTRA } from '../eventTracking/amplitudeEvents'

export const useInstantiateCustomTemplate = () => {
  const dispatch = useDispatch()
  const projectFilter = useProjectFilter()

  return useCallback(
    async ({ templateID, startingDate, clearingFilters = true, eventProps }) => {
      if (!templateID || !_.isNumber(templateID)) {
        const errorObject = new ObjectError(OBJECT_ERROR_STATUS.INVALID_IDENTIFIER, 'Invalid template identifier')
        return { error: true, payload: errorObject }
      }

      const params = {}
      if (startingDate) {
        params['starting_date'] = _.toInteger(startingDate.getTime() / 1000)
      }

      const response = await dispatch(instantiateCustomProjectTemplate(templateID, params))
      const { error, payload } = response
      if (error) {
        return response
      }

      const project = fromJS(payload)
      // Clear filters
      if (clearingFilters && !projectFilter(project)) {
        dispatch(setDefaultFilterProjects())
      }

      // Create event
      const eventPropsForCustom = { type: EVENT_EXTRA.INSTANTIATE_TEMPLATE.TYPE.CUSTOM }
      dispatch(trackEvent(AMPLITUDE_ACTION_TYPES.INSTANTIATE_TEMPLATE, { ...eventPropsForCustom, ...eventProps }))

      return { payload: project }
    },
    [dispatch, projectFilter]
  )
}

export const useInstantiatePublicTemplate = () => {
  const dispatch = useDispatch()
  const projectFilter = useProjectFilter()

  return useCallback(
    async ({ templateID, clearingFilters = true, eventProps }) => {
      if (!templateID || !_.isNumber(templateID)) {
        const errorObject = new ObjectError(OBJECT_ERROR_STATUS.INVALID_IDENTIFIER, 'Invalid template identifier')
        return { error: true, payload: errorObject }
      }

      const response = await dispatch(instantiatePublicProjectTemplate(templateID))
      const { error, payload } = response
      if (error) {
        return response
      }

      const project = fromJS(payload)
      // Clear filters
      if (clearingFilters && !projectFilter(project)) {
        dispatch(setDefaultFilterProjects())
      }

      // Create event
      const eventPropsForPublic = { type: EVENT_EXTRA.INSTANTIATE_TEMPLATE.TYPE.PUBLIC }
      dispatch(trackEvent(AMPLITUDE_ACTION_TYPES.INSTANTIATE_TEMPLATE, { ...eventPropsForPublic, ...eventProps }))

      return { payload: project }
    },
    [dispatch, projectFilter]
  )
}

export const useCreateCustomTemplateFromProject = () => {
  const dispatch = useDispatch()

  return useCallback(
    async ({ projectID, params, eventProps }) => {
      if (!projectID || !_.isNumber(projectID)) {
        const errorObject = new ObjectError(OBJECT_ERROR_STATUS.INVALID_IDENTIFIER, 'Invalid project identifier')
        return { error: true, payload: errorObject }
      }

      const response = await dispatch(createCustomTemplateFromProject(projectID, params))
      const { error, payload } = response
      if (error) {
        return response
      }
      const template = fromJS(payload)

      // Create event
      const eventPropsForCustom = { type: EVENT_EXTRA.CREATE_TEMPLATE.TYPE.CUSTOM }
      dispatch(trackEvent(AMPLITUDE_ACTION_TYPES.CREATE_TEMPLATE, { eventPropsForCustom, ...eventProps }))

      return { payload: template }
    },
    [dispatch]
  )
}
