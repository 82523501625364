import _ from 'lodash'
import { Map } from 'immutable'
import { safeMapFnCreator } from './immutableHelpers'
import { resourceURIParser } from './URLHelper'

let _export = {}

const KEYS = {
  ID: 'id',
  ITEM_ROLE: 'item_role',
  DELETED: 'deleted',
  FOLLOWING: 'following',
  PROJECT_URI: 'project',
  PROJECT_ROLE: 'project_role',
  USER_URI: 'user',
  USERNAME: 'username',
  TIMESTAMP: 'timestamp',
}
_export.KEYS = KEYS

const PROJECT_ROLE = {
  ADMIN: 0,
  REMOVE: 1,
  ADD: 2,
  UPDATE: 3,
  READ: 4,
}
_export.PROJECT_ROLE = PROJECT_ROLE

_export.isProjectMembership = pMemb => {
  return pMemb.get(KEYS.PROJECT_ROLE)
}

_export.getId = pMemb => pMemb.get(KEYS.ID)
_export.getProjectURI = pMemb => pMemb.get(KEYS.PROJECT_URI)
_export.getProjectRole = pMemb => pMemb.get(KEYS.PROJECT_ROLE)
_export.getUserURI = pMemb => pMemb.get(KEYS.USER_URI)
_export.getUsername = pMemb => pMemb.get(KEYS.USERNAME)
_export.isDeleted = pMemb => pMemb.get(KEYS.DELETED)
_export.toPseudoUser = pMemb => {
  const username = _export.getUsername(pMemb)
  const uri = _export.getUserURI(pMemb)
  const id = resourceURIParser(uri)?.id
  return Map({
    email: username,
    username,
    resource_uri: uri,
    id,
  })
}

_export.setProjectMembershipRole = (pMemb, role) => {
  return pMemb.set(KEYS.PROJECT_ROLE, role)
}

_export.isProjectAdmin = pMemb =>
  pMemb && !_export.isDeleted(pMemb) && _export.getProjectRole(pMemb) === PROJECT_ROLE.ADMIN

_export = _.mapValues(_export, f => (_.isFunction(f) ? safeMapFnCreator(f) : f))

export default _export
