import { Option } from '../types'

export const timeout = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

interface FilenameData {
  name: string
  extension: string
}

export const getFilenameData = (filename: string): Option<FilenameData> => {
  const parts = filename.split('.')
  if (parts.length >= 2) {
    const extension = parts.pop()!
    const name = parts.join('.')
    return { name, extension }
  }
}
