import { ACTIONS } from '../actions/onboardingActions'
import {
  currentStepSelector,
  isCancelledSelector,
  isFinishedSelector,
  stepObjectByStepSelector,
} from '../selectors/onboardingSelectors'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent as trackEvent } from '../common/src/eventTracking/amplitudeEvents'
import { onboardingSubject } from '../reactions'
import { onboardingStepHelper } from '../helpers'

export const onboardingMiddleware = store => next => action => {
  const { type } = action
  if (type === ACTIONS.SEND_STEP_EVENT) {
    // Before action
    const state = store.getState()
    const currentStep = currentStepSelector(state)
    const currentStepObject = stepObjectByStepSelector(state, currentStep)
    const currentStepKey = onboardingStepHelper.getKey(currentStepObject)
    const isFinished = isFinishedSelector(state)
    const result = next(action)

    // After action
    const nextState = store.getState()
    const nextStep = currentStepSelector(nextState)
    const nextIsFinished = isFinishedSelector(nextState)

    // Condition when it's finished
    const onFinish = !isFinished && nextIsFinished

    // Track event when a step is completed
    if (currentStep < nextStep || onFinish) {
      store.dispatch(
        trackEvent(AMPLITUDE_ACTION_TYPES.WEB_APP_ONBOARDING_COMPLETED_STEP, {
          step: currentStep,
          key: currentStepKey,
        })
      )
    }

    if (onFinish) {
      // Clear coachmarks
      onboardingSubject.next()

      // Track event when onboarding is finished
      store.dispatch(trackEvent(AMPLITUDE_ACTION_TYPES.WEB_APP_ONBOARDING_FINISHED))
    }

    return result
  } else if (type === ACTIONS.SET_IS_CANCELLED) {
    // Before action
    const state = store.getState()
    const isCancelled = isCancelledSelector(state)
    const result = next(action)

    // After action
    const nextState = store.getState()
    const nextIsCancelled = isCancelledSelector(nextState)

    const onCancelled = !isCancelled && nextIsCancelled
    if (onCancelled) {
      // Clear coachmarks
      onboardingSubject.next()
    }

    return result
  }
  return next(action)
}
