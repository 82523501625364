import { useCallback } from 'react'
import { useStore } from 'react-redux'
import { app as microsoftTeams, monetization } from '@microsoft/teams-js'
import { getMembersInCurrentConversation, parseTeamsContext } from '../common/src/actions/teamsActions'
import { getEmailsJoinByComma } from '../common/src/helpers/usersUtils'
import { objToQueryParamsString } from '../common/src/helpers/functionalUtils'
import { simulateLinkClick } from '../common/src/utils'
import { teamsSelectors } from '../common/src/selectors'
import { getSentry } from '../getSentry'
import { SERVER_URLS } from '../common/src/constants'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../common/src/eventTracking/amplitudeEvents'
import { openExternalLink } from '@/utils/externalLinkHandler'

const Sentry = getSentry()

const WELL_KNOWN_PARENT_DOMAINS = [
  'https://loop.cloud-dev.microsoft',
  'https://loop.cloud.microsoft',
  'https://loop.microsoft.com',
]

export const initializeTeams = async dispatch => {
  try {
    await microsoftTeams.initialize(WELL_KNOWN_PARENT_DOMAINS)
    const context = await microsoftTeams.getContext()
    dispatch(parseTeamsContext(context))
    dispatch(getMembersInCurrentConversation())
  } catch (err) {}
}

export const openPurchaseExperienceAction = mode => async (dispatch, getState) => {
  dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.TRIAL_UPGRADE_PRESSED, { mode }))

  const state = getState()
  const insideTeams = teamsSelectors.getHostName(state) === teamsSelectors.TEAMS_HOST_NAME.TEAMS

  if (insideTeams) {
    try {
      const response = await monetization.openPurchaseExperience()
      console.log('after callback: ', response)
      window.location.reload()
    } catch (err) {
      Sentry.captureException(err)
      Sentry.withScope(scope => {
        scope.setExtra('err', err.message?.toString?.())
        Sentry.captureMessage('open purchase experience failed')
      })
      openExternalLink(SERVER_URLS.CHECKOUT)
    }
  } else {
    openExternalLink(SERVER_URLS.CHECKOUT)
  }
}

export const useDeepLinkExecutor = () => {
  const store = useStore()
  return useCallback(
    link => {
      const state = store.getState()
      const insideTeams = teamsSelectors.isInsideTeams(state)
      const isOutlook = teamsSelectors.isOutlook(state)

      if (isOutlook) {
        //Special case due this issue https://sync.appfluence.com/native/api/v1/item/2143468104/
        simulateLinkClick(link, true)
        return
      }

      if (insideTeams) {
        microsoftTeams.openLink(link)
      } else {
        simulateLinkClick(link, true)
      }
    },
    [store]
  )
}

/**
 * Get deeplink to open chat with several graph users
 * @param users Array of graph users
 * @param topicName
 * @param message
 * @returns {string} DeepLink
 */
export const getDeepLinkToOpenChatWithUsers = ({ users, topicName, message }) => {
  if (!users || users.length === 0) {
    return ''
  }
  const usersParam = getEmailsJoinByComma(users)
  return getDeepLinkToOpenChat({
    emails: usersParam,
    topicName,
    message,
  })
}

/**
 * Get deeplink to open chat with several emails
 * @param emails emails separated by comma
 * @param topicName
 * @param message
 * @returns {string} DeepLink
 */
export const getDeepLinkToOpenChat = ({ emails, topicName, message }) => {
  const query = objToQueryParamsString({
    users: emails,
    topicName,
    message,
  })
  return `https://teams.microsoft.com/l/chat/0/0?${query.toString()}`
}
