import React, { memo, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { userHelper } from '../../common/src/helpers'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { useOrganizedUsersOptions } from '../../hooks/useUsersOption'
import { UserPanel } from '../users/panels/UserPanel'
import { useTranslation } from 'react-i18next'

export const OwnerPanel = memo(({ user, project, onChange, onDismiss, ...rest }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const minusUsers = useMemo(() => {
    return [user]
  }, [user])
  const options = useOrganizedUsersOptions({
    project,
    minusUsers,
  })

  const onSelectUserClosing = useCallback(
    user => {
      const email = user ? userHelper.getEmail(user) : ''
      dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.DELEGATE_ITEM))
      onChange(email)
      onDismiss()
    },
    [onChange, onDismiss, dispatch]
  )

  return (
    <UserPanel
      headerText={t('item_detail.owner_panel_text_field_placeholder')}
      onDismiss={onDismiss}
      user={user}
      options={options}
      onSelectUser={onSelectUserClosing}
      selectedUserHeader={t('users.assigned_to')}
      textFieldPlaceholder={t('item_detail.owner_panel_text_field_placeholder')}
      invitationIconProps={{ title: t('item_detail.owner_panel_text_field_placeholder') }}
      clearText={t('users.clear_owner')}
      project={project}
      {...rest}
    />
  )
})
