import type { ItemFromCSV } from '../../types/item'
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from '@fluentui/react-components'
import { PrintableComponent } from '../PrintableComponent'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { PrintItemsContext } from '../../typedContexts'
import { useTranslation } from 'react-i18next'

type PrintItemsModalProps = {
  items: ItemFromCSV[]
  open: boolean
  onClose: () => void
}
const PrintItemsModal = ({ items, open, onClose }: PrintItemsModalProps) => {
  const { t } = useTranslation()
  const printRef = useRef(null)
  const primaryButtonRef = useRef<HTMLButtonElement>(null)
  const handlePrint = useReactToPrint({ content: () => printRef.current })

  useEffect(() => {
    if (open) {
      primaryButtonRef.current?.focus()
    }
  }, [open])

  return (
    <>
      <Dialog
        open={open}
        onOpenChange={(ev, data) => {
          if (!data.open) onClose()
        }}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{t('item.multiple_selection.print_modal_title')}</DialogTitle>
            <DialogContent>
              <PrintableComponent pageName="Selected items" ref={printRef}>
                <ul className="flex flex-col gap-2">
                  {items.map((item, index) => (
                    <li className="list-disc" key={index}>
                      <div className="font-bold">
                        {item.name} ({item.completionPercentage}%)
                      </div>
                      <div className="text-xs">
                        <div>Owner: {item.owner}</div>
                        {!!item.startDate && <div>Started on {item.startDate}</div>}
                        {!!item.dueDate && <div>Due on {item.dueDate}</div>}
                        {!!item.descriptionText && (
                          <div>
                            <div>Notes:</div>
                            <div className="whitespace-pre-wrap">
                              {item.descriptionText.trim().slice(0, 100)}
                              {item.descriptionText.trim().length > 100 && '…'}
                            </div>
                          </div>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </PrintableComponent>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button>{t('general.close')}</Button>
              </DialogTrigger>
              <Button appearance="primary" onClick={handlePrint} ref={primaryButtonRef}>
                {t('general.print')}
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  )
}

export const PrintItemsModalSingleton = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false)
  const [items, setItems] = useState<ItemFromCSV[]>([])
  const printItems = useCallback((itemList: ItemFromCSV[]) => {
    setItems(itemList)
    setOpen(true)
  }, [])
  const contextValue = useMemo(() => ({ printItems }), [printItems])
  return (
    <PrintItemsContext.Provider value={contextValue}>
      {children}
      <PrintItemsModal open={open} items={items} onClose={() => setOpen(false)} />
    </PrintItemsContext.Provider>
  )
}
