import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from '@fluentui/react-components'
import { useEffect, useRef } from 'react'

export const AlertModal = ({
  open,
  title,
  subText,
  primaryActionText: givenPrimaryActionText,
  defaultActionText: givenDefaultActionText,
  onPrimaryActionClick,
  onDefaultActionClick,
  onYesAlwaysClick,
  showDefaultActionButton = true,
  onDismiss,
  onDismissed,
  ...rest
}) => {
  const primaryButtonRef = useRef(null)
  const { t } = useTranslation()
  const primaryActionText = givenPrimaryActionText ?? t('modal.accept')
  const defaultActionText = givenDefaultActionText ?? t('modal.cancel')

  useEffect(() => {
    if (open) {
      primaryButtonRef.current?.focus()
    }
  }, [open])

  const onPrimaryClick = () => {
    onPrimaryActionClick?.()
  }

  const onDefaultClick = () => {
    onDefaultActionClick?.()
  }

  return (
    <Dialog
      open={open}
      onOpenChange={(ev, data) => {
        if (!data.open) onDismiss?.()
      }}
      modalType="alert"
      {...rest}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{subText}</DialogContent>
          <DialogActions className="!flex-row justify-between sm:justify-end">
            {showDefaultActionButton && (onPrimaryActionClick || onDefaultActionClick) && (
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary" onClick={onDefaultClick}>
                  {defaultActionText}
                </Button>
              </DialogTrigger>
            )}
            {onYesAlwaysClick && (
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary" onClick={onYesAlwaysClick} className="!min-w-28">
                  {t('modal.yes_always')}
                </Button>
              </DialogTrigger>
            )}
            <DialogTrigger disableButtonEnhancement>
              <Button
                ref={primaryButtonRef}
                autoFocus
                appearance="primary"
                onClick={onPrimaryClick}
                data-testid="AlertModal_PrimaryButton"
              >
                {primaryActionText}
              </Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
