import React from 'react'
import styled from 'styled-components'
import { FlexRow } from './FlexContainer'

const Container = styled(FlexRow)`
  flex: 1;
  min-height: 0; /* important in order to shrink! */
`

const Content = styled.div`
  flex: 1;
  min-width: 1px;
  height: 100%;
`

const Panel = styled.div`
  height: 100%;
`

export const LeftPanelLayout = ({ panel, main }) => {
  return (
    <Container>
      <Panel>{panel}</Panel>
      <Content>{main}</Content>
    </Container>
  )
}
