import { createAction } from 'redux-api-middleware'
import _ from 'lodash'
import {
  appendQueryParamsToURL,
  extendPMHeaders,
  getCredentialsConfig,
  withDefaultParams,
} from '../helpers/requestHelper'
import { PM_API_RESOURCES, PM_API_URLS } from '../constants'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'

const meetingsDefaultParams = {
  limit: 50,
}

export const getMeetings = withDefaultParams(params => {
  return createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.MEETINGS, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.MEETINGS, { params }),
    headers: extendPMHeaders(),
  })
}, meetingsDefaultParams)

export const getMeetingsInHours = withDefaultParams((params, hours = 24) => {
  params = { ...params, hours }
  return createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.MEETINGS_UPCOMING, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.MEETINGS, { params }),
    headers: extendPMHeaders(),
  })
}, meetingsDefaultParams)

export const getMeetingsInInterval = withDefaultParams((params, minDate, maxDate) => {
  if (!minDate || !maxDate) {
    const type = 'WRONG_MEETING_LOAD'
    console.warn(type)
    return Promise.reject({ type })
  }

  const min_date = minDate.toISOString()
  const max_date = maxDate.toISOString()
  params = { ...params, min_date, max_date }
  return createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.MEETINGS_LOAD, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.MEETINGS, { params }),
    headers: extendPMHeaders(),
  })
}, meetingsDefaultParams)

export const MEETING_SOURCES = {
  CUSTOM: 0,
  MSFT: 1,
  GOOGLE: 2,
}

export const getMeeting = (meetingID, params = {}) => {
  const url = _.template(PM_API_URLS.MEETING)({ id: meetingID })
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.MEETING, { meetingID }),
    headers: extendPMHeaders(),
  })
}

export const getNearestMeeting = (meetingID, params = {}) => {
  const url = _.template(PM_API_URLS.MEETING_NEAREST)({ id: meetingID })
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.NEAREST_MEETING, { meetingID }),
    headers: extendPMHeaders(),
  })
}

export const reloadNotesMeeting = (meetingID, params = {}) => {
  const url = _.template(PM_API_URLS.MEETING_RELOAD_NOTES)({ id: meetingID })
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.MEETING, { meetingID }),
    headers: extendPMHeaders(),
  })
}

export const patchMeeting = (meetingID, meetingPatch) => {
  if (!meetingID) {
    const type = 'WRONG_MEETING_PATCH'
    console.warn(type)
    return Promise.reject({ type })
  }
  const url = _.template(PM_API_URLS.MEETING)({ id: meetingID })
  const body = JSON.stringify(meetingPatch.toJS())
  return createAction({
    endpoint: appendQueryParamsToURL(url),
    credentials: getCredentialsConfig(),
    method: 'PATCH',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.MEETING, { meetingID }),
    headers: extendPMHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
    }),
    body,
  })
}

const meetingItemsDefaultParams = {
  limit: 50,
  order_by: '-lastModifiedTimestamp',
}

export const getMeetingItems = (meetingID, params = meetingItemsDefaultParams) => {
  const url = _.template(PM_API_URLS.MEETING_ITEMS)({ id: meetingID })
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.MEETING_ITEMS, { meetingID, params }),
    headers: extendPMHeaders(),
  })
}
