import _ from 'lodash'
import { APIS } from '../constants'

export const generateActionTypeString = (api, resource, event) => api + '/' + resource + '/' + event
const _generateActionTypeObj = (api, resource, event) => ({ api, resource, event })

const _generateActionTypeWithMeta = (api, resource, event, meta) => ({
  type: generateActionTypeString(api, resource, event),
  meta,
})

/**
 * Event types as obj, to use as CONSTANT on comparisons
 */
export const API_EVENT = {
  START: 'START',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
}

export const actionTypeString = {
  start: _.partial(generateActionTypeString, _, _, API_EVENT.START),
  success: _.partial(generateActionTypeString, _, _, API_EVENT.SUCCESS),
  error: _.partial(generateActionTypeString, _, _, API_EVENT.ERROR),
}

const _eventTypes = [API_EVENT.START, API_EVENT.SUCCESS, API_EVENT.ERROR]

/**
 * Generates all the event types for a given resource in an api. -> [pm/me/start, pm/me/success, pm/me/error]
 */
export const generateActionTypesForRequest = (api, resource, meta) =>
  _.map(_eventTypes, _.partial(_generateActionTypeWithMeta, api, resource, _, meta))

/**
 * Shorthand to generate API_EVENTS on PM_API
 */
export const generateActionTypesForPMAPI = _.partial(generateActionTypesForRequest, APIS.PM)

/**
 * Convert actionType to obj
 */
export const parseApiActionType = type => {
  if (!_.isString(type)) {
    throw new Error('Action should be a String')
  }
  const pieces = type.split('/')
  if (pieces.length !== 3) {
    return void 0
  }
  return _generateActionTypeObj(...pieces)
}

export const isPMApiDetailedAction = da => (da ? da.api === APIS.PM : void 0)

// Action to detect state rehydration. Defined here instead of importing it from 'redux-persist-immutable', since
// other packages may not use that dependence
export const PERSIST_REHYDRATE_ACTION_TYPE = 'persist/REHYDRATE'
