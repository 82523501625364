import styled from 'styled-components'
import { DefaultButton } from '@fluentui/react/lib/Button'
import { Label } from '../text/Text'
import { Modal } from '@fluentui/react/lib/Modal'
import { Spinner } from '../basic/Loading'
import { ErrorText } from '../text/ErrorText'
import { useTranslation } from 'react-i18next'
import { FlexColumn } from '../layout/FlexContainer'

const StyledModal = styled(Modal)``

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${p => p.theme.palette.always_primary};
  padding: 0 24px;
  min-height: 40px;
`

const Title = styled.span.attrs({
  variant: 'xLarge',
})`
  color: ${p => p.theme.palette.always_white};
`

const Content = styled.div`
  padding: 8px 24px;
`

const Body = styled.div`
  position: relative;
`

const Footer = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 8px;
`

const CloseButton = styled(DefaultButton)`
  margin-left: 8px;
`

const modalDefaultStyles = {
  main: {
    minWidth: '320px',
    boxSizing: 'border-box',
  },
}

export const SimpleModal = ({
  title,
  subtitle,
  buttons,
  closeButtonProps = {},
  loading = false,
  errorMessage,
  children,
  styles = {},
  ...rest
}) => {
  const { onDismiss } = rest
  const { t } = useTranslation()

  const defaultCloseButtonProps = {
    text: t('modal.close'),
  }

  const cButtonProps = { ...defaultCloseButtonProps, ...closeButtonProps }
  return (
    <StyledModal styles={styles.modal ? styles.modal : modalDefaultStyles} {...rest}>
      <Header style={styles.header}>
        <Title style={styles.title}>{title}</Title>
        {loading && <Spinner size="medium" />}
      </Header>
      <Content style={styles.content}>
        {subtitle && <Label style={styles.subtitle}>{subtitle}</Label>}
        <Body style={styles.body}>{children}</Body>
        <Footer style={styles.footer}>{errorMessage && <ErrorText>{errorMessage}</ErrorText>}</Footer>
      </Content>
      <div className="mb-4 mr-6 mt-2 flex items-center justify-end">
        {buttons}
        <CloseButton style={styles.close} onClick={onDismiss} {...cButtonProps} />
      </div>
    </StyledModal>
  )
}
