import React from 'react'
import { SimpleModal } from '../../../components/modal/SimpleModal'
import { graphMessageHelper } from '../../../common/src/helpers'

export const GraphEmailModal = ({ resource, ...rest }) => {
  const body = graphMessageHelper.getHTMLBody(resource)
  const sanitizedBody = graphMessageHelper.sanitizeDivLinks(body)

  const html = {
    __html: sanitizedBody,
  }
  return (
    <SimpleModal title="Email reader" {...rest}>
      <div dangerouslySetInnerHTML={html} />
    </SimpleModal>
  )
}
