import { createContext, useState, PropsWithChildren, FC, useContext } from 'react'
import { Option } from '@/types'

interface IParams {
  id?: string
  pid?: string
}

type ParamsContextData = {
  data: IParams
  setData: (data: IParams) => void
}

const ParamsContext = createContext<Option<ParamsContextData>>(undefined)

export const useCenterTitle = () => {
  const context = useContext(ParamsContext)
  if (!context) {
    throw new Error('useManagedParams must be used within a ParamsManager')
  }
  return context.data
}

export const useSetCenterTitle = () => {
  const context = useContext(ParamsContext)
  if (!context) {
    throw new Error('useManagedParams must be used within a ParamsManager')
  }
  return context.setData
}

export const CenterTitleManager: FC<PropsWithChildren> = ({ children }) => {
  const [data, setData] = useState<IParams>({})
  return <ParamsContext.Provider value={{ data, setData }}>{children}</ParamsContext.Provider>
}
