import React from 'react'
import styled from 'styled-components'
import { Icon } from '@fluentui/react/lib/Icon'
import { ICON_NAMES } from '../../common/src/msIcons'
import { cn } from '@appfluence/classnames'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
  font-size: 1em;

  cursor: pointer;
  color: ${p => p.theme.palette.white};
  font-weight: bold;

  &.isDisabled {
    color: ${p => p.theme.palette.neutralLight};
    font-weight: normal;
    cursor: default;
  }

  &.isDone {
    color: ${p => p.theme.palette.white};
    text-decoration: line-through;
  }
`

const Step = styled.div`
  align-self: flex-start;
  margin-right: 4px;
  width: 12px;
`

const SText = styled.div`
  flex: 1;
  align-self: flex-start;
  word-break: break-word;
  hyphens: auto;
  margin-right: 12px;
`

const ArrowIcon = styled(Icon).attrs({
  iconName: ICON_NAMES.ChevronRight,
})`
  float: right;
`

const FlagIcon = styled(Icon).attrs({
  iconName: ICON_NAMES.Flag,
})``

export const OnboardingCell = ({
  step = 0,
  totalSteps = 0,
  text = '',
  showArrow = false,
  isDone = false,
  isDisabled = false,
  className,
  onClick,
  ...rest
}) => {
  const disabled = isDisabled || isDone
  const containerClass = cn(className, { isDisabled: disabled }, { isDone })
  return (
    <Container className={containerClass} onClick={disabled ? undefined : onClick} {...rest}>
      {step < totalSteps - 1 ? (
        <Step>{`${step + 1}.`}</Step>
      ) : (
        <Step>
          <FlagIcon />
        </Step>
      )}
      <SText>{text}</SText>
      {showArrow && !isDisabled && <ArrowIcon />}
    </Container>
  )
}
