import React, { memo, useCallback, useMemo, useReducer } from 'react'
import _ from 'lodash'
import { mergeStyleSets } from '@fluentui/react/lib/Styling'
import { TextField } from '@fluentui/react/lib/TextField'
import { Label } from '../../components/text/Text'
import { Dropdown } from '@fluentui/react/lib/Dropdown'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import {
  FullScreenModalContainer,
  FullScreenModalSection,
  FullScreenModalSectionBody,
  FullScreenModalSectionHeader,
} from '../modal/FullScreenModalContainer'
import { Spinner } from '../../components/basic/Loading'
import { useCreateNewProjectCustom } from '../../hooks/projectHooks'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { QuadrantsThemesCollection } from '../../components/input/quadrant/QuadrantsThemesCollection'
import { quadrantsColorsTemplatesByKey, quadrantsColorsTemplatesKeys } from '../../templates/quadrantsColorsTemplates'
import { projectHelper } from '../../common/src/helpers'
import { quadrantColorStringFromObject } from '../../common/src/helpers/colorsHelpers'
import { localProjectTemplateKeys, localProjectTemplates } from '../../templates/localProjectTemplates'
import { UserGroupsDropdown } from '../../components/dropdown/UserGroupsDropdown'
import { useTranslation } from 'react-i18next'
import { Stack } from '@fluentui/react/lib/Stack'
import { UserGroupInfoIconCallout } from '../../components/info/UserGroupInfoIconCallout'

const displayName = 'BlankProjectModal'

const classNames = {
  body: `${displayName}-body`,
  name: `${displayName}-name`,
  notes: `${displayName}-notes`,
  group: `${displayName}-group`,
  template: `${displayName}-template`,
  templateNotes: `${displayName}-templateNotes`,
  colors: `${displayName}-colors`,
  footer: `${displayName}-footer`,
  create: `${displayName}-create`,
  error: `${displayName}-error`,
}

const useClassNames = () => {
  return useMemo(() => {
    return mergeStyleSets({
      body: [classNames.body, { maxWidth: '450px !important' }],
      name: [classNames.name, { marginTop: '12px' }],
      notes: [classNames.notes, { marginTop: '12px' }],
      group: [classNames.group, { marginTop: '12px' }],
      template: [classNames.template, { marginTop: '12px' }],
      colors: [classNames.colors, { marginTop: '12px' }],
      footer: [
        classNames.footer,
        {
          marginTop: '20px',
          height: '32px',
        },
      ],
      create: [
        classNames.create,
        {
          width: '100%',
        },
      ],
      error: [
        classNames.error,
        {
          marginTop: '8px',
          color: 'var(--color-negative)',
        },
      ],
    })
  }, [])
}

const identifiers = {
  nameTextField: `${displayName}-nameTextField`,
  notesTextField: `${displayName}-notesTextField`,
}

const ACTION_TYPE = {
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  SET_LOADING: 'SET_LOADING',
  SET_NAME: 'SET_NAME',
  SET_NOTES: 'SET_NOTES',
  SET_GROUP_ID: 'SET_GROUP_ID',
  SET_TEMPLATE_KEY: 'SET_TEMPLATE_KEY',
  SET_COLORS_KEY: 'SET_COLORS_KEY',
}

const initialState = {
  loading: false,
  errorMessage: false,
  name: '',
  notes: '',
  groupID: null,
  templateKey: localProjectTemplateKeys.basic,
  colorsKey: quadrantsColorsTemplatesKeys.classic,
  isValid: false,
}

export const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case ACTION_TYPE.SET_ERROR_MESSAGE:
      state.errorMessage = payload
      break
    case ACTION_TYPE.SET_LOADING:
      state.loading = payload
      state.errorMessage = ''
      break
    case ACTION_TYPE.SET_NAME: {
      const name = action.payload
      state.name = name
      state.isValid = !_.isEmpty(name)
      break
    }
    case ACTION_TYPE.SET_NOTES:
      state.notes = action.payload
      break
    case ACTION_TYPE.SET_GROUP_ID:
      state.groupID = action.payload
      break
    case ACTION_TYPE.SET_TEMPLATE_KEY:
      state.templateKey = action.payload
      break
    case ACTION_TYPE.SET_COLORS_KEY:
      state.colorsKey = action.payload
      break
    default:
      break
  }
  return { ...state }
}

const onRenderUserGroupLabel = props => {
  return (
    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }}>
      <Label>{props.label}</Label>
      <UserGroupInfoIconCallout showReloadWhenClickLink />
    </Stack>
  )
}

export const BlankProjectModal = memo(({ history, location, ...rest }) => {
  const [state, localDispatch] = useReducer(reducer, { ...initialState })
  const { loading, name, notes, groupID, templateKey, colorsKey, errorMessage, isValid } = state
  const template = localProjectTemplates[templateKey]
  const createNewProject = useCreateNewProjectCustom()
  const classNames = useClassNames()
  const { t } = useTranslation()

  const onChangeName = useCallback((ev, value) => {
    localDispatch({ type: ACTION_TYPE.SET_NAME, payload: value })
  }, [])

  const onChangeNotes = useCallback((ev, value) => {
    localDispatch({ type: ACTION_TYPE.SET_NOTES, payload: value })
  }, [])

  const onChangeUserGroup = useCallback((event, option) => {
    const { groupID } = option
    localDispatch({ type: ACTION_TYPE.SET_GROUP_ID, payload: groupID })
  }, [])

  const onChangeTheme = useCallback(theme => {
    localDispatch({ type: ACTION_TYPE.SET_COLORS_KEY, payload: theme.key })
  }, [])

  const onCreate = useCallback(
    projectID => {
      const path = getRelativeURLKeepingQuerySearch.matrixForProjectId(projectID)
      history.push(path)
    },
    [history]
  )

  const templateOptions = useMemo(() => {
    return _.chain(localProjectTemplates)
      .values()
      .map(t => ({ key: t.key, text: t.name }))
      .value()
  }, [])

  const onChangeTemplate = useCallback((event, item) => {
    localDispatch({ type: ACTION_TYPE.SET_TEMPLATE_KEY, payload: item.key })
  }, [])

  const create = useCallback(async () => {
    localDispatch({ type: ACTION_TYPE.SET_LOADING, payload: true })

    const colors = quadrantsColorsTemplatesByKey[colorsKey].quadrantColors
    const quadrantsColorsByKey = _.reduce(
      projectHelper.QUADRANT_COLORS,
      (result, colorKey, i) => {
        result[colorKey] = quadrantColorStringFromObject(colors[i])
        return result
      },
      {}
    )
    const response = await createNewProject({
      name,
      notes,
      [projectHelper.KEYS.USER_GROUP_ID]: groupID,
      textFirstQuadrant: template.textFirstQuadrant,
      textSecondQuadrant: template.textSecondQuadrant,
      textThirdQuadrant: template.textThirdQuadrant,
      textFourthQuadrant: template.textFourthQuadrant,
      ...quadrantsColorsByKey,
      notify: false,
      delayToOpen: 150,
      onCreate,
    })
    localDispatch({ type: ACTION_TYPE.SET_LOADING, payload: false })

    const { error } = response
    if (error) {
      localDispatch({ type: ACTION_TYPE.SET_ERROR_MESSAGE, payload: t('project.created_unsuccessfully') })
    }
  }, [localDispatch, createNewProject, name, notes, groupID, template, colorsKey, onCreate, t])

  return (
    <FullScreenModalContainer bodyClassName={classNames.body} {...rest}>
      <FullScreenModalSection>
        <FullScreenModalSectionHeader>
          <div>Add project details</div>
        </FullScreenModalSectionHeader>
        <FullScreenModalSectionBody>
          <TextField
            id={identifiers.nameTextField}
            label={t('project.name_title')}
            value={name}
            placeholder={t('project.name_placeholder')}
            onChange={onChangeName}
            disabled={loading}
            className={classNames.name}
          />
          <TextField
            id={identifiers.notesTextField}
            multiline
            resizable={false}
            label={t('project.notes_title')}
            value={notes}
            placeholder={t('project.notes_placeholder')}
            onChange={onChangeNotes}
            disabled={loading}
            className={classNames.notes}
          />
          <UserGroupsDropdown
            label={t('project.group')}
            onRenderLabel={onRenderUserGroupLabel}
            selectedID={groupID}
            onChange={onChangeUserGroup}
            className={classNames.group}
          />
          <Dropdown
            label={t('project.template')}
            selectedKey={templateKey}
            options={templateOptions}
            onChange={onChangeTemplate}
            className={classNames.template}
          />
          {template.notes && <div className="mt-2 text-justify text-pm-black">{template.notes}</div>}
          <Label className={classNames.colors}>{t('project.colors')}</Label>
          <QuadrantsThemesCollection selectedKey={colorsKey} onSelect={onChangeTheme} />
          <div className={classNames.footer}>
            {loading ? (
              <Spinner size="medium" />
            ) : (
              <PrimaryButton onClick={create} className={classNames.create} disabled={!isValid}>
                {t('project.create_project')}
              </PrimaryButton>
            )}
          </div>
          {errorMessage && <div className={classNames.error}>{errorMessage}</div>}
        </FullScreenModalSectionBody>
      </FullScreenModalSection>
    </FullScreenModalContainer>
  )
})
