import React, { useContext, useMemo, useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Panel } from '@fluentui/react/lib/Panel'
import { Nav } from '@fluentui/react/lib/Nav'
import { generateTrackingHandler, navigationPaths, toWithParams } from '../../utils/NavPanelCommon'
import { ICON_NAMES } from '../../common/src/msIcons'
import { CUSTOM_ICONS } from '../../icons/customIcons'
import { useRouter } from '../../hooks/useRouterHook'
import { useAttentionNeeded } from '../../hooks/useAttentionNeeded'
import { InvitationsModalContext } from '../../contexts'
import { getSignOut } from '../../common/src/actions/authAPI'
import { VERSION } from '../../common/src/environment'
import { isMobile } from '../../common/src/helpers/platformHelper'
import { decreaseViewportScale, increaseViewportScale } from '../../actions/uiActions'
import { VIEWPORT } from '../../reducers/uiKeys'
import { useAccountOptions, MODE, useLanguageOption } from '../../config/account'
import { useSharingRequestsForMe } from '../../common/src/hooks/sharingRequestsHooks'
import { getViewportScale } from '../../selectors/uiSelectors'
import { isEmbeddedOnTeams } from '../../helpers/queryParamsHelper'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useShowCreateProjectView } from '../../hooks/projectHooks'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { AppearanceSettingsDialog } from '@/components/modal/AppearanceSettingsDialog'

const extraActions = {
  invitations: 'invitations',
  signOut: 'signOut',
  account: 'account',
  version: 'version',
  options: 'options',
  createProject: 'createProject',
  preferredMode: 'preferredMode',
}

const sortedPaths = [
  extraActions.account,
  extraActions.createProject,
  navigationPaths.alertsHome,
  extraActions.invitations,
  navigationPaths.inbox,
  navigationPaths.projects,
  navigationPaths.search,
  navigationPaths.globalCalendar,
  navigationPaths.globalGantt,
  navigationPaths.globalFeed,
  navigationPaths.one_on_one,
  navigationPaths.globalReports,
  navigationPaths.workload,
  extraActions.preferredMode,
  extraActions.options,
  extraActions.signOut,
  extraActions.version,
]

const hydrateLinks = links => (link, index) => {
  return { ...links[link], key: index }
}

const useViewportScaleConditions = () => {
  const viewportScale = useSelector(getViewportScale)
  return {
    canIncrease: viewportScale <= VIEWPORT.MAX,
    canDecrease: viewportScale >= VIEWPORT.MIN,
  }
}

export const NavPanel = ({ ...rest }) => {
  const narrow = useNarrowWidth()
  const theme = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { onDismiss } = rest
  const viewportConditions = useViewportScaleConditions()
  const { history, location } = useRouter()
  const invitationsModalContext = useContext(InvitationsModalContext)
  const { countString } = useAttentionNeeded()
  const invitationsSize = useSharingRequestsForMe().size
  const trackingHandler = generateTrackingHandler(dispatch)
  const beautifulCount = countString && ` (${countString})`
  const showCreateProjectView = useShowCreateProjectView()
  const accountOptions = useAccountOptions(MODE.LINK)
  const languageOption = useLanguageOption(MODE.LINK)
  const [isAppearanceSettingsDialogOpen, setIsAppearanceSettingsDialogOpen] = useState(false)

  const links = useMemo(() => {
    const to = toWithParams()
    return {
      [navigationPaths.alertsHome]: {
        iconProps: {
          iconName: ICON_NAMES.Home,
        },
        name: t('left_panel.home_button_name') + `${beautifulCount}`,
        onClick: () => {
          //trackingHandler.home()
          //tracking inside view
          history.push(to.alertsHome)
          onDismiss()
        },
      },
      [extraActions.createProject]: {
        iconProps: {
          iconName: ICON_NAMES.CircleAddition,
        },
        name: t('left_panel.create_project_button_name'),
        onClick: () => {
          showCreateProjectView()
          onDismiss()
        },
      },
      [extraActions.invitations]: {
        iconProps: {
          iconName: ICON_NAMES.MailForwardMirrored,
        },
        name: t('left_panel.pending_invitation_button_name') + `(${invitationsSize})`,
        onClick: () => {
          invitationsModalContext.setIsOpen(true)
          onDismiss()
        },
      },
      [navigationPaths.inbox]: {
        iconProps: {
          iconName: ICON_NAMES.Inbox,
        },
        name: t('left_panel.inbox_button_name'),
        onClick: () => {
          trackingHandler.inbox()
          history.push(to.inbox)
          onDismiss()
        },
      },
      [navigationPaths.projects]: {
        iconProps: {
          iconName: CUSTOM_ICONS.QUADRANTS,
          styles: {
            root: {
              width: 16,
              fill: theme.palette.themeSecondary,
            },
          },
        },
        name: t('left_panel.projects_button_name'),
        onClick: () => {
          history.push(to.projects)
          onDismiss()
        },
      },
      [navigationPaths.search]: {
        iconProps: {
          iconName: ICON_NAMES.Search,
        },
        name: t('left_panel.search_button_name'),
        onClick: () => {
          trackingHandler.search()
          history.push(to.search)
          onDismiss()
        },
      },
      [navigationPaths.globalCalendar]: {
        iconProps: {
          iconName: ICON_NAMES.Calendar,
        },
        name: t('left_panel.calendar_button_name'),
        onClick: () => {
          trackingHandler.calendar()
          history.push(to.globalCalendar)
          onDismiss()
        },
      },
      [navigationPaths.globalGantt]: {
        iconProps: {
          iconName: ICON_NAMES.BookmarksMirrored,
        },
        name: t('left_panel.gantt_button_name'),
        onClick: () => {
          history.push(to.globalGantt)
          onDismiss()
        },
      },
      [navigationPaths.globalFeed]: {
        iconProps: {
          iconName: ICON_NAMES.ActivityFeed,
        },
        name: t('left_panel.feed_button_name'),
        onClick: () => {
          trackingHandler.feed()
          history.push(to.globalFeed)
          onDismiss()
        },
      },
      [navigationPaths.one_on_one]: {
        iconProps: {
          iconName: ICON_NAMES.People,
        },
        name: t('left_panel.one_on_one_button_name'),
        onClick: () => {
          trackingHandler.one_on_one()
          history.push(to.one_on_one)
          onDismiss()
        },
      },
      [navigationPaths.globalReports]: {
        iconProps: {
          iconName: ICON_NAMES.ReportDocument,
        },
        name: t('left_panel.reports_general_button_name'),
        onClick: () => {
          trackingHandler.reports()
          history.push(to.globalReports)
          onDismiss()
        },
      },
      [navigationPaths.workload]: {
        iconProps: {
          iconName: 'DocumentManagement',
        },
        name: t('left_panel.workload_button_name'),
        onClick: () => {
          trackingHandler.workload()
          history.push(to.workload)
          onDismiss()
        },
      },
      [extraActions.account]: {
        name: t('left_panel.account.title'),
        links: [...accountOptions, languageOption],
      },
      [extraActions.preferredMode]: {
        iconProps: {
          iconName: 'CSS',
        },
        name: t('left_panel.account.appearance_settings'),
        onClick: () => setIsAppearanceSettingsDialogOpen(true),
      },
      [extraActions.options]: {
        name: t('left_panel.account.font'),
        links: [
          {
            key: 'increase_font_size',
            name: t('left_panel.account.increase_font_size'),
            iconProps: {
              iconName: ICON_NAMES.FontIncrease,
            },
            disabled: !viewportConditions.canIncrease,
            onClick: () => {
              if (viewportConditions.canIncrease) {
                return dispatch(increaseViewportScale())
              }
            },
          },
          {
            key: 'decrease_font_size',
            name: t('left_panel.account.decrease_font_size'),
            iconProps: {
              iconName: ICON_NAMES.FontDecrease,
            },
            disabled: !viewportConditions.canDecrease,
            onClick: () => {
              if (viewportConditions.canDecrease) {
                return dispatch(decreaseViewportScale())
              }
            },
          },
        ],
      },
      [extraActions.signOut]: {
        iconProps: {
          iconName: ICON_NAMES.SignOut,
        },
        name: t('left_panel.account.sign_out'),
        onClick: () => {
          dispatch(getSignOut())
          onDismiss()
        },
      },
      [extraActions.version]: {
        name: `v${VERSION}`,
        disabled: true,
      },
    }
  }, [
    t,
    beautifulCount,
    invitationsSize,
    theme,
    accountOptions,
    languageOption,
    history,
    onDismiss,
    showCreateProjectView,
    invitationsModalContext,
    trackingHandler,
    viewportConditions.canIncrease,
    viewportConditions.canDecrease,
    dispatch,
  ])

  const prepareConditionalSections = () => {
    const blacklist = []
    if (invitationsSize === 0) {
      blacklist.push(extraActions.invitations)
    }
    if (!isMobile() || isEmbeddedOnTeams()) {
      blacklist.push(extraActions.options)
    }
    if (narrow) {
      blacklist.push(navigationPaths.globalReports)
      blacklist.push(navigationPaths.workload)
    }
    if (blacklist.length) {
      return sortedPaths.filter(p => !_.find(blacklist, bp => bp === p))
    }
    return sortedPaths
  }

  const paths = prepareConditionalSections()

  const groups = [
    {
      links: _.map(paths, hydrateLinks(links)),
    },
  ]

  const currentPathname = location.pathname
  const selectedKey = _.findIndex(paths, path => _.includes(currentPathname, path))
  return (
    <Panel {...rest}>
      <Nav groups={groups} selectedKey={selectedKey} />
      <AppearanceSettingsDialog
        open={isAppearanceSettingsDialogOpen}
        onClose={() => setIsAppearanceSettingsDialogOpen(false)}
      />
    </Panel>
  )
}
