import _ from 'lodash'
import { itemHelper } from '../common/src/helpers'
import { ROUTE_ID } from '../routes/routeIdList'
import { BASENAME } from '../common/src/environment'
import { removeTrailingMatchingChar } from '../common/src/helpers/stringHelper'
import { MATRIX_MODE } from '../reducers/uiKeys'

const ABSOLUTE_BASE = removeTrailingMatchingChar(`https://${window.location.host}${BASENAME}`, '/')
const prependBase = p => ABSOLUTE_BASE + p

export const getPathnameKeepingQuerySearch = (to, search = window.location.search) => ({
  pathname: to,
  search,
})

const optionalPath = p => (p ? `${p}/` : '')
const optionalEmailPath = _.flow([_.toLower, encodeURIComponent, optionalPath])

export const NEW_PROJECT_SECTIONS = {
  CUSTOM: 'custom_template',
  PUBLIC: 'public_template',
  AI: 'ai',
}

export const getRelativeURL = {
  index: () => `/`,
  singleProjectViewForID: pid => `/project/${pid}/`,
  singleItemViewForID: id => `/item/${id}/`,
  singleItemReadOnlyViewForID: id => `/read_only/item/${id}/`,
  projectViewInAppForID: pid => `/app/project/${pid}/`,
  itemViewInAppForID: id => `/app/item/${id}/`,
  graphResourceViewInAppForID: id => `/app/graph_resource/graph-${id}/`,
  oneProjectMatrixForProjectId: (pid, id) => `/matrix/${pid}/${optionalPath(id)}`,
  oneProjectListForProjectId: (pid, id) => `/list/${pid}/${optionalPath(id)}`,
  oneProjectKanbanForProjectId: (pid, id) => `/kanban/${pid}/${optionalPath(id)}`,
  oneProjectCalendarForProjectId: (pid, id) => `/calendar/${pid}/${optionalPath(id)}`,
  oneProjectReportsForProjectId: (pid, id) => `/reports/${pid}/${optionalPath(id)}`,
  oneProjectGanttForProjectId: (pid, id) => `/gantt/${pid}/${optionalPath(id)}`,
  oneProjectFeedForProjectId: (pid, id) => `/feed/${pid}/${optionalPath(id)}`,
  oneProjectReadonlyMatrixForProjectId: (pid, id) => `/read_only/matrix/${pid}/${optionalPath(id)}`,
  oneProjectReadonlyListForProjectId: (pid, id) => `/read_only/list/${pid}/${optionalPath(id)}`,
  oneProjectReadonlyKanbanForProjectId: (pid, id) => `/read_only/kanban/${pid}/${optionalPath(id)}`,
  oneProjectReadonlyCalendarForProjectId: (pid, id) => `/read_only/calendar/${pid}/${optionalPath(id)}`,
  oneProjectReadonlyGanttForProjectId: (pid, id) => `/read_only/gantt/${pid}/${optionalPath(id)}`,
  matrixForProjectId: (pid, id) => `/app/matrix/${pid}/${optionalPath(id)}`,
  restoreItemsForProjectId: pid => `/app/restore/${pid}/`,
  listForProjectId: (pid, id) => `/app/list/${pid}/${optionalPath(id)}`,
  kanbanForProjectId: (pid, id) => `/app/kanban/${pid}/${optionalPath(id)}`,
  printProject: pid => `/app/print_project/${pid}`,
  calendarForProjectId: (pid, id) => `/app/calendar/${pid}/${optionalPath(id)}`,
  reportsForProjectId: (pid, id) => `/app/reports/${pid}/${optionalPath(id)}`,
  ganttForProjectId: (pid, id) => `/app/gantt/${pid}/${optionalPath(id)}`,
  feedForProjectId: (pid, id) => `/app/feed/${pid}/${optionalPath(id)}`,
  globalFeedApp: (pid, id) => `/app/global_feed/${optionalPath(id)}`,
  globalReportsApp: (pid, id) => `/app/global_reports/${optionalPath(id)}`,
  globalCalendarApp: (pid, id) => `/app/global_calendar/${optionalPath(id)}`,
  globalGanttApp: (pid, id) => `/app/global_gantt/${optionalPath(id)}`,
  search: id => `/search/${optionalPath(id)}`,
  searchApp: id => `/app/search/${optionalPath(id)}`,
  inboxWithItem: id => `/app/inbox/${id}/`,
  projects: pid => `/app/projects/${pid ? pid + '/' : ''}`,
  oneOnOne: (email, id) => `/one_on_one/${optionalEmailPath(email)}${optionalPath(id)}`,
  oneOnOneApp: (email, id) => `/app/one_on_one/${optionalEmailPath(email)}${optionalPath(id)}`,
  OneOnOneHub: (email, id) => `/one_on_one_hub/${optionalEmailPath(email)}${optionalPath(id)}`,
  homeSection: (section, id) => `/app/home/${optionalPath(section)}${optionalPath(id)}`,
  homeRedirect: () => `/app/home/`,
  teamsCalendar: id => `/teams/calendar/${id}/`,
  teamsFeed: id => `/teams/feed/${id}/`,
  redirectToResource: (resource, id) => `/redirect/${resource}/${id}/`,
  redirectToFirstProject: () => `/redirect/firstProject/`,
  newProjectApp: (section = '') => `/app/new_project/${section}`,
  blankProjectApp: () => `/app/new_project/blank`,
  newProjectFromCustomTemplateApp: id => `/app/new_project/custom_template/${id}`,
  newProjectFromPublicTemplateApp: id => `/app/new_project/public_template/${id}`,
  newCustomProjectTemplateApp: id => `/app/new_custom_project_template/${id}`,
  meeting: (meetingId, id) => `/meeting/${meetingId}/${optionalPath(id)}`,
  paywall: () => `/paywall/`,
  version: () => `/version/`,
  loading: () => `/loading/`,
  loadingStatic: () => `/loading-static/`,
  inactivitySignOut: () => `/inactivity_signed_out/`,
  effortApp: id => `/app/planning/effort/${optionalPath(id)}`,
}

export const getRelativeURLKeepingQuerySearch = _.mapValues(getRelativeURL, f =>
  _.flow([f, getPathnameKeepingQuerySearch])
)
export const getAbsoluteURL = _.mapValues(getRelativeURL, f => _.flow([f, prependBase]))

const RELATIVE_URL_BY_MATRIX_MODE = {
  [MATRIX_MODE.MATRIX]: getRelativeURLKeepingQuerySearch.matrixForProjectId,
  [MATRIX_MODE.LIST]: getRelativeURLKeepingQuerySearch.listForProjectId,
}
export const getRelativePathToMatrixBasedOnMode = mode => RELATIVE_URL_BY_MATRIX_MODE[mode]

export const RELATIVE_URL_BY_ROUTE_ID = {
  [ROUTE_ID.SINGLE_PROJECT]: getRelativeURLKeepingQuerySearch.singleProjectViewForID,
  [ROUTE_ID.SINGLE_ITEM]: getRelativeURLKeepingQuerySearch.singleItemViewForID,
  [ROUTE_ID.SINGLE_ITEM_READ_ONLY]: getRelativeURLKeepingQuerySearch.singleItemReadOnlyViewForID,
  [ROUTE_ID.PROJECTS]: getRelativeURLKeepingQuerySearch.projects,
  [ROUTE_ID.MATRIX_APP]: getRelativeURLKeepingQuerySearch.matrixForProjectId,
  [ROUTE_ID.MATRIX_ONE]: getRelativeURLKeepingQuerySearch.oneProjectMatrixForProjectId,
  [ROUTE_ID.MATRIX_READ_ONLY]: getRelativeURLKeepingQuerySearch.oneProjectReadonlyMatrixForProjectId,
  [ROUTE_ID.LIST_APP]: getRelativeURLKeepingQuerySearch.listForProjectId,
  [ROUTE_ID.LIST_ONE]: getRelativeURLKeepingQuerySearch.oneProjectListForProjectId,
  [ROUTE_ID.LIST_READ_ONLY]: getRelativeURLKeepingQuerySearch.oneProjectReadonlyListForProjectId,
  [ROUTE_ID.KANBAN_APP]: getRelativeURLKeepingQuerySearch.kanbanForProjectId,
  [ROUTE_ID.KANBAN_ONE]: getRelativeURLKeepingQuerySearch.oneProjectKanbanForProjectId,
  [ROUTE_ID.KANBAN_READ_ONLY]: getRelativeURLKeepingQuerySearch.oneProjectReadonlyKanbanForProjectId,
  [ROUTE_ID.CALENDAR_APP]: getRelativeURLKeepingQuerySearch.calendarForProjectId,
  [ROUTE_ID.CALENDAR_ONE]: getRelativeURLKeepingQuerySearch.oneProjectCalendarForProjectId,
  [ROUTE_ID.CALENDAR_READ_ONLY]: getRelativeURLKeepingQuerySearch.oneProjectReadonlyCalendarForProjectId,
  [ROUTE_ID.REPORTS_APP]: getRelativeURLKeepingQuerySearch.reportsForProjectId,
  [ROUTE_ID.REPORTS_ONE]: getRelativeURLKeepingQuerySearch.oneProjectReportsForProjectId,
  [ROUTE_ID.GANTT_APP]: getRelativeURLKeepingQuerySearch.ganttForProjectId,
  [ROUTE_ID.GANTT_ONE]: getRelativeURLKeepingQuerySearch.oneProjectGanttForProjectId,
  [ROUTE_ID.GANTT_READ_ONLY]: getRelativeURLKeepingQuerySearch.oneProjectReadonlyGanttForProjectId,
  [ROUTE_ID.FEED_APP]: getRelativeURLKeepingQuerySearch.feedForProjectId,
  [ROUTE_ID.GLOBAL_FEED_APP]: getRelativeURLKeepingQuerySearch.globalFeedApp,
  [ROUTE_ID.GLOBAL_REPORTS_APP]: getRelativeURLKeepingQuerySearch.globalReportsApp,
  [ROUTE_ID.GLOBAL_CALENDAR_APP]: getRelativeURLKeepingQuerySearch.globalCalendarApp,
  [ROUTE_ID.GLOBAL_GANTT_APP]: getRelativeURLKeepingQuerySearch.globalGanttApp,
  [ROUTE_ID.FEED_ONE]: getRelativeURLKeepingQuerySearch.oneProjectFeedForProjectId,
  [ROUTE_ID.INBOX]: getRelativeURLKeepingQuerySearch.inboxWithItem,
  [ROUTE_ID.PROJECT_APP]: getRelativeURLKeepingQuerySearch.projectViewInAppForID,
  [ROUTE_ID.ITEM_APP]: getRelativeURLKeepingQuerySearch.itemViewInAppForID,
  [ROUTE_ID.GRAPH_RESOURCE_APP]: getRelativeURLKeepingQuerySearch.graphResourceViewInAppForID,
  [ROUTE_ID.ONE_ON_ONE]: getRelativeURLKeepingQuerySearch.oneOnOne,
  [ROUTE_ID.ONE_ON_ONE_APP]: getRelativeURLKeepingQuerySearch.oneOnOneApp,
  [ROUTE_ID.ONE_ON_ONE_HUB]: getRelativeURLKeepingQuerySearch.OneOnOneHub,
  [ROUTE_ID.HOME]: getRelativeURLKeepingQuerySearch.homeSection,
  [ROUTE_ID.NEW_PROJECT_APP]: getRelativeURLKeepingQuerySearch.newProjectApp,
  [ROUTE_ID.BLANK_PROJECT_APP]: getRelativeURLKeepingQuerySearch.blankProjectApp,
  [ROUTE_ID.NEW_PROJECT_FROM_CUSTOM_TEMPLATE_APP]: getRelativeURLKeepingQuerySearch.newProjectFromCustomTemplateApp,
  [ROUTE_ID.NEW_PROJECT_FROM_PUBLIC_TEMPLATE_APP]: getRelativeURLKeepingQuerySearch.newProjectFromPublicTemplateApp,
  [ROUTE_ID.NEW_CUSTOM_PROJECT_TEMPLATE_APP]: getRelativeURLKeepingQuerySearch.newCustomProjectTemplateApp,
  [ROUTE_ID.MEETING]: getRelativeURLKeepingQuerySearch.meeting,
  [ROUTE_ID.PAYWALL]: getRelativeURLKeepingQuerySearch.paywall,
  [ROUTE_ID.PRINT_PROJECT]: getRelativeURLKeepingQuerySearch.printProject,
  [ROUTE_ID.SEARCH]: getRelativeURLKeepingQuerySearch.search,
  [ROUTE_ID.SEARCH_APP]: getRelativeURLKeepingQuerySearch.searchApp,
  [ROUTE_ID.EFFORT_PLANNING]: getRelativeURLKeepingQuerySearch.effortApp,
  [ROUTE_ID.LOADING]: getRelativeURLKeepingQuerySearch.loading,
  [ROUTE_ID.LOADING_STATIC]: getRelativeURLKeepingQuerySearch.loadingStatic,
}

export const AppMatrixRoutes = new Set([
  ROUTE_ID.MATRIX_APP,
  ROUTE_ID.LIST_APP,
  ROUTE_ID.CALENDAR_APP,
  ROUTE_ID.GANTT_APP,
  ROUTE_ID.FEED_APP,
  ROUTE_ID.REPORTS_APP,
])

export const getPathToMatrixFromItem = item => {
  const projectIdd = itemHelper.getProjectIdd(item)
  const itemId = itemHelper.getId(item)
  const isInbox = !projectIdd
  return isInbox
    ? getRelativeURLKeepingQuerySearch.inboxWithItem(itemId)
    : getRelativeURLKeepingQuerySearch.matrixForProjectId(projectIdd)
}

export const isInApp = matchUrl => _.startsWith(matchUrl, '/app')
export const isReadOnly = location => _.includes(location.pathname, 'read_only')
export const isSingleItem = matchUrl => _.startsWith(matchUrl, '/item')
export const isAppSingleItem = matchUrl => _.startsWith(matchUrl, '/app/item')
