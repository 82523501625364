import _ from 'lodash'
import { APIS } from '../constants'
import { REQUEST_REDUCER_KEYS } from '../reducers/requestReducerKeys'
import { requestStateHelper } from '../helpers'

const requestStatus = (state, API, resource, id) => {
  if (id) {
    const resourcePath = resource + `_${id}`
    return state.getIn([REQUEST_REDUCER_KEYS.REDUCER, API, resourcePath])
  }
  return state.getIn([REQUEST_REDUCER_KEYS.REDUCER, API, resource])
}

export const requestIsInitialized = (state, API, resource, id) => {
  const status = requestStatus(state, API, resource, id)
  return requestStateHelper.isInitializedRequest(status)
}

export const requestIsPending = (state, API, resource, id) => {
  const status = requestStatus(state, API, resource, id)
  return requestStateHelper.isPendingRequest(status)
}

export const requestIsNeeded = (state, API, resource, id) => {
  const status = requestStatus(state, API, resource, id)
  return requestStateHelper.isNeededRequest(status)
}

export const requestIsError = (state, API, resource, id) => {
  const status = requestStatus(state, API, resource, id)
  return requestStateHelper.isErrorRequest(status)
}

export const requestStatusForPMResource = _.partial(requestStatus, _, APIS.PM)
export const isInitializedRequestForPMResource = _.partial(requestIsInitialized, _, APIS.PM)
export const isPendingRequestForPMResource = _.partial(requestIsPending, _, APIS.PM)
export const isNeededRequestForPMResource = _.partial(requestIsNeeded, _, APIS.PM)
export const isErrorRequestForPMResource = _.partial(requestIsError, _, APIS.PM)

export const getLastSyncTimestamp = state => {
  return state.getIn([REQUEST_REDUCER_KEYS.REDUCER, REQUEST_REDUCER_KEYS.SYNC_TIMESTAMP])
}

// TODO: it only uses in one place
export const nextQueryStringRequestForPMResource = (state, resource) => {
  const status = requestStatusForPMResource(state, resource)
  if (status) {
    const nextString = status.get('next')
    if (!nextString || !_.startsWith(nextString, '?')) {
      return void 0
    }
    return nextString
  }
  return void 0
}
