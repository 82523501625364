import { API_EVENT, generateActionTypeString } from '@/common/src/helpers/reducerHelper'
import { APIS, PM_API_RESOURCES } from '@/common/src/constants'
import { setUser } from '@/utils/ExpoCommunication'

const Handlers = {
  [generateActionTypeString(APIS.PM, PM_API_RESOURCES.SIGN_OUT, API_EVENT.ERROR)]: _action => {
    setUser(null)
  },
  [generateActionTypeString(APIS.PM, PM_API_RESOURCES.ME, API_EVENT.ERROR)]: action => {
    setUser(null)
  },
  [generateActionTypeString(APIS.PM, PM_API_RESOURCES.ME, API_EVENT.SUCCESS)]: action => {
    setUser({ id: action.payload.id, email: action.payload.email })
  },
}

export const reactNativeEventMiddleware = _store => next => action => {
  if (window.ReactNativeWebView) {
    Handlers[action.type]?.(action)
  }
  return next(action)
}
