import { FILTER_REDUCER_KEYS } from '../reducers/filtersKeys'
import projectHelper from './projectHelper'
import { PROJECTS_SORT_TYPE } from '../actions/filtersActions'
import * as stringHelper from './stringHelper'
import _ from 'lodash'
import { tagsPredicateGenerator, textPredicateGenerator } from './filtersHelper'

const userGroupPredicateGenerator = userGroup => {
  if (!userGroup) {
    return () => true
  }
  return _.partial(_.isEqual, userGroup)
}

export const createProjectFilter = filters => {
  const text = filters.get(FILTER_REDUCER_KEYS.TEXT)
  const userGroupID = filters.get(FILTER_REDUCER_KEYS.USER_GROUP)
  const tagNames = filters.get(FILTER_REDUCER_KEYS.TAGS)
  const tagsMode = filters.get(FILTER_REDUCER_KEYS.TAGS_MODE)

  const textPredicate = textPredicateGenerator(text)
  const userGroupPredicate = userGroupPredicateGenerator(userGroupID)
  const tagsPredicate = tagsPredicateGenerator(tagNames, tagsMode)
  return project => {
    return (
      textPredicate(projectHelper.getName(project)) &&
      userGroupPredicate(projectHelper.getUserGroupID(project)) &&
      tagsPredicate(projectHelper.getTags(project))
    )
  }
}

export const PROJECTS_SORT_PREDICATES = {
  [PROJECTS_SORT_TYPE.NAME]: (a, b) => {
    const aValue = projectHelper.getName(a)
    const bValue = projectHelper.getName(b)
    return stringHelper.sortAfterToLower(aValue, bValue)
  },
  [PROJECTS_SORT_TYPE.INDEX]: (a, b) => {
    const aValue = projectHelper.getIndex(a)
    const bValue = projectHelper.getIndex(b)
    return bValue - aValue
  },
  [PROJECTS_SORT_TYPE.TIMESTAMP]: (a, b) => {
    const aValue = projectHelper.getTimestamp(a)
    const bValue = projectHelper.getTimestamp(b)
    return bValue - aValue
  },
  [PROJECTS_SORT_TYPE.STARRED]: (a, b) => {
    const aValue = _.toInteger(projectHelper.isStarred(a))
    const bValue = _.toInteger(projectHelper.isStarred(b))
    return bValue - aValue
  },
}

export const createProjectSortDescriptor = filters => {
  const sortType = filters.get(FILTER_REDUCER_KEYS.SORT)
  const ascending = filters.get(FILTER_REDUCER_KEYS.SORT_ASCENDING)
  const starredPredicate =
    sortType === PROJECTS_SORT_TYPE.INDEX ? () => 0 : PROJECTS_SORT_PREDICATES[PROJECTS_SORT_TYPE.STARRED]
  const predicate = PROJECTS_SORT_PREDICATES[sortType]
  const predicateWithSortType = ascending ? predicate : _.flow(predicate, n => -n)
  return (a, b) => starredPredicate(a, b) || predicateWithSortType(a, b)
}
