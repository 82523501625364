import styled from 'styled-components'
import { forwardRef, memo, Suspense, useCallback, useMemo } from 'react'
import { Icon } from '@fluentui/react/lib/Icon'
import { graphEventHelper, graphResourceHelper } from '../../common/src/helpers'
import { ITEMS_SORT_TYPE } from '../../common/src/actions/filtersActions'
import { Loading } from '../basic/Loading'
import { useTranslation } from 'react-i18next'
import { LazyIViewPlaceholder } from '../placeholder/LazyIViewPlaceholder'
import { formatDate } from '../../utils/datefns'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { FlagRegular } from '@fluentui/react-icons'
import { TableVirtuoso } from 'react-virtuoso'
import {
  Skeleton,
  SkeletonItem,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@fluentui/react-components'

const IMAGE_SIZE = 24

const SIcon = styled(Icon).attrs({
  side: IMAGE_SIZE,
})`
  font-size: ${IMAGE_SIZE - 6}px;
  margin: auto;
`

export const GRAPH_ITEM_COLUMN_KEYS = {
  ICON: ITEMS_SORT_TYPE.ICON,
  NAME: ITEMS_SORT_TYPE.NAME,
  LAST_MODIFIED_TIMESTAMP: ITEMS_SORT_TYPE.LAST_MODIFIED_TIMESTAMP,
}

const columnHelper = createColumnHelper()

export const GraphItemVirtualizedTable = memo(({ items: _items, selectedItem, placeholder, onRowClick, loading }) => {
  const items = useMemo(
    () => (loading ? [..._items, ...Array.from(Array(15).keys()).map(n => ({ id: `skeleton-${n}` }))] : _items),
    [_items, loading]
  )
  const { t } = useTranslation()

  const columns = useMemo(
    () => [
      columnHelper.accessor(GRAPH_ITEM_COLUMN_KEYS.ICON, {
        id: GRAPH_ITEM_COLUMN_KEYS.ICON,
        header: () => (
          <div className="flex w-full justify-center">
            <FlagRegular className="text-xl" />
          </div>
        ),
        cell: props => (
          <div className="flex w-full justify-center">
            <SIcon iconName={props.getValue()} style={{ color: props.row.original.iconColor }} />
          </div>
        ),
        meta: { size: 30 },
      }),
      columnHelper.accessor(GRAPH_ITEM_COLUMN_KEYS.NAME, {
        id: GRAPH_ITEM_COLUMN_KEYS.NAME,
        header: () => t('item.name'),
        cell: props => <div className="truncate text-pm-black">{props.getValue()}</div>,
      }),
      columnHelper.accessor(
        row =>
          graphEventHelper.isEvent(row)
            ? graphEventHelper.getStartDate(row)
            : graphResourceHelper.getLastModifiedTimestampDate(row),
        {
          id: GRAPH_ITEM_COLUMN_KEYS.LAST_MODIFIED_TIMESTAMP,
          header: () => t('date.date'),
          cell: props => {
            if (!props.getValue()) return null
            const text = formatDate('ShortenedAllDay')(props.getValue())
            return <div className="truncate text-pm-black">{text}</div>
          },
          meta: { size: 90 },
        }
      ),
    ],
    [t]
  )

  const table = useReactTable({
    data: items,
    columns,
    getRowId: row => row.id,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: GRAPH_ITEM_COLUMN_KEYS.LAST_MODIFIED_TIMESTAMP,
          desc: true,
        },
      ],
    },
  })

  const { rows } = table.getRowModel()

  if (!loading && !items.length) {
    return (
      <Suspense fallback={<Loading />}>
        <LazyIViewPlaceholder {...placeholder} />
      </Suspense>
    )
  }

  return (
    <TableVirtuoso
      className="h-full w-full"
      data={rows}
      context={{ selectedItem, onRowClick }}
      computeItemKey={(_, row) => row.id}
      fixedHeaderContent={() => {
        return table.getHeaderGroups().map(headerGroup => {
          return (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                const isSorted = header.column.getIsSorted()
                return (
                  <TableHeaderCell
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: header.column.columnDef.meta?.size, overflow: 'hidden' }}
                    sortDirection={isSorted === 'asc' ? 'ascending' : isSorted === 'desc' ? 'descending' : null}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHeaderCell>
                )
              })}
            </TableRow>
          )
        })
      }}
      components={{
        Table: CustomTable,
        TableBody,
        TableHead: CustomTableHead,
        TableRow: CustomTableRow,
      }}
    />
  )
})

const CustomTable = forwardRef((props, ref) => <Table {...props} ref={ref} sortable />)

const CustomTableHead = forwardRef((props, ref) => {
  return <TableHeader {...props} ref={ref} className="bg-pm-white" />
})

const CustomTableRow = ({ item: row, context: { selectedItem, onRowClick }, ...rest }) => {
  const selected = selectedItem && selectedItem.id === row.original.id
  const handleClick = useCallback(() => onRowClick?.({ rowData: row.original }), [row.original, onRowClick])
  return (
    <TableRow onClick={handleClick} aria-selected={selected} appearance={selected ? 'neutral' : 'none'} {...rest}>
      {row.getVisibleCells().map(cell => {
        return (
          <TableCell key={cell.id} style={{ width: cell.column.columnDef.meta?.size, overflow: 'hidden' }}>
            {row.id.startsWith('skeleton') ? (
              <Skeleton>
                <SkeletonItem />
              </Skeleton>
            ) : (
              flexRender(cell.column.columnDef.cell, cell.getContext())
            )}
          </TableCell>
        )
      })}
    </TableRow>
  )
}
