import React, { useContext } from 'react'
import { motion } from 'framer-motion'
import { LeftPanelLayout } from '../layout/LeftPanelLayout'
import { LeftPanelComponent } from '../leftPanel/LeftPanelComponent'
import { MainLayout } from '../layout/MainLayout'
import { MobileNavBarComponent } from '../mobileNavBar/MobileNavBarComponent'
import { LocationForRoutesContext, ViewSizeContext } from '../../contexts'
import { useExtendedHistory } from '../../hooks/useExtendedHistory'
import { useSelector } from 'react-redux'
import { getRawContext } from '../../common/src/selectors/teamsSelectors'
import { ENFORCE_TEAMS_LAYOUT } from '../../environment'
import { TeamsNavBarComponent } from '../mobileNavBar/TeamsNavBarComponent'
import { isEmbeddedOnOutlook } from '../../helpers/queryParamsHelper'

const blacklistedPathsForAnimation = ['/one_on_one', '/projects', '/home']

const getKeyForLocation = ({ pathname }) => blacklistedPathsForAnimation.find(k => pathname.includes(k)) || pathname

const useCheckTeamsNavBar = () => {
  const context = useSelector(getRawContext)
  const current = context?.page?.id
  const inDesktopApp = !!window.electronAPI
  return ENFORCE_TEAMS_LAYOUT || !!current || inDesktopApp
}

export const NavPanel = props => {
  const { narrowWidth } = useContext(ViewSizeContext)
  const { location } = useExtendedHistory()
  const showTeamsNavBar = useCheckTeamsNavBar()
  const main = <div className="h-full w-full" {...props} />
  if (narrowWidth) {
    const topBar = isEmbeddedOnOutlook() ? undefined : <MobileNavBarComponent />
    const key = getKeyForLocation(location)
    const animatedMain = narrowWidth ? (
      <motion.div
        className="h-full w-full"
        key={key}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
      >
        <LocationForRoutesContext.Provider value={location}>{main}</LocationForRoutesContext.Provider>
      </motion.div>
    ) : (
      main
    )
    return <MainLayout topBar={topBar} main={animatedMain} />
  } else if (showTeamsNavBar) {
    const topBar = <TeamsNavBarComponent />
    return <MainLayout topBar={topBar} main={main} />
  }
  const panel = <LeftPanelComponent />
  return <LeftPanelLayout panel={panel} main={main} />
}
