import _ from 'lodash'
import { useEffect } from 'react'
import { API_EVENT, isPMApiDetailedAction, parseApiActionType } from '../helpers/reducerHelper'
import { useMergeState } from './enhancedHooks'

const hasAccessDenied = status => status === 401 || status === 403
const isNotFound = status => status === 404
const gone = status => status === 410

/**
 * Hook to fetch requests with control to get loading, data, etc
 * For error, accessDenied and notFound only one response is enough
 * @param fetchMethod
 * @param parser
 * @returns {{}}
 */

const initialStateOfUseFetch = { loading: true, data: undefined, error: undefined }

export const useFetch = (fetchMethod, parser = _.identity) => {
  const [state, setState] = useMergeState(initialStateOfUseFetch)
  useEffect(() => {
    if (!fetchMethod) {
      return
    }
    let mounted = true
    const promise = fetchMethod()
    promise
      .then(value => {
        // Only one object or an array
        if (!mounted) {
          return
        }
        const responses = _.isArray(value) ? value : [value]
        let object = {}
        const payloadArray = []
        _.forEach(responses, response => {
          if (!response) {
            return
          }
          const { type, payload } = response
          const apiAction = parseApiActionType(type)
          if (isPMApiDetailedAction(apiAction)) {
            const { event } = apiAction
            if (API_EVENT.ERROR === event) {
              const { status } = payload
              payload.accessDenied = hasAccessDenied(status)
              payload.notFound = isNotFound(status)
              payload.gone = gone(status)
              object.error = payload
              return false // Stopping forEach
            }
          }
          payloadArray.push(parser(payload))
        })
        if (!object.error) {
          object.data = payloadArray.length === 1 ? _.first(payloadArray) : payloadArray
        }
        return object
      })
      .catch(err => {
        return { error: true, data: err }
      })
      .then(value => {
        if (!mounted) {
          return
        }
        const newState = { loading: false, ...value }
        setState(newState)
      })
    return () => {
      mounted = false
    }
  }, [fetchMethod, parser, setState])

  return { ...state, loading: fetchMethod ? state.loading : false }
}
