import React, { memo } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { Shimmer, ShimmerElementType } from '@fluentui/react/lib/Shimmer'

const Container = styled.div`
  height: 46px;
`

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
`

const Circle = styled(Shimmer).attrs({
  shimmerElements: [
    {
      type: ShimmerElementType.circle,
      height: 28,
    },
  ],
})`
  margin-right: -4px;
  border: 2px solid ${p => p.theme.palette.white};
`

const Line = styled(Shimmer).attrs({
  shimmerElements: [
    {
      type: ShimmerElementType.line,
      height: 12,
    },
  ],
})`
  flex: 1;

  margin-bottom: 4px;
`
const NarrowLine = styled(Shimmer).attrs({
  shimmerElements: [
    {
      type: ShimmerElementType.line,
      height: 8,
    },
  ],
})`
  flex: 1;
`

export const MeetingGhostCell = memo(({ shimmerColors, ...rest }) => {
  const [titleWidth] = React.useState(() => _.random(25, 90) + '%')
  const [subTitleWidth] = React.useState(() => _.random(25, 50) + '%')
  const [circlesCount] = React.useState(() => _.random(1, 3))
  return (
    <Container {...rest}>
      <SubContainer>
        <div style={{ flex: 1 }}>
          <Line shimmerColors={shimmerColors} width={titleWidth} />
          <NarrowLine shimmerColors={shimmerColors} width={subTitleWidth} />
        </div>
        {_.times(circlesCount, () => (
          <Circle shimmerColors={shimmerColors} />
        ))}
      </SubContainer>
    </Container>
  )
})
