import { useTranslation } from 'react-i18next'
import { Text, Spinner } from '@fluentui/react-components'

export const InAppPurchaseSuccess = () => {
  const { t } = useTranslation()
  return (
    <div className="m-8 flex flex-col items-center justify-start">
      <Text className="mb-6" size={700}>
        {t('inapp.success.title')}
      </Text>
      <Text className="mb-6">{t('inapp.success.message')}</Text>
      <Spinner size="tiny" label={t('inapp.success.wait')} className="mt-10" />
    </div>
  )
}
