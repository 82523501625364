import { cn } from '@appfluence/classnames'
import React from 'react'

export const Separator = ({ className }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn('box-border w-full', className)}>
      <div className="h-px bg-pm-neutral-light" />
    </div>
  )
}
