import { fromJS } from 'immutable'
import { OBJECT_ERROR_STATUS, ObjectError } from '../common/src/errors/errors'
import { itemHelper, projectHelper, stateHelper } from '../common/src/helpers'
import { getProject } from '../common/src/actions/projectsAPI'
import { getItem } from '../common/src/actions/itemsAPI'
import { isPendingRequestForPMResource } from '../common/src/selectors/requestStateSelectors'
import { PM_API_RESOURCE_TYPE, PM_API_RESOURCES } from '../common/src/constants'
import { timeout } from '../utils'

const ensureProjectIsNotPending = async (store, pid) => {
  await timeout(0)
  const stateChecker = store => {
    return isPendingRequestForPMResource(store.getState(), PM_API_RESOURCES.PROJECT, pid)
  }
  let unsubscribe
  if (stateChecker(store)) {
    unsubscribe = store.subscribe(() => {
      if (!stateChecker(store)) {
        unsubscribe?.()
      }
    })
  }
}

export const manageProject = async ({ store, id, t }) => {
  if (!id || id <= 0) {
    const text = t(`invalid_object.${PM_API_RESOURCE_TYPE.PROJECT}.invalid_id_title`)
    const error = new ObjectError(OBJECT_ERROR_STATUS.INVALID_IDENTIFIER, text)
    return { isError: true, payload: { error } }
  }

  const { dispatch } = store
  await ensureProjectIsNotPending(store, id)
  const state = store.getState()
  let project = stateHelper.getProject(state, id)
  if (!project) {
    const response = await dispatch(getProject(id, true))
    if (response.error) {
      const error = response.payload
      return { isError: true, payload: { error } }
    }
    project = fromJS(response.payload)
  }

  // Manage invalid status for the project
  if (projectHelper.isDeleted(project)) {
    const name = projectHelper.getName(project)
    const text = t(`invalid_object.${PM_API_RESOURCE_TYPE.PROJECT}.deleted_with_name`, { name })
    const error = new ObjectError(OBJECT_ERROR_STATUS.DELETED, text)
    return { isError: true, payload: { error, project } }
  }

  return { isError: false, payload: { id, project } }
}

const ensureItemIsNotPending = async (store, id) => {
  await timeout(0)
  const checkState = () => isPendingRequestForPMResource(store.getState(), PM_API_RESOURCES.ITEM, id)
  if (checkState()) {
    const unsubscribe = store.subscribe(() => {
      if (!checkState()) {
        unsubscribe?.()
      }
    })
  }
}

export const manageItem = async ({ store, id, t }) => {
  if (!id || id <= 0) {
    const text = t(`invalid_object.${PM_API_RESOURCE_TYPE.ITEM}.invalid_id_title`)
    const error = new ObjectError(OBJECT_ERROR_STATUS.INVALID_IDENTIFIER, text)
    return { isError: true, payload: { error } }
  }

  const { dispatch } = store
  await ensureItemIsNotPending(store, id)
  const state = store.getState()
  let item = stateHelper.getItem(state, id)

  if (!item) {
    const response = await dispatch(getItem(id))
    if (response.error) {
      const error = response.payload
      return { isError: true, payload: { error } }
    }

    item = fromJS(response.payload)
  }

  // Manage invalid status for the item
  if (itemHelper.isDeleted(item)) {
    const name = itemHelper.getName(item)
    const text = t(`invalid_object.${PM_API_RESOURCE_TYPE.ITEM}.deleted_with_name`, { name })
    const error = new ObjectError(OBJECT_ERROR_STATUS.DELETED, text)
    return { isError: true, payload: { error, item } }
  }
  const isInbox = itemHelper.isInbox(item) || stateHelper.isInboxPlusItem(state, item)
  return { isError: false, payload: { id, item, isInbox } }
}
