import React, { useMemo, memo } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator'
import { useStepObjectByStep } from '../../hooks/onboardingHooks'
import { onboardingStepHelper } from '../../helpers'
import { onboardingImagesByKey } from '../../resources/onboardingSteps'
import { useTranslation } from 'react-i18next'
import { FlexColumn } from '../layout/FlexContainer'

const containerVariants = {
  hidden: { opacity: 0, scale: 1, x: 200 },
  visible: {
    opacity: 1,
    scale: 1,
    x: 0,
    transition: {
      duration: 0.5,
      delay: 0.1,
    },
  },
}

const Container = styled(motion.div).attrs({
  variants: containerVariants,
  initial: 'hidden',
  animate: 'visible',
})`
  color: ${p => p.theme.palette.white};
`

const TitleBar = styled(FlexColumn)`
  position: relative;
  align-items: center;
  justify-content: center;
`

const TitleImage = styled.img`
  height: 60px;
`

const Title = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 16px;
`

const Message = styled.div`
  margin-top: 8px;
  text-align: center;
  word-break: break-word;
  hyphens: auto;
`

const Indicator = styled(ProgressIndicator)`
  margin-top: 8px;
`

export const OnboardingCompletedStepView = memo(({ step }) => {
  const currentStepObject = useStepObjectByStep(step)
  const { t } = useTranslation()
  const currentStepKey = currentStepObject && onboardingStepHelper.getKey(currentStepObject)

  const stepStrings = useMemo(() => {
    return {
      title: t(`onboarding_step.${currentStepKey}.title`),
      tipTitle: t(`onboarding_step.${currentStepKey}.tipTitle`),
      tipMessage: t(`onboarding_step.${currentStepKey}.tipMessage`),
      completedTitle: t(`onboarding_step.${currentStepKey}.completedTitle`),
      completedMessage: t(`onboarding_step.${currentStepKey}.completedMessage`),
    }
  }, [t, currentStepKey])
  const image = onboardingImagesByKey[currentStepKey]
  const { completedTitle, completedMessage } = stepStrings || {}
  if (!completedTitle || !completedMessage || !image) {
    return
  }
  return (
    <Container>
      <TitleBar>
        <TitleImage src={image} />
        <Title>{completedTitle}</Title>
      </TitleBar>
      <Message>{completedMessage}</Message>
      <Indicator />
    </Container>
  )
})
