import { useSelector } from 'react-redux'
import { itemHelper, stateHelper } from '../../common/src/helpers'
import { checkOwnershipByMode } from './checkOwnershipByMode'
import { useState } from 'react'
import { mapValuesToJsArray } from '../../common/src/helpers/immutableHelpers'
import _ from 'lodash'

const sortItemByLastModified = item => -itemHelper.getLastModifiedTimestamp(item)

export const useMyItems = mode => {
  const allItems = useSelector(stateHelper.getAllItemsMap)
  const meEmail = useSelector(stateHelper.getMeEmail)
  const checkOwnership = checkOwnershipByMode[mode]

  // Store previous order of item IDs
  const [previousOrder, setPreviousOrder] = useState([])

  const filteredItems = allItems.filter(i => {
    return checkOwnership(i, meEmail) && itemHelper.getState(i) === itemHelper.STATE.UNFINISHED
  })

  const sorted = (() => {
    const currentIds = new Set(filteredItems.keys())
    const previousIds = new Set(previousOrder.map(item => itemHelper.getId(item)))

    // Check if we have the same set of IDs
    const hasSameIds = currentIds.size === previousIds.size && [...currentIds].every(id => previousIds.has(id))

    if (hasSameIds) {
      // Maintain previous order but use updated item data
      return previousOrder.map(prevItem => {
        const id = itemHelper.getId(prevItem)
        return allItems.get(id)
      })
    } else {
      const currentItems = mapValuesToJsArray(filteredItems, _.identity, void 0, sortItemByLastModified)
      // Store new order and return sorted items
      setPreviousOrder(currentItems)
      return currentItems
    }
  })()

  // Slice to first 30 items
  return sorted.slice(0, 30)
}
