import _ from 'lodash'
import { SelectableOptionMenuItemType } from '@fluentui/react/lib/SelectableOption'
import { userHelper } from '../common/src/helpers'

export const USER_OPTION_TYPE = {
  NORMAL: SelectableOptionMenuItemType.Normal,
  DIVIDER: SelectableOptionMenuItemType.Divider,
  HEADER: SelectableOptionMenuItemType.Header,
}

export const USER_OPTIONS_KEYS = {
  CLEAR_OWNER: 'clearOwner',
  MYSELF_HEADER: 'myselfHeader',
  PROJECT_MEMBERS_HEADER: 'projectMembersHeader',
  TEAM_HEADER: 'teamHeader',
  OTHER_USERS_HEADER: 'otherUsersHeader',
  NO_PM_USERS_HEADER: 'noPMUsersHeader',
}

const getDividerFor = key => ({
  key: `divider-${key}`,
  text: '-',
  itemType: USER_OPTION_TYPE.DIVIDER,
})

export const createUserOption = (user, t) => {
  const email = userHelper.getEmail(user)
  const fullName = userHelper.getFullName(user) || email
  const no_pm_tooltip = t('users.no_pm_user_tooltip')
  return {
    key: email,
    email: email,
    text: fullName,
    ariaLabel: userHelper.isPMUser(user) ? email : no_pm_tooltip,
    useAriaLabelAsText: true, // This value is necessary to force ariaLabel attribute as tooltip
    user,
  }
}

export function createOptionUserHelper(t, projectName = '') {
  return {
    items: [
      {
        key: USER_OPTIONS_KEYS.CLEAR_OWNER,
        text: t('users.clear_owner'),
      },
      {
        key: USER_OPTIONS_KEYS.MYSELF_HEADER,
        text: t('users.myself'),
        itemType: USER_OPTION_TYPE.HEADER,
      },
      {
        key: USER_OPTIONS_KEYS.PROJECT_MEMBERS_HEADER,
        text: t('users.in_project', { name: projectName }),
        itemType: USER_OPTION_TYPE.HEADER,
      },
      {
        key: USER_OPTIONS_KEYS.TEAM_HEADER,
        text: t('users.your_team'),
        itemType: USER_OPTION_TYPE.HEADER,
      },
      {
        key: USER_OPTIONS_KEYS.OTHER_USERS_HEADER,
        text: t('users.other_users'),
        itemType: USER_OPTION_TYPE.HEADER,
      },
      {
        key: USER_OPTIONS_KEYS.NO_PM_USERS_HEADER,
        text: t('users.no_pm_users'),
        itemType: USER_OPTION_TYPE.HEADER,
        infoLink: `https://appfluence.com/help/article/collaborators-i-dont-recognize/`,
      },
    ],
  }
}

export const createUserOptions = (users, t) => users.map(user => createUserOption(user, t))

export const createOrganizedUsersOptions = ({
  projectName,
  projectMembers,
  team,
  otherUsers,
  noPM,
  me,
  withNoOwner = true,
  noOwnerText,
  addDivider = false,
  t,
}) => {
  const optionUserHelper = createOptionUserHelper(t, projectName)
  const options = []
  if (withNoOwner) {
    const customNoOwnerOption = noOwnerText
      ? _.merge({}, optionUserHelper.items[0], { text: noOwnerText })
      : optionUserHelper.items[0]
    options.push(customNoOwnerOption)
    addDivider && options.push(getDividerFor(USER_OPTIONS_KEYS.CLEAR_OWNER))
  }

  if (me) {
    options.push(optionUserHelper.items[1])
    options.push(createUserOption(me, t))
    addDivider && options.push(getDividerFor(USER_OPTIONS_KEYS.MYSELF_HEADER))
  }

  if (projectName && projectMembers && projectMembers.length > 0) {
    options.push(optionUserHelper.items[2])
    options.push(...projectMembers.map(user => createUserOption(user, t)))
    addDivider && options.push(getDividerFor(USER_OPTIONS_KEYS.PROJECT_MEMBERS_HEADER))
  }

  if (team && team.length > 0) {
    options.push(optionUserHelper.items[3])
    options.push(...team.map(user => createUserOption(user, t)))
    addDivider && options.push(getDividerFor(USER_OPTIONS_KEYS.TEAM_HEADER))
  }

  if (otherUsers && otherUsers.length > 0) {
    options.push(optionUserHelper.items[4])
    options.push(...otherUsers.map(user => createUserOption(user, t)))
    addDivider && options.push(getDividerFor(USER_OPTIONS_KEYS.OTHER_USERS_HEADER))
  }

  if (noPM && noPM.length > 0) {
    options.push(optionUserHelper.items[5])
    options.push(...noPM.map(user => createUserOption(user, t)))
    addDivider && options.push(getDividerFor(USER_OPTIONS_KEYS.NO_PM_USERS_HEADER))
  }

  return options
}
