import { ProjectTemplateList } from './ProjectTemplateList'
import { PUBLIC_PROJECT_TEMPLATE_CATEGORY_ALL } from '@/constants/projectTemplates'
import { PROJECT_TEMPLATE_TYPE } from '../ProjectTemplateType'
import { useAllPublicProjectTemplates, usePublicProjectTemplateSearch } from '@/queries/projectTemplates'
import { useDebouncedValue } from '@/hooks/useDebouncedValue'
import { useMemo } from 'react'

const type = PROJECT_TEMPLATE_TYPE.PUBLIC

function compareDownloads(a, b) {
  return b.downloads - a.downloads
}

export const PublicProjectTemplateList = ({
  searchText = '',
  selectedCategory = PUBLIC_PROJECT_TEMPLATE_CATEGORY_ALL,
  ...rest
}) => {
  const debouncedPublicSearch = useDebouncedValue(searchText, 500)

  const {
    data: allTemplates,
    fetchNextPage,
    isLoading: isLoadingAll,
  } = useAllPublicProjectTemplates({ category: selectedCategory })
  const { data: searchedTemplates, isLoading: isLoadingSearch } = usePublicProjectTemplateSearch(
    { text: debouncedPublicSearch, category: selectedCategory },
    !!debouncedPublicSearch
  )

  const templates = debouncedPublicSearch ? searchedTemplates : allTemplates
  const isLoading = debouncedPublicSearch ? isLoadingSearch : isLoadingAll

  const sortedTemplates = useMemo(() => {
    if (!templates) return []
    return debouncedPublicSearch ? [...templates].sort(compareDownloads) : templates
  }, [debouncedPublicSearch, templates])

  return (
    <ProjectTemplateList
      type={type}
      templates={sortedTemplates}
      loading={isLoading}
      endReached={debouncedPublicSearch ? undefined : fetchNextPage}
      increaseViewportBy={debouncedPublicSearch ? undefined : 10}
      {...rest}
    />
  )
}
