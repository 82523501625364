import React from 'react'
import styled from 'styled-components'
import { Stack } from '@fluentui/react/lib/Stack'

const Container = styled.div``
const StyledStack = styled(Stack)``
const stackTokens = { childrenGap: 8 }

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  margin-bottom: 4px;
  border-bottom: 1px solid ${p => p.theme.palette.neutralLight};
`
export const PanelView = ({ headerChildren, headerContainerProps, children, ...rest }) => {
  return (
    <Container {...rest}>
      <HeaderContainer {...headerContainerProps}>{headerChildren}</HeaderContainer>
      <StyledStack tokens={stackTokens}>{children}</StyledStack>
    </Container>
  )
}
