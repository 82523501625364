import { fromJS, OrderedSet } from 'immutable'
import _ from 'lodash'
import {
  API_EVENT,
  isPMApiDetailedAction,
  parseApiActionType,
  PERSIST_REHYDRATE_ACTION_TYPE,
} from '../helpers/reducerHelper'
import { PM_API_RESOURCES } from '../constants'
import { GENERIC_ACTION_TYPE } from '../actions/genericActions'
import { SEARCH_REDUCER_KEYS as KEYS } from './searchKeys'
import { itemHelper, projectHelper } from '../helpers'
import { SEARCH_ACTION_TYPES, SEARCH_MODELS } from '../actions/searchAPI'

const jsSectionBase = {
  [KEYS.ITEMS]: {
    [KEYS.SET]: OrderedSet(),
    [KEYS.PARAMS]: {},
  },
  [KEYS.PROJECTS]: {
    [KEYS.SET]: OrderedSet(),
    [KEYS.PARAMS]: {},
  },
}

const base = fromJS({
  [KEYS.SECTION_SEARCH]: jsSectionBase,
  [KEYS.SECTION_ONE_ON_ONE]: jsSectionBase,
  [KEYS.SECTION_MEETING]: jsSectionBase,
  [KEYS.SECTION_AGENDA]: jsSectionBase,
})

const getObjectsTypeInfoFromParams = params => {
  const objectType = params.model === SEARCH_MODELS.ITEM ? KEYS.ITEMS : KEYS.PROJECTS
  const key = objectType === KEYS.ITEMS ? itemHelper.KEYS.ID : projectHelper.KEYS.IDD
  return { objectType, key }
}

const extractParams = params => {
  const newParams = _.omit(params, 'offset')
  return fromJS(newParams)
}

export const search = (state = base, action) => {
  const { type, payload, meta } = action
  if (_.get(meta, 'section') === KEYS.SECTION_SINK) {
    return state
  }
  const apiAction = parseApiActionType(type)
  if (isPMApiDetailedAction(apiAction) && apiAction.resource === PM_API_RESOURCES.SEARCH) {
    const { event } = apiAction
    if (event === API_EVENT.START) {
      const { params, section } = meta
      const { objectType } = getObjectsTypeInfoFromParams(params)
      const newParams = extractParams(params)
      const prevParams = state.getIn([section, objectType, KEYS.PARAMS]) || fromJS(jsSectionBase)
      if (!newParams.equals(prevParams)) {
        state = state.withMutations(st => {
          st.setIn([section, objectType, KEYS.SET], OrderedSet())
          st.setIn([section, objectType, KEYS.PARAMS], newParams)
        })
      }
    } else if (event === API_EVENT.SUCCESS) {
      const { params, section } = meta
      const { objectType, key } = getObjectsTypeInfoFromParams(params)
      const newParams = extractParams(params)
      const prevParams = state.getIn([section, objectType, KEYS.PARAMS])
      if (newParams.equals(prevParams)) {
        state = state.withMutations(st => {
          let set = st.getIn([section, objectType, KEYS.SET])
          const newIDs = _.map(payload.objects, o => _.get(o, key))
          set = set.union(newIDs)
          st.setIn([section, objectType, KEYS.SET], set)
        })
      }
    }
  } else {
    switch (type) {
      case SEARCH_ACTION_TYPES.INSERT:
        {
          const { id, section, objectType } = payload
          const list = state.getIn([section, objectType, KEYS.SET]).toList()
          const set = list.insert(0, id).toOrderedSet()
          state = state.setIn([section, objectType, KEYS.SET], set)
        }
        break

      case PERSIST_REHYDRATE_ACTION_TYPE:
        {
          if (payload.search) {
            state = state.merge(payload.search)
          }
        }
        break
      case GENERIC_ACTION_TYPE.CLEAR_ALL:
        {
          state = base
        }
        break
    }
  }
  return state
}
