const info_ = {
  name: '',
  version: '',
}

export const DynamicPlatformConfig = {
  setName: name => (info_.name = name),
  getName: () => info_.name,
  setVersion: version => (info_.version = version),
  getVersion: () => info_.version,
}
