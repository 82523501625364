import React from 'react'
import { ResponsiveButton } from './ResponsiveButton'

const topBarAddButtonProps = {
  iconProps: {
    iconName: 'CircleAdditionSolid',
  },
}

export const TopBarAddButton = props => {
  return <ResponsiveButton {...topBarAddButtonProps} {...props} />
}
