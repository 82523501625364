import { itemHelper } from '../common/src/helpers'
import { getItemLink } from '../common/src/constants'
import type { Item } from '@/types'

declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage: (data: string) => void
    }
  }
}

const TYPES = {
  OPEN_URL: 'OPEN_URL',
  SHARE_ITEM: 'SHARE_ITEM',
  SET_BADGE_COUNT: 'SET_BADGE_COUNT',
  SHOW_DEV_PANEL: 'SHOW_DEV_PANEL',
  GET_FILE_TO_SHARE: 'GET_FILE_TO_SHARE',
  VIBRATE: 'VIBRATE',
  SET_USER: 'SET_USER',
  REQUEST_REVIEW: 'REQUEST_REVIEW',
}

const sendFluxMessage = (message: object) => {
  try {
    const json = JSON.stringify(message)
    window.ReactNativeWebView?.postMessage(json)
  } catch (err) {
    console.warn('Error sending data to expo')
  }
}

export const openLinkMessage = (url: string) => {
  sendFluxMessage({
    type: TYPES.OPEN_URL,
    payload: url,
  })
}

export const openDevPanel = () => {
  sendFluxMessage({
    type: TYPES.SHOW_DEV_PANEL,
    payload: true,
  })
}

export const shareItem = (item: Item) => {
  const id = itemHelper.getId(item)
  sendFluxMessage({
    type: TYPES.SHARE_ITEM,
    payload: {
      title: itemHelper.getName(item),
      url: getItemLink(id),
    },
  })
}

export const setBadgeCount = (count: number) => {
  sendFluxMessage({
    type: TYPES.SET_BADGE_COUNT,
    payload: count,
  })
}

export const setUser = (user: { id: number; email: string } | null) => {
  sendFluxMessage({
    type: TYPES.SET_USER,
    payload: user,
  })
}

export enum VibrateMode {
  ImpactLow = 'impactLow',
  ImpactMedium = 'impactMedium',
  ImpactHigh = 'impactHigh',
  NotificationSuccess = 'notificationSuccess',
  NotificationWarning = 'notificationWarning',
  NotificationError = 'notificationError',
  Selection = 'selection',
}

export const vibrate = (mode: VibrateMode) => {
  sendFluxMessage({
    type: TYPES.VIBRATE,
    payload: mode,
  })
}

export const getFileToShare = (payload: { fileURI: string; fileName: string | null; fileType: string | null }) => {
  sendFluxMessage({
    type: TYPES.GET_FILE_TO_SHARE,
    payload,
  })
}

export const requestReview = () => {
  sendFluxMessage({
    type: TYPES.REQUEST_REVIEW,
  })
}
