import _ from 'lodash'
import { fromJS } from 'immutable'
import { ONBOARDING_KEYS as KEYS } from './onboardingKeys'
import { ACTIONS } from '../actions/onboardingActions'
import { PERSIST_REHYDRATE_ACTION_TYPE } from '../common/src/helpers/reducerHelper'
import { GENERIC_ACTION_TYPE } from '../common/src/actions/genericActions'
import { onboardingStepsByKey } from '../resources/onboardingSteps'
import { onboardingStepHelper } from '../helpers'

const base = fromJS({
  [KEYS.IS_FINISHED]: false,
  [KEYS.IS_CANCELLED]: true, // Renaming for IS_SHOWN
  [KEYS.CURRENT_STEP]: 0,
  [KEYS.STEPS_BY_KEY]: onboardingStepsByKey,
})

const reduceEffect = {
  [ACTIONS.SET_IS_FINISHED]: ({ payload }, state) => {
    return state.set(KEYS.IS_FINISHED, payload)
  },
  [ACTIONS.SET_IS_CANCELLED]: ({ payload: isCancelled }, state) => {
    if (isCancelled) {
      return state.set(KEYS.IS_CANCELLED, isCancelled)
    }
    return base.set(KEYS.IS_CANCELLED, isCancelled)
  },
  [ACTIONS.SET_STEP]: ({ payload: step }, state) => {
    return state.set(KEYS.CURRENT_STEP, step)
  },
  [ACTIONS.SEND_STEP_EVENT]: ({ payload }, state) => {
    const { key } = payload
    const stepObjects = state.get(KEYS.STEPS_BY_KEY)
    let stepObject = stepObjects.get(key)
    const step = onboardingStepHelper.getStep(stepObject)
    const currentStep = state.get(KEYS.CURRENT_STEP)
    if (step !== currentStep) {
      return state
    }

    return state.withMutations(st => {
      const nextCount = onboardingStepHelper.getCount(stepObject) + 1
      stepObject = stepObject.set(onboardingStepHelper.KEYS.COUNT, nextCount)
      st.setIn([KEYS.STEPS_BY_KEY, key], stepObject)

      const limit = onboardingStepHelper.getLimit(stepObject)
      const lastStep = stepObjects.size - 1

      // Step completed
      if (nextCount >= limit) {
        state = st.set(KEYS.CURRENT_STEP, currentStep + 1)
        if (currentStep === lastStep) {
          // Finished
          state = st.set(KEYS.IS_FINISHED, true)
        }
      }
    })
  },
}

export const onboarding = (state = base, action) => {
  const { type, payload } = action
  switch (type) {
    case PERSIST_REHYDRATE_ACTION_TYPE:
      {
        if (payload.onboarding) {
          state = state.merge(payload.onboarding)
          if (state.get(KEYS.STEPS_BY_KEY).count() !== _.size(onboardingStepsByKey)) {
            state = state.set(KEYS.CURRENT_STEP, 0)
            state = state.set(KEYS.STEPS_BY_KEY, fromJS(onboardingStepsByKey))
          }
        }
      }
      break
    case GENERIC_ACTION_TYPE.CLEAR_ALL:
      {
        state = base
      }
      break
    default:
      {
        const effect = reduceEffect[type]
        if (_.isFunction(effect)) {
          state = effect(action, state)
        }
      }
      break
  }
  return state
}
