import _ from 'lodash'
import { pickPredicate, safeMapFnCreator } from './immutableHelpers'
import { resourceURIParser } from './URLHelper'

const _export = {}

const KEYS = {
  ID: 'id',
  MESSAGE: 'message',
  PROJECT: 'project',
  PROJECT_NAME: 'project_name',
  RESOURCE_URI: 'resource_uri',
  SOURCE_USER: 'source_user',
  SOURCE_USERNAME: 'source_username',
  STATUS: 'status',
  TARGET_USER: 'target_user',
  TARGET_USERNAME: 'target_username',
  RANDOM_HASH: 'random_hash',
}
_export.KEYS = KEYS

_export.STATUS = {
  INIT: 'init',
  ACCEPTED: 'accepted',
  REMOVE: 'remove',
  REJECTED: 'rejected',
}

_export.isSharingRequest = r => {
  return r.has(KEYS.STATUS)
}

_export.getId = r => r.get(KEYS.ID)
_export.getProjectURI = r => r.get(KEYS.PROJECT)
_export.getProjectName = r => r.get(KEYS.PROJECT_NAME)
_export.getURI = r => r.get(KEYS.RESOURCE_URI)
_export.getSourceUserURI = r => r.get(KEYS.SOURCE_USER)
_export.getSourceUsername = r => r.get(KEYS.SOURCE_USERNAME)
_export.getTargetUserURI = r => r.get(KEYS.TARGET_USER)
_export.getTargetUsername = r => r.get(KEYS.TARGET_USERNAME)
_export.getStatus = r => r.get(KEYS.STATUS)
_export.setStatus = (r, s) => r.set(KEYS.STATUS, s)

_export.getProjectID = r => {
  const projectURI = _export.getProjectURI(r)
  return resourceURIParser(projectURI).id
}

const SEND_WHITELIST = [
  KEYS.ID,
  KEYS.MESSAGE,
  KEYS.PROJECT,
  KEYS.PROJECT_NAME,
  KEYS.RESOURCE_URI,
  KEYS.SOURCE_USER,
  KEYS.SOURCE_USERNAME,
  KEYS.STATUS,
  KEYS.TARGET_USER,
  KEYS.TARGET_USERNAME,
  KEYS.TIMESTAMP,
  KEYS.RANDOM_HASH,
]

_export.prepareForSend = r => {
  const obj = r.filter(pickPredicate(...SEND_WHITELIST)).toJS()
  return JSON.stringify(obj)
}

export default _.mapValues(_export, f => (_.isFunction(f) ? safeMapFnCreator(f) : f))
