import { useContext, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { RouteIdContext } from '../contexts'

export const RouteWithId = ({ routeId, ...rest }) => {
  const { setRouteId } = useContext(RouteIdContext)

  useEffect(() => {
    setRouteId(routeId)
  }, [routeId, setRouteId])

  return <Route {...rest} />
}
