import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { InfoIconCallout } from '../../components/info/InfoIconCallout'
import { PlusButton } from '../../components/buttons/PlusButton'
import { useCreateItemModal } from '@/hooks/useCreateItemModal'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { linkSubject } from '../../reactions/linkSubject'
import { itemHelper } from '../../common/src/helpers'
import { SECTIONS } from './HomeConstants'
import { useTranslation } from 'react-i18next'
import { BaseFlexRow } from '../../components/layout/FlexContainer'
import { ResponsiveButton } from '../../components/buttons/ResponsiveButton'
import { useExtendedHistory } from '../../hooks/useExtendedHistory'
import { useDispatch } from 'react-redux'
import { FREQUENT_ITEM_FILTERS, setFrequentItemFilter } from '../../common/src/actions/filtersActions'
import { FILTER_REDUCER_KEYS } from '../../common/src/reducers/filtersKeys'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'

const Content = styled.div`
  color: ${p => p.theme.palette.neutralDark};
  font-weight: bolder;
  font-size: large;
  box-sizing: content-box;
  padding: 40px 0px 0px 8px;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;

  &.first {
    padding-top: 0;
  }

  @media print {
    color: ${p => p.theme.palette.always_black};
  }
`

const Create = styled(PlusButton)`
  cursor: pointer;
`

const CreateContainer = styled.div`
  margin-left: 10px;
  align-self: center;
  height: 20px;

  @media print {
    display: none;
  }
`

const Info = styled(InfoIconCallout)`
  margin-left: 10px;
`

export const HomeAgendaHeader_ = ({ title, infoMessage, displayAdd: _displayAdd = true, date, ...rest }) => {
  const createItemModal = useCreateItemModal()
  const { t } = useTranslation()
  const displayAdd = _displayAdd && !_.includes(t('agenda.overdue'), title)
  const onClickAdd = () => {
    createItemModal({
      mode: EVENT_EXTRA.CREATE_ITEM.MODE.HOME_AGENDA,
      displayProjectSelector: true,
      dueDate: date,
      open: false,
      completion: i => {
        linkSubject.next({
          urlData: itemHelper.getUriData(i),
          section: SECTIONS.AGENDA,
        })
      },
    })
  }
  return (
    <Content {...rest}>
      {title}
      {infoMessage && <Info message={infoMessage} />}
      {displayAdd && (
        <CreateContainer title={t('agenda.create_item')}>
          <Create size={20} onClick={onClickAdd} />
        </CreateContainer>
      )}
    </Content>
  )
}

export const HomeAgendaHeader = React.memo(HomeAgendaHeader_)

const SeeMoreRow = styled(BaseFlexRow)`
  justify-content: center;
`

export const SeeMore = React.memo(() => {
  const { t } = useTranslation()
  const { history } = useExtendedHistory()
  const dispatch = useDispatch()
  const onClick = () => {
    dispatch(setFrequentItemFilter(FREQUENT_ITEM_FILTERS.MY_ITEMS, FILTER_REDUCER_KEYS.SEARCH))
    const path = getRelativeURLKeepingQuerySearch.searchApp()
    history.push(path)
  }
  return (
    <SeeMoreRow>
      <ResponsiveButton onClick={onClick}>{t('general.see_more')}</ResponsiveButton>
    </SeeMoreRow>
  )
})
