import { fromJS, Record } from 'immutable'

const KEYS = {
  NAME: 'name',
  ID: 'id',
  FILTER: 'filter',
}

const FILTER_KEYS = {
  SORT: 'sort',
  SORT_ASCENDING: 'sort_ascending',
  OWNERS: 'owners',
  STATE: 'state',
  FOLLOWED: 'followed',
  QUADRANT: 'quadrant',
  DATE_TYPE: 'date_type',
  PROGRESS_CONDITION: 'progress_condition',
  PROGRESS: 'progress',
  TAGS: 'tags',
  TEXT: 'text',
  PROJECT_IDS: 'project_ids',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
}

const Base = Record({
  id: 0,
  name: '',
  filter: {},
})

let helper = {}

helper.create = ({ name, filter, ...rest }) => {
  return Base({ name, filter: fromJS(filter), ...rest })
}

helper.getName = customFilter => customFilter.get(KEYS.NAME)
helper.getId = group => group.get(KEYS.ID)

helper.getFilter = group => group.get(KEYS.FILTER)

helper.getSort = customFilter => customFilter.get(KEYS.FILTER).get(FILTER_KEYS.SORT)
helper.getSortAscending = customFilter => customFilter.get(KEYS.FILTER).get(FILTER_KEYS.SORT_ASCENDING)
helper.getOwners = customFilter => customFilter.get(KEYS.FILTER).get(FILTER_KEYS.OWNERS)
helper.getState = customFilter => customFilter.get(KEYS.FILTER).get(FILTER_KEYS.STATE)
helper.getFollowed = customFilter => customFilter.get(KEYS.FILTER).get(FILTER_KEYS.FOLLOWED)
helper.getQuadrant = customFilter => customFilter.get(KEYS.FILTER).get(FILTER_KEYS.QUADRANT)
helper.getDateType = customFilter => customFilter.get(KEYS.FILTER).get(FILTER_KEYS.DATE_TYPE)
helper.getProgressCondition = customFilter => customFilter.get(KEYS.FILTER).get(FILTER_KEYS.PROGRESS_CONDITION)
helper.getProgress = customFilter => customFilter.get(KEYS.FILTER).get(FILTER_KEYS.PROGRESS)
helper.getTags = customFilter => customFilter.get(KEYS.FILTER).get(FILTER_KEYS.TAGS)
helper.getTagsMode = customFilter => customFilter.get(KEYS.FILTER).get(FILTER_KEYS.TAGS_MODE)
helper.getText = customFilter => customFilter.get(KEYS.FILTER).get(FILTER_KEYS.TEXT)
helper.getProjects = customFilter => customFilter.get(KEYS.FILTER).get(FILTER_KEYS.PROJECT_IDS)
helper.getProjectsMode = customFilter => customFilter.get(KEYS.FILTER).get(FILTER_KEYS.PROJECTS_MODE)
helper.getStartDate = customFilter => customFilter.get(KEYS.FILTER).get(FILTER_KEYS.START_DATE)
helper.getEndDate = customFilter => customFilter.get(KEYS.FILTER).get(FILTER_KEYS.END_DATE)

export default helper
