import React from 'react'
import { useDispatch } from 'react-redux'
import { PRODUCTION_WEBAPP } from '../../common/src/constants'
import { InfoIconCallout } from '../../components/info/InfoIconCallout'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { useTranslation } from 'react-i18next'

export const InfoGetPM = React.memo(({ className }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const shouldRecommendPM = true

  const getPMLinkOnClick = () => {
    window.open(PRODUCTION_WEBAPP, '_blank')
    dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.OPEN_WEBAPP))
  }

  const getPMLink = {
    title: t('one_on_one_hub.get_pm_callout_download_link'),
    onClick: getPMLinkOnClick,
  }

  if (shouldRecommendPM) {
    return (
      <InfoIconCallout
        title={t('one_on_one_hub.get_pm_callout_title')}
        message={t('one_on_one_hub.get_pm_callout_subtitle')}
        link={getPMLink}
        calloutMaxWidth={300}
        iconName="CloneToDesktop"
        className={className}
      />
    )
  }
  return false
})
