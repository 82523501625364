import React from 'react'
import { UsersCollection } from './usersCollection/UsersCollection'
import { useProjectMembersForProject } from '../../common/src/hooks/usersHooks'
import { userHelper } from '../../common/src/helpers'

export const ProjectMembersCollection = ({ project, ...rest }) => {
  const members = useProjectMembersForProject({
    project,
    sortDescriptor: userHelper.sortDescriptorByFullName,
    populateIsAdmin: true,
    asArray: true,
  })
  return <UsersCollection users={members} {...rest} />
}
