import _ from 'lodash'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useAsyncEffect } from '../common/src/hooks/useAsyncEffect'
import { useDispatch, useSelector } from 'react-redux'
import { KEYS } from '../reducers/workloadKeys'
import { itemHelper, stateHelper } from '../common/src/helpers'
import { getPaginatedSearchItem } from '../common/src/actions/searchAPI'
import { SEARCH_REDUCER_KEYS } from '../common/src/reducers/searchKeys'
import { dateToKey, MS_PER_H, TOTALS } from '../views/planning/effort/common'
import { Record, Set } from 'immutable'
import { datefns } from '../utils/datefns'

const BASE = 'workload'

export const useShowCompleted = () => useSelector(state => state.getIn([BASE, KEYS.SHOW_COMPLETED]))

const useWorkloadFilter = () => {
  const viewState = useSelector(state => state.get(BASE))
  return useCallback(
    item => {
      const blacklist = viewState.get(KEYS.TAG_BLACKLIST)
      const tags = itemHelper.getTags(item)
      return !tags.some(t => {
        const name = t.get('name')
        return blacklist.has(name)
      })
    },
    [viewState]
  )
}

export const useUsernameBlacklist = () => useSelector(s => s.getIn([BASE, KEYS.USERNAME_BLACKLIST]))

export const useFetchDueItemsInInterval = (startDate, dueDate) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const knownRef = useRef(Set())
  const cacheKey = datefns.getUnixTime(startDate)

  useAsyncEffect(
    async isMounted => {
      if (knownRef.current.has(cacheKey)) {
        setLoading(false)
        return
      }
      knownRef.current = knownRef.current.add(cacheKey)
      const params = [
        {
          state__lte: itemHelper.STATE.DONE,
          dueDate__gt: datefns.getUnixTime(startDate),
          dueDate__lt: datefns.getUnixTime(dueDate),
          limit: 500,
        },
        {
          state__lte: itemHelper.STATE.DONE,
          dueDate__gt: datefns.getUnixTime(dueDate),
          startDate__lt: datefns.getUnixTime(dueDate),
          startDate__gt: datefns.getUnixTime(datefns.addYears(-1, startDate)),
          limit: 500,
        },
      ]
      setLoading(true)
      const promises = _.map(params, p => dispatch(getPaginatedSearchItem(p, SEARCH_REDUCER_KEYS.SECTION_AGENDA)))
      await Promise.all(promises)
      if (isMounted()) {
        setLoading(false)
      }
    },
    [cacheKey]
  )

  return loading
}

export const useTimeIntervalForDay = day => {
  return useMemo(() => {
    const range = _.range(7).map(i => datefns.setDay(i, day))
    return {
      range,
      start: datefns.startOfDay(range[0]),
      end: datefns.endOfDay(range[6]),
    }
  }, [day])
}

export const useItemsInInterval = interval => {
  const filterFn = useWorkloadFilter()
  const allItems = useSelector(stateHelper.getAllItemsMap)
  const showCompleted = useShowCompleted()
  const [start, end] = _.map(interval, datefns.getUnixTime)
  const inViewInterval = useCallback(x => _.inRange(x, start, end), [start, end])
  const viewIntervalIncludedInItemInterval = useCallback(
    (iStart, iDue) => iStart && iStart < start && iDue > end,
    [start, end]
  )
  return useMemo(() => {
    return allItems.filter(i => {
      const iDue = itemHelper.getDueTimestamp(i)
      const iStart = itemHelper.getStartTimestamp(i)
      const done = itemHelper.isCompleted(i)
      if (!showCompleted && done) {
        return false
      }
      if (!iDue) {
        return false
      }
      const shouldRender =
        inViewInterval(iDue) || inViewInterval(iStart) || viewIntervalIncludedInItemInterval(iStart, iDue)
      return shouldRender && itemHelper.getState(i) <= itemHelper.STATE.DONE && filterFn(i)
    })
  }, [allItems, showCompleted, inViewInterval, viewIntervalIncludedInItemInterval, filterFn])
}

export const useTableData = ({ team, items, days, state }) => {
  const usernameBlacklist = useUsernameBlacklist()
  const showCompleted = useShowCompleted()
  return useMemo(() => {
    const def = ColDataFactory()
    const userRowBase = _.reduce(
      days,
      (acc, day) => {
        const key = dateToKey(day)
        acc[key] = def
        return acc
      },
      {}
    )
    const teamObj = team.filter((v, email) => !usernameBlacklist.has(email)).toObject()
    teamObj[TOTALS] = true // Add totals row
    const table = _.mapValues(teamObj, () => ({ ...userRowBase }))
    items.reduce((acc, item) => {
      const username = itemHelper.getOwnerUsername(item)
      if (!acc[username]) {
        return acc
      }
      const interval = {
        start: itemHelper.getStartDate(item),
        end: itemHelper.getDueDate(item),
      }
      const multiday = interval.start && !datefns.isSameDay(interval.start, interval.end)
      const eff = (itemHelper.getEstimatedEffort(item) || state.defEffort) / 60
      const allDay = itemHelper.isAllDay(item)
      const inInterval = datefns.isWithinInterval(interval)
      const completionPercentage = itemHelper.getCompletionPercentage(item)
      const completionModifier = showCompleted ? 1 : 100 / completionPercentage

      if (multiday) {
        const businessDays = datefns.differenceInBusinessDays(interval.start, interval.end) + 1
        _.each(days, d => {
          const isWeekend = datefns.isWeekend(d)
          const key = dateToKey(d)
          let adjustedInterval = undefined
          if (datefns.isSameDay(interval.start, d)) {
            adjustedInterval = {
              start: interval.start,
              end: datefns.endOfDay(interval.start),
            }
          } else if (datefns.isSameDay(interval.end, d)) {
            adjustedInterval = {
              start: datefns.startOfDay(interval.end),
              end: interval.end,
            }
          } else if (inInterval(d)) {
            adjustedInterval = {
              start: datefns.startOfDay(d),
              end: datefns.endOfDay(d),
            }
          }
          if (!adjustedInterval) {
            return
          }
          const current = acc[username][key]
          const colTotals = acc[TOTALS][key]
          acc[TOTALS][key] = colTotals.withMutations(_totals => {
            acc[username][key] = current.withMutations(_current => {
              _current.items = _current.items.add(item)
              _totals.items = _totals.items.add(item)
              if (!isWeekend) {
                const effortContribution = eff / businessDays / completionModifier
                _current.effort += effortContribution
                _totals.effort += effortContribution
                _current.hours += effortContribution
                _totals.hours += effortContribution
              }
            })
          })
        })
      } else {
        const key = dateToKey(interval.end)
        const current = acc[username][key]
        const colTotals = acc[TOTALS][key]
        acc[TOTALS][key] = colTotals.withMutations(_totals => {
          acc[username][key] = current.withMutations(_current => {
            _current.items = _current.items.add(item)
            _totals.items = _totals.items.add(item)
            const effortContribution = eff / completionModifier
            _current.effort += effortContribution
            _totals.effort += effortContribution
            if (allDay) {
              _current.hours += 8
              _totals.hours += 8
            } else {
              if (interval.start) {
                const ms = interval.end - interval.start
                const h = ms / MS_PER_H
                _current.hours += h
                _totals.hours += h
              } else {
                _current.hours += state.defHours
                _totals.hours += state.defHours
              }
            }
          })
        })
      }
      return acc
    }, table)
    computeTotals(table)
    return table
  }, [team, items, days, state, usernameBlacklist, showCompleted])
}

const computeTotals = table => {
  _.each(table, row => {
    row[TOTALS] = ColDataFactory().withMutations(t => {
      _.each(row, dayData => {
        t.items = t.items.union(dayData.items)
        t.hours += dayData.hours
        t.effort += dayData.effort
      })
    })
  })
}

const ColDataFactory = Record({
  items: Set(),
  hours: 0,
  effort: 0,
})
