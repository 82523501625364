export const OBJECT_ERROR_NAME = 'ObjectError'

export const OBJECT_ERROR_STATUS = {
  INVALID_IDENTIFIER: 'invalid_identifier',
  DELETED: 'deleted',

  PROJECT_EMPTY_NAME: 'project_empty_name',
  ITEM_EMPTY_NAME: 'item_empty_name',

  INVALID_PROJECTS_INDEXES: 'invalid_projects_indexes',
  EMPTY_FILE: 'empty_file',
}

/**
 * Error class for an invalid object
 *
 * @class ObjectError
 * @access public
 * @param {number} status - the status code of the API response
 * @param {string} statusText - the status text of the API response
 *  'Content-Type' header signals a JSON response
 */

class ObjectError extends Error {
  constructor(status, statusText) {
    super()
    this.name = OBJECT_ERROR_NAME
    this.status = status
    this.statusText = statusText
    this.message = `${status} - ${statusText}`
  }
}

export { ObjectError }
