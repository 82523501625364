import { MessageBar } from '@fluentui/react/lib/MessageBar'
import { MessageBarType } from '@fluentui/react'
import { FlexRow } from '../../components/layout/FlexContainer'
import { Spinner } from '@fluentui/react/lib/Spinner'
import { Section } from './ItemDetailSubcomponents'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { itemHelper, linkDependencyHelper, stateHelper } from '../../common/src/helpers'
import { useTranslation } from 'react-i18next'

export const ItemConflicts = ({ iAmPremium, conflicts, loading, error }) => {
  const { type, content } = useConflictsMessageBar({ iAmPremium, loading, error, conflicts })
  return (
    <Section>
      <MessageBar messageBarType={type}>{content}</MessageBar>
    </Section>
  )
}

const ConflictList = styled.ul`
  list-style-type: none;
  padding-left: 0;
`

const CircularDependencyLink = ({ link }) => {
  const { t } = useTranslation()
  const parentItem = useSelector(state => stateHelper.getItem(state, link.parent))
  const blocksItem = useSelector(state => stateHelper.getItem(state, link.blocks))
  return (
    <li>
      {t('item_detail.conflicts.circular_dependency_link', {
        parent: itemHelper.getName(parentItem),
        blocks: itemHelper.getName(blocksItem),
      })}
    </li>
  )
}

const CircularDependencyConflict = ({ conflict }) => {
  const { links } = conflict
  const { t } = useTranslation()
  return (
    <li>
      <strong>{t('item_detail.conflicts.circular_dependency')}</strong> {t('item_detail.conflicts.links_involved')}
      <br />
      <ul>
        {links.map(link => (
          <CircularDependencyLink key={link.link} link={link} />
        ))}
      </ul>
    </li>
  )
}

const LINK_DATES_TRANSLATION_KEY = {
  [linkDependencyHelper.DEPENDENCY.BLOCKS]: 'item_detail.conflicts.link_dates_explanation_blocks',
  [linkDependencyHelper.DEPENDENCY.BLOCKED_BY]: 'item_detail.conflicts.link_dates_explanation_blocked_by',
  [linkDependencyHelper.DEPENDENCY.CAUSES]: 'item_detail.conflicts.link_dates_explanation_causes',
  [linkDependencyHelper.DEPENDENCY.CAUSED_BY]: 'item_detail.conflicts.link_dates_explanation_caused_by',
}

const LinkDatesConflict = ({ conflict }) => {
  const { t } = useTranslation()
  const conflictingItem = useSelector(state => stateHelper.getItem(state, conflict?.item_id))
  return (
    <li>
      <strong>{t('item_detail.conflicts.link_dates')}</strong>
      <br />
      <span>
        {t(LINK_DATES_TRANSLATION_KEY[conflict.dependency], { item_name: itemHelper.getName(conflictingItem) })}
      </span>
    </li>
  )
}

const Conflict = ({ conflict }) => {
  if (conflict.type === CONFLICT_TYPES.CIRCULAR_DEPENDENCY) return <CircularDependencyConflict conflict={conflict} />
  return <LinkDatesConflict conflict={conflict} />
}

const useConflictsMessageBar = ({ iAmPremium, loading, error, conflicts }) => {
  const { t } = useTranslation()
  if (!iAmPremium) {
    return { type: MessageBarType.severeWarning, content: t('item_detail.conflicts.not_premium') }
  }
  if (loading) {
    return {
      type: MessageBarType.info,
      content: (
        <FlexRow>
          <span>{t('item_detail.conflicts.loading')}</span>
          <Spinner />
        </FlexRow>
      ),
    }
  }
  if (conflicts?.conflicts?.length === 0) {
    return { type: MessageBarType.success, content: t('item_detail.conflicts.no_conflicts') }
  }
  return {
    type: MessageBarType.severeWarning,
    content: (
      <>
        <span>{t('item_detail.conflicts.found_conflicts')}</span>
        <ConflictList>
          {conflicts?.conflicts?.map((conflict, index) => (
            <Conflict key={index} conflict={conflict} />
          ))}
        </ConflictList>
      </>
    ),
  }
}

const CONFLICT_TYPES = {
  CIRCULAR_DEPENDENCY: 'CIRCULAR_DEPENDENCY',
  LINK_DATES: 'LINK_DATES',
}
