import { useMemo } from 'react'
import { QuadrantSelector } from '../input/quadrant/QuadrantSelector'
import { projectHelper } from '@/common/src/helpers'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { cn } from '@/modules/classnames'

export const QuadrantMenuItem = ({ project, quadrant, quadrantSize = 18, text = '', ...rest }) => {
  const { t } = useTranslation()
  const name = useMemo(() => {
    if (text) {
      return text
    }
    if (project === 'keep' || !project) {
      return t('project.quadrant') + ' ' + (quadrant + 1)
    }
    return projectHelper.getSafeQuadrantName(project, quadrant)
  }, [project, quadrant, t, text])

  return (
    <div {...rest} className={cn('flex min-w-0 items-center px-1', rest.className)}>
      {_.isNumber(quadrant) && (
        <QuadrantSelector
          size={quadrantSize}
          project={project === 'keep' ? undefined : project}
          quadrant={quadrant}
          isItem={true}
          readOnly={true}
        />
      )}
      <span className="ml-2 inline-block flex-grow truncate">{name}</span>
    </div>
  )
}
