import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { sharingRequestHelper, stateHelper, userHelper } from '../helpers'
import { useMe } from './usersHooks'

export const useSharingRequestsForUser = user => {
  const sharingRequestsMap = useSelector(state => stateHelper.getSharingRequestsMap(state))
  const email = userHelper.getEmail(user)
  return useMemo(() => {
    return sharingRequestsMap
      .toList()
      .flatMap(m => m.toList())
      .filter(r => sharingRequestHelper.getTargetUsername(r) === email)
  }, [email, sharingRequestsMap])
}

export const useSharingRequestsForMe = () => {
  const me = useMe()
  return useSharingRequestsForUser(me)
}
