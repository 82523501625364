import { useCallback, useContext, useEffect, useRef } from 'react'
import { useCreateItemModal } from '@/hooks/useCreateItemModal'
import { EVENT_EXTRA } from '@/common/src/eventTracking/amplitudeEvents'
import { getFileToShare } from '@/utils/ExpoCommunication'
import { FileContext } from '@/contexts'
import { getFilenameData } from '@/utils'
import { useCreateNewItem } from '@/common/src/hooks/itemHooks'
import { useHistory } from 'react-router'
import { getRelativeURLKeepingQuerySearch } from '@/helpers/routeHelper'
import { itemHelper } from '@/common/src/helpers'

export const ShareIntentListener = () => {
  const createItem = useCreateItemModal()
  const alreadyCheckedSearchParam = useRef(false)
  const { uploadFiles } = useContext(FileContext)
  const createNewItem = useCreateNewItem()
  const history = useHistory()

  const createItemWithFiles = useCallback(
    async files => {
      const fileName = files && files[files.length - 1]?.name
      const name = fileName
      if (!name) {
        return
      }

      const extension = fileName && getFilenameData(fileName)?.extension

      const response = await createNewItem({
        name: name,
        quadrant: 0,
        projectID: undefined,
        eventProps: {
          mode: EVENT_EXTRA.CREATE_ITEM.MODE.MATRIX_DRAG_DROP,
          quadrant: 0,
          fileExtension: extension,
        },
      })
      const { error, payload } = response
      if (!error && files.length) {
        uploadFiles({ item: payload, files })
      }
      history.replace(getRelativeURLKeepingQuerySearch.inboxWithItem(itemHelper.getId(payload)))
    },
    [createNewItem, history, uploadFiles]
  )

  useEffect(() => {
    if (alreadyCheckedSearchParam.current) return
    alreadyCheckedSearchParam.current = true
    const searchParams = new URLSearchParams(window.location.search)
    const shareTextParam = searchParams.get('shareText')
    if (shareTextParam) {
      createItem({
        mode: EVENT_EXTRA.CREATE_ITEM.MODE.SHARE_INTENT,
        notes: shareTextParam,
        displayProjectSelector: true,
      })
    }
    const shareFilePath = searchParams.get('shareFilePath')
    if (shareFilePath) {
      getFileToShare({
        fileURI: shareFilePath,
        fileName: searchParams.get('shareFileName'),
        fileType: searchParams.get('shareFileType'),
      })
      window.shareFile = (fileName, dataUrl) => {
        ;(async function () {
          const res = await fetch(dataUrl)
          const blob = await res.blob()
          createItemWithFiles([new File([blob], fileName, { type: /data:(.*);/.exec(dataUrl)[1] })])
        })()
      }
    } else {
      window.shareFile = undefined
    }
  }, [createItem, createItemWithFiles])
}
