export const useSingleAndDoubleClick = (onClick, onDoubleClick) => {
  return event => {
    if (event.detail === 1) {
      onClick(event)
    } else {
      event.preventDefault()
      onDoubleClick(event)
    }
  }
}
