import React, { memo, useMemo } from 'react'
import { cn } from '@appfluence/classnames'
import { FontWeights, mergeStyleSets } from '@fluentui/react/lib/Styling'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { useTheme } from 'styled-components'

const useClassNames = narrow => {
  const theme = useTheme()
  return useMemo(() => {
    return mergeStyleSets({
      tileStructure: [
        {
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
          height: '192px',
          width: '152px',
          minWidth: '152px',
          position: 'relative',
          borderRadius: '30px',
          boxSizing: 'border-box',
          padding: '16px 0',
          color: theme.palette.black,
          '@media (hover: hover) and (pointer: fine)': {
            ':hover': {
              backgroundColor: theme.palette.neutralLight,
            },
          },
        },
        narrow && {
          height: '142px',
          width: '102px',
          minWidth: '102px',
          padding: '12px 0',
          borderRadius: '12px',
        },
      ],
      tileStructureName: [
        theme.fonts.medium,
        {
          display: 'block',
          width: '100%',
          maxHeight: '32px',
          lineHeight: '16px',

          boxSizing: 'border-box',
          marginTop: '4px',
          padding: '0 12px',

          fontWeight: FontWeights.semibold,
          overflow: 'hidden',
          textAlign: 'center',
          textOverflow: 'ellipsis',
          webkitBoxOrient: 'vertical',
          webkitLineClamp: 2,
        },
        narrow && {
          marginTop: '6px',
          padding: '0 8px',
        },
      ],
      tileStructureChildren: [
        {
          display: 'flex',
          justifyContent: 'center',
          width: '120px',
          height: '120px',
        },
        narrow && {
          width: '80px',
          height: '80px',
        },
      ],
    })
  }, [theme, narrow])
}

export const TileStructure = memo(({ name, className, childrenContainerClassName, children, ...rest }) => {
  const narrow = useNarrowWidth()
  const { tileStructure, tileStructureChildren, tileStructureName } = useClassNames(narrow)
  const containerClassName = cn(tileStructure, className)
  return (
    <div className={containerClassName} {...rest}>
      <div className={cn(tileStructureChildren, childrenContainerClassName)}>{children}</div>
      {name && <div className={tileStructureName}>{name}</div>}
    </div>
  )
})
