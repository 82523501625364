import { motion } from 'framer-motion'
import { useTheme } from 'styled-components'
import { concatStyleSetsWithProps, ActionButton, IconButton } from '@fluentui/react'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { ShortcutTooltipHost } from '../../tooltip/ShortcutTooltipHost'

const baseStyles = ({ height }) => ({
  root: {
    height: `${height}px`,
  },
  menuIcon: {
    display: 'none !important',
  },
})

const narrowStyles = {
  root: {
    width: '24px',
  },
  icon: {
    fontSize: '20px',
    marginLeft: '0px',
    marginRight: '0px',
  },
}

const widerStyles = props => ({
  root: {
    background: props.theme.palette.themePrimary,
    fontWeight: 'bolder',
  },
  label: {
    color: props.theme.palette.white,
    whiteSpace: 'nowrap',
  },
  icon: {
    fontSize: 'inherit',
    color: props.theme.palette.white,
  },
  iconHovered: {
    color: props.theme.palette.white,
  },
  iconPressed: {
    color: props.theme.palette.white,
  },
  flexContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContainer: {
    height: 'auto',
  },
})

export const ResponsiveButton = ({ styles, tooltipKeys, tooltipContent, alwaysWide = false, height = 24, ...rest }) => {
  const theme = useTheme()
  const narrowScreen = useNarrowWidth()
  const narrowWidth = alwaysWide ? false : narrowScreen
  const CustomButton = narrowWidth ? IconButton : ActionButton
  const finalStyles = concatStyleSetsWithProps(
    { theme },
    baseStyles({ height }),
    narrowWidth ? narrowStyles : widerStyles,
    styles
  )
  const button = <CustomButton styles={finalStyles} {...rest} />
  const withTooltip = tooltipContent && !narrowWidth
  return (
    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }} style={{ display: 'inline-block' }}>
      {withTooltip ? (
        <ShortcutTooltipHost content={tooltipContent} tooltipKeys={tooltipKeys}>
          {button}
        </ShortcutTooltipHost>
      ) : (
        button
      )}
    </motion.div>
  )
}
