export const KEY_CODE = {
  ENTER: 13,
  D: 68,
  F: 70,
  K: 75,
  R: 82,
  F3: 114,
}

export const isSearchKeyPress = e =>
  e.keyCode === KEY_CODE.F3 || (e.ctrlKey && e.keyCode === KEY_CODE.F) || (e.metaKey && e.keyCode === KEY_CODE.F)

export const isEnter = evt => evt.keyCode === KEY_CODE.ENTER
