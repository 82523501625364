const mailLinkRegex = /^message:<(?<id>.*)>$/

export const isAppleMailLink = (link: string): boolean => {
  return !!extractAppleMailId(link)
}

export const extractAppleMailId = (link: string): string | undefined => {
  const decoded = decodeURIComponent(link)
  const match = decoded.match(mailLinkRegex)
  const id = match?.groups?.id
  return id
}
