import React, { memo } from 'react'
import { Icon } from '@fluentui/react/lib/Icon'
import { Image } from '@fluentui/react/lib/Image'
import { QuadrantSelector } from '../../../components/input/quadrant/QuadrantSelector'
import { helperByProjectTemplateType, PROJECT_TEMPLATE_TYPE } from '../ProjectTemplateType'

const classNames = {
  container: 'ProjectCards-container',
  blank: 'ProjectCards-black',
}

export const ProjectCards = memo(({ type = PROJECT_TEMPLATE_TYPE.CUSTOM, template, className, size = 40, ...rest }) => {
  const helper = helperByProjectTemplateType[type]
  return (
    <div
      className={`${classNames.container} box-border flex items-center justify-center self-start rounded-md p-2 ${
        className ?? ''
      }`}
      {...rest}
    >
      {type === PROJECT_TEMPLATE_TYPE.CUSTOM ? (
        <QuadrantSelector size={size} readOnly={true} />
      ) : (
        <Image src={helper.getImage(template)} width={size} height={size} />
      )}
    </div>
  )
})

export const BlankProjectCard = memo(({ className, ...rest }) => {
  return (
    <div
      className={`${classNames.container} box-border flex items-center justify-center self-start rounded-md p-2 ${
        className ?? ''
      }`}
      {...rest}
    >
      <div
        className={`${classNames.blank} flex h-full w-full items-center justify-center border-2 border-dashed text-3xl text-pm-neutral-primary md:text-4xl`}
      >
        <Icon iconName={'CircleAddition'} />
      </div>
    </div>
  )
})
