export const ITEMS_REDUCER_KEYS = {
  ITEMS: 'ITEMS',
  BY_ID: 'byId',
  MODIFIED: 'modified',
  UNSYNCED: 'unsynced',
  ACCESS_DENIED: 'accessDenied',
  INBOX_PLUS: 'inboxPlus',
  ME_EMAIL: 'me_email',
  ATTENTION_NEEDED: 'attention',
}
