import type { FC } from 'react'
import logo from '../resources/images/pm-128.png'
import logoSquared48 from '../resources/images/pm_icon_48.png'
import { useConfig } from '../queries/config'

const LogoServerSrc = '/static/pm_icon_90.png'

interface IProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  size?: 'default' | 'squared48'
}

export const PMLogo: FC<IProps> = ({ size = 'default', ...props }) => {
  const isCustom = useConfig().data?.custom
  const sized = size === 'default' ? logo : logoSquared48
  const src = isCustom ? LogoServerSrc : sized
  return <img src={src} alt="logo" {...props} />
}
