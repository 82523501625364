import { memo, useCallback, useMemo, useEffect, useId, useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { addDays } from 'date-fns'
import { bindActionCreators } from 'redux'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Checkbox,
  Dropdown,
  Field,
  Option,
  Radio,
  RadioGroup,
  SearchBox,
  Slider,
} from '@fluentui/react-components'
import { Label } from '../../components/text/Text'
import { ITEM_FILTER_SECTION_KEY } from '../../common/src/reducers/filtersKeys'
import {
  CONDITION_KEYS,
  ITEMS_DATE_TYPE,
  ITEMS_SORT_TYPE,
  setDefaultFilterItems,
  setItemsDateTypeFilter,
  setItemsEndDateTimestampFilter,
  setItemsFollowedFilter,
  setItemsOwnerFilter,
  setItemsProgressFilter,
  setItemsSortType,
  setItemsStartDateTimestampFilter,
  setItemsStateFilter,
  setItemsTagsFilter,
  setItemsTextFilter,
  setItemProjectFilter,
  setFrequentItemFilter,
  STATE_FILTER,
  FREQUENT_ITEM_FILTERS,
  setItemsQuadrantFilter,
  setItemsSortAscending,
  setItemsStarredFirst,
  TAGS_MODE_KEYS,
  setItemTagsModeFilter,
  setItemStarredProjectFilter,
  setItemsGroupByFilter,
  GROUP_BY,
  setItemsNoOwnerFilter,
  PROJECTS_MODE_KEYS,
  setItemProjectsMode,
  setItemsTextModeFilter,
  TEXT_MODE_KEYS,
} from '../../common/src/actions/filtersActions'
import {
  getItemsDateTypeFilter,
  getItemsEndDateTimestampFilter,
  getItemsFollowedFilter,
  getItemsOwnersFilter,
  getItemsProgressConditionFilter,
  getItemsProgressFilter,
  getItemsSortType,
  getItemsStartDateTimestampFilter,
  getItemsStateFilter,
  getItemsTagsFilter,
  getItemsTextFilter,
  getItemsProjectFilter,
  isFilteringItems,
  getItemsQuadrantFilter,
  getItemsSortAscending,
  getItemsStarredFirst,
  getItemsTagsModeFilter,
  getItemsStarredProjectFilter,
  getItemsGroupByFilter,
  getItemsNoOwnerFilter,
  getItemsProjectsMode,
  getItemsTextModeFilter,
} from '../../common/src/selectors/filtersSelectors'
import { stateHelper, userHelper } from '../../common/src/helpers'
import { FiltersView } from './FiltersView'
import { SuggestionsTagPicker } from '@/components/pickers/SuggestionsTagPicker'
import { useUsersForEmails } from '../../common/src/hooks/usersHooks'
import { useOrganizedUsersOptions } from '../../hooks/useUsersOption'
import { itemTagsMapSelector } from '../../common/src/selectors/tagsSelectors'
import { ErrorText } from '../../components/text/ErrorText'
import { useMergeState } from '../../common/src/hooks/enhancedHooks'
import { useDatePickerStrings } from '../../hooks/dateHooks'
import { useTranslation } from 'react-i18next'
import { LocalizedDatePickerV9 } from '../../components/pickers/LocalizedDatePicker'
import { ResponsiveButton } from '../../components/buttons/ResponsiveButton'
import { useRouteMatch } from 'react-router'
import { getRelativeURL } from '../../helpers/routeHelper'
import { useTextSearch } from '../../hooks/useTextSearch'
import { UsersPanel } from '../../components/users/panels/UsersPanel'
import { UsersCollection } from '../../components/users/usersCollection/UsersCollection'
import { QuadrantsDropdownV9 } from '../../components/dropdown/QuadrantsDropdown'
import { getMatchParameterInteger } from '../../common/src/utils'
import { getAllCustomFiltersIfNeeded } from '../../common/src/actions/combinedAPI'
import { postCustomFilter } from '../../common/src/actions/customFiltersAPI'
import { fromJS, List } from 'immutable'
import { ItemCustomFilters } from './ItemCustomFilters'
import { getFollowedItemsInProjectIfNecessary } from '../../common/src/actions/combinedAPI'
import { useSelectedProjectId } from '../../hooks/PMHooks'
import { Sections } from './ItemFiltersView.Common'
import { cn } from '@/modules/classnames'
import { ArrowDownFilled, ArrowUpFilled } from '@fluentui/react-icons'
import { ProjectsDropdownMultiSelect } from '@/components/dropdown/ProjectsDropdownMultiSelect'
import { ItemColumnSelectorDialog } from '@/components/modal/ItemColumnSelectorDialog'

const SErrorText = styled(ErrorText)`
  padding: 2px 0;
`

const extraStyles = props => ({
  root: {
    background: 'transparent',
  },
  label: {
    color: props.theme.palette.themePrimary,
  },
  flexContainer: {
    margin: '4px 4px 4px 4px',
  },
})

export const frequentFilterButtonExtraStyles = extraStyles

const FrequentFiltersContainer = styled.div`
  margin-bottom: 10px;
`

const subtractOneDay = date => addDays(date, -1)
const addOneDay = date => addDays(date, 1)

const AllowedFrequentItems = {
  [ITEM_FILTER_SECTION_KEY.GLOBAL_CALENDAR]: new Set([FREQUENT_ITEM_FILTERS.STARRED, FREQUENT_ITEM_FILTERS.MY_ITEMS]),
  [ITEM_FILTER_SECTION_KEY.GLOBAL_GANTT]: new Set([FREQUENT_ITEM_FILTERS.STARRED, FREQUENT_ITEM_FILTERS.MY_ITEMS]),
}

export const useFrequentFilterData = filtersType => {
  const dispatch = useDispatch()
  return useMemo(() => {
    const onClickCreator = fname => () => dispatch(setFrequentItemFilter(fname, filtersType))
    let sortedKeys = [
      FREQUENT_ITEM_FILTERS.OVERDUE,
      FREQUENT_ITEM_FILTERS.STARRED,
      FREQUENT_ITEM_FILTERS.MY_ITEMS,
      FREQUENT_ITEM_FILTERS.THIS_WEEK,
      FREQUENT_ITEM_FILTERS.MODIFIED_SINCE_YESTERDAY,
      FREQUENT_ITEM_FILTERS.NEXT_7_DAYS,
    ]
    if (AllowedFrequentItems[filtersType]) {
      const allowedSet = AllowedFrequentItems[filtersType]
      sortedKeys = sortedKeys.filter(k => allowedSet.has(k))
    }
    const data = {
      [FREQUENT_ITEM_FILTERS.OVERDUE]: {
        onClick: onClickCreator(FREQUENT_ITEM_FILTERS.OVERDUE),
        id: 'FrequentFilters_due_today',
        nameKey: 'item_filters.overdue_or_today',
      },
      [FREQUENT_ITEM_FILTERS.THIS_WEEK]: {
        onClick: onClickCreator(FREQUENT_ITEM_FILTERS.THIS_WEEK),
        id: 'FrequentFilters_due_this_week',
        nameKey: 'item_filters.due_this_week',
      },
      [FREQUENT_ITEM_FILTERS.STARRED]: {
        onClick: onClickCreator(FREQUENT_ITEM_FILTERS.STARRED),
        id: 'FrequentFilters_starred',
        nameKey: 'item_filters.starred',
      },
      [FREQUENT_ITEM_FILTERS.MODIFIED_SINCE_YESTERDAY]: {
        onClick: onClickCreator(FREQUENT_ITEM_FILTERS.MODIFIED_SINCE_YESTERDAY),
        id: 'FrequentFilters_modified_since_yesterday',
        nameKey: 'item_filters.modified_since_yesterday',
      },
      [FREQUENT_ITEM_FILTERS.MY_ITEMS]: {
        onClick: onClickCreator(FREQUENT_ITEM_FILTERS.MY_ITEMS),
        id: 'FrequentFilters_my_items',
        nameKey: 'item_filters.my_items',
      },
      [FREQUENT_ITEM_FILTERS.NEXT_7_DAYS]: {
        onClick: onClickCreator(FREQUENT_ITEM_FILTERS.NEXT_7_DAYS),
        id: 'FrequentFilters_next_7_days',
        nameKey: 'item_filters.next_7_days',
      },
    }
    return {
      sortedKeys,
      data,
    }
  }, [dispatch, filtersType])
}

const FrequentFilters = memo(({ filtersType }) => {
  const { t } = useTranslation()
  const { data, sortedKeys } = useFrequentFilterData(filtersType)
  const elements = _.map(sortedKeys, k => {
    const d = data[k]
    return (
      <ResponsiveButton alwaysWide styles={extraStyles} key={k} onClick={d.onClick} id={d.id}>
        {t(d.nameKey)}
      </ResponsiveButton>
    )
  })
  return <FrequentFiltersContainer>{elements}</FrequentFiltersContainer>
})

const SUsersCollection = styled(UsersCollection)`
  margin-top: 2px !important;
`

const ItemFiltersView_ = ({
  showSelectColumnsButton,
  filtersType,
  isFilteringItems,
  sortType,
  sortAscending,
  starredFirst,
  sortTypeBlacklist,
  hideSections = [],
  textFilter,
  textModeFilter,
  stateFilter,
  ownerFilter,
  noOwnerFilter,
  followedFilter,
  dateType,
  startDateTimestampFilter,
  endDateTimestampFilter,
  progressCondition,
  progress,
  tagsFilter,
  collaborators,
  tags,
  tagsMode,
  commonFilters,
  itemProjectFilter,
  projectsMode,
  starredProjectFilter,
  quadrantFilter,
  groupBy,
  setDefaultFilter,
  setSortType,
  setSortAscending,
  setStarredFirst,
  setTextFilter,
  setTextModeFilter,
  setStateFilter,
  setOwnerFilter,
  setNoOwnerFilter,
  setFollowedFilter,
  setItemsDateTypeFilter,
  setItemsStartDateTimestampFilter,
  setItemsEndDateTimestampFilter,
  setItemsProgressFilter,
  setTagsFilter,
  setTagsModeFilter,
  setItemsCommonFilters,
  setItemProjectFilter,
  setProjectsModeFilter,
  setQuadrantFilter,
  setItemStarredProjectFilter,
  setGroupByFilter,
  ...rest
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllCustomFiltersIfNeeded())
  }, [dispatch])

  const [state, setState] = useMergeState({
    tagsFilterError: null,
    isOpenOwnerPanel: false,
  })

  const [isColumnSelectorDialogOpen, setIsColumnSelectorDialogOpen] = useState(false)

  const selectedProjectId = useSelectedProjectId()
  const { t } = useTranslation()

  const datePickerStringsGetter = useDatePickerStrings()

  const match = useRouteMatch()
  const pid = getMatchParameterInteger(match, 'pid')
  const project = useSelector(state => stateHelper.getProject(state, pid))

  const textModeLabelId = useId()
  const sortLabelId = useId()
  const groupByLabelId = useId()
  const stateLabelId = useId()
  const quadrantLabelId = useId()
  const dateLabelId = useId()
  const progressLabelId = useId()

  // Text search
  const { textSearch, onSearchBoxChange } = useTextSearch({ setTextFilter, globalTextSearch: textFilter })

  // Owner
  const ownerFilterArray = useMemo(() => ownerFilter.toJS(), [ownerFilter])
  const owners = useUsersForEmails(ownerFilterArray)
  const usersOptions = useOrganizedUsersOptions()
  const isSearchView = !!useRouteMatch(getRelativeURL.searchApp())
  const inMeeting = !!useRouteMatch(getRelativeURL.meeting())
  // Dates
  const startDateFilter = useMemo(() => {
    return startDateTimestampFilter ? new Date(startDateTimestampFilter * 1000) : null
  }, [startDateTimestampFilter])
  const endDateFilter = useMemo(() => {
    return endDateTimestampFilter ? subtractOneDay(new Date(endDateTimestampFilter * 1000)) : null
  }, [endDateTimestampFilter])

  // Tags
  const selectedTags = tagsFilter.toArray()

  // Options
  const sortOptions = useMemo(() => {
    return {
      [ITEMS_SORT_TYPE.INDEX]: t('item_filters.manually'),
      [ITEMS_SORT_TYPE.NAME]: t('item_filters.alphabetically'),
      [ITEMS_SORT_TYPE.LAST_MODIFIED_TIMESTAMP]: t('item_filters.modification_date'),
      [ITEMS_SORT_TYPE.ICON]: t('item_filters.icon'),
      [ITEMS_SORT_TYPE.DUE_DATE]: t('item_filters.due_date'),
      [ITEMS_SORT_TYPE.OWNER_USERNAME]: t('item_filters.by_owner'),
      [ITEMS_SORT_TYPE.CREATOR_USERNAME]: t('item_filters.by_creator'),
      [ITEMS_SORT_TYPE.START_DATE]: t('item_filters.start_date'),
      [ITEMS_SORT_TYPE.COMPLETION]: t('item_filters.most_completed'),
      [ITEMS_SORT_TYPE.CREATION_DATE]: t('item_filters.by_creation_date'),
      [ITEMS_SORT_TYPE.COMPLETION_DATE]: t('item_filters.by_completion_date'),
      [ITEMS_SORT_TYPE.EFFORT]: t('item_filters.effort'),
    }
  }, [t])

  const dateTypeOptions = useMemo(() => {
    return {
      [ITEMS_DATE_TYPE.LAST_MODIFIED_DATE]: t('item_filters.date_type_modified_date'),
      [ITEMS_DATE_TYPE.DUE_DATE]: t('item_filters.date_type_due_date'),
      [ITEMS_DATE_TYPE.START_DATE]: t('item_filters.date_type_start_date'),
    }
  }, [t])

  const stateOptions = useMemo(() => {
    return {
      [STATE_FILTER.UNFINISHED]: t('item_filters.unfinished_items'),
      [STATE_FILTER.FINISHED]: t('item_filters.finished_items'),
      [STATE_FILTER.ALL_ITEMS]: t('item_filters.all_items'),
    }
  }, [t])

  const onClear = () => {
    setDefaultFilter()
  }

  const onClearText = () => {
    setTextFilter('')
  }

  const onChangeSortType = (event, data) => {
    setSortType(data.selectedOptions[0])
  }

  const onChangeTextMode = (event, data) => {
    setTextModeFilter(data.selectedOptions[0])
  }

  const onChangeStateFilter = (event, data) => {
    setStateFilter(data.selectedOptions[0])
  }

  const onChangeFollowedFilter = (event, data) => {
    setFollowedFilter(data.checked)
    if (data.checked) {
      dispatch(getFollowedItemsInProjectIfNecessary(selectedProjectId))
    }
  }

  const onChangeStarredFirst = (event, data) => {
    setStarredFirst(data.checked)
  }

  const showOwnerPanel = useCallback(() => {
    setState({ isOpenOwnerPanel: true })
  }, [setState])

  const hideOwnerPanel = useCallback(() => {
    setState({ isOpenOwnerPanel: false })
  }, [setState])

  const onAddOwnerFilter = user => {
    const email = user ? userHelper.getEmail(user) : ''
    const currentOwners = ownerFilter.toArray()
    const isIncluded = currentOwners.indexOf(email) !== -1
    const newOwners = isIncluded ? currentOwners : [...currentOwners, email]
    setOwnerFilter(newOwners)
  }

  const onRemoveOwnerFilter = user => {
    const email = user ? userHelper.getEmail(user) : ''
    const currentOwners = ownerFilter.toArray()
    const newOwners = _.filter(currentOwners, ownerEmail => ownerEmail !== email)
    setOwnerFilter(newOwners)
  }

  const onDateTypeChange = (ev, data) => {
    const dateKey = data.selectedOptions[0]
    setItemsDateTypeFilter(dateKey)
  }

  const onSelectStartDate = date => {
    if (!date) setItemsStartDateTimestampFilter(null)
    else setItemsStartDateTimestampFilter(date.getTime() / 1000)
  }

  const onSelectEndDate = date => {
    if (!date) setItemsEndDateTimestampFilter(null)
    else setItemsEndDateTimestampFilter(addOneDay(date).getTime() / 1000)
  }

  const onChangeItemProjectFilter = data => {
    setItemProjectFilter(List(data))
  }

  const onChangeStarredProjectFilter = (event, data) => {
    setItemStarredProjectFilter(data.checked)
  }

  const progressSliderValueFormat = useCallback(value => {
    return `${value >= 0 ? '≥' : '≤'} ${Math.abs(value)}%`
  }, [])

  const onChangeProgress = useCallback(
    (event, data) => {
      setItemsProgressFilter(data.value, CONDITION_KEYS.GREATER_THAN)
    },
    [setItemsProgressFilter]
  )

  const onInsertTagFilter = tagName => {
    const currentTagNames = tagsFilter.toArray()
    const isIncluded = currentTagNames.indexOf(tagName) !== -1
    const newTagNames = isIncluded ? currentTagNames : [...currentTagNames, tagName]
    setTagsFilter(newTagNames)
  }

  const onRemoveTagFilter = removedTagName => {
    const currentTagNames = tagsFilter.toArray()
    _.remove(currentTagNames, tagName => tagName === removedTagName)
    setTagsFilter(currentTagNames)
  }

  const saveCustomFilter = name => {
    const filter = fromJS({
      name,
      filter: {
        sort: sortType,
        sort_ascending: sortAscending,
        owners: ownerFilter,
        state: stateFilter,
        followed: followedFilter,
        quadrant: quadrantFilter,
        date_type: dateType,
        progress_condition: progressCondition,
        progress,
        tags: tagsFilter,
        text: textFilter,
        projects: itemProjectFilter,
        start_date: startDateTimestampFilter,
        end_date: endDateTimestampFilter,
      },
    })
    dispatch(postCustomFilter(filter))
  }

  const sortOptionsWhitelist = useMemo(() => {
    if (!sortTypeBlacklist) {
      return sortOptions
    }
    const whitelist = { ...sortOptions }
    sortTypeBlacklist.forEach(key => {
      delete whitelist[key]
    })
    return whitelist
  }, [sortOptions, sortTypeBlacklist])

  const groupByProjectOptions = {
    [GROUP_BY.NONE]: t('item_filters.group_by_project.none'),
    [GROUP_BY.PROJECT_ALPHABETICAL]: t('item_filters.group_by_project.alphabetically'),
  }

  const progressSliderValue = progressCondition === CONDITION_KEYS.GREATER_THAN ? progress : -progress
  const { tagsFilterError, isOpenOwnerPanel } = state
  return (
    <>
      <FiltersView
        isFiltering={isFilteringItems}
        clearTitle={t('item_filters.clear_title')}
        onClear={onClear}
        {...rest}
      >
        {showSelectColumnsButton && (
          <Button onClick={() => setIsColumnSelectorDialogOpen(true)}>
            {t('item_filters.select_columns.button_label')}
          </Button>
        )}
        <FrequentFilters filtersType={filtersType} />
        <ItemCustomFilters
          className={cn(hideSections.includes(Sections.CustomFilters) && 'hidden')}
          onSaveCustomFilter={saveCustomFilter}
          filtersType={filtersType}
          sortTypeBlacklist={sortTypeBlacklist}
        />
        <SearchBox
          id="itemFiltersView_searchBox"
          value={textSearch}
          placeholder={t('item_filters.search_bar_placeholder')}
          onClear={onClearText}
          onChange={(event, data) => onSearchBoxChange(event, data)}
          className="mt-1"
        />
        {(filtersType === ITEM_FILTER_SECTION_KEY.SEARCH || filtersType === ITEM_FILTER_SECTION_KEY.ONE_ON_ONE) && (
          <>
            <label id={textModeLabelId} className="font-semibold text-black dark:text-white">
              {t('item_filters.text_mode_label')}
            </label>
            <Dropdown
              aria-labelledby={textModeLabelId}
              selectedOptions={[textModeFilter]}
              value={t(`item_filters.text_mode.${textModeFilter}`)}
              onOptionSelect={onChangeTextMode}
            >
              {Object.keys(TEXT_MODE_KEYS).map(option => (
                <Option key={TEXT_MODE_KEYS[option]} value={TEXT_MODE_KEYS[option]}>
                  {t(`item_filters.text_mode.${TEXT_MODE_KEYS[option]}`)}
                </Option>
              ))}
            </Dropdown>
          </>
        )}
        <div className={cn(hideSections.includes(Sections.Sort) && 'hidden')}>
          <label id={sortLabelId} className="font-semibold text-black dark:text-white">
            {t('item_filters.sort_type_label')}
          </label>
          <div className="mt-2 flex gap-1">
            <Dropdown
              id="itemFiltersView_dropdownSorting"
              aria-labelledby={sortLabelId}
              selectedOptions={[sortType]}
              value={sortOptionsWhitelist[sortType]}
              onOptionSelect={onChangeSortType}
              className="!min-w-0 flex-1"
            >
              {Object.keys(sortOptionsWhitelist).map(option => (
                <Option key={option} value={option}>
                  {sortOptionsWhitelist[option]}
                </Option>
              ))}
            </Dropdown>
            <Button
              icon={sortAscending ? <ArrowUpFilled /> : <ArrowDownFilled />}
              onClick={() => setSortAscending(!sortAscending)}
            />
          </div>
        </div>
        {(filtersType === ITEM_FILTER_SECTION_KEY.SEARCH || filtersType === ITEM_FILTER_SECTION_KEY.ONE_ON_ONE) && (
          <>
            <label id={groupByLabelId} className="font-semibold text-black dark:text-white">
              {t('item_filters.group_by_project.label')}
            </label>
            <Dropdown
              aria-labelledby={groupByLabelId}
              selectedOptions={[groupBy]}
              value={groupByProjectOptions[groupBy]}
              onOptionSelect={(e, data) => setGroupByFilter(data.selectedOptions[0])}
            >
              {Object.keys(groupByProjectOptions).map(option => (
                <Option key={option} value={option}>
                  {groupByProjectOptions[option]}
                </Option>
              ))}
            </Dropdown>
          </>
        )}
        {!hideSections.includes(Sections.StarredFirst) && (
          <Checkbox
            label={{ children: t('item_filters.starred_first'), className: 'text-black dark:text-white' }}
            checked={starredFirst}
            onChange={onChangeStarredFirst}
            className={cn(hideSections.includes(Sections.StarredFirst) && 'hidden')}
          />
        )}
        <label
          id={stateLabelId}
          className={cn(hideSections.includes(Sections.State) && 'hidden', 'font-semibold text-black dark:text-white')}
        >
          {t('item_filters.state_label')}
        </label>
        <Dropdown
          aria-labelledby={stateLabelId}
          selectedOptions={[stateFilter]}
          value={stateOptions[stateFilter]}
          onOptionSelect={onChangeStateFilter}
        >
          {Object.keys(stateOptions).map(option => (
            <Option key={option} value={option}>
              {stateOptions[option]}
            </Option>
          ))}
        </Dropdown>
        <div className={cn(hideSections.includes(Sections.Quadrant) && 'hidden')}>
          <label id={quadrantLabelId} className="font-semibold text-black dark:text-white">
            {t('item_filters.quadrant_label')}
          </label>
          <QuadrantsDropdownV9
            aria-labelledby={quadrantLabelId}
            project={project}
            quadrant={quadrantFilter}
            onQuadrantChanged={setQuadrantFilter}
            disabled={false}
            enableEmpty
            textEmpty={t('item_filters.all_quadrants')}
            className="mt-2 w-full"
          />
        </div>
        <Label>{t('item_filters.owner_label')}</Label>
        <SUsersCollection
          users={owners}
          max={10}
          onClick={showOwnerPanel}
          addButtonProps={owners?.length === 0 ? { extraText: t('item_filters.select_users') } : undefined}
          disabled={noOwnerFilter}
        />
        <Checkbox
          label={{ children: t('item_filters.no_owner'), className: 'text-black dark:text-white' }}
          checked={noOwnerFilter}
          onChange={(event, data) => setNoOwnerFilter(data.checked)}
        />
        <Checkbox
          label={{ children: t('item_filters.is_followed'), className: 'text-black dark:text-white' }}
          checked={followedFilter}
          onChange={onChangeFollowedFilter}
          className={cn(hideSections.includes(Sections.Followed) && 'hidden', 'mt-0')}
        />
        <div className={cn(hideSections.includes(Sections.Dates) && 'hidden')}>
          <label id={dateLabelId} className="font-semibold text-black dark:text-white">
            {t('item_filters.date_label')}
          </label>
          <Dropdown
            aria-labelledby={dateLabelId}
            selectedOptions={[dateType]}
            onOptionSelect={onDateTypeChange}
            value={dateTypeOptions[dateType]}
            className="mt-2 w-full"
          >
            {Object.keys(dateTypeOptions).map(option => (
              <Option key={option} value={option}>
                {dateTypeOptions[option]}
              </Option>
            ))}
          </Dropdown>
          <LocalizedDatePickerV9
            id="itemFiltersView_startDatePicker"
            strings={datePickerStringsGetter(null, endDateFilter)}
            placeholder={t('item_filters.start_date_placeholder')}
            value={startDateFilter}
            maxDate={endDateFilter}
            onSelectDate={onSelectStartDate}
            className="mt-1"
          />
          <LocalizedDatePickerV9
            id="itemFiltersView_endDatePicker"
            strings={datePickerStringsGetter(startDateFilter, null)}
            placeholder={t('item_filters.end_date_placeholder')}
            value={endDateFilter}
            minDate={startDateFilter}
            onSelectDate={onSelectEndDate}
            className="mt-1"
          />
        </div>
        {(isSearchView || inMeeting) && (
          <>
            <Field label={{ className: 'font-semibold', children: t('item_filters.projects_label') }}>
              <RadioGroup
                layout="horizontal"
                value={projectsMode}
                onChange={(_, data) => setProjectsModeFilter(data.value)}
              >
                {Object.values(PROJECTS_MODE_KEYS).map(key => (
                  <Radio key={key} value={key} label={t(`item_filters.projects_mode.${key}`)} />
                ))}
              </RadioGroup>
              <ProjectsDropdownMultiSelect
                selectedProjects={itemProjectFilter.toArray()}
                onChange={onChangeItemProjectFilter}
                className="!mt-1"
              />
            </Field>
            <Checkbox
              label={{ children: t('item_filters.starred_projects'), className: 'text-black dark:text-white' }}
              checked={starredProjectFilter}
              onChange={onChangeStarredProjectFilter}
              disabled={itemProjectFilter.size > 0}
            />
          </>
        )}
        <label htmlFor={progressLabelId} className="font-semibold text-black dark:text-white">
          {t('item_filters.progress_label')} ({progressSliderValueFormat(progressSliderValue)})
        </label>
        <Slider
          id={progressLabelId}
          min={0}
          max={100}
          step={5}
          value={progressSliderValue}
          onChange={onChangeProgress}
          className="w-full"
        />
        <div>
          <Label>{t('item_filters.tags_label')}</Label>
          <RadioGroup
            layout="horizontal"
            value={tagsMode}
            onChange={(_, data) => setTagsModeFilter(data.value)}
            className="mb-1"
          >
            {Object.values(TAGS_MODE_KEYS).map(key => (
              <Radio key={key} value={key} label={t(`item_filters.tags_mode.${key}`)} />
            ))}
          </RadioGroup>
          {tagsFilterError && <SErrorText>{tagsFilterError}</SErrorText>}
          <SuggestionsTagPicker
            selectedTags={selectedTags}
            onAddTag={onInsertTagFilter}
            onRemoveTag={onRemoveTagFilter}
            type="item"
          />
        </div>
      </FiltersView>
      <UsersPanel
        users={owners}
        options={usersOptions}
        onSelectUser={onAddOwnerFilter}
        onRemoveUser={onRemoveOwnerFilter}
        textFieldPlaceholder={t('item_filters.user_panel_text_field_placeholder')}
        invitationIconProps={{ title: t('item_filters.user_panel_text_field_placeholder') }}
        isOpen={isOpenOwnerPanel}
        onDismiss={hideOwnerPanel}
        removeText={t('users.press_to_remove')}
      />
      <ItemColumnSelectorDialog
        open={isColumnSelectorDialogOpen}
        onDismiss={() => setIsColumnSelectorDialogOpen(false)}
      />
    </>
  )
}

export const ItemsFiltersView = connect(
  (state, ownProps) => {
    const filtersType = ownProps.filtersType
    return {
      isFiltering: isFilteringItems(state, filtersType),
      sortType: getItemsSortType(state, filtersType),
      sortAscending: getItemsSortAscending(state, filtersType),
      starredFirst: getItemsStarredFirst(state, filtersType),
      textFilter: getItemsTextFilter(state, filtersType),
      textModeFilter: getItemsTextModeFilter(state, filtersType),
      stateFilter: getItemsStateFilter(state, filtersType),
      ownerFilter: getItemsOwnersFilter(state, filtersType),
      noOwnerFilter: getItemsNoOwnerFilter(state, filtersType),
      followedFilter: getItemsFollowedFilter(state, filtersType),
      dateType: getItemsDateTypeFilter(state, filtersType),
      startDateTimestampFilter: getItemsStartDateTimestampFilter(state, filtersType),
      endDateTimestampFilter: getItemsEndDateTimestampFilter(state, filtersType),
      progressCondition: getItemsProgressConditionFilter(state, filtersType),
      progress: getItemsProgressFilter(state, filtersType),
      tagsFilter: getItemsTagsFilter(state, filtersType),
      collaborators: stateHelper.getCollaboratorsMap(state, filtersType),
      tags: itemTagsMapSelector(state),
      tagsMode: getItemsTagsModeFilter(state, filtersType),
      itemProjectFilter: getItemsProjectFilter(state, filtersType),
      projectsMode: getItemsProjectsMode(state, filtersType),
      starredProjectFilter: getItemsStarredProjectFilter(state, filtersType),
      quadrantFilter: getItemsQuadrantFilter(state, filtersType),
      groupBy: getItemsGroupByFilter(state, filtersType),
    }
  },
  (dispatch, ownProps) => {
    const filtersType = ownProps.filtersType
    return bindActionCreators(
      {
        setDefaultFilter: _.partialRight(setDefaultFilterItems, filtersType),
        setSortType: _.partialRight(setItemsSortType, filtersType),
        setSortAscending: _.partialRight(setItemsSortAscending, filtersType),
        setStarredFirst: _.partialRight(setItemsStarredFirst, filtersType),
        setStateFilter: _.partialRight(setItemsStateFilter, filtersType),
        setTextFilter: _.partialRight(setItemsTextFilter, filtersType),
        setTextModeFilter: _.partialRight(setItemsTextModeFilter, filtersType),
        setOwnerFilter: _.partialRight(setItemsOwnerFilter, filtersType),
        setNoOwnerFilter: _.partialRight(setItemsNoOwnerFilter, filtersType),
        setFollowedFilter: _.partialRight(setItemsFollowedFilter, filtersType),
        setItemsDateTypeFilter: _.partialRight(setItemsDateTypeFilter, filtersType),
        setItemsStartDateTimestampFilter: _.partialRight(setItemsStartDateTimestampFilter, filtersType),
        setItemsEndDateTimestampFilter: _.partialRight(setItemsEndDateTimestampFilter, filtersType),
        setItemsProgressFilter: _.partialRight(setItemsProgressFilter, filtersType),
        setTagsFilter: _.partialRight(setItemsTagsFilter, filtersType),
        setTagsModeFilter: _.partialRight(setItemTagsModeFilter, filtersType),
        setItemProjectFilter: _.partialRight(setItemProjectFilter, filtersType),
        setProjectsModeFilter: _.partialRight(setItemProjectsMode, filtersType),
        setQuadrantFilter: _.partialRight(setItemsQuadrantFilter, filtersType),
        setItemStarredProjectFilter: _.partialRight(setItemStarredProjectFilter, filtersType),
        setGroupByFilter: _.partialRight(setItemsGroupByFilter, filtersType),
      },
      dispatch
    )
  }
)(ItemFiltersView_)
