import _ from 'lodash'

export const ElementTypes = {
  paragraph: 'paragraph',
  heading1: 'heading-one',
  heading2: 'heading-two',
  blockQuote: 'block-quote',
  numberedList: 'numbered-list',
  numberedListItem: 'numbered-list-item',
  bulletedList: 'bulleted-list',
  bulletedListItem: 'bulleted-list-item',

  checkList: 'check-list',
  checkListItem: 'check-list-item',

  newText: 'new-text',
}

export const CheckListItemKeys = {
  id: 'id',
  checked: 'checked',
  url: 'url',

  // User and keys
  user: 'user',
  email: 'email',
  fullName: 'fullName',
  imageUrl: 'imageUrl',
}

export const ListItemByList = {
  [ElementTypes.numberedList]: ElementTypes.numberedListItem,
  [ElementTypes.bulletedList]: ElementTypes.bulletedListItem,
  [ElementTypes.checkList]: ElementTypes.checkListItem,
}

export const ListByListItem = _.transform(ListItemByList, (obj, value, key) => {
  obj[value] = key
})

export const LeafMarks = {
  bold: 'bold',
  italic: 'italic',
  underline: 'underline',
  code: 'code',
}

export const ShortCuts = [
  {
    regex: /^[*+-]$/,
    type: ElementTypes.bulletedListItem,
  },
  {
    regex: /^[1-9]$/,
    type: ElementTypes.numberedListItem,
  },
  {
    regex: /^\[]$/,
    type: ElementTypes.checkListItem,
  },
  {
    regex: /^[>]$/,
    type: ElementTypes.blockQuote,
  },
  {
    regex: /^#$/,
    type: ElementTypes.heading1,
  },
  {
    regex: /^#{2}$/,
    type: ElementTypes.heading2,
  },
]

export const HotKeys = {
  'mod+b': LeafMarks.bold,
  'mod+i': LeafMarks.italic,
  'mod+u': LeafMarks.underline,
  'mod+`': LeafMarks.code,
}
