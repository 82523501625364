import _ from 'lodash'
import { PM_API_RESOURCES, PM_API_URLS, PM_API_RESOURCE_TYPE } from '../constants'
import { createAction } from 'redux-api-middleware'
import { appendQueryParamsToURL, extendPMHeaders, getCredentialsConfig } from '../helpers/requestHelper'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'
import { resourceURICreator } from '../helpers/URLHelper'

export const getTeamsTab = (id, params = {}) => {
  const url = _.template(PM_API_URLS.TAB)({ id })
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.TAB, { id, params }),
    headers: extendPMHeaders(),
  })
}

export const putMeetingIdForTab = ({ id, meetingId }, params = {}) => {
  const url = _.template(PM_API_URLS.TAB)({ id })
  const meetingUri = resourceURICreator(1, PM_API_RESOURCE_TYPE.MEETING, meetingId)
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method: 'PUT',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.TAB_PUT, { id, meetingUri, params }),
    headers: extendPMHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
    }),
    body: JSON.stringify({
      pm_meeting: meetingUri,
    }),
  })
}
