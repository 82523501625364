import { memo } from 'react'
import { ProjectCards } from './ProjectCards'
import { helperByProjectTemplateType, PROJECT_TEMPLATE_TYPE } from '../ProjectTemplateType'
import { Button } from '@fluentui/react-components'
import { Dismiss } from '@/components/BundledIcons'

export const ProjectTemplateCell = memo(
  ({ type = PROJECT_TEMPLATE_TYPE.CUSTOM, template, className, onDelete, ...rest }) => {
    const helper = helperByProjectTemplateType[type]
    const name = helper.getName(template)
    const notes = helper.getNotes(template)
    const localOnDelete = evt => {
      evt.stopPropagation()
      evt.preventDefault()
      onDelete(template)
    }
    return (
      <div
        className={`group relative box-border flex items-center px-2 py-3 text-sm text-pm-black hover:cursor-pointer hover:bg-pm-neutral-quaternary ${
          className ?? ''
        }`}
        {...rest}
      >
        {onDelete && (
          <Button
            icon={<Dismiss />}
            shape="circular"
            size="small"
            onClick={localOnDelete}
            className="absolute left-1 top-1 z-10 !bg-red-700 group-hover:!flex md:!hidden"
          />
        )}
        <ProjectCards type={type} template={template} className="mr-3" />
        <div className="flex flex-1 flex-col">
          <div className="flex-1 font-semibold">{name}</div>
          {notes && <div className="line-clamp-2 flex-1">{notes}</div>}
        </div>
      </div>
    )
  }
)
