import { getMembersInConversation } from './botHelperAPI'

export const ACTION_TYPES = {
  PARSE_TEAMS_CONTEXT: 'PARSE_TEAMS_CONTEXT',
  CONSUME_DEEP_LINK: 'CONSUME_DEEP_LINK',
}

export const parseTeamsContext = context => ({
  type: ACTION_TYPES.PARSE_TEAMS_CONTEXT,
  payload: context,
})

export const getMembersInCurrentConversation = () => (dispatch, getState) => {
  const state = getState()
  const tenantId = state.getIn(['teams', 'tenantId'])
  const conversationId = state.getIn(['teams', 'conversationId'])
  if (conversationId) {
    return dispatch(getMembersInConversation(conversationId, tenantId))
  }
}

export const setDidConsumeDeepLink = () => ({
  type: ACTION_TYPES.CONSUME_DEEP_LINK,
  payload: {},
})
