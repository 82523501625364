import React from 'react'
import { cn } from '@appfluence/classnames'
import { userHelper } from '../../../common/src/helpers'
import { getAvatarURIForEmail } from '../../../common/src/constants'
import { useId } from '../../../hooks/useId'
import { Tooltip } from '@fluentui/react-components'
import { Avatar, CellContainer, Header, Table, TOTALS, UserCellContent, VIEW_KEYS, TableRow, dateToKey } from './common'
import { useTheme } from 'styled-components'
import { useCSVDownloader } from 'react-papaparse'
import { useTranslation } from 'react-i18next'
import { datefns } from '../../../utils/datefns'
import { adjustToRange } from '@/utils/generalUtils'

const countGetters = {
  [VIEW_KEYS.HOURS]: c => c?.hours ?? 0,
  [VIEW_KEYS.TASKS]: c => c?.items?.size ?? 0,
  [VIEW_KEYS.EFFORT]: c => c?.effort ?? 0,
}

const getDisplayMap = range => {
  return Object.fromEntries(range.map(m => [dateToKey(m), true]))
}

const CSVComponent = ({ className, headers, table, team, viewKey }) => {
  const { t } = useTranslation()
  const { CSVDownloader } = useCSVDownloader()
  const dataToCSV = []

  const headerElements = headers.map(colHeader => (typeof colHeader === 'string' ? colHeader : colHeaderFmt(colHeader)))
  dataToCSV.push(headerElements)

  Object.entries(table).map(([email, columns]) => {
    const name = userHelper.getFullName(team.get(email)) || userHelper.getEmail(team.get(email))
    const finalName = name || t('effort_planning.all_users')
    const colElements = Object.entries(columns).map(([_key, c]) => {
      const getCount = countGetters[viewKey]
      return getCount(c)
    })
    const returning = [finalName]
    colElements.forEach(c => returning.push(c))

    //Breaks it into an array prior to pushing so papaparse understands it
    dataToCSV.push(returning)
    return returning
  })

  return (
    <div className={cn('effort-table csv-container', className)}>
      <CSVDownloader
        data={dataToCSV}
        type="button"
        filename="Effort Planning"
        bom={true}
        className="btn-csv flex h-6 cursor-pointer items-center whitespace-nowrap rounded-sm border-none bg-pm-theme-primary px-2 py-0.5 font-bold text-pm-white shadow-none"
      >
        {t('effort_planning.export_csv')}
      </CSVDownloader>
    </div>
  )
}

const colHeaderFmt = datefns.format('iii d')

export const DataTable = ({ range, table, viewKey, onClickCell, team }) => {
  const { t } = useTranslation()
  const headers = [t('effort_planning.user'), ...range, TOTALS]
  const display = getDisplayMap(range)
  const headerElements = headers.map(colHeader => {
    if (typeof colHeader === 'string') {
      return <th key={colHeader}>{colHeader}</th>
    }
    const t = colHeaderFmt(colHeader)
    const key = dateToKey(colHeader)
    const className = cn({
      pm_hidden: !display[key],
    })
    const onClickDate = () => onClickCell(TOTALS, key, table[TOTALS][key])
    return (
      <th key={key} className={className} onClick={onClickDate}>
        {t}
      </th>
    )
  })
  const elements = Object.entries(table).map(([email, columns]) => {
    const totalsCell = columns[TOTALS]
    const colElements = Object.entries(columns).map(([colKey, c]) => {
      const className = cn({
        pm_hidden: !(display[colKey] ?? true),
      })
      return (
        <Cell
          cellData={c}
          className={className}
          key={colKey}
          viewKey={viewKey}
          onClickCell={onClickCell}
          email={email}
          columnKey={colKey}
          totalsCell={totalsCell}
        />
      )
    })
    const onClickUser = () => onClickCell(email, TOTALS, columns[TOTALS])
    return (
      <TableRow key={email}>
        <UserCell onClick={onClickUser} email={email} userData={team.get(email)} />
        {colElements}
      </TableRow>
    )
  })
  return (
    <>
      <Table>
        <thead>
          <Header>{headerElements}</Header>
        </thead>
        <tbody>{elements}</tbody>
      </Table>
      <CSVComponent className="ml-2" headers={headers} table={table} team={team} viewKey={viewKey} />
    </>
  )
}

const UserCell_ = ({ onClick, email, userData }) => {
  const id = useId('avatar')
  if (email === TOTALS) {
    return (
      <td className="p-0">
        <button className="h-full w-full cursor-pointer border-none bg-transparent p-0" onClick={onClick}>
          <Tooltip content={email} id={id}>
            <UserCellContent>
              <Avatar className="pm_transparent" />
              <span className="pm_bold">Total</span>
            </UserCellContent>
          </Tooltip>
        </button>
      </td>
    )
  }
  const name = userHelper.getFullName(userData) || userHelper.getEmail(userData)
  const avatarSrc = getAvatarURIForEmail(email)
  return (
    <td className="h-full p-0">
      <button className="h-full w-full cursor-pointer border-none bg-transparent p-0" onClick={onClick}>
        <Tooltip content={email} id={id}>
          <UserCellContent>
            <Avatar src={avatarSrc} />
            <span>{name}</span>
          </UserCellContent>
        </Tooltip>
      </button>
    </td>
  )
}

const UserCell = React.memo(UserCell_)

const adjustToPercent = adjustToRange(0, 1)
const formatter = count => {
  if (!count) {
    return '-'
  }
  return count.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 0 })
}

const Cell_ = ({ cellData, viewKey, onClickCell, email, columnKey, className, totalsCell }) => {
  const getCount = countGetters[viewKey]
  let reference
  if (email === TOTALS) {
    reference = getCount(totalsCell)
  } else {
    reference = columnKey === TOTALS ? 8 * 5 : 8
  }
  const onClick = () => onClickCell(email, columnKey, cellData)
  const theme = useTheme()
  const count = getCount(cellData)
  const p = adjustToPercent(count / reference)
  const colorPercent = 70 - p * 70
  const bgColor = `hsla(${colorPercent}, 100%, 50%, ${p / 1.7})`
  const style = {
    backgroundColor: bgColor,
    color: count ? theme.palette.black : theme.palette.neutralLight,
  }
  return (
    <CellContainer className={className} onClick={onClick} style={style}>
      {formatter(count)}
    </CellContainer>
  )
}

const Cell = React.memo(Cell_)
