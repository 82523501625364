import React, { useCallback, useState } from 'react'
import { PendingInvitationsModal } from './InvitationsModal'
import { InvitationsModalContext } from '../../contexts'

export const InvitationsModalSingleton = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const contextValue = { isOpen, setIsOpen }

  const onDismiss = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  return (
    <InvitationsModalContext.Provider value={contextValue}>
      <PendingInvitationsModal isBlocking={false} isOpen={isOpen} onDismiss={onDismiss} />
      {children}
    </InvitationsModalContext.Provider>
  )
}
