import React, { forwardRef } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { Button, CounterBadge, makeStyles, SplitButton } from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { DismissRegular, FilterRegular } from '@fluentui/react-icons'
import { cn } from '@appfluence/classnames'
import { isOpenFiltersHotKey, useHotkey } from '@/hooks/useHotkey'

const useClasses = makeStyles({
  counterBadge: {
    position: 'absolute',
    top: '-0.25rem',
    left: '0.55rem',
  },
})

export const FilterButton = forwardRef(
  ({ isFiltering, filtersCount, tooltip, onClear, className, onClick, appearance, ...rest }, ref) => {
    const classes = useClasses()
    const narrowWidth = useNarrowWidth()
    if (isFiltering === undefined && _.isNumber(filtersCount)) {
      isFiltering = filtersCount > 0
    }
    const MyButton = filtersCount && onClear && !narrowWidth ? SplitButton : Button
    const { t } = useTranslation()
    const disabled = rest.disabled ?? false

    useHotkey(isOpenFiltersHotKey, onClick)

    return (
      <Tooltip content={tooltip} relationship="description">
        <MyButton
          appearance={isFiltering ? 'primary' : appearance}
          icon={
            <span className={cn('relative flex h-full', filtersCount && !narrowWidth && 'mr-4')}>
              <FilterRegular className={classes.filterIcon} />
              <CounterBadge count={filtersCount} color="informative" className={classes.counterBadge} size="small" />
            </span>
          }
          menuButton={
            onClear
              ? {
                  onClick: ev => {
                    ev.stopPropagation()
                    onClear()
                  },
                  icon: <DismissRegular />,
                }
              : undefined
          }
          primaryActionButton={{ onClick }}
          onClick={onClick}
          className={className}
          ref={ref}
          disabled={disabled}
        >
          {narrowWidth ? false : t('item_filters.filters')}
        </MyButton>
      </Tooltip>
    )
  }
)
