import _ from 'lodash'

export const textWithoutWhitespacesAndNewlines = _.flow([
  _.partial(_.trim, _, ' \n'),
  _.partial(_.replace, _, '\n', ''),
])

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const parseEmail = e => {
  try {
    const data = _.split(e, '@')
    return {
      username: data[0],
      domain: data[1],
    }
  } catch (e) {
    return undefined
  }
}

export function validateContact(email) {
  if (!validateEmail(email)) return false
  const invalidStringEmails = ['@appfluence', 'noreply', 'no-reply', 'support']
  return !_.some(invalidStringEmails, em => _.includes(email, em))
}

// eslint-disable-next-line no-control-regex
const onlyASCIIRegExp = /^[\x00-\x7F]+$/

export const validateTag = name => {
  const trimmedName = _.trim(name, ' \n')
  return trimmedName.length && name.length === trimmedName.length && onlyASCIIRegExp.test(trimmedName)
}

// TODO: Deprecated, using KEY_CODE defined on keyboardHelper
export const KEY_CODE = {
  ENTER: 13,
}

export const caseInsensitiveIncludes = target => {
  const lowerTarget = _.toLower(target)
  return text => {
    const lowerText = _.toLower(text)
    return _.includes(lowerText, lowerTarget)
  }
}

export const sortString = (a, b) => {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}

export const sortAfterToLower = (aRaw, bRaw) => {
  const a = _.toLower(aRaw)
  const b = _.toLower(bRaw)
  return sortString(a, b)
}

export const removeTrailingMatchingChar = (str, char) => {
  const l = str.length
  if (l && str[l - 1] === char) {
    return str.substring(0, l - 1)
  }
  return str
}

export function validateURL(url) {
  try {
    new URL(url)
    return true
  } catch (_) {
    return false
  }
}
