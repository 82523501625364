import {
  addProjectTagFilter,
  addItemTagFilter,
  setProjectsTagsModeFilter,
  setItemTagsModeFilter,
  TAGS_MODE_KEYS,
} from '@/common/src/actions/filtersActions'
import { useDispatch } from 'react-redux'
import type { Tag } from '@/types'
import { useRouteId } from './useRouteId'
import { useExtendedHistory } from './useExtendedHistory'
import { ROUTE_ID } from '@/routes/routeIdList'
import { AppMatrixRoutes, getRelativeURLKeepingQuerySearch } from '@/helpers/routeHelper'
import { FILTER_REDUCER_KEYS } from '@/common/src/reducers/filtersKeys'
import { useCallback } from 'react'

type TagScope = 'project' | 'item'

export const useFilterByTag = (tagScope: TagScope) => {
  const dispatch = useDispatch()
  const routeId = useRouteId()
  const { history } = useExtendedHistory()
  return useCallback(
    (tag: Tag) => {
      const isProject = tagScope === 'project'
      const name = tag.get('name')
      if (isProject) {
        dispatch(addProjectTagFilter(name))
        dispatch(setProjectsTagsModeFilter(TAGS_MODE_KEYS.INCLUDE))
        if (routeId !== ROUTE_ID.PROJECTS) {
          history.push(getRelativeURLKeepingQuerySearch.projects())
        }
        return
      }
      const isAppProjectView = AppMatrixRoutes.has(routeId)
      const targetFilter = isAppProjectView ? FILTER_REDUCER_KEYS.PROJECT_MATRIX : FILTER_REDUCER_KEYS.SEARCH
      dispatch(addItemTagFilter(name, targetFilter))
      dispatch(setItemTagsModeFilter(TAGS_MODE_KEYS.INCLUDE, targetFilter))

      if (!isAppProjectView && routeId !== ROUTE_ID.SEARCH_APP) {
        history.push(getRelativeURLKeepingQuerySearch.searchApp())
      }
    },
    [dispatch, history, routeId, tagScope]
  )
}
