import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { ContainerPanel } from '../panels/ContainerPanel'
import { NewItem1On1 } from './NewItem1On1'
import { useTranslation } from 'react-i18next'

export const NewItem1On1Panel = memo(
  ({ headerText, onDismiss, collaborator, graphResource, mode, onItemCreated, ...rest }) => {
    const { t } = useTranslation()
    return (
      <ContainerPanel headerText={t('one_on_one.create_new_item')} onDismiss={onDismiss} {...rest}>
        <NewItem1On1
          dismiss={onDismiss}
          collaborator={collaborator}
          graphResource={graphResource}
          mode={mode}
          onItemCreated={onItemCreated}
        />
      </ContainerPanel>
    )
  }
)

NewItem1On1Panel.propTypes = {
  headerText: PropTypes.string,
  onDismiss: PropTypes.func,
  collaborator: PropTypes.string,
  graphResource: PropTypes.any,
  mode: PropTypes.string,
  onItemCreated: PropTypes.func,
}
