import { JSONContent } from '@tiptap/react'

type ListType = 'bulletList' | 'taskList'

const bulletRegex = /^(?<pre>\s*)(?<character>[-*+☑☐>])\s(?<rest>.*)/

/**
 * Detects if a line of text starts with a bullet
 * @param text
 */
const bulletDetector = (text: string) => {
  const result = bulletRegex.exec(text)
  if (result?.groups) {
    return {
      isBullet: true,
      character: result.groups.character as string,
      pre: result.groups.pre as string,
      rest: result.groups.rest as string,
    } as const
  }
  return {
    isBullet: false,
  } as const
}

const charToListType = (char: string) => {
  switch (char) {
    case '☐':
    case '☑':
      return 'taskList'
    default:
      return 'bulletList'
  }
}

/// Finds the parent node of the list where we will have to insert the item,
/// traversing the document list by list until we reach the desired level or a non-list node
const findListParent = (node: JSONContent, indents: number, depth = 1): JSONContent => {
  if (depth < indents) {
    const lastItem = node.content?.[node.content.length - 1]
    // If the last node of current list is not a list, current list (or root doc) is the parent
    if (!lastItem || !lastItem.content || !(lastItem.type === 'bulletList' || lastItem.type === 'taskList')) {
      return node
    }
    return findListParent(lastItem.content[lastItem.content.length - 1], indents, depth + 1)
  }
  return node
}

/// Finds (or creates if necessary) the taskList/bulletList node where we will insert the current item
const createOrFindListNode = (doc: JSONContent, indents: number, listType: ListType): JSONContent => {
  const listParent = findListParent(doc, indents)
  if (!listParent.content) {
    listParent.content = []
  }
  // If parent is empty or its last item is not a list, create a new list
  if (listParent.content.length === 0 || listParent.content[listParent.content.length - 1].type !== listType) {
    const list = { type: listType, content: [] }
    listParent.content.push(list)
    return list
  }
  return listParent.content[listParent.content.length - 1]
}

/// Creates the new list item given the listType, bulletData and content.
function createListItem(listType: ListType, bulletData: ReturnType<typeof bulletDetector>, paragraph: JSONContent) {
  const itemType = listType === 'taskList' ? 'taskItem' : 'listItem'
  const attrs =
    listType === 'taskList'
      ? {
          checked: bulletData.character === '☑',
        }
      : undefined
  const listItem = attrs ? { type: itemType, attrs, content: [paragraph] } : { type: itemType, content: [paragraph] }
  return listItem
}

/**
 * Converts a custom PMNotes string to a TipTap JSON document
 * @param pm custom PMNotes string
 */
export const PM2TipTap = (pm?: string): JSONContent[] => {
  if (!pm) {
    return [{ type: 'paragraph' }]
  }
  const lines = pm.split(/\r\n|\n\r|\n|\r/)
  const context = { indentLength: 1, init: false }
  const doc = lines.reduce(
    (doc, line) => {
      const bulletData = bulletDetector(line)
      const processedLine = bulletData.isBullet ? bulletData.rest : line
      const paragraphContent = processedLine ? [{ type: 'text', text: processedLine }] : undefined
      const paragraph = { type: 'paragraph', content: paragraphContent }

      if (!bulletData.isBullet) {
        doc.content.push(paragraph)
        return doc
      }
      // Initialize indent length with the first bullet/checkbox indent
      if (!context.init) {
        context.init = true
        context.indentLength = bulletData.pre.length || 1 // If indent is empty, use 1 by default
      }

      const listType = charToListType(bulletData.character)
      const listItem = createListItem(listType, bulletData, paragraph)
      const preLength = context.indentLength ? bulletData.pre.length : bulletData.pre.length + 1 // If context indent is 0, add 1
      const indents = Math.trunc(preLength / context.indentLength)
      const listNode = createOrFindListNode(doc, indents, listType) // List node where we will insert the current item

      listNode.content?.push(listItem)

      return doc
    },
    { type: 'doc', content: [] } as JSONContent & { content: JSONContent[] }
  )
  return doc.content
}
