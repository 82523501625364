import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Label } from '../../text/Text'
import { IconButton } from '@fluentui/react/lib/Button'
import { ICON_NAMES } from '../../../common/src/msIcons'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-items: flex-start;
  padding: 4px 0;
`

const Info = styled(IconButton).attrs({
  iconProps: {
    iconName: ICON_NAMES.InfoSolid,
  },
})`
  margin-left: 8px;
  font-size: larger;
`

const HeaderUsersListCell_ = ({ text, infoLink, ...rest }) => {
  return (
    <Container {...rest}>
      <Label>{text}</Label>
      {infoLink && <Info href={infoLink} target="_blank" />}
    </Container>
  )
}

export const HeaderUsersListCell = React.memo(HeaderUsersListCell_)

HeaderUsersListCell.propTypes = {
  text: PropTypes.string.isRequired,
  infoLink: PropTypes.string,
}
