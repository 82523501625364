import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from '@fluentui/react-components'
import { sanitize } from './utils'
import { useTranslation } from 'react-i18next'

export type NotesDiffDialogProps = {
  notesDiffHTML: string
}
export const NotesDiffDialog = ({ notesDiffHTML }: NotesDiffDialogProps) => {
  const { t } = useTranslation()
  return (
    <Dialog>
      <DialogTrigger>
        <span className="cursor-pointer underline">{t('item_chat.notes_diff_dialog_trigger')}</span>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t('item_chat.notes_diff_dialog_title')}</DialogTitle>
          <DialogContent>
            <div
              className="rounded border border-solid bg-neutral-100 p-4 dark:bg-neutral-900 [&_del]:dark:text-black [&_ins]:dark:text-black"
              dangerouslySetInnerHTML={{ __html: sanitize(notesDiffHTML) }}
            />
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="primary">{t('general.close')}</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
