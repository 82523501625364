import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onboardingSubject } from '../reactions'
import { currentStepSelector, isCancelledSelector, stepObjectByStepSelector } from '../selectors/onboardingSelectors'
import { onboardingStepHelper } from '../helpers'
import { sendStepEvent } from '../actions/onboardingActions'
import { useBoolean } from '../common/src/hooks/useBoolean'

export const useStepObjectByStep = step => {
  return useSelector(state => stepObjectByStepSelector(state, step))
}

export const useCurrentStepKey = () => {
  const currentStep = useSelector(currentStepSelector)
  const currentStepObject = useStepObjectByStep(currentStep)
  return currentStepObject && onboardingStepHelper.getKey(currentStepObject)
}

export const useCreateOnboardingStep = keyTarget => {
  const ref = useRef(null)
  const [isVisible, { setTrue: show, setFalse: hide }] = useBoolean(false)

  useEffect(() => {
    const subscription = onboardingSubject.subscribe(key => {
      if (key === keyTarget) {
        show()
      } else {
        hide()
      }
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [hide, show, keyTarget])

  return {
    key: keyTarget,
    ref,
    isVisible,
    showCoachmark: show,
    hideCoachmark: hide,
  }
}

export const useSendStepEventIfNeeded = () => {
  const dispatch = useDispatch()
  const isCancelled = useSelector(isCancelledSelector)
  const currentStepKey = useCurrentStepKey()

  return useCallback(
    stepKey => {
      if (isCancelled) {
        return
      }

      if (currentStepKey === stepKey) {
        dispatch(sendStepEvent(stepKey))
      }
    },
    [currentStepKey, dispatch, isCancelled]
  )
}
