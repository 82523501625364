import React, { memo, useEffect, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { MaskedTextField } from '@fluentui/react/lib/TextField'

const mask = '**:**'
const maskFormat = {
  '*': /[0-9]/,
}

const MTextField = styled(MaskedTextField).attrs({
  maskChar: '0',
  mask,
  maskFormat,
})`
  height: 32px;
  box-sizing: border-box;
`

const zerosNumberString = (number, length = 2) => _.padStart(number, length, '0')

const parseValueInHoursAndMinutes = value => {
  const values = _.split(value, ':')
  const hours = values[0] % 24
  const minutes = values[1] % 60
  return [hours, minutes]
}

export const TimeTextField = memo(({ value, onChange, ...rest }) => {
  const [time, setTime] = useState('00:00')

  useEffect(() => {
    setTime(value)
  }, [value])

  const _onChange = (event, value) => {
    const [hours, minutes] = parseValueInHoursAndMinutes(value)
    const hoursString = zerosNumberString(hours)
    const minutesString = zerosNumberString(minutes)

    setTime(`${hoursString}:${minutesString}`)
    onChange(hours, minutes)
  }

  return <MTextField value={time} onChange={_onChange} {...rest} />
})
