import { APIS } from '../constants'

export const ACTIONS = {
  ITEM_RECEIVED: APIS.PM + '/WS_ITEM',
  NOTIFICATION_RECEIVED: APIS.PM + '/WS_NOTIFICATION',
  PROJECT_RECEIVED: APIS.PM + '/WS_PROJECT',
  SHARING_REQUEST_RECEIVED: APIS.PM + '/WS_SHARING_REQUEST',
  COMMENT_RECEIVED: APIS.PM + '/WS_COMMENT',
  RECONNECTION: APIS.PM + '/WS_RECONNECTION',
}
