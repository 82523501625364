export const OnboardingStepKeys = {
  CREATE_FIRST_PROJECT: 'CREATE_FIRST_PROJECT',
  SHARE_PROJECT: 'SHARE_PROJECT',
  CREATE_ITEMS: 'CREATE_ITEMS',
  ADD_NOTES_TO_ITEMS: 'ADD_NOTES_TO_ITEMS',
  ADD_DATES_TO_ITEMS: 'ADD_DATES_TO_ITEMS_',
  ASSIGN_ITEMS: 'ASSIGN_ITEMS',
}

export const ACTIONS = {
  SET_IS_FINISHED: 'ONBOARDING/SET_IS_FINISHED',
  SET_IS_CANCELLED: 'ONBOARDING/SET_IS_CANCELLED',
  SET_STEP: 'ONBOARDING/SET_STEP',
  SEND_STEP_EVENT: 'ONBOARDING/SEND_STEP_EVENT',
}

export const setIsFinished = isFinished => {
  return {
    type: ACTIONS.SET_IS_CANCELLED,
    payload: isFinished,
  }
}

export const setIsCancelled = isCancelled => {
  return {
    type: ACTIONS.SET_IS_CANCELLED,
    payload: isCancelled,
  }
}

export const setOnboardingStep = (step = 0) => {
  return {
    type: ACTIONS.SET_STEP,
    payload: step,
  }
}

export const sendStepEvent = key => {
  return {
    type: ACTIONS.SEND_STEP_EVENT,
    payload: {
      key,
    },
  }
}
