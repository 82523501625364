import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { cn } from '@appfluence/classnames'
import { Icon } from '@fluentui/react/lib/Icon'
import { attentionNeededCountToString } from '../../hooks/useAttentionNeeded'
import { CounterBadge } from '@fluentui/react-components'

const IconButtonContainer = styled.button`
  display: flex;
  position: relative;
  cursor: pointer;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 0;

  &.disabled {
    cursor: default;
  }

  &.clicked {
    cursor: pointer;
  }
  &.isFiltering {
    transform: scale(1.35);
    animation: lds-heart 3s infinite cubic-bezier(0.215, 0.61, 0.355, 1.2);
  }
`

const OnlyIconContainer = styled.div`
  display: flex;
  position: relative;

  &.clicked {
    cursor: pointer;
  }
`

const StyledIcon = styled(Icon).attrs({})`
  font-size: ${p => p.size + 'px'};
  width: ${p => p.size + 'px'};
  height: ${p => p.size + 'px'}; /* IE */
  fill: ${p => (p.disabled ? p.theme.neutralPrimaryAlt : p.theme.palette.always_white)};
  ${p => p.selected && `color: ${p.theme.palette.themePrimary}`};
`

export const BubbleButton = forwardRef((props, ref) => {
  const {
    iconName,
    n = 0,
    size = 14,
    selected = false,
    onClick,
    className,
    info = false,
    onlyIcon = false,
    isFiltering = false,
    disabled = false,
    ...rest
  } = props
  const containerSubclass = cn(className, { clicked: !!onClick, isFiltering, disabled })
  const countString = attentionNeededCountToString(n)
  const Container = onlyIcon ? OnlyIconContainer : IconButtonContainer

  return (
    <Container ref={ref} className={containerSubclass} onClick={onClick} {...rest}>
      <StyledIcon iconName={iconName} size={size} selected={selected} disabled={disabled} />
      {!!countString && (
        <CounterBadge color={info ? 'brand' : 'danger'} size="small" className="!absolute -right-2.5 -top-2">
          {countString}
        </CounterBadge>
      )}
    </Container>
  )
})
