import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { safeCallFn } from '../../common/src/helpers/functionalUtils'
import { ContainerDrawer } from '@/components/panels/ContainerDrawer'
import { useTranslation } from 'react-i18next'

export const ItemsFiltersPanel = ({ headerText, onOpened, children, isOpen, ...rest }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  useEffect(() => {
    safeCallFn(onOpened)
  }, [dispatch, onOpened, isOpen])

  return (
    <ContainerDrawer isOpen={isOpen} headerText={t('item_filters.header_title')} {...rest}>
      {children}
    </ContainerDrawer>
  )
}
