import { useAtom } from 'jotai'
import { useCallback } from 'react'
import { lastReviewRequestAtom } from '@/atoms'
import { requestReview } from '@/utils/ExpoCommunication'
import { useMe } from '@/common/src/hooks/usersHooks'
import { differenceInMonths, differenceInWeeks } from 'date-fns'

export const useRequestReview = () => {
  const [lastReviewRequest, setLastReviewRequest] = useAtom(lastReviewRequestAtom)
  const me = useMe()
  return useCallback(() => {
    const joinedTimestamp = me?.get('date_joined')
    const joinedDate = joinedTimestamp ? new Date(+joinedTimestamp * 1000) : null
    const now = new Date()
    if (
      (!lastReviewRequest || differenceInMonths(now, lastReviewRequest) >= 1) &&
      joinedDate &&
      differenceInWeeks(now, joinedDate) >= 1
    ) {
      requestReview()
      setLastReviewRequest(now)
    }
  }, [lastReviewRequest, me, setLastReviewRequest])
}
