import { API_EVENT, generateActionTypeString } from '../common/src/helpers/reducerHelper'
import { APIS, PM_API_RESOURCES } from '../common/src/constants'

const targetActionType = generateActionTypeString(APIS.PM, PM_API_RESOURCES.SIGN_OUT, API_EVENT.ERROR)

export const signOutMiddleware = persistorGetter => store => next => action => {
  const result = next(action)
  if (action.type === targetActionType) {
    const persistor = persistorGetter()
    if (persistor) {
      persistor.purge()
    }
  }
  return result
}
