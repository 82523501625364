import { useState } from 'react'
import styled from 'styled-components'
import { Icon } from '@fluentui/react/lib/Icon'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import { Callout, DirectionalHint } from '@fluentui/react/lib/Callout'
import PropTypes from 'prop-types'
import { cn } from '@appfluence/classnames'
import { ICON_NAMES } from '../../common/src/msIcons'
import './InfoIconCallout.css'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media print {
    display: none;
  }
`

const Message = styled.p`
  color: ${p => p.theme.palette.black};
  font-size: 12px;
  font-weight: 400;
  margin: 0;
`

const Title = styled.p`
  color: ${p => p.theme.palette.black};
  font-size: 21px;
  font-weight: 300;
  padding-bottom: 10px;
  margin: 0;
`

const SIcon = styled(Icon)`
  font-size: ${p => p.iconSize}px;
  color: ${p => (p.success ? p.theme.palette.affirmative : p.theme.palette.themePrimary)};
`

export const InfoIconCallout = ({
  title,
  message,
  link,
  className,
  iconName = ICON_NAMES.InfoSolid,
  calloutMaxWidth = 0,
  iconSize = 24,
  animate: animateProp = true,
}) => {
  const [show, setShow] = useState(false)
  const [success, setSuccess] = useState(false)
  const [animate, setAnimate] = useState(animateProp ?? true)
  const [containerElement, setContainerElement] = useState(null)

  const onClickIcon = event => {
    event.stopPropagation()
    setShow(prev => !prev)
    setAnimate(false)
  }

  const onDismissCallout = () => setShow(false)

  const onClickButton = () => {
    link.onClick()
    setShow(false)
    setSuccess(!!link.success)
  }

  const iconClass = cn({
    'info-icon-callout': true,
    'info-icon-success': success,
    animate: animate || success,
  })

  return (
    <Container ref={setContainerElement} className={className}>
      {success ? (
        <SIcon iconName={link.success} className={iconClass} iconSize={iconSize} />
      ) : (
        <SIcon iconName={iconName} onClick={onClickIcon} className={iconClass} iconSize={iconSize} />
      )}
      <Callout
        target={containerElement}
        hidden={!show}
        onDismiss={onDismissCallout}
        directionalHint={DirectionalHint.bottomLeftEdge}
        calloutMaxWidth={calloutMaxWidth}
      >
        <div className="info-callout-container">
          {title && <Title>{title}</Title>}
          <Message>{message}</Message>
          {link && (
            <PrimaryButton text={link.title} onClick={onClickButton} target="_blank" className="info-callout-button" />
          )}
        </div>
      </Callout>
    </Container>
  )
}

InfoIconCallout.propTypes = {
  iconName: PropTypes.string,
  iconSize: PropTypes.number,
  calloutMaxWidth: PropTypes.number,
  title: PropTypes.string,
  message: PropTypes.any.isRequired,
  link: PropTypes.shape({
    title: PropTypes.string,
    onClick: PropTypes.func,
  }),
}
