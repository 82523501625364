import { memo, useCallback, useRef } from 'react'
import { RightDetailView } from '../rightDetailView/RightDetailView'
import { Matrix } from '../../components/matrix/Matrix'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { MainLayout } from '../../components/layout/MainLayout'
import { ProjectLevelTopBar } from '../project/ProjectLevelTopBar'
import { OnboardingView } from '../../components/onboarding/OnboardingView'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { itemHelper, projectHelper } from '../../common/src/helpers'
import { uriDataCreator } from '../../common/src/helpers/URLHelper'
import { PM_API_RESOURCE_TYPE } from '../../common/src/constants'
import { linkSubject, SOURCES } from '../../reactions/linkSubject'
import { useReactToPrint } from 'react-to-print'
import { PrintableComponent } from '@/components/PrintableComponent'
import { useDispatch, useSelector } from 'react-redux'
import { setPrefersDarkMode } from '@/actions/uiActions'
import { getPrefersDarkMode } from '@/selectors/uiSelectors'

const ProjectMatrixView_ = ({
  project,
  items,
  selectedItemId,
  sortFn,
  loading,
  projectSelectorEnabled = true,
  readOnly = false,
  matrixPreviewMode = false,
  showOwner = true,
}) => {
  const dispatch = useDispatch()
  const narrowWidth = useNarrowWidth()

  const onClickItem = useCallback(
    item => {
      if (matrixPreviewMode) {
        return
      }
      const serverID = itemHelper.getId(item)
      const urlData = uriDataCreator(1, PM_API_RESOURCE_TYPE.ITEM, serverID)
      linkSubject.next({ urlData, source: SOURCES.MATRIX })
    },
    [matrixPreviewMode]
  )

  const mainRef = useRef()
  const selectedMode = useSelector(getPrefersDarkMode)
  const previousMode = useRef(null)

  const printMain = useReactToPrint({
    content: () => mainRef.current,
    onBeforeGetContent: async () => {
      previousMode.current = selectedMode
      dispatch(setPrefersDarkMode('light'))
    },
    onAfterPrint: () => {
      dispatch(setPrefersDarkMode(previousMode.current))
    },
  })

  const showOnboardingView = !narrowWidth && !readOnly

  const main = (
    <PrintableComponent pageName={projectHelper.getName(project)} className="print:h-screen" ref={mainRef}>
      <Matrix
        items={items}
        onClickItem={onClickItem}
        project={project}
        selectedItemId={selectedItemId}
        sortFn={sortFn}
        readOnly={readOnly}
        loading={loading}
        showOwner={showOwner}
      />
      {showOnboardingView && <OnboardingView />}
    </PrintableComponent>
  )
  const detail = <RightDetailView readOnly={readOnly} />
  const topBar = (
    <ProjectLevelTopBar
      getNavigationURLForProjectId={getRelativeURLKeepingQuerySearch.matrixForProjectId}
      key={'ProjectMatrixViewTopBar'}
      project={project}
      projectSelectorEnabled={projectSelectorEnabled}
      readOnly={readOnly}
      items={items}
      printMain={printMain}
    />
  )
  let subViews = { main }
  if (!matrixPreviewMode) {
    subViews = {
      ...subViews,
      rightSide: detail,
      topBar,
    }
  }

  return <MainLayout {...subViews} />
}

export const ProjectMatrixView = memo(ProjectMatrixView_)
