import React, { useCallback } from 'react'
import _ from 'lodash'
import { useEventListener } from '../common/src/hooks/eventsHooks'
import { Toast, useToastController } from '@fluentui/react-components'
import { targetIsKindOfTextInput } from '../common/src/helpers/eventHelpers'
import { isHotkey } from 'is-hotkey'

export const useDebugToast = () => {
  const { dispatchToast } = useToastController()
  const addDebugToast = useCallback(
    obj => {
      const text = JSON.stringify(obj, null, 2)
      dispatchToast(
        <Toast>
          <pre>{text}</pre>
        </Toast>,
        {
          intent: 'info',
          timeout: -1,
        }
      )
    },
    [dispatchToast]
  )
  return { addDebugToast }
}

export const useDebugHandler = obj => {
  const { addDebugToast } = useDebugToast()
  useEventListener('keydown', evt => {
    const validate = evt => _.every([_.negate(targetIsKindOfTextInput), isHotkey('option+d')], fn => fn(evt))
    if (validate(evt)) {
      addDebugToast(obj)
    }
  })
}
