import { useCallback } from 'react'
import { motion } from 'framer-motion'
import { projectHelper } from '../../common/src/helpers'
import { useSelectedProject } from '../../hooks/PMHooks'
import { cn } from '@appfluence/classnames'
import { Button, Popover, PopoverSurface, PopoverTrigger } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { removeArchiveTag } from '../../common/src/actions/tagsAPI'
import { useDispatch } from 'react-redux'

export const Archived = ({ className, ...rest }) => {
  const project = useSelectedProject()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const unarchiveProject = useCallback(() => {
    dispatch(removeArchiveTag({ object: project }))
  }, [dispatch, project])

  if (project && projectHelper.isArchived(project)) {
    return (
      <Popover>
        <PopoverTrigger>
          <motion.button
            className={cn(
              'cursor-pointer rounded-md border-0 bg-red-600 p-1.5 font-bold text-white transition-colors hover:bg-red-800 active:bg-red-900',
              className
            )}
            {...rest}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: 'easeOut', duration: 1.7, delay: 1 }}
          >
            {t('project_archived_popover.popover_trigger_text')}
          </motion.button>
        </PopoverTrigger>
        <PopoverSurface>
          <div className="flex flex-col items-center">
            <span className="mb-3 mt-0 text-base font-bold">{t('project_archived_popover.popover_title')}</span>
            <Button appearance="primary" onClick={unarchiveProject}>
              {t('project_archived_popover.unarchive_button')}
            </Button>
          </div>
        </PopoverSurface>
      </Popover>
    )
  }
  return false
}

export const BottomCenterArchived = () => (
  <Archived className="absolute bottom-1.5 left-1/2 z-10 m-auto -translate-x-1/2" />
)
