export enum ProjectDisplayMode {
  List = 'List',
  Grid = 'Grid',
}

export enum ItemDisplayMode {
  Default = 'Default',
  Compact = 'Compact',
  Standard = 'Standard',
}
