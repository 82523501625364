import {
  Button,
  FluentProvider,
  Toast,
  ToastBody,
  ToastFooter,
  ToastTitle,
  ToastTrigger,
  webDarkTheme,
  webLightTheme,
} from '@fluentui/react-components'
import type React from 'react'
import { useTranslation } from 'react-i18next'
import { Dismiss } from '../BundledIcons'
import { useIsDarkTheme } from '../../themes'

type FluentToastProps = {
  children: React.ReactNode
  body?: React.ReactNode
  footer?: React.ReactNode
  dismissable?: boolean
}
export const FluentToast = ({ children, dismissable = true, body, footer }: FluentToastProps) => {
  const { t } = useTranslation()
  const isDarkTheme = useIsDarkTheme()
  return (
    <Toast appearance="inverted">
      <ToastTitle
        action={
          dismissable ? (
            <ToastTrigger>
              <FluentProvider theme={isDarkTheme ? webLightTheme : webDarkTheme}>
                <Button icon={<Dismiss />} appearance="transparent" aria-label={t('general.dismiss')} />
              </FluentProvider>
            </ToastTrigger>
          ) : undefined
        }
      >
        {children}
      </ToastTitle>
      {body && <ToastBody>{body}</ToastBody>}
      {footer && (
        <ToastFooter>
          <FluentProvider theme={isDarkTheme ? webLightTheme : webDarkTheme}>{footer}</FluentProvider>
        </ToastFooter>
      )}
    </Toast>
  )
}
