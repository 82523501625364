import React from 'react'
import styled from 'styled-components'
import { APP_NAME } from '../../constants/constants'
import { PMLogo } from '../Logo'
import { useConfig } from '../../queries/config'

const Container = styled.div`
  color: ${p => p.dynColor || p.theme.palette.themeSecondary};
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: 600;
  user-select: none;
  cursor: ${p => (p.onClick ? 'pointer' : 'inherit')};
`

const Text = styled.span`
  font-weight: bold;
  margin-left: 8px;
`

const Logo = styled(PMLogo)`
  height: 20px;
  width: 20px;
`

export const AppfluenceTitle = ({ title = APP_NAME, onClick, ...rest }) => {
  const color = useConfig().data?.theme_color
  return (
    <Container onClick={onClick} dynColor={color} {...rest}>
      <Logo />
      <Text>{title}</Text>
    </Container>
  )
}
