import { useCallback, useEffect, useRef, useState } from 'react'
import _ from 'lodash'

export const useTextSearch = ({ setTextFilter, globalTextSearch }) => {
  const debouncedSetTextFilterRef = useRef(_.debounce(setTextFilter, 250, { trailing: true }))
  const [textSearch, setTextSearch] = useState(globalTextSearch)

  useEffect(() => setTextSearch(globalTextSearch), [setTextSearch, globalTextSearch])

  const onSearchBoxChange = useCallback((evt, data) => {
    const t = data.value ?? ''
    setTextSearch(t)
    debouncedSetTextFilterRef.current(t)
  }, [])

  const onSearch = useCallback(
    text => {
      setTextFilter(text ?? '')
    },
    [setTextFilter]
  )

  useEffect(
    () => () => {
      debouncedSetTextFilterRef.current.cancel()
    },
    []
  )

  return { textSearch, onSearchBoxChange, onSearch }
}
