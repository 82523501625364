import { useEffect } from 'react'
import { projectHelper } from '../../common/src/helpers'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'

const getNextPath = (currentSelectedProjectId, allProjects) => {
  if (currentSelectedProjectId) {
    return getRelativeURLKeepingQuerySearch.matrixForProjectId(currentSelectedProjectId)
  }
  const project = allProjects.first()
  const idd = projectHelper.getIdd(project)
  return getRelativeURLKeepingQuerySearch.matrixForProjectId(idd)
}

export const useAutoSelectProjectEffect = (shouldAutoSelect, pid, currentSelectedProjectId, allProjects, history) => {
  useEffect(() => {
    if (shouldAutoSelect && !pid && allProjects && allProjects.size) {
      const path = getNextPath(currentSelectedProjectId, allProjects)
      history.replace(path)
    }
  }, [pid, allProjects, history, shouldAutoSelect, currentSelectedProjectId])
}
