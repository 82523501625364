import { cn } from '@appfluence/classnames'
import { type HTMLAttributes } from 'react'

let isMacResult: boolean | undefined
export function isMac() {
  if (isMacResult === undefined) {
    isMacResult = navigator.userAgent.includes('Macintosh')
  }
  return isMacResult
}

const ShortcutButtonKey = ({ className, ...rest }: HTMLAttributes<HTMLSpanElement>) => (
  <span
    className={cn(
      'border-px box-border flex h-[24px] min-w-[24px] items-center justify-center rounded-sm p-1',
      'border border-solid border-pm-neutral-secondary bg-pm-neutral-lighter-alt text-pm-neutral-secondary',
      className
    )}
    {...rest}
  />
)

export const META_KEY = isMac() ? '⌘' : 'Ctrl'

export type ShortcutHelpProps = {
  keys: string[]
  className?: string
}
export const ShortcutHelp = ({ className, keys }: ShortcutHelpProps) => {
  const visualKeys = keys.map(key => <ShortcutButtonKey key={key}>{key}</ShortcutButtonKey>)
  return <div className={cn(className, 'pointer-events-none flex gap-1')}>{visualKeys}</div>
}
