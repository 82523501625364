import React, { memo } from 'react'
import styled, { useTheme } from 'styled-components'
import { Icon } from '@fluentui/react/lib/Icon'
import { TagContainer } from './TagContainer'
import { TagName } from './TagName'
import { useTranslation } from 'react-i18next'

const StyledIcon = styled(Icon)`
  margin-right: 2px;
`

export const TagPlaceholder = memo(({ placeholder, iconName = 'Tag', primaryColor, secondaryColor, ...rest }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const ph = placeholder ?? t('general.tag')
  return (
    <TagContainer
      primaryColor={primaryColor || theme.palette.neutralTertiary}
      secondaryColor={secondaryColor || theme.palette.white}
      {...rest}
    >
      {iconName && <StyledIcon iconName={iconName} />}
      <TagName>{ph}</TagName>
    </TagContainer>
  )
})
