import React from 'react'
import { ICON_NAMES } from '../../common/src/msIcons'
import { InfoIconCallout } from '../info/InfoIconCallout'
import { useDispatch } from 'react-redux'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { addCollaborator } from '../../common/src/actions/usersAPI'
import { useTranslation } from 'react-i18next'

export const InviteUserButton = React.memo(({ className, email }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const inviteUser = () => {
    dispatch(addCollaborator(email))
    dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.ADD_COLLABORATOR))
  }
  const inviteLink = {
    title: t('promotions.invite_new_collaborator_link'),
    success: ICON_NAMES.MailCheck,
    onClick: inviteUser,
  }

  return (
    <InfoIconCallout
      title={t('promotions.invite_new_collaborator_title')}
      message={t('promotions.invite_new_collaborator_subtitle')}
      link={inviteLink}
      calloutMaxWidth={300}
      iconName={'AddFriend'}
      className={className}
    />
  )
})
