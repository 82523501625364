import { Button, Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle } from '@fluentui/react-components'
import { ArrowLeft24Regular, Dismiss24Regular } from '@fluentui/react-icons'
import { type ReactNode } from 'react'
import { useNarrowWidth } from '@/hooks/useNarrowWidth'

export type ContainerDrawerProps = {
  isOpen: boolean
  onDismiss: () => void
  headerText: string
  children: ReactNode
}

export const ContainerDrawer = ({ isOpen, onDismiss, headerText, children }: ContainerDrawerProps) => {
  const narrow = useNarrowWidth()
  return (
    <Drawer
      open={isOpen}
      onOpenChange={(_, data) => {
        if (!data.open) onDismiss()
      }}
      position="end"
      style={{ width: 340 }}
    >
      <DrawerHeader>
        <DrawerHeaderTitle
          className={narrow ? 'flex-row-reverse !justify-end !gap-3' : undefined}
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={narrow ? <ArrowLeft24Regular /> : <Dismiss24Regular />}
              onClick={onDismiss}
            />
          }
        >
          {headerText}
        </DrawerHeaderTitle>
      </DrawerHeader>
      <DrawerBody className="!pb-5">{children}</DrawerBody>
    </Drawer>
  )
}
