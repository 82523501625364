import _ from 'lodash'
import { DEVELOPMENT } from '../environment'

const resourceURIRegExp = /^\/api\/v(.+)\/(\w+)\/(\d+)\/$/
const insideURLRegExp = /\/api\/v(.+)\/(\w+)\/(\d+)\//
const simpleObjectRegExp = /\/(item|project)\/(\d+)/
const knownDomains = /(prioritymatrix|appfluence|1on1hub)\.com$/
const knownDevDomains = /(priority.app|localhost)$/

const schemeRegExp = /^([a-z0-9]+):\/\//

const parseStringAsObjectURI = (string, regExp) => {
  if (_.isString(string)) {
    const match = string.match(regExp)
    if (match) {
      return {
        v: _.parseInt(match[1]),
        type: match[2],
        id: _.parseInt(match[3]),
      }
    }
  }
  return void 0
}

const simpleParser = string => {
  if (_.isString(string)) {
    const match = string.match(simpleObjectRegExp)
    if (match) {
      return {
        v: 1,
        type: match[1],
        id: _.parseInt(match[2]),
      }
    }
  }
  return void 0
}

export const resourceURIParser = resourceURI => parseStringAsObjectURI(resourceURI, resourceURIRegExp)

export const resourceURICreator = (v, type, id) => `/api/v${v}/${type}/${id}/`
export const uriDataCreator = (v, type, id) => ({ v, type, id })

const URLBased = {
  isOwnProtocol: u => u.protocol === 'prioritymatrix:',
  isKnownDomain: ({ hostname: domain }) => knownDomains.test(domain) || (DEVELOPMENT && knownDevDomains.test(domain)),
}

export const URLParser = string => {
  const withKnownResourceURI = parseStringAsObjectURI(string, insideURLRegExp)
  if (withKnownResourceURI) {
    return withKnownResourceURI
  }
  //seconds pass
  try {
    const url = new URL(string)
    const valid = URLBased.isOwnProtocol(url) || URLBased.isKnownDomain(url)
    if (!valid) {
      return undefined
    }
    return simpleParser(string)
  } catch (err) {
    return undefined
  }
}

export const getProtocolFromString = urlString => {
  const matches = urlString && urlString.match(schemeRegExp)
  return matches ? matches[1] : undefined
}

export const hasProtocol = urlString => {
  return !!getProtocolFromString(urlString)
}

export const extractWebappRelativePath = link => {
  const webappRegexp = /office365\/(apptest|app)\/index(?<relative>\/[^?\n]+)/
  const result = webappRegexp.exec(link)
  return _.get(result, 'groups.relative', '')
}

export const getCurrentSection = () => {
  const pathname = window.location.pathname
  const splitted = pathname.split('/')
  const indexOfHome = splitted.indexOf('home')
  return indexOfHome === -1 ? null : splitted[indexOfHome + 1]
}
