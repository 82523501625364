export const copyToClipboard = text => {
  const el = document.createElement('textarea')
  el.value = text
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  const success = document.execCommand('copy')
  document.body.removeChild(el)
  return !!success
}

export const saveSvg = (svgEl, name, styles) => {
  let stylesElement = undefined
  try {
    svgEl.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
    if (styles) {
      stylesElement = document.createElement('style')
      stylesElement.innerHTML = styles
      svgEl.appendChild(stylesElement)
    }
    const svgData = svgEl.outerHTML
    const preface = '<?xml version="1.0" standalone="no"?>\r\n'
    const svgBlob = new Blob([preface, svgData], { type: 'image/svg+xml;charset=utf-8' })
    const svgUrl = URL.createObjectURL(svgBlob)
    const downloadLink = document.createElement('a')
    downloadLink.href = svgUrl
    downloadLink.download = name
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  } catch (err) {}
  try {
    if (stylesElement) {
      svgEl.removeChild(stylesElement)
    }
  } catch (err) {}
}
