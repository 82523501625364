import { DEVELOPMENT } from '../common/src/environment'
import { getSentry } from '../getSentry'

const Sentry = getSentry()

export const errorHandlerMiddleware = persistorGetter => store => next => action => {
  try {
    return next(action)
  } catch (err) {
    const errorName = 'Error in reducers!'
    Sentry.withScope(scope => {
      scope.setExtra('errorName', errorName)
      scope.setExtra('action', action)
      Sentry.captureException(err)
    })
    if (DEVELOPMENT) {
      console.error(errorName)
      console.error(err)
      return
    }
    const persistor = persistorGetter()
    if (persistor) {
      persistor.purge()
    }
    window.location.reload()
  }
}
