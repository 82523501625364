import _ from 'lodash'
import { API_EVENT, isPMApiDetailedAction, parseApiActionType } from '../common/src/helpers/reducerHelper'
import { PM_API_RESOURCES } from '../common/src/constants'
import { resourceURIParser } from '../common/src/helpers/URLHelper'
import { sharingRequestHelper, stateHelper, userHelper } from '../common/src/helpers'
import { getProject } from '../common/src/actions/projectsAPI'
import { getAllItems, getPartialInboxPlus } from '../common/src/actions/itemsAPI'
import { getCollaborators } from '../common/src/actions/usersAPI'
import { ACTIONS as WS_ACTIONS } from '../common/src/websocket/wsActions'
import { asyncScheduler, fromEvent, skip, throttleTime } from 'rxjs'
import { timestampInSecondsToIsoString } from '../common/src/helpers/dateHelper'
import { getLastSyncTimestamp } from '../common/src/selectors/requestStateSelectors'
import {
  getAllProjectsIfNotPending,
  getAllProjectSummariesIfNotPending,
  getSharingRequestsIfNotPending,
} from '../common/src/actions/combinedAPI'
import { getSentry } from '../getSentry'

const leadingTime = _.partial(throttleTime, _, asyncScheduler, { leading: true, trailing: false })

/**
 * Websocket reconnnection and polling handler.
 */
const getObjectsSinceLastSync = async store => {
  const state = store.getState()
  const syncTimestamp = getLastSyncTimestamp(state)
  const timestampParameter = {
    timestamp__gte: timestampInSecondsToIsoString(syncTimestamp),
  }
  if (!syncTimestamp) {
    return
  }
  const actions = [
    getAllItems(timestampParameter),
    getAllProjectsIfNotPending(timestampParameter),
    getPartialInboxPlus(timestampParameter),
  ]
  const me = stateHelper.getMe(state)
  const userID = userHelper.getID(me)
  if (userID) {
    actions.push(getSharingRequestsIfNotPending(userID))
  }
  for (const action of actions) {
    await store.dispatch(action).catch()
  }
}

let focusSubscription = null

const Sentry = getSentry()

export const apiSideEffectsMiddleware = store => next => action => {
  const { type, payload, meta } = action
  const apiAction = parseApiActionType(type)
  if (isPMApiDetailedAction(apiAction)) {
    const { resource, event } = apiAction
    if (event === API_EVENT.START && resource === PM_API_RESOURCES.ITEM) {
      const { itemID } = meta
      if (!itemID) {
        Sentry.captureException(new Error(`Requesting Item: itemID is ${itemID}`))
      }
    } else if (event === API_EVENT.SUCCESS) {
      switch (resource) {
        case PM_API_RESOURCES.SHARING_REQUEST:
        case PM_API_RESOURCES.SHARING_REQUEST_PUT:
        case PM_API_RESOURCES.SHARING_REQUEST_POST:
          {
            const requestJS = payload
            const status = requestJS.status
            const projectID = resourceURIParser(requestJS.project).id
            if (projectID) {
              if (status === sharingRequestHelper.STATUS.ACCEPTED || status === sharingRequestHelper.STATUS.REMOVE) {
                store.dispatch(getProject(projectID, true))
                store.dispatch(getCollaborators()) //in case we invite a new user
              }
            }
          }
          break
        case PM_API_RESOURCES.ITEM_COMMENTS:
          {
            const { discard } = meta
            if (discard) {
              // To avoid load in reducers
              return
            }
          }
          break
        case PM_API_RESOURCES.IMPORT_PROJECT_CSV:
          {
            store.dispatch(getAllProjectSummariesIfNotPending())
          }
          break
        default:
          break
      }
    }
  } else {
    switch (type) {
      case WS_ACTIONS.RECONNECTION:
        {
          getObjectsSinceLastSync(store)
        }
        break
      default:
        break
    }
  }

  if (!focusSubscription) {
    const threshold = 2 * 60 * 1000
    focusSubscription = fromEvent(window, 'focus')
      .pipe(leadingTime(threshold)) //ms
      .pipe(skip(1)) //skip first
      .subscribe(() => {
        console.log('sync items')
        getObjectsSinceLastSync(store)
      })
  }

  return next(action)
}
