import styled from 'styled-components'

export const OfficeSectionHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 16px;
`

export const OfficeSectionBody = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1;
`
