import React from 'react'

export function useBoolean(initialState) {
  const [value, setValue] = React.useState(initialState)
  const setTrue = React.useCallback(() => {
    setValue(true)
  }, [])
  const setFalse = React.useCallback(() => {
    setValue(false)
  }, [])
  const toggle = React.useCallback(() => setValue(v => !v), [])
  return [value, { setTrue, setFalse, toggle, setValue }]
}
