import _ from 'lodash'
import { DEFAULT_TEAMS_LOCATION, TEAMS_KEYS as KEYS } from '../reducers/teamsKeys'

export const getTeamsLocation = state => state.getIn([KEYS.REDUCER, KEYS.LOCATION]) || DEFAULT_TEAMS_LOCATION
export const getTeamsState = state => state.get(KEYS.REDUCER)
export const getDidLoadTeamsContext = state => state.getIn([KEYS.REDUCER, KEYS.DID_LOAD_CONTEXT])
export const getDidConsumeDeeplink = state => state.getIn([KEYS.REDUCER, KEYS.DID_CONSUME_DEEPLINK])
export const getMeetingId = state => state.getIn([KEYS.REDUCER, KEYS.MEETING_ID])
export const getChannelId = state => state.getIn([KEYS.REDUCER, KEYS.CONVERSATION_ID])
export const getRawContext = state => state.getIn([KEYS.REDUCER, KEYS.RAW_CONTEXT])
export const getHostName = state => _.get(getRawContext(state), 'app.host.name')
export const getHostClientType = state => _.get(getRawContext(state), 'app.host.clientType')
export const isInsideTeams = state => !!getRawContext(state) //We're inside teams when context exists
export const isMobileHost = state => {
  const hostClientType = getHostClientType(state)
  return [TEAMS_HOST_CLIENT_TYPE.ANDROID, TEAMS_HOST_CLIENT_TYPE.IOS].includes(hostClientType)
}

export const isOutlook = state => getHostName(state) === TEAMS_HOST_NAME.OUTLOOK

/**
 * Teams hosts
 * https://learn.microsoft.com/en-us/javascript/api/@microsoft/teams-js/app.apphostinfo?view=msteams-client-js-latest#properties
 */
export const TEAMS_HOST_CLIENT_TYPE = {
  ANDROID: 'android',
  IOS: 'ios',
  WEB: 'web',
  DESKTOP: 'desktop',
  RIGEL: 'rigel',
}

/**
 * Teams names
 * https://learn.microsoft.com/en-us/javascript/api/@microsoft/teams-js/app.apphostinfo?view=msteams-client-js-latest#properties
 */
export const TEAMS_HOST_NAME = {
  OFFICE: 'Office',
  ORANGE: 'Orange',
  OUTLOOK: 'Outlook',
  TEAMS: 'Teams',
}
