import React, { useCallback, useContext, useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { FileContext } from '../../contexts'
import { DragAndDrop, SupportedTypes } from '../../components/generic/DragAndDrop'
import { getFileList } from '../../common/src/helpers/ReactEventHelper'
import { useTranslation } from 'react-i18next'

const Container = styled.div``

const DragAndDropContainer = styled(DragAndDrop).attrs(p => ({
  supportedTypes: [SupportedTypes.Files],
  placeholdersByType: {
    [SupportedTypes.Files]: p.t('item.file.drag_and_drop_placeholder'),
  },
  styles: {
    subContainer: {
      fontSize: '30px',
    },
    icon: {
      fontSize: '60px',
    },
  },
}))``

export const FileDropSection = ({ children, item, readOnly = false }) => {
  const [, setLoading] = useState(false)
  const { uploadFiles } = useContext(FileContext)
  const { t } = useTranslation()

  const handleDrop = useCallback(
    async ({ files, elementType }) => {
      if (_.isEmpty(files)) {
        return
      }
      setLoading(true)
      await uploadFiles({ item, files })
      setLoading(false)
    },
    [uploadFiles, item]
  )

  const onPaste = useCallback(
    evt => {
      const fileList = getFileList(evt)
      const files = _.isObject(fileList) ? Array.from(fileList) : []
      if (!_.isEmpty(files)) {
        uploadFiles({ item, files, showConfirmation: true })
        evt.preventDefault()
        evt.stopPropagation()
      }
    },
    [uploadFiles, item]
  )

  if (readOnly) {
    return <Container>{children}</Container>
  }

  return (
    <DragAndDropContainer t={t} onDrop={handleDrop} onPaste={onPaste}>
      {children}
    </DragAndDropContainer>
  )
}
