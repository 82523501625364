export const ITEM_FILTER_SECTION_KEY = {
  PROJECT_MATRIX: 'matrix',
  PROJECT_LIST: 'list',
  PROJECT_KANBAN: 'kanban',
  PROJECT_CALENDAR: 'calendar',
  SEARCH: 'search',
  ONE_ON_ONE: 'one_on_one',
  INBOX: 'inbox',
  RESOURCES_PANEL: 'resources_panel',
  GLOBAL_CALENDAR: 'global_calendar',
  GLOBAL_GANTT: 'global_gantt',
}

export const FILTER_REDUCER_KEYS = {
  FILTERS: 'filters',
  PROJECTS: 'projects',
  PROJECTS_PANEL: 'projectsPanel',
  STARRED_PROJECT: 'starredProject',
  SORT: 'sort',
  SORT_ASCENDING: 'sortAscending',
  STARRED_FIRST: 'starredFirst',
  STATE: 'state',
  QUADRANT: 'quadrant',
  TEXT: 'text',
  TEXT_MODE: 'textMode',
  OWNERS: 'owners',
  NO_OWNER: 'noOwner',
  FOLLOWED: 'followed',
  DATE_TYPE: 'dateType',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  PROGRESS_CONDITION: 'progressCondition',
  PROGRESS: 'progress',
  PROJECT_IDS: 'projectIds',
  PROJECTS_MODE: 'projectsMode',
  TAGS: 'tags',
  TAGS_MODE: 'tagsMode',
  USER_GROUP: 'userGroup',
  SHOW_ARCHIVED: 'showArchived',
  GROUP_BY: 'groupBy',
  SHOW_RECURRENT_ITEMS_HOURS_AHEAD: 'show_recurrent_items_hours_ahead',
  ...ITEM_FILTER_SECTION_KEY,
}
