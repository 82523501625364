import React, { Suspense, useCallback, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { isEmbeddedOnTeams } from '../../helpers/queryParamsHelper'
import { OneOnOneYoutubeVideo } from './OneOnOneYoutubeVideo'
import { useCollaboratorsMinusMe } from '../../common/src/hooks/usersHooks'
import { OneOnOneUserSelectionPanel } from './OneOnOneUserSelectionPanel'
import { Button, Link } from '@fluentui/react-components'
import { LazyIViewPlaceholder } from '../../components/placeholder/LazyIViewPlaceholder'
import { Loading } from '../../components/basic/Loading'
import { IVIEW_PLACEHOLDER_TYPES } from '../../components/placeholder/IViewPlaceholderTypes'
import { PeopleAdd } from '../../components/BundledIcons'

const TeamsParagraph = () => {
  const { t } = useTranslation()
  return (
    <>
      <p className="ms-sm12 ms-md8">
        {t('one_on_one.teams_placeholder_0.message_0')}{' '}
        <Link target="_blank" href="https://www.youtube.com/watch?v=UpU4HyofBN4" rel="noopener noreferrer" as="a">
          {t('one_on_one.teams_placeholder_0.message_click_link')}
        </Link>{' '}
        {t('one_on_one.teams_placeholder_0.message_1')}
      </p>
      <OneOnOneYoutubeVideo />
      <p className="ms-sm12 ms-md8">{t('one_on_one.teams_placeholder_0.message_2')}</p>
    </>
  )
}

export const OneOnOnePlaceholderText = ({ onChangeCollaborator }) => {
  const { t } = useTranslation()
  const teamsParagraph = isEmbeddedOnTeams() && <TeamsParagraph />
  const userDoesntHaveCollaborators = _.isEqual(useCollaboratorsMinusMe().size, 0)
  const [isOpenPanel, setIsOpenPanel] = useState(false)
  const showPanel = useCallback(() => {
    setIsOpenPanel(true)
  }, [setIsOpenPanel])
  const hidePanel = useCallback(() => {
    setIsOpenPanel(false)
  }, [setIsOpenPanel])
  const onSelectUser = useCallback(
    email => {
      onChangeCollaborator(email)
    },
    [onChangeCollaborator]
  )

  return (
    <div>
      <Suspense fallback={<Loading />}>
        <LazyIViewPlaceholder {...{ type: IVIEW_PLACEHOLDER_TYPES.ENCOURAGING, imageIndex: 2 }}>
          <div className="flex-centered-container flex-column text-centered">
            <h3>{t('one_on_one.welcome_placeholder_0.welcome')}</h3>
            <p className="ms-sm12 ms-md8">
              <b>{t('one_on_one.welcome_placeholder_0.title')}</b> {t('one_on_one.welcome_placeholder_0.message')}
            </p>
            {
              <p className="ms-sm12 ms-md8">
                <Button
                  appearance="primary"
                  icon={<PeopleAdd />}
                  onClick={showPanel}
                  id={'OneOnOnePlaceholder_openPanelButton'}
                >
                  {userDoesntHaveCollaborators
                    ? t('one_on_one.note_placeholder_0.button')
                    : t('one_on_one.note_placeholder_0.button_has_collaborator')}
                </Button>
                {userDoesntHaveCollaborators && (
                  <p>
                    <b>{t('one_on_one.note_placeholder_0.title')}</b> {t('one_on_one.note_placeholder_0.message')}
                  </p>
                )}
                <OneOnOneUserSelectionPanel
                  email={null}
                  onChange={onSelectUser}
                  isOpen={isOpenPanel}
                  onDismiss={hidePanel}
                />
              </p>
            }
            {teamsParagraph}
          </div>
        </LazyIViewPlaceholder>
      </Suspense>
    </div>
  )
}
