import type { ItemFromCSV } from '../types/item'

export const generateEmail = (items: ItemFromCSV[]) => {
  const mailtoLink = `mailto:?subject=${encodeURIComponent('Priority Matrix Items')}&body=${encodeURIComponent(
    items
      .map(
        item =>
          `• ${item.name}\n\tFrom project '${item.project_name}'.\n\tOwner: ${item.owner}${
            item.startDate ? `\n\tStarted on ${item.startDate}` : ''
          }${item.dueDate ? `\n\tDue on ${item.dueDate}` : ''}\n\tCompletion percentage: ${item.completionPercentage}${
            item.descriptionText ? `\n\tNotes: ${item.descriptionText}` : ''
          }`
      )
      .join('\n\n')
  )}`

  // click on the link
  window.open(mailtoLink, '_blank')
}
