import _ from 'lodash'
import i18n from 'i18next'
import { graphEventHelper, itemHelper } from '../../common/src/helpers'
import { BASIC_ITEM_LIST_TYPES } from './HomeConstants'
import { datefns, calendar, formatDate } from '../../utils/datefns'
import { dateToTimestampInSeconds, toDate } from '../../common/src/helpers/dateHelper'

export const getRenderList = ({ items, graphEvents, now }) => {
  const first = datefns.startOfDay(now)
  const thresholdMapper = _.flow([n => datefns.add({ days: n })(first), dateToTimestampInSeconds])
  const thresholds = _.map(_.range(-1, 8), thresholdMapper)
  const baseMatrix = _.map(thresholds, () => [])
  const getGroupIndex = date => _.findIndex(thresholds, d => d > date)
  let grouped = items.reduce((acc, item) => {
    const due = itemHelper.getDueTimestamp(item)
    const idx = getGroupIndex(due)
    if (idx === -1) {
      return acc
    }
    const group = acc[idx]
    const suggestedIndex = sortedIndexByDueDate(item, group)
    group.splice(suggestedIndex, 0, item)
    return acc
  }, baseMatrix)
  if (graphEvents.size) {
    const intervals = _.map(thresholds, (t, i) => {
      if (i > 0) {
        return [thresholds[i - 1], thresholds[i]].map(toDate)
      }
      return undefined
    })
    grouped = graphEvents.reduce((acc, event) => {
      _.each(acc, (arr, i) => {
        const interval = intervals[i]
        if (interval && graphEventHelper.occursInInterval(event, interval)) {
          arr.push(event)
        }
      })
      return acc
    }, grouped)
  }
  return convertGroupsToList(thresholds, grouped)
}

const getTitleForThreshold = timestamp => {
  const date = toDate(timestamp - 1)
  const fmt = new Intl.RelativeTimeFormat(navigator.language, { numeric: 'auto' })

  const text = calendar({
    sameDay: () => fmt.format(0, 'day'),
    nextDay: () => fmt.format(1, 'day'),
    nextWeek: d =>
      formatDate({
        weekday: 'long',
        day: 'numeric',
      })(d),
    lastDay: () => fmt.format(-1, 'day'),
    lastWeek: () => i18n.t('agenda.overdue'),
    sameElse: () => i18n.t('agenda.overdue'),
  })(new Date())(date)
  return {
    text,
    type: BASIC_ITEM_LIST_TYPES.HEADER,
    key: text,
    date,
  }
}

const extendOverdue = titleObj => {
  if (_.includes(i18n.t('agenda.overdue'), titleObj.text)) {
    return {
      ...titleObj,
      infoMessage: i18n.t('agenda.overdue_callout'),
    }
  }
  return titleObj
}

const getTitles = _.flow([getTitleForThreshold, extendOverdue])

const convertGroupsToList = (thresholds, groupedItems) => {
  const titles = _.map(thresholds, getTitles)
  const zippedGroups = _.zip(titles, groupedItems)
  const overdue = _.first(zippedGroups)
  const sortedGroups = [..._.drop(zippedGroups, 1), overdue]
  const filtered = _.filter(sortedGroups, ([title, g]) => !_.isEmpty(g))
  return _.flattenDeep(filtered)
}

const sortedIndexByDueDate = (value, array) => _.sortedIndexBy(array, value, itemHelper.getDueTimestamp)
