import _ from 'lodash'
import { PM_API_RESOURCES, PM_API_URLS } from '../constants'
import { createAction } from 'redux-api-middleware'
import { appendQueryParamsToURL, extendPMHeaders, getCredentialsConfig } from '../helpers/requestHelper'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'

export const getAllNotifications = (params = {}) => {
  const url = PM_API_URLS.NOTIFICATIONS
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.NOTIFICATIONS),
    headers: extendPMHeaders(),
  })
}

export const getNotification = (notificationId, params = {}) => {
  const url = _.template(PM_API_URLS.NOTIFICATION)({ id: notificationId })
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.NOTIFICATION, { notificationId }),
    headers: extendPMHeaders(),
  })
}

export const patchNotification = (notificationId, notificationPatch) => {
  if (!notificationId) {
    const type = 'WRONG_NOTIFICATION_PATCH'
    console.warn(type)
    return Promise.reject({ type })
  }
  const url = _.template(PM_API_URLS.NOTIFICATION)({ id: notificationId })
  const body = JSON.stringify(notificationPatch.toJS())
  return createAction({
    endpoint: appendQueryParamsToURL(url),
    credentials: getCredentialsConfig(),
    method: 'PATCH',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.NOTIFICATION, { notificationId, body: notificationPatch }),
    headers: extendPMHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
    }),
    body,
  })
}
