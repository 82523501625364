import { LOCATION_ACTION_TYPES } from '../reducers/locationKeys'
import { dateHelper } from '../common/src/helpers'

export const setLocation = path => {
  const timestamp = dateHelper.dateToTimestampInSeconds(new Date())
  return {
    type: LOCATION_ACTION_TYPES.SET_LOCATION,
    payload: {
      path,
      timestamp,
    },
  }
}
