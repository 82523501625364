import _ from 'lodash'
import { RSAA } from 'redux-api-middleware'
import { getCSVImportToProjectURL, PM_API_RESOURCES, PM_API_URLS, SERVER_URLS } from '../constants'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'
import { createPaginatedAPIAction } from '../helpers/paginationHelper'
import { projectHelper, userHelper } from '../helpers'
import {
  appendQueryParamsToURL,
  extendPMHeaders,
  getCredentialsConfig,
  withDefaultParams,
} from '../helpers/requestHelper'
import { OBJECT_ERROR_STATUS, ObjectError } from '../errors/errors'
import { requestNotNeeded } from './generalActions'
import helper from '../helpers/stateHelper'
import { createAction } from 'redux-api-middleware'

const DEFAULT_PARAMETERS = {
  GET_ALL_PROJECTS: {
    state: 0,
    limit: 1000,
    skip_tag: 'archived',
  },
  GET_ALL_SUMMARIES: {
    state: 0,
    skip_tag: 'archived',
    limit: 1000,
    returnindex: 1,
  },
}

export const getAllProjects = withDefaultParams(
  createPaginatedAPIAction(params => {
    return {
      [RSAA]: {
        endpoint: appendQueryParamsToURL(PM_API_URLS.ALL_PROJECTS, params),
        credentials: getCredentialsConfig(),
        method: 'GET',
        types: generateActionTypesForPMAPI(PM_API_RESOURCES.ALL_PROJECTS, { params }),
        headers: extendPMHeaders(),
      },
    }
  }),
  DEFAULT_PARAMETERS.GET_ALL_PROJECTS
)

export const getProject = (projectID, memberships = false, fastProject) => {
  const params = { returnmemberships: memberships }
  if (fastProject) {
    params.fastproject = fastProject
  }
  const url = _.template(PM_API_URLS.PROJECT)({ idd: projectID })
  const types = generateActionTypesForPMAPI(PM_API_RESOURCES.PROJECT, { projectID, isReadOnly: !!fastProject })
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(url, params),
      credentials: getCredentialsConfig(!!fastProject),
      method: 'GET',
      types: types,
      headers: extendPMHeaders(),
    },
  }
}

export const postProject = project => {
  if (!project) {
    return { type: 'INVALID_PROJECT_TO_POST' }
  }
  const name = projectHelper.getName(project)
  if (!name) {
    return { type: 'INVALID_PROJECT_WITHOUT_NAME_TO_POST' }
  }

  const body = projectHelper.prepareProjectForPut(project)
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(PM_API_URLS.ALL_PROJECTS),
      credentials: getCredentialsConfig(),
      method: 'POST',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.PROJECT_POST),
      headers: extendPMHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      }),
      body,
    },
  }
}

export const putProject = project => {
  const projectID = projectHelper.getIdd(project)
  if (!projectID || projectID <= 0) {
    return { type: 'INVALID_PROJECT_ID' }
  }

  const _url = _.template(PM_API_URLS.PROJECT)({ idd: projectID })
  const body = projectHelper.prepareProjectForPut(project)
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(_url),
      credentials: getCredentialsConfig(),
      method: 'PUT',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.PROJECT_PUT, { projectID }),
      headers: extendPMHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      }),
      body,
    },
  }
}

export const duplicateProject = (project, copyFinishedItems) => {
  const projectID = projectHelper.getIdd(project)
  if (!projectID || projectID <= 0) {
    return { type: 'INVALID_PROJECT_ID' }
  }

  const _url = _.template(PM_API_URLS.PROJECT_DUPLICATE)({ idd: projectID })
  const params = copyFinishedItems ? { finished: true } : {}
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(_url, params),
      credentials: getCredentialsConfig(),
      method: 'GET',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.PROJECT, { projectID }),
      headers: extendPMHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      }),
    },
  }
}

export const getProjectIfNotExists =
  (projectID, ...args) =>
  (dispatch, getState) => {
    const state = getState()
    const exists = !!helper.getProject(state, projectID)
    return !exists ? dispatch(getProject(projectID, ...args)) : Promise.resolve(requestNotNeeded)
  }

export const leaveProject = project => dispatch => {
  const preDeletedProject = project.set(projectHelper.KEYS.STATE, projectHelper.STATE.TRASH)
  return dispatch(putProject(preDeletedProject))
}

export const deleteProjectForEverybody = project => dispatch => {
  const preDeletedProject = project.set(projectHelper.KEYS.STATE, projectHelper.STATE.TRASH_FOR_EVERYBODY)
  return dispatch(putProject(preDeletedProject))
}

export const getReadOnlyToken = idd => {
  const _url = _.template(PM_API_URLS.PROJECT_READONLY_TOKEN)({ idd })
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(_url),
      credentials: getCredentialsConfig(),
      method: 'GET',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.PROJECT_READONLY_TOKEN, { projectID: idd }),
      headers: extendPMHeaders(),
    },
  }
}

const _getAllProjectSummaries = createPaginatedAPIAction(params => {
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(PM_API_URLS.ALL_PROJECT_SUMMARIES, params),
      credentials: getCredentialsConfig(),
      method: 'GET',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.ALL_PROJECT_SUMMARIES),
      headers: extendPMHeaders(),
    },
  }
})

export const getAllProjectSummaries = withDefaultParams(_getAllProjectSummaries, DEFAULT_PARAMETERS.GET_ALL_SUMMARIES)

export const importProjectCSV = (file, projectID) => dispatch => {
  let promise = void 0
  try {
    const body = {
      project: file,
    }
    //todo check FormData polyfill
    const formData = _.reduce(
      body,
      (acc, v, k) => {
        if (acc) {
          acc.append(k, v)
        }
        return acc
      },
      new FormData()
    )
    const url = projectID ? getCSVImportToProjectURL(projectID) : SERVER_URLS.IMPORT_CSV
    promise = dispatch({
      [RSAA]: {
        endpoint: appendQueryParamsToURL(url),
        credentials: getCredentialsConfig(),
        method: 'POST',
        types: generateActionTypesForPMAPI(PM_API_RESOURCES.IMPORT_PROJECT_CSV),
        headers: extendPMHeaders(),
        body: formData,
      },
    })
  } catch (err) {
    console.log(err)
  }
  return promise
}

export const sortProjectIDs = projectIDs => {
  if (!projectIDs || projectIDs.length < 2) {
    const errorObject = new ObjectError(OBJECT_ERROR_STATUS.INVALID_PROJECTS_INDEXES, `The name cannot be empty`)
    return { type: 'INVALID_PROJECTS_INDEXES', payload: errorObject }
  }
  const body = JSON.stringify(projectIDs)
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(PM_API_URLS.SORT_PROJECTS),
      credentials: getCredentialsConfig(),
      method: 'POST',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.SORT_PROJECTS),
      headers: extendPMHeaders(),
      body,
    },
  }
}

export const updateUserRoleInProject = (user, role, projectID) => {
  if (user && projectID) {
    const email = userHelper.getEmail(user)
    if (!email) {
      const ERROR = 'INVALID_EMAIL'
      console.warn(ERROR)
      return { type: ERROR }
    }
    if (role !== 2 && role !== 0) {
      const ERROR = 'INVALID_ROLE'
      console.warn(ERROR)
      return { type: ERROR }
    }
    if (projectID <= 0) {
      return { type: 'INVALID_PROJECT_ID' }
    }
    const _url = _.template(PM_API_URLS.UPDATE_PROJECT_ROLE)({ idd: projectID })
    const params = '?useremail=' + email + '&project_role=' + role
    return createAction({
      endpoint: _url + params,
      credentials: getCredentialsConfig(),
      method: 'PUT',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.UPDATE_PROJECT_ROLE, {
        user: user,
        projectID: projectID,
        role: role,
      }),
      headers: extendPMHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      }),
    })
  } else {
    const invalidUpdate = 'NOT_USER_OR_PROJECT'
    console.warn(invalidUpdate)
    return { type: invalidUpdate }
  }
}

export const getProjectSecretEmails = projectID => {
  const url = _.template(PM_API_URLS.INBOX_ADDRESSES)({ idd: projectID })
  const types = generateActionTypesForPMAPI(PM_API_RESOURCES.INBOX_ADDRESSES, { projectID })
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(url),
      credentials: getCredentialsConfig(),
      method: 'GET',
      types: types,
      headers: extendPMHeaders(),
    },
  }
}

export const createProjectFromGoal = (goal = '') => {
  const types = generateActionTypesForPMAPI(PM_API_RESOURCES.CREATE_PROJECT_AI)
  return {
    [RSAA]: {
      endpoint: PM_API_URLS.CREATE_PROJECT_AI,
      credentials: getCredentialsConfig(),
      method: 'POST',
      headers: extendPMHeaders(),
      types,
      body: JSON.stringify({ goal }),
    },
  }
}

export const getGoalExamples = () => {
  const types = generateActionTypesForPMAPI(PM_API_RESOURCES.GOAL_EXAMPLES)
  return {
    [RSAA]: {
      endpoint: PM_API_URLS.GOAL_EXAMPLES,
      credentials: getCredentialsConfig(),
      method: 'GET',
      headers: extendPMHeaders(),
      types,
    },
  }
}
