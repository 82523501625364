import { Record } from 'immutable'
import { toDate } from './dateHelper'

const KEYS = {
  ID: 'id',
  JOINED_DATE: 'joined_date',
  ROLE: 'role',
  USER: 'user',
}

const USER_GROUP_ROLE = {
  ADMIN: 0,
  MEMBER: 10,
}

const Base = Record({
  id: 0,
  joined_date: '0',
  role: 0,
  user: null,
})

let helper = {}
helper.USER_GROUP_ROLE = USER_GROUP_ROLE
helper.create = Base

helper.getId = membership => membership.get(KEYS.ID)
helper.getJoinedDate = membership => toDate(membership.get(KEYS.JOINED_DATE))
helper.getUser = membership => membership.get(KEYS.USER)
helper.getRole = membership => membership.get(KEYS.ROLE)

export default helper
