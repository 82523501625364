import { forwardRef, type RefObject, type SVGProps } from 'react'
import { memo } from 'react'
import { cn } from '@appfluence/classnames'
import './CircleInput.scss'

interface ICircleInputProps extends SVGProps<SVGSVGElement> {
  value?: number
  readOnly?: boolean
  className?: string
  componentRef?: RefObject<SVGSVGElement>
  onClick?: () => void
  text?: string
  color?: string
}

const CircleInput_ = forwardRef<SVGSVGElement, ICircleInputProps>(
  ({ value = 0, readOnly = false, className, onClick, text = '', color, ...rest }, ref) => {
    const svgClassName = cn('pm-circle-input', className, { readOnly })
    return (
      <svg viewBox="0 0 36 36" className={svgClassName} onClick={readOnly ? undefined : onClick} ref={ref} {...rest}>
        <path className="circle-bg" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
        <path
          className="circle"
          strokeDasharray={`${value}, 100`}
          style={{ stroke: color }}
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="18" y="20.9" className={'percentage'}>
          {text}
        </text>
      </svg>
    )
  }
)

export const CircleInput = memo(CircleInput_)
