export const DEFAULT_TEAMS_LOCATION = '__NONE__'

export const TEAMS_KEYS = {
  REDUCER: 'teams',
  LOCATION: 'location',
  CONVERSATION_ID: 'conversationId',
  TENANT_ID: 'tenantId',
  MEETING_ID: 'meetingId',
  SUB_ENTITY_ID: 'subEntityId',
  DID_LOAD_CONTEXT: 'didLoadContext',
  DID_CONSUME_DEEPLINK: 'didConsumeDeeplink',
  RAW_CONTEXT: 'raw_context',
}
