import _ from 'lodash'
import { safeMapFnCreator } from '../common/src/helpers/immutableHelpers'

let helper = {}

const KEYS = {
  KEY: 'key',
  STEP: 'step',
  COUNT: 'count',
  LIMIT: 'limit',
}
helper.KEYS = KEYS

helper.getKey = u => u.get(KEYS.KEY)
helper.getStep = u => u.get(KEYS.STEP)
helper.getCount = u => u.get(KEYS.COUNT)
helper.getLimit = u => u.get(KEYS.LIMIT)

helper = _.mapValues(helper, f => (_.isFunction(f) ? safeMapFnCreator(f) : f))
export default helper
