import { type ITextProps, type ILabelProps, Text as MSText } from '@fluentui/react'
import { Label as MSLabel } from '@fluentui/react/lib/Label'
import { cn } from '@appfluence/classnames'

export const Text = ({ className, ...rest }: ITextProps) => (
  <MSText className={cn('text-pm-neutral-primary', className)} {...rest} />
)

export const Label = ({ className, ...rest }: ILabelProps) => (
  <MSLabel className={cn('text-pm-neutral-primary', className)} {...rest} />
)
