import { createGraphResourceBase } from './graphResourceBase'
import { ICON_NAMES } from '../../msIcons'
import { GRAPH_RESOURCE_TYPE } from './graphResourceBase'

const helper = {}

helper.create = task => {
  return createGraphResourceBase(task, {
    name: task.subject || 'No name',
    icon: ICON_NAMES.TaskLogo,
    iconColor: '#0072c6',
    type: GRAPH_RESOURCE_TYPE.OUTLOOK_TASK,
  })
}

helper.isOutlookTask = m => m.type === GRAPH_RESOURCE_TYPE.OUTLOOK_TASK

export default helper
