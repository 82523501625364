import _ from 'lodash'
import { memo, useCallback } from 'react'
import { FrequencyDropdown } from '../../dropdown/FrequencyDropdown'
import { itemHelper } from '../../../common/src/helpers'
import { LocalizedDatePickerV9 } from '../../pickers/LocalizedDatePicker'
import { useTranslation } from 'react-i18next'
import { endOfDay } from 'date-fns'
import { InfoLabel } from '@fluentui/react-components'

export const ItemRecurrencePanelPart = memo(
  ({ frequency, untilDate, onChangeFrequency, onChangeUntilDate, frequencyKeys, isReminder = false }) => {
    const { t } = useTranslation()

    const onChangeUntilDateEndOfDay = useCallback(
      date => {
        const adjustedDate = date && endOfDay(date)
        onChangeUntilDate(adjustedDate)
      },
      [onChangeUntilDate]
    )

    const isRecurring = frequency && frequency !== itemHelper.FREQUENCY_TYPE.NEVER
    const hasChangeUntil = _.isFunction(onChangeUntilDate)
    return (
      <>
        <div className="flex flex-wrap-reverse">
          <FrequencyDropdown
            label={
              isReminder ? (
                <InfoLabel info={t('item.reminder.recurrence_tooltip')}>{t('date.recurrence')}</InfoLabel>
              ) : (
                t('date.recurrence')
              )
            }
            selectedKey={frequency}
            onChange={onChangeFrequency}
            frequencyKeys={frequencyKeys}
          />
        </div>
        {isRecurring && hasChangeUntil && (
          <LocalizedDatePickerV9
            label={t('date.until_date')}
            value={untilDate}
            onSelectDate={onChangeUntilDateEndOfDay}
          />
        )}
      </>
    )
  }
)
