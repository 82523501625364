import _ from 'lodash'

export const getFileList = evt => _.get(evt, 'dataTransfer.files') || _.get(evt, 'clipboardData.files')
export const getFileArray = evt => {
  const fileList = getFileList(evt)
  return fileList ? Array.from(fileList) : []
}
export const clearFileList = evt => {
  const dataTransfer = evt.dataTransfer
  if (dataTransfer && dataTransfer.clearAll) {
    dataTransfer.clearAll()
  }
}
