import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { cn } from '@appfluence/classnames'
import styled from 'styled-components'
import ContentEditable from 'react-contenteditable'
import { useTranslation } from 'react-i18next'
import { OneOnOneUserSelectionPanel } from '../../views/oneOnOne/OneOnOneUserSelectionPanel'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent as trackEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { sanitize } from '../chat/utils'

const Container = styled.div`
  box-sizing: border-box;
  flex: 1 1 auto;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  min-height: 20px;
  min-width: 0;
  outline: none;
  width: inherit;
  will-change: width;

  background-color: ${p => p.theme.palette.white};
  border: 1px solid ${p => p.theme.palette.white};
  border-radius: 5px;
  padding: 8px 10px 10px;
  margin: 5px 10px;
`

const SubContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  overflow: hidden;
`

const Placeholder = styled.div`
  color: ${p => p.theme.palette.neutralTertiary};
  font-size: 15px;
  line-height: 20px;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition:
    opacity 0.08s linear,
    visibility 0.08s linear;
  user-select: none;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
`
const Editable = styled(ContentEditable).attrs({
  disabled: false,
})`
  font-size: 15px;
  font-weight: 400;
  max-height: 60px;
  min-height: 20px;
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  z-index: 1;
  color: ${p => p.theme.palette.black};

  line-height: 20px;
  width: 100%;

  @media (min-height: 800px) {
    max-height: 100px;
  }
`

const trigger = '@'

export const CommentTextField = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const { comment, onChange, contentEditableProps, showMentionPanel, setShowMentionPanel } = props
  const editableRef = useRef(null)
  const dispatch = useDispatch()
  const [cursorMentionPosition, setCursorMentionPosition] = useState(0)

  useEffect(() => {
    if (ref) {
      ref.current = editableRef.current
    }
  }, [ref])

  useEffect(() => {
    const listener = e => {
      e.preventDefault()
      let text = ''
      if (e.clipboardData || e.originalEvent.clipboardData) {
        text = (e || e.originalEvent).clipboardData.getData('text/plain')
      } else if (window.clipboardData) {
        text = window.clipboardData.getData('Text')
      }
      if (document.queryCommandSupported('insertText')) {
        document.execCommand('insertText', false, text)
      } else {
        document.execCommand('paste', false, text)
      }
    }
    const currentEditableRef = editableRef.current
    currentEditableRef?.addEventListener?.('paste', listener)
    return () => {
      currentEditableRef?.removeEventListener?.('paste', listener)
    }
  }, [editableRef])

  const getTextFromHeadToCaret = element => {
    // Extracted from: https://stackoverflow.com/a/20834263
    let caretOffset = 0
    if (typeof window.getSelection !== 'undefined') {
      const range = window.getSelection().getRangeAt(0)
      const preCaretRange = range.cloneRange()
      preCaretRange.selectNodeContents(element)
      preCaretRange.setEnd(range.endContainer, range.endOffset)
      caretOffset = preCaretRange.toString().length
    } else if (typeof document.selection !== 'undefined' && document.selection.type !== 'Control') {
      const textRange = document.selection.createRange()
      const preCaretTextRange = document.body.createTextRange()
      preCaretTextRange.moveToElementText(element)
      preCaretTextRange.setEndPoint('EndToEnd', textRange)
      caretOffset = preCaretTextRange.text.length
    }
    return caretOffset
  }

  const shouldTriggerMention = useCallback((event, text) => {
    const character = event.nativeEvent?.data
    if (character !== trigger) {
      return false
    }
    if (text.length === 1) {
      return true
    }
    const position = getTextFromHeadToCaret(event.currentTarget)
    const plainText = text.replace(/<[^>]*>/g, '')
    // Mention must be initiated at the start of the paragraph or with a previous space
    // But this is an approach and we just allow for 1 mention
    if (plainText.length === text.length && (position === 1 || plainText[position - 2] === ' ')) {
      setCursorMentionPosition(position)
      return true
    }
    setCursorMentionPosition(0)
    return text.substring(text.length - 2, text.length) === ` ${character}`
  }, [])

  const getPrefixForMention = useCallback(
    text => {
      if (text[text.length - 1] === trigger) {
        return `${comment.substring(0, comment.length - 1)}`
      }
      return `${comment} `
    },
    [comment]
  )

  const focusInput = useCallback(() => {
    try {
      const input = editableRef.current
      const range = document.createRange()
      const windowSelection = window.getSelection()
      const child = input.childElementCount > 0 ? input.lastChild : input
      range.setStart(child, child.length)
      windowSelection.removeAllRanges()
      windowSelection.addRange(range)
    } catch (err) {
      console.error(err)
    }
  }, [])

  const showPanel = useCallback(() => {
    setShowMentionPanel(true)
  }, [setShowMentionPanel])
  const hidePanel = useCallback(() => {
    setShowMentionPanel(false)
    setTimeout(focusInput, 300)
  }, [focusInput, setShowMentionPanel])

  const handleChange = useCallback(
    event => {
      const text = event.target.value
      if (shouldTriggerMention(event, text)) {
        showPanel()
      }
      onChange(event, text)
    },
    [shouldTriggerMention, onChange, showPanel]
  )

  const onSelectUser = useCallback(
    (email, _, id) => {
      if (email && id) {
        if (cursorMentionPosition > 0) {
          const start = comment.substring(0, cursorMentionPosition - 1)
          const end = comment.substring(cursorMentionPosition, comment.length)
          const text = `${start}<b data-user-id="${id}">${email}</b> ${end}`
          onChange(null, text)
        } else {
          const prefix = getPrefixForMention(comment)
          const text = `${prefix}<b data-user-id="${id}">${email}</b> `
          onChange(null, text)
        }
        dispatch(trackEvent(AMPLITUDE_ACTION_TYPES.MENTION_USER))
      }
    },
    [comment, cursorMentionPosition, dispatch, getPrefixForMention, onChange]
  )

  const placeholderClassName = cn({ hidden: !_.isEmpty(comment) })
  return (
    <Container title={t('chat.text_field_tooltip')}>
      <SubContainer>
        <Placeholder className={placeholderClassName}>{t('chat.text_field_placeholder')}</Placeholder>
        <Editable {...contentEditableProps} innerRef={editableRef} html={sanitize(comment)} onChange={handleChange} />
        <OneOnOneUserSelectionPanel
          email={''}
          onChange={onSelectUser}
          headerText={t('chat.mention_header')}
          isOpen={showMentionPanel}
          onDismiss={hidePanel}
        />
      </SubContainer>
    </Container>
  )
})
