export const SEARCH_REDUCER_KEYS = {
  REDUCER: 'search',

  SECTION_SEARCH: 'section_search',
  SECTION_ONE_ON_ONE: 'section_one_on_one',
  SECTION_AGENDA: 'section_agenda',
  SECTION_MEETING: 'section_meeting',
  SECTION_PANEL: 'section_panel',
  SECTION_SINK: 'section_sink',

  ITEMS: 'items',
  PROJECTS: 'projects',
  SET: 'set',
  PARAMS: 'params',
}
