import { Trans } from 'react-i18next'
import { Link } from '@fluentui/react/lib/Link'
import { SERVER_URLS } from '../../common/src/constants'

export const UserGroupCalloutMessage = ({ onClickLink }) => (
  <Trans
    i18nKey="users.user_groups_explanation"
    components={{
      mylink: <Link onClick={onClickLink} href={SERVER_URLS.USER_GROUPS} target="_blank" underline />,
    }}
  />
)
