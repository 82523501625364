import React, { memo } from 'react'
import { ContainerPanel } from '../ContainerPanel'
import { ItemDetailView } from '../../../views/ItemDetail/ItemDetail'

const styles = {
  scrollableContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'auto',
    minHeight: 0,
  },
  content: {
    width: '100%',
    flex: 1,
    padding: 0,
  },
}

export const ItemPanel = memo(({ headerText = 'Item Details', ...rest }) => {
  return (
    <ContainerPanel headerText={headerText} styles={styles} {...rest}>
      <ItemDetailView />
    </ContainerPanel>
  )
})
