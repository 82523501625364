import { memo, useCallback, useId, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { useNarrowWidth } from '../../../hooks/useNarrowWidth'
import { CustomProjectTemplateList } from './CustomProjectTemplateList'
import { PublicProjectTemplateList } from './PublicProjectTemplateList'
import { useDeleteCustomProjectTemplate, usePublicProjectTemplateCategories } from '@/queries/projectTemplates'
import { PUBLIC_PROJECT_TEMPLATE_CATEGORY_ALL } from '@/constants/projectTemplates'
import { HiddenFileInput } from '../../../components/input/HiddenFileInput'
import { PROJECT_TEMPLATE_TYPE } from '../ProjectTemplateType'
import { NEW_PROJECT_SECTIONS } from '../../../helpers/routeHelper'
import { AlertModal } from '../../../components/modal/AlertModal'
import { useTranslation } from 'react-i18next'
import { InfoIconCallout } from '../../../components/info/InfoIconCallout'
import { Button, Combobox, Option, SearchBox, Tab, TabList, useToastController } from '@fluentui/react-components'
import { CreateProjectWithAI } from '../CreateProjectWithAI'
import { ArrowUpload, Sparkle } from '../../../components/BundledIcons'
import { FluentToast } from '../../../components/toast/FluentToast'
import { Virtuoso } from 'react-virtuoso'

const allPublicCategoryOption = { key: PUBLIC_PROJECT_TEMPLATE_CATEGORY_ALL, text: 'All' }

export const ProjectTemplateTabs = memo(({ selectedKey, onTabSelect, onSelect, aiEnabled }) => {
  const { dispatchToast, updateToast } = useToastController()
  const narrow = useNarrowWidth()
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const templateToDelete = useRef()
  const [selectedPublicCategory, setSelectedPublicCategory] = useState(PUBLIC_PROJECT_TEMPLATE_CATEGORY_ALL)
  const [publicCategorySearch, setPublicCategorySearch] = useState('')
  const { data: publicCategories } = usePublicProjectTemplateCategories(selectedKey === NEW_PROJECT_SECTIONS.PUBLIC)
  const fileInputRef = useRef(null)
  const { t } = useTranslation()
  const deleteTemplateToastId = useId()
  const [publicTemplateSearchText, setPublicTemplateSearchText] = useState('')

  const comboBoxOptions = useMemo(() => {
    return [allPublicCategoryOption, ...(publicCategories?.map(c => ({ key: c, text: _.startCase(c) })) ?? [])]
  }, [publicCategories])

  const onImport = useCallback(() => {
    fileInputRef.current.click()
  }, [])

  const onChangeFile = useCallback(
    evt => {
      const files = evt.target.files
      const file = _.first(files)
      onSelect({ type: PROJECT_TEMPLATE_TYPE.IMPORT_CSV, file })
      // Clean input value
      fileInputRef.current.value = null
    },
    [onSelect]
  )

  const onPressDelete = useCallback(
    template => {
      setShowConfirmDelete(true)
      templateToDelete.current = template
    },
    [setShowConfirmDelete]
  )

  const { mutate: deleteTemplate } = useDeleteCustomProjectTemplate()

  const onDelete = useCallback(() => {
    const template = templateToDelete.current
    dispatchToast(<FluentToast>{t('new_project.deleting_template')}</FluentToast>, {
      toastId: deleteTemplateToastId,
      intent: 'info',
      timeout: -1,
    })
    deleteTemplate(template.id, {
      onSuccess: () => {
        updateToast({
          toastId: deleteTemplateToastId,
          content: <FluentToast>{t('new_project.template_deleted_successfully')}</FluentToast>,
          intent: 'success',
          timeout: 5000,
        })
        templateToDelete.current = undefined
      },
      onError: error => {
        updateToast({
          toastId: deleteTemplateToastId,
          content: (
            <FluentToast>
              {t('new_project.template_deleted_error')}: {error.message}
            </FluentToast>
          ),
          intent: 'error',
          timeout: 5000,
        })
      },
    })
  }, [deleteTemplate, deleteTemplateToastId, dispatchToast, t, updateToast])

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex w-full justify-between">
        <TabList selectedValue={selectedKey} onTabSelect={onTabSelect}>
          <Tab value={NEW_PROJECT_SECTIONS.CUSTOM}>
            {narrow ? (
              'Private'
            ) : (
              <div className="flex items-center gap-1">
                {t('new_project.my_templates')}
                <InfoIconCallout
                  iconSize={18}
                  message={t('project_template.access_explanation_new_project')}
                  animate={false}
                />
              </div>
            )}
          </Tab>
          <Tab value={NEW_PROJECT_SECTIONS.PUBLIC}>{narrow ? 'Public' : t('new_project.public_templates')}</Tab>
          {aiEnabled && (
            <Tab value={NEW_PROJECT_SECTIONS.AI} icon={<Sparkle />}>
              {t('new_project.create_with_ai.tab_title')}
            </Tab>
          )}
        </TabList>
        <div className="flex items-center">
          <Button icon={<ArrowUpload />} onClick={onImport} appearance="subtle">
            {narrow ? undefined : t('project_template.import_from_csv')}
          </Button>
          <HiddenFileInput id="file" onChange={onChangeFile} ref={fileInputRef} accept=".csv" />
        </div>
      </div>
      {selectedKey === NEW_PROJECT_SECTIONS.CUSTOM && (
        <>
          <CustomProjectTemplateList onSelect={onSelect} onDelete={onPressDelete} />
          <AlertModal
            title={t('project_template.confirm_delete_custom_template')}
            subText={t('project_template.confirm_delete_custom_template_subtext')}
            open={showConfirmDelete}
            onDismiss={() => setShowConfirmDelete(false)}
            primaryActionText={t('general.delete')}
            onPrimaryActionClick={onDelete}
          />
        </>
      )}
      {selectedKey === NEW_PROJECT_SECTIONS.PUBLIC && (
        <>
          <div className="flex justify-end gap-2 pt-1">
            <SearchBox
              className="!w-48"
              appearance="underline"
              placeholder={t('new_project.search_templates_placeholder')}
              value={publicTemplateSearchText}
              onChange={(ev, data) => setPublicTemplateSearchText(data.value)}
            />
            <Combobox
              appearance="underline"
              className="!min-w-0"
              input={{ className: 'min-w-0' }}
              defaultSelectedOptions={[allPublicCategoryOption.key]}
              defaultValue={_.startCase(selectedPublicCategory)}
              onOptionSelect={(ev, data) => setSelectedPublicCategory(data.selectedOptions[0])}
              onChange={ev => setPublicCategorySearch(ev.target.value.trim())}
            >
              <Virtuoso
                style={{ height: 400 }}
                data={comboBoxOptions.filter(option =>
                  option.text.toLowerCase().includes(publicCategorySearch.toLowerCase())
                )}
                itemContent={(_, option) => (
                  <Option key={option.key} value={option.key}>
                    {option.text}
                  </Option>
                )}
              />
            </Combobox>
          </div>
          <PublicProjectTemplateList
            searchText={publicTemplateSearchText}
            selectedCategory={selectedPublicCategory}
            onSelect={onSelect}
          />
        </>
      )}
      {selectedKey === NEW_PROJECT_SECTIONS.AI && <CreateProjectWithAI onSelectTemplate={onSelect} />}
    </div>
  )
})
