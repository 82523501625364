import React, { memo, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { ActionButton } from '@fluentui/react/lib/Button'
import { ContainerPanel } from '../ContainerPanel'
import { PanelView } from '../PanelView'
import { LocalizedDatePicker } from '../../pickers/LocalizedDatePicker'
import { useDidChangeDate } from '../../../hooks/dateHooks'
import { useTranslation } from 'react-i18next'

const StyledClearButton = styled(ActionButton)`
  height: auto;
`

const DatePanelView = memo(({ startDate, dueDate, onChangeStartDate, onChangeDueDate, ...rest }) => {
  const onClearDate = useCallback(() => {
    onChangeStartDate()
    onChangeDueDate()
  }, [onChangeStartDate, onChangeDueDate])
  const { t } = useTranslation()

  const didChangeDate = useDidChangeDate()

  const didChangeStartDate = useMemo(() => {
    return didChangeDate(startDate, onChangeStartDate)
  }, [didChangeDate, startDate, onChangeStartDate])

  const didChangeDueDate = useMemo(() => {
    return didChangeDate(dueDate, onChangeDueDate)
  }, [didChangeDate, dueDate, onChangeDueDate])

  const headerChildren = (
    <StyledClearButton disabled={!(startDate || dueDate)} onClick={onClearDate}>
      {t('general.clear')}
    </StyledClearButton>
  )
  return (
    <PanelView headerChildren={headerChildren} {...rest}>
      <LocalizedDatePicker
        label={t('project_dates_panel.start_date')}
        value={startDate}
        onSelectDate={didChangeStartDate}
      />
      <LocalizedDatePicker label={t('project_dates_panel.due_date')} value={dueDate} onSelectDate={didChangeDueDate} />
    </PanelView>
  )
})

export const ProjectDatesPanel = memo(
  ({
    t,
    headerText = t('project_dates_panel.edit_dates'),
    startDate,
    dueDate,
    onChangeStartDate,
    onChangeDueDate,
    onDismiss,
    ...rest
  }) => {
    return (
      <ContainerPanel headerText={headerText} onDismiss={onDismiss} {...rest}>
        <DatePanelView
          startDate={startDate}
          dueDate={dueDate}
          onChangeStartDate={onChangeStartDate}
          onChangeDueDate={onChangeDueDate}
        />
      </ContainerPanel>
    )
  }
)
