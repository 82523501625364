/**
 * In our webapp, we rely on Webpush notifications. The purpose of this file is to handle notifications when we're
 * embedded in Electron.
 */

import { fromJS } from 'immutable'
import { ACTIONS as WS_ACTIONS } from '../common/src/websocket/wsActions'
import { itemHelper, stateHelper } from '../common/src/helpers'
import { getComment } from '../common/src/actions/commentsAPI'
import { API_EVENT, isPMApiDetailedAction, parseApiActionType } from '../common/src/helpers/reducerHelper'
import { getAvatarURIForEmail, PM_API_RESOURCE_TYPE, STATIC_RESOURCE } from '../common/src/constants'
import { uriDataCreator } from '../common/src/helpers/URLHelper'
import { linkSubject, SOURCES } from '../reactions/linkSubject'
import { isElectronApp } from '../integrations/electron'

const openItemWithID = id => {
  const urlData = uriDataCreator(1, PM_API_RESOURCE_TYPE.ITEM, id)
  linkSubject.next({ urlData, source: SOURCES.BROWSER_NOTIFICATION })
}

const onClickNotificationWithItemId = id =>
  function () {
    window.focus()
    this.close()
    openItemWithID(id)
  }

const unescapeComment = comment => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(comment, 'text/html')
  return doc.documentElement.textContent
}

export const notificationMiddleware = store => next => action => {
  if (!isElectronApp()) {
    //continue in case this is not Electron
    return next(action)
  }
  const { type, payload } = action
  switch (type) {
    case WS_ACTIONS.ITEM_RECEIVED: {
      const state = store.getState()
      const currentUserEmail = stateHelper.getMeEmail(state)
      const itemAsImmutable = fromJS(payload)
      if (!itemHelper.needsAttention(itemAsImmutable, currentUserEmail)) {
        break
      }
      const { name, id, completed_by_username, delegated_by_username } = payload
      const oldItem = stateHelper.getItem(state, id)
      if (oldItem) {
        if (itemHelper.checkCompletionNotification(itemAsImmutable, oldItem, currentUserEmail)) {
          new window.Notification(`Completed: ${name}`, {
            body: `By ${completed_by_username}`,
            icon: STATIC_RESOURCE.PM128,
          }).onclick = onClickNotificationWithItemId(id)
        } else if (itemHelper.checkDelegationNotification(itemAsImmutable, oldItem, currentUserEmail)) {
          // TODO add icon?
          // getAvatarURIForEmail(itemAsImmutable.get(itemHelper.KEYS.DELEGATED_BY_USERNAME))
          new window.Notification(`Delegated: ${name}`, {
            body: `By ${delegated_by_username}`,
            icon: getAvatarURIForEmail(itemAsImmutable.get(itemHelper.KEYS.DELEGATED_BY_USERNAME)),
          }).onclick = onClickNotificationWithItemId(id)
        } else if (itemHelper.checkCommentNotification(itemAsImmutable, oldItem)) {
          const commentID = itemHelper.getLastCommentID(itemAsImmutable)
          store.dispatch(getComment(commentID, { showOriginalText: 1, nohtml: 1 })).then(action => {
            const { type, payload } = action
            const apiAction = parseApiActionType(type)
            if (!isPMApiDetailedAction(apiAction)) {
              return
            }

            const { event } = apiAction
            if (event !== API_EVENT.SUCCESS) {
              return
            }

            const comment = payload
            if (itemHelper.checkCommentNotificationWithComment(itemAsImmutable, comment, currentUserEmail)) {
              const escapedText = comment.original_text ?? comment.text
              const text = unescapeComment(escapedText)
              const commentAuthor = comment.author_fullname ?? comment.author_email
              const itemName = itemHelper.getName(itemAsImmutable)
              if (!text || !itemName || !commentAuthor) {
                return
              }
              new window.Notification(`${commentAuthor} says:`, {
                body: `'${text}' on '${itemName}'`,
                icon: getAvatarURIForEmail(commentAuthor),
              }).onclick = onClickNotificationWithItemId(id)
            }
          })
        }
      } else {
        const editedByUsername = itemHelper.getEditedByUsername(itemAsImmutable)
        if (currentUserEmail === editedByUsername) {
          break
        }

        //we don't know what happened.. but we can notify update
        new window.Notification('Item Update', {
          body: name,
          icon: STATIC_RESOURCE.PM128,
        }).onclick = onClickNotificationWithItemId(id)
      }
      break
    }
    default:
      break
  }
  return next(action)
}
