import { forwardRef, memo } from 'react'
import { userHelper } from '../../../common/src/helpers'
import { getAvatarURIForEmail } from '../../../common/src/constants'
import { useTranslation } from 'react-i18next'
import { useMe } from '../../../common/src/hooks/usersHooks'
import { projectHelper } from '../../../common/src/helpers'
import { useSelectedProject } from '../../../hooks/PMHooks'
import { Button, Divider, Persona } from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { Copy, Mail, People } from '../../BundledIcons'
import { getRelativeURL } from '../../../helpers/routeHelper.js'
import { useHistory } from 'react-router-dom'
import { cn } from '@appfluence/classnames'

export const UserCollectionCell = memo(
  forwardRef(
    (
      {
        user,
        project,
        personaProps = {},
        disabled = false,
        readOnly = false,
        highlightAdmin = false,
        className,
        tooltipText,
        onShowError,
        hidePersonaDetails = false,
        placeholder,
        ...rest
      },
      ref
    ) => {
      const { avatar: avatarProps, primaryText: primaryTextProps, ...restPersonaProps } = personaProps
      let isInvited = false
      let content
      let admin = false
      const shouldShadeOwnerOnHover = rest.onClick && hidePersonaDetails
      const selectedProject = useSelectedProject()
      const email = userHelper.getEmail(user)
      const isCreator = projectHelper.getCreatorUsername(project ?? selectedProject) === email
      const isAdmin = projectHelper.isAdminUser(project ?? selectedProject, user)

      if (!user) {
        content = (
          <Persona
            className="!flex min-w-0 flex-1 items-center"
            textAlignment="center"
            avatar={{
              size: 24,
              ...avatarProps,
              className: cn(hidePersonaDetails && '!mr-0', avatarProps?.className),
            }}
            primaryText={{ className: 'truncate', children: placeholder }}
            {...restPersonaProps}
          />
        )
      } else {
        const fullName = userHelper.getFullName(user) || email
        isInvited = userHelper.isInvited(user)
        admin = isAdmin

        const renderAvatar = (Component, props) => (
          <UserDetailTooltip user={user} isAdmin={isAdmin} isCreator={isCreator} isInvited={isInvited}>
            <Component {...props} />
          </UserDetailTooltip>
        )

        content = (
          <>
            <Tooltip
              visible={tooltipText ? undefined : false}
              content={tooltipText}
              relationship={tooltipText ? 'description' : 'inaccessible'}
            >
              <Persona
                className="min-w-0 flex-1 items-center"
                textAlignment="center"
                name={fullName}
                primaryText={{
                  children: hidePersonaDetails ? null : fullName,
                  ...primaryTextProps,
                  className: cn('truncate', primaryTextProps?.className),
                }}
                avatar={{
                  name: fullName,
                  image: {
                    src: getAvatarURIForEmail(email),
                    className: shouldShadeOwnerOnHover ? 'hover:brightness-75 active:brightness-50 transition-all' : '',
                  },
                  size: 24,
                  ...avatarProps,
                  className: cn(
                    hidePersonaDetails && '!mr-0',
                    highlightAdmin && isAdmin && 'border-2 border-solid border-pm-theme-primary',
                    avatarProps?.className
                  ),
                  children: renderAvatar,
                }}
                {...restPersonaProps}
              />
            </Tooltip>
          </>
        )
      }

      const Component = rest.onClick ? 'button' : 'div'

      return (
        <Component
          className={cn(
            'border-none bg-transparent p-0',
            'box-content flex items-center',
            rest.onClick && 'cursor-pointer',
            hidePersonaDetails ? 'rounded-full' : 'rounded-sm',
            isInvited && 'border-pm-yellow',
            admin && 'border-pm-theme-primary',
            disabled && 'bg-pm-neutral-lighter text-pm-neutral-primary',
            readOnly && 'cursor-default',
            !hidePersonaDetails && rest.onClick && 'hover:bg-pm-neutral-lighter',
            className
          )}
          ref={ref}
          {...rest}
        >
          {content}
        </Component>
      )
    }
  )
)

const UserDetailTooltip = ({ user, children, isAdmin, isCreator, isInvited }) => {
  const name = userHelper.getFullName(user)
  const email = userHelper.getEmail(user)
  const avatarURL = getAvatarURIForEmail(email)
  const history = useHistory()
  const me = useMe()
  const itsMe = userHelper.getEmail(me) === email
  const { t } = useTranslation()

  const secondaryText = isCreator
    ? t('project_detail.creator')
    : isAdmin
      ? 'admin'
      : isInvited
        ? t('project_detail.invite_pending')
        : undefined

  if (!user) {
    return children
  }
  return (
    <Tooltip
      relationship="description"
      positioning={{ offset: 0 }}
      content={{
        onClick: event => event.stopPropagation(),
        className: '!max-w-none w-[20rem]',
        children: (
          <div className="box-border flex w-full flex-col gap-2">
            <header className="px-3 pb-1 pt-3">
              <Persona
                name={name}
                avatar={{ name, image: { src: avatarURL }, size: 40 }}
                textAlignment="center"
                primaryText={{ className: '!font-bold truncate' }}
                secondaryText={secondaryText && { children: secondaryText }}
                className="!justify-items-stretch"
              />
            </header>
            <Divider />
            <div className="group relative w-full">
              <Button
                appearance="subtle"
                as="a"
                icon={<Mail />}
                href={`mailto:${email}`}
                className="!w-full !justify-start !text-pm-theme-primary"
              >
                <span className="underline">{email}</span>
              </Button>
              <Button
                className="absolute right-0 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100"
                appearance="default"
                icon={<Copy />}
                onClick={() => {
                  navigator.clipboard.writeText(email)
                }}
                title={t('user_detail_tooltip.copy_email_tooltip')}
              />
            </div>
            {!itsMe && (
              <>
                <Divider />
                <Button
                  appearance="subtle"
                  as="a"
                  href={getRelativeURL.oneOnOneApp(email)}
                  icon={<People />}
                  onClick={event => {
                    history.push(getRelativeURL.oneOnOneApp(email))
                    event.preventDefault()
                  }}
                  className="!justify-start"
                >
                  {t('user_detail_tooltip.open_one_on_one', { name })}
                </Button>
              </>
            )}
          </div>
        ),
      }}
      showDelay={1000}
    >
      {children}
    </Tooltip>
  )
}
