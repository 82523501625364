/**
 * MS Icon names. Let's use this file until they offer a proper solution in their package
 * https://github.com/microsoft/fluentui/issues/7110
 * Extracted from deprecated { IconNames } from '@fluentui/react/lib/Icons';
 *
 * Right now, we keep the icons we really use so that we can save some kb in our bundle
 */
export const ICON_NAMES = {
  NavigateBack: 'NavigateBack',
  PageLock: 'PageLock',
  Share: 'Share',
  LocationDot: 'LocationDot',
  Page: 'Page',
  OpenPane: 'OpenPane',
  Read: 'Read',
  MailCheck: 'MailCheck',
  ReceiptCheck: 'ReceiptCheck',
  FavoriteStar: 'FavoriteStar',
  FavoriteStarFill: 'FavoriteStarFill',
  Flag: 'Flag',
  SignOut: 'SignOut',
  Add: 'Add',
  AddLink: 'AddLink',
  FontDecrease: 'FontDecrease',
  FontIncrease: 'FontIncrease',
  ChromeBack: 'ChromeBack',
  ChromeClose: 'ChromeClose',
  ChevronDown: 'ChevronDown',
  ChevronLeft: 'ChevronLeft',
  ChevronRight: 'ChevronRight',
  ChevronUp: 'ChevronUp',
  Inbox: 'Inbox',
  People: 'People',
  ActivityFeed: 'ActivityFeed',
  BookmarksMirrored: 'BookmarksMirrored',
  Calendar: 'Calendar',
  ReportDocument: 'ReportDocument',
  Search: 'Search',
  Cancel: 'Cancel',
  MailForwardMirrored: 'MailForwardMirrored',
  MailForward: 'MailForward',
  CircleAddition: 'CircleAddition',
  OpenInNewWindow: 'OpenInNewWindow',
  Home: 'Home',
  Link: 'Link',
  AddFriend: 'AddFriend',
  Send: 'Send',
  InfoSolid: 'InfoSolid',
  Attach: 'Attach',
  Completed: 'Completed',
  PlannerLogo: 'PlannerLogo',
  CollapseMenu: 'CollapseMenu',
  Filter: 'Filter',
  DateTime: 'DateTime',
  FilterSolid: 'FilterSolid',
  Event: 'Event',
  OneDriveLogo: 'OneDriveLogo',
  OutlookLogoInverse: 'OutlookLogoInverse',
  OutlookLogo: 'OutlookLogo',
  OfficeLogo: 'OfficeLogo',
  TaskLogo: 'TaskLogo',
  RecurringEvent: 'RecurringEvent',
  Trackers: 'Trackers',
  Edit: 'Edit',
  TeamsLogo: 'TeamsLogo',
  ZoomIn: 'ZoomIn',
  ZoomOut: 'ZoomOut',
  Checkbox: 'Checkbox',
  CheckboxComposite: 'CheckboxComposite',
  Copy: 'Copy',
  TextField: 'TextField',
  Delete: 'Delete',
  CommentActive: 'CommentActive',
  Unfavorite: 'Unfavorite',
  RedEye: 'RedEye',
  UserRemove: 'UserRemove',
  OpenInNewTab: 'OpenInNewTab',
  GridViewMedium: 'GridViewMedium',
  FabricMoveToFolder: 'FabricMoveToFolder',
  ChangeEntitlements: 'ChangeEntitlements',
  Lock: 'Lock',
  TextDocumentShared: 'TextDocumentShared',
  JoinOnlineMeeting: 'JoinOnlineMeeting',
  Ribbon: 'Ribbon',
}
