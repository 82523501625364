export const CYPRESS_ID = {
  USER_INPUT_CONTAINER: 'user_input_container',
  USER_PANEL_EDIT: 'user_panel_edit',
  CREATE_QUADRANT: q => `create_q${q}`,
  EDIT_PROJECT: 'edit_project',
  ONE_ON_ONE_SELECTION_BOX: 'one_on_one_selection_box',
  ONE_ON_ONE_ADD_TASK_TOP: 'one_on_one_add_task_top',
  ONE_ON_ONE_CREATE_TASK: 'one_on_one_add_create_task',
  MAIN_CONTAINER: 'main_container',
  SLATE_CONTAINER: 'slate_container',
  BACK: 'back',
}
