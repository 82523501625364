import _ from 'lodash'
import { queryParamsHelper } from '../helpers'
import { openLinkMessage } from './ExpoCommunication'
import { extractWebappRelativePath, URLParser } from '../common/src/helpers/URLHelper'
import { simulateLinkClick } from '../common/src/utils'
import { getLinkFromEvent } from '../common/src/helpers/webviewHelper'
import { linkSubject } from '../reactions/linkSubject'

export const externalLinkHandler = url => {
  if (queryParamsHelper.isEmbeddedOnMobile()) {
    openLinkMessage(url)
    return true
  }
  return false
}

const manageInternalLink = link => {
  let handled = false
  try {
    const knownDomains = {
      'sync.appfluence.com': 'prod',
      'prioritymatrix.com': 'prod',
    }
    const currentDomain = window.location.hostname
    const linkURL = new URL(link)
    const isSame = currentDomain === linkURL.hostname
    const bothKnown = knownDomains[currentDomain] && knownDomains[currentDomain] === knownDomains[linkURL.hostname]
    if (isSame || bothKnown) {
      const relativePath = extractWebappRelativePath(link)
      if (relativePath) {
        linkSubject.next({
          internalRelativePath: relativePath,
        })
        handled = true
      }
    }
  } catch (e) {
    console.log(e)
  }
  return handled
}

export const manageLink =
  (callback = _.identity, customHandleExternal, manageInternal = true) =>
  href => {
    if (!href) {
      return false
    }

    if (manageInternal && manageInternalLink(href)) {
      return true
    }

    const urlData = URLParser(href) || {}
    if (urlData.id && urlData.type !== 'file') {
      callback(urlData)
    } else {
      const handled = externalLinkHandler(href)
      if (!handled) {
        const shouldStop = customHandleExternal?.(href) || false
        if (shouldStop) {
          return false
        }
        simulateLinkClick(href, true)
      }
    }
    return true
  }

export const captureLinkClick =
  (callback = _.identity, customHandleExternal, manageInternal) =>
  evt => {
    const href = getLinkFromEvent(evt)
    if (manageLink(callback, customHandleExternal, manageInternal)(href)) {
      evt.preventDefault()
      evt.stopPropagation()
    }
  }

export const openExternalLink = href => {
  const handled = externalLinkHandler(href)
  if (!handled) {
    simulateLinkClick(href, true)
  }
}
