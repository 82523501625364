import { PMGlobalCalendar } from './PMGlobalCalendar'
import { MainLayout } from '../../components/layout/MainLayout'
import { RightDetailView } from '../rightDetailView/RightDetailView'
import { useRouteId } from '../../hooks/useRouteId'
import { ROUTE_ID } from '../../routes/routeIdList'
import { TeamsHeader } from '../../components/layout/TeamsHeader'

export const PMGlobalCalendarView = () => {
  const routeId = useRouteId()
  const inTeams = routeId === ROUTE_ID.CALENDAR_TEAMS
  const components = {
    header: inTeams ? <TeamsHeader title="Calendar" /> : null,
    main: <PMGlobalCalendar />,
    rightSide: <RightDetailView displayOptionsMenu={!inTeams} />,
  }
  return <MainLayout {...components} />
}
