import { useMediaQuery } from 'react-responsive'

export const useDesktop = () => {
  return useMediaQuery({ minWidth: 1024 })
}

export const Desktop = ({ children }) => {
  const isDesktop = useDefault()
  return isDesktop ? children : null
}

export const useTablet = () => {
  return useMediaQuery({ minWidth: 768, maxWidth: 1024 })
}

export const Tablet = ({ children }) => {
  const isTablet = useTablet()
  return isTablet ? children : null
}

export const useMobile = () => {
  return useMediaQuery({ maxWidth: 767 })
}
export const Mobile = ({ children }) => {
  const isMobile = useMobile()
  return isMobile ? children : null
}

export const useDefault = () => {
  return useMediaQuery({ minWidth: 768 })
}

export const Default = ({ children }) => {
  const isNotMobile = useDefault()
  return isNotMobile ? children : null
}
