import React, { memo } from 'react'
import _ from 'lodash'
import { NewProjectCard, ProjectCard } from './ProjectCards'
import { useLinkToCreateProject } from '../../hooks/projectHooks'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'

export const ProjectCardsSection = memo(({ showNewProjectCard = true, projects }) => {
  const { onClick: onNewProject, to: toNewProject } = useLinkToCreateProject(
    EVENT_EXTRA.CREATE_PROJECT.MODE.PROJECT_CARDS
  )

  const cards = _.map(projects, (project, index) => {
    return <ProjectCard key={`projectsCardsSection_${index}`} project={project} />
  })

  return (
    <>
      {showNewProjectCard && <NewProjectCard onClick={onNewProject} linkTo={toNewProject} />}
      {cards}
    </>
  )
})
