import React from 'react'
import { ItemsFiltersPanel } from './ItemsFiltersPanel'
import { ProjectMatrixFiltersView } from './ProjectMatrixFiltersView'

export const ProjectMatrixFiltersPanel = ({ ...rest }) => {
  return (
    <ItemsFiltersPanel {...rest}>
      <ProjectMatrixFiltersView />
    </ItemsFiltersPanel>
  )
}
