import { useMe } from '../../common/src/hooks/usersHooks'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { UserCollectionCell } from './usersCollection/UserCollectionCell'

export const MeComponent = () => {
  const me = useMe()
  const narrowWidth = useNarrowWidth()
  return (
    <div className="mr-2 min-w-[24px] flex-grow-0">
      <UserCollectionCell user={me} hidePersonaDetails={narrowWidth} />
    </div>
  )
}
