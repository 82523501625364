import styled from 'styled-components'

export let extenders = {}

export const teamsClassName = 'inside-ms-teams'

extenders.textField = e => styled(e)`
  &.${teamsClassName} label {
    font-weight: lighter;
  }
  &.${teamsClassName} div.ms-TextField-fieldGroup {
    background: ${p => p.theme.palette.neutralLight};
    border-radius: 3px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 2px solid transparent;
  }
  &.${teamsClassName} div.ms-TextField-fieldGroup:focus-within {
    border-bottom: 2px solid ${p => p.theme.palette.themePrimary};
  }
  &.${teamsClassName} span.ms-Dropdown-title {
    background: ${p => p.theme.palette.neutralLight};
  }
`

extenders.searchBox = e => styled(e)`
  &.${teamsClassName} {
    background: ${p => p.theme.palette.neutralLight};
    border-radius: 3px;
    border-width: 0;
    border-bottom: 2px solid transparent;
  }
  &.${teamsClassName}:focus-within {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 2px solid ${p => p.theme.palette.themePrimary};
  }
  &.${teamsClassName}:after {
    content: none !important;
  }
`
