import { useEffect } from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import { RouteWithId } from './routeWithID'
import { simulateLinkClick } from '../common/src/utils'
import { getLoginWithNext } from '../common/src/constants'
import * as queryParamsHelper from '../helpers/queryParamsHelper'
import { authSelectors } from '../common/src/selectors'
import { TrialMessageBar } from '../components/messageBar/TrialMessageBar'
import { ENABLE_PAYWALL } from '../environment'
import { useExtendedHistory } from '../hooks/useExtendedHistory'
import { getRelativeURLKeepingQuerySearch } from '../helpers/routeHelper'
import { BaseFlexContainer } from '../components/layout/FlexContainer'
import { objToQueryParamsString, queryParamsStringToObj } from '../common/src/helpers/functionalUtils'
import { extractWebappRelativePath } from '../common/src/helpers/URLHelper'
import { NotificationMessageBar } from '../components/messageBar/NotificationMessageBar'
import { RouteListener } from '../components/routeListener/routeListener'
import { useShouldDisplayUpgrade } from '@/hooks/userHooks'

const authContainer = connect(state => ({
  isAuthenticated: authSelectors.isAuthenticated(state),
  wasSignedOut: authSelectors.wasSignedOut(state),
}))

const usePaywall = excludePromotions => {
  const licensed = useSelector(authSelectors.hasLicense)
  const { history } = useExtendedHistory()
  useEffect(() => {
    if (ENABLE_PAYWALL && !excludePromotions && licensed === false) {
      const to = getRelativeURLKeepingQuerySearch.paywall()
      const sp = queryParamsStringToObj(to.search)
      sp.paywall_next = extractWebappRelativePath(window.location.href)
      to.search = objToQueryParamsString(sp)
      history.replace(to)
    }
  }, [licensed, history, excludePromotions])
}

const ParentContainer = styled(BaseFlexContainer)`
  flex-direction: column;
`

const Content = styled.div`
  flex: 1;
  min-height: 1px;
  width: 100%;
`

export const PrivateRoute = authContainer(
  ({ component: Component, isAuthenticated, wasSignedOut, loginPath, excludePromotions, render, ...rest }) => {
    const { history } = useExtendedHistory()

    const shouldDisplayUpgrade = useShouldDisplayUpgrade()
    useEffect(() => {
      if (isAuthenticated) {
        return
      }
      const next = queryParamsHelper.getNextQueryParamFromCurrentLocation(wasSignedOut)
      const link = getLoginWithNext(
        next,
        queryParamsHelper.isEmbedded(),
        queryParamsHelper.isEmbeddedOnTeams(),
        loginPath
      )
      simulateLinkClick(link)
      const to = getRelativeURLKeepingQuerySearch.loading()
      history.push(to)
    }, [isAuthenticated, wasSignedOut, history, loginPath])

    usePaywall(excludePromotions)
    const showTrialMessageBar = !excludePromotions && shouldDisplayUpgrade

    const _render = props => {
      if (isAuthenticated) {
        const localRender = () => {
          if (Component) {
            return p => <Component {...p} />
          } else if (render) {
            return render
          }
        }
        return (
          <ParentContainer>
            <RouteListener routeId={rest.routeId} />
            <NotificationMessageBar />
            {showTrialMessageBar && <TrialMessageBar />}
            <Content>{localRender()(props)}</Content>
          </ParentContainer>
        )
      }
      return false
    }
    return <RouteWithId render={_render} {...rest} />
  }
)
