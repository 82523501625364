import _ from 'lodash'
import { RSAA } from 'redux-api-middleware'
import { generateActionTypesForRequest } from '../helpers/reducerHelper'
import { stateHelper, graphStateHelper } from '../helpers'
import { APIS, BASE_URL, GRAPH_RESOURCES, PM_API_URLS, GRAPH_URLS } from '../constants'
import { USERS_KEYS } from '../reducers/userKeys'
import { GRAPH_RESOURCE_TYPE } from '../helpers/graph/graphResourceBase'
import { appendQueryParamsToURL, extendPMHeaders, getCredentialsConfig } from '../helpers/requestHelper'
import { unsafeObjToQueryParams } from '../helpers/functionalUtils'
import { format } from 'date-fns/fp'

export const _generateActionTypes = _.partial(generateActionTypesForRequest, APIS.GRAPH_PROXY)

const fmtDate = format('yyyy-MM-dd')

export const getGroupMembers = groupId => {
  const graphURL = `https://graph.microsoft.com/v1.0/groups/${groupId}/members/microsoft.graph.user`
  const params = {
    q: graphURL,
  }
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(PM_API_URLS.GRAPH_PROXY, params),
      credentials: getCredentialsConfig(),
      method: 'GET',
      types: _generateActionTypes(GRAPH_RESOURCES.GROUP_MEMBERS),
      headers: extendPMHeaders(),
    },
  }
}

export const getMatchingCollaboratorsFromGroup = groupId => {
  const url = `${BASE_URL}graph_api/group_members/${groupId}/`
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(url),
      credentials: getCredentialsConfig(),
      method: 'GET',
      types: _generateActionTypes(GRAPH_RESOURCES.PM_COLLABORATORS_IN_GROUP),
      headers: extendPMHeaders(),
    },
  }
}

export const graphRequest = (query, requestType, meta = {}) => {
  const url = PM_API_URLS.GRAPH_PROXY
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(url, { q: query }),
      credentials: getCredentialsConfig(),
      method: 'GET',
      types: _generateActionTypes(requestType, meta),
      headers: extendPMHeaders(),
    },
  }
}

export const resetGraphData = () => dispatch => {
  return dispatch({ type: GRAPH_RESOURCE_TYPE.RESET_GRAPH })
}

export const getGraphCollaborators = () => (dispatch, getState) => {
  if (!graphStateHelper.isGraphEnabled(getState())) {
    return Promise.resolve()
  }
  return dispatch(graphRequest(GRAPH_URLS.USERS, GRAPH_RESOURCES.GRAPH_COLLABORATORS))
}

export const getOutlookMails = (queryParams, meta) => (dispatch, getState) => {
  if (!graphStateHelper.isGraphEnabled(getState())) {
    return Promise.resolve()
  }
  const url = GRAPH_URLS.EMAILS + queryParams
  return dispatch(graphRequest(url, GRAPH_RESOURCES.OUTLOOK_EMAILS, meta))
}

export const getOutlookMailsWithUser = (username, top = 40) => {
  const search = `&$search="from:${username}%20OR%20to:${username}"`
  const queryParams = `?$top=${top}${search}`
  const meta = { username }
  return getOutlookMails(queryParams, meta)
}

export const getOutlookMailsWithText = (text, top = 40) => {
  const search = text ? `&$search="${text}"` : `&$filter=subject gt ''`
  const queryParams = `?$top=${top}${search}`
  const meta = { text }
  return getOutlookMails(queryParams, meta)
}

export const getCalendarEvents = (queryParams, meta) => (dispatch, getState) => {
  if (!graphStateHelper.isGraphEnabled(getState())) {
    return Promise.resolve()
  }
  const url = GRAPH_URLS.EVENTS + queryParams
  return dispatch(graphRequest(url, GRAPH_RESOURCES.CALENDAR_EVENTS, meta))
}

export const getCalendarViewEvents = (queryParams, meta) => (dispatch, getState) => {
  if (!graphStateHelper.isGraphEnabled(getState())) {
    return Promise.resolve()
  }
  const url = GRAPH_URLS.CALENDAR_VIEW_EVENTS + queryParams
  return dispatch(graphRequest(url, GRAPH_RESOURCES.CALENDAR_EVENTS, meta))
}

/**
 * @param {Date} start
 * @param {Date} end
 * @param {Object} params
 */
export const getCalendarViewEventsInRange = ({ start, end, params = {} }) => {
  const transform = _.flow([
    _.partial(_.mapValues, _, fmtDate),
    o => ({ ...o, ...params }),
    unsafeObjToQueryParams,
    s => `?${s}`,
  ])
  const baseParams = {
    startDateTime: start,
    endDateTime: end,
  }
  return getCalendarViewEvents(transform(baseParams))
}

export const getCalendarEventsWithUser =
  (username, top = 40) =>
  (dispatch, getState) => {
    // Right filter would be this but it doesn't work
    // const filter = `&$filter=attendees/any(a: a/emailAddress/name eq 'abc@xyz.com')`
    const state = getState()
    const graphUser = state.getIn([
      USERS_KEYS.REDUCER,
      USERS_KEYS.GRAPH_COLLABORATORS,
      username,
      USERS_KEYS.GRAPH_PROFILE,
    ])
    const organizer = graphUser ? graphUser.get('displayName') : username
    const now = fmtDate(new Date())
    const filter = `&$filter=organizer/emailAddress/name eq '${organizer}' and start/dateTime gt '${now}'`
    const queryParams = `?$top=${top}&$orderby=start/dateTime${filter}`
    const meta = { username }
    return dispatch(getCalendarEvents(queryParams, meta))
  }

export const getCalendarEventsWithText = (text, top = 40) => {
  const now = fmtDate(new Date())
  const filter = `&$filter=startswith(subject,'${text}') and start/dateTime gt '${now}'`
  const queryParams = `?$top=${top}&$orderby=start/dateTime${filter}`
  const meta = { text }
  return getCalendarEvents(queryParams, meta)
}

export const getDriveFiles = (queryParams, meta) => (dispatch, getState) => {
  if (!graphStateHelper.isGraphEnabled(getState())) {
    return Promise.resolve()
  }
  const url = GRAPH_URLS.FILES + queryParams
  return dispatch(graphRequest(url, GRAPH_RESOURCES.DRIVE_FILES, meta))
}

export const getDriveFilesWithUser = username => (dispatch, getState) => {
  if (!graphStateHelper.isGraphEnabled(getState())) {
    return Promise.resolve()
  }
  const queryParams = `?orderby=lastModifiedDateTime desc`
  const url = _.template(GRAPH_URLS.SHARED_FILES)({ username }) + queryParams
  const meta = { username }
  return dispatch(graphRequest(url, GRAPH_RESOURCES.DRIVE_FILES, meta))
}

export const getDriveSharedFiles = () => (dispatch, getState) => {
  if (!graphStateHelper.isGraphEnabled(getState())) {
    return Promise.resolve()
  }
  const queryParams = '?orderby=lastModifiedDateTime desc'
  const url = GRAPH_URLS.SHARED_FILES_WITH_ME + queryParams
  const meta = {}
  return dispatch(graphRequest(url, GRAPH_RESOURCES.SHARED_FILES, meta))
}

export const getDriveFilesWithText = text => {
  const queryParams = `/search(q='${text}')?orderby=lastModifiedDateTime desc`
  const meta = { text }
  return getDriveFiles(queryParams, meta)
}

export const getOutlookTasks = (queryParams, meta) => (dispatch, getState) => {
  if (!graphStateHelper.isGraphEnabled(getState())) {
    return Promise.resolve()
  }
  const url = GRAPH_URLS.OUTLOOK_TASKS + queryParams
  return dispatch(graphRequest(url, GRAPH_RESOURCES.OUTLOOK_TASKS, meta))
}

export const getOutlookTaskWithText = (text, top = 40) => {
  const now = fmtDate(new Date())
  const filter = `&$filter=startswith(subject,'${text}') and dueDateTime/dateTime gt '${now}'`
  const queryParams = `?$top=${top}&$orderby=dueDateTime/dateTime${filter}`
  const meta = { text }
  return getOutlookTasks(queryParams, meta)
}

export const getResourcesByText =
  (text, top = 40) =>
  dispatch =>
    Promise.all([
      dispatch(getOutlookMailsWithText(text, top)),
      dispatch(getCalendarEventsWithText(text, top)),
      dispatch(getOutlookTaskWithText(text, top)),
      dispatch(getDriveFilesWithText(text)),
      dispatch(getPlannerTasksByText(text)),
    ])

export const getResourcesByUser = user => dispatch =>
  Promise.all([
    dispatch(getOutlookMailsWithUser(user)),
    dispatch(getCalendarEventsWithUser(user)),
    dispatch(getDriveSharedFiles()),
  ])

const getPlannerPlans = () => (dispatch, getState) => {
  const state = getState()
  if (!graphStateHelper.isGraphEnabled(state)) {
    return Promise.resolve()
  }
  return dispatch(graphRequest(GRAPH_URLS.PLANS, GRAPH_RESOURCES.PLANNER_PLANS))
}

const getPlannerTasksByPlan = plan => (dispatch, getState) => {
  const state = getState()
  if (!graphStateHelper.isGraphEnabled(state)) {
    return Promise.resolve()
  }

  const url = _.template(GRAPH_URLS.PLANNER_TASKS)({ id: plan.id })
  const users = stateHelper.getGraphCollaboratorsMapById(state)
  const me = stateHelper.getMe(state)
  const email = me.get(USERS_KEYS.EMAIL)
  const domain = email.split('@').pop()
  const graphMe = stateHelper.getGraphCollaboratorWithEmail(state, email)
  // TODO: Save and Get graphMeId from better place because this is a problem when mail differs
  const graphMeId = graphMe && graphMe.getIn([USERS_KEYS.GRAPH_PROFILE, USERS_KEYS.ID])
  if (!graphMeId) {
    //This happens when an pm mail differs from graph mail
    return Promise.resolve('Missing graph user')
  }
  const meta = { plan: plan.title, users, graphMeId, domain }
  return dispatch(graphRequest(url, GRAPH_RESOURCES.PLANNER_TASKS, meta))
}

export const getAllPlannerTasks =
  (limit = 5) =>
  async (dispatch, getState) => {
    const state = getState()
    if (!graphStateHelper.isGraphEnabled(state)) {
      return Promise.resolve()
    }

    const plans = await dispatch(getPlannerPlans())
    const planArray = _.get(plans, 'payload.value')
    if (planArray) {
      const withLimit = _.take(planArray, limit)
      _.map(withLimit, p => dispatch(getPlannerTasksByPlan(p)))
    }
  }

export const getPlannerTasksByText = text => async (dispatch, getState) => {
  const state = getState()
  if (!graphStateHelper.isGraphEnabled(state)) {
    return Promise.resolve()
  }

  const resources = graphStateHelper.getAll(state)
  const elements = resources.filter(
    e =>
      e &&
      e.get('type') === GRAPH_RESOURCE_TYPE.PLANNER_TASK &&
      _.includes(e.get('name').toLowerCase(), text.toLowerCase())
  )
  const meta = { text }
  const payload = { elements: elements }
  return dispatch({ type: GRAPH_RESOURCE_TYPE.PLANNER_TASK, payload, meta })
}

export const getNextPage = (nextLink, type) => dispatch => {
  //TODO figure out how to pass meta here, to append text/username info to redux action
  return dispatch(graphRequest(nextLink, type))
}

export const postShareNewTab = params => {
  const body = JSON.stringify(params)
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(PM_API_URLS.SHARE_NEW_TEAMS_TAB),
      credentials: getCredentialsConfig(),
      method: 'POST',
      types: _generateActionTypes(GRAPH_RESOURCES.SHARE_NEW_TEAMS_TAB),
      headers: extendPMHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      }),
      body,
    },
  }
}

export const exchangeToken = params => {
  const body = JSON.stringify(params)
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(PM_API_URLS.GRAPH_EXCHANGE_TOKEN),
      credentials: getCredentialsConfig(),
      method: 'POST',
      types: _generateActionTypes(GRAPH_RESOURCES.EXCHANGE_TOKEN),
      headers: extendPMHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      }),
      body,
    },
  }
}
