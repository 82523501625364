import { useEffect, useCallback, useState, useMemo, useRef } from 'react'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { MainLayoutMainContext } from '../../contexts'
import { CollapsedRightPanel } from '../rightPanel/CollapsedRightPanel'
import { Button } from '@fluentui/react-components'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import { useResizeDetector } from 'react-resize-detector'
import { ProjectsPanel } from './ProjectsPanel'
import { useMediaQuery } from 'react-responsive'
import { ROUTE_ID } from '../../routes/routeIdList'
import { isInApp } from '../../helpers/routeHelper'
import { useSelectedItemId } from '../../hooks/PMHooks'
import { useRouteId } from '../../hooks/useRouteId'
import { useLocation } from 'react-router'
import { IosArrowLtr24Regular, IosArrowRtl24Regular } from '@fluentui/react-icons'
import { cn } from '@/modules/classnames'

const ComposedMain = ({ children }) => {
  const { ref, width } = useResizeDetector({ handleHeight: false })
  const dimensions = useMemo(() => ({ width }), [width])
  return (
    <MainLayoutMainContext.Provider value={dimensions}>
      <div className="relative flex h-full w-full flex-col" ref={ref}>
        {children}
      </div>
    </MainLayoutMainContext.Provider>
  )
}

const FixedSplitter = () => <div className="h-full w-0.5 bg-pm-neutral-light"></div>

const ExcludeSet = new Set([
  ROUTE_ID.GLOBAL_CALENDAR_APP,
  ROUTE_ID.GLOBAL_GANTT_APP,
  ROUTE_ID.PROJECTS,
])

const Content = ({ header, main, rightSide, fixed = false, autoSaveId }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [collapsedProjects, setCollapsedProjects] = useState(false)
  const narrow = useNarrowWidth()
  const selectedItemId = useSelectedItemId()
  const rightPanelRef = useRef(null)
  const projectsPanelRef = useRef(null)
  const routeId = useRouteId()
  const location = useLocation()
  const [isResizingProjectsPanel, setIsResizingProjectsPanel] = useState(false)
  const [isResizingRightPanel, setIsResizingRightPanel] = useState(false)

  const collapseProjectsPanel = useCallback(() => {
    projectsPanelRef.current?.collapse()
  }, [])
  const expandProjectsPanel = useCallback(() => {
    projectsPanelRef.current?.expand()
  }, [])

  const onClickOpen = useCallback(evt => {
    rightPanelRef.current?.expand()
  }, [])

  const onClickSplitterButton = useCallback(evt => {
    if (rightPanelRef.current?.isCollapsed()) {
      rightPanelRef.current?.expand()
    } else {
      rightPanelRef.current?.collapse()
    }
  }, [])

  useEffect(() => {
    if (selectedItemId !== null && rightPanelRef.current?.isCollapsed()) {
      rightPanelRef.current?.expand()
    }
  }, [selectedItemId])

  const isWideWindow = useMediaQuery({ query: '(min-width: 1000px)' })

  if (!rightSide || narrow) {
    return (
      <div className="flex h-full flex-col">
        {header}
        <div className="min-h-0 flex-1">{main}</div>
      </div>
    )
  }

  return (
    <div className="flex h-full flex-col">
      {header}
      <PanelGroup className="min-h-0 flex-1" direction="horizontal" autoSaveId={autoSaveId}>
        {isInApp(location.pathname) && !ExcludeSet.has(routeId) && isWideWindow && (
          <>
            {collapsedProjects && (
              <ProjectsPanel collapsed collapsePanel={collapseProjectsPanel} expandPanel={expandProjectsPanel} />
            )}
            <Panel
              ref={projectsPanelRef}
              onResize={size => {
                setCollapsedProjects(size === 0)
              }}
              collapsible
              minSize={15}
              maxSize={25}
              defaultSize={15}
            >
              <ProjectsPanel collapsePanel={collapseProjectsPanel} expandPanel={expandProjectsPanel} />
            </Panel>
            <PanelResizeHandle
              onDragging={setIsResizingProjectsPanel}
              className={cn(
                'w-0.5 bg-pm-neutral-tertiary-alt transition-all',
                isResizingProjectsPanel && 'w-1 bg-pm-theme-primary'
              )}
            />
          </>
        )}
        <Panel order={1}>{main}</Panel>
        {fixed ? (
          <FixedSplitter />
        ) : (
          <PanelResizeHandle
            onDragging={setIsResizingRightPanel}
            className={cn(
              'relative w-0.5 bg-pm-neutral-tertiary-alt transition-all',
              isResizingRightPanel && 'w-1 bg-pm-theme-primary'
            )}
          >
            <Button
              appearance="transparent"
              onClick={onClickSplitterButton}
              className="absolute -right-1.5 top-1/2 z-50 -translate-y-1/2"
              icon={
                collapsed ? (
                  <IosArrowLtr24Regular className="translate-x-0.5" />
                ) : (
                  <IosArrowRtl24Regular className="-translate-x-1" />
                )
              }
              iconProps={{ iconName: collapsed ? 'ChevronLeft' : 'ChevronRight' }}
            />
          </PanelResizeHandle>
        )}
        <Panel
          order={2}
          ref={rightPanelRef}
          maxSize={45}
          minSize={15}
          defaultSize={30}
          collapsible
          onResize={size => {
            setCollapsed(size === 0)
          }}
        >
          <div className="h-full overflow-y-auto overflow-x-hidden">{rightSide}</div>
        </Panel>
        {collapsed && (
          <div className="w-11">
            <CollapsedRightPanel opener={onClickOpen} />
          </div>
        )}
      </PanelGroup>
    </div>
  )
}

export const MainLayout = ({ header, topBar, main, rightSide, fixed, children, autoSaveId = 'MainAndRight' }) => {
  const composedMain = (
    <ComposedMain>
      {!!topBar && <div className="z-10">{topBar}</div>}
      <div className="relative min-h-0 w-full flex-1 overflow-y-auto overflow-x-hidden">{main}</div>
    </ComposedMain>
  )
  return (
    <div className="relative h-full w-full p-0">
      <Content header={header} rightSide={rightSide} main={composedMain} fixed={fixed} autoSaveId={autoSaveId} />
      {children}
    </div>
  )
}
