import { fromJS, Map } from 'immutable'
import {
  API_EVENT,
  isPMApiDetailedAction,
  parseApiActionType,
  PERSIST_REHYDRATE_ACTION_TYPE,
} from '../helpers/reducerHelper'
import { APIS, GRAPH_RESOURCES, PM_API_RESOURCES, PM_TEAMS_HELPER_RESOURCES } from '../constants'
import _ from 'lodash'
import { GENERIC_ACTION_TYPE } from '../actions/genericActions'
import { USERS_KEYS as KEYS } from './userKeys'
import userHelper from '../helpers/userHelper'

//this will contain /o/token/ response in immutable format
const base = Map({
  [KEYS.ME]: null,
  [KEYS.COLLABORATORS]: Map(),
  [KEYS.PLATFORMS]: null,
  [KEYS.GRAPH_COLLABORATORS]: Map(),
  [KEYS.TEAMS_MEMBERS]: Map(),
})

const getPMUser = c => {
  const pm_user = _.get(c, 'pm_user')
  if (pm_user) {
    return fromJS(pm_user)
  }
  return null
}
const createUserFromExternalUser = externalUser => {
  const pm_user = getPMUser(externalUser)
  if (pm_user) {
    return pm_user
  }

  const email = externalUser.mail || externalUser.email || externalUser.userPrincipalName
  return fromJS({
    email,
    [userHelper.KEYS.FULL_NAME]: externalUser.displayName,
    [userHelper.KEYS.FIRST_NAME]: externalUser.givenName,
    [userHelper.KEYS.LAST_NAME]: externalUser.surname,
  })
}

const parseMsMemberAssociatedUser = (state, memberArray = []) => {
  if (memberArray.length) {
    state = state.withMutations(_st => {
      _.each(memberArray, m => {
        const user = createUserFromExternalUser(m)
        const email = userHelper.getEmail(user)
        _st.setIn([KEYS.COLLABORATORS, email], user)
      })
      return _st
    })
  }
  return state
}

const parseTeamsMembers = (state, memberArray = []) =>
  state.withMutations(_st => {
    _.each(memberArray, c => {
      const user = createUserFromExternalUser(c)
      const email = userHelper.getEmail(user)
      _st.setIn([KEYS.TEAMS_MEMBERS, email], user)
    })
  })

const parseGraphMembers = (state, memberArray = []) =>
  state.withMutations(_st => {
    _.each(memberArray, c => {
      // TODO: It would be a good idea to add 'pm_user' field to
      //  graph users to know if the user has account in PM.
      const user = createUserFromExternalUser(c)
      const email = userHelper.getEmail(user)
      _st.setIn([KEYS.GRAPH_COLLABORATORS, email], fromJS(user))
      _st.setIn([KEYS.GRAPH_COLLABORATORS, email, KEYS.GRAPH_PROFILE], fromJS(c))
    })
  })

const apiActionHandler = {
  [PM_API_RESOURCES.ME]: (state, { payload }) => {
    return state.set(KEYS.ME, fromJS(payload))
  },
  [PM_API_RESOURCES.COLLABORATORS]: (state, { payload }) => {
    return state.withMutations(_st => {
      _.each(payload.objects, c => {
        _st.setIn([KEYS.COLLABORATORS, c.email], fromJS(c))
      })
      return _st
    })
  },
  [PM_API_RESOURCES.MS_COLLABORATORS]: (state, { payload }) => {
    state = parseMsMemberAssociatedUser(state, payload.objects)
    state = parseGraphMembers(state, payload.objects)
    return state
  },
  [PM_API_RESOURCES.PLATFORMS]: (state, { payload }) => {
    return state.set(KEYS.PLATFORMS, fromJS(payload))
  },
  [PM_API_RESOURCES.LICENSE_STATUS]: (state, { payload }) => {
    return state.set(KEYS.PLATFORMS, fromJS(payload.platforms))
  },
}

export const users = (state = base, action) => {
  const { type, payload } = action
  const apiAction = parseApiActionType(type)
  if (apiAction) {
    const { api, resource, event } = apiAction
    if (isPMApiDetailedAction(apiAction)) {
      if (event === API_EVENT.SUCCESS) {
        //Only apply changes on success
        const fn = apiActionHandler[resource]
        state = fn ? fn(state, action) : state
      }
    } else if (api === APIS.GRAPH_PROXY && event === API_EVENT.SUCCESS) {
      if (resource === GRAPH_RESOURCES.PM_COLLABORATORS_IN_GROUP) {
        const memberArray = payload.value
        state = parseMsMemberAssociatedUser(state, memberArray)
        state = parseGraphMembers(state, memberArray)
      } else if (resource === GRAPH_RESOURCES.GRAPH_COLLABORATORS) {
        // When graph user have pm_use
        //state = parseMsMemberAssociatedUser(state, memberArray)
        state = parseGraphMembers(state, payload.value)
      }
    } else if (api === APIS.PM_TEAMS_HELPER && event === API_EVENT.SUCCESS) {
      if (resource === PM_TEAMS_HELPER_RESOURCES.MEMBERS_IN_CONVERSATION) {
        const memberArray = payload
        state = parseMsMemberAssociatedUser(state, memberArray)
        state = parseTeamsMembers(state, memberArray)
      }
    }
  } else {
    switch (type) {
      case PERSIST_REHYDRATE_ACTION_TYPE:
        {
          if (payload.users) {
            const filteredState = payload.users.filter((v, k) => k === KEYS.ME)
            state = state.merge(filteredState)
          }
        }
        break
      case GENERIC_ACTION_TYPE.CLEAR_ALL:
        {
          state = base
        }
        break
    }
  }
  return state
}
