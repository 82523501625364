import { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { AttachFilled } from '@fluentui/react-icons'
import { clearFileList, getFileArray } from '../../common/src/helpers/ReactEventHelper'
import { isInternetExplorer } from '../../common/src/helpers/platformHelper'
import { FlexColumn } from '../layout/FlexContainer'
import { isElectronApp } from '../../integrations/electron'
import { FluentToast } from '../toast/FluentToast'
import { useToastController, ToastTrigger, Link } from '@fluentui/react-components'
import { SERVER_URLS } from '../../common/src/constants'
import { useTranslation } from 'react-i18next'
import { isAppleMailLink } from '../../integrations/appleMail'

const Container = styled.div`
  position: relative;
  flex: 1;

  width: 100%;
  height: 100%;
`

const SubContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: dashed ${p => p.theme.palette.neutralPrimaryAlt} 2px;
  border-radius: 5px;
  background-color: ${p => p.theme.palette.overlay};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
`

const CenteredContainer = styled(FlexColumn)`
  align-items: center;
  justify-content: center;

  position: relative;
  width: 80%;

  color: ${p => p.theme.palette.themePrimary};
`

const DraggingPlaceholder = styled(FlexColumn)`
  align-items: center;
  justify-content: center;

  position: absolute;
`

const useShowAppleMailBanner = () => {
  const { t } = useTranslation()
  const { dispatchToast } = useToastController()
  return useCallback(
    elementType => {
      const showBanner = elementType === 'AppleMail' && !isElectronApp()
      if (showBanner) {
        const footer = (
          <ToastTrigger>
            <Link href={SERVER_URLS.MANAGE_DOWNLOADS} target="_blank">
              {t('general.link')}
            </Link>
          </ToastTrigger>
        )
        const toast = <FluentToast footer={footer}>{t('quadrant.drag_and_drop_apple_mail')}</FluentToast>
        dispatchToast(toast, {
          timeout: -1,
        })
      }
    },
    [dispatchToast, t]
  )
}

export const SupportedTypes = {
  Text: isInternetExplorer() ? 'Text' : 'text/plain',
  Files: 'Files',
  Link: 'text/uri-list',
}

export const DragAndDrop = ({
  onDrop,
  children,
  supportedTypes = _.values(SupportedTypes),
  placeholdersByType = {
    [SupportedTypes.Files]: 'Drop a file here',
    [SupportedTypes.Link]: 'Drop a link here',
    [SupportedTypes.Text]: 'Drop text here',
  },
  styles = {},
  className,
  ...rest
}) => {
  const [dragging, setDragging] = useState(false)
  const [primaryType, setPrimaryType] = useState(SupportedTypes.Files)
  const dragCounterRef = useRef(0)

  const handleDrag = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()
    e.dataTransfer.effectAllowed = 'copy'
    e.dataTransfer.dropEffect = 'copy'
    return false
  }, [])

  const handleDragIn = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()

      dragCounterRef.current += 1

      const types = e.dataTransfer.types
      const newPrimaryType = _.find(supportedTypes, t => _.includes(types, t))
      if (!newPrimaryType) {
        return
      }

      setPrimaryType(newPrimaryType)
      setDragging(true)
    },
    [supportedTypes]
  )

  const handleDragOut = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()

      dragCounterRef.current -= 1
      if (dragCounterRef.current === 0) {
        setDragging(false)
      }
    },
    [dragCounterRef, setDragging]
  )

  const showAppleMailBanner = useShowAppleMailBanner()

  const asyncDrop = useCallback(
    async evt => {
      const text = evt.dataTransfer.getData(SupportedTypes.Text)
      const link = evt.dataTransfer.getData(SupportedTypes.Link)
      const files = getFileArray(evt)
      clearFileList(evt)
      let notes = ''
      let elementType = 'unknown'
      const safeLink = typeof link === 'string' ? link : ''
      if (isAppleMailLink(safeLink)) {
        elementType = 'AppleMail'
        if (window.electronAPI?.getAppleMailContent) {
          notes = await window.electronAPI.getAppleMailContent(link)
        }
        if (!isElectronApp()) {
          showAppleMailBanner(elementType)
        }
      }
      onDrop({ text, link, files, notes, elementType })
    },
    [onDrop, showAppleMailBanner]
  )

  const handleDrop = useCallback(
    evt => {
      evt.preventDefault()
      evt.stopPropagation()
      asyncDrop(evt)
      dragCounterRef.current = 0
      setDragging(false)
      return false
    },
    [dragCounterRef, setDragging, asyncDrop]
  )

  const {
    container: containerStyle,
    subContainer: subContainerStyle,
    centeredContainer: centeredContainerStyle,
    icon: iconStyle,
    span: spanStyle,
  } = styles
  return (
    <Container
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      className={className}
      style={containerStyle}
      {...rest}
    >
      {dragging && (
        <SubContainer style={subContainerStyle}>
          <CenteredContainer style={centeredContainerStyle}>
            <DraggingPlaceholder>
              <AttachFilled style={iconStyle} />
              <span style={spanStyle}>{placeholdersByType[primaryType] || ''}</span>
            </DraggingPlaceholder>
          </CenteredContainer>
        </SubContainer>
      )}
      {children}
    </Container>
  )
}
