import { OnboardingStepKeys } from '../actions/onboardingActions'
import teamWorkSVG from './images/illustrations/undraw_team_work.svg'
import newTasksSVG from './images/illustrations/undraw_next_tasks.svg'
import notesSVG from './images/illustrations/undraw_notes.svg'
import onlineCalendarSVG from './images/illustrations/undraw_online_calendar.svg'
import teamSVG from './images/illustrations/undraw_team_page.svg'
import completedSVG from './images/illustrations/undraw_completed.svg'

export const onboardingStepsByKey = {
  /*[OnboardingStepKeys.CREATE_FIRST_PROJECT]: {
    step: 0,
    key: OnboardingStepKeys.CREATE_FIRST_PROJECT,
    count: 0,
    limit: 1,
  },*/
  [OnboardingStepKeys.CREATE_ITEMS]: {
    step: 0,
    key: OnboardingStepKeys.CREATE_ITEMS,
    count: 0,
    limit: 1,
  },
  [OnboardingStepKeys.ADD_NOTES_TO_ITEMS]: {
    step: 1,
    key: OnboardingStepKeys.ADD_NOTES_TO_ITEMS,
    count: 0,
    limit: 1,
  },
  [OnboardingStepKeys.ADD_DATES_TO_ITEMS]: {
    step: 2,
    key: OnboardingStepKeys.ADD_DATES_TO_ITEMS,
    count: 0,
    limit: 1,
  },
  [OnboardingStepKeys.SHARE_PROJECT]: {
    step: 3,
    key: OnboardingStepKeys.SHARE_PROJECT,
    count: 0,
    limit: 1,
  },
  [OnboardingStepKeys.ASSIGN_ITEMS]: {
    step: 4,
    key: OnboardingStepKeys.ASSIGN_ITEMS,
    count: 0,
    limit: 1,
  },
}

export const onboardingImagesByKey = {
  [OnboardingStepKeys.CREATE_FIRST_PROJECT]: teamWorkSVG,
  [OnboardingStepKeys.SHARE_PROJECT]: newTasksSVG,
  [OnboardingStepKeys.CREATE_ITEMS]: notesSVG,
  [OnboardingStepKeys.ADD_NOTES_TO_ITEMS]: onlineCalendarSVG,
  [OnboardingStepKeys.ADD_DATES_TO_ITEMS]: teamSVG,
  [OnboardingStepKeys.ASSIGN_ITEMS]: completedSVG,
}
