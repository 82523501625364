import React from 'react'
import styled, { useTheme } from 'styled-components'
import { cn } from '@appfluence/classnames'
import PropTypes from 'prop-types'
import { graphEventHelper } from '../../common/src/helpers'
import { Icon } from '@fluentui/react/lib/Icon'
import { useCellStyles } from '../itemCell/ItemCellStyles'
import { ICON_NAMES } from '../../common/src/msIcons'
import { FlexColumn } from '../layout/FlexContainer'
import { isSameDay } from 'date-fns'
import { formatDate } from '../../utils/datefns'

const CellContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  position: relative;
  padding: 6px;
  min-height: 58px;
  box-sizing: border-box;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      background: ${p => p.styles.highlight};
    }
  }

  &.selected {
    background: ${p => p.styles.selectedBackground};
  }
`

const StyledIcon = styled(Icon)`
  font-size: 30px;
  margin-right: 8px;
  color: ${p => p.color};
`

const Name = styled.div`
  width: 100%;
  color: ${p => p.theme.palette.black};
  user-select: none;

  /* Multiline */
  word-break: break-word;
  hyphens: auto;

  &.narrow {
    /* One line with ellipsis */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &.selected {
    color: ${p => p.theme.palette.black};
  }
`

const DueText = styled.span`
  color: ${p => p.color};
  font-size: 10px;
  user-select: none;
`

const Canceled = styled(DueText)`
  color: ${p => p.theme.palette.negative};
`

const Body = styled(FlexColumn)`
  flex: 1;
  justify-content: start;
  align-items: start;
`

const getIconProps = (event, theme) => {
  const { icon, iconColor } = event
  const isTeams = graphEventHelper.isProvidedByTeams(event)
  return {
    color: isTeams ? theme.palette.teams : iconColor,
    iconName: isTeams ? ICON_NAMES.TeamsLogo : icon,
  }
}

const GEC = ({ event, selected = false, now, style, stylesProp, onClick, onFocus, ...rest }) => {
  const theme = useTheme()
  const defStyles = useCellStyles()
  const styles = stylesProp || defStyles
  const dueInfo = getDueInfo({ event, now })
  const localOnClick = () => onClick?.(event)
  const localOnFocus = () => onFocus?.(event)
  const iconProps = getIconProps(event, theme)
  const cellCN = cn({ selected })
  const cancelledCN = cn({ pm_hidden: !event?.resource?.isCancelled })
  return (
    <CellContainer
      styles={styles}
      style={style}
      onClick={localOnClick}
      className={cellCN}
      onFocus={localOnFocus}
      {...rest}
    >
      <StyledIcon {...iconProps} />
      <Body>
        <Name>{event.name}</Name>
        <DueText color={dueInfo.color}>
          {dueInfo.text}
          <Canceled className={cancelledCN}> CANCELLED</Canceled>
        </DueText>
      </Body>
    </CellContainer>
  )
}

const getDueInfo = ({ event, now }) => {
  const [start, end] = graphEventHelper.getRange(event)
  if (start < now && now < end) {
    return {
      text: 'In progress',
      color: 'green',
    }
  }
  if (isSameDay(start, end)) {
    const f = formatDate({ hour: 'numeric', minute: 'numeric' })
    return {
      text: `${f(start)} - ${f(end)}`,
      color: '',
    }
  }
  return {
    text: '',
    color: '',
  }
}

export const GraphEventCell = React.memo(GEC)

GraphEventCell.propTypes = {
  event: PropTypes.object.isRequired,
  styles: PropTypes.object,
  style: PropTypes.object,
  now: PropTypes.instanceOf(Date),
  onClick: PropTypes.func,
  selected: PropTypes.bool,
}
