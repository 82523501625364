import { useCallback } from 'react'
import { ShimmerVirtualizedList } from '../../components/list/ShimmerVirtualizedList'
import { MeetingCell } from './MeetingCell'
import { MeetingGhostCell } from './MeetingGhostCell'
import meetingHelper from '../../common/src/helpers/meetingHelper'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'

export const MeetingVirtualizedList = ({ meetings, selectedMeeting, onSelect }) => {
  const narrow = useNarrowWidth()
  const selectedID = meetingHelper.getID(selectedMeeting)
  const rowRenderer = useCallback(
    index => {
      const meeting = meetings[index]
      const serverID = meetingHelper.getID(meeting)
      const selected = serverID === selectedID
      return <MeetingCell meeting={meeting} idx={index} onClick={onSelect} selected={selected} narrow={narrow} />
    },
    [meetings, onSelect, selectedID, narrow]
  )

  return (
    <ShimmerVirtualizedList
      rows={meetings}
      rowRenderer={rowRenderer}
      shimmerComponent={MeetingGhostCell}
      computeItemKey={(idx, meeting) => meetingHelper.getID(meeting) ?? `idx-${idx}`}
    />
  )
}
