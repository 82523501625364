import React from 'react'
import { LeftPanelButton } from './LeftPanelButton'
import { useShowCreateProjectView } from '../../hooks/projectHooks'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'

export const LeftPanelNewProjectButton = ({ className, ...rest }) => {
  const onCreateProject = useShowCreateProjectView(EVENT_EXTRA.CREATE_PROJECT.MODE.LEFT_PANEL_ADD_BUTTON)

  return <LeftPanelButton onClick={onCreateProject} {...rest}></LeftPanelButton>
}
