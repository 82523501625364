import { useEffect } from 'react'
import { linkSubject } from '@/reactions/linkSubject'
import { URLParser } from '@/common/src/helpers/URLHelper'

const handleDeepLink = (url: string, source?: string) => {
  const resource = URLParser(url)
  if (resource) {
    linkSubject.next({ urlData: resource, source })
  }
}

export const useElectronDeeplinks = () => {
  useEffect(() => {
    if (!window.electronAPI) {
      console.log('Not running in Electron')
      return
    }
    console.log('Running in Electron')
    window.electronAPI.registerDeepLinkHandler(value => handleDeepLink(value))
  }, [])
}
