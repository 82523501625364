import React, { memo, useCallback, useLayoutEffect } from 'react'
import _ from 'lodash'
import { ProjectTemplateRecommendations } from './templates/ProjectTemplateRecommendations'
import { ProjectTemplateTabs } from './templates/ProjectTemplateTabs'
import {
  FullScreenModalContainer,
  FullScreenModalSection,
  FullScreenModalSectionBody,
  FullScreenModalSectionHeader,
} from '../modal/FullScreenModalContainer'
import { getRelativeURLKeepingQuerySearch, NEW_PROJECT_SECTIONS } from '../../helpers/routeHelper'
import { PROJECT_TEMPLATE_TYPE } from './ProjectTemplateType'
import { useCreateNewProjectFromCSVAdvanced } from '../../hooks/projectHooks'
import { useTranslation } from 'react-i18next'
import { useConfig } from '../../queries/config'
import { cn } from '@appfluence/classnames'
import {
  Button,
  TeachingPopover,
  TeachingPopoverBody,
  TeachingPopoverSurface,
  TeachingPopoverTrigger,
} from '@fluentui/react-components'
import { Edit } from '@/components/BundledIcons'
import { DeleteRegular, EditRegular } from '@fluentui/react-icons'

const useTab = ({ history, match, aiEnabled }) => {
  const section = _.get(match, 'params.section')
  const validSection = _.find(NEW_PROJECT_SECTIONS, s => s === section)
  const defSection = aiEnabled ? NEW_PROJECT_SECTIONS.AI : NEW_PROJECT_SECTIONS.CUSTOM
  useLayoutEffect(() => {
    if (!validSection) {
      history.replace(getRelativeURLKeepingQuerySearch.newProjectApp(defSection))
    }
  }, [history, validSection, defSection])
  return validSection ?? defSection
}

export const NewProjectModal = memo(({ history, match, location, ...rest }) => {
  const createNewProjectFromCSV = useCreateNewProjectFromCSVAdvanced()
  const { t } = useTranslation()
  const { data: config } = useConfig()
  const aiEnabled = config?.ai_deployed
  const selectedTabKey = useTab({ history, match, aiEnabled })

  const onTabSelect = useCallback(
    (event, data) => {
      const { value } = data
      history.push(getRelativeURLKeepingQuerySearch.newProjectApp(value))
    },
    [history]
  )

  const onCreate = useCallback(
    projectID => {
      const path = getRelativeURLKeepingQuerySearch.matrixForProjectId(projectID)
      history.push(path)
    },
    [history]
  )

  const onSelect = useCallback(
    ({ type, template, file }) => {
      if (type === PROJECT_TEMPLATE_TYPE.BLANK) {
        const path = getRelativeURLKeepingQuerySearch.blankProjectApp()
        history.push(path, location.state)
        return
      }

      if (type === PROJECT_TEMPLATE_TYPE.IMPORT_CSV) {
        createNewProjectFromCSV({
          file,
          onCreate,
        })
        return
      }

      const templateID = template.id
      if (type === PROJECT_TEMPLATE_TYPE.CUSTOM) {
        const path = getRelativeURLKeepingQuerySearch.newProjectFromCustomTemplateApp(templateID)
        history.push(path, location.state)
      } else if (type === PROJECT_TEMPLATE_TYPE.PUBLIC) {
        const path = getRelativeURLKeepingQuerySearch.newProjectFromPublicTemplateApp(templateID)
        history.push(path, location.state)
      }
    },
    [history, location, createNewProjectFromCSV, onCreate]
  )

  const type =
    selectedTabKey === NEW_PROJECT_SECTIONS.CUSTOM ? PROJECT_TEMPLATE_TYPE.CUSTOM : PROJECT_TEMPLATE_TYPE.PUBLIC
  return (
    <FullScreenModalContainer {...rest}>
      <FullScreenModalSection>
        <FullScreenModalSectionHeader>{t('new_project.template_title')}</FullScreenModalSectionHeader>
        <FullScreenModalSectionBody>
          <ProjectTemplateRecommendations type={type} onSelect={onSelect} />
        </FullScreenModalSectionBody>
      </FullScreenModalSection>
      <TemplateExplanationButton visible={selectedTabKey === NEW_PROJECT_SECTIONS.CUSTOM} />
      <ProjectTemplateTabs
        selectedKey={selectedTabKey}
        onTabSelect={onTabSelect}
        onSelect={onSelect}
        aiEnabled={aiEnabled}
      />
    </FullScreenModalContainer>
  )
})

const ExplanationText = ({ children }) => <div className="flex items-center gap-3">{children}</div>

const TemplateExplanationButton = ({ visible }) => {
  const { t } = useTranslation()

  return (
    <div
      className={cn('flex h-5 justify-center transition-all', visible ? 'visible opacity-100' : 'invisible opacity-0')}
    >
      <TeachingPopover>
        <TeachingPopoverTrigger disableButtonEnhancement>
          <Button appearance="transparent" icon={<Edit />}>
            {t('new_project.manage_templates')}
          </Button>
        </TeachingPopoverTrigger>
        <TeachingPopoverSurface className="max-w-36">
          <TeachingPopoverBody>
            <div className="flex flex-col gap-3">
              <ExplanationText>
                <EditRegular className="flex-shrink-0 text-xl text-pm-theme-primary" />
                <span className="text-pm-black">{t('new_project.edit_template')}</span>
              </ExplanationText>
              <ExplanationText>
                <DeleteRegular className="flex-shrink-0 text-xl text-pm-negative" />
                <span className="text-pm-black">{t('new_project.delete_template')}</span>
              </ExplanationText>
            </div>
          </TeachingPopoverBody>
        </TeachingPopoverSurface>
      </TeachingPopover>
    </div>
  )
}
