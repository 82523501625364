import _ from 'lodash'
import { useSelector } from 'react-redux'
import { stateHelper } from '../common/src/helpers'
import { useToastController } from '@fluentui/react-components'
import { useCallback } from 'react'
import { simulateLinkClick } from '../common/src/utils'
import { VERSION } from '../common/src/environment'
import { useKonamiCheatCode } from './useKonamiCheatCode'
import { FluentToast } from '../components/toast/FluentToast'

export const useCodes = () => {
  const isAppfluenceUser = useSelector(stateHelper.isAppfluenceCurrentUser)
  const { dispatchToast } = useToastController()
  const goToApptest = useCallback(() => {
    if (isAppfluenceUser) {
      const { href } = window.location
      const next = _.replace(href, 'office365/app/', 'office365/apptest/')
      if (next !== href) {
        simulateLinkClick(next)
      }
    }
  }, [isAppfluenceUser])

  const showVersion = useCallback(() => {
    dispatchToast(<FluentToast>{VERSION}</FluentToast>, {
      intent: 'success',
    })
  }, [dispatchToast])

  useKonamiCheatCode('apptest', goToApptest)
  useKonamiCheatCode(';', showVersion)
}
