import { ItemCell } from '../../../components/itemCell/ItemCell'
import React from 'react'
import styled from 'styled-components'
import { linkDependencyHelper } from '../../../common/src/helpers'
import { IconButton } from '@fluentui/react/lib/Button'
import { TooltipHost } from '@/components/tooltip/TooltipHost'
import { useDependencyExplanation, useDependencyOptions } from '../../../hooks/dependencyHooks'

const DependencyButtonsRow = styled.div`
  display: flex;
  justify-content: center;
`

const Explanation = styled.div`
  margin-top: 4px;
  font-weight: 300;
  font-style: italic;
  text-align: center;
`

const dependencies = linkDependencyHelper.DEPENDENCY
const dependencyIcons = new Map([
  [dependencies.RELATED, { iconName: 'ChevronFold10' }],
  [dependencies.BLOCKED_BY, { iconName: 'Download', styles: { root: { fontWeight: 600 } } }],
  [dependencies.BLOCKS, { iconName: 'Upload', styles: { root: { fontWeight: 600 } } }],
  [dependencies.CAUSED_BY, { iconName: 'DoubleChevronDown8' }],
  [dependencies.CAUSES, { iconName: 'DoubleChevronUp8' }],
])
const dependencyIconsReversed = new Map([
  [dependencies.RELATED, { iconName: 'ChevronFold10' }],
  [dependencies.BLOCKED_BY, { iconName: 'Upload', styles: { root: { fontWeight: 600 } } }],
  [dependencies.BLOCKS, { iconName: 'Download', styles: { root: { fontWeight: 600 } } }],
  [dependencies.CAUSED_BY, { iconName: 'DoubleChevronUp8' }],
  [dependencies.CAUSES, { iconName: 'DoubleChevronDown8' }],
])

export const CreateItemLinkSection = ({
  className,
  linkedItem,
  dependency,
  onChangeDependency,
  explanationKeyPrefix,
  itemBelow,
  reversed = false,
}) => {
  const dependencyOptions = useDependencyOptions()
  const explanation = useDependencyExplanation({ dependency, translationKeyPrefix: explanationKeyPrefix })
  const icons = reversed ? dependencyIconsReversed : dependencyIcons

  return (
    <div className={className}>
      {!!linkedItem && <ItemCell readOnly item={linkedItem} />}
      <DependencyButtonsRow>
        {Array.from(icons).map(([key, value]) => (
          <TooltipHost content={dependencyOptions.find(option => option.key === key)?.text} key={key}>
            <IconButton
              key={key}
              iconProps={value}
              checked={key === dependency}
              onClick={evt => onChangeDependency(evt, key)}
            />
          </TooltipHost>
        ))}
      </DependencyButtonsRow>
      <Explanation>{explanation}</Explanation>
      {!!itemBelow && <ItemCell readOnly item={itemBelow} />}
    </div>
  )
}
