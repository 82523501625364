import React from 'react'
import _ from 'lodash'
import { useMe } from './usersHooks'
import { userHelper } from '../helpers'

const needToCheckSearchLookbackBanner = me => {
  return 0 < userHelper.getSearchLookbackInDays(me)
}

export const useNeedToCheckSearchLookbackBanner = () => {
  const me = useMe()
  return needToCheckSearchLookbackBanner(me)
}

const EMPTY_OBJECT = {}

export const useExtendQueryParametersWithSearchLoopback = (params = EMPTY_OBJECT) => {
  const me = useMe()
  return React.useMemo(() => {
    if (!needToCheckSearchLookbackBanner(me)) {
      return params
    }
    const searchLookback = userHelper.getSearchLookbackInDays(me)
    const dateLimit = new Date()
    dateLimit.setHours(0, 0, 0, 0)
    dateLimit.setDate(-searchLookback)
    const lastModifiedParameterName = 'lastModifiedTimestamp__gte'
    const currentLastModifiedQuery = params[lastModifiedParameterName]
    const nextLastModifiedParameter = currentLastModifiedQuery
      ? _.max([dateLimit.toISOString(), currentLastModifiedQuery])
      : dateLimit.toISOString()
    return {
      ...params,
      [lastModifiedParameterName]: nextLastModifiedParameter,
    }
  }, [params, me])
}

export const useExtendQueryParametersToCheckBannerInfo = (params = EMPTY_OBJECT) => {
  const me = useMe()
  return React.useMemo(() => {
    if (!needToCheckSearchLookbackBanner(me)) {
      return params
    }
    const searchLookback = userHelper.getSearchLookbackInDays(me)
    const dateLimit = new Date()
    dateLimit.setHours(0, 0, 0, 0)
    dateLimit.setDate(-searchLookback)
    return {
      ...params,
      lastModifiedTimestamp__gte: undefined,
      lastModifiedTimestamp__lte: dateLimit.toISOString(),
      limit: 1,
    }
  }, [params, me])
}
