import { memo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { Map } from 'immutable'
import { projectHelper, projectMembershipHelper, sharingRequestHelper, userHelper } from '../../common/src/helpers'
import { useOrganizedUsersOptions } from '../../hooks/useUsersOption'
import { UsersPanel } from '../users/panels/UsersPanel'
import { useMe, useProjectMembersForProject, useUsersForEmails } from '../../common/src/hooks/usersHooks'
import { postSharingRequest, putSharingRequest } from '../../common/src/actions/sharingRequestsAPI'
import { resourceURICreator } from '../../common/src/helpers/URLHelper'
import { PM_API_RESOURCE_TYPE } from '../../common/src/constants'
import { OnboardingStepKeys } from '../../actions/onboardingActions'
import { useSendStepEventIfNeeded } from '../../hooks/onboardingHooks'
import { useUsersMenuProps } from '../../hooks/usersMenuPropsHooks'
import { useMergeState } from '../../common/src/hooks/enhancedHooks'
import { useTranslation } from 'react-i18next'
import { AlertModal } from '../modal/AlertModal'
import { UsersList } from '@/components/users/usersList/UsersList'
import { leaveProject, updateUserRoleInProject } from '@/common/src/actions/projectsAPI'
import { getRelativeURLKeepingQuerySearch } from '@/helpers/routeHelper'
import { useHistory } from 'react-router'

const newInvitationForEmail = (projectID, email, invitationState = sharingRequestHelper.STATUS.INIT) => {
  return Map({
    [sharingRequestHelper.KEYS.STATUS]: invitationState,
    [sharingRequestHelper.KEYS.TARGET_USERNAME]: email,
    [sharingRequestHelper.KEYS.PROJECT]: resourceURICreator(1, PM_API_RESOURCE_TYPE.PROJECT, projectID),
  })
}

const defaultState = {
  loading: false,
  errorMessage: null,
}

const useResetState = ({ projectId, setState }) => {
  useEffect(() => {
    setState(defaultState)
  }, [setState, projectId])
}

export const ProjectMembersPanel = memo(({ project, onDismiss, ...rest }) => {
  const [alertModalProps, setAlertModalProps] = useMergeState({ open: false })
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [state, setState] = useMergeState(defaultState)
  const me = useMe()
  const meEmail = userHelper.getEmail(me)
  const meID = userHelper.getID(me)
  const projectId = projectHelper.getIdd(project)
  const members = useProjectMembersForProject({
    project,
    sortDescriptor: userHelper.sortDescriptorByFullName,
    populateIsAdmin: true,
    asArray: true,
  })
  const options = useOrganizedUsersOptions({
    minusUsers: members,
  })
  const sendStepEventIfNeeded = useSendStepEventIfNeeded()
  useResetState({ projectId, setState })
  const history = useHistory()

  const onReceiveResponse = useCallback(
    response => {
      if (response.error) {
        setState({
          errorMessage: response.payload.response?.message ?? response.payload.message,
          loading: false,
        })
        return
      }
      setState(defaultState)
    },
    [setState]
  )

  const onSelectFollower = useCallback(
    user => {
      const email = user && userHelper.getEmail(user)
      if (_.isEmpty(email)) {
        return
      }
      setState({ loading: true })

      const invitation = newInvitationForEmail(projectId, email)
      dispatch(postSharingRequest(invitation)).then(onReceiveResponse)

      // Send onboarding step event
      sendStepEventIfNeeded(OnboardingStepKeys.SHARE_PROJECT)
    },
    [setState, projectId, dispatch, onReceiveResponse, sendStepEventIfNeeded]
  )

  const onRemoveFollower = useCallback(
    user => {
      const email = user && userHelper.getEmail(user)
      if (_.isEmpty(email)) {
        return
      }

      const remove = async () => {
        setState({ loading: true, errorMessage: null })

        if (meEmail === email) {
          await dispatch(leaveProject(project))
          setState(defaultState)
          onDismiss?.()
          const path = getRelativeURLKeepingQuerySearch.projects()
          history.push(path)
        } else if (sharingRequestHelper.isSharingRequest(user)) {
          const request = sharingRequestHelper.setStatus(user, sharingRequestHelper.STATUS.REJECTED)
          dispatch(putSharingRequest(request))?.then?.(onReceiveResponse)
        } else {
          // User
          const email = userHelper.getEmail(user)
          const invitation = newInvitationForEmail(projectId, email, sharingRequestHelper.STATUS.REMOVE)
          dispatch(postSharingRequest(invitation)).then(onReceiveResponse)
        }
      }

      const admins = members.filter(member => userHelper.isAdmin(member))
      const removingLastAdmin = admins.length === 1 && userHelper.getEmail(admins[0]) === email
      if (removingLastAdmin) {
        setAlertModalProps({
          open: true,
          title: t('project_detail.remove_last_admin_confirmation_title'),
          subText: t('project_detail.remove_last_admin_confirmation_subtext'),
          onPrimaryActionClick: remove,
          onYesAlwaysClick: null,
          primaryActionText: t('project_detail.remove_last_admin_confirmation_primary_action'),
        })
      } else {
        setAlertModalProps({
          open: true,
          title: t(
            meEmail === email
              ? 'project_detail.remove_yourself_confirmation_title'
              : 'project_detail.remove_member_confirmation_title',
            {
              member: userHelper.getFullName(user),
            }
          ),
          subText: t(
            meEmail === email
              ? 'project_detail.remove_yourself_confirmation_subtext'
              : 'project_detail.remove_member_confirmation_subtext',
            {
              member: userHelper.getFullName(user),
            }
          ),
          onPrimaryActionClick: remove,
          onYesAlwaysClick: null,
          primaryActionText: t(
            meEmail === email
              ? 'project_detail.remove_yourself_confirmation_primary_action'
              : 'project_detail.remove_member_confirmation_primary_action'
          ),
        })
      }
    },
    [
      members,
      setState,
      meEmail,
      dispatch,
      project,
      onDismiss,
      history,
      onReceiveResponse,
      projectId,
      setAlertModalProps,
      t,
    ]
  )

  const onEndEditing = useCallback(() => {
    setState({ errorMessage: null })
  }, [setState])

  const handleShowError = useCallback(errorMessage => setState({ errorMessage }), [setState])

  const menuProps = useUsersMenuProps({ users: members, object: project })

  const canRemoveOtherUsers =
    projectHelper.isAdminUser(project, me) ||
    userHelper.isSupermanager(me) ||
    (projectHelper.getCreatorUsername(project) === meEmail &&
      projectHelper.getMemberships(project)?.filter(membership => projectMembershipHelper.isProjectAdmin(membership))
        .size === 0)

  const { loading, errorMessage } = state
  return (
    <UsersPanel
      headerText={t('project_detail.modify_members')}
      onDismiss={onDismiss}
      users={members}
      options={options}
      onSelectUser={onSelectFollower}
      onRemoveUser={onRemoveFollower}
      enabledEditing={projectHelper.isAdminUserID(project, meID) || userHelper.isSupermanager(me)}
      editText={t('users.edit_admins')}
      editSection={<EditAdminsSection project={project} onShowError={handleShowError} setState={setState} />}
      onEndEditing={onEndEditing}
      errorMessage={errorMessage}
      usersHeader={t('users.members')}
      invitationIconProps={{ title: t('project_detail.add_new_member_tooltip') }}
      loading={loading}
      menuProps={menuProps}
      fromProject
      onShowError={handleShowError}
      onlyRemovableMe={!canRemoveOtherUsers}
      removeText={canRemoveOtherUsers ? t('users.press_to_remove') : t('users.remove_myself')}
      {...rest}
    >
      {/* The AlertModal must be inside the panel so the panel doesn't dismiss when clicking inside the modal */}
      <AlertModal {...alertModalProps} onDismiss={() => setAlertModalProps({ open: false })} />
    </UsersPanel>
  )
})

const EditAdminsSection = ({ project, onShowError, setState }) => {
  const dispatch = useDispatch()
  const me = useMe()
  const projectMemberships = projectHelper.getMemberships(project)
  const { t } = useTranslation()

  const adminUsernames = projectMemberships
    .filter(membership => projectMembershipHelper.isProjectAdmin(membership))
    .map(m => projectMembershipHelper.getUsername(m))
  const nonAdminUsernames = projectMemberships
    .filter(
      membership =>
        !projectMembershipHelper.isDeleted(membership) && !projectMembershipHelper.isProjectAdmin(membership)
    )
    .map(m => projectMembershipHelper.getUsername(m))

  const adminUsers = useUsersForEmails(adminUsernames)
    .map(user => ({ user }))
    .toArray()
  const nonAdminUsers = useUsersForEmails(nonAdminUsernames)
    .map(user => ({ user }))
    .toArray()

  const onReceiveResponse = useCallback(
    response => {
      if (response.error) {
        const apiResponse = response.payload.response
        const errorMessage = apiResponse.message ?? response.payload.message
        onShowError?.(errorMessage)
      }
      setState(state => ({ ...state, loading: false }))
    },
    [onShowError, setState]
  )
  const onClickMakeAdmin = useCallback(
    async user => {
      setState(state => ({ ...state, loading: true }))
      const response = await dispatch(
        updateUserRoleInProject(user, projectMembershipHelper.PROJECT_ROLE.ADMIN, projectHelper.getIdd(project))
      )
      onReceiveResponse(response)
    },
    [dispatch, onReceiveResponse, project, setState]
  )

  const onClickDemoteAdmin = useCallback(
    async user => {
      if (userHelper.getEmail(user) !== userHelper.getEmail(me)) {
        setState(state => ({ ...state, loading: true }))
        const response = await dispatch(
          updateUserRoleInProject(user, projectMembershipHelper.PROJECT_ROLE.ADD, projectHelper.getIdd(project))
        )
        onReceiveResponse(response)
      }
    },
    [dispatch, me, onReceiveResponse, project, setState]
  )

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <div className="flex justify-between">
          <span className="font-semibold">{t('users.current_admins')}</span>
          <span className="italic">{t('users.press_to_demote')}</span>
        </div>
        <UsersList project={project} usersOptions={adminUsers} onSelectUser={onClickDemoteAdmin} />
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex justify-between">
          <span className="font-semibold">{t('users.rest_of_members')}</span>
          <span className="italic">{t('users.press_to_promote')}</span>
        </div>
        <UsersList project={project} usersOptions={nonAdminUsers} onSelectUser={onClickMakeAdmin} />
      </div>
    </div>
  )
}
