import { useSelector } from 'react-redux'
import { getLastCollaboratorInLocation } from '../selectors/uiSelectors'
import { getTeamsLocation } from '../common/src/selectors/teamsSelectors'
import { getSearchedItems } from '../common/src/selectors/searchSelectors'
import { SEARCH_REDUCER_KEYS } from '../common/src/reducers/searchKeys'
import { useDeferredValue } from 'react'
import { useSelectedItem } from '../hooks/PMHooks'

export const oneOnOneContainerGenerator = Component => props => {
  const appLocation = useSelector(getTeamsLocation)
  const searchedItemsSync = useSelector(state => getSearchedItems(state, SEARCH_REDUCER_KEYS.SECTION_ONE_ON_ONE))
  const searchedItems = useDeferredValue(searchedItemsSync)
  const lastRememberedCollaborator = useSelector(state => getLastCollaboratorInLocation(state, appLocation))
  const selectedItemSync = useSelectedItem()
  const selectedItem = useDeferredValue(selectedItemSync)
  return (
    <Component
      {...props}
      searchedItems={searchedItems}
      appLocation={appLocation}
      lastRememberedCollaborator={lastRememberedCollaborator}
      selectedItem={selectedItem}
    />
  )
}
