import { useMemo, useCallback } from 'react'
import _ from 'lodash'
import { useHistory, useRouteMatch } from 'react-router'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import { PM_API_RESOURCE_TYPE } from '../../common/src/constants'
import { itemHelper, projectHelper, stateHelper } from '../../common/src/helpers'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { InfoController } from '../../components/info/InfoController'
import { AccessDenied } from '../others/AccessDenied'
import { getMatchParameterInteger } from '../../common/src/utils'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const getServerIDFromMatchByType = {
  [PM_API_RESOURCE_TYPE.PROJECT]: _.partial(getMatchParameterInteger, _, 'pid'),
  [PM_API_RESOURCE_TYPE.ITEM]: _.partial(getMatchParameterInteger, _, 'id'),
}

const isObjectDeleted = object => {
  if (itemHelper.isItem(object)) {
    return itemHelper.isDeleted(object)
  } else if (projectHelper.isProject(object)) {
    return projectHelper.isDeleted(object)
  }
  return false
}

export const useIsObjectInvalidToBeShown = (type = PM_API_RESOURCE_TYPE.PROJECT) => {
  const match = useRouteMatch()
  return useCallback(
    (object, error) => {
      const getServerIDFromMatch = getServerIDFromMatchByType[type]
      return error || !getServerIDFromMatch || !getServerIDFromMatch(match) || !object || isObjectDeleted(object)
    },
    [type, match]
  )
}

export const InvalidObjectComponent = ({ type = PM_API_RESOURCE_TYPE.PROJECT, object, error, readOnly = false }) => {
  const history = useHistory()
  const match = useRouteMatch()
  const getServerIDFromMatch = getServerIDFromMatchByType[type]
  const serverID = getServerIDFromMatch && getServerIDFromMatch(match)
  const meEmail = useSelector(stateHelper.getMeEmail)
  const { t } = useTranslation()

  const localizedStrings = useMemo(() => {
    return {
      invalid_id_title: t(`invalid_object.${type}.invalid_id_title`),
      deleted_title: t(`invalid_object.${type}.deleted_title`),
      deleted_subtitle: t(`invalid_object.${type}.deleted_subtitle`),
      access_denied_title: t(`invalid_object.${type}.access_denied_title`),
      access_denied_subtitle: t(`invalid_object.${type}.access_denied_subtitle`),
      not_found_title: t(`invalid_object.${type}.not_found_title`),
      not_found_subtitle: t(`invalid_object.${type}.not_found_subtitle`),

      explore_projects_question: t(`invalid_object.${type}.explore_projects_question`),
    }
  }, [t, type])

  const openProjects = () => {
    const path = getRelativeURLKeepingQuerySearch.projects()
    history.push(path)
  }

  // Special component for access denied
  if (error && error.accessDenied) {
    return <AccessDenied serverID={serverID} objectType={type} />
  }
  const hideExploreProjectOption = readOnly

  // Info
  let titleError = null
  let subtitleError = ''
  let supportInfo = { serverID, meEmail }
  let showSupportInfo = false

  if (error) {
    const { accessDenied, gone } = error
    if (readOnly) {
      titleError = t('common_localized_strings.joker_error_title')
      subtitleError = t('common_localized_strings.check_link_subtitle')
    } else if (accessDenied) {
      titleError = localizedStrings.access_denied_title
      subtitleError = localizedStrings.access_denied_subtitle
    } else if (gone) {
      if (type === PM_API_RESOURCE_TYPE.PROJECT) {
        showSupportInfo = true
      }
      titleError = localizedStrings.deleted_title
      subtitleError = localizedStrings.deleted_subtitle
    } else {
      titleError = localizedStrings.not_found_title
      subtitleError = localizedStrings.not_found_subtitle
    }
  } else if (!object || isObjectDeleted(object)) {
    titleError = localizedStrings.deleted_title
    subtitleError = `${localizedStrings.deleted_subtitle}`
  }

  if (!hideExploreProjectOption) {
    subtitleError = subtitleError + ` ${localizedStrings.explore_projects_question}`
  }

  if (!titleError) {
    return false
  }

  const buttons = hideExploreProjectOption ? (
    void 0
  ) : (
    <>
      <PrimaryButton key={'button'} onClick={openProjects}>
        {t('common_localized_strings.open_projects')}
      </PrimaryButton>
    </>
  )
  return (
    <InfoController
      title={titleError}
      subtitle={subtitleError}
      buttons={buttons}
      supportInfo={showSupportInfo && supportInfo}
    />
  )
}
