import { useTranslation } from 'react-i18next'
import { Button, type ButtonProps } from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { usePrintItems } from '../../typedContexts'
import { useDownloadItemsCSV } from '../../hooks/useDownloadItemsCSV'
import { Print } from '../BundledIcons'
import { isPrintHotKey, useHotkey } from '../../hooks/useHotkey'
import React, { useMemo } from 'react'
import { META_KEY } from '../shortcutHelp/ShortcutHelp'
import { ShortcutTooltipContent } from '../../tooltip/ShortcutTooltipHost'

export type PrintButtonProps = {
  appearance?: ButtonProps['appearance']
  items: any[]
  className?: string
}

export const PrintButton = ({ items, className, appearance }: PrintButtonProps) => {
  const { t } = useTranslation()
  const { printItems } = usePrintItems()
  const downloadItemsCSV = useDownloadItemsCSV()
  const itemIds = useMemo(() => items.map(item => item?.get?.('id')).filter(Boolean), [items])

  const print = async () => {
    const items = await downloadItemsCSV(itemIds)
    if (!items) return
    printItems(items)
  }

  useHotkey(isPrintHotKey, print)

  return (
    <Tooltip
      content={<ShortcutTooltipContent tooltipKeys={[META_KEY, 'P']} label={t('general.print')} />}
      relationship="label"
    >
      <Button
        onClick={async () => {
          const items = await downloadItemsCSV(itemIds)
          if (!items) return
          printItems(items)
        }}
        icon={<Print />}
        className={className}
        appearance={appearance}
      />
    </Tooltip>
  )
}
