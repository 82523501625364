import { forwardRef, useCallback, useState } from 'react'
import { CircleInput } from '../input/CircleInput'
import { EffortSelectionPanel } from '../effort/EffortSelectionView'
import { useTranslation } from 'react-i18next'
import { itemHelper } from '../../common/src/helpers'
import { customEffortFormatter, EFFORT_SHORT_TRANSLATION_KEYS } from '../effort/commonEffort'

const TOTAL_OPTIONS = Object.keys(EFFORT_SHORT_TRANSLATION_KEYS).length - 1

export const ItemEffortCircle = forwardRef(
  ({ effort = 0, estimatedEffort = 0, onChange, readOnly = false, className, ...rest }, ref) => {
    const [open, setOpen] = useState(false)
    const { t } = useTranslation()

    let text = t(EFFORT_SHORT_TRANSLATION_KEYS[effort])
    const value = (effort / TOTAL_OPTIONS) * 100
    const onClickCircle = useCallback(() => !readOnly && setOpen(true), [setOpen, readOnly])
    const onDismiss = useCallback(() => setOpen(false), [setOpen])

    const shouldDisplayCustom = estimatedEffort !== itemHelper.DEFAULT_ESTIMATED_EFFORT[effort]
    if (shouldDisplayCustom) {
      const { unit, value } = customEffortFormatter(estimatedEffort)
      text = t(`item.effort.short.custom_${unit}`, { count: value })
    }

    return (
      <>
        <CircleInput ref={ref} text={text} value={value} className={className} onClick={onClickCircle} {...rest} />
        <EffortSelectionPanel
          isOpen={open}
          onDismiss={onDismiss}
          effort={effort}
          estimatedEffort={estimatedEffort}
          onChange={onChange}
        />
      </>
    )
  }
)
