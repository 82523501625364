import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SearchBoxWithShortcut } from '../../components/SearchBoxWithShortcut'
import { TopRightBar } from '../../components/layout/TopRightBar'
import { FilterButton } from '../../components/buttons/FilterButton'
import { setDefaultFilterItems, setItemsTextFilter } from '../../common/src/actions/filtersActions'
import { getItemFiltersCount, getItemsTextFilter } from '../../common/src/selectors/filtersSelectors'
import { SearchFiltersPanel } from '../filters/SearchFiltersPanel'
import { FILTER_REDUCER_KEYS } from '../../common/src/reducers/filtersKeys'
import { useSearchKeyPress } from '../../hooks/useSearchKeyPress'
import { useTranslation } from 'react-i18next'
import { useFilterTagsFromParams } from '../../hooks/filterHooks'
import { useTextSearch } from '../../hooks/useTextSearch'
import { TopBarMultiSelectButton } from '../../components/buttons/TopBarMultiSelectButton'
import { PrintButton } from '../../components/buttons/PrintButton'

export const SearchTopBar = memo(({ inApp, items }) => {
  const dispatch = useDispatch()
  const filtersCount = useSelector(state => getItemFiltersCount(state, FILTER_REDUCER_KEYS.SEARCH))
  const clearFilters = () => dispatch(setDefaultFilterItems(FILTER_REDUCER_KEYS.SEARCH))
  const textFilter = useSelector(state => getItemsTextFilter(state, FILTER_REDUCER_KEYS.SEARCH))
  const setTextFilter = useCallback(
    text => {
      return dispatch(setItemsTextFilter(text, FILTER_REDUCER_KEYS.SEARCH))
    },
    [dispatch]
  )
  const { textSearch, onSearchBoxChange, onSearch } = useTextSearch({ setTextFilter, globalTextSearch: textFilter })

  const searchBoxRef = useRef(null)
  const [isOpenFiltersPanel, setIsOpenFiltersPanel] = useState(false)
  const { t } = useTranslation()

  const showFiltersPanel = useCallback(() => {
    setIsOpenFiltersPanel(true)
  }, [setIsOpenFiltersPanel])

  const hideFiltersPanel = useCallback(() => {
    setIsOpenFiltersPanel(false)
  }, [setIsOpenFiltersPanel])

  // Focus search box when this view is showed
  useEffect(() => {
    setTimeout(() => {
      searchBoxRef.current?.focus()
    }, 50)
  }, [])

  const onClickSearch = useCallback(() => {
    setTimeout(() => {
      searchBoxRef.current.focus()
    }, 100)
  }, [searchBoxRef])

  useSearchKeyPress(onClickSearch)

  useFilterTagsFromParams()
  const filterButtonRef = useRef(null)

  const rightSubcomponents = (
    <>
      <TopBarMultiSelectButton allItems={items} />
      <SearchBoxWithShortcut
        id="searchTopBar_searchTextField"
        className="max-w-72"
        ref={searchBoxRef}
        value={textSearch}
        placeholder={t('search.top_bar_search_placeholder')}
        onChange={onSearchBoxChange}
        onSearch={onSearch}
      />
      <FilterButton
        id="searchTopBar_filtersButton"
        tooltip={t('search.top_bar_filter_button_tooltip')}
        filtersCount={filtersCount}
        onClick={showFiltersPanel}
        onClear={filtersCount && clearFilters}
        ref={filterButtonRef}
      />
      <PrintButton items={items} className="!hidden md:!flex" />
    </>
  )

  return (
    <>
      <TopRightBar
        className="py-3"
        key={'SearchTopBar'}
        inApp={inApp}
        title={t('search.title')}
        rightSubcomponents={rightSubcomponents}
      />
      <SearchFiltersPanel isOpen={isOpenFiltersPanel} onDismiss={hideFiltersPanel} />
    </>
  )
})
