import React, { useCallback } from 'react'
import { BASIC_ITEM_LIST_TYPES, SECTIONS } from './HomeConstants'
import { cn } from '@appfluence/classnames'
import { HomeAgendaHeader, SeeMore } from './CustomRow'
import { graphEventHelper, itemHelper } from '../../common/src/helpers'
import { GraphEventCell } from '../../components/graph/GraphEventCell'
import { ItemCell } from '../../components/itemCell/ItemCell'

export const useRowRenderer = ({
  allItemsInList,
  onSelectItem,
  now,
  selection,
  onSelectGraphResource,
  displayAdd = true,
  showProject = true,
}) => {
  return useCallback(
    (element, index) => {
      if (element.type === BASIC_ITEM_LIST_TYPES.HEADER) {
        return (
          <HomeAgendaHeader
            key={element.key}
            className={cn(index === 0 && 'first')}
            title={element.text}
            infoMessage={element.infoMessage}
            date={element.date}
            displayAdd={displayAdd}
          />
        )
      } else if (element.type === BASIC_ITEM_LIST_TYPES.SEE_MORE) {
        return <SeeMore key={element.key} />
      } else if (graphEventHelper.isEvent(element)) {
        const selected = selection && element.id === selection.graphResourceID
        return (
          <GraphEventCell
            event={element}
            now={now}
            key={element.id}
            tabIndex={0}
            index={index}
            selected={selected}
            onFocus={onSelectGraphResource}
          />
        )
      } else {
        const serverID = itemHelper.getId(element)
        const selected = selection && serverID === selection.itemID
        return (
          <ItemCell
            key={serverID}
            item={element}
            idx={index}
            tabIndex={0}
            onClick={onSelectItem}
            selected={selected}
            section={SECTIONS.AGENDA}
            shouldShowProject={showProject}
            allItemsInList={allItemsInList}
          />
        )
      }
    },
    [displayAdd, selection, now, onSelectGraphResource, onSelectItem, showProject, allItemsInList]
  )
}
