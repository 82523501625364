import styled from 'styled-components'
import { QuadrantSelector } from '../input/quadrant/QuadrantSelector'

const OptionText = styled.span`
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SQuadrantSelector = styled(QuadrantSelector)`
  margin-left: ${p => (p.withLeftMargin ? '2px' : 0)};
`

export const renderProjectOption = (option, defaultRenderer) => {
  const item = option.item ?? option
  if (item.itemType) {
    return defaultRenderer(item)
  }
  return (
    <>
      <SQuadrantSelector size={20} project={item.data?.project} readOnly withLeftMargin={option.item} />
      <OptionText>{item.text}</OptionText>
    </>
  )
}
