import { Iterable, Set } from 'immutable'

/**
 * Fast conversion from immutable obj to js
 * @param o The immutable obj
 * @param mapFn Function to map values to the resulting array
 * @param [filterFn] Optional fn to filter the immutable obj
 * @param [sortMapperFn] Optional fn to sort the immutable obj
 * @returns {Array} Mapped Array
 */
export const mapValuesToJsArray = (o, mapFn, filterFn, sortMapperFn) => {
  if (!Iterable.isIterable(o) || !mapFn) {
    console.warn('mapValuesToJsArray: invalid args')
    return void 0
  }
  o = o.toIndexedSeq()
  if (filterFn) {
    o = o.filter(filterFn)
  }
  if (sortMapperFn) {
    o = o.sortBy(sortMapperFn)
  }
  return o.map(mapFn).toArray()
}

//wrapper that checks if the first arg is an immutable Map
export const safeMapFnCreator = fn => (...args) => {
  const o = args[0]
  if (Iterable.isIterable(o)) {
    return fn(...args)
  } else if (!o) {
    return o
  } else {
    console.warn('using a immutable fn with a non immutable obj', o)
    return void 0
  }
}

export const pickPredicate = (...keys) => {
  const keySet = Set(keys)
  return function (v, k) {
    return keySet.has(k)
  }
}
