import {
  Button,
  Menu,
  MenuItem,
  MenuItemLink,
  MenuList,
  MenuPopover,
  MenuTrigger,
  useToastController,
} from '@fluentui/react-components'
import { ArrowDownload, Mail, Share } from '@/components/BundledIcons'
import { useTranslation } from 'react-i18next'
import { useDownloadItemsCSV } from '@/hooks/useDownloadItemsCSV'
import { generateEmail } from '@/utils/generateEmail'
import { useDispatch, useSelector } from 'react-redux'
import { projectHelper, stateHelper } from '@/common/src/helpers'
import { type ReactNode, useCallback } from 'react'
import { getCSVExportURL } from '@/common/src/constants'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent as trackEvent } from '@/common/src/eventTracking/amplitudeEvents'
import { FluentToast } from '@/components/toast/FluentToast'
import { Tooltip } from '@/components/tooltip/Tooltip'

export type ProjectShareButtonProps = {
  className?: string
  project: any
  printOption: ReactNode
}

export const ProjectShareButton = ({ className, project, printOption }: ProjectShareButtonProps) => {
  const { t } = useTranslation()
  const downloadItemsCSV = useDownloadItemsCSV()
  const dispatch = useDispatch()
  const { dispatchToast } = useToastController()

  const items = useSelector(state => stateHelper.getItemsInProject(state, project))

  const handleEmailItems = async () => {
    const itemsCsv = await downloadItemsCSV(items.toArray().map((item: any) => item.get('id')))
    if (!itemsCsv) return
    generateEmail(itemsCsv)
  }

  const exportToCSV = useCallback(() => {
    dispatch(trackEvent(AMPLITUDE_ACTION_TYPES.CSV_EXPORT_PROJECT))
    dispatchToast(<FluentToast>Project successfully exported! Open your Downloads folder...</FluentToast>, {
      intent: 'success',
    })
  }, [dispatch, dispatchToast])

  return (
    <Menu>
      <Tooltip content={t('project_level_top_bar.share_button_tooltip')} relationship="label">
        <MenuTrigger disableButtonEnhancement>
          <Button icon={<Share />} className={className} />
        </MenuTrigger>
      </Tooltip>
      <MenuPopover>
        <MenuList>
          {printOption}
          <MenuItem onClick={handleEmailItems} icon={<Mail />}>
            {t('item.multiple_selection.email')}
          </MenuItem>
          <MenuItemLink
            href={getCSVExportURL(projectHelper.getIdd(project))}
            onClick={exportToCSV}
            className="no-underline"
            target="_blank"
            icon={<ArrowDownload />}
          >
            {t('project.menu.export_csv')}
          </MenuItemLink>
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
