import React, { Suspense, useCallback, useMemo, useState, useEffect } from 'react'
import { itemHelper, userHelper } from '../../common/src/helpers'
import { useSelection } from '../../hooks/PMHooks'
import { Loading } from '../../components/basic/Loading'
import { useRandomValueFromArray } from '../../hooks/arrayHooks'
import { isEmbeddedOnTeams } from '../../helpers/queryParamsHelper'
import { useTranslatedPlaceholders } from '../../hooks/translationHooks'
import { BASIC_ITEM_LIST_TYPES } from './HomeConstants'
import { LazyIViewPlaceholder } from '../../components/placeholder/LazyIViewPlaceholder'
import { getSearchItem } from '../../common/src/actions/searchAPI'
import { HOME_DROPDOWN_OPTIONS } from '../../actions/uiActions'
import { SEARCH_REDUCER_KEYS } from '../../common/src/reducers/searchKeys'
import { useMe } from '../../common/src/hooks/usersHooks'
import { useDispatch } from 'react-redux'
import { StaticItemList } from '../../components/list/staticItemList/StaticItemList'
import { GhostItemCell } from '../../components/itemCell/GhostItemCell'
import { useRowRenderer } from './commonRowrenderer'

const fetchItems = (email, mode) => {
  const params = {
    state__lte: itemHelper.STATE.UNFINISHED,
    summaries: 0,
    order_by: `-${itemHelper.KEYS.LAST_MODIFIED_TIMESTAMP}`,
  }
  if (mode === HOME_DROPDOWN_OPTIONS.OWNED) {
    params.owner = email
  } else {
    params.returnfollowers = 1
    params.following = 1
  }
  return getSearchItem(params, SEARCH_REDUCER_KEYS.SECTION_AGENDA)
}

export const HomeMyItems = ({ onSelectItem, mode, items }) => {
  const [loading, setLoading] = useState(true)
  const selection = useSelection()
  const rowRenderer = useRowRenderer({
    selection,
    onSelectItem,
    allItemsInList: items,
  })
  const dispatch = useDispatch()
  const me = useMe()
  const email = userHelper.getEmail(me)

  useEffect(() => {
    if (!email) {
      return
    }
    setLoading(true)
    dispatch(fetchItems(email, mode)).then(() => {
      setLoading(false)
    })
  }, [dispatch, email, mode, setLoading])

  const placeholders = useTranslatedPlaceholders('alerts.placeholder_', 7)
  const validPlaceholders = useMemo(() => {
    const allPlaceholders = placeholders.map((p, i) => (i === 6 ? { ...p, type: 3 } : p))
    return !isEmbeddedOnTeams() ? allPlaceholders.slice(0, -1) : allPlaceholders
  }, [placeholders])
  const placeholder = useRandomValueFromArray(validPlaceholders)

  const itemsWithSeeMoreButton = items.length
    ? [
        ...items,
        {
          type: BASIC_ITEM_LIST_TYPES.SEE_MORE,
          key: BASIC_ITEM_LIST_TYPES.SEE_MORE,
        },
      ]
    : items

  const noRowsRenderer = useCallback(() => {
    return (
      <Suspense fallback={<Loading />}>
        <LazyIViewPlaceholder {...placeholder} />
      </Suspense>
    )
  }, [placeholder])

  return (
    <StaticItemList
      rows={itemsWithSeeMoreButton}
      rowRenderer={rowRenderer}
      noRowsRenderer={noRowsRenderer}
      loading={loading}
      shimmerComponent={GhostItemCell}
    />
  )
}
