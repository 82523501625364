import { ChangeIconContext } from '../../typedContexts'
import { type ReactNode, useCallback, useMemo, useState } from 'react'
import { IconSelectorModal } from '../../components/icon/IconSelectorModal'

export const IconSelectorModalSingleton = ({ children }: { children: ReactNode }) => {
  const [currentIcon, setCurrentIcon] = useState<string | undefined | null>(null)
  const [onChangeIcon, setOnChangeIcon] = useState<((newIcon: string) => void) | null>(null)
  const [open, setOpen] = useState(false)
  const openModal = useCallback(
    ({ currentIcon, onChangeIcon }: { currentIcon?: string; onChangeIcon: (newIcon: string) => void }) => {
      setOpen(true)
      setCurrentIcon(currentIcon)
      setOnChangeIcon(() => onChangeIcon)
    },
    []
  )
  const onDismiss = useCallback(() => {
    setOpen(false)
  }, [])
  const contextValue = useMemo(
    () => ({
      openModal,
    }),
    [openModal]
  )
  const handleChangeIcon = useCallback(
    (newIcon: string) => {
      onChangeIcon?.(newIcon)
      setCurrentIcon(newIcon)
      setOpen(false)
    },
    [onChangeIcon]
  )
  return (
    <ChangeIconContext.Provider value={contextValue}>
      {children}
      <IconSelectorModal open={open} onDismiss={onDismiss} onChange={handleChangeIcon} currentIcon={currentIcon} />
    </ChangeIconContext.Provider>
  )
}
