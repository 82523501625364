import _ from 'lodash'
import styled from 'styled-components'
import { useCallback, useMemo, memo } from 'react'
import { Set } from 'immutable'
import { useDispatch } from 'react-redux'
import { useFetch } from '../../../common/src/hooks/networkingHooks'
import { getLastTypedCommentsWithItemSummary } from '../../../common/src/actions/commentsAPI'
import { Loading } from '../../basic/Loading'
import { ErrorText } from '../../text/ErrorText'
import { ActionButton } from '@fluentui/react/lib/Button'
import { FlexContainer } from '../../layout/FlexContainer'
import { getAvatarURIForEmail } from '../../../common/src/constants'
import { dateHelper, teamsHelper } from '../../../common/src/helpers'
import { useDeepLinkExecutor } from '../../../utils/teams'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../../common/src/eventTracking/amplitudeEvents'
import { intlFormatDistance } from 'date-fns'
import { useTeamsConfig } from '../../../queries/config'

const Container = styled(FlexContainer)`
  justify-content: flex-start;
  align-items: flex-start;
`

const responseParser = res => res.objects

const useProcessComments = (comments, amount) => {
  //target amount to reduce displayed comments
  return useMemo(() => {
    if (!comments) {
      return comments
    }
    const base = { arr: [], set: Set() }
    const { arr } = _.transform(
      comments,
      (acc, comment) => {
        try {
          const { id } = comment.item_summary
          if (acc.arr.length === amount) {
            return false
          }
          if (acc.set.has(id)) {
            return acc
          }
          acc.arr.push(comment)
          acc.set = acc.set.add(id)
        } catch (err) {}
        return acc
      },
      base
    )
    return arr
  }, [comments, amount])
}

const useRecentItems = userId => {
  const dispatch = useDispatch()
  const fetchMethod = useCallback(
    () =>
      dispatch(
        getLastTypedCommentsWithItemSummary({
          authorId: userId,
          limit: 40,
        })
      ),
    [dispatch, userId]
  )

  const { loading, data, error } = useFetch(fetchMethod, responseParser)
  return {
    loading,
    error,
    data: useProcessComments(data, 5),
  }
}

const RecentActivityText = styled.span`
  font-weight: bolder;
  font-size: 12px;
`

const extractHumanizedDate = (comments, now) => {
  const lastModified = _.first(comments)?.timestamp + 'Z' //So that it can read as locale
  const lastModifiedDate = dateHelper.toDate(lastModified)
  return intlFormatDistance(lastModifiedDate, now, { locale: navigator.language })
}

const useMountNow = () => useMemo(() => new Date(), [])

export const RecentItemsSection = ({ pmUserId }) => {
  const { loading, data, error } = useRecentItems(pmUserId)
  const now = useMountNow()
  if (loading) {
    return <Loading />
  }
  if (error) {
    return <ErrorText>Error. Please check your internet connection</ErrorText>
  }
  if (!data || !data.length) {
    return <span>No shared, recently active items.</span>
    // TODO: create items in this case
  }
  const elements = _.map(data, c => <Item comment={c} key={c.id} />)
  const humanLastSeen = extractHumanizedDate(data, now)

  return (
    <Container>
      <RecentActivityText>Last seen {humanLastSeen}</RecentActivityText>
      {elements}
    </Container>
  )
}

const ItemButton = styled(ActionButton).attrs({
  styles: {
    textContainer: {
      maxWidth: '100%',
      maxHeight: '32px',
      lineHeight: '16px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
    },
    label: {
      maxWidth: '100%',
      maxHeight: '32px',
      lineHeight: '16px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'start',
      display: '-webkit-box',
      // WebkitLineClamp is not added to the final styles.
      // Maybe it's an @uifabric issue
      // So we add it with styled-components
    },
  },
})`
  max-width: 100%;
  text-align: start;
  font-size: smaller;

  span {
    -webkit-line-clamp: 2;
    -webkit-box-orientation: vertical;
  }
`

const ItemContainer = styled(FlexContainer)`
  flex-direction: row;
  justify-content: start;
  align-items: center;
`

const ItemPersona = styled.img`
  max-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border-width: 0;
`

const Item = memo(({ comment }) => {
  const dispatch = useDispatch()
  const teamsConfig = useTeamsConfig()
  const executeDeepLink = useDeepLinkExecutor()
  const name = comment?.item_name
  const email = comment?.item_summary?.owner_username
  const id = comment?.item_summary?.id
  const ownerAvatar = getAvatarURIForEmail(email)
  const onClick = () => {
    dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.GROWTH_OPEN_ITEM))
    const link = teamsHelper.getDeeplinkWithAppInfo(teamsConfig).toItem(id)
    executeDeepLink(link)
  }
  return (
    <ItemContainer>
      <ItemPersona src={ownerAvatar} />
      <ItemButton onClick={onClick}>{name}</ItemButton>
    </ItemContainer>
  )
})
