import { useEffect } from 'react'
import _ from 'lodash'
import { FILTER_REDUCER_KEYS } from '../common/src/reducers/filtersKeys'
import { bindActionCreators } from 'redux'
import { useStore, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { getItemsTagsFilter, getProjectsTagsFilter } from '../common/src/selectors/filtersSelectors'
import { setItemsTagsFilter, setProjectsTagsFilter } from '../common/src/actions/filtersActions'
import * as queryParamsHelper from '../helpers/queryParamsHelper'

export const useFilterTagsFromParams = (isProjectFilter = false) => {
  const store = useStore()
  const state = store.getState()
  const dispatch = useDispatch()
  const location = useLocation()
  const searchQueryTags = queryParamsHelper.getSearchParamsByTag()
  const history = useHistory()
  const setFilter = isProjectFilter ? setProjectsTagsFilter : setItemsTagsFilter
  const getFilter = isProjectFilter ? getProjectsTagsFilter : getItemsTagsFilter

  useEffect(() => {
    const tagsFilter = getFilter(state, isProjectFilter ? FILTER_REDUCER_KEYS.PROJECTS : FILTER_REDUCER_KEYS.SEARCH)
    const setTagsFilter = bindActionCreators(
      _.partialRight(setFilter, isProjectFilter ? FILTER_REDUCER_KEYS.PROJECTS : FILTER_REDUCER_KEYS.SEARCH),
      dispatch
    )

    const onInsertTagFilter = tagName => {
      const currentTagNames = tagsFilter.toArray()
      const newTagNames = _.concat(currentTagNames, tagName)
      setTagsFilter(newTagNames)
    }
    const queryParams = new URLSearchParams(location.search)

    if (searchQueryTags && !_.includes(tagsFilter.toArray(), searchQueryTags)) {
      onInsertTagFilter(searchQueryTags)
      queryParams.delete('tag_filter')
      history.replace({
        search: queryParams.toString(),
      })
    }
  }, [dispatch, getFilter, history, isProjectFilter, location.search, searchQueryTags, setFilter, state])
}
