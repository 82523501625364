import _ from 'lodash'
import { useCallback, useEffect, useReducer } from 'react'
import { FileContext } from '../../contexts'
import { useDeleteFiles, useUploadFiles } from '../../hooks/filesHooks'
import { fileHelper, itemHelper } from '../../common/src/helpers'
import { safeNameFromFile } from '../../common/src/helpers/fileHelper'
import { AlertModal } from '../modal/AlertModal'
import { useTranslation } from 'react-i18next'
import { electronFileDownloadedSubject } from '../../reactions'
import { datefns } from '../../utils/datefns'
import { useAtom } from 'jotai'
import { hideConfirmationUploadFileAtom } from '@/atoms'

const ACTION_TYPE = {
  CLEAR: 'CLEAR',
  SET_CONFIRM_ALERT: 'SET_CONFIRM_ALERT',
  SET_OVERWRITE_ALERT: 'SET_OVERWRITE_ALERT',
  HIDE: 'HIDE',
}

const initialState = {
  alertTitle: 'item.file.confirm_upload_title',
  alertSubTitle: 'item.file.confirm_upload_subtitle',
  alertCount: 0,
  item: null,
  files: null,
  duplicatedFilenamesCount: 0,
  openDialog: false,
}

const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case ACTION_TYPE.CLEAR:
      return initialState
    case ACTION_TYPE.HIDE:
      return { ...state, openDialog: false }
    case ACTION_TYPE.SET_CONFIRM_ALERT: {
      const { files } = payload
      return {
        alertTitle: 'item.file.confirm_upload_title',
        alertSubTitle: 'item.file.confirm_upload_subtitle',
        alertCount: files.length,
        ...payload,
        openDialog: true,
      }
    }
    case ACTION_TYPE.SET_OVERWRITE_ALERT: {
      const { duplicatedCount } = payload
      return {
        alertTitle: 'item.file.confirm_overwrite_title',
        alertSubTitle: 'item.file.confirm_overwrite_subtitle',
        alertCount: duplicatedCount,
        ...payload,
        openDialog: true,
      }
    }
    default:
      throw new Error()
  }
}

const useElectronListener = () => {
  useEffect(() => {
    if (!window.electronAPI?.registerOnFileDownloaded) return
    window.electronAPI.registerOnFileDownloaded(file => {
      electronFileDownloadedSubject.next(file)
    })
  }, [])
}

const processFileNames = files =>
  _.map(files, f => {
    if (f.name === 'image.png' || f.name === 'imagen.png') {
      const now = datefns.format('yyyy-MM-dd-HH.mm.ss')(new Date())
      const name = `image-${now}.png`
      const mimeType = 'image/png'
      const blob = f.slice(0, f.size, mimeType)
      return new File([blob], name, { type: mimeType })
    }
    return f
  })

export const FileManager = ({ children }) => {
  const [state, localDispatch] = useReducer(reducer, initialState)
  const { alertTitle, alertSubTitle, item, files, openDialog, alertCount } = state
  const uploadFiles = useUploadFiles()
  const deleteFiles = useDeleteFiles()
  const { t } = useTranslation()
  const [hideConfirmationUploadFile, setHideConfirmationUploadFile] = useAtom(hideConfirmationUploadFileAtom)

  useElectronListener()

  const safeUploadFiles = useCallback(
    ({ item, files, showConfirmation = false, showToast = true }) => {
      files = processFileNames(files)
      const filenames = itemHelper.getFiles(item).map(fileHelper.getFilename).toArray()
      const duplicatedFilenames = _.map(files, f => {
        const name = safeNameFromFile(f.name)
        return _.find(filenames, filename => filename === name)
      }).filter(o => !!o)

      if (duplicatedFilenames.length > 0) {
        localDispatch({
          type: ACTION_TYPE.SET_OVERWRITE_ALERT,
          payload: { item, files, duplicatedCount: duplicatedFilenames.length },
        })
        return Promise.resolve()
      } else if (!hideConfirmationUploadFile && showConfirmation) {
        localDispatch({
          type: ACTION_TYPE.SET_CONFIRM_ALERT,
          payload: { item, files, duplicatedCount: duplicatedFilenames.length },
        })
        return Promise.resolve()
      }
      return uploadFiles({ item, files, showToast })
    },
    [hideConfirmationUploadFile, uploadFiles]
  )

  const onDismissDialog = useCallback(() => {
    localDispatch({
      type: ACTION_TYPE.HIDE,
    })
  }, [localDispatch])

  const onConfirmUpload = useCallback(() => {
    onDismissDialog()
    return uploadFiles({ item, files })
  }, [uploadFiles, onDismissDialog, item, files])

  const onDismissed = useCallback(() => {
    localDispatch({
      type: ACTION_TYPE.CLEAR,
    })
  }, [localDispatch])

  const providerValue = {
    uploadFiles: safeUploadFiles,
    deleteFiles,
  }
  return (
    <FileContext.Provider value={providerValue}>
      {children}
      <AlertModal
        title={t(alertTitle)}
        subText={t(alertSubTitle, { count: alertCount })}
        open={openDialog}
        onDismiss={onDismissDialog}
        onPrimaryActionClick={onConfirmUpload}
        onDismissed={onDismissed}
        onYesAlwaysClick={() => {
          setHideConfirmationUploadFile(true)
          onConfirmUpload()
        }}
      />
    </FileContext.Provider>
  )
}
