import { MenuItem, MenuItemProps } from '@fluentui/react-components'
import { Link } from 'react-router-dom'
import type { HTMLAttributeAnchorTarget } from 'react'

export const MenuItemLink = ({
  href,
  target,
  ...rest
}: MenuItemProps & { href: string; target?: HTMLAttributeAnchorTarget }) => {
  return (
    <Link to={href} target={target} className="no-underline">
      <MenuItem {...rest} />
    </Link>
  )
}
