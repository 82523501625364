import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { debounceTime, Subject } from 'rxjs'
import { shallowCompareArrays } from '../common/src/utils'
import _ from 'lodash'

export const useSideEffectAfterMount = (method, varArray) => {
  const firstRun = useRef(true)
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false
      return
    }
    if (_.isFunction(method)) {
      method()
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, varArray)
}

export const useOnVariableChange = (method, varArray) => {
  const last = useRef(['this_is a String'])
  // eslint-disable-next-line react-compiler/react-compiler
  if (!shallowCompareArrays(last.current, varArray)) {
    if (_.isFunction(method)) {
      method()
    }
  }
  // eslint-disable-next-line react-compiler/react-compiler
  last.current = varArray
}

export const useForceUpdate = () => {
  const [updateKey, setUpdate] = useState({})
  return {
    forceUpdate: () => {
      setUpdate({})
    },
    updateKey,
  }
}

export const useDebouncedHandler = (mapper, callback, debounceMs = 500) => {
  const [topic] = useState(() => new Subject())
  useLayoutEffect(() => {
    const subscription = topic.pipe(debounceTime(debounceMs)).subscribe(callback)
    return () => {
      subscription.unsubscribe()
    }
  }, [callback, debounceMs, topic])
  return useCallback((...args) => topic.next(mapper(...args)), [mapper, topic])
}
