export const ACTION_TYPES = {
  SEARCH: 'VIEW/SEARCH',
  PROJECTS: 'VIEW/PROJECTS',
  ONE_ON_ONE: 'VIEW/ONE_ON_ONE',
  PROJECT_HANDLER_INAPP: 'VIEW/PROJECT_HANDLER_INAPP',
  ONE_PROJECT_HANDLER: 'VIEW/ONE_PROJECT_HANDLER',
  PROJECT_DETAIL: 'VIEW/PROJECT_DETAIL',
  ITEM_DETAIL: 'VIEW/ITEM_DETAIL',
  ITEM_FILTERS: 'VIEW/ITEM_FILTERS',
  PROJECTS_FILTERS: 'VIEW/PROJECTS_FILTERS',
  ITEM_TAGS: 'VIEW/ITEM_FILTERS',
  PROJECT_TAGS: 'VIEW/PROJECTS_FILTERS',
  INBOX: 'VIEW/INBOX',
  RECENTLY_VISITED: 'VIEW/RECENTLY_VISITED',
  MS_COLLABORATORS: 'VIEW/MS_COLLABORATORS',
}

export const recentlyVisitedView = () => ({
  type: ACTION_TYPES.RECENTLY_VISITED,
})

export const msCollaborators = () => ({
  type: ACTION_TYPES.MS_COLLABORATORS,
})

export const searchView = () => ({
  type: ACTION_TYPES.SEARCH,
})

export const projectsView = () => ({
  type: ACTION_TYPES.PROJECTS,
})

export const projectDetail = () => ({
  type: ACTION_TYPES.PROJECT_DETAIL,
})

export const itemDetail = () => ({
  type: ACTION_TYPES.ITEM_DETAIL,
})

export const oneOnOneView = () => ({
  type: ACTION_TYPES.ONE_ON_ONE,
})

export const projectHandlerInApp = pid => ({
  type: ACTION_TYPES.PROJECT_HANDLER_INAPP,
  payload: pid,
})

export const oneProjectHandler = (projectID, fastToken) => ({
  type: ACTION_TYPES.ONE_PROJECT_HANDLER,
  payload: { projectID, fastToken },
})
