/**
 * In this file we write shared code that can be useful in different reducers.
 * For instance, item/project tags are handled in a very similar way
 */

import { fromJS, is } from 'immutable'
import { tagHelper } from '../helpers'

const findTagName = name => t => tagHelper.getName(t) === name

export const addTagCreator = (byIdKey, tagsKey) => (state, id, tag) => {
  const { name } = tag
  const path = [byIdKey, id, tagsKey]
  const tags = state.getIn(path)
  if (tags && !tags.some(findTagName(name))) {
    const newTags = tags.push(fromJS(tag))
    state = state.setIn(path, newTags)
  }
  return state
}

export const removeTagCreator = (byIdKey, tagsKey) => (state, id, tag) => {
  const { name } = tag
  const path = [byIdKey, id, tagsKey]
  const tags = state.getIn(path)
  if (!tags) return state
  const newTags = tags.filterNot(findTagName(name))
  if (!is(tags, newTags)) {
    state = state.setIn(path, newTags)
  }
  return state
}
