import * as resourceRequestSelectors_ from './resourceRequestSelectors'
import * as teamsSelectors_ from './teamsSelectors'
import * as meetingsSelectors_ from './meetingSelectors'
import * as authSelectors_ from './authSelectors'
import * as configSelectors_ from './configSelectors'

export const resourceRequestSelectors = resourceRequestSelectors_
export const teamsSelectors = teamsSelectors_
export const meetingsSelectors = meetingsSelectors_
export const authSelectors = authSelectors_
export const configSelectors = configSelectors_
