import React from 'react'
import styled from 'styled-components'
import { Shimmer, ShimmerElementType } from '@fluentui/react/lib/Shimmer'
import { Card, CardContainer } from './common'
import { FlexColumn, FlexRow } from '../../layout/FlexContainer'

const Circle = styled(Shimmer).attrs({
  shimmerElements: [
    {
      type: ShimmerElementType.circle,
      height: 48,
    },
  ],
})`
  margin: 10px;
`
const Line = styled(Shimmer).attrs({
  shimmerElements: [
    {
      type: ShimmerElementType.line,
      height: 15,
    },
  ],
})`
  margin-bottom: 7px;
`
const PersonaContainer = styled(FlexRow)`
  width: 300px;
`
const PersonaText = styled(FlexColumn)`
  flex: 1;
  margin-top: 15px;
`

const GhostGrowthPersonaCard_ = ({ ...rest }) => {
  const shimmerColors = {}
  return (
    <CardContainer {...rest}>
      <Card>
        <PersonaContainer>
          <Circle />
          <PersonaText>
            <Line width="70%" />
            <Line shimmerColors={shimmerColors} width="25%" />
          </PersonaText>
        </PersonaContainer>
        <PersonaText>
          <Line shimmerColors={shimmerColors} width="70%" />
          <Line shimmerColors={shimmerColors} width="25%" />
        </PersonaText>
      </Card>
    </CardContainer>
  )
}

export const GhostGrowthPersonaCard = React.memo(GhostGrowthPersonaCard_)
