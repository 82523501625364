import { cn } from '@appfluence/classnames'
import type { FC } from 'react'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  progress: number
  width?: number
  height?: number
}

export const ProgressBar: FC<IProps> = ({ width = 32, height = 5, progress, className, ...rest }) => {
  return (
    <div
      {...rest}
      style={{
        width,
        height,
      }}
      className={cn('bg-pm-neutral-lighter', className)}
    >
      <div
        className={'h-full bg-pm-theme-primary'}
        style={{
          width: `${progress}%`,
        }}
      />
    </div>
  )
}
