import { useLayoutEffect } from 'react'
import _ from 'lodash'
import { Route, Switch } from 'react-router'
import { PrivateRoute } from './privateRoute'
import { LocationForRoutesContext } from '../contexts'
import { useSetCenterTitle } from '@/components/managers/ParamsManager'
import { useCenterGetter } from '@/components/mobileNavBar/commonMobileNavBar'

const getSwitchElement = (key, children) => {
  const render = location => {
    return (
      <Switch key={key} {...(location ? { location } : {})}>
        {children}
      </Switch>
    )
  }
  return <LocationForRoutesContext.Consumer>{render}</LocationForRoutesContext.Consumer>
}

export const NestedRoute = ({ data, key }) => {
  'use no memo'
  const { component, Parent } = data
  const RouteType = data.route || PrivateRoute
  if (_.isArray(component)) {
    if (component.length) {
      const render = props => {
        const nestedRoutes = _.map(component, (_data, i) => {
          const nextData = {
            ..._data,
            path: data.path + _data.path,
          }
          const nextProps = {
            key: i,
            data: nextData,
          }
          return NestedRoute(nextProps)
        })
        const switchElement = getSwitchElement(key, nestedRoutes)
        if (Parent) {
          return <Parent key={key}>{switchElement}</Parent>
        }
        return switchElement
      }
      return <Route path={data.path} render={render} key={key} />
    }
    throw new Error('Error. Invalid routes')
  }

  const _render = props => {
    if (data.component) {
      return (
        <>
          <data.component routeId={data.routeId} {...props} />
          <CenterTitleCapturer />
        </>
      )
    } else {
      return data.render(props)
    }
  }
  return (
    <RouteType
      path={data.path}
      render={_render}
      key={key}
      routeId={data.routeId}
      loginPath={data.loginPath}
      excludePromotions={data.excludePromotions}
    />
  )
}

const CenterTitleCapturer = () => {
  const setter = useSetCenterTitle()
  const centerData = useCenterGetter()
  const cacheString = JSON.stringify(centerData)
  useLayoutEffect(() => {
    setter(centerData)
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheString, setter])
  return null
}
