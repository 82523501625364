import React from 'react'
import { ICON_NAMES } from '../../common/src/msIcons'
import { BasicDateSection } from './BasicDateSection'
import { itemHelper, reminderHelper } from '../../common/src/helpers'
import { useTranslation } from 'react-i18next'
import { FREQUENCY_TRANSLATION_KEYS } from '../dropdown/FrequencyDropdown'

export const RepeatSection = ({
  frequency,
  untilDate,
  onChangeFrequency,
  onChangeUntilDate,
  onClick,
  readOnly = false,
  ...rest
}) => {
  const { t } = useTranslation()
  const isRecurring = frequency !== itemHelper.FREQUENCY_TYPE.NEVER && frequency !== reminderHelper.FREQUENCY_TYPE.ONCE
  const text = isRecurring ? t(`date.frequency.${FREQUENCY_TRANSLATION_KEYS[frequency]}`) : null

  return isRecurring ? (
    <BasicDateSection
      name={t('date.repeat')}
      text={text}
      iconName={ICON_NAMES.RecurringEvent}
      onClick={readOnly ? undefined : onClick}
      {...rest}
    />
  ) : null
}
