import React, { memo, useMemo } from 'react'
import _ from 'lodash'
import { useRecentlyModified } from '../../common/src/hooks/projectHooks'
import { ProjectCardsSection } from '../card/ProjectCardsSection'

export const RecentProjectsSection = memo(({ maxCards = 6, ...rest }) => {
  const projectsList = useRecentlyModified(maxCards)
  const projects = useMemo(() => {
    return _.slice(projectsList.toArray(), 0, maxCards)
  }, [projectsList, maxCards])
  return <ProjectCardsSection showNewProjectCard={projects.length < 3} projects={projects} {...rest} />
})
