import _ from 'lodash'
import { createAction } from 'redux-api-middleware'
import { PM_API_RESOURCES, PM_API_URLS } from '../constants'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'
import { itemHelper, fileHelper } from '../helpers'
import { appendQueryParamsToURL, extendPMHeaders, getCredentialsConfig } from '../helpers/requestHelper'

export const postFile = ({ item, itemFile, file, overwritingItemURL = false }) => {
  let promise = void 0
  try {
    const itemID = itemHelper.getId(item)
    const fileID = fileHelper.getID(itemFile)
    const body = {
      item: itemHelper.getResourceURI(item),
      content: file,
      forceOverwriteItemURL: overwritingItemURL.toString(),
    }
    if (itemFile) {
      body.id = fileID.toString()
    }

    //todo check FormData polyfill
    const formData = _.reduce(
      body,
      (acc, v, k) => {
        if (acc) {
          acc.append(k, v)
        }
        return acc
      },
      new FormData()
    )
    promise = createAction({
      endpoint: appendQueryParamsToURL(PM_API_URLS.POST_FILE),
      credentials: getCredentialsConfig(),
      method: 'POST',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.POST_FILE, { itemID, fileID }),
      headers: extendPMHeaders({}, false),
      body: formData,
    })
  } catch (err) {
    console.log(err)
  }
  return promise
}

export const deleteFile = itemFile => {
  let promise = void 0
  try {
    const fileID = fileHelper.getID(itemFile)
    const itemID = fileHelper.getItemID(itemFile)
    const url = _.template(PM_API_URLS.FILE)({ id: fileID })
    promise = createAction({
      endpoint: appendQueryParamsToURL(url),
      credentials: getCredentialsConfig(),
      method: 'DELETE',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.DELETE_FILE, { itemID, fileID }),
      headers: extendPMHeaders(),
    })
  } catch (err) {
    console.log(err)
  }
  return promise
}
