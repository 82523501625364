import React, { useMemo } from 'react'
import { Icon } from '@fluentui/react/lib/Icon'
import { FontSizes, mergeStyleSets } from '@fluentui/react/lib/Styling'
import { cn } from '@appfluence/classnames'
import { useTheme } from 'styled-components'

const classNames = {
  container: 'Downloads-container',
  text: 'Downloads-text',
  icon: 'Downloads-icon',
}

const useClassNames = () => {
  const theme = useTheme()
  return useMemo(() => {
    return mergeStyleSets({
      container: [
        classNames.container,
        {
          display: 'flex',
          alignItems: 'center',
        },
      ],
      text: [classNames.text, {}],
      icon: [
        classNames.icon,
        {
          marginLeft: '12px',
          color: theme.palette.themePrimary,
          fontSize: FontSizes.icon,
        },
      ],
    })
  }, [theme])
}

export const Downloads = ({ count = 0, className, ...rest }) => {
  const classNames = useClassNames()

  return (
    <div className={cn(classNames.container, className)} {...rest}>
      <div className={classNames.text}>{count}</div>
      <Icon className={classNames.icon} iconName={'Download'} />
    </div>
  )
}
