import { type CommandProps, Editor, type Extensions } from '@tiptap/react'
import { Document } from '@tiptap/extension-document'
import { Paragraph } from '@tiptap/extension-paragraph'
import { Text } from '@tiptap/extension-text'
import { BulletList } from '@tiptap/extension-bullet-list'
import { ListItem } from '@tiptap/extension-list-item'
import { TaskList } from '@tiptap/extension-task-list'
import { TaskItem } from '@tiptap/extension-task-item'

export const extensionsWithDefaultList: Extensions = [
  Document,
  Paragraph,
  Text,
  BulletList,
  ListItem,
  TaskList,
  TaskItem.configure({ nested: true }),
]

// Runs a command in an alternative editor with a different set of extensions
export const runCommandInAlternativeEditor = (
  { editor: originalEditor, chain }: CommandProps,
  alternativeExtensions: Extensions,
  action: (editor: Editor) => boolean
) => {
  const alternativeEditor = new Editor({
    extensions: alternativeExtensions,
    content: originalEditor.getJSON(),
  })
  alternativeEditor.commands.setTextSelection(originalEditor.state.selection)
  const couldPerformAction = action(alternativeEditor)
  if (!couldPerformAction) {
    return false
  }
  return chain().setContent(alternativeEditor.getJSON(), true).setTextSelection(alternativeEditor.state.selection).run()
}
