import React, { useEffect, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { setLocation } from '../../actions/locationActions'
import { HistoryContext } from '../../contexts'
import { withRouter } from 'react-router'
import { getRemember } from '../../helpers/queryParamsHelper'
import { setMultipleSelectionOn, clearItemMultipleSelection } from '../../actions/uiActions'
import { useRouteId } from '../../hooks/useRouteId'

const NAV_TYPES = {
  POP: 'POP',
  PUSH: 'PUSH',
  REPLACE: 'REPLACE',
}

const reducer = (state, { type }) => {
  const { count } = state
  if (type === NAV_TYPES.PUSH) {
    return { count: count + 1, canGoForward: false }
  } else if (type === NAV_TYPES.POP) {
    return { count: count - 1, canGoForward: true }
  }
  return state
}

const useHistoryCounter = history => {
  const [{ count, canGoForward }, dispatch] = useReducer(reducer, { count: 0, canGoForward: false })
  const reduxDispatch = useDispatch()
  useEffect(() => {
    const listener = history.listen((routeInfo, type) => {
      dispatch({ type })
      const remember = getRemember()
      if (remember) {
        const { pathname } = routeInfo
        reduxDispatch(setLocation(pathname))
      }
    })
    return () => listener()
  }, [history, reduxDispatch])
  return { count, canGoForward }
}

const useClearMultiSelection = () => {
  const routeId = useRouteId()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setMultipleSelectionOn(false))
    dispatch(clearItemMultipleSelection())
  }, [routeId, dispatch])
}

const HistoryManager_ = ({ history, location, children }) => {
  const { count, canGoForward } = useHistoryCounter(history)
  useClearMultiSelection()
  const providerValue = {
    count,
    canGoForward,
    history,
    location,
  }
  return <HistoryContext.Provider value={providerValue}>{children}</HistoryContext.Provider>
}

export const HistoryManager = withRouter(HistoryManager_)
