import { useToastController } from '@fluentui/react-components'
import { useInstantiateCustomTemplate, useInstantiatePublicTemplate } from '../common/src/hooks/projectTemplateHooks'
import { useSendStepEventIfNeeded } from './onboardingHooks'
import { useCallback, useId } from 'react'
import { uriDataCreator } from '../common/src/helpers/URLHelper'
import { PM_API_RESOURCE_TYPE } from '../common/src/constants'
import { linkSubject } from '../reactions/linkSubject'
import { projectHelper } from '../common/src/helpers'
import { OnboardingStepKeys } from '../actions/onboardingActions'
import { useTranslation } from 'react-i18next'
import { FluentToast } from '../components/toast/FluentToast'

const useInstantiateTemplateAdvanced = instantiateTemplate => {
  const { dispatchToast, updateToast } = useToastController()
  const sendStepEventIfNeeded = useSendStepEventIfNeeded()
  const { t } = useTranslation()
  const toastId = useId()

  const onCreateByDefault = useCallback(serverID => {
    const urlData = uriDataCreator(1, PM_API_RESOURCE_TYPE.PROJECT, serverID)
    linkSubject.next({ urlData })
  }, [])

  return useCallback(
    async ({
      open = true,
      notify = true,
      delayToOpen = 0,
      sendCreateFirstProjectOnboardingStepEvent = true,
      onCreate = onCreateByDefault,
      ...rest
    }) => {
      if (notify) {
        dispatchToast(<FluentToast>{t('project_template.instantiating')}</FluentToast>, {
          intent: 'info',
          timeout: -1,
          toastId,
        })
      }

      const response = await instantiateTemplate(rest)
      const { error, payload } = response
      if (!error) {
        // Open project
        if (open) {
          const projectID = projectHelper.getIdd(payload)
          if (delayToOpen > 0) {
            setTimeout(() => {
              onCreate(projectID)
            }, delayToOpen)
          } else {
            onCreate(projectID)
          }
        }

        // Send onboarding step event
        if (sendCreateFirstProjectOnboardingStepEvent) {
          sendStepEventIfNeeded(OnboardingStepKeys.CREATE_FIRST_PROJECT)
        }
      }

      if (notify) {
        updateToast({
          toastId,
          content: (
            <FluentToast>
              {error
                ? t('project_template.instantiated_unsuccessfully')
                : t('project_template.instantiated_successfully')}
            </FluentToast>
          ),
          intent: error ? 'error' : 'success',
          timeout: 5000,
        })
      }

      return response
    },
    [dispatchToast, instantiateTemplate, onCreateByDefault, sendStepEventIfNeeded, t, toastId, updateToast]
  )
}

export const useInstantiateCustomTemplateAdvanced = () => {
  const instantiateCustomTemplate = useInstantiateCustomTemplate()
  return useInstantiateTemplateAdvanced(instantiateCustomTemplate)
}

export const useInstantiatePublicTemplateAdvanced = () => {
  const instantiatePublicTemplate = useInstantiatePublicTemplate()
  return useInstantiateTemplateAdvanced(instantiatePublicTemplate)
}
