import { createAction } from 'redux-api-middleware'
import _ from 'lodash'
import {
  appendQueryParamsToURL,
  extendPMHeaders,
  getCredentialsConfig,
  withDefaultParams,
} from '../helpers/requestHelper'
import { PM_API_RESOURCES, PM_API_URLS } from '../constants'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'
import { requestNotNeeded } from './generalActions'
import { customProjectTemplateSelector, publicProjectTemplateSelector } from '../selectors/projectTemplatesSelectors'

const DEFAULT_PARAMETERS = {
  ALL: {
    limit: 100,
  },
  CREATE_TEMPLATE: {
    reset_items: false,
    skip_completed: false,
    include_members: true,
  },
}

export const getCustomProjectTemplates = params =>
  createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.CUSTOM_PROJECT_TEMPLATES, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.CUSTOM_PROJECT_TEMPLATES),
    headers: extendPMHeaders(),
  })
export const getCustomProjectTemplatesByDefault = withDefaultParams(getCustomProjectTemplates, DEFAULT_PARAMETERS.ALL)

const generateCustomProjectTemplateHandler = method => (templateID, params) => {
  const url = _.template(PM_API_URLS.CUSTOM_PROJECT_TEMPLATE)({ id: templateID })
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method,
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.CUSTOM_PROJECT_TEMPLATE, { method, id: templateID }),
    headers: extendPMHeaders(),
  })
}

export const getCustomProjectTemplate = generateCustomProjectTemplateHandler('GET')
export const deleteCustomProjectTemplate = generateCustomProjectTemplateHandler('DELETE')

export const getCustomProjectTemplateIfNotExists = (templateID, params) => (dispatch, getState) => {
  const state = getState()
  const exists = !!customProjectTemplateSelector(state, templateID)
  return !exists ? dispatch(getCustomProjectTemplate(templateID, params)) : Promise.resolve(requestNotNeeded)
}

export const getPublicProjectTemplateCategories = params =>
  createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.PUBLIC_PROJECT_TEMPLATE_CATEGORIES, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.PUBLIC_PROJECT_TEMPLATE_CATEGORIES),
    headers: extendPMHeaders(),
  })

export const getPublicProjectTemplates = params =>
  createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.PUBLIC_PROJECT_TEMPLATES, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.PUBLIC_PROJECT_TEMPLATES),
    headers: extendPMHeaders(),
  })
export const getPublicProjectTemplatesByDefault = withDefaultParams(getPublicProjectTemplates, DEFAULT_PARAMETERS.ALL)

export const getPublicProjectTemplate = (templateID, params) => {
  const url = _.template(PM_API_URLS.PUBLIC_PROJECT_TEMPLATE)({ id: templateID })
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.PUBLIC_PROJECT_TEMPLATE),
    headers: extendPMHeaders(),
  })
}

export const getPublicProjectTemplateIfNotExists = (templateID, params) => (dispatch, getState) => {
  const state = getState()
  const exists = !!publicProjectTemplateSelector(state, templateID)
  return !exists ? dispatch(getPublicProjectTemplate(templateID, params)) : Promise.resolve(requestNotNeeded)
}

// Instantiate

export const instantiateCustomProjectTemplate = (templateID, params) => {
  const url = _.template(PM_API_URLS.INSTANTIATE_CUSTOM_PROJECT_TEMPLATES)({ id: templateID })
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.INSTANTIATE_PROJECT_TEMPLATES),
    headers: extendPMHeaders(),
  })
}

export const instantiatePublicProjectTemplate = (templateID, params) => {
  const url = _.template(PM_API_URLS.INSTANTIATE_PUBLIC_PROJECT_TEMPLATES)({ id: templateID })
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.INSTANTIATE_PROJECT_TEMPLATES),
    headers: extendPMHeaders(),
  })
}

// Create

export const createCustomTemplateFromProject = (projectID, params = DEFAULT_PARAMETERS.CREATE_TEMPLATE) => {
  const url = _.template(PM_API_URLS.CREATE_CUSTOM_TEMPLATE_FROM_PROJECT)({ id: projectID })
  const body = JSON.stringify(params)
  return createAction({
    endpoint: url,
    credentials: getCredentialsConfig(),
    method: 'POST',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.CUSTOM_PROJECT_TEMPLATE),
    headers: extendPMHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
    }),
    body,
  })
}
