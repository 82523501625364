import { APIS, PM_API_RESOURCES } from '../common/src/constants'
import { API_EVENT, generateActionTypeString } from '../common/src/helpers/reducerHelper'
import { getSharingRequestsIfNotPending } from '../common/src/actions/combinedAPI'
import { Me } from '@/types/me'
import { useQuery } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

declare global {
  interface Window {
    prioritymatrix: {
      mePromise: Promise<Me | undefined>
      projectSummariesPromise: Promise<any | undefined>
      sessionToken: string | undefined
    }
  }
}

const useMeInit = () => {
  const dispatch = useDispatch()
  const { data, isError } = useQuery({
    queryKey: ['me'],
    queryFn: () => window.prioritymatrix.mePromise,
    staleTime: 0,
  })

  const eventType = isError ? API_EVENT.ERROR : data ? API_EVENT.SUCCESS : undefined

  useEffect(() => {
    if (eventType) {
      const type = generateActionTypeString(APIS.PM, PM_API_RESOURCES.ME, eventType)
      dispatch({
        type,
        payload: data ?? {},
      })
    }
  }, [eventType, dispatch, data])

  const id = data?.id

  useEffect(() => {
    if (id) dispatch(getSharingRequestsIfNotPending(id))
  }, [id, dispatch])
}

const useProjectSummariesInit = () => {
  const dispatch = useDispatch()
  const { data, isError } = useQuery({
    queryKey: ['projectSummaries'],
    queryFn: () => window.prioritymatrix.projectSummariesPromise,
    staleTime: 0,
  })

  const eventType = isError ? API_EVENT.ERROR : data ? API_EVENT.SUCCESS : undefined

  useEffect(() => {
    if (eventType) {
      const type = generateActionTypeString(APIS.PM, PM_API_RESOURCES.ALL_PROJECT_SUMMARIES, eventType)
      dispatch({
        type,
        payload: data,
      })
    }
  }, [eventType, dispatch, data])
}

export const useFastRequests = () => {
  useProjectSummariesInit()
  useMeInit()
}
