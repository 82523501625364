import { memo } from 'react'
import type { FC } from 'react'

interface IColorCellProps {
  color: string
  onClick: () => void
}

export const ColorCell: FC<IColorCellProps> = memo(({ color, onClick, ...rest }) => {
  const colorCellStyle = { backgroundColor: color }
  return (
    <div className="relative float-left flex h-12 w-12" {...rest}>
      <button className="m-2 flex-1 cursor-pointer rounded-sm border-none" onClick={onClick} style={colorCellStyle} />
    </div>
  )
})
