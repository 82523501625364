import { connect } from 'react-redux'
import { stateHelper } from '../common/src/helpers'
import { bindActionCreators } from 'redux'
import { inviteToItemWithId, postItem } from '../common/src/actions/itemsAPI'
import { dispatchEvent as trackEvent } from '../common/src/eventTracking/amplitudeEvents'
import { getAllProjectsIfNeeded } from '../common/src/actions/combinedAPI'

const mapStateToProps = (state, ownProps) => {
  const { collaborator } = ownProps
  let commonProjects
  if (collaborator) {
    commonProjects = stateHelper.getProjectsWith(state, collaborator)
  } else {
    commonProjects = stateHelper.getAllProjects(state)
  }
  return {
    commonProjects: commonProjects.toArray(),
    meEmail: stateHelper.getMeEmail(state),
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      postItem,
      inviteToItemWithId,
      trackEvent,
      getAllProjectsIfNeeded,
    },
    dispatch
  )
}

export const createItem1on1ContainerGenerator = connect(mapStateToProps, mapDispatchToProps)
