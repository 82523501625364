import { useQuery } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { getConfig } from '../common/src/actions/configAPI'
import { useMemo } from 'react'
import { isEmbeddedOnTeams } from '@/helpers/queryParamsHelper'

interface Config {
  microsoft_graph_backend: string
  microsoft_aad_backend: string
  aad_app_id: string
  apple_id_client: string
  apple_id_team: string
  theme_color: string
  theme_light_color: string
  theme_dark_color: string
  app_display_name: string
  app_display_name_short: string
  public: boolean
  custom: boolean
  inbox: boolean
  referral_program: boolean
  ai: boolean
  ai_deployed: boolean
  task_connectors: boolean
  free_tier: boolean
  gov_sso: boolean
  commercial_sso: boolean
  forced_sso: boolean
  public_saml: boolean
  hipaa: boolean
  help_url: string
  info_url: string
  eula_url: string
  privacy_url: string
  inactivity_logout: number
  outlook_app_id: string
  teams_app_id: string
  teams_publisher: string
  edit_comment_limit_minutes: number
  review: boolean
}

export const useConfig = () => {
  const dispatch = useDispatch()
  return useQuery({
    queryKey: ['config'],
    queryFn: async () => {
      const params = {} as Record<string, number>
      const embeddedOnTeams = isEmbeddedOnTeams()
      if (embeddedOnTeams) {
        params.teams = 1
      }
      const res = await dispatch(getConfig(params))
      return res.payload as Config
    },
    staleTime: 5 * 60 * 1000,
  })
}

export const useTeamsConfig = () => {
  const { data: config } = useConfig()
  return useMemo(
    () => ({
      appId: config?.teams_app_id,
      publisher: config?.teams_publisher,
    }),
    [config]
  )
}

const DEFAULT_INACTIVITY_LOGOUT = 600
export const useInactivityLogout = (): number => {
  const config = useConfig().data
  if (config?.inactivity_logout) return config.inactivity_logout
  if (config?.hipaa) return DEFAULT_INACTIVITY_LOGOUT
  return 0
}
