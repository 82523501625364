import { API_EVENT, generateActionTypeString } from '../common/src/helpers/reducerHelper'
import { APIS, PM_API_RESOURCES } from '../common/src/constants'
import { AppEventType } from '../integrations/electron'

const Handlers = {
  [generateActionTypeString(APIS.PM, PM_API_RESOURCES.SIGN_OUT, API_EVENT.ERROR)]: _action => {
    window.electronAPI.notifyEvent?.(AppEventType.SignOut)
  },
  [generateActionTypeString(APIS.PM, PM_API_RESOURCES.ME, API_EVENT.SUCCESS)]: action => {
    window.electronAPI.notifyEvent?.(AppEventType.SignIn, action.payload)
  },
}

export const electronEventMiddleware = _store => next => action => {
  if (window.electronAPI) {
    Handlers[action.type]?.(action)
  }
  return next(action)
}
