import styled from 'styled-components'
import { BasicCard } from '../BasicCard'

export const Card = styled(BasicCard)`
  height: 345px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 20px;
`

export const CardContainer = styled.div`
  height: 365px;
  padding: 5px;
`
