import React, { memo, useCallback, useMemo } from 'react'
import { Label } from '../../text/Text'
import { ActionButton } from '@fluentui/react/lib/Button'
import { ContainerPanel } from '../../panels/ContainerPanel'
import { PanelView } from '../../panels/PanelView'
import styled from 'styled-components'
import { UsersViewWithSearch } from '../usersList/UsersListWithSearch'
import { UsersListCell } from '../usersList/UsersListCell'
import { MenuButton } from './UsersPanel'
import { cn } from '@appfluence/classnames'
import { useTranslation } from 'react-i18next'

const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  min-height: 20px;
  padding-bottom: 4px;

  &.spaceBetween {
    justify-content: space-between;
  }
`

const ClearButton = styled(ActionButton).attrs({
  styles: {
    label: {
      margin: 0,
    },
  },
})`
  height: auto;
  padding: 0;
`

const UserPanelView = memo(
  ({
    user,
    project,
    options,
    onSelectUser,
    selectedUserHeader,
    textFieldHeader,
    textFieldPlaceholder,
    invitationIconProps,
    clearText,
    hiddenClearButton = false,
    menuProps,
    ...rest
  }) => {
    const { t } = useTranslation()
    const onClearOwner = useCallback(() => {
      onSelectUser()
    }, [onSelectUser])

    const users = useMemo(() => {
      return user ? [user] : []
    }, [user])

    const headerContainerClassName = cn({ spaceBetween: !!menuProps })

    const showHeader = !hiddenClearButton || menuProps
    const headerChildren = showHeader ? (
      <HeaderContainer className={headerContainerClassName}>
        {!hiddenClearButton && (
          <ClearButton disabled={!user} onClick={onClearOwner}>
            {clearText}
          </ClearButton>
        )}
        {menuProps && <MenuButton menuProps={menuProps} />}
      </HeaderContainer>
    ) : undefined

    return (
      <PanelView headerChildren={headerChildren} {...rest}>
        <Label>{selectedUserHeader}</Label>
        <UsersListCell user={user} project={project} readOnly={true} />
        <UsersViewWithSearch
          project={project}
          textFieldHeaderText={t('users.add_members')}
          textFieldHeader={textFieldHeader}
          textFieldPlaceholder={textFieldPlaceholder}
          invitationIconProps={invitationIconProps}
          selectedUsers={users}
          options={options}
          onSelectUser={onSelectUser}
        />
      </PanelView>
    )
  }
)

export const UserPanel = memo(
  ({
    headerText,
    user,
    project,
    options,
    onSelectUser,
    selectedUserHeader,
    textFieldHeader,
    textFieldPlaceholder,
    invitationIconProps,
    clearText,
    hiddenClearButton = false,
    menuProps,
    onDismiss,
    ...rest
  }) => {
    const { t } = useTranslation()
    return (
      <ContainerPanel headerText={headerText || t('users.choose_user')} onDismiss={onDismiss} {...rest}>
        <UserPanelView
          user={user}
          project={project}
          options={options}
          onSelectUser={onSelectUser}
          selectedUserHeader={t('users.change_user')}
          textFieldHeader={textFieldHeader}
          textFieldPlaceholder={textFieldPlaceholder}
          invitationIconProps={invitationIconProps}
          clearText={t('users.clear')}
          hiddenClearButton={hiddenClearButton}
          menuProps={menuProps}
        />
      </ContainerPanel>
    )
  }
)
