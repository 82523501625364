import { PM_API_RESOURCES, PM_API_URLS } from '../constants'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'
import { createAction } from 'redux-api-middleware'
import { appendQueryParamsToURL, extendPMHeaders, getCredentialsConfig } from '../helpers/requestHelper'

export const syncTZOffset = date => {
  const url = PM_API_URLS.SYNC_TZ_OFFSET
  const params = {
    tz_offset: -date.getTimezoneOffset() / 60,
  }
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.SYNC_TZ_OFFSET),
    headers: extendPMHeaders(),
  })
}
