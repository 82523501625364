import { useSelector } from 'react-redux'
import { stateHelper } from '@/common/src/helpers'
import { Suspense, lazy, useCallback, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { dateToTimestampInSeconds } from '@/common/src/helpers/dateHelper'
import { objToQueryParamsString } from '@/common/src/helpers/functionalUtils'
import { getPaginatedSearchItem } from '@/common/src/actions/searchAPI'
import { Option, DateInterval, ProjectList } from '@/types'
import { useDispatch } from 'react-redux'
import { useFilteredItems, useParamsForItemsFilter } from '@/common/src/hooks/filtersHooks'
import { ITEM_FILTER_SECTION_KEY } from '@/common/src/reducers/filtersKeys'
import { retry } from '@/common/src/retry'
import { Loading } from '@/components/basic/Loading'
import { getAllItemSummaries } from '@/common/src/actions/itemsAPI'
import { ItemList } from '@/types'
import type { PMCalendar as PMCalendarComponent } from './PMCalendar'

const PMCalendar = lazy<typeof PMCalendarComponent>(() =>
  retry(() => import('./PMCalendar')).then(m => ({ default: m.PMCalendar }))
)

const getDefaultInterval = () => {
  const now = new Date()
  return [now, now]
}

const useFetchData = (interval: Option<DateInterval>) => {
  const dispatch = useDispatch()
  const [startDate, endDate] = interval ?? getDefaultInterval()
  const baseParams = useParamsForItemsFilter(ITEM_FILTER_SECTION_KEY.GLOBAL_CALENDAR)

  const params = {
    ...baseParams,
    dueDate__gt: dateToTimestampInSeconds(startDate),
    dueDate__lt: dateToTimestampInSeconds(endDate),
    summaries: 1,
  }

  const qp = objToQueryParamsString(params)

  const queries = [
    useQuery({
      queryKey: ['calendar-items', qp],
      queryFn: () => {
        return dispatch(getPaginatedSearchItem(params, 'calendar'))
      },
      enabled: !!interval,
    }),
    useQuery({
      queryKey: ['calendar-items', 'recurrence'],
      queryFn: () => {
        return dispatch(
          getAllItemSummaries({
            dueDate__gt: 1,
            frequency_not: 'never',
          })
        )
      },
      enabled: !!interval,
    }),
  ]

  return {
    isLoading: queries.some(q => q.isLoading),
  }
}

const useCalendarData = () => {
  const [interval, setDateRange] = useState<Option<DateInterval>>()
  const { isLoading } = useFetchData(interval)
  const allItems = useSelector(stateHelper.getAllItemsMap)
  const filtered = useFilteredItems(allItems, ITEM_FILTER_SECTION_KEY.GLOBAL_CALENDAR) as ItemList

  const projects = useSelector(stateHelper.getAllProjects) as ProjectList
  const onChangeRenderInterval = useCallback((i: DateInterval) => setDateRange(i), [setDateRange])

  return {
    items: filtered,
    projects,
    onChangeRenderInterval,
    isLoading,
  }
}

export const PMGlobalCalendar = () => {
  const { items, projects, onChangeRenderInterval, isLoading } = useCalendarData()
  return (
    <Suspense fallback={<Loading />}>
      <PMCalendar
        items={items}
        projects={projects}
        readonly={false}
        global
        onChangeRenderInterval={onChangeRenderInterval}
        loading={isLoading}
      />
    </Suspense>
  )
}
