import { BulletList } from '@tiptap/extension-bullet-list'
import { ListItem } from '@tiptap/extension-list-item'
import { TaskList } from '@tiptap/extension-task-list'
import { TaskItem } from '@tiptap/extension-task-item'
import { runCommandInAlternativeEditor, extensionsWithDefaultList } from './TipTapNotes/tiptapUtils'

// Custom List Item to avoid multiple paragraphs in a list item
export const CustomListItem = ListItem.extend({ content: 'paragraph list*' })

// With `CustomListItem` content config, it's not possible to toggle a list in multiple lines,
// so we replace `toggleBulletList` command to play it in an alternative editor with default ListItem
export const CustomBulletList = BulletList.extend({
  addCommands() {
    return {
      toggleBulletList: () => commandProps => {
        return runCommandInAlternativeEditor(commandProps, extensionsWithDefaultList, alternativeEditor =>
          alternativeEditor.commands.toggleBulletList()
        )
      },
    }
  },
})

// Custom Task Item to avoid multiple paragraphs in a task item
export const CustomTaskItem = TaskItem.extend({ content: 'paragraph list*' }).configure({ nested: true })

// With `CustomTaskItem` content config, it's not possible to toggle a list in multiple lines,
// so we replace `toggleTaskList` command to play it in an alternative editor with default TaskItem
export const CustomTaskList = TaskList.extend({
  addCommands() {
    return {
      toggleTaskList: () => commandProps => {
        return runCommandInAlternativeEditor(commandProps, extensionsWithDefaultList, editor =>
          editor.commands.toggleTaskList()
        )
      },
    }
  },
})
