import { fromJS } from 'immutable'
import {
  API_EVENT,
  isPMApiDetailedAction,
  parseApiActionType,
  PERSIST_REHYDRATE_ACTION_TYPE,
} from '../helpers/reducerHelper'
import { PM_API_RESOURCES } from '../constants'
import { GENERIC_ACTION_TYPE } from '../actions/genericActions'
import { ASSOCIATED_LINKS_REDUCER_KEYS } from './associatedLinksReducerKeys'
import { linkDependencyHelper } from '../helpers'

const base = fromJS({
  [ASSOCIATED_LINKS_REDUCER_KEYS.BY_ID]: {},
})

export const associatedLinks = (state = base, action) => {
  const { type, payload, meta } = action
  const apiAction = parseApiActionType(type)
  if (isPMApiDetailedAction(apiAction)) {
    const { resource, event } = apiAction
    if (event === API_EVENT.SUCCESS) {
      switch (resource) {
        case PM_API_RESOURCES.ASSOCIATED_LINKS:
          {
            const links = payload.objects
            const { itemID } = meta
            state = state.setIn([ASSOCIATED_LINKS_REDUCER_KEYS.BY_ID, itemID], fromJS(links))
          }
          break
        case PM_API_RESOURCES.DELETE_ASSOCIATED_LINKS:
          {
            const { itemID, linkID } = meta
            const links = state.getIn([ASSOCIATED_LINKS_REDUCER_KEYS.BY_ID, itemID])
            const newLinks = links.filterNot(l => linkDependencyHelper.getID(l) === linkID)
            state = state.setIn([ASSOCIATED_LINKS_REDUCER_KEYS.BY_ID, itemID], newLinks)
          }
          break
      }
    }
  } else {
    switch (type) {
      case PERSIST_REHYDRATE_ACTION_TYPE:
        {
          if (payload.items) {
            state = state.merge(payload.items)
          }
        }
        break
      case GENERIC_ACTION_TYPE.CLEAR_ALL:
        {
          state = base
        }
        break
      default:
        break
    }
  }
  return state
}
