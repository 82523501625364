/* eslint-disable react-compiler/react-compiler */

import React, { Suspense, useCallback, useEffect, useMemo, useRef } from 'react'
import { cn } from '@appfluence/classnames'
import { bindActionCreators } from 'redux'
import { connect, useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'lodash'
import { DirectionalHint, IconButton, TextField } from '@fluentui/react'
import { useOnVariableChange } from '../../hooks/hooks'
import { projectDetail as projectDetailInit } from '../../actions/viewInitializationActions'
import { getProject } from '../../common/src/actions/projectsAPI'
import { getProjectIfNotPending, markProjectAsModified, sendProject } from '../../common/src/actions/combinedAPI'
import { getSharingRequestsByProject } from '../../common/src/actions/sharingRequestsAPI'
import '../ItemDetail/ItemDetail.css'
import { Separator } from '../../components/basic/Separator'
import { projectHelper, stateHelper } from '../../common/src/helpers'
import { Loading } from '../../components/basic/Loading'
import { DateSection } from '../../components/basic/DateSection'
import { textWithoutWhitespacesAndNewlines } from '../../common/src/helpers/stringHelper'
import { KEY_CODE } from '../../common/src/helpers/keyboardHelper'
import { ProjectSection } from './ProjectSection'
import { CalendarLinkSection } from './ProjectDetailSubcomponents'
import { dateToTimestampInSeconds, increaseDaysToSecondsTimestamp } from '../../common/src/helpers/dateHelper'
import { ProjectMembersPanel } from '../../components/project/ProjectMembersPanel'
import { getRelativePathToMatrixBasedOnMode, getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { ColorsPanel } from '../../components/panels/colors/ColorsPanel'
import { quadrantColorObjects } from '../../templates/quadrantColorObjects'
import { quadrantColorStringFromObject } from '../../common/src/helpers/colorsHelpers'
import { ProjectDatesPanel } from '../../components/panels/project/ProjectDatesPanel'
import { OnboardingCoachmark } from '../../components/onboarding/OnboardingCoachmark'
import { useCreateOnboardingStep } from '../../hooks/onboardingHooks'
import { OnboardingStepKeys } from '../../actions/onboardingActions'
import { addArchiveTag } from '../../common/src/actions/tagsAPI'
import { AlertModal } from '../../components/modal/AlertModal'
import { useRandomValueFromArray } from '../../hooks/arrayHooks'
import { useObtainCalendarLink } from '../../hooks/projectHooks'
import { useMergeState } from '../../common/src/hooks/enhancedHooks'
import { useAsyncEffect } from '../../common/src/hooks/useAsyncEffect'
import { timeout } from '../../utils'
import { useTranslation } from 'react-i18next'
import { LazyIViewPlaceholder } from '../../components/placeholder/LazyIViewPlaceholder'
import { useRouteId } from '../../hooks/useRouteId'
import { ROUTE_ID } from '../../routes/routeIdList'
import { StarredCell } from '../../components/starred/StarredCell'
import { ProjectOptionsButton } from './ProjectOptionsButton'
import { QuadrantSelector } from '../../components/input/quadrant/QuadrantSelector'
import { ProjectMembersCollection } from '../../components/users/ProjectMembersCollection'
import { TagDetails } from '../../components/tags/TagDetails'
import { UserGroupsDropdown } from '../../components/dropdown/UserGroupsDropdown'
import { useSelectedProject, useSelectedProjectId } from '../../hooks/PMHooks'
import { UserCollectionCell } from '@/components/users/usersCollection/UserCollectionCell'
import { ProjectNotesEditor } from '@/views/project/ProjectNotesEditor'
import { useAllProjectCounters } from '@/queries/projectCounters'
import { CircleFilled } from '@fluentui/react-icons'
import { Link } from '@fluentui/react-components'
import { getPreferredMatrixViewMode } from '@/selectors/uiSelectors'
import { FREQUENT_ITEM_FILTERS, setFrequentItemFilter } from '@/common/src/actions/filtersActions'

const styles = {
  projectName: {
    fontSize: '20px',
    maxHeight: '3.6em',
    padding: '0px',
  },
  notes: {
    padding: '6px 0px 6px',
  },
}

const REQUIRED_INACTIVITY_PERIOD = 3000

const ALERT_KEY = {
  ARCHIVE: 'ARCHIVE_ITEM',
  USER_GROUP: 'USER_GROUP',
}

const initialState = {
  updateManually: 1,
  isOpenMembersPanel: false,
  isOpenDatesPanel: false,
  isOpenColorPanel: false,
  alertType: ALERT_KEY.ARCHIVE,
  isOpenAlertModal: false,
  selectedQuadrant: 0,
}

const ProjectDetail = ({
  readOnly = false,
  markProjectAsModified,
  sendProject,
  getSharingRequestsByProject,
  history,
  project: givenProject,
}) => {
  const { data: counters } = useAllProjectCounters()
  const _selectedProjectID = useSelectedProjectId()
  const selectedProjectID = givenProject ? projectHelper.getIdd(givenProject) : _selectedProjectID
  const projectCounters = counters?.[selectedProjectID]
  const _projectInGlobalState = useSelectedProject()
  const projectInGlobalState = givenProject || _projectInGlobalState
  const me = useSelector(stateHelper.getMe)
  const isAdmin = useMemo(() => projectHelper.isAdminUser(projectInGlobalState, me), [me, projectInGlobalState])
  const [state, setState] = useMergeState(initialState)
  const routeId = useRouteId()
  const isProjectAppRoute = routeId === ROUTE_ID.PROJECT_APP || routeId === ROUTE_ID.SINGLE_PROJECT
  const {
    updateManually,
    isOpenMembersPanel,
    isOpenDatesPanel,
    isOpenColorPanel,
    alertType,
    isOpenAlertModal,
    selectedQuadrant,
  } = state
  const _container = useRef(null)
  const projectRef = useRef(null)
  const renderedId = useRef(null)
  const debouncedMarkProjectAsModify = useRef(_.debounce(markProjectAsModified, 250, { trailing: true }))
  const debouncedSendProject = useRef(_.debounce(sendProject, REQUIRED_INACTIVITY_PERIOD, { trailing: true }))
  const dispatch = useDispatch()

  const membersOnboardingStep = useCreateOnboardingStep(OnboardingStepKeys.SHARE_PROJECT)

  useEffect(() => {
    dispatch(projectDetailInit())
  }, [dispatch])

  const { t } = useTranslation()

  useEffect(() => {
    if (selectedProjectID) {
      getSharingRequestsByProject(selectedProjectID)
    }
  }, [selectedProjectID, getSharingRequestsByProject])

  useAsyncEffect(async () => {
    await timeout(0)
    if (selectedProjectID && selectedProjectID !== renderedId.current) {
      dispatch(getProjectIfNotPending({ projectID: selectedProjectID, memberships: true }))
    }
    renderedId.current = selectedProjectID
    return () => {
      debouncedSendProject.current.flush()
    }
  }, [selectedProjectID, debouncedSendProject])

  // Still need to improve this section to properly manage changes in projects
  useOnVariableChange(() => {
    if (!projectInGlobalState) {
      projectRef.current = null
      return
    }

    projectRef.current = projectInGlobalState
    const clearedName = textWithoutWhitespacesAndNewlines(projectRef.current.get('name'))
    projectRef.current = projectRef.current.set('name', clearedName)
  }, [projectInGlobalState])

  const forceUpdate = useCallback(() => {
    setState({ updateManually: updateManually + 1 })
  }, [setState, updateManually])

  const showMembersPanel = useCallback(() => {
    setState({ isOpenMembersPanel: true })
  }, [setState])

  const hideMembersPanel = useCallback(() => {
    setState({ isOpenMembersPanel: false })
  }, [setState])

  const showColorPanel = useCallback(() => {
    setState({ isOpenColorPanel: true })
  }, [setState])

  const showColorPanelForQuadrant = useCallback(
    q => () => {
      setState({ selectedQuadrant: q })
      showColorPanel()
    },
    [showColorPanel, setState]
  )

  const hideColorPanel = useCallback(() => {
    setState({ isOpenColorPanel: false })
  }, [setState])

  const openDatesPanel = useCallback(() => {
    setState({ isOpenDatesPanel: true })
  }, [setState])
  const closeDatesPanel = useCallback(() => {
    setState({ isOpenDatesPanel: false })
  }, [setState])

  const showAlertModal = useCallback(
    alertType => {
      setState({ isOpenAlertModal: true, alertType })
    },
    [setState]
  )
  const hideAlertModal = useCallback(() => {
    setState({ isOpenAlertModal: false })
  }, [setState])

  const changeProperty = useCallback(
    (prop, value, update = true) => {
      if (projectRef.current) {
        projectRef.current = projectRef.current.set(prop, value)
        if (update) {
          forceUpdate()
        }
      }

      debouncedMarkProjectAsModify.current(projectRef.current)
      debouncedSendProject.current(projectRef.current)
    },
    [forceUpdate]
  )

  const onChangeColor = useCallback(
    color => {
      const key = projectHelper.QUADRANT_COLORS[selectedQuadrant]
      changeProperty(key, quadrantColorStringFromObject(color), true)
    },
    [selectedQuadrant, changeProperty]
  )

  const project = projectRef.current

  const archiveProject = useCallback(() => {
    dispatch(addArchiveTag({ object: projectRef.current }))
  }, [dispatch])

  const projectName = projectHelper.getName(project)

  const { copyCalendarLink, getCalendarLink, calendarLink, isLoadingCalendarLink } =
    useObtainCalendarLink(selectedProjectID)

  // Alert props
  const alertProps = useMemo(() => {
    switch (alertType) {
      case ALERT_KEY.ARCHIVE:
        return {
          title: t('project_detail.archive_alert_title'),
          subText: t('project_detail.archive_alert_subtitle'),
          primaryActionText: t('general.archive'),
          onPrimaryActionClick: archiveProject,
        }
      case ALERT_KEY.USER_GROUP:
        return {
          title: t('project_detail.user_group_alert_title'),
          subText: t('project_detail.user_group_alert_subtitle'),
        }
      default:
        return null
    }
  }, [alertType, t, archiveProject])

  const onChangeName = useCallback((ev, text) => changeProperty(projectHelper.KEYS.NAME, text), [changeProperty])

  const onChangeNotes = useCallback(text => changeProperty(projectHelper.KEYS.NOTES, text), [changeProperty])

  const onChangeStartDate = useCallback(
    value => {
      const startTimestamp = dateToTimestampInSeconds(value)
      const dueTimestamp = projectHelper.getEndTimestamp(project)
      if (dueTimestamp <= startTimestamp) {
        const newDueTimestamp = increaseDaysToSecondsTimestamp(startTimestamp, 1)
        changeProperty(projectHelper.KEYS.END_DATE, newDueTimestamp, false)
      }
      changeProperty(projectHelper.KEYS.START_DATE, startTimestamp)
    },
    [project, changeProperty]
  )

  const onChangeEndDate = useCallback(
    value => {
      const endTimestamp = dateToTimestampInSeconds(value)
      const startTimestamp = projectHelper.getStartTimestamp(project)
      if (startTimestamp >= endTimestamp) {
        const newStartTimestamp = increaseDaysToSecondsTimestamp(endTimestamp, -1)
        changeProperty(projectHelper.KEYS.START_DATE, newStartTimestamp, false)
      }
      changeProperty(projectHelper.KEYS.END_DATE, endTimestamp)
    },
    [project, changeProperty]
  )

  const onChangeQuadrantName = useCallback(
    q => (ev, value) => {
      changeProperty(projectHelper.QUADRANT_NAMES[q], value)
    },
    [changeProperty]
  )

  const onKeyPressName = useCallback(event => {
    if (event.keyCode === KEY_CODE.ENTER) {
      event.preventDefault()
      event.target.blur()
    }
  }, [])

  const onChangeUserGroup = useCallback(
    (event, option) => {
      if (!isAdmin) {
        showAlertModal(ALERT_KEY.USER_GROUP)
        return
      }

      const { groupID } = option
      changeProperty(projectHelper.KEYS.USER_GROUP_ID, groupID)
    },
    [isAdmin, changeProperty, showAlertModal]
  )

  const placeholders = useMemo(() => {
    return [
      {
        title: t('project_detail.placeholder_0.title'),
        message: t('project_detail.placeholder_0.message'),
        type: 2,
      },
      {
        title: t('project_detail.placeholder_1.title'),
        message: t('project_detail.placeholder_1.message'),
        type: 2,
      },
      {
        title: t('project_detail.placeholder_2.title'),
        message: t('project_detail.placeholder_2.message'),
        type: 2,
      },
    ]
  }, [t])

  const placeholder = useRandomValueFromArray(placeholders)

  const creatorUsername = projectHelper.getCreatorUsername(project)
  const creator = useSelector(state => stateHelper.getUserWithEmail(state, creatorUsername))
  const preferredProjectViewMode = useSelector(getPreferredMatrixViewMode)

  if (!selectedProjectID || !project || projectHelper.isDeleted(project)) {
    return (
      <Suspense fallback={<Loading />}>
        <LazyIViewPlaceholder {...placeholder} />
      </Suspense>
    )
  }

  const startDate = projectHelper.getStartDate(project)
  const endDate = projectHelper.getEndDate(project)
  const overdue = !!endDate && endDate.getTime() < Date.now()
  const quadrantRows = [0, 1, 2, 3].map(i => {
    const name = projectHelper.getQuadrantName(project, i)
    return (
      <div className="mx-0 my-2 flex w-full" key={i}>
        <QuadrantSelector
          className="mr-1"
          size={32}
          project={project}
          quadrant={i}
          readOnly={readOnly}
          onClick={showColorPanelForQuadrant(i)}
          counters={projectCounters ? { [i]: projectCounters[i] } : undefined}
        />
        <TextField
          className="line-h max-h-[3.6em] flex-grow text-xl leading-none"
          autoAdjustHeight
          borderless
          id={`projectDetail_quadrant_${i}`}
          resizable={false}
          value={name}
          onKeyDown={onKeyPressName}
          onChange={onChangeQuadrantName(i)}
          readOnly={readOnly}
        />
      </div>
    )
  })

  const selectedUserGroupID = projectHelper.getUserGroupID(project)
  const onClickOwner = readOnly ? void 0 : showMembersPanel
  const onClickGoToMatrix = () => {
    const path = getRelativeURLKeepingQuerySearch.matrixForProjectId(selectedProjectID)
    history.push(path)
  }
  const hideOnReadonly = cn({ pm_hidden: readOnly || !isProjectAppRoute })

  const goToOverdueMode = preferredProjectViewMode === 'list' ? 'list' : 'matrix'
  const overdueHref = getRelativePathToMatrixBasedOnMode(goToOverdueMode)(selectedProjectID)

  const onClickOverdue = event => {
    event.preventDefault()
    dispatch(setFrequentItemFilter(FREQUENT_ITEM_FILTERS.OVERDUE, goToOverdueMode))
    history.push(overdueHref)
  }

  return (
    <div className="flex flex-col overflow-auto px-2 py-0" ref={_container} id="projectDetailContainer">
      <div className="mb-1 mt-2.5 flex w-full">
        <StarredCell object={project} readOnly={readOnly} className="mr-3 self-start text-2xl leading-5" />
        <TextField
          className="flex-1 p-0"
          autoAdjustHeight
          multiline
          borderless
          resizable={false}
          value={projectName}
          id={'projectDetail_projectName'}
          onKeyDown={onKeyPressName}
          onChange={onChangeName}
          readOnly={readOnly}
          style={styles.projectName}
        />
        <IconButton
          id={'projectDetail_goToMatrixButton'}
          className={hideOnReadonly}
          iconProps={{ iconName: 'Go' }}
          onClick={onClickGoToMatrix}
          title={t('project_detail.go_to_matrix_tooltip')}
        />
        <ProjectOptionsButton id="projectDetail_optionButton" project={projectInGlobalState} />
      </div>
      <Separator />
      <ProjectSection name={t('project_detail.user_group_title_section')} isGroupUsersSection={true}>
        <UserGroupsDropdown
          id="projectDetail_userGroupDropdown"
          className="mb-2"
          styles={{ title: { borderStyle: 'none', paddingLeft: 0 } }}
          selectedID={selectedUserGroupID}
          onChange={onChangeUserGroup}
          readOnly={readOnly}
        />
      </ProjectSection>
      <Separator />
      <ProjectSection name={t('project_detail.members_title_section')}>
        <ProjectMembersCollection
          className="mb-2"
          project={projectInGlobalState}
          max={16}
          addButtonProps={{
            title: t('project_detail.add_new_member_tooltip'),
          }}
          onClick={onClickOwner}
          readOnly={readOnly}
          containerRef={membersOnboardingStep.ref}
        />
        {membersOnboardingStep.isVisible && (
          <OnboardingCoachmark
            stepKey={membersOnboardingStep.key}
            target={membersOnboardingStep.ref.current}
            onDismiss={membersOnboardingStep.hideCoachmark}
            positioningContainerProps={{ directionalHint: DirectionalHint.leftCenter }}
          />
        )}
        <ProjectMembersPanel project={project} onDismiss={hideMembersPanel} isOpen={isOpenMembersPanel} />
      </ProjectSection>
      <Separator />
      <ProjectSection name={t('project_detail.creator')}>
        <UserCollectionCell
          user={creator}
          className="mb-2"
          personaProps={{
            className: 'flex flex-col',
            secondaryText: project.get('creationDate')
              ? new Date(project.get('creationDate') * 1000).toLocaleString(undefined, {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })
              : undefined,
          }}
        />
      </ProjectSection>
      <Separator />
      <ProjectSection name={t('project_detail.quadrants_title_section')}>
        {quadrantRows}
        {!!projectCounters?.overdue && (
          <div className="mb-2 mt-1 flex items-center gap-3">
            <div className="flex w-8 justify-center">
              <CircleFilled className="text-red-600" />
            </div>
            <Link href={overdueHref.pathname} onClick={onClickOverdue}>
              {t('project_detail.overdue_count', { count: projectCounters.overdue })}
            </Link>
          </div>
        )}
      </ProjectSection>
      <Separator />
      <ProjectSection name={t('project_detail.notes_title_section')}>
        <ProjectNotesEditor value={projectHelper.getNotes(project)} onChange={onChangeNotes} readOnly={readOnly} />
      </ProjectSection>
      <Separator />
      <ProjectSection name={t('project_detail.dates_title_section')}>
        <div className="mb-2 flex">
          <ProjectDatesPanel
            t={t}
            startDate={startDate}
            dueDate={endDate}
            onChangeStartDate={onChangeStartDate}
            onChangeDueDate={onChangeEndDate}
            isOpen={isOpenDatesPanel}
            onDismiss={closeDatesPanel}
          />
          <DateSection
            name={t('project_detail.start_date')}
            date={startDate}
            overdue={overdue}
            allDay={true}
            onClick={openDatesPanel}
            readOnly={readOnly}
            styles={{ root: { marginRight: '12px' } }}
          />
          <DateSection
            name={t('project_detail.end_date')}
            date={endDate}
            allDay={true}
            overdue={overdue}
            onClick={openDatesPanel}
            readOnly={readOnly}
          />
        </div>
      </ProjectSection>
      <Separator />
      <ProjectSection name={t('project_detail.tags_title_section')}>
        <TagDetails
          className="mb-2"
          object={project}
          readOnly={readOnly}
          placeholderTagProps={{ id: 'projectDetail_addTagsPlaceholder' }}
        />
      </ProjectSection>
      <Separator />
      <ProjectSection name={t('project_detail.calendar')}>
        <CalendarLinkSection
          calendarLink={calendarLink}
          copyCalendarLink={copyCalendarLink}
          getCalendarLink={getCalendarLink}
          isLoadingCalendarLink={isLoadingCalendarLink}
        />
      </ProjectSection>
      <ColorsPanel
        isOpen={isOpenColorPanel}
        onDismiss={hideColorPanel}
        colorObjects={quadrantColorObjects}
        onChangeColor={onChangeColor}
      />
      <AlertModal {...alertProps} open={isOpenAlertModal} onDismiss={hideAlertModal} />
    </div>
  )
}

export const ProjectDetailView = withRouter(
  connect(
    state => ({}),
    dispatch =>
      bindActionCreators(
        {
          getProject,
          markProjectAsModified,
          sendProject,
          getSharingRequestsByProject,
        },
        dispatch
      )
  )(ProjectDetail)
)
