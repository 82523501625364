export const USERS_KEYS = {
  REDUCER: 'users',
  ME: 'me',
  COLLABORATORS: 'collaborators',
  GRAPH_COLLABORATORS: 'graphCollaborators',
  GRAPH_PROFILE: 'graphProfile',
  TEAMS_MEMBERS: 'teamsMembers',
  PLATFORMS: 'platforms',
  ID: 'id',
  EMAIL: 'email',
}
