import _ from 'lodash'
import { createAction } from 'redux-api-middleware'
import { PM_API_RESOURCES, PM_API_URLS } from '../constants'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'
import { createPaginatedAPIAction } from '../helpers/paginationHelper'
import { sharingRequestHelper } from '../helpers'
import { appendQueryParamsToURL, extendPMHeaders, getCredentialsConfig } from '../helpers/requestHelper'
import { OBJECT_ERROR_STATUS, ObjectError } from '../errors/errors'

export const getSharingRequests = userID => {
  const params = userID ? { target_user: userID } : {}
  return createPaginatedAPIAction(params => {
    const _defParams = {
      status: 'init',
    }
    params = _.extend(_defParams, params)
    return createAction({
      endpoint: appendQueryParamsToURL(PM_API_URLS.ALL_SHARING_REQUESTS, params),
      credentials: getCredentialsConfig(),
      method: 'GET',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.ALL_SHARING_REQUESTS, { userID }),
      headers: extendPMHeaders(),
    })
  })(params)
}

export const getSharingRequestsByProject = projectID => {
  if (!projectID || projectID <= 0) {
    const errorObject = new ObjectError(OBJECT_ERROR_STATUS.INVALID_IDENTIFIER, 'Invalid project identifier')
    return { type: 'INVALID_PROJECT_ID', payload: errorObject }
  }

  return createPaginatedAPIAction(params => {
    const _defParams = {
      status: 'init',
      target_user__isnull: 'false',
      project: projectID,
    }
    params = _.extend(_defParams, params)
    return createAction({
      endpoint: appendQueryParamsToURL(PM_API_URLS.ALL_SHARING_REQUESTS, params),
      credentials: getCredentialsConfig(),
      method: 'GET',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.ALL_SHARING_REQUESTS, { projectID }),
      headers: extendPMHeaders(),
    })
  })()
}

export const getSharingRequest = id => {
  const _url = _.template(PM_API_URLS.SHARING_REQUEST)({ id })
  const types = generateActionTypesForPMAPI(PM_API_RESOURCES.SHARING_REQUEST, id)
  return createAction({
    endpoint: appendQueryParamsToURL(_url),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types,
    headers: extendPMHeaders(),
  })
}

export const postSharingRequest = request => {
  const body = sharingRequestHelper.prepareForSend(request)
  return createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.ALL_SHARING_REQUESTS),
    credentials: getCredentialsConfig(),
    method: 'POST',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.SHARING_REQUEST_POST, { request }),
    headers: extendPMHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
    }),
    body,
  })
}

export const putSharingRequest = request => {
  const id = sharingRequestHelper.getId(request)
  if (id) {
    const _url = _.template(PM_API_URLS.SHARING_REQUEST)({ id })
    const body = sharingRequestHelper.prepareForSend(request)
    return createAction({
      endpoint: appendQueryParamsToURL(_url),
      credentials: getCredentialsConfig(),
      method: 'PUT',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.SHARING_REQUEST_PUT, { request }),
      headers: extendPMHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      }),
      body,
    })
  } else {
    const type = 'WRONG_SHARING_REQUEST_PUT'
    console.warn(type)
    return { type }
  }
}

export const putAcceptingSharingRequest = request => {
  const newRequest = sharingRequestHelper.setStatus(request, sharingRequestHelper.STATUS.ACCEPTED)
  return putSharingRequest(newRequest)
}

export const putRejectingSharingRequest = request => {
  const newRequest = sharingRequestHelper.setStatus(request, sharingRequestHelper.STATUS.REJECTED)
  return putSharingRequest(newRequest)
}

export const getRequestAccess = projectID => {
  if (projectID) {
    const _url = _.template(PM_API_URLS.REQUEST_ACCESS)({ idd: projectID })
    const types = generateActionTypesForPMAPI(PM_API_RESOURCES.REQUEST_ACCESS, { projectID })
    return createAction({
      endpoint: appendQueryParamsToURL(_url),
      credentials: getCredentialsConfig(),
      method: 'GET',
      types,
      headers: extendPMHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      }),
    })
  } else {
    const type = 'WRONG_REQUEST_ACCESS'
    console.warn(type)
    return { type }
  }
}
