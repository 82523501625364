import React from 'react'
import styled from 'styled-components'
import { PlaceholderView } from '../PlaceholderView'
import HINT_IMAGE from '../../resources/images/views/gantt/local_gantt_location.webp'

const Hint = styled.img.attrs({
  src: HINT_IMAGE,
  alt: 'Hint',
})`
  margin-top: 24px;
  max-width: 100%;
`

export const GanttFeaturePlaceholder = () => {
  return (
    <PlaceholderView prefix="feature.placeholder.gantt">
      <Hint />
    </PlaceholderView>
  )
}
