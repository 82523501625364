import { useAtom } from 'jotai'
import { itemTableColumnsAtoms, useResetItemTableColumns } from '@/atoms/itemTableColumnsAtoms'
import { ITEM_COLUMN_KEYS } from '@/components/table/itemColumnKeys'
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Switch,
} from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef } from 'react'
import { useNarrowWidth } from '@/hooks/useNarrowWidth'

export type ItemColumnSelectorDialogProps = {
  open: boolean
  onDismiss: () => void
}
export const ItemColumnSelectorDialog = ({ open, onDismiss }: ItemColumnSelectorDialogProps) => {
  const { t } = useTranslation()
  const [showOwnerColumn, setShowOwnerColumn] = useAtom(itemTableColumnsAtoms[ITEM_COLUMN_KEYS.OWNER])
  const [showQuadrantColumn, setShowQuadrantColumn] = useAtom(itemTableColumnsAtoms[ITEM_COLUMN_KEYS.QUADRANT])
  const [showProgressColumn, setShowProgressColumn] = useAtom(itemTableColumnsAtoms[ITEM_COLUMN_KEYS.PROGRESS])
  const [showDueDateColumn, setShowDueDateColumn] = useAtom(itemTableColumnsAtoms[ITEM_COLUMN_KEYS.DUE_DATE])
  const [showStartDateColumn, setShowStartDateColumn] = useAtom(itemTableColumnsAtoms[ITEM_COLUMN_KEYS.START_DATE])
  const [showLastModifiedColumn, setShowLastModifiedColumn] = useAtom(
    itemTableColumnsAtoms[ITEM_COLUMN_KEYS.LAST_MODIFIED_TIMESTAMP]
  )
  const [showIconColumn, setShowIconColumn] = useAtom(itemTableColumnsAtoms[ITEM_COLUMN_KEYS.ICON])
  const [showEffortColumn, setShowEffortColumn] = useAtom(itemTableColumnsAtoms[ITEM_COLUMN_KEYS.EFFORT])
  const [showCompletionDateColumn, setShowCompletionDateColumn] = useAtom(
    itemTableColumnsAtoms[ITEM_COLUMN_KEYS.COMPLETION_DATE]
  )
  const [showCreatorColumn, setShowCreatorColumn] = useAtom(itemTableColumnsAtoms[ITEM_COLUMN_KEYS.CREATOR])
  const [showCreationDateColumn, setShowCreationDateColumn] = useAtom(
    itemTableColumnsAtoms[ITEM_COLUMN_KEYS.CREATION_DATE]
  )

  const narrowWidth = useNarrowWidth() as boolean

  const reset = useResetItemTableColumns()

  const primaryButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (open) {
      primaryButtonRef.current?.focus()
    }
  }, [open])

  return (
    <Dialog
      open={open}
      onOpenChange={(event, data) => {
        if (!data.open) {
          onDismiss()
        }
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t('item_filters.select_columns.dialog.title')}</DialogTitle>
          <DialogContent>
            {t('item_filters.select_columns.dialog.description')}
            <div className="flex flex-col">
              <Switch
                label={t('item.quadrant')}
                checked={showQuadrantColumn === 'responsive' ? !narrowWidth : showQuadrantColumn}
                onChange={(event, data) => setShowQuadrantColumn(data.checked)}
              />
              <Switch
                label={t('item.icon.title')}
                checked={showIconColumn === 'responsive' ? !narrowWidth : showIconColumn}
                onChange={(event, data) => setShowIconColumn(data.checked)}
              />
              <Switch
                label={t('item.owner')}
                checked={showOwnerColumn === 'responsive' ? !narrowWidth : showOwnerColumn}
                onChange={(event, data) => setShowOwnerColumn(data.checked)}
              />
              <Switch
                label={t('item.creator')}
                checked={showCreatorColumn === 'responsive' ? !narrowWidth : showCreatorColumn}
                onChange={(event, data) => setShowCreatorColumn(data.checked)}
              />
              <Switch
                label={t('item.progress.name')}
                checked={showProgressColumn === 'responsive' ? !narrowWidth : showProgressColumn}
                onChange={(event, data) => setShowProgressColumn(data.checked)}
              />
              <Switch
                label={t('item.effort.name')}
                checked={showEffortColumn === 'responsive' ? !narrowWidth : showEffortColumn}
                onChange={(event, data) => setShowEffortColumn(data.checked)}
              />
              <Switch
                label={t('item.creation_date')}
                checked={showCreationDateColumn === 'responsive' ? !narrowWidth : showCreationDateColumn}
                onChange={(event, data) => setShowCreationDateColumn(data.checked)}
              />
              <Switch
                label={t('item.start')}
                checked={showStartDateColumn === 'responsive' ? !narrowWidth : showStartDateColumn}
                onChange={(event, data) => setShowStartDateColumn(data.checked)}
              />
              <Switch
                label={t('item.due')}
                checked={showDueDateColumn === 'responsive' ? !narrowWidth : showDueDateColumn}
                onChange={(event, data) => setShowDueDateColumn(data.checked)}
              />
              <Switch
                label={t('item.completion_date')}
                checked={showCompletionDateColumn === 'responsive' ? !narrowWidth : showCompletionDateColumn}
                onChange={(event, data) => setShowCompletionDateColumn(data.checked)}
              />
              <Switch
                label={t('item.modified')}
                checked={showLastModifiedColumn === 'responsive' ? !narrowWidth : showLastModifiedColumn}
                onChange={(event, data) => setShowLastModifiedColumn(data.checked)}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={reset}>{t('general.reset')}</Button>
            <DialogTrigger disableButtonEnhancement>
              <Button ref={primaryButtonRef} appearance="primary">
                {t('general.ok')}
              </Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
