import React, { forwardRef, memo, useMemo } from 'react'
import { List } from '@fluentui/react/lib/List'
import { useTranslation } from 'react-i18next'
import { cn } from '@appfluence/classnames'
import styled from 'styled-components'
import { Persona } from '@fluentui/react/lib/Persona'
import { PersonaPresence, PersonaSize } from '@fluentui/react/lib/PersonaCoin'
import { getInitial } from '../../../common/src/helpers/functionalUtils'
import { motion } from 'framer-motion'
import { useUserGroups } from '../../../queries/userGroups'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-items: flex-start;
  box-sizing: content-box;
  cursor: pointer;

  &.readOnly {
    cursor: inherit;
  }
`

const useDefaultPersonaProps = () => {
  const { t } = useTranslation()
  return useMemo(() => {
    return {
      size: PersonaSize.size24,
      presence: PersonaPresence.none,
      placeholder: t('users.anybody'),
      styles: {
        root: {
          width: '100%',
        },
        primaryText: {
          color: 'inherited',
        },
      },
    }
  }, [t])
}

/**
 * @typedef {import("../../../types/userGroup").UserGroup} UserGroup
 * @param {UserGroup} userGroup
 * @returns {{imageInitials: (string|*|string), imageUrl: (null|string|*), text}}
 */
const getPersonaPropsForGroup = userGroup => ({
  text: userGroup.name,
  imageUrl: userGroup.group_logo,
  imageInitials: getInitial(userGroup.name),
})

export const UserGroupCell = memo(
  forwardRef(({ userGroup, readOnly = false, className, ...rest }, ref) => {
    const defaultPersonaProps = useDefaultPersonaProps(false)

    const personaProps = getPersonaPropsForGroup(userGroup)

    const containerClassName = cn(className, { readOnly })

    return (
      <Container className={containerClassName} ref={ref} {...rest}>
        <Persona imageShouldFadeIn={false} {...defaultPersonaProps} {...personaProps} />
      </Container>
    )
  })
)

const SUserGroupCell = styled(UserGroupCell)`
  width: 100%;
  padding: 4px 0;
  border-radius: 2px;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: ${p => p.theme.palette.neutralLighter};
    }
  }
`

export const UserGroupsList = React.memo(({ onSelectGroup, ...rest }) => {
  const { data: userGroups } = useUserGroups()
  const orderedUserGroups = useMemo(() => {
    return userGroups?.sort((a, b) => a.name.localeCompare(b.name)) ?? []
  }, [userGroups])
  const onRenderCell = option => {
    return (
      <motion.div whileTap={{ scale: 0.9 }} style={{ display: 'inline-block', width: '100%' }}>
        <SUserGroupCell userGroup={option} onClick={() => onSelectGroup(option)} />
      </motion.div>
    )
  }

  return <List items={orderedUserGroups} onRenderCell={onRenderCell} {...rest} />
})
