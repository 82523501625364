import { memo, useCallback, useRef, useState } from 'react'
import { ProgressPopoverSurface } from '../callout/ProgressPopoverSurface'
import { useSingleAndDoubleClick } from '../../common/src/hooks/useSingleAndDoubleClick'
import { useShortcut } from '../../hooks/useKonamiCheatCode'
import { Popover, PopoverTrigger, useToastController } from '@fluentui/react-components'
import { KEY_CODE } from '../../common/src/helpers/keyboardHelper'
import { CircleInput } from '../input/CircleInput'
import { FluentToast } from '../toast/FluentToast'

const useCompletionKeyPress = toggleFn => {
  const { dispatchToast } = useToastController()
  const callback = useCallback(() => {
    const next = toggleFn()
    const text = next ? 'Item completed' : 'Item completion reset'
    dispatchToast(<FluentToast>{text}</FluentToast>, {
      intent: 'success',
    })
  }, [dispatchToast, toggleFn])
  useShortcut(KEY_CODE.D, ['altKey'], callback)
}

const ItemProgressCircle_ = ({ value = 0, className, onChange, readOnly = false }) => {
  const [showMenu, setShowMenu] = useState(false)
  const hoverTimeout = useRef(null)

  const handleSingleClick = useCallback(() => setShowMenu(prev => !prev), [setShowMenu])
  const handleDoubleClick = useCallback(() => {
    const next = value < 100 ? 100 : 0
    onChange(next)
    return next
  }, [value, onChange])
  const handleClick = useSingleAndDoubleClick(handleSingleClick, handleDoubleClick, { prevent: false })
  useCompletionKeyPress(handleDoubleClick)

  const handleMouseEnter = useCallback(() => {
    hoverTimeout.current = setTimeout(() => setShowMenu(true), 500)
  }, [hoverTimeout, setShowMenu])

  const handleMouseLeave = useCallback(() => {
    clearTimeout(hoverTimeout.current)
  }, [hoverTimeout])

  return (
    <Popover
      open={showMenu}
      onOpenChange={(e, data) => setShowMenu(data.open)}
      withArrow
      positioning={{ position: 'below' }}
    >
      <PopoverTrigger>
        <CircleInput
          value={value}
          text={`${value}%`}
          onClick={handleClick}
          className={className}
          readOnly={readOnly}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          color={value === 100 ? 'var(--color-affirmative)' : undefined}
        />
      </PopoverTrigger>
      <ProgressPopoverSurface value={value} onChange={onChange} />
    </Popover>
  )
}

export const ItemProgressCircle = memo(ItemProgressCircle_)
