import React, { useCallback } from 'react'
import { itemHelper } from '../../common/src/helpers'
import { ItemCell } from '../itemCell/ItemCell'
import { GhostItemCell } from '../itemCell/GhostItemCell'
import { ShimmerVirtualizedList } from './ShimmerVirtualizedList'

export const ItemVirtualizedList = ({
  items,
  selectedItem,
  onSelectItem,
  readOnly = false,
  section,
  shouldShowProject = false,
  noRowsRenderer,
}) => {
  const selectedItemID = itemHelper.getId(selectedItem)
  const rowRenderer = useCallback(
    index => {
      const item = items[index]
      const serverID = itemHelper.getId(item)
      const selected = serverID === selectedItemID
      return (
        <ItemCell
          allItemsInList={items}
          item={item}
          idx={index}
          onClick={onSelectItem}
          selected={selected}
          readOnly={readOnly}
          section={section}
          shouldShowProject={shouldShowProject}
        />
      )
    },
    [items, selectedItemID, onSelectItem, readOnly, section, shouldShowProject]
  )

  return (
    <ShimmerVirtualizedList
      rows={items}
      rowRenderer={rowRenderer}
      shimmerComponent={GhostItemCell}
      computeItemKey={(_, item) => itemHelper.getId(item)}
      noRowsRenderer={noRowsRenderer}
    />
  )
}
