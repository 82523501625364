import React from 'react'
import styled from 'styled-components'
import { Label } from '../../components/text/Text'
import { UserGroupInfoIconCallout } from '../../components/info/UserGroupInfoIconCallout'
import { cn } from '@appfluence/classnames'

const _style = {
  label: { fontSize: '10px' },
}

const SInfoIconCallout = styled(UserGroupInfoIconCallout)`
  margin-left: 5px;
  transform: scale(0.85);
`

export const ProjectSection = ({
  name,
  children = false,
  contentStyle = {},
  className,
  isGroupUsersSection = false,
}) => {
  return (
    <div className={cn('flex flex-col', className)}>
      <div className="flex">
        <Label style={_style.label}>{name}</Label>
        {isGroupUsersSection && <SInfoIconCallout />}
      </div>
      <div className="flex flex-col" style={contentStyle}>
        {children}
      </div>
    </div>
  )
}
