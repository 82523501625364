import React from 'react'
import _ from 'lodash'

export const KEYS = {
  DEPENDENCY: 'dependency',
  ID: 'id',
  LINK: 'link',
  LINKED_ITEM: 'linked_item',
  LINKED_ITEM_DETAILS: 'linked_item_details',
  NAME: 'name',
  SIZE: 'size',
  TIMESTAMP: 'timestamp',
}

export const DEPENDENCY = {
  RELATED: 0,
  BLOCKED_BY: 1,
  BLOCKS: 2,
  CAUSED_BY: 3,
  CAUSES: 4,
}

export const DEPENDENCY_KINDS = {
  [DEPENDENCY.RELATED]: 'Related',
  [DEPENDENCY.BLOCKED_BY]: 'Blocked by',
  [DEPENDENCY.BLOCKS]: 'Blocks',
  [DEPENDENCY.CAUSED_BY]: 'Caused by',
  [DEPENDENCY.CAUSES]: 'Causes',
}

export const getDependencyKind = dependency => {
  return DEPENDENCY_KINDS[dependency]
}

export const getID = l => l.get(KEYS.ID)

export const useDependencyOptions = () =>
  React.useMemo(
    () =>
      _.map(DEPENDENCY_KINDS, (value, prop) => {
        return {
          key: parseInt(prop),
          text: value,
        }
      }),
    []
  )
