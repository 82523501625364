import React, { memo, useState, useRef, useCallback } from 'react'
import { cn } from '@appfluence/classnames'
import { TopRightBar } from '../../components/layout/TopRightBar'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { useTranslation } from 'react-i18next'
import { FilterButton } from '../../components/buttons/FilterButton'
import { useSelector, useDispatch } from 'react-redux'
import { getItemFiltersCount } from '../../common/src/selectors/filtersSelectors'
import { FILTER_REDUCER_KEYS } from '../../common/src/reducers/filtersKeys'
import { InboxFiltersPanel } from '../filters/InboxFiltersPanel'
import { SecretInboxButton } from '../../components/inbox/secretInboxButton'
import { useCreateItemModal } from '@/hooks/useCreateItemModal'
import { Info } from '../../components/BundledIcons'
import { Button } from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { SearchBoxWithShortcut } from '../../components/SearchBoxWithShortcut'
import { getItemsTextFilter } from '../../common/src/selectors/filtersSelectors'
import { setDefaultFilterItems, setItemsTextFilter } from '../../common/src/actions/filtersActions'
import { Default } from '../../helpers/responsiveHelpers'
import { useSearchKeyPress } from '../../hooks/useSearchKeyPress'
import { AddItemButtonWithTooltip } from '../../components/buttons/AddItemButtonWithTooltip'
import { ShortcutTooltipContent } from '../../tooltip/ShortcutTooltipHost'
import { SEARCH_KEYS } from '../../constants/shortcutKeys'
import { useTextSearch } from '../../hooks/useTextSearch'
import { TopBarMultiSelectButton } from '../../components/buttons/TopBarMultiSelectButton'
import { PrintButton } from '../../components/buttons/PrintButton'
import { Search16Regular } from '@fluentui/react-icons'

export const InboxTopBar = memo(({ items }) => {
  const dispatch = useDispatch()
  const filtersType = FILTER_REDUCER_KEYS.INBOX
  const filtersCount = useSelector(state => getItemFiltersCount(state, filtersType))
  const clearFilters = () => dispatch(setDefaultFilterItems(filtersType))
  const textFilter = useSelector(state => getItemsTextFilter(state, filtersType))
  const [showSearchBox, setShowSearchBox] = useState(textFilter && textFilter.length > 0)
  const searchBoxRef = useRef(null)
  const setTextFilter = useCallback(text => dispatch(setItemsTextFilter(text, filtersType)), [dispatch, filtersType])
  const { t } = useTranslation()
  const [isOpenFP, setIsOpenFP] = useState(false)
  const createItemModal = useCreateItemModal()
  const { textSearch, onSearchBoxChange, onSearch } = useTextSearch({ setTextFilter, globalTextSearch: textFilter })

  const onClickSearch = useCallback(() => {
    setShowSearchBox(true)

    setTimeout(() => {
      searchBoxRef.current.focus()
    }, 100)
  }, [])

  useSearchKeyPress(onClickSearch)

  const onClear = () => {
    setShowSearchBox(false)
  }

  const onNewItem = () => {
    createItemModal({
      isInbox: true,
      quadrant: 0,
      mode: EVENT_EXTRA.CREATE_ITEM.MODE.INBOX,
    })
  }

  const onClickFilterButton = () => setIsOpenFP(true)
  const onDismissFP = () => setIsOpenFP(false)
  const leftComponents = (
    <>
      <Tooltip content={t('inbox.info_button_tooltip')} relationship="label">
        <Button
          appearance="subtle"
          as="a"
          icon={<Info />}
          href="https://appfluence.com/productivity/item-inbox/"
          target="_blank"
          className="!mx-1"
        />
      </Tooltip>
    </>
  )

  const searchButtonTooltip = t('project_level_top_bar.search_button_tooltip')

  const searchButton = (
    <>
      {!showSearchBox && (
        <Tooltip
          content={<ShortcutTooltipContent label={searchButtonTooltip} tooltipKeys={SEARCH_KEYS} />}
          relationship="label"
        >
          <Button
            icon={<Search16Regular />}
            id="inboxTopBar_searchButton"
            aria-label={searchButtonTooltip}
            onClick={onClickSearch}
          />
        </Tooltip>
      )}
      <SearchBoxWithShortcut
        id="inboxTopBar_searchBox"
        className={cn('hidden max-w-72', showSearchBox && 'flex')}
        ref={searchBoxRef}
        value={textSearch}
        placeholder={t('common_localized_strings.search_items')}
        onClear={onClear}
        onChange={onSearchBoxChange}
        onSearch={onSearch}
        onEscape={onClear}
      />
    </>
  )

  const filterButtonTooltip = t('project_level_top_bar.filter_button_tooltip')

  const rightComponents = (
    <>
      <SecretInboxButton />
      <InboxFiltersPanel isOpen={isOpenFP} onDismiss={onDismissFP} />
      <Default>{searchButton}</Default>
      <TopBarMultiSelectButton allItems={items} />
      <AddItemButtonWithTooltip
        height={30}
        id="inboxTopBar_addItemButton"
        text={t('item.create_item')}
        onClick={onNewItem}
      />
      <FilterButton
        tooltip={filterButtonTooltip}
        filtersCount={filtersCount}
        onClear={filtersCount && clearFilters}
        onClick={onClickFilterButton}
      />
      <PrintButton items={items} className="!hidden md:!flex" />
    </>
  )
  return (
    <TopRightBar
      id={'inboxTopRightBar'}
      key={'inboxTopRightBar'}
      title={'Inbox'}
      leftSubcomponents={leftComponents}
      rightSubcomponents={rightComponents}
    />
  )
})
