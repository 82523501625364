const getNavigatorLanguage = () =>
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en'

// From https://stackoverflow.com/a/60437579
export const localeUses24HourTime = (langCode = getNavigatorLanguage()) => {
  //forcing 12 hour time for Australia
  //https://sync.appfluence.com/native/api/v1/item/494157504/
  if (langCode === 'en-AU') {
    return true
  }
  try {
    return (
      new Intl.DateTimeFormat(langCode, {
        hour: 'numeric',
      })
        .formatToParts(new Date(2020, 0, 1, 13))
        .find(part => part.type === 'hour').value.length === 2
    )
  } catch (err) {
    return false
  }
}
