import React, { forwardRef } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { cn } from '@appfluence/classnames'
import { useMobile } from '../../helpers/responsiveHelpers'
import { FlexColumn } from '../layout/FlexContainer'

const defaultSpriteSide = 32

const Box = styled(FlexColumn)`
  text-align: center;
  align-items: center;
  justify-content: center;
  ${p => `width: ${p.side}px`};
  ${p => `height: ${p.side}px`};
`

const IconDiv = styled.div`
  min-height: ${defaultSpriteSide}px;
  min-width: ${defaultSpriteSide}px;
  ${p => p.side && `transform: scale(${p.side / defaultSpriteSide});`}
  &.shadeOnHover {
    border-radius: 2px;
    transition: background-color 0.2s;
    @media (hover: hover) and (pointer: fine) {
      :hover {
        background-color: rgba(26, 26, 26, 0.15);
      }
    }
    :active {
      background-color: rgba(26, 26, 26, 0.25);
    }
  }
  @media print {
    print-color-adjust: exact;
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
`

export const IconCell = React.memo(
  forwardRef(({ icon, side = defaultSpriteSide, onClick, className, iconProps, ...rest }, ref) => {
    const isMobilePlatform = useMobile()
    const _onClick = event => {
      if (_.isFunction(onClick)) {
        onClick(icon)
        if (!isMobilePlatform) {
          event.stopPropagation()
        }
      }
    }

    const iconClass = cn('sprite', icon, { shadeOnHover: onClick })
    return (
      <Box ref={ref} title={icon.replace('128', '')} side={side} className={className} onClick={_onClick} {...rest}>
        <IconDiv side={side} className={iconClass} {...iconProps} />
      </Box>
    )
  })
)
