import React, { memo, useCallback, useEffect, useReducer, useRef } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { cn } from '@appfluence/classnames'
import { Icon } from '@fluentui/react/lib/Icon'
import { Label } from '../../text/Text'
import { TextField } from '@fluentui/react/lib/TextField'
import { KEY_CODE } from '../../../common/src/helpers/keyboardHelper'
import { validateEmail } from '../../../common/src/helpers/stringHelper'
import { userFrom } from '../../../common/src/helpers/transformerHelpers'
import { UsersList } from './UsersList'
import { userHelper } from '../../../common/src/helpers'
import { withCyAttr } from '../../../helpers/styleHelper'
import { CYPRESS_ID } from '../../../constants/cypress'
import { Trans, useTranslation } from 'react-i18next'
import { useCollaboratorsMap } from '../../../common/src/hooks/usersHooks'
import { getCollaborators } from '../../../common/src/actions/usersAPI'
import { useDispatch } from 'react-redux'
import { UserGroupsList } from '../userGroupsList/UserGroupsList'
import { Accordion } from '../../accordion/Accordion'
import { Link } from '@fluentui/react/lib/Link'
import { SERVER_URLS } from '../../../common/src/constants'
import { hasTouchScreen } from '@/helpers/deviceDetectionHelper'

const useUpdateCollaborators = () => {
  const collaborators = useCollaboratorsMap()
  const dispatch = useDispatch()
  const FETCH_COLLABORATORS_DELAY = 5000
  return useCallback(
    email => {
      const isKnown = collaborators.some(c => userHelper.getEmail(c) === email)
      if (!isKnown) {
        setTimeout(() => {
          dispatch(getCollaborators())
        }, FETCH_COLLABORATORS_DELAY)
      }
    },
    [collaborators, dispatch]
  )
}

const SearchContainer = styled.div.attrs(withCyAttr())`
  display: flex;
  align-items: center;
  margin-bottom: -4px;
`

const STextField = styled(TextField)`
  flex: 1;
`

const ValidIcon = styled(Icon)`
  font-size: 24px;
  margin-left: 8px;
  color: ${p => p.theme.palette.neutralQuaternaryAlt};
  user-select: none;

  &.isValid {
    color: ${p => p.theme.palette.themePrimary};
    cursor: pointer;
  }
`

const filterOptionsWithText = (options, text) => {
  if (!text) {
    return options
  }
  return options.filter(({ user }) => {
    if (!user) {
      return true
    }
    return userHelper.contains(user, text)
  })
}

const optionsContainsUsers = options => {
  return _.some(options, ({ user }) => !!user)
}

const initialState = {
  text: '',
  isValid: false,
  isUnknown: false,
  errorMessage: '',
  filteredOptions: [],
}

const ACTION_TYPE = {
  SET_TEXT: 'SET_EMAIL',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  UPDATE_FILTERED_OPTIONS: 'UPDATE_FILTERED_OPTIONS',
}

const setText = text => ({ type: ACTION_TYPE.SET_TEXT, payload: text })
const setErrorMessage = message => ({ type: ACTION_TYPE.SET_ERROR_MESSAGE, payload: message })
const updateFilteredOptions = options => ({ type: ACTION_TYPE.UPDATE_FILTERED_OPTIONS, payload: options })

const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case ACTION_TYPE.SET_TEXT:
      return { ...state, text: payload, isValid: validateEmail(payload), errorMessage: '' }
    case ACTION_TYPE.SET_ERROR_MESSAGE: {
      return { ...state, errorMessage: payload }
    }
    case ACTION_TYPE.UPDATE_FILTERED_OPTIONS: {
      const { text, isValid } = state
      const filteredOptions = filterOptionsWithText(payload, text)
      const isUnknown = isValid && !optionsContainsUsers(filteredOptions)
      return { ...state, isUnknown, filteredOptions }
    }
  }
}

const invitationIconPropsByDefault = {
  iconName: 'UserFollowed',
}

export const UsersViewWithSearch = memo(
  ({
    textFieldHeaderText,
    textFieldPlaceholder,
    invitationIconProps,
    selectedUsers = [],
    options,
    onSelectUser,
    onSelectGroup,
    project,
  }) => {
    const { t } = useTranslation()
    const safePlaceholder = textFieldPlaceholder ?? t('validate.email_placeholder')
    const [state, localDispatch] = useReducer(reducer, initialState)
    const { text, isValid, isUnknown, errorMessage, filteredOptions } = state

    const filterOptions = useCallback(
      options => {
        localDispatch(updateFilteredOptions(options))
      },
      [localDispatch]
    )
    const debouncedFilterOptionsRef = useRef(_.debounce(filterOptions, 250))

    const invitationIconPropsForUnknownUser = {
      iconName: 'AddFriend',
      title: t('users.no_pm_user_tooltip'),
    }

    // Unmount
    useEffect(() => {
      const currentDebouncedFilterOptions = debouncedFilterOptionsRef.current
      return () => {
        currentDebouncedFilterOptions.cancel()
      }
    }, [])

    useEffect(() => {
      localDispatch(updateFilteredOptions(options))
    }, [localDispatch, options])

    const updateCollaborators = useUpdateCollaborators()

    const onSelected = useCallback(() => {
      if (validateEmail(text)) {
        const isSelected = _.some(selectedUsers, u => userHelper.getEmail(u) === text)
        if (isSelected) {
          localDispatch(setErrorMessage(t('users.already_selected')))
        } else {
          const newUser = userFrom(text)
          localDispatch(setText(''))
          onSelectUser(newUser, isUnknown)
          updateCollaborators(text)
        }
      } else {
        localDispatch(setErrorMessage(t('validate.email_not_valid')))
      }
    }, [text, selectedUsers, t, onSelectUser, isUnknown, updateCollaborators])

    const onChangeTextFieldValue = useCallback(
      (event, value) => {
        localDispatch(setText(value))
        debouncedFilterOptionsRef.current(options)
      },
      [localDispatch, options, debouncedFilterOptionsRef]
    )

    const onKeyPressTextField = useCallback(
      event => {
        if (event.charCode === KEY_CODE.ENTER) {
          onSelected()
        }
      },
      [onSelected]
    )

    const calloutMessage = (
      <>
        <Trans
          i18nKey="users.user_groups_explanation"
          components={{
            mylink: <Link href={SERVER_URLS.USER_GROUPS} target="_blank" underline />,
          }}
        />
        <br />
        {t('item_detail.user_groups_follow')}
      </>
    )

    const iconClassname = cn({ isValid })
    const finalInvitationIconProps = { ...invitationIconPropsByDefault, ...invitationIconProps }
    const finalIconProps = isUnknown ? invitationIconPropsForUnknownUser : finalInvitationIconProps
    return (
      <>
        {textFieldHeaderText && <Label>{textFieldHeaderText}</Label>}
        <SearchContainer cy={CYPRESS_ID.USER_INPUT_CONTAINER}>
          <STextField
            placeholder={safePlaceholder}
            value={text}
            onChange={onChangeTextFieldValue}
            onKeyPress={onKeyPressTextField}
            errorMessage={errorMessage}
            validateOnLoad={false}
            autoFocus={!hasTouchScreen()}
          />
          <ValidIcon className={iconClassname} onClick={onSelected} {...finalIconProps} />
        </SearchContainer>
        {onSelectGroup && (
          <Accordion title={t('general.user_groups')} calloutMessage={calloutMessage}>
            <UserGroupsList onSelectGroup={onSelectGroup} />
          </Accordion>
        )}
        <UsersList usersOptions={filteredOptions} project={project} onSelectUser={onSelectUser} />
      </>
    )
  }
)
