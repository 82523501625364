export const OneOnOneYoutubeVideo = () => {
  return (
    <iframe
      className="aspect-video w-11/12 max-w-md border-none"
      src="https://www.youtube.com/embed/UpU4HyofBN4"
      title='How to do Great "One on One" (1:1) Conversations Inside Microsoft Teams'
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  )
}
