import { useCallback, useContext, useState } from 'react'
import { cn } from '@appfluence/classnames'
import { useDispatch } from 'react-redux'
import { MobileBackButton, MobileNavBarButtonContainer as ButtonContainer } from './MobileNavBarButton'
import { ICON_NAMES } from '../../common/src/msIcons'
import { NavPanel } from './NavPanel'
import { BubbleButton } from '../buttons/BubbleButton'
import { useAttentionNeeded } from '../../hooks/useAttentionNeeded'
import { useExtendedHistory } from '../../hooks/useExtendedHistory'
import { useRouteId } from '../../hooks/useRouteId'
import { ROUTE_ID } from '../../routes/routeIdList'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { useSharingRequestsForMe } from '../../common/src/hooks/sharingRequestsHooks'
import { CUSTOM_ICONS } from '../../icons/customIcons'
import { Bar, Center, Title } from './commonMobileNavBar'
import { InvitationsModalContext } from '../../contexts'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { openPurchaseExperienceAction } from '../../utils/teams'
import { useCenterTitle } from '../managers/ParamsManager'
import { useShouldDisplayUpgrade } from '@/hooks/userHooks'

export const MobileNavBarComponent = () => {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const invitationsModalContext = useContext(InvitationsModalContext)
  const invitationsCount = useSharingRequestsForMe().size
  const shouldDisplayUpgrade = useShouldDisplayUpgrade()
  const titleData = useCenterTitle()
  const routeId = useRouteId()
  const { count: alertCount } = useAttentionNeeded()
  const { history } = useExtendedHistory()
  const onClickMenu = useCallback(() => setIsOpen(true), [])
  const onDismiss = useCallback(() => setIsOpen(false), [])
  const center = titleData ? <Title {...titleData} /> : undefined

  const onClickHome = useCallback(() => {
    const path = getRelativeURLKeepingQuerySearch.homeSection()
    history.push(path)
  }, [history])
  const homeCN = cn({ pm_hidden: routeId === ROUTE_ID.HOME })
  const invitationCN = cn({ pm_hidden: invitationsCount === 0 })
  const upgradeCN = cn({ pm_hidden: !shouldDisplayUpgrade })

  const onClickSearch = useCallback(() => {
    const path = getRelativeURLKeepingQuerySearch.searchApp()
    history.push(path)
  }, [history])
  const searchCN = cn({ pm_hidden: routeId !== ROUTE_ID.HOME })

  const onClickProjects = useCallback(() => {
    const path = getRelativeURLKeepingQuerySearch.projects()
    history.push(path)
  }, [history])

  const onClickUpgrade = () =>
    dispatch(openPurchaseExperienceAction(EVENT_EXTRA.TRIAL_UPGRADE_PRESSED.MODE.MOBILE_NAV_BAR))
  const onClickInvitations = () => invitationsModalContext.setIsOpen(true)

  return (
    <Bar>
      <MobileBackButton />
      <Center>{center}</Center>
      <ButtonContainer onClick={onClickProjects} className={searchCN}>
        <BubbleButton iconName={CUSTOM_ICONS.QUADRANTS} n={0} size={16} />
      </ButtonContainer>
      <ButtonContainer onClick={onClickSearch} className={searchCN}>
        <BubbleButton iconName={ICON_NAMES.Search} n={0} size={16} />
      </ButtonContainer>
      <ButtonContainer onClick={onClickHome} className={homeCN}>
        <BubbleButton iconName={ICON_NAMES.Home} n={alertCount} size={16} />
      </ButtonContainer>
      <ButtonContainer onClick={onClickInvitations} className={invitationCN}>
        <BubbleButton iconName={ICON_NAMES.MailForwardMirrored} n={invitationsCount} size={16} />
      </ButtonContainer>
      <ButtonContainer onClick={onClickUpgrade} className={upgradeCN}>
        <BubbleButton iconName={ICON_NAMES.Ribbon} n={' '} size={16} />
      </ButtonContainer>
      <ButtonContainer onClick={onClickMenu}>
        <BubbleButton iconName={ICON_NAMES.CollapseMenu} size={16} />
      </ButtonContainer>
      <NavPanel isOpen={isOpen} onDismiss={onDismiss} />
    </Bar>
  )
}
