import _ from 'lodash'
import { fromJS } from 'immutable'
import { NOTIFICATION_REDUCER_KEYS as KEYS } from './notificationReducerKeys'
import { notificationHelper } from '../helpers'
import {
  API_EVENT,
  isPMApiDetailedAction,
  parseApiActionType,
  PERSIST_REHYDRATE_ACTION_TYPE,
} from '../helpers/reducerHelper'
import { GENERIC_ACTION_TYPE } from '../actions/genericActions'
import { ACTIONS as WS_ACTIONS } from '../websocket/wsActions'
import { PM_API_RESOURCES } from '../constants'

const base = fromJS({
  [KEYS.BY_ID]: {},
})

export const notifications = (state = base, action) => {
  const { type, payload } = action
  const apiAction = parseApiActionType(type)
  if (isPMApiDetailedAction(apiAction)) {
    const { resource, event } = apiAction
    if (event === API_EVENT.SUCCESS) {
      const handler = successHandlers[resource]
      if (handler) {
        state = handler(state, action)
      }
    } else if (event === API_EVENT.START) {
      const handler = startHandlers[resource]
      if (handler) {
        state = handler(state, action)
      }
    }
  } else {
    switch (type) {
      case GENERIC_ACTION_TYPE.CLEAR_ALL:
        {
          state = base
        }
        break
      case PERSIST_REHYDRATE_ACTION_TYPE:
        {
          if (payload.notifications) {
            state = state.merge(payload.notifications)
          }
        }
        break
      case WS_ACTIONS.NOTIFICATION_RECEIVED:
        {
          state = storeSingleNotification(state, payload)
        }
        break
    }
  }
  return state
}

const startHandlers = {
  [PM_API_RESOURCES.NOTIFICATION]: (state, { meta }) => {
    const { notificationId: id, body } = meta
    const current = state.getIn([KEYS.BY_ID, id])
    if (current) {
      return state.mergeIn([KEYS.BY_ID, id], body)
    }
    return state
  },
}

const successHandlers = {
  [PM_API_RESOURCES.NOTIFICATIONS]: (state, { payload }) => {
    const { objects: notifications } = payload
    return state.withMutations(_st => {
      const storeSingle = notification => storeSingleNotification(_st, notification)
      _.each(notifications, storeSingle)
      return _st
    })
  },
  [PM_API_RESOURCES.NOTIFICATION]: (state, { payload: notification }) => storeSingleNotification(state, notification),
}

const storeSingleNotification = (state, notification) => {
  const id = _.get(notification, notificationHelper.KEYS.ID)
  if (id) {
    return state.setIn([KEYS.BY_ID, id], fromJS(notification))
  }
  return state
}
