import React, { useCallback, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'
import { ChoiceGroup, Dropdown, SearchBox } from '@fluentui/react'
import { Label } from '../../components/text/Text'
import {
  PROJECTS_SHOW_ARCHIVED_OPTIONS,
  PROJECTS_SORT_TYPE,
  setDefaultFilterProjects,
  setProjectsShowArchivedFilter,
  setProjectsSortType,
  setProjectsTagsFilter,
  setProjectsTagsModeFilter,
  setProjectsTextFilter,
  setProjectsUserGroupFilter,
  TAGS_MODE_KEYS,
} from '../../common/src/actions/filtersActions'
import { FiltersView } from './FiltersView'
import {
  getProjectsShowArchivedFilter,
  getProjectsSortType,
  getProjectsTagsFilter,
  getProjectsTagsModeFilter,
  getProjectsTextFilter,
  getProjectsUserGroupFilter,
  isFilteringProjects,
} from '../../common/src/selectors/filtersSelectors'
import { UserGroupsDropdown } from '../../components/dropdown/UserGroupsDropdown'
import { useTranslation } from 'react-i18next'
import { SERVER_URLS } from '../../common/src/constants'
import { Link } from '@fluentui/react-components'
import { SuggestionsTagPicker } from '@/components/pickers/SuggestionsTagPicker'

const SSearchBox = styled(SearchBox)`
  margin-top: 5px;
`

const StyledDropdown = styled(Dropdown)``

const StyledTagsContainer = styled.div``

export const ProjectsFiltersView = ({ mode }) => {
  const sortType = useSelector(state => getProjectsSortType(state, mode))
  const textFilter = useSelector(state => getProjectsTextFilter(state, mode))
  const userGroupFilter = useSelector(state => getProjectsUserGroupFilter(state, mode))
  const tagsFilter = useSelector(state => getProjectsTagsFilter(state, mode))
  const tagsMode = useSelector(state => getProjectsTagsModeFilter(state, mode))
  const showArchived = useSelector(state => getProjectsShowArchivedFilter(state, mode))

  const dispatch = useDispatch()

  const isFiltering = useSelector(state => isFilteringProjects(state, mode))
  const searchBoxRef = useRef(null)
  const selectedTags = tagsFilter.toArray()
  const { t } = useTranslation()

  const sortOptions = useMemo(() => {
    return [
      { key: PROJECTS_SORT_TYPE.INDEX, text: t('project_filters.manually') },
      { key: PROJECTS_SORT_TYPE.NAME, text: t('project_filters.alphabetically') },
      { key: PROJECTS_SORT_TYPE.TIMESTAMP, text: t('project_filters.modification_date') },
    ]
  }, [t])
  const showArchivedOptions = useMemo(() => {
    return [
      { key: PROJECTS_SHOW_ARCHIVED_OPTIONS.ONLY_ACTIVE, text: t('project_filters.archived_mode.only_active') },
      { key: PROJECTS_SHOW_ARCHIVED_OPTIONS.ONLY_ARCHIVED, text: t('project_filters.archived_mode.only_archived') },
      { key: PROJECTS_SHOW_ARCHIVED_OPTIONS.ALL, text: t('project_filters.archived_mode.all') },
    ]
  }, [t])

  const onClear = useCallback(() => {
    dispatch(setDefaultFilterProjects(mode))
  }, [dispatch, mode])

  const onClearText = useCallback(() => {
    dispatch(setProjectsTextFilter('', mode))
  }, [dispatch, mode])

  const onChangeText = useCallback(
    (evt, text) => {
      dispatch(setProjectsTextFilter(text, mode))
    },
    [dispatch, mode]
  )

  const onSearch = useCallback(
    text => {
      dispatch(setProjectsTextFilter(text, mode))
    },
    [dispatch, mode]
  )

  const onChangeUserGroup = useCallback(
    (event, option) => {
      const { groupID } = option
      dispatch(setProjectsUserGroupFilter(groupID, mode))
    },
    [dispatch, mode]
  )

  const onChangeSortType = useCallback(
    (event, item) => {
      dispatch(setProjectsSortType(item.key, mode))
    },
    [dispatch, mode]
  )

  const onChangeShowArchived = useCallback(
    (event, item) => {
      dispatch(setProjectsShowArchivedFilter(item.key, mode))
    },
    [dispatch, mode]
  )

  const onInsertTagFilter = useCallback(
    tagName => {
      const currentTagNames = tagsFilter.toArray()
      const newTagNames = _.concat(currentTagNames, tagName)
      dispatch(setProjectsTagsFilter(newTagNames, mode))
    },
    [tagsFilter, dispatch, mode]
  )

  const onRemoveTagFilter = useCallback(
    removedTagName => {
      const currentTagNames = tagsFilter.toArray()
      _.remove(currentTagNames, tagName => tagName === removedTagName)
      dispatch(setProjectsTagsFilter(currentTagNames, mode))
    },
    [tagsFilter, dispatch, mode]
  )

  return (
    <FiltersView isFiltering={isFiltering} clearTitle={t('project_filters.clear_title')} onClear={onClear}>
      <SSearchBox
        id="projectFiltersView_searchBox"
        componentRef={searchBoxRef}
        value={textFilter}
        placeholder={t('project_filters.search_bar_placeholder')}
        onClear={onClearText}
        onChange={onChangeText}
        onSearch={onSearch}
      />
      <StyledDropdown
        id="projectFiltersView_dropdownSorting"
        label={t('project_filters.sort_type_label')}
        selectedKey={sortType}
        options={sortOptions}
        onChange={onChangeSortType}
      />
      <UserGroupsDropdown
        id="projectFiltersView_userGroupsDropdown"
        label={t('project_filters.user_group_label')}
        selectedID={userGroupFilter}
        onChange={onChangeUserGroup}
      />
      <StyledTagsContainer>
        <Label>{t('project_filters.tags_label')}</Label>
        <ChoiceGroup
          options={Object.values(TAGS_MODE_KEYS).map(key => ({
            key,
            text: t(`project_filters.tags_mode.${key}`),
            styles: { root: { margin: 0 } },
          }))}
          selectedKey={tagsMode}
          onChange={(e, option) => dispatch(setProjectsTagsModeFilter(option.key, mode))}
          styles={{ flexContainer: 'flex gap-4', root: 'mb-2' }}
        />
        <SuggestionsTagPicker
          selectedTags={selectedTags}
          onAddTag={onInsertTagFilter}
          onRemoveTag={onRemoveTagFilter}
          type="project"
        />
      </StyledTagsContainer>
      <StyledDropdown
        id="projectFiltersView_archivedDropdown"
        label={t('project_filters.show_archived_label')}
        selectedKey={showArchived}
        options={showArchivedOptions}
        onChange={onChangeShowArchived}
      />
      <Link href={SERVER_URLS.RESTORE_PROJECTS} target="_blank" className="!mt-4">
        {t('project_filters.manage_deleted')}
      </Link>
    </FiltersView>
  )
}
