import { itemHelper } from '@/common/src/helpers/index.js'

export const customEffortFormatter = estimatedEffort => {
  if (estimatedEffort > 90) {
    return {
      unit: 'hour',
      value: estimatedEffort / 60, //.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 0 }),
    }
  }
  return {
    unit: 'min',
    value: estimatedEffort,
  }
}

export const EFFORT_TRANSLATION_KEYS = {
  [itemHelper.EFFORT_KEYS.NO_EFFORT]: 'item.effort.long.no_effort',
  [itemHelper.EFFORT_KEYS.ONE_HOUR]: 'item.effort.long.one_hour',
  [itemHelper.EFFORT_KEYS.TWO_HOURS]: 'item.effort.long.two_hours',
  [itemHelper.EFFORT_KEYS.HALF_A_DAY]: 'item.effort.long.half_day',
  [itemHelper.EFFORT_KEYS.ONE_DAY]: 'item.effort.long.one_day',
  [itemHelper.EFFORT_KEYS.HALF_A_WEEK]: 'item.effort.long.half_week',
  [itemHelper.EFFORT_KEYS.ONE_WEEK]: 'item.effort.long.one_week',
  [itemHelper.EFFORT_KEYS.TWO_WEEKS]: 'item.effort.long.two_weeks',
  [itemHelper.EFFORT_KEYS.ONE_MONTH]: 'item.effort.long.one_month',
  [itemHelper.EFFORT_KEYS.SEVERAL_MONTHS]: 'item.effort.long.several_months',
}

export const EFFORT_SHORT_TRANSLATION_KEYS = {
  [itemHelper.EFFORT_KEYS.NO_EFFORT]: 'item.effort.short.no_effort',
  [itemHelper.EFFORT_KEYS.ONE_HOUR]: 'item.effort.short.one_hour',
  [itemHelper.EFFORT_KEYS.TWO_HOURS]: 'item.effort.short.two_hours',
  [itemHelper.EFFORT_KEYS.HALF_A_DAY]: 'item.effort.short.half_day',
  [itemHelper.EFFORT_KEYS.ONE_DAY]: 'item.effort.short.one_day',
  [itemHelper.EFFORT_KEYS.HALF_A_WEEK]: 'item.effort.short.half_week',
  [itemHelper.EFFORT_KEYS.ONE_WEEK]: 'item.effort.short.one_week',
  [itemHelper.EFFORT_KEYS.TWO_WEEKS]: 'item.effort.short.two_weeks',
  [itemHelper.EFFORT_KEYS.ONE_MONTH]: 'item.effort.short.one_month',
  [itemHelper.EFFORT_KEYS.SEVERAL_MONTHS]: 'item.effort.short.several_months',
}

const flip = data => Object.fromEntries(Object.entries(data).map(([key, value]) => [value, key]))

export const EFFORT_REVERSE_MAP = flip(itemHelper.DEFAULT_ESTIMATED_EFFORT)
