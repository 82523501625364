export const LOCATION_KEYS = {
  PATH: 'path',
  TIMESTAMP: 'timestamp',
}

export const LOCATION_ACTION_TYPES = {
  SET_LOCATION: 'LOCATION/SET',
}

export const REDUCER_NAME = 'location'
