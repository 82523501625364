import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItemLink,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import {
  Chat,
  Link,
  LinkPerson,
  People,
  PeopleLock,
  Print,
  Share,
  ShareAndroid,
  ShareIos,
} from '@/components/BundledIcons'
import { useTranslation } from 'react-i18next'
import { useCopyLink, useCopyPublicLink, useMakeItemPrivate, useMakeItemPublic } from '@/hooks/itemHooks'
import { queryParamsHelper } from '@/helpers'
import { shareItem } from '@/utils/ExpoCommunication'
import { useConfig } from '@/queries/config'
import { itemHelper } from '@/common/src/helpers'
import { useState } from 'react'
import { PrintItemDialog } from '@/views/ItemDetail/PrintItemDialog'
import { getItemChatLink } from '@/common/src/constants.js'
import { openExternalLink } from '@/utils/externalLinkHandler.js'

function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }

  return 'unknown'
}

export const ItemDetailShareButton = ({ item }) => {
  const { t } = useTranslation()
  const [isPrintItemOpen, setIsPrintItemOpen] = useState(false)
  const config = useConfig().data
  const hipaa = config?.hipaa
  const mobileOperatingSystem = getMobileOperatingSystem()
  const embeddedOnMobile = queryParamsHelper.isEmbeddedOnMobile()
  const icon =
    mobileOperatingSystem === 'Android' ? <ShareAndroid /> : mobileOperatingSystem === 'iOS' ? <ShareIos /> : <Share />
  const publicLink = itemHelper.getPublicLink(item)
  const itemId = itemHelper.getId(item)
  const itemChatLink = getItemChatLink(itemId)

  const copyLink = useCopyLink(item)
  const copyPublicLink = useCopyPublicLink(publicLink)
  const makeItemPrivate = useMakeItemPrivate(itemId)
  const makeItemPublic = useMakeItemPublic(itemId)

  const handlePrintChat = event => {
    event.preventDefault()
    openExternalLink(itemChatLink)
  }

  return (
    <>
      <Menu>
        <Tooltip content={t('item_detail.share')}>
          <MenuTrigger>
            <MenuButton onDoubleClick={copyLink} appearance="subtle" icon={icon}></MenuButton>
          </MenuTrigger>
        </Tooltip>
        <MenuPopover>
          <MenuList>
            <MenuItem icon={<Link />} onClick={copyLink}>
              {t('item_detail.copy_link')}
            </MenuItem>
            {embeddedOnMobile && (
              <MenuItem
                icon={icon}
                onClick={() => {
                  shareItem(item)
                }}
              >
                {t('item_detail.share')}
              </MenuItem>
            )}
            {!hipaa && publicLink && (
              <MenuItem icon={<LinkPerson />} onClick={copyPublicLink}>
                {t('item_detail.copy_public_link')}
              </MenuItem>
            )}
            {!hipaa && publicLink && (
              <MenuItem icon={<PeopleLock />} onClick={makeItemPrivate}>
                {t('item_detail.make_item_private')}
              </MenuItem>
            )}
            {!hipaa && !publicLink && (
              <MenuItem icon={<People />} onClick={makeItemPublic}>
                {t('item_detail.make_item_public')}
              </MenuItem>
            )}
            <MenuItem icon={<Print />} onClick={() => setIsPrintItemOpen(true)}>
              {t('item_detail.print_item')}
            </MenuItem>
            <MenuItemLink icon={<Chat />} href={itemChatLink} onClick={handlePrintChat}>
              {t('item_detail.new_tab_print_chat')}
            </MenuItemLink>
          </MenuList>
        </MenuPopover>
      </Menu>
      <PrintItemDialog item={item} open={isPrintItemOpen} onDismiss={() => setIsPrintItemOpen(false)} />
    </>
  )
}
