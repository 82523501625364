import { RSAA } from 'redux-api-middleware'
import { APIS, PM_API_URLS, PM_TEAMS_HELPER_RESOURCES } from '../constants'
import { generateActionTypesForRequest } from '../helpers/reducerHelper'
import { appendQueryParamsToURL, extendPMHeaders, getCredentialsConfig } from '../helpers/requestHelper'

export const getMembersInConversation = (conversation_id, tenant_id) => {
  const params = { conversation_id, tenant_id }
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(PM_API_URLS.TEAMS_CONVERSATION_MEMBERS, params),
      credentials: getCredentialsConfig(),
      method: 'GET',
      types: generateActionTypesForRequest(APIS.PM_TEAMS_HELPER, PM_TEAMS_HELPER_RESOURCES.MEMBERS_IN_CONVERSATION),
      headers: extendPMHeaders(),
    },
  }
}

export const getMatchedMeeting = (meeting_id, tenant_id) => {
  const params = { meeting_id, tenant_id }
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(PM_API_URLS.TEAMS_MATCHED_MEETING, params),
      credentials: getCredentialsConfig(),
      method: 'GET',
      types: generateActionTypesForRequest(APIS.PM_TEAMS_HELPER, PM_TEAMS_HELPER_RESOURCES.MATCHED_MEETING),
      headers: extendPMHeaders(),
    },
  }
}
