import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar'
import { useDispatch, useSelector } from 'react-redux'
import * as authSelectors from '../../common/src/selectors/authSelectors'
import { MessageBarButton } from '@fluentui/react/lib/Button'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { useTranslation } from 'react-i18next'
import { getShowTrialMessageBar } from '../../selectors/uiSelectors'
import { dismissTrialMessageBar, setShowTrialMessageBar } from '../../actions/uiActions'
import { FlexColumn } from '../layout/FlexContainer'
import { openPurchaseExperienceAction } from '../../utils/teams'
import { uiSelectors } from '../../selectors'
import { differenceInDays } from 'date-fns'
import { useShouldDisplayUpgrade } from '@/hooks/userHooks'

const Text = styled.span`
  color: ${p => p.theme.palette.always_black};
  font-weight: bold;
`

const MBButton = styled(MessageBarButton)`
  width: 130px;
  margin: 4px;
`

const Column = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
`

const useDoNotDisturb = () => {
  const timestamp = useSelector(uiSelectors.getLastDismissTrialMessageBarIsoTimestamp)
  return useMemo(() => {
    const DO_NOT_DISTURB_FOR_SEVEN_DAYS = 7
    const now = new Date()
    const last = new Date(timestamp)
    const diff = differenceInDays(now, last)
    return diff < DO_NOT_DISTURB_FOR_SEVEN_DAYS
  }, [timestamp])
}

const useMessage = () => {
  const { t } = useTranslation()
  const daysLeft = useSelector(authSelectors.daysLeftOnTrial)
  const paying = useSelector(authSelectors.isPaying)
  const SHOW_TRIAL_WITHIN_PERIOD = 7
  const doNotDisturb = useDoNotDisturb()

  if (paying === false) {
    if (daysLeft > SHOW_TRIAL_WITHIN_PERIOD) {
      //skip message bar
      return {
        text: '',
        dismissible: true,
      }
    } else if (daysLeft >= 0) {
      return {
        text: daysLeft > 0 ? t('trial.ongoing', { count: daysLeft }) : t('trial.today'),
        dismissible: true,
      }
    }
    return {
      text: doNotDisturb ? '' : t('trial.expired'),
      dismissible: true,
      expired: true,
    }
  }
  return {
    text: '',
    dismissible: true,
  }
}

const TrialMessageBar_ = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const narrow = useNarrowWidth()
  const shouldDisplayUpgrade = useShouldDisplayUpgrade()
  const display = useSelector(getShowTrialMessageBar)
  const setDisplay = useCallback(value => dispatch(setShowTrialMessageBar(value)), [dispatch])
  const { text, dismissible, expired } = useMessage()
  if (!text || !display) {
    return false
  }
  const onUpgrade = () => {
    dispatch(openPurchaseExperienceAction(EVENT_EXTRA.TRIAL_UPGRADE_PRESSED.MODE.MESSAGE_BAR))
  }
  const actions = []
  if (shouldDisplayUpgrade) {
    actions.push(
      <MBButton key="k" onClick={onUpgrade}>
        {t('general.upgrade')}
      </MBButton>
    )
  }
  if (dismissible) {
    const onDismiss = () => {
      setDisplay(false)
      if (expired) dispatch(dismissTrialMessageBar())
    }
    actions.push(
      <MBButton key="dismiss" onClick={onDismiss}>
        {t('general.maybe_later')}
      </MBButton>
    )
  }
  const grouped = narrow ? [<Column key={0}>{actions}</Column>] : actions
  return (
    <MessageBar messageBarType={MessageBarType.severeWarning} isMultiline={false} actions={grouped}>
      <Text>{text}</Text>
    </MessageBar>
  )
}

export const TrialMessageBar = React.memo(TrialMessageBar_)
