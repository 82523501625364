/**
 * Here we extract info from the redux global state.
 * It's a way to extend stateHelper, but for graph info
 */
import { safeMapFnCreator } from '../immutableHelpers'
import { Set } from 'immutable'
import { GRAPH_RESOURCES_REDUCER_KEYS as KEYS } from '../../reducers/graphResourcesReducerKeys'
import _ from 'lodash'
import { createSelector } from 'reselect'

const helper = {}

helper.getAll = state => state.getIn([KEYS.REDUCER, KEYS.BY_ID])

helper.populateIdSet = createSelector(
  helper.getAll,
  (state, idSet = Set()) => idSet,
  (all, idSet) => {
    return idSet.map(id => all.get(id))
  }
)

helper.getById = (state, id) => {
  const resourcePath = [KEYS.REDUCER, KEYS.BY_ID, id]
  return state.getIn(resourcePath)
}

helper.isGraphEnabled = state => state.getIn([KEYS.REDUCER, KEYS.ENABLED])

helper.getAllByUsername = (state, username) => {
  const idSet = state.getIn([KEYS.REDUCER, KEYS.BY_USER, username])
  return helper.populateIdSet(state, idSet)
}

helper.getAllByText = (state, text) => {
  const idSet = state.getIn([KEYS.REDUCER, KEYS.BY_TEXT, text])
  return helper.populateIdSet(state, idSet)
}

helper.getAllByUsernameAndText = createSelector(
  (state, username) => state.getIn([KEYS.REDUCER, KEYS.BY_USER, username]) || Set(),
  (state, username, text) => state.getIn([KEYS.REDUCER, KEYS.BY_TEXT, text]) || Set(),
  (state, username) => username,
  (state, username, text) => text,
  helper.getAll,
  (byUser, byText, username, text, all) => {
    if (!username) {
      return Set()
    }
    const ids = text ? byUser.intersect(byText) : byUser
    return ids.map(id => all.get(id))
  }
)

// Use let so that webstorm can autocomplete
let exportableHelper = helper
exportableHelper = _.mapValues(helper, f => (_.isFunction(f) ? safeMapFnCreator(f) : f))
export default exportableHelper
