import { useMemo } from 'react'
import { Redirect } from 'react-router'
import { useProjectsMap } from '../../common/src/hooks/projectHooks'
import { projectHelper } from '../../common/src/helpers'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { useSelector } from 'react-redux'
import { resourceRequestSelectors } from '../../common/src/selectors'
import { caseInsensitiveIncludes } from '../../common/src/helpers/stringHelper'

const PROJECTS_LOADING = 'projects_loading'

export const FirstProjectRedirect = () => {
  const projects = useProjectsMap()
  const didFetchProjects = useSelector(resourceRequestSelectors.didFetProjectSummaries)

  const projectToRedirect = useMemo(() => {
    if (!didFetchProjects || !projects) {
      return PROJECTS_LOADING
    }
    const matchesTeamProject = caseInsensitiveIncludes('team project')
    const matchesMyProject = caseInsensitiveIncludes('my project')
    const preferredProjectsArray = [
      projects.find(p => {
        const name = projectHelper.getName(p)
        return matchesTeamProject(name)
      }),
      projects.find(p => {
        const name = projectHelper.getName(p)
        return matchesMyProject(name)
      }),
      projects.first(),
    ]
    return preferredProjectsArray.find(Boolean)
  }, [didFetchProjects, projects])

  if (projectToRedirect === PROJECTS_LOADING) {
    return 'Redirecting...'
  }

  if (projectToRedirect) {
    const projectIdd = projectHelper.getIdd(projectToRedirect)
    const matrixPath = getRelativeURLKeepingQuerySearch.matrixForProjectId(projectIdd)
    return <Redirect to={matrixPath} />
  }

  const homePath = getRelativeURLKeepingQuerySearch.homeSection()
  return <Redirect to={homePath} />
}
