import { useCallback, useEffect, useMemo, useState, useRef, useLayoutEffect } from 'react'
import _ from 'lodash'
import { MainLayout } from '../../components/layout/MainLayout'
import { RightDetailView } from '../rightDetailView/RightDetailView'
import { useRouteId } from '../../hooks/useRouteId'
import { ROUTE_ID } from '../../routes/routeIdList'
import { BASE_URL } from '../../common/src/constants'
import { captureLinkClick } from '../../utils/externalLinkHandler'
import { linkSubject, SOURCES } from '../../reactions/linkSubject'
import { isInternetExplorer } from '../../common/src/helpers/platformHelper'
import { IENotSupportedView } from '../IENotSupportedView'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { ReportsMobilePlaceholder } from './ReportsMobilePlaceholder'
import { useCollaboratorsMap, useMe } from '../../common/src/hooks/usersHooks'
import { ProjectLevelTopBar } from '../project/ProjectLevelTopBar'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { getSentry } from '@/getSentry'

const baseReports = `${BASE_URL}reports/`
const url = `${baseReports}projectanalytics/?embedded=1`

export const PMProjectAnalyticsReport = props => {
  const narrowWidth = useNarrowWidth()
  const routeId = useRouteId()
  const displayOptionsMenu = routeId !== ROUTE_ID.CALENDAR_TEAMS
  const components = {
    main: isInternetExplorer() ? (
      <IENotSupportedView />
    ) : narrowWidth ? (
      <ReportsMobilePlaceholder />
    ) : (
      <Reports {...props} />
    ),
    rightSide: <RightDetailView displayOptionsMenu={displayOptionsMenu} />,
    topBar: (
      <ProjectLevelTopBar
        getNavigationURLForProjectId={getRelativeURLKeepingQuerySearch.reportsForProjectId}
        key={'ProjectMatrixViewTopBar'}
        project={props.project}
        projectSelectorEnabled={props.projectSelectorEnabled}
        items={props.items}
      />
    ),
  }
  return <MainLayout {...components} />
}

const observeDeeperIframe = ({ contentWindow, setContentLoaded }) => {
  try {
    const targetNode = contentWindow.document.getElementsByTagName('body')[0]
    const config = { childList: true, subtree: true }
    const callback = function (mutationsList, observer) {
      _.each(mutationsList, mutation => {
        if (mutation.type === 'childList') {
          _.each(mutation.addedNodes, node => {
            const iframe = node.querySelectorAll?.('iframe')?.[0]
            if (iframe) {
              const nestedWindow = iframe.contentWindow
              nestedWindow.onload = () => {
                addNavigationListener(nestedWindow)
                setContentLoaded(true)
              }
            }
          })
        }
      })
    }
    const observer = new MutationObserver(callback)
    observer.observe(targetNode, config)
  } catch (err) {
    console.log(err)
  }
}

const addNavigationListener = contentWindow => {
  const customHandleExternalLink = href => {
    try {
      return href.startsWith(baseReports)
    } catch (err) {
      return false
    }
  }
  const listener = captureLinkClick(
    urlData => {
      linkSubject.next({ urlData, source: SOURCES.REPORTS })
    },
    customHandleExternalLink,
    false
  )
  contentWindow.addEventListener('click', listener, true)
}

const useInitializationListener = ({ setContentLoaded }) => {
  useLayoutEffect(() => {
    const handler = evt => {
      if (evt.origin !== window.location.origin) {
        return //cannot rely on this message
      }

      if (evt.data === 'ready') {
        setContentLoaded(true)
      }
    }
    window.addEventListener('message', handler)
    return () => {
      window.removeEventListener('message', handler)
    }
  }, [setContentLoaded])
}

const Reports = ({ items, project, selectedItemId, loading, sortFn }) => {
  const [contentLoaded, setContentLoaded] = useState(false)
  useInitializationListener({ setContentLoaded })
  const contentLoadedRef = useRef(contentLoaded)

  useEffect(() => {
    contentLoadedRef.current = contentLoaded
  }, [contentLoaded])

  const ref = useRef()
  const me = useMe()
  const collaboratorsMap = useCollaboratorsMap()
  const collaboratorsJS = useMemo(() => {
    return collaboratorsMap?.toList?.()?.toJS()
  }, [collaboratorsMap])
  const projectJS = useMemo(() => {
    return project?.toJS()
  }, [project])
  const itemsJS = useMemo(() => {
    return items?.toJS()
  }, [items])
  useEffect(() => {
    if (me) {
      try {
        ref.current?.contentDocument?.loadMe?.(me.toJS())
      } catch (err) {
        getSentry().captureException(err)
      }
    }
  }, [me])

  useEffect(() => {
    if (projectJS && itemsJS && collaboratorsJS) {
      try {
        ref.current?.contentDocument?.loadProject?.(projectJS, itemsJS, collaboratorsJS)
      } catch (err) {
        getSentry().captureException(err)
      }
    }
  }, [projectJS, itemsJS, collaboratorsJS, contentLoaded])
  const onLoad = useCallback(
    i => () => {
      if (contentLoadedRef.current) {
        return
      }
      const contentWindow = ref.current?.contentWindow
      const doc = contentWindow?.document
      if (doc) {
        observeDeeperIframe({ contentWindow, setContentLoaded })
        addNavigationListener(contentWindow)
      } else {
        if (i) {
          setTimeout(() => onLoad(i - 1)(), 200)
        } else {
          getSentry().captureException(new Error('Reports iframe could not be loaded'))
        }
      }
    },
    []
  )
  return (
    <div className="h-full w-full overflow-y-hidden">
      <iframe className="h-full w-full border-none" src={url} ref={ref} onLoad={onLoad(3)} title="Reports" />
    </div>
  )
}
