import { useProjectsMap } from '@/common/src/hooks/projectHooks'
import { FILTER_REDUCER_KEYS } from '@/common/src/reducers/filtersKeys'
import {
  Tag,
  TagPicker,
  TagPickerControl,
  TagPickerGroup,
  TagPickerInput,
  TagPickerList,
  TagPickerOption,
  type TagPickerProps,
  useTagPickerFilter,
} from '@fluentui/react-components'
import { useState } from 'react'
import type { Map } from 'immutable'
import { Project } from '@/types'
import { useTranslation } from 'react-i18next'
import { QuadrantSelector } from '@/components/input/quadrant/QuadrantSelector'

export type ProjectsDropdownMultiSelectProps = {
  selectedProjects: number[]
  onChange: (projects: number[]) => void
  className?: string
}

export const ProjectsDropdownMultiSelect = ({
  selectedProjects,
  onChange,
  className,
}: ProjectsDropdownMultiSelectProps) => {
  const [query, setQuery] = useState('')
  const projectsMap: Map<number, Project> = useProjectsMap(true, FILTER_REDUCER_KEYS.PROJECTS_PANEL)
  const { t } = useTranslation()
  const inboxName = t('general.inbox')

  const children = useTagPickerFilter({
    query,
    options: ['0', ...projectsMap.map(project => project?.get('idd').toString() as string).toArray()],
    noOptionsElement: (
      <TagPickerOption value="no-matches">{t('projects_dropdown.no_matches') as string}</TagPickerOption>
    ),
    renderOption: option => (
      <TagPickerOption
        key={option}
        media={<QuadrantSelector size={20} project={projectsMap.get(+option)} readOnly />}
        value={option}
      >
        {option === '0' ? t('general.inbox') : projectsMap.get(+option)?.get('name')}
      </TagPickerOption>
    ),
    filter: option => {
      const optionName = option === '0' ? inboxName : projectsMap.get(+option)?.get('name')
      return !selectedProjects.includes(+option) && optionName.toLowerCase().includes(query.toLowerCase())
    },
  })

  const onOptionSelect: TagPickerProps['onOptionSelect'] = (event, data) => {
    if (data.value === 'no-matches') {
      return
    }

    setQuery('')
    onChange(data.selectedOptions.map(option => +option))
  }

  return (
    <TagPicker onOptionSelect={onOptionSelect} selectedOptions={selectedProjects.map(id => id.toString())}>
      <TagPickerControl className={className}>
        <TagPickerGroup>
          {selectedProjects.map(projectId => {
            const projectName = projectId === 0 ? inboxName : projectsMap.get(projectId)?.get('name')
            return (
              <Tag
                key={projectId}
                shape="rounded"
                media={<QuadrantSelector size={12} project={projectsMap.get(projectId)} readOnly className="ml-1" />}
                value={projectId?.toString()}
                primaryText={{ className: 'truncate' }}
                title={projectName}
              >
                {projectName}
              </Tag>
            )
          })}
        </TagPickerGroup>
        <TagPickerInput aria-label="Select Projects" value={query} onChange={e => setQuery(e.target.value)} />
      </TagPickerControl>
      <TagPickerList>{children}</TagPickerList>
    </TagPicker>
  )
}
