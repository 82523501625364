import { useCallback, useContext } from 'react'
import { NewItemModalContext } from '@/contexts'
import { useDispatch } from 'react-redux'
import { SURFACE_TYPE } from '@/views/modal/CreationModalCommon'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '@/common/src/eventTracking/amplitudeEvents'

export const useCreateItemModal = () => {
  const ctx = useContext(NewItemModalContext)
  const dispatch = useDispatch()
  return useCallback(
    p => {
      const newState = {
        isDisplayed: true,
        quadrant: 0,
        name: '',
        notes: '',
        isInbox: false,
        surface: SURFACE_TYPE.DIALOG,
        displayProjectSelector: false,
        open: true,
        completion: undefined,
        ownerEmail: undefined,
        dueDate: undefined,
        startDate: undefined,
        allDay: false,
        initialProject: undefined,
        linkedItem: undefined,
        copiedFromID: null,
        ...p,
      }
      ctx.setNewItemModalState(newState)
      if (newState.open) {
        dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.OPEN_CREATE_ITEM_DIALOG, { from: newState.mode }))
      }
    },
    [ctx, dispatch]
  )
}
