import { type HTMLAttributes, useState } from 'react'
import styled from 'styled-components'
import { FontIcon, type IFontIconProps } from '@fluentui/react'
import { InfoIconCallout } from '../info/InfoIconCallout'
import { cn } from '@appfluence/classnames'
import { useResizeDetector } from 'react-resize-detector'

const SFontIcon = ({ className, open, ...props }: IFontIconProps & { open: boolean }) => {
  return (
    <FontIcon
      className={cn('my-0 ml-0.5 mr-2.5 self-center transition-transform', open && 'rotate-90', className)}
      {...props}
    />
  )
}

const TitleButton = ({ className, ...props }: HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className={cn(
        'cursor-pointer border-none bg-[transparent] font-[inherit] text-pm-black',
        'mb-[3px] flex items-center rounded-sm p-[5px] outline-none transition-colors',
        'hover:bg-pm-neutral-lighter active:bg-pm-neutral-light',
        className
      )}
      {...props}
    />
  )
}

type AccordionTitleProps = {
  title: string
  onClick: () => void
  open: boolean
  calloutMessage?: string
  titleTextComponent?: JSX.Element
}
const AccordionTitle = ({ title, onClick, open, calloutMessage, titleTextComponent }: AccordionTitleProps) => {
  return (
    <TitleButton onClick={onClick}>
      <SFontIcon iconName="ChevronRight" open={open} />
      {titleTextComponent ?? <span className="font-semibold">{title}</span>}
      {calloutMessage && <InfoIconCallout className="ml-2" message={calloutMessage} />}
    </TitleButton>
  )
}

const AccordionContent = styled.div<{ maxHeight: string | 0 }>`
  overflow-y: hidden;
  max-height: ${p => p.maxHeight};
  transition: max-height 0.2s cubic-bezier(0, 0.67, 0.23, 1);
`

export type AccordionProps = {
  title: string
  children: JSX.Element
  openByDefault?: boolean
  height?: string
  calloutMessage?: string
  className?: string
  titleTextComponent?: JSX.Element
  onOpenChange?: (isOpen: boolean) => void
}

export const Accordion = ({
  title,
  children,
  openByDefault = false,
  height,
  calloutMessage,
  className,
  titleTextComponent,
  onOpenChange,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(height ? openByDefault : false)

  const handleTitleClick = () => {
    setIsOpen(!isOpen)
    onOpenChange?.(!isOpen)
  }

  const { ref: contentRef, height: contentHeight } = useResizeDetector()

  const maxHeight = isOpen ? height ?? `${contentHeight}px` : 0

  return (
    <div className={cn('flex w-full flex-col', className)}>
      <AccordionTitle
        title={title}
        open={isOpen}
        onClick={handleTitleClick}
        calloutMessage={calloutMessage}
        titleTextComponent={titleTextComponent}
      />
      <AccordionContent maxHeight={maxHeight}>
        <div ref={contentRef}>{children}</div>
      </AccordionContent>
    </div>
  )
}
