import _ from 'lodash'
import { safeMapFnCreator } from './immutableHelpers'
import { getAvatarURIForEmail } from '../constants'
import sharingRequestHelper from './sharingRequestHelper'

let _export = {}

const KEYS = {
  ID: 'id',
  EMAIL: 'email',
  FULL_NAME: 'full_name',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  RESOURCE_URI: 'resource_uri',
  TEAMMATE: 'teammate',
  USER_PROFILE: 'user_profile',

  UNLIMITED_HISTORY: 'unlimited_history',
  SEARCH_LOOKBACK: 'search_lookback',

  IS_INVITED: 'isInvited',
  IS_ADMIN: 'isAdmin',
}
_export.KEYS = KEYS

const PROFILE_KEYS = {
  COMPANY: 'company',
  USER_HASH: 'userhash',
  PREMIUM_LEVEL: 'premium_level',
  IS_SUPERMANAGER: 'is_supermanager',
}
_export.PROFILE_KEYS = PROFILE_KEYS

_export.isUser = u => {
  return !!u.get(KEYS.EMAIL)
}

_export.isPMUser = u => {
  return !!(u.get(KEYS.EMAIL) && u.get(KEYS.RESOURCE_URI))
}

_export.getID = u => u.get(KEYS.ID)
_export.getEmail = u => u.get(KEYS.EMAIL)

_export.getFirstName = u => u.get(KEYS.FIRST_NAME)
_export.getLastName = u => u.get(KEYS.LAST_NAME)
_export.getFullName = u => {
  const fullName = u.get(KEYS.FULL_NAME)
  if (!_.isEmpty(fullName)) {
    return fullName
  }
  const first = _export.getFirstName(u) || ''
  const second = _export.getLastName(u) || ''
  if (first || second) {
    return _.trim(`${first} ${second}`)
  }
  return fullName
}
_export.getURI = u => u.get(KEYS.RESOURCE_URI)
_export.getAvatarURL = u => getAvatarURIForEmail(_export.getEmail(u))
_export.getTeammate = u => u.get(KEYS.TEAMMATE)
_export.getProfile = u => u.get(KEYS.USER_PROFILE)

_export.getCompany = u => u.getIn([KEYS.USER_PROFILE, PROFILE_KEYS.COMPANY])
_export.getUserHash = u => u.getIn([KEYS.USER_PROFILE, PROFILE_KEYS.USER_HASH])
_export.getPremiumLevel = u => u.getIn([KEYS.USER_PROFILE, PROFILE_KEYS.PREMIUM_LEVEL])

_export.isInvited = u => !!u.get(KEYS.IS_INVITED)
_export.isAdmin = u => !!u.get(KEYS.IS_ADMIN)
_export.isSupermanager = u => !!u.getIn([KEYS.USER_PROFILE, PROFILE_KEYS.IS_SUPERMANAGER])

_export.contains = (u, text) => {
  const email = _export.getEmail(u)
  const fullName = _export.getFullName(u) || email || ''
  return fullName.match(new RegExp(text, 'i')) || _.includes(email, text)
}

_export.getFullNameWithEmail = u => {
  const email = _export.getEmail(u)
  const fullName = _export.getFullName(u)
  return _.isEmpty(fullName) ? email : `${fullName} (${email})`
}

_export.fromSharingRequest = request => {
  const email = sharingRequestHelper.getTargetUsername(request)
  return request.set(KEYS.EMAIL, email)
}

_export.sortDescriptorByEmail = u => _.toLower(_export.getEmail(u))
_export.sortDescriptorByFullName = u => {
  const text = _export.getFullName(u) || _export.getEmail(u)
  return _.toLower(text)
}

_export.hasUnlimitedHistory = u => u.get(KEYS.UNLIMITED_HISTORY)
_export.getSearchLookbackInDays = u => u.get(KEYS.SEARCH_LOOKBACK)

_export = _.mapValues(_export, f => (_.isFunction(f) ? safeMapFnCreator(f) : f))

export default _export
