import React, { useMemo } from 'react'
import { MainLayout } from './MainLayout'

const Container = ({ hasRightSide, children }) => {
  const style = useMemo(() => {
    let style = { boxSizing: 'border-box', height: '100%' }
    const value = 20
    if (hasRightSide) {
      style.paddingLeft = value
    } else {
      style.padding = `0 ${value}px`
    }
    return style
  }, [hasRightSide])
  return <div style={style}>{children}</div>
}

export const MeetingLayout = ({ header, main, rightSide }) => {
  const mainContainer = <Container hasRightSide={!!rightSide}>{main}</Container>

  return <MainLayout header={header} main={mainContainer} rightSide={rightSide} fixed={true} />
}
