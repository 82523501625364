import { ShortcutHelp } from '../components/shortcutHelp/ShortcutHelp'
import React from 'react'
import { type ITooltipHostProps, type ITooltipProps } from '@fluentui/react'
import { TooltipHost } from '@/components/tooltip/TooltipHost'

export type ShortcutTooltipHostProps = ITooltipHostProps & {
  tooltipKeys: string[]
}
export const ShortcutTooltipHost = ({ tooltipKeys, tooltipProps, ...rest }: ShortcutTooltipHostProps) => {
  const customTooltipProps: ITooltipProps = {
    onRenderContent: props => {
      const content = props?.content
      return content ? (
        <div className="flex flex-col items-center gap-1">
          <span>{content}</span>
          {tooltipKeys && <ShortcutHelp keys={tooltipKeys} />}
        </div>
      ) : null
    },
  }
  return <TooltipHost tooltipProps={{ ...tooltipProps, ...customTooltipProps }} {...rest} />
}

export type ShortcutTooltipContentProps = {
  label: string
  tooltipKeys: string[]
}
export const ShortcutTooltipContent = ({ label, tooltipKeys }: ShortcutTooltipContentProps) => {
  return (
    <div className="flex flex-col items-center gap-1">
      <span>{label}</span>
      {<ShortcutHelp keys={tooltipKeys} />}
    </div>
  )
}
