import { createAction } from 'redux-api-middleware'
import { PM_API_RESOURCES, PM_API_URLS } from '../constants'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'
import { appendQueryParamsToURL, extendPMHeaders, getCredentialsConfig } from '../helpers/requestHelper'
import { AUTH_ACTIONS } from './AuthActions'
import { OAUTH_CLIENT_ID, OAUTH_CLIENT_SECRET } from '../environment'

export const getToken = (username, password) => {
  let body = new URLSearchParams()
  body.set('username', username)
  body.set('password', password)
  body.set('grant_type', 'password')
  body = body.toString()
  return createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.GET_TOKEN),
    method: 'POST',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.GET_TOKEN),
    headers: () => {
      const authorization = 'Basic ' + btoa(OAUTH_CLIENT_ID + ':' + OAUTH_CLIENT_SECRET)
      return {
        authorization,
        'content-type': 'application/x-www-form-urlencoded',
      }
    },
    body,
  })
}

//Setting the same info as getToken response, but manually. Util when we're using authorization code oauth

export const setTokenManually = serverData => ({
  type: AUTH_ACTIONS.MANUALLY_SET_TOKEN,
  payload: serverData,
})

//TODO remove data on reducers
export const getSignOut = () => {
  return createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.SIGN_OUT),
    method: 'GET',
    credentials: getCredentialsConfig(),
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.SIGN_OUT),
    headers: extendPMHeaders(),
  })
}
