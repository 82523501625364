import { useMemo } from 'react'
import { linkDependencyHelper } from '../common/src/helpers'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

export const useDependencyOptions = () => {
  const { t } = useTranslation()
  return useMemo(() => {
    const DEPENDENCY_KINDS = {
      [linkDependencyHelper.DEPENDENCY.RELATED]: t('links_section.dependency.related'),
      [linkDependencyHelper.DEPENDENCY.BLOCKED_BY]: t('links_section.dependency.blocked_by'),
      [linkDependencyHelper.DEPENDENCY.BLOCKS]: t('links_section.dependency.blocks'),
      [linkDependencyHelper.DEPENDENCY.CAUSED_BY]: t('links_section.dependency.caused_by'),
      [linkDependencyHelper.DEPENDENCY.CAUSES]: t('links_section.dependency.causes'),
    }
    return _.map(DEPENDENCY_KINDS, (text, key) => {
      return {
        key: parseInt(key),
        text,
      }
    })
  }, [t])
}

export const useDependencyExplanation = ({
  dependency,
  translationKeyPrefix = 'links_section.create_item_explanation',
}) => {
  const { t } = useTranslation('', { keyPrefix: translationKeyPrefix })
  const options = useMemo(
    () => ({
      [linkDependencyHelper.DEPENDENCY.RELATED]: t('related'),
      [linkDependencyHelper.DEPENDENCY.BLOCKS]: t('blocks'),
      [linkDependencyHelper.DEPENDENCY.BLOCKED_BY]: t('blocked_by'),
      [linkDependencyHelper.DEPENDENCY.CAUSES]: t('causes'),
      [linkDependencyHelper.DEPENDENCY.CAUSED_BY]: t('caused_by'),
    }),
    [t]
  )
  return useMemo(() => options[dependency], [options, dependency])
}
