import React from 'react'
import styled from 'styled-components'
import { ActionButton, IconButton } from '@fluentui/react/lib/Button'
import { PanelView } from '../../components/panels/PanelView'
import { ICON_NAMES } from '../../common/src/msIcons'
import { useTranslation } from 'react-i18next'

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-bottom: 8px;
`

const ClearButton = styled(ActionButton)`
  height: auto;
  padding: 0;
  margin-left: -4px;
`

export const MenuButton = styled(IconButton).attrs({
  iconProps: {
    iconName: ICON_NAMES.CollapseMenu,
  },
  styles: {
    icon: {
      fontSize: '14px',
    },
  },
})`
  height: 24px;
`

export const FiltersView = ({ isFiltering, clearTitle, menuProps, onClear, children, ...rest }) => {
  const onClearButton = () => {
    onClear()
  }
  const { t } = useTranslation()

  const headerChildren = (
    <HeaderContainer>
      <ClearButton disabled={!isFiltering} onClick={onClearButton}>
        {t('project_filters.clear_title')}
      </ClearButton>
      {menuProps && <MenuButton menuProps={menuProps} />}
    </HeaderContainer>
  )

  return (
    <PanelView headerChildren={headerChildren} {...rest}>
      {children}
    </PanelView>
  )
}
