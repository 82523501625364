import _ from 'lodash'

export let ACTION_TYPES = {
  TOGGLE_USERNAME_STATUS: 'TOGGLE_USERNAME_STATUS',
  SET_BLACKLIST: 'SET_BLACKLIST',
  SET_SHOW_COMPLETED: 'SET_SHOW_COMPLETED',
}

ACTION_TYPES = _.mapValues(ACTION_TYPES, v => 'WORKLOAD/' + v)

export const toggleUsernameStatus = (username, status) => {
  return {
    type: ACTION_TYPES.TOGGLE_USERNAME_STATUS,
    payload: { username, status },
  }
}

export const setBlacklist = usernameSet => {
  return {
    type: ACTION_TYPES.SET_BLACKLIST,
    payload: { usernameSet },
  }
}

export const setShowCompleted = showCompleted => {
  return {
    type: ACTION_TYPES.SET_SHOW_COMPLETED,
    payload: { showCompleted },
  }
}

export const setBlacklistUserGroup = (userGroup, team) => {
  const teamEmails = team.keySeq()
  const userGroupEmails = userGroup.memberships.map(membership => membership.user.email)
  const blacklist = teamEmails.filter(email => userGroupEmails.indexOf(email) === -1)
  return setBlacklist(blacklist.toSet())
}
