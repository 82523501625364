import { ContextualMenuItemType } from '@fluentui/react'
import { projectHelper } from '../../common/src/helpers'
import { renderProjectOption } from '../dropdown/renderProjectOption'
import { useRecentlyVisitedProjects } from '../../common/src/hooks/projectHooks'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { toWithParams } from '../../utils/NavPanelCommon'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useOpenProject, useShowCreateProjectView } from '../../hooks/projectHooks'

const MAX_RECENT_PROJECTS = 4

export const useFastProjectsMenu = ({ onAfterShowAllProjects, onAfterClickForProject, onAfterCreateProject } = {}) => {
  const to = toWithParams()
  const history = useHistory()
  const { t } = useTranslation()
  const recentProjects = useRecentlyVisitedProjects(MAX_RECENT_PROJECTS)
  const openProject = useOpenProject()
  const showCreateProjectView = useShowCreateProjectView(EVENT_EXTRA.CREATE_PROJECT.MODE.LEFT_PANEL_PROJECTS_BUTTON)

  const handleShowAllProjects = () => {
    onAfterShowAllProjects?.()
    history.push(to.projects)
  }

  const handleClickForProject = project => {
    onAfterClickForProject?.(project)
    openProject(project)
  }

  const handleCreateProject = () => {
    onAfterCreateProject?.()
    showCreateProjectView()
  }

  return {
    items: [
      {
        id: 'leftPanelComponent_showAllProjectsButton',
        key: 'all_projects',
        text: t('project.show_all_projects'),
        onClick: handleShowAllProjects,
        iconProps: { iconName: 'BulletedList' },
      },
      {
        key: 'recent',
        itemType: ContextualMenuItemType.Section,
        sectionProps: {
          topDivider: true,
          bottomDivider: true,
          items: recentProjects.map(project => ({
            key: projectHelper.getIdd(project) ?? 0,
            data: { project },
            onRenderContent: renderProjectOption,
            text: projectHelper.getName(project),
            onClick: () => handleClickForProject(project),
          })),
        },
      },
      {
        key: 'create_project',
        text: t('project.new_project'),
        iconProps: { iconName: 'Add' },
        onClick: handleCreateProject,
      },
    ],
  }
}
