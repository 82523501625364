import type FullCalendar from '@fullcalendar/react'
import type { EventInput } from '@fullcalendar/core'
import { Item } from '@/types'

export enum View {
  Day = 'timeGridDay',
  Week = 'timeGridWeek',
  Month = 'dayGridMonth',
}

export interface ICalColor {
  fontColor: string
  colorBg: string
}

interface CalendarProjectInfo {
  color: CalendarColorName
  display: boolean
}

export type CalendarProjectInfoMap = Map<number, CalendarProjectInfo>
export type FullCalendarProps = typeof FullCalendar.prototype.props

export enum CalendarColorName {
  Salmon = 'Salmon',
  Lavender = 'Lavender',
  BlueWhisper = 'Blue Whisper',
  Glory = 'Glory',
  Dreamy = 'Dreamy',
  Sunshine = 'Sunshine',
  Coral = 'Coral',
  SaltPan = 'Salt Pan',
  BubbleGum = 'Bubble Gum',
  Sandstone = 'Sandstone',
  Cream = 'Cream',
  Gray = 'Gray',
}

export const ColorPaletteMap: Record<CalendarColorName, ICalColor> = {
  [CalendarColorName.Salmon]: { fontColor: 'white', colorBg: 'rgb(241,115,113)' },
  [CalendarColorName.Lavender]: { fontColor: 'white', colorBg: 'rgb(187,107,201)' },
  [CalendarColorName.BlueWhisper]: { fontColor: 'white', colorBg: 'rgb(92,107,192)' },
  [CalendarColorName.Glory]: { fontColor: 'black', colorBg: 'rgb(192,212,250)' },
  [CalendarColorName.Dreamy]: { fontColor: 'black', colorBg: 'rgb(174,213,129)' },
  [CalendarColorName.Sunshine]: { fontColor: 'black', colorBg: 'rgb(255, 245, 141)' },
  [CalendarColorName.Coral]: { fontColor: 'black', colorBg: 'rgb(255,183,97)' },
  [CalendarColorName.SaltPan]: { fontColor: 'white', colorBg: 'rgb(131, 155, 166)' },
  [CalendarColorName.BubbleGum]: { fontColor: 'white', colorBg: 'rgb(248, 183, 206)' },
  [CalendarColorName.Sandstone]: { fontColor: 'white', colorBg: 'rgb(171, 147, 138)' },
  [CalendarColorName.Cream]: { fontColor: 'black', colorBg: 'rgb(184, 177, 104)' },
  [CalendarColorName.Gray]: { fontColor: 'black', colorBg: 'rgb(228, 228, 228)' },
} as const

export const DefaultCalendarColorName = CalendarColorName.Salmon

type CustomEventData = {
  pmItem: Item
}
export type FullCalendarRef = React.RefObject<FullCalendar>
export type PMEvent = EventInput & CustomEventData
