import { useTheme } from 'styled-components'
import { useMemo } from 'react'
import { useIsDarkTheme } from '../../themes'

export const useCellStyles = () => {
  const theme = useTheme()
  const isDarkTheme = useIsDarkTheme()
  return useMemo(() => {
    return {
      text: theme.palette.black,
      highlight: isDarkTheme ? theme.palette.always_black : theme.palette.neutralTertiary,
      background: theme.palette.white,
      readBackground: void 0,
      selectedBackground: theme.palette.neutralLight,
    }
  }, [isDarkTheme, theme])
}
