import _ from 'lodash'
import { BasicDateSection } from './BasicDateSection'
import { Format, formatDate } from '../../utils/datefns'
import { FC, Ref } from 'react'

interface IProps {
  name: string
  date: Date
  allDay: boolean
  overdue?: boolean
  onClick: () => void

  readOnly?: boolean
  styles: any
  className?: string
  iconName?: string
  containerRef: Ref<HTMLDivElement>
  children?: React.ReactNode
}

export const DateSection: FC<IProps> = ({ name, date, allDay, overdue = false, onClick, ...rest }) => {
  const existsDate = !!date
  const format: Format = allDay ? 'ShortenedAllDay' : 'ShortenedNormal'
  const text = existsDate ? formatDate(format)(date) : ''
  const shortenedTextIfSpanish = _.replace(text, 'de', '')
  return <BasicDateSection name={name} text={shortenedTextIfSpanish} overdue={overdue} onClick={onClick} {...rest} />
}
