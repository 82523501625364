import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { deleteProjectForEverybody, leaveProject } from '../../common/src/actions/projectsAPI'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  useToastController,
} from '@fluentui/react-components'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { FluentToast } from '../toast/FluentToast'
import { useHistory } from 'react-router'
import { projectHelper } from '../../common/src/helpers'
import { useMe } from '../../common/src/hooks/usersHooks'

export const DeleteProjectModal = ({ project, open, onDismiss }) => {
  const me = useMe()
  const imAdmin = projectHelper.isAdminUser(project, me)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const { dispatchToast } = useToastController()
  const primaryButtonRef = useRef(null)
  const [inProgress, setInProgress] = useState(false)
  const owners = projectHelper.getOwnersURIs(project)

  useEffect(() => {
    if (open) {
      primaryButtonRef.current?.focus()
    }
  }, [open])

  const afterDelete = () => {
    onDismiss?.()
    const path = getRelativeURLKeepingQuerySearch.projects()
    history.push(path)
    dispatchToast(<FluentToast>Project deleted</FluentToast>, {
      intent: 'success',
    })
    setInProgress(false)
  }
  const deleteForMe = async () => {
    setInProgress(true)
    await dispatch(leaveProject(project))
    afterDelete()
  }
  const deleteForEverybody = async () => {
    setInProgress(true)
    await dispatch(deleteProjectForEverybody(project))
    afterDelete()
  }

  const translationKey = owners?.size === 1 ? 'delete' : imAdmin ? 'leave_or_delete' : 'leave'

  const buttons = (() => {
    if (owners?.size === 1) {
      return (
        <Button
          appearance="primary"
          onClick={imAdmin ? deleteForEverybody : deleteForMe}
          disabled={inProgress}
          ref={primaryButtonRef}
        >
          {t('modal.delete')}
        </Button>
      )
    }
    if (!imAdmin) {
      return (
        <Button appearance="primary" onClick={deleteForMe} disabled={inProgress} ref={primaryButtonRef}>
          {t('modal.for_me')}
        </Button>
      )
    }
    return (
      <>
        <Button appearance="secondary" onClick={deleteForMe} disabled={inProgress}>
          {t('modal.for_me')}
        </Button>
        <Button appearance="primary" onClick={deleteForEverybody} disabled={inProgress} ref={primaryButtonRef}>
          {t('modal.for_everybody')}
        </Button>
      </>
    )
  })()

  return (
    <Dialog
      open={open}
      modalType="alert"
      onOpenChange={(ev, data) => {
        if (!data.open) {
          onDismiss?.()
        }
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t(`modal.delete_project.${translationKey}.title`)}</DialogTitle>
          <DialogContent>
            {t(`modal.delete_project.${translationKey}.subtitle`, { project_name: projectHelper.getName(project) })}
          </DialogContent>
          <DialogActions>
            <DialogTrigger>
              <Button appearance="secondary" disabled={inProgress}>
                {t('general.cancel')}
              </Button>
            </DialogTrigger>
            {buttons}
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
