import { type InfiniteData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { appendQueryParamsToURL, getCredentialsConfig } from '@/common/src/helpers/requestHelper'
import { useDispatch, useStore } from 'react-redux'
import type { PaginatedResponse } from '@/types/apiTypes'
import type { CustomTemplate, PublicTemplate } from '@/types/projectTemplate'
import {
  AMPLITUDE_ACTION_TYPES,
  dispatchEvent as trackEvent,
  EVENT_EXTRA,
} from '@/common/src/eventTracking/amplitudeEvents'
import { PUBLIC_PROJECT_TEMPLATE_CATEGORY_ALL } from '@/constants/projectTemplates'

export const useAllPublicProjectTemplates = ({ category }: { category?: string }) => {
  const store = useStore()
  return useInfiniteQuery({
    queryKey: ['projectTemplates', 'public', { category }],
    queryFn: async ({ pageParam }) => {
      const response = await fetch(
        appendQueryParamsToURL('/api/v1/public_template/', {
          limit: 50,
          offset: pageParam,
          category: category === PUBLIC_PROJECT_TEMPLATE_CATEGORY_ALL ? undefined : category,
        })(store.getState()),
        {
          credentials: getCredentialsConfig(),
        }
      )
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return (await response.json()) as PaginatedResponse<PublicTemplate>
    },
    staleTime: 1000 * 60 * 60,
    getNextPageParam: lastPage => (lastPage.meta.next ? lastPage.meta.offset + lastPage.meta.limit : undefined),
    initialPageParam: 0,
    select: data => data.pages.flatMap(page => page.objects),
  })
}

export const usePublicProjectTemplateSearch = (
  { text, category }: { text: string; category: string },
  enabled = true
) => {
  const store = useStore()
  return useQuery({
    enabled,
    queryKey: ['projectTemplates', 'public', 'search', { text, category }],
    queryFn: async ({ signal }) => {
      const response = await fetch(
        appendQueryParamsToURL('/api/v1/public_template/search/', {
          text,
          category: category === PUBLIC_PROJECT_TEMPLATE_CATEGORY_ALL ? undefined : category,
        })(store.getState()),
        {
          signal,
          credentials: getCredentialsConfig(),
        }
      )
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return (await response.json()) as PublicTemplate[]
    },
    staleTime: 1000 * 60 * 60,
  })
}

type ProjectTemplateAISearchResponse = { error?: string; templates: PublicTemplate[] }
export const usePublicProjectTemplateAISearch = ({ text }: { text: string }, enabled = true) => {
  const store = useStore()
  return useQuery({
    enabled,
    queryKey: ['projectTemplates', 'publicAISearch', { text }],
    queryFn: async ({ signal }) => {
      const response = await fetch(
        appendQueryParamsToURL('/api/v1/public_template/ai_search_dict/', { text })(store.getState()),
        {
          signal,
          credentials: getCredentialsConfig(),
        }
      )
      if (!response.ok) {
        let error = 'Network response was not ok'
        try {
          const json = (await response.json()) as ProjectTemplateAISearchResponse
          if (json.error) {
            error = json.error
          }
        } catch {}
        throw new Error(error)
      }
      const json = (await response.json()) as ProjectTemplateAISearchResponse
      if (json.error) {
        throw new Error(json.error)
      }
      return json.templates
    },
    retry: false,
    staleTime: 1000 * 60 * 60,
  })
}

export const usePublicProjectTemplate = (id: number) => {
  const store = useStore()
  const queryClient = useQueryClient()
  return useQuery({
    queryKey: ['projectTemplates', 'public', id],
    queryFn: async () => {
      const response = await fetch(appendQueryParamsToURL(`/api/v1/public_template/${id}/`)(store.getState()), {
        credentials: getCredentialsConfig(),
      })
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return (await response.json()) as PublicTemplate
    },
    staleTime: 1000 * 60 * 60,
    initialData: () =>
      (
        queryClient.getQueryData(['projectTemplates', 'public', { text: '' }]) as Array<PublicTemplate> | undefined
      )?.find((template: PublicTemplate) => template.id === id),
    initialDataUpdatedAt: () => queryClient.getQueryState(['projectTemplates', 'public', { text: '' }])?.dataUpdatedAt,
  })
}

export const useCustomProjectTemplates = () => {
  const store = useStore()
  return useInfiniteQuery({
    queryKey: ['projectTemplates', 'custom'],
    queryFn: async ({ pageParam }) => {
      const response = await fetch(
        appendQueryParamsToURL('/api/v1/template/', { limit: 100, offset: pageParam })(store.getState()),
        {
          credentials: getCredentialsConfig(),
        }
      )
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return (await response.json()) as PaginatedResponse<CustomTemplate>
    },
    getNextPageParam: lastPage => (lastPage.meta.next ? lastPage.meta.offset + lastPage.meta.limit : undefined),
    initialPageParam: 0,
    select: data => data.pages.flatMap(page => page.objects),
  })
}

export const useCustomProjectTemplate = (id: number) => {
  const store = useStore()
  const queryClient = useQueryClient()
  return useQuery({
    queryKey: ['projectTemplates', 'custom', id],
    queryFn: async () => {
      const response = await fetch(appendQueryParamsToURL(`/api/v1/template/${id}/`)(store.getState()), {
        credentials: getCredentialsConfig(),
      })
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return (await response.json()) as CustomTemplate
    },
    initialData: () =>
      (
        queryClient.getQueryData(['projectTemplates', 'custom']) as
          | InfiniteData<PaginatedResponse<CustomTemplate>, number>
          | undefined
      )?.pages
        .flatMap(page => page.objects)
        .find(template => template.id === id),
  })
}

export const usePublicProjectTemplateCategories = (enabled = true) => {
  const store = useStore()
  return useQuery({
    queryKey: ['projectTemplates', 'publicCategories'],
    queryFn: async () => {
      const response = await fetch(appendQueryParamsToURL('/api/v1/public_template/categories/')(store.getState()), {
        credentials: getCredentialsConfig(),
      })
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return (await response.json()) as string[]
    },
    staleTime: 1000 * 60 * 60,
    enabled,
  })
}

export const useDeleteCustomProjectTemplate = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (id: number) => {
      const response = await fetch(`/api/v1/template/${id}/`, {
        method: 'DELETE',
        credentials: getCredentialsConfig(),
      })
      if (!response.ok) {
        throw new Error(response.status + ' ' + response.statusText)
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['projectTemplates', 'custom'] })
    },
  })
}

export type CreateCustomProjectTemplateFromProjectParams = {
  projectId: number
  name: string
  notes: string
  reset_items: boolean
  skip_completed: boolean
  include_members: boolean
}
export const useCreateCustomProjectTemplateFromProject = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  return useMutation({
    mutationFn: async ({ projectId, ...rest }: CreateCustomProjectTemplateFromProjectParams) => {
      const response = await fetch(`/api/v1/project/${projectId}/create_template/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(rest),
        credentials: getCredentialsConfig(),
      })
      if (!response.ok) {
        throw new Error(response.status + ' ' + response.statusText)
      }
      return (await response.json()) as CustomTemplate
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['projectTemplates', 'custom'] })
      const eventPropsForCustom = { type: EVENT_EXTRA.CREATE_TEMPLATE.TYPE.CUSTOM }
      dispatch(trackEvent(AMPLITUDE_ACTION_TYPES.CREATE_TEMPLATE, eventPropsForCustom))
    },
  })
}
