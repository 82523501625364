import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { CustomPersona, OneOnOne, Teams, Title, useInviteUser } from './GrowthCommon'
import { useDispatch, useSelector } from 'react-redux'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { markGrowthUserAsInvited } from '../../actions/uiActions'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import { isEmailMarkedAsInvitedInGrowthView } from '../../selectors/uiSelectors'
import { BaseFlexRow } from '../../components/layout/FlexContainer'

const Container = styled(BaseFlexRow)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 450px));
  grid-gap: 1rem;
  justify-content: space-around;
`

export const GrowthList = ({ title, collaborators }) => {
  if (_.isEmpty(collaborators)) {
    return false
  }
  const elements = _.map(collaborators, c => <Cell key={c.hashCode()} collaborator={c} />)
  return (
    <>
      <Title>{title}</Title>
      <Container>{elements}</Container>
    </>
  )
}

const CellContainer = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const PersonaContainer = styled.div`
  flex: 1;
  justify-self: start;
  overflow: hidden;
`

const Cell = React.memo(({ collaborator }) => {
  const graphProfile = collaborator.get('graphProfile')
  if (graphProfile) {
    return <GraphCell collaborator={collaborator} />
  }
  const email = collaborator.get('email')
  return (
    <CellContainer key={email}>
      <PersonaContainer>
        <CustomPersona collaborator={collaborator} />
      </PersonaContainer>
      <OneOnOne email={email} />
    </CellContainer>
  )
})

const GraphCell = ({ collaborator }) => {
  const graphProfile = collaborator.get('graphProfile')
  const email = graphProfile.get('mail')
  const alreadyInvited = useSelector(isEmailMarkedAsInvitedInGrowthView(email))
  return (
    <CellContainer key={email}>
      <PersonaContainer>
        <CustomPersona collaborator={collaborator} />
      </PersonaContainer>
      <Teams email={email} />
      <OneOnOne email={email} />
      <InviteButton alreadyInvited={alreadyInvited} email={email} />
    </CellContainer>
  )
}

const InviteButton = ({ alreadyInvited, email }) => {
  const dispatch = useDispatch()
  const invite = useInviteUser(dispatch)
  const clickInvite = async () => {
    dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.GROWTH_INVITE_USER))
    dispatch(markGrowthUserAsInvited(email))
    await invite(email)
  }
  return (
    <PrimaryButton onClick={clickInvite} disabled={alreadyInvited}>
      {alreadyInvited ? 'Invited' : 'Invite'}
    </PrimaryButton>
  )
}
