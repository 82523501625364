import React from 'react'
import styled from 'styled-components'
import { ContainerPanel } from '../../components/panels/ContainerPanel'
import { PanelView } from '../../components/panels/PanelView'
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona'
import { ActionButton } from '@fluentui/react/lib/Button'
import { graphProfilePictureUrlUsingProxy } from '../../common/src/constants'
import { ICON_NAMES } from '../../common/src/msIcons'

export const GrowthUserPanel = ({ collaborator, ...rest }) => {
  return (
    <ContainerPanel {...rest}>
      <View collaborator={collaborator} />
    </ContainerPanel>
  )
}

const personaStyles = {
  root: {
    margin: 'auto',
  },
}

const Name = styled.p`
  margin-bottom: 5px;
`

const View = ({ collaborator, enableActions = true }) => {
  const graphProfile = collaborator.get('graphProfile')
  const id = graphProfile.get('id')
  const avatarUrl = id ? graphProfilePictureUrlUsingProxy(id) : ''
  const name = graphProfile.get('displayName')
  const email = graphProfile.get('mail')
  return (
    <PanelView>
      <Persona size={PersonaSize.size72} imageUrl={avatarUrl} styles={personaStyles} />
      <Name>{name}</Name>
      <p>{email}</p>
      {enableActions && (
        <>
          <ActionButton iconProps={{ iconName: ICON_NAMES.TeamsLogo }}>Open Chat in Teams</ActionButton>
          <ActionButton iconProps={{ iconName: ICON_NAMES.OpenPane }}>Open One on One</ActionButton>
        </>
      )}
    </PanelView>
  )
}
