export const KEYS = {
  TITLE: 'title',
  BODY: 'body',
  STATE: 'state',
  CAN_BE_ACCEPTED: 'can_be_accepted',
  ID: 'id',
  EXPIRED_DATE: 'expired_date',
  IMAGE_URL: 'image_url',
  LINK_URL: 'link_url',
  LINK_TEXT: 'link_text',
  MESSAGE_TYPE: 'message_type',
}

export const NOTIFICATION_STATE = {
  EXPIRED: -1,
  UNREAD: 0,
  READ: 1,
  ACCEPTED: 2,
  DISMISSED: 3,
}

export const MESSAGE_TYPE = {
  INFO: 0,
  SUCCESS: 1,
  WARNING: 2,
  DANGER: 3,
  CUSTOM: 4,
}

export const getTitle = notification => notification.get(KEYS.TITLE)
export const getBody = notification => notification.get(KEYS.BODY)
export const getState = notification => notification.get(KEYS.STATE)
export const canBeAccepted = notification => notification.get(KEYS.CAN_BE_ACCEPTED)
export const getId = notification => notification.get(KEYS.ID)
export const getExpiredDate = notification => notification.get(KEYS.EXPIRED_DATE)
export const getImageUrl = notification => notification.get(KEYS.IMAGE_URL)
export const getLinkUrl = notification => notification.get(KEYS.LINK_URL)
export const getLinkText = notification => notification.get(KEYS.LINK_TEXT)
export const getMessageType = notification => notification.get(KEYS.MESSAGE_TYPE)
export const isExpired = (notification, now) => {
  const expiredStr = getExpiredDate(notification)
  if (!expiredStr) return false
  const expiration = new Date(expiredStr)
  return expiration < now
}
export const shouldBeShown = (notification, now) =>
  getState(notification) === NOTIFICATION_STATE.UNREAD && !isExpired(notification, now)
