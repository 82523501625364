import { itemHelper, meetingHelper, projectHelper } from '@/common/src/helpers'
import { getPathToMatrixFromItem, getRelativeURLKeepingQuerySearch } from '@/helpers/routeHelper'
import { ROUTE_ID } from '@/routes/routeIdList'
import { Project, Item, Meeting } from '@/types'

interface IContext {
  project: Project
  item: Item
  meeting: Meeting
}

interface TitleProps {
  name: string
  hideArrow: boolean
  path?: string | { pathname: string; search?: string }
}

const titleForMatrix = ({ project }: IContext): TitleProps => {
  const selectedProjectName = projectHelper.getName(project)
  const newPath = getRelativeURLKeepingQuerySearch.projects()
  const newPathQueryParams = new URLSearchParams(newPath.search)
  newPathQueryParams.set('fromProject', projectHelper.getIdd(project))
  return {
    name: selectedProjectName,
    hideArrow: false,
    path: {
      pathname: newPath.pathname,
      search: newPathQueryParams.toString(),
    },
  }
}

const titleForProject = ({ project }: IContext): TitleProps => {
  const id = projectHelper.getIdd(project)
  return {
    name: 'Open Matrix',
    hideArrow: false,
    path: getRelativeURLKeepingQuerySearch.matrixForProjectId(id),
  }
}

const titleForItem = ({ item }: IContext): TitleProps => {
  const title = itemHelper.isInbox(item) ? 'Open Inbox' : 'Open Matrix'
  return {
    name: title,
    hideArrow: false,
    path: getPathToMatrixFromItem(item),
  }
}

const titleForMeeting = ({ meeting }: IContext): TitleProps => {
  const title = meetingHelper.getTitle(meeting)
  return {
    name: title,
    hideArrow: true,
  }
}

const genericTitle = (title: string): TitleProps => {
  return {
    name: title,
    hideArrow: true,
  }
}

export const routesWithTitle = (context: IContext) => {
  const { item, project, meeting } = context
  return {
    [ROUTE_ID.MATRIX_APP]: project ? () => titleForMatrix(context) : null,
    [ROUTE_ID.LIST_APP]: project ? () => titleForMatrix(context) : null,
    [ROUTE_ID.REPORTS_APP]: project ? () => titleForMatrix(context) : null,
    [ROUTE_ID.CALENDAR_APP]: project ? () => titleForMatrix(context) : null,
    [ROUTE_ID.GANTT_APP]: project ? () => titleForMatrix(context) : null,
    [ROUTE_ID.FEED_APP]: project ? () => titleForMatrix(context) : null,
    [ROUTE_ID.PROJECT_APP]: project ? () => titleForProject(context) : null,
    [ROUTE_ID.ITEM_APP]: item ? () => titleForItem(context) : null,
    [ROUTE_ID.MEETING]: meeting ? () => titleForMeeting(context) : null,
    [ROUTE_ID.HOME]: () => genericTitle('Home'),
    [ROUTE_ID.INBOX]: () => genericTitle('Inbox'),
    [ROUTE_ID.GLOBAL_CALENDAR_APP]: () => genericTitle('Calendar'),
    [ROUTE_ID.GLOBAL_FEED_APP]: () => genericTitle('Feed'),
    [ROUTE_ID.GLOBAL_GANTT_APP]: () => genericTitle('Gantt'),
    [ROUTE_ID.GLOBAL_REPORTS_APP]: () => genericTitle('Reports'),
    [ROUTE_ID.ONE_ON_ONE_APP]: () => genericTitle('One on One'),
    [ROUTE_ID.PROJECTS]: () => genericTitle('Project List '),
    [ROUTE_ID.SEARCH_APP]: () => genericTitle('Search'),
  }
}
