import React, { memo, useCallback } from 'react'
import styled from 'styled-components'
import { ContainerPanel } from '../panels/ContainerPanel'
import { SearchItems } from './SearchItems'
import { ItemPanel } from '../panels/item/ItemPanel'
import { useBoolean } from '../../common/src/hooks/useBoolean'
import { itemHelper } from '../../common/src/helpers'
import { useTranslation } from 'react-i18next'
import { linkSubject } from '../../reactions/linkSubject'
import { uriDataCreator } from '../../common/src/helpers/URLHelper'
import { PM_API_RESOURCE_TYPE } from '../../common/src/constants'

const styles = {
  scrollableContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'hidden',
  },
  content: {
    flex: 1,
  },
}

const SSearchItems = styled(SearchItems)`
  margin: 0;
`

export const SearchItemsPanel = memo(
  ({
    hideItemId,
    getInitialItems,
    getInitialItemsFromState,
    onAddItem,
    onSelectItemProp,
    subHeader = null,
    ...rest
  }) => {
    const { t } = useTranslation()
    const [isOpenItemPanel, { setTrue: showItemPanel, setFalse: hideItemPanel }] = useBoolean(false)
    const onSelectItemDefault = useCallback(
      item => {
        const id = itemHelper.getId(item)
        linkSubject.next({ urlData: uriDataCreator(1, PM_API_RESOURCE_TYPE.ITEM, id) })
        showItemPanel()
      },
      [showItemPanel]
    )
    const onSelectItem = onSelectItemProp || onSelectItemDefault
    return (
      <ContainerPanel headerText={t('search_panel.search_items_header')} styles={styles} {...rest}>
        {!!subHeader && subHeader}
        <SSearchItems
          hideItemId={hideItemId}
          onSelectItem={onSelectItem}
          onAddItem={onAddItem}
          getInitialItems={getInitialItems}
          getInitialItemsFromState={getInitialItemsFromState}
        />
        <ItemPanel isOpen={isOpenItemPanel} onDismiss={hideItemPanel} />
      </ContainerPanel>
    )
  }
)
