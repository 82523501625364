import React from 'react'
import { ItemsFiltersPanel } from './ItemsFiltersPanel'
import { InboxFiltersView } from './InboxFiltersView'

export const InboxFiltersPanel = ({ ...rest }) => {
  return (
    <ItemsFiltersPanel {...rest}>
      <InboxFiltersView />
    </ItemsFiltersPanel>
  )
}
