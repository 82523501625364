import React from 'react'
import styled from 'styled-components'
import { Icon } from '@fluentui/react/lib/Icon'
import { GRAPH_RESOURCE_TYPE } from '../../common/src/helpers/graph/graphResourceBase'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Text = styled.h3`
  margin: 0 0 0 8px;
  font-weight: lighter;
`

const StyledIcon = styled(Icon)`
  font-size: 30px;
  color: ${p => p.color};
`

const typeToText = {
  [GRAPH_RESOURCE_TYPE.MESSAGE]: 'Message',
  [GRAPH_RESOURCE_TYPE.EVENT]: 'Calendar Event',
  [GRAPH_RESOURCE_TYPE.FILE]: 'OneDrive File',
  [GRAPH_RESOURCE_TYPE.PLANNER_TASK]: 'Planner Task',
  [GRAPH_RESOURCE_TYPE.OUTLOOK_TASK]: 'Outlook Task',
}

export const Header = ({ resource }) => {
  const { icon, type, iconColor } = resource
  const title = typeToText[type]
  return (
    <Container>
      <StyledIcon iconName={icon} color={iconColor} />
      <Text>{title}</Text>
    </Container>
  )
}
