import { Sections } from '@/views/filters/ItemFiltersView.Common'
import { ItemsFiltersView } from '@/views/filters/ItemsFiltersView'
import { FILTER_REDUCER_KEYS } from '@/common/src/reducers/filtersKeys'
import { ItemsFiltersPanel } from '@/views/filters/ItemsFiltersPanel'

const hideSections = [Sections.Dates, Sections.Sort, Sections.CustomFilters, Sections.StarredFirst]

export const ProjectCalendarFiltersPanel = ({ ...rest }) => {
  return (
    <ItemsFiltersPanel {...rest}>
      <ItemsFiltersView filtersType={FILTER_REDUCER_KEYS.PROJECT_CALENDAR} hideSections={hideSections} />
    </ItemsFiltersPanel>
  )
}
