import { getRandomInt } from './random'

export const getRandomIndexFromArray = <T>(array: T[]): number | null => {
  if (!array) {
    return null
  }
  const length = array.length
  if (length === 0) {
    return null
  }
  return getRandomInt(length - 1)
}

export const getRandomValueFromArray = <T>(array: T[]): T | null => {
  const randomIndex = getRandomIndexFromArray(array)
  if (randomIndex === null || randomIndex === undefined) {
    return null
  }
  return array[randomIndex]
}

export const sortBy = <T>(arr: T[], key: keyof T | ((obj: T) => any), order: 'asc' | 'desc' = 'asc'): T[] => {
  return arr.slice().sort((a, b) => {
    const valueA = typeof key === 'function' ? key(a) : a[key]
    const valueB = typeof key === 'function' ? key(b) : b[key]

    if (valueA < valueB) return order === 'asc' ? -1 : 1
    if (valueA > valueB) return order === 'asc' ? 1 : -1
    return 0
  })
}
