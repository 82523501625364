import { useTranslation } from 'react-i18next'
import { Button } from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { PanelRightExpand } from '../BundledIcons'

export type RightPanelComponentProps = {
  opener: () => void
}

export const CollapsedRightPanel = ({ opener }: RightPanelComponentProps) => {
  const { t } = useTranslation()

  return (
    <div className="box-border flex h-full w-full flex-col items-center py-1">
      <Tooltip content={t('right_panel.open_tooltip')} relationship="label">
        <Button size="large" appearance="subtle" onClick={opener} icon={<PanelRightExpand />} />
      </Tooltip>
    </div>
  )
}
