import React from 'react'

export const CUSTOM_ICONS = {
  QUADRANTS: 'PM_QUADRANTS',
}

export const customIcons = {
  [CUSTOM_ICONS.QUADRANTS]: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0,0,512,512" style={{ flex: '1' }}>
      <g>
        <path className="st0" d="M211,30v181H30V30H211 M241,0h-30H30H0v30v181v30h30h181h30v-30V30V0L241,0z" />
      </g>
      <g>
        <path className="st0" d="M482,30v181H301V30H482 M512,0h-30H301h-30v30v181v30h30h181h30v-30V30V0L512,0z" />
      </g>
      <g>
        <path className="st0" d="M211,301v181H30V301H211 M241,271h-30H30H0v30v181v30h30h181h30v-30V301V271L241,271z" />
      </g>
      <g>
        <path
          className="st0"
          d="M482,301v181H301V301H482 M512,271h-30H301h-30v30v181v30h30h181h30v-30V301V271L512,271z"
        />
      </g>
    </svg>
  ),
}
