import { Record } from 'immutable'
import { dateToTimestampInSeconds, toDate } from '../dateHelper'

export const GraphResourceBase = Record({
  __GRAPH_RESOURCE: true,
  name: '',
  icon: '',
  iconColor: '',
  link: '',
  id: '',
  type: '',
  descriptionText: '',
  creationDate: 0,
  lastModifiedTimestamp: 0,
  resource: {},
})

export const createGraphResourceBase = (resource, customData = {}) => {
  const commonResourceData = {
    resource,
    creationDate: dateToTimestampInSeconds(new Date(resource.createdDateTime)),
    lastModifiedTimestamp: dateToTimestampInSeconds(new Date(resource.lastModifiedDateTime)),
    id: resource.id,
  }
  return GraphResourceBase({ ...commonResourceData, ...customData })
}

export const GRAPH_RESOURCE_TYPE = {
  RESET_GRAPH: 'resetGraph',
  MESSAGE: 'message',
  EVENT: 'event',
  FILE: 'file',
  PLANNER_TASK: 'plannerTask',
  OUTLOOK_TASK: 'outlookTask',
}

export const plannerWebLink = 'https://tasks.office.com/${ domain }/en-us/Home/Task/${ id }?Type=TaskLink'

export const helper = {}

helper.GraphResourceBase = GraphResourceBase
helper.GRAPH_RESOURCE_TYPE = GRAPH_RESOURCE_TYPE

helper.isGraphResource = obj => obj.__GRAPH_RESOURCE === true

helper.getName = resource => resource.name
helper.getLastModifiedTimestampDate = resource => toDate(resource.lastModifiedTimestamp)
helper.getLastModifiedTimestamp = resource => resource.lastModifiedTimestamp
