import React from 'react'
import { SPECIAL_TABLE_CELL } from './index'
import { Button, TableCell, TableRow } from '@fluentui/react-components'
import { openPurchaseExperienceAction } from '../../utils/teams'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { SERVER_URLS } from '../../common/src/constants'
import { simulateLinkClick } from '../../common/src/utils'
import { useShouldDisplayUpgrade } from '@/hooks/userHooks'

export const SearchLookbackBannerForTable = n => {
  return {
    type: 'SEARCH_LOOKBACK_BANNER',
    meta: SPECIAL_TABLE_CELL,
    render: ({ row, ...rowProps }) => (
      <TableRow {...rowProps}>
        <TableCell colSpan={row.getVisibleCells().length}>
          <BannerComponent n={n} />
        </TableCell>
      </TableRow>
    ),
  }
}

const BannerComponent_ = ({ n }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const shouldDisplayUpgrade = useShouldDisplayUpgrade()
  const onClickUpgrade = () => {
    dispatch(openPurchaseExperienceAction(EVENT_EXTRA.TRIAL_UPGRADE_PRESSED.MODE.SEARCH_LOOKBACK))
  }
  const onClickAccount = () => {
    simulateLinkClick(SERVER_URLS.MANAGE_ACCOUNT, true)
  }
  const upgradeButton = (
    <Button appearance="primary" onClick={onClickUpgrade}>
      {t('general.upgrade')}
    </Button>
  )
  const accountButton = (
    <Button appearance="primary" onClick={onClickAccount}>
      {t('general.account')}
    </Button>
  )
  return (
    <div className="flex items-center gap-2">
      <p>{t('search_loopback.message', { count: n })}</p>
      {shouldDisplayUpgrade ? upgradeButton : accountButton}
    </div>
  )
}

const BannerComponent = React.memo(BannerComponent_)
