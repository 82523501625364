import { API_EVENT, isPMApiDetailedAction, parseApiActionType } from '../helpers/reducerHelper'
import { PM_API_RESOURCES } from '../constants'
import { itemHelper, sharingRequestHelper, stateHelper, userHelper } from '../helpers'
import { ACTIONS as ITEMS_ACTIONS, localMarkItemsAsReadFromIDs, markItemsAsRead } from '../actions/itemActions'
import { markItemsAsReadInAPI } from '../actions/commentsAPI'
import { nDaysAgo } from '../helpers/time'
import { ACTIONS as WS_ACTIONS } from '../websocket/wsActions'
import { resourceURIParser } from '../helpers/URLHelper'

// Add userUri to modify project owners
const metaAddingUserUriForSharingRequests = (state, email, meta) => {
  const user = stateHelper.getUserWithEmail(state, email)
  const userUri = userHelper.getURI(user)
  if (!userUri) {
    return meta
  }
  return { ...meta, userUri }
}

export const customApiMiddleware = store => next => action => {
  const { type, payload, meta } = action
  const apiAction = parseApiActionType(type)
  if (isPMApiDetailedAction(apiAction)) {
    const { resource, event } = apiAction
    if (event === API_EVENT.START) {
      switch (resource) {
        case PM_API_RESOURCES.SHARING_REQUEST_PUT:
        case PM_API_RESOURCES.SHARING_REQUEST_POST:
          {
            const { request } = meta
            const state = store.getState()
            const targetUsername = sharingRequestHelper.getTargetUsername(request)
            action.meta = metaAddingUserUriForSharingRequests(state, targetUsername, meta)
          }
          break
        default:
          break
      }
    } else if (event === API_EVENT.SUCCESS) {
      switch (resource) {
        case PM_API_RESOURCES.SHARING_REQUEST_PUT:
        case PM_API_RESOURCES.SHARING_REQUEST_POST:
          {
            const requestJS = payload
            // Add user uri in meta
            const state = store.getState()
            const { target_username: targetUsername } = requestJS
            action.meta = metaAddingUserUriForSharingRequests(state, targetUsername, meta)
          }
          break
        default:
          break
      }
    } else if (API_EVENT.ERROR === event) {
      /* TODO */
    }
  } else {
    switch (type) {
      case ITEMS_ACTIONS.MARK_ITEMS_AS_READ:
        {
          store.dispatch(markItemsAsReadInAPI(payload))
        }
        break
      case ITEMS_ACTIONS.CLEAR_COMPLETED_ATTENTION_NEEDED_ITEMS:
        {
          const state = store.getState()
          const idSet = stateHelper.getAttentionNeededIdSetInCurrentSession(state)
          const itemSet = stateHelper.getItemsFromIDs(state, idSet)
          const filteredSet = itemSet.filter(itemHelper.isCompleted)
          store.dispatch(markItemsAsRead(filteredSet))
        }
        break
      case ITEMS_ACTIONS.CLEAR_ALL_ATTENTION_NEEDED_ITEMS:
        {
          const state = store.getState()
          const idSet = stateHelper.getAttentionNeededIdSetInCurrentSession(state)
          const itemSet = stateHelper.getItemsFromIDs(state, idSet)
          store.dispatch(markItemsAsRead(itemSet))
        }
        break
      case ITEMS_ACTIONS.CLEAR_OLDER_ALERTS:
        {
          const state = store.getState()
          const idSet = stateHelper.getAttentionNeededIdSetInCurrentSession(state)
          const itemSet = stateHelper.getItemsFromIDs(state, idSet)
          const filteredSet = itemSet.filter(item => {
            const lastAttentionDate = new Date(itemHelper.getLastAttention(item) * 1000)
            return lastAttentionDate < nDaysAgo(payload.days)
          })
          store.dispatch(markItemsAsRead(filteredSet))
        }
        break
      case WS_ACTIONS.COMMENT_RECEIVED:
        {
          const comment = payload
          const state = store.getState()
          const me = stateHelper.getMe(state)
          if (!me || !comment) break
          const meId = userHelper.getID(me)
          const authorData = resourceURIParser(comment.author)
          const authorId = authorData && authorData.id
          const isMine = authorId === meId
          const isOwnReadReceipt = comment.is_read && comment.is_automatic && isMine
          if (!isOwnReadReceipt) break
          const itemId = (resourceURIParser(comment.item) || {}).id
          if (!itemId) break
          store.dispatch(localMarkItemsAsReadFromIDs([itemId]))
        }
        break
      default:
        break
    }
  }
  return next(action)
}
