const mainPMParameters = {
  limit: 100,
  offset: 0,
}

/**
 * Wrapper for paginated requests
 * @param actionCreator Should be a function that returns a redux-api-middleware action. Must receive a queryParams as first parameter (as an object)
 * @param [defaultParameters] Default query params for request.
 * @returns {Function} Action creator that accepts queryParams. It manages pagination
 */
export const createPaginatedAPIAction =
  (actionCreator, defaultParameters = {}) =>
  (queryParams = {}, ...args) =>
  async dispatch => {
    let hasMore = true,
      response
    queryParams = { ...mainPMParameters, ...defaultParameters, ...queryParams }
    while (hasMore) {
      response = await dispatch(actionCreator(queryParams, ...args))
      if (!response) {
        return
      }
      const { meta, error } = response && response.payload
      if (error || !meta) {
        return response
      }
      hasMore = !!meta.next
      queryParams.offset += queryParams.limit
    }
    return response
  }
