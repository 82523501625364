import { useDispatch } from 'react-redux'
import { List } from 'immutable'
import { itemHelper, projectHelper } from '../../common/src/helpers'
import { addTag, removeTag } from '../../common/src/actions/tagsAPI'
import { SimpleModal } from './SimpleModal'
import { SuggestionsTagPicker } from '../pickers/SuggestionsTagPicker'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { useMergeState } from '../../common/src/hooks/enhancedHooks'
import { useTranslation } from 'react-i18next'

const TagsSelectorModal_ = ({ object, readOnly = false, type, onDismiss, ...rest }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [state, setState] = useMergeState({
    loading: false,
    errorMessage: null,
  })

  const onInsertTagFilter = tagName => {
    setState({
      loading: true,
      errorMessage: null,
    })
    return dispatch(addTag({ tagName, object })).then(onSuccessAddingTag)
  }

  const onRemoveTagFilter = tagName => {
    setState({
      loading: true,
      errorMessage: null,
    })
    return dispatch(removeTag({ tagName, object })).then(onSuccessRemovingTag)
  }

  const onSuccessAddingTag = ev => {
    const { error } = ev
    const newMessageError = error ? t('item.tag.error_adding') : null
    dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.ADD_TAG))
    setState({
      loading: false,
      errorMessage: newMessageError,
    })
  }

  const onSuccessRemovingTag = ev => {
    const { error } = ev
    const newMessageError = error ? t('item.tag.error_removing') : null
    dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.REMOVE_TAG))
    setState({
      loading: false,
      errorMessage: newMessageError,
    })
  }

  const objectTags =
    (projectHelper.isProject(object) ? projectHelper.getTags(object) : itemHelper.getTags(object)) || List()
  const selectedTags = objectTags.map(tag => tag.get('name')).toArray()
  const { loading, errorMessage } = state
  const title = t(`item.tag.${type}`)
  return (
    <SimpleModal
      title={title}
      subtitle={t('item.tag.add_tags')}
      loading={loading}
      errorMessage={errorMessage}
      onDismiss={onDismiss}
      {...rest}
    >
      <SuggestionsTagPicker
        type={type}
        onAddTag={onInsertTagFilter}
        onRemoveTag={onRemoveTagFilter}
        selectedTags={selectedTags}
        disabled={readOnly}
      />
    </SimpleModal>
  )
}

export const ItemTagsSelectorModal = props => <TagsSelectorModal_ type="item" {...props} />

export const ProjectTagsSelectorModal = props => <TagsSelectorModal_ type="project" {...props} />
