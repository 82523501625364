import { fromJS } from 'immutable'
import {
  API_EVENT,
  isPMApiDetailedAction,
  parseApiActionType,
  PERSIST_REHYDRATE_ACTION_TYPE,
} from '../helpers/reducerHelper'
import { PM_API_RESOURCES } from '../constants'
import { sharingRequestHelper } from '../helpers/'
import { ACTIONS as WS_ACTIONS } from '../websocket/wsActions'
import { GENERIC_ACTION_TYPE } from '../actions/genericActions'
import { SHARING_REQUESTS_KEYS } from './sharingRequestsKeys'

const base = fromJS({
  [SHARING_REQUESTS_KEYS.byProjectID]: {},
})

const applyRequests = (state, requests) => {
  return state.withMutations(_st => {
    requests.forEach(request => {
      const projectID = sharingRequestHelper.getProjectID(request)
      const targetUsername = sharingRequestHelper.getTargetUsername(request)
      _st.setIn([SHARING_REQUESTS_KEYS.byProjectID, projectID, targetUsername], request)
    })
    return _st
  })
}

const applyRequestsByProjectID = (state, projectID, requests) => {
  return state.withMutations(_st => {
    _st.deleteIn([SHARING_REQUESTS_KEYS.byProjectID, projectID])
    requests.forEach(request => {
      const targetUsername = sharingRequestHelper.getTargetUsername(request)
      _st.setIn([SHARING_REQUESTS_KEYS.byProjectID, projectID, targetUsername], request)
    })
    return _st
  })
}

const applyRequest = (state, request) => {
  const targetUsername = sharingRequestHelper.getTargetUsername(request)
  if (!targetUsername) {
    return state
  }
  const status = sharingRequestHelper.getStatus(request)
  const projectID = sharingRequestHelper.getProjectID(request)
  if (!projectID) {
    return state
  }
  if (status === sharingRequestHelper.STATUS.INIT) {
    return state.setIn([SHARING_REQUESTS_KEYS.byProjectID, projectID, targetUsername], request)
  } else {
    return state.deleteIn([SHARING_REQUESTS_KEYS.byProjectID, projectID, targetUsername])
  }
}

export const sharingRequests = (state = base, action) => {
  const { type, payload, meta } = action
  const apiAction = parseApiActionType(type)
  if (isPMApiDetailedAction(apiAction)) {
    const { resource, event } = apiAction
    if (event === API_EVENT.START) {
      switch (resource) {
        // It improves use's feedback but
        // if the request is automatically accepted
        // the owner had to be added to the project
        // but the request has not enough info to build
        // the user's resource uri.
        case PM_API_RESOURCES.SHARING_REQUEST_PUT:
        case PM_API_RESOURCES.SHARING_REQUEST_POST:
          {
            const { request } = meta
            state = applyRequest(state, request)
          }
          break
        default:
          break
      }
    } else if (event === API_EVENT.SUCCESS) {
      switch (resource) {
        case PM_API_RESOURCES.ALL_SHARING_REQUESTS:
          {
            const { projectID, userID } = meta
            const requests = fromJS(payload.objects)
            if (!requests) return state
            if (projectID) {
              state = applyRequestsByProjectID(state, projectID, requests)
            } else if (userID) {
              // Remove old requests by this user
              state = state.withMutations(_st => {
                const newTargetUsernames = requests.map(request => sharingRequestHelper.getTargetUsername(request))
                const oldTargetRequests = _st
                  .get(SHARING_REQUESTS_KEYS.byProjectID)
                  .toList()
                  .flatMap(m => m.toList())
                  .filter(r => {
                    const target = sharingRequestHelper.getTargetUsername(r)
                    return newTargetUsernames.some(nTarget => nTarget === target)
                  })
                oldTargetRequests.forEach(request => {
                  const projectID = sharingRequestHelper.getProjectID(request)
                  const targetUsername = sharingRequestHelper.getTargetUsername(request)
                  _st.deleteIn([SHARING_REQUESTS_KEYS.byProjectID, projectID, targetUsername])
                })
                return _st
              })
              state = applyRequests(state, requests)
            } else {
              state = applyRequests(state, requests)
            }
          }
          break
        case PM_API_RESOURCES.SHARING_REQUEST:
        case PM_API_RESOURCES.SHARING_REQUEST_PUT:
        case PM_API_RESOURCES.SHARING_REQUEST_POST:
          {
            const request = fromJS(payload)
            state = applyRequest(state, request)
          }
          break
      }
    } else if (API_EVENT.ERROR === event) {
      //handle error
    }
  } else {
    switch (type) {
      case PERSIST_REHYDRATE_ACTION_TYPE:
        {
          if (payload.sharingRequests) {
            state = state.merge(payload.sharingRequests)
          }
        }
        break
      case GENERIC_ACTION_TYPE.CLEAR_ALL:
        {
          state = base
        }
        break
      case WS_ACTIONS.SHARING_REQUEST_RECEIVED:
        {
          const request = fromJS(payload)
          state = applyRequest(state, request)
        }
        break
    }
  }

  return state
}
