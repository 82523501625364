import { CounterBadge } from '@fluentui/react-components'

export type IconWithBubbleProps = {
  children: React.ReactNode
  count: number
}

export const IconWithCount = ({ children, count }: IconWithBubbleProps) => {
  return (
    <div className="relative h-full w-full">
      {children}
      <CounterBadge count={count} size="small" className="!absolute !-right-1 !-top-1" />
    </div>
  )
}
