import { RSAA } from 'redux-api-middleware'
import { PM_API_RESOURCES, PM_API_URLS } from '../constants'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'
import { appendQueryParamsToURL, extendPMHeaders, getCredentialsConfig } from '../helpers/requestHelper'

export const registerGcmNotifications = ({ token: registration_id, deviceId: device_id, name }) => {
  const url = PM_API_URLS.DEVICE_GCM
  const parameters = {
    registration_id,
    device_id,
    name,
  }
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(url),
      credentials: getCredentialsConfig(),
      method: 'POST',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.DEVICE_GCM_POST),
      headers: extendPMHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      }),
      body: JSON.stringify(parameters),
    },
  }
}

export const registerApnsNotifications = ({ token: registration_id, deviceId: device_id, name }) => {
  const url = PM_API_URLS.DEVICE_APNS
  const parameters = {
    registration_id,
    device_id,
    name,
  }
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(url),
      credentials: getCredentialsConfig(),
      method: 'POST',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.DEVICE_APNS_POST),
      headers: extendPMHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      }),
      body: JSON.stringify(parameters),
    },
  }
}
