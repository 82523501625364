import { useTranslatedPlaceholders } from '../../hooks/translationHooks'
import type { ValueOf } from '../../types'
import { HOME_DROPDOWN_OPTIONS } from '../../actions/uiActions'
import { Eye, Person, TaskListLtr } from '../../components/BundledIcons'

export const usePlaceholders = () => {
  return useTranslatedPlaceholders('agenda.placeholder', 3)
}

export const BASIC_ITEM_LIST_TYPES = {
  HEADER: 'header',
  SEE_MORE: 'see_more',
} as const

export const DROPDOWN_ICONS = {
  [HOME_DROPDOWN_OPTIONS.OWNED]: Person,
  [HOME_DROPDOWN_OPTIONS.FOLLOWED]: Eye,
  [HOME_DROPDOWN_OPTIONS.EVERYTHING]: TaskListLtr,
} as const

export const SECTIONS = {
  ALERTS: 'alerts',
  RECENT: 'recent',
  AGENDA: 'agenda',
  MY_ITEMS: 'my_items',
} as const

export type Section = ValueOf<typeof SECTIONS>
