import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'

export const useComponentDidMount = f => {
  // eslint-disable-next-line react-compiler/react-compiler
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(f, [])
}
export const useMountDebugger = name => {
  useEffect(() => {
    console.log('mount ' + name)
    return () => {
      console.log('unmount ' + name)
    }
  }, [name])
}
export const useMergeState = initialState => {
  const [state, setState] = useState(initialState)
  const setMergedState = useCallback(
    value => {
      setState(prevState => {
        const newState = _.isFunction(value) ? value(prevState) : value
        return { ...prevState, ...newState }
      })
    },
    [setState]
  )
  return [state, setMergedState]
}
export const usePrevious = value => {
  const [current, setCurrent] = useState(value)
  const [previous, setPrevious] = useState(null)

  if (value !== current) {
    setPrevious(current)
    setCurrent(value)
  }

  return previous
}
