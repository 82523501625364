import { getEmailsJoinByComma } from './usersUtils'

export const createMailToLink = (to = '', subject = '', body = '') => {
  const encodedSubject = subject || encodeURIComponent(subject)
  const encodedBody = body || encodeURIComponent(body)
  return `mailto:${to}?subject=${encodedSubject}&body=${encodedBody}`
}

export const createMailToLinkForUsers = ({ users, subject, body }) => {
  const to = users && users.length > 0 ? getEmailsJoinByComma(users) : ''
  return createMailToLink(to, subject, body)
}
