import { fromJS } from 'immutable'
import _ from 'lodash'
import {
  API_EVENT,
  isPMApiDetailedAction,
  parseApiActionType,
  PERSIST_REHYDRATE_ACTION_TYPE,
} from '../helpers/reducerHelper'
import { PROJECT_TEMPLATE_REDUCER_KEYS as KEYS } from './projectTemplatesReducerKeys'
import { GENERIC_ACTION_TYPE } from '../actions/genericActions'
import { PM_API_RESOURCES } from '../constants'

export const base = fromJS({
  [KEYS.CUSTOM]: {},
  [KEYS.PUBLIC]: {},
  [KEYS.PUBLIC_CATEGORIES]: [],
})

const storeProjectTemplateForKey = (state, key = KEYS.CUSTOM, template) => {
  const { id } = template
  return state.setIn([key, id], fromJS(template))
}

const storeProjectTemplatesForKey = (state, key = KEYS.CUSTOM, templates) => {
  return state.withMutations(_st => {
    _.each(templates, t => {
      storeProjectTemplateForKey(_st, key, t)
    })
    return _st
  })
}

export const projectTemplates = (state = base, action) => {
  const { type, payload, meta } = action
  const apiAction = parseApiActionType(type)
  if (isPMApiDetailedAction(apiAction)) {
    const { resource, event } = apiAction
    if (event === API_EVENT.SUCCESS) {
      switch (resource) {
        case PM_API_RESOURCES.CUSTOM_PROJECT_TEMPLATES:
          {
            state = storeProjectTemplatesForKey(state, KEYS.CUSTOM, payload.objects)
          }
          break
        case PM_API_RESOURCES.CUSTOM_PROJECT_TEMPLATE:
          {
            if (_.get(meta, 'method') === 'DELETE') {
              state = state.deleteIn([KEYS.CUSTOM, meta.id])
            } else {
              state = storeProjectTemplateForKey(state, KEYS.CUSTOM, payload)
            }
          }
          break
        case PM_API_RESOURCES.PUBLIC_PROJECT_TEMPLATE_CATEGORIES:
          {
            state = state.set(KEYS.PUBLIC_CATEGORIES, fromJS(payload))
          }
          break
        case PM_API_RESOURCES.PUBLIC_PROJECT_TEMPLATES:
          {
            state = storeProjectTemplatesForKey(state, KEYS.PUBLIC, payload.objects)
          }
          break
        case PM_API_RESOURCES.PUBLIC_PROJECT_TEMPLATE:
          {
            state = storeProjectTemplateForKey(state, KEYS.PUBLIC, payload)
          }
          break
      }
    }
  } else {
    switch (type) {
      case PERSIST_REHYDRATE_ACTION_TYPE:
        {
          const projectTemplates = payload.projectTemplates
          if (projectTemplates) {
            state = state.merge(projectTemplates)
          }
        }
        break
      case GENERIC_ACTION_TYPE.CLEAR_ALL:
        {
          state = base
        }
        break
      default:
        break
    }
  }
  return state
}
