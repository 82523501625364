import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Label } from '../text/Text'

const Container = styled.div``

const Input = styled.input`
  height: 32px;
  box-sizing: border-box;
`

// Do not work in IE and Safari (Desktop)
export const TimeInput = ({ label, value, onChange, disabled = false, ...rest }) => {
  const _onChange = useCallback(
    event => {
      const newDate = event.target.valueAsDate
      if (!newDate) {
        return
      }
      const utcHours = newDate.getUTCHours()
      const utcMinutes = newDate.getUTCMinutes()
      onChange(utcHours, utcMinutes)
    },
    [onChange]
  )

  return (
    <Container {...rest}>
      {label && <Label>{label}</Label>}
      <Input type={'time'} placeholder={'HH:mm'} required value={value} onChange={_onChange} disabled={disabled} />
    </Container>
  )
}
