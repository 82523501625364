import React from 'react'
import { cn } from '@appfluence/classnames'
import { TipTapNotes } from '../../../components/notes/TipTapNotes/TipTapNotes'

export type ItemCreationNotesProps = {
  onChangeNotes: (value: string) => void
  placeholder?: string
  value?: string
  className?: string
  additionalToolbarButtons?: JSX.Element
}

export const ItemCreationNotes = ({
  onChangeNotes,
  placeholder,
  value,
  className,
  additionalToolbarButtons,
}: ItemCreationNotesProps) => {
  return (
    <TipTapNotes
      onUpdate={onChangeNotes}
      placeholder={placeholder}
      value={value}
      containerClassName={cn('h-40 border border-pm-neutral-secondary border-solid rounded-sm', className)}
      toolbarClassName="border-0 border-b border-b-pm-neutral-secondary border-solid p-1"
      contentClassName="overflow-y-auto outline-pm-theme-primary outline-1 outline-offset-[-1px] focus-within:outline"
      editorClassName="px-2 py-1.5 focus:outline-none min-h-full box-border"
      additionalToolbarButtons={additionalToolbarButtons}
      data-testid="CreateItemModal_NotesEditor"
    />
  )
}
