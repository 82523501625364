import { ProjectTemplateList } from './ProjectTemplateList'
import { PROJECT_TEMPLATE_TYPE } from '../ProjectTemplateType'
import { useCustomProjectTemplates } from '@/queries/projectTemplates'

const type = PROJECT_TEMPLATE_TYPE.CUSTOM

export const CustomProjectTemplateList = ({ ...rest }) => {
  const { data, isFetching: loading } = useCustomProjectTemplates()
  const templates = data?.sort((a, b) => a.name.localeCompare(b.name)) || []

  return <ProjectTemplateList type={type} templates={templates} loading={loading} {...rest} />
}
