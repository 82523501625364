import _ from 'lodash'
import { useCallback, useDeferredValue, useEffect, useId, useMemo, useState, useContext } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { itemHelper, stateHelper } from '../common/src/helpers'
import { useCreateNewItem } from '../common/src/hooks/itemHooks'
import { uriDataCreator, URLParser } from '../common/src/helpers/URLHelper'
import { getItemLink, PM_API_RESOURCE_TYPE } from '../common/src/constants'
import { linkSubject } from '../reactions/linkSubject'
import { Button, ToastTrigger, useToastController } from '@fluentui/react-components'
import { OnboardingStepKeys } from '../actions/onboardingActions'
import { useSendStepEventIfNeeded } from './onboardingHooks'
import {
  getItem,
  getMakeItemPrivate,
  getMakeItemPublic,
  inviteToItemWithId,
  uninviteToItemWithId,
} from '../common/src/actions/itemsAPI'
import { useTranslation } from 'react-i18next'
import { useMobile } from '../helpers/responsiveHelpers'
import { isSingleItem } from '../helpers/routeHelper'
import { useHistory, useRouteMatch } from 'react-router'
import { AMPLITUDE_ACTION_TYPES, EVENT_EXTRA, dispatchEvent } from '../common/src/eventTracking/amplitudeEvents'
import { copyToClipboard } from '../common/src/helpers/clipboardUtils'
import { useShowToastDidCopyLinkToClipboard } from './clipboardHooks'
import { useCreateItemModal } from '@/hooks/useCreateItemModal'
import { recentlyVisitedView as init } from '../actions/viewInitializationActions'
import { getRecentlyVisitedItemsIdSet, getShowedCompletedItemHelp } from '../selectors/uiSelectors'
import { API_EVENT, parseApiActionType } from '../common/src/helpers/reducerHelper'
import { showCompletedItemHelp } from '../actions/uiActions'
import { fromJS } from 'immutable'
import { getItemsFilters } from '../common/src/selectors/filtersSelectors'
import { createItemFilter } from '../common/src/helpers/itemsFiltersHelper'
import { FILTER_REDUCER_KEYS } from '../common/src/reducers/filtersKeys'
import { FluentToast } from '../components/toast/FluentToast'
import { AlertModalContext } from '@/contexts'
import { useSelectedItemId } from './PMHooks'
import { useMutateItem } from '@/queries/items'
import { useRequestReview } from '@/hooks/useRequestReview'
import { getFastProject } from '@/helpers/queryParamsHelper'
import { hideConfirmationDeleteItemAtom } from '@/atoms'
import { useAtom } from 'jotai'

export const useCreateNewItemCustom = () => {
  const { dispatchToast, updateToast } = useToastController()
  const createNewItem = useCreateNewItem()
  const sendStepEventIfNeeded = useSendStepEventIfNeeded()
  const { t } = useTranslation()
  const toastId = useId()

  const openItemWithID = useCallback(serverID => {
    const urlData = uriDataCreator(1, PM_API_RESOURCE_TYPE.ITEM, serverID)
    linkSubject.next({ urlData })
  }, [])

  return useCallback(
    async ({ open = true, notify = true, delayToOpen = 0, ...rest }) => {
      if (notify) {
        dispatchToast(<FluentToast>{t('item.creating')}</FluentToast>, {
          toastId,
          intent: 'info',
          timeout: -1,
        })
      }

      const response = await createNewItem(rest)
      const { error, payload } = response
      if (!error) {
        // Open item
        if (open) {
          const itemID = itemHelper.getId(payload)
          if (delayToOpen > 0) {
            setTimeout(() => {
              openItemWithID(itemID)
            }, delayToOpen)
          } else {
            openItemWithID(itemID)
          }
        }

        // Send onboarding step event
        sendStepEventIfNeeded(OnboardingStepKeys.CREATE_ITEMS)
      }

      if (notify) {
        updateToast({
          toastId,
          content: (
            <FluentToast>{error ? t('item.created_unsuccessfully') : t('item.created_successfully')}</FluentToast>
          ),
          intent: error ? 'error' : 'success',
          timeout: 5000,
        })
      }

      return response
    },
    [createNewItem, dispatchToast, openItemWithID, sendStepEventIfNeeded, t, toastId, updateToast]
  )
}

export const useMakeItemPublic = itemId => {
  const dispatch = useDispatch()
  const { t } = useTranslation(undefined, { keyPrefix: 'item_detail.make_item_public_toast' })
  const toastId = useId()

  const { dispatchToast, updateToast } = useToastController()
  return useCallback(async () => {
    const req = dispatch(getMakeItemPublic(itemId))
    dispatchToast(<FluentToast>{t('wip')}</FluentToast>, {
      toastId,
      intent: 'info',
      timeout: -1,
    })
    const { error: makePublicError } = await req
    const newItemResponse = await dispatch(getItem(itemId, { returnpubliclink: 1 }))
    const error = makePublicError || newItemResponse.error
    updateToast({
      toastId,
      content: <FluentToast>{error ? t('error') : t('success')}</FluentToast>,
      intent: error ? 'error' : 'success',
      timeout: 5000,
    })
    if (error) return
    const publicLink = newItemResponse.payload?.[itemHelper.KEYS.PUBLIC_LINK]
    copyToClipboard(publicLink)
  }, [dispatch, dispatchToast, itemId, t, toastId, updateToast])
}

export const useMakeItemPrivate = itemId => {
  const dispatch = useDispatch()
  const { t } = useTranslation(undefined, { keyPrefix: 'item_detail.make_item_private_toast' })
  const toastId = useId()

  const { dispatchToast, updateToast } = useToastController()
  return useCallback(async () => {
    const req = dispatch(getMakeItemPrivate(itemId))
    dispatchToast(<FluentToast>{t('wip')}</FluentToast>, {
      toastId,
      intent: 'info',
      timeout: -1,
    })
    const { error } = await req
    updateToast({
      toastId,
      content: <FluentToast>{error ? t('error') : t('success')}</FluentToast>,
      intent: error ? 'error' : 'success',
      timeout: 5000,
    })
    dispatch(getItem(itemId, { returnpubliclink: 1 }))
  }, [dispatch, dispatchToast, itemId, t, toastId, updateToast])
}

export const useAdjustPathnameOnDeletedItem = () => {
  const isMobile = useMobile()
  const match = useRouteMatch()
  const history = useHistory()
  return useCallback(
    itemID => {
      if (isMobile) {
        history.goBack()
      } else if (itemID && !isSingleItem(match.path)) {
        const { location } = history
        const pathname = location.pathname
        const pathItem = `/${itemID}`
        const newPathname = _.replace(pathname, pathItem, '')
        history.replace(newPathname)
      }
    },
    [history, isMobile, match.path]
  )
}

export const useCopyLink = (item, from) => {
  const isAppfluence = useSelector(stateHelper.isAppfluenceCurrentUser)
  const dispatch = useDispatch()
  const itemID = itemHelper.getId(item)
  const showToastDidCopyLinkToClipboard = useShowToastDidCopyLinkToClipboard()
  return useCallback(
    evt => {
      const link = getItemLink(itemID)
      dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.COPY_ITEM_LINK, { from }))
      if (evt.shiftKey && isAppfluence) {
        const name = itemHelper.getName(item)
        //Copy  using out changelog format
        copyToClipboard(`- (Fix) ${name}.\n  [Item](${link})`)
      } else {
        copyToClipboard(link)
      }
      showToastDidCopyLinkToClipboard()
    },
    [itemID, dispatch, from, isAppfluence, showToastDidCopyLinkToClipboard, item]
  )
}

export const useCopyPublicLink = publicLink => {
  const showToastDidCopyLinkToClipboard = useShowToastDidCopyLinkToClipboard()
  return () => {
    copyToClipboard(publicLink)
    showToastDidCopyLinkToClipboard()
  }
}

export const useMarkCompleted = (item, from) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const store = useStore()
  const showedCompletedItemHelp = useSelector(getShowedCompletedItemHelp)
  const itemIsDone = itemHelper.isCompleted(item)
  const { dispatchToast } = useToastController()
  const { t } = useTranslation()
  const { mutate: mutateItem, mutateAsync: asyncMutateItem } = useMutateItem(itemHelper.getId(item))
  const requestReview = useRequestReview()

  const undoModification = useCallback(
    oldPercentage => {
      const newItem = stateHelper.getItem(store.getState(), itemHelper.getId(item))
      mutateItem(newItem.set(itemHelper.KEYS.COMPLETION_PERCENTAGE, oldPercentage))
    },
    [item, mutateItem, store]
  )

  const dispatchToastUndo = useCallback(
    oldPercentage => {
      dispatchToast(
        <FluentToast
          footer={
            <ToastTrigger>
              <Button appearance="primary" onClick={() => undoModification(oldPercentage)}>
                {t('general.undo')}
              </Button>
            </ToastTrigger>
          }
        >
          {t('item.progress.marked_done_toast')}
        </FluentToast>,
        {
          intent: 'success',
        }
      )
    },
    [dispatchToast, t, undoModification]
  )

  const markCompleted = useMemo(
    () =>
      _.debounce(
        async (newPercentage = 100) => {
          const oldPercentage = itemHelper.getCompletionPercentage(item)
          if (oldPercentage === newPercentage) {
            return
          }
          setLoading(true)
          const newState = newPercentage === 100 ? itemHelper.STATE.DONE : itemHelper.STATE.UNFINISHED
          const modifiedItem = item.withMutations(ctx => {
            ctx.set(itemHelper.KEYS.STATE, newState)
            ctx.set(itemHelper.KEYS.COMPLETION_PERCENTAGE, newPercentage)
          })
          const response = await asyncMutateItem(modifiedItem)
          const responseType = parseApiActionType(response.type)

          setLoading(false)

          if (responseType.event === API_EVENT.SUCCESS && !itemIsDone) {
            dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.COMPLETE_ITEM, { from }))
            requestReview()
            dispatchToastUndo(oldPercentage)
            if (!showedCompletedItemHelp) {
              dispatch(showCompletedItemHelp())
              dispatchToast(<FluentToast>{t('item.progress.marked_completed_toast')}</FluentToast>, {
                appearance: 'success',
                timeout: -1,
              })
            }
          }
        },
        1000,
        { leading: true }
      ),
    [
      item,
      itemIsDone,
      asyncMutateItem,
      dispatch,
      from,
      requestReview,
      dispatchToastUndo,
      showedCompletedItemHelp,
      dispatchToast,
      t,
    ]
  )
  return {
    loading,
    markCompleted,
  }
}

const inboxProject = fromJS({ idd: 0 })
export const useCreateLinkedItem = (item, from) => {
  const createItemModal = useCreateItemModal()
  const itemProjectId = itemHelper.getProjectIdd(item)
  const unsafeItemProject = useSelector(state => stateHelper.getProject(state, itemProjectId))
  const itemProject = unsafeItemProject || inboxProject
  const dispatch = useDispatch()
  return () => {
    dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.OPEN_CREATE_LINKED_ITEM, { from }))
    createItemModal({
      mode: EVENT_EXTRA.CREATE_ITEM.MODE.LINKED_ITEM,
      displayProjectSelector: true,
      quadrant: itemHelper.getQuadrant(item),
      initialProject: itemProject,
      linkedItem: item,
    })
  }
}

export const useRecentItems = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(init())
  }, [dispatch])
  const idSet = useSelector(getRecentlyVisitedItemsIdSet)
  const reversed = useMemo(() => idSet.reverse(), [idSet])
  const recentlyVisited = useSelector(state => stateHelper.getItemsFromIDs(state, reversed))
  return useMemo(() => recentlyVisited.filter(i => i && !itemHelper.isDeleted(i)).toArray(), [recentlyVisited])
}

export const useFollowItem = (item, from) => {
  const dispatch = useDispatch()
  const meEmail = useSelector(stateHelper.getMeEmail)
  const itemId = itemHelper.getId(item)
  const isFollowed = itemHelper.isFollower(item, meEmail)
  const isOwner = itemHelper.isOwner(item, meEmail)

  return {
    isFollowed,
    isOwner,
    follow: () => {
      if (!isOwner) {
        const action = isFollowed ? uninviteToItemWithId(itemId, meEmail) : inviteToItemWithId(itemId, meEmail)
        const event = isFollowed ? AMPLITUDE_ACTION_TYPES.REMOVE_FOLLOWER : AMPLITUDE_ACTION_TYPES.ADD_FOLLOWER
        dispatch(action)
        dispatch(dispatchEvent(event, { from }))
      }
    },
  }
}

export const useDuplicateItem = (item, from) => {
  const dispatch = useDispatch()
  const createItemModal = useCreateItemModal()
  const itemProject = useSelector(state => stateHelper.getProject(state, itemHelper.getProjectIdd(item)))
  return () => {
    dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.OPEN_DUPLICATE_ITEM, { from }))
    createItemModal({
      mode: EVENT_EXTRA.CREATE_ITEM.MODE.DUPLICATE_ITEM,
      displayProjectSelector: true,
      quadrant: itemHelper.getQuadrant(item),
      name: itemHelper.getName(item),
      notes: itemHelper.getNotes(item),
      dueDate: itemHelper.getDueDate(item),
      startDate: itemHelper.getStartDate(item),
      initialProject: itemProject,
      ownerEmail: itemHelper.getOwnerUsername(item),
      allDay: itemHelper.getAllDay(item),
      copiedFromID: itemHelper.getId(item),
    })
  }
}

export const useApplyFiltersToItems = (items, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  const filters = useSelector(state => getItemsFilters(state, filtersType))
  const deferredFilters = useDeferredValue(filters)
  const meEmail = useSelector(stateHelper.getMeEmail)
  const itemFilter = useMemo(() => createItemFilter(deferredFilters, meEmail), [deferredFilters, meEmail])
  return useMemo(() => items && items.filter(itemFilter), [itemFilter, items])
}

export const useLinkedProjectId = item => {
  const macResourceURL = itemHelper.getMacResourceURL(item)
  const parsedLink = useMemo(() => URLParser(macResourceURL), [macResourceURL])
  const linkIsProject = parsedLink?.type === 'project' || parsedLink?.type === 'matrix'
  const linkProjectId = parsedLink?.id
  return linkIsProject ? linkProjectId : null
}

export const useTriggerItemDeletionModal = () => {
  const dispatch = useDispatch()
  const { setProps: setAlertModalProps } = useContext(AlertModalContext)
  const { t } = useTranslation()
  const selectedItemId = useSelectedItemId()
  const adjustPathnameOnDeletedItem = useAdjustPathnameOnDeletedItem()
  const { mutate: mutateItem } = useMutateItem(selectedItemId)
  const [hideConfirmationDeleteItem, setHideConfirmationDeleteItem] = useAtom(hideConfirmationDeleteItemAtom)
  return useCallback(
    ({ item }) => {
      const itemName = itemHelper.getName(item)
      const itemID = itemHelper.getId(item)
      dispatch(
        dispatchEvent(AMPLITUDE_ACTION_TYPES.OPEN_DELETE_ITEM_DIALOG, {
          from: EVENT_EXTRA.ITEM_ACTION.FROM.CONTEXTUAL_MENU,
        })
      )
      const deleteItem = () => {
        const modifiedItem = item.set(itemHelper.KEYS.STATE, itemHelper.STATE.DELETED)
        mutateItem(modifiedItem)
        dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.DELETE_ITEM))
        if (selectedItemId === itemID) {
          adjustPathnameOnDeletedItem(itemID)
        }
      }
      if (hideConfirmationDeleteItem) {
        deleteItem()
        return
      }
      setAlertModalProps({
        open: true,
        title: t('item_detail.delete_alert_title'),
        subText: t('item_detail.delete_alert_subtitle', { name: itemName }),
        primaryActionText: t('general.delete'),
        onPrimaryActionClick: deleteItem,
        onYesAlwaysClick: () => {
          setHideConfirmationDeleteItem(true)
          deleteItem()
        },
      })
    },
    [
      dispatch,
      hideConfirmationDeleteItem,
      setAlertModalProps,
      t,
      mutateItem,
      selectedItemId,
      adjustPathnameOnDeletedItem,
      setHideConfirmationDeleteItem,
    ]
  )
}

export const useItemParams = readOnly => {
  return useMemo(() => {
    const fastproject = getFastProject()
    if (readOnly && fastproject) {
      return { fastproject }
    }
    return {}
  }, [readOnly])
}
