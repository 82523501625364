import { ONBOARDING_KEYS as KEYS } from '../reducers/onboardingKeys'
import { onboardingStepHelper } from '../helpers'
import { createSelector } from 'reselect'

export const isFinishedSelector = state => state.getIn([KEYS.REDUCER, KEYS.IS_FINISHED])
export const isCancelledSelector = state => state.getIn([KEYS.REDUCER, KEYS.IS_CANCELLED])
export const currentStepSelector = state => state.getIn([KEYS.REDUCER, KEYS.CURRENT_STEP])

export const stepsByKeySelector = createSelector(
  state => state.getIn([KEYS.REDUCER, KEYS.STEPS_BY_KEY]),
  (state, sorted = false) => sorted,
  (steps, sorted) => (sorted ? steps.sortBy(onboardingStepHelper.getStep) : steps)
)

export const stepObjectByStepSelector = (state, step) => {
  const stepsKeys = stepsByKeySelector(state, true).toList()
  return stepsKeys.get(step)
}

export const stepsCountSelector = state => state.getIn([KEYS.REDUCER, KEYS.STEPS_BY_KEY]).size
