/**
 *  Utils to interact with immutable project template
 **/

import _ from 'lodash'
import { safeMapFnCreator } from './immutableHelpers'
import { toDate } from './dateHelper'
import { resourceURIParser } from './URLHelper'
import { PM_API_RESOURCE_TYPE } from '../constants'

let helper = {}

const KEYS = {
  ID: 'id',
  NAME: 'name',
  NOTES: 'notes',
  DOWNLOADS: 'downloads',
  RESOURCE_URI: 'resource_uri',

  CREATOR_URI: 'creator',
  CREATOR_USERNAME: 'creator_username',
  PUBLIC: 'public',
  TIMESTAMP: 'timestamp',
}
helper.KEYS = KEYS

helper.isCustomProjectTemplate = t => {
  const resourceUri = helper.getResourceURI(t)
  const r = resourceURIParser(resourceUri)
  return r && r.type === PM_API_RESOURCE_TYPE.CUSTOM_PROJECT_TEMPLATE
}

helper.getID = t => t.get(KEYS.ID)
helper.getName = t => t.get(KEYS.NAME)
helper.getNotes = t => t.get(KEYS.NOTES)
helper.getDownloads = t => t.get(KEYS.DOWNLOADS)
helper.getResourceURI = t => t.get(KEYS.RESOURCE_URI)

helper.getCreatorURI = t => t.get(KEYS.CREATOR_URI)
helper.getCreatorUsername = t => t.get(KEYS.CREATOR_USERNAME)
helper.isPublic = t => t.get(KEYS.PUBLIC)

helper.getTimestamp = (t, type = KEYS.TIMESTAMP) => {
  const timestamp = t.get(type)
  return timestamp ? timestamp : 0
}
helper.getTimestampDate = t => toDate(helper.getTimestamp(t))

helper.sortDescriptorByName = t => _.toLower(helper.getName(t))
helper.sortDescriptorByDownloads = t => helper.getDownloads(t)

helper = _.mapValues(helper, f => (_.isFunction(f) ? safeMapFnCreator(f) : f))
export default helper
