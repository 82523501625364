import React from 'react'
import _ from 'lodash'
import { QuadrantMenuItem } from './QuadrantMenuItem'

export const subMenuQuadrantItems = (project, onChangeQuadrant = _.identity) => {
  const quadrantsItems = _.map([0, 1, 2, 3], q => {
    return {
      key: 'quadrant' + q,
      quadrant: q,
      onClick: () => onChangeQuadrant(q),
    }
  })

  return {
    items: quadrantsItems,
    contextualMenuItemAs: props => (
      <QuadrantMenuItem
        id={'itemDetail_submenuMoveQuadrant'}
        project={project}
        quadrant={props.item.quadrant}
        text={props.item.text}
      />
    ),
  }
}
