import { FC, useEffect, memo } from 'react'
import { authSelectors } from '../common/src/selectors'
import { getLicenseStatus } from '../common/src/actions/usersAPI'
import { useTranslation } from 'react-i18next'
import { BasicCard } from './card/BasicCard'
import { ProgressBar } from '@fluentui/react-components'
import { openPurchaseExperienceAction } from '../utils/teams'
import { EVENT_EXTRA } from '../common/src/eventTracking/amplitudeEvents'
import { useDispatch, useSelector } from '../hooks/react-redux'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { Option } from '../types'
import { useShouldDisplayUpgrade } from '@/hooks/userHooks'

enum QuotedFeature {
  Members = 'members',
  Projects = 'projects',
  Items = 'items',
  TotalItems = 'total_items',
}

export const FreemiumQuotaCard = memo(() => {
  const dispatch = useDispatch()
  const trial = useSelector(authSelectors.isOnTrial)
  const { t } = useTranslation()
  const titleTranslationKey = trial ? 'freemium_quota.card_title_trial' : 'freemium_quota.card_title'
  const shouldDisplayUpgrade = useShouldDisplayUpgrade()

  const onClick = () => {
    if (shouldDisplayUpgrade) {
      dispatch(openPurchaseExperienceAction(EVENT_EXTRA.TRIAL_UPGRADE_PRESSED.MODE.FREEMIUM_QUOTA_CARD))
    }
  }

  useEffect(() => {
    dispatch(getLicenseStatus())
  }, [dispatch])
  return (
    <BasicCard className="box-border flex h-28 cursor-pointer flex-col !px-4 !py-2" onClick={onClick}>
      <span className="mb-2 text-xs">{t(titleTranslationKey)}</span>
      <div className="box-border grid grid-cols-2 gap-x-2 gap-y-2">
        <QuotedFeatureCircle feature={QuotedFeature.Projects} />
        <QuotedFeatureCircle feature={QuotedFeature.Members} />
        <QuotedFeatureCircle feature={QuotedFeature.Items} />
        <QuotedFeatureCircle feature={QuotedFeature.TotalItems} />
      </div>
    </BasicCard>
  )
})

const limitMapper = {
  [QuotedFeature.Members]: 'max_members',
  [QuotedFeature.Projects]: 'max_projects',
  [QuotedFeature.Items]: 'max_items',
  [QuotedFeature.TotalItems]: 'max_total_items',
} as const

const titleMapper = {
  [QuotedFeature.Members]: 'members',
  [QuotedFeature.Projects]: 'projects',
  [QuotedFeature.Items]: 'open_items',
  [QuotedFeature.TotalItems]: 'total_items',
} as const

interface IQuotaCircle {
  feature: QuotedFeature
}

const getProgressColor = (percentage: number): Option<string> => {
  if (percentage > 99) {
    return 'red'
  } else if (percentage > 75) {
    return 'orange'
  }
  return undefined
}

const QuotedFeatureCircle: FC<IQuotaCircle> = ({ feature }) => {
  const { t } = useTranslation()
  const allUsages = useSelector(authSelectors.quotaUsage)
  const allLimits = useSelector(authSelectors.freeTierLimit)
  const limit = allLimits.get(limitMapper[feature])
  const usage = feature === QuotedFeature.Members ? Math.max(allUsages.get(feature), 1) : allUsages.get(feature)
  const value = usage / limit
  const text = usage + '/' + limit
  const label = t('freemium_quota.' + titleMapper[feature])
  const tooltipText = t('freemium_quota.' + titleMapper[feature] + '_tooltip')
  const color = getProgressColor(value * 100)
  return (
    <Tooltip content={tooltipText} relationship="description">
      <div className="flex flex-col items-center justify-center">
        <ProgressBar value={value} bar={{ style: { backgroundColor: color } }} />
        <span className="mt-0.5 text-[10px] leading-3">{label}</span>
        <span className="text-xs">{text}</span>
      </div>
    </Tooltip>
  )
}
