import React, { memo, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { List } from '@fluentui/react/lib/List'
import {
  currentStepSelector,
  isCancelledSelector,
  isFinishedSelector,
  stepsByKeySelector,
} from '../../selectors/onboardingSelectors'
import { onboardingSubject } from '../../reactions'
import { onboardingStepHelper } from '../../helpers'
import { OnboardingCell } from './OnboardingCell'
import titleImageSrc from '../../resources/images/illustrations/undraw_book_reading.svg'
import { useTranslation } from 'react-i18next'

const containerVariants = {
  hidden: { opacity: 0, scale: 1, x: -200 },
  visible: {
    opacity: 1,
    scale: 1,
    x: 0,
    transition: {
      duration: 0.5,
      delay: 0.1,
    },
  },
}

const Container = styled(motion.div).attrs({
  variants: containerVariants,
  initial: 'hidden',
  animate: 'visible',
})`
  color: ${p => p.theme.palette.white};
`

const TitleBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const Title = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  margin-left: 10px;
  color: ${p => p.theme.palette.white};
`

const TitleImage = styled.img.attrs({
  src: titleImageSrc,
})`
  height: 40px;
`

const Body = styled.div``

export const OnboardingMainView = memo(() => {
  const isFinished = useSelector(isFinishedSelector)
  const isCancelled = useSelector(isCancelledSelector)
  const stepsKeys = useSelector(state => stepsByKeySelector(state, true))
  const { t } = useTranslation()
  const steps = useMemo(() => {
    return stepsKeys.toArray()
  }, [stepsKeys])
  const totalSteps = steps.length
  const currentStep = useSelector(currentStepSelector)

  const createOnClickCell = useCallback(
    key => () => {
      onboardingSubject.next(key)
    },
    []
  )

  const onRenderCell = useCallback(
    (stepObject, step) => {
      const key = onboardingStepHelper.getKey(stepObject)
      const title = t(`onboarding_step.${key}.title`)
      return (
        <OnboardingCell
          step={step}
          totalSteps={totalSteps}
          isDone={isFinished || step < currentStep}
          isDisabled={isCancelled || step > currentStep}
          key={key}
          text={title}
          onClick={createOnClickCell(key)}
        />
      )
    },
    [t, totalSteps, isFinished, currentStep, isCancelled, createOnClickCell]
  )

  return (
    <Container>
      <TitleBar>
        <TitleImage />
        <Title>{t('onboarding_step.do_this_first')}</Title>
      </TitleBar>
      <Body>
        <List items={steps} onRenderCell={onRenderCell} />
      </Body>
    </Container>
  )
})
