import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, PopoverSurface, Slider } from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { CheckmarkCircleFilled, DismissCircleFilled } from '@fluentui/react-icons'

export type ProgressPopoverSurfaceProps = {
  value: number
  onChange: (value: number) => void
}

export const ProgressPopoverSurface = ({ value, onChange }: ProgressPopoverSurfaceProps) => {
  const { t } = useTranslation()
  const notCompleted = value < 100

  const onClickButton = useCallback(() => {
    if (notCompleted) {
      onChange(100)
    } else {
      onChange(0)
    }
  }, [notCompleted, onChange])

  const completeTooltipContent = notCompleted ? t('item.progress.mark_completed') : t('item.progress.reset_completion')

  return (
    <PopoverSurface className="w-52 !px-2 !py-1">
      <div className="flex h-12 items-center py-1">
        <Slider className="flex-1" step={5} value={value} onChange={(ev, data) => onChange(data.value)} />
        <Tooltip content={completeTooltipContent} relationship="label">
          <Button
            appearance="subtle"
            onClick={onClickButton}
            icon={notCompleted ? <CheckmarkCircleFilled /> : <DismissCircleFilled />}
          />
        </Tooltip>
      </div>
    </PopoverSurface>
  )
}
