import React, { useEffect } from 'react'
import { MainLayout } from '../../components/layout/MainLayout'
import { GraphResourceDetails } from './GraphResourceDetails'
import { useSelectedGraphItemId } from '../../hooks/PMHooks'
import { useSelector } from 'react-redux'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { graphStateHelper } from '../../common/src/helpers'

/**
 * In mobile, if you directly open this view the selected element
 * will not be ready since at this point we don't have a way to fetch
 * it. We need it to be previously stored in memory.
 *
 * So, right now we redirect the view to home view to avoid white-screens
 */
const useSafeRedirect = (id, history) => {
  const resource = useSelector(state => graphStateHelper.getById(state, id))
  useEffect(() => {
    if (id && !resource) {
      const link = getRelativeURLKeepingQuerySearch.homeSection('alerts')
      history.replace(link)
    }
  }, [id, resource, history])
}

export const GraphResourceView = ({ match, history }) => {
  const id = useSelectedGraphItemId()
  useSafeRedirect(id, history)
  const components = {
    main: <GraphResourceDetails />,
  }
  return <MainLayout {...components} />
}
