import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fromJS } from 'immutable'
import styled from 'styled-components'
import { Label, PrimaryButton } from '@fluentui/react'
import { SimpleModal } from './SimpleModal'
import { ProjectsDropdown } from '../dropdown/ProjectsDropdown'
import { QuadrantsDropdown } from '../dropdown/QuadrantsDropdown'
import { getNewItemsMovingItemsTo } from '../../common/src/actions/combinedAPI'
import { AlertModal } from './AlertModal'
import { projectHelper } from '../../common/src/helpers'
import { uriDataCreator } from '../../common/src/helpers/URLHelper'
import { PM_API_RESOURCE_TYPE } from '../../common/src/constants'
import { linkSubject, SOURCES } from '../../reactions/linkSubject'
import { useMergeState } from '../../common/src/hooks/enhancedHooks'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { navigationPaths } from '../../utils/NavPanelCommon'
import { hasTouchScreen } from '@/helpers/deviceDetectionHelper'
import { ProjectSelectorDrawer } from '@/views/modal/createItem/ProjectSelectorDrawer'

const SProjectsDropdown = styled(ProjectsDropdown)`
  margin-bottom: 12px;
`

const modalStyles = {
  title: {
    fontWeight: 'bold',
  },
}

const DEFAULT_QUADRANT = 3

export const MoveItemsModal = ({ items, initProject, initQuadrant, onMoveItems, ...rest }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const [state, setState] = useMergeState({
    project: null,
    quadrant: null,
    loading: false,
    errorMessage: null,
    movedItem: null,
    showAlertModal: false,
  })
  const { project, quadrant, loading, errorMessage, showAlertModal } = state

  useEffect(() => {
    setState(prevState => {
      const project = initProject !== undefined ? initProject : prevState.project
      return {
        project,
        quadrant: initQuadrant ?? (project ? (prevState.quadrant ?? null) : null),
        loading: false,
        errorMessage: null,
        movedItem: null,
        showAlertModal: false,
      }
    })
  }, [items, initProject, initQuadrant, setState])
  const { onDismiss } = rest

  const onMoveItemsByDefault = useCallback(() => {
    if (!project) {
      history.push(navigationPaths.inbox)
      return
    }
    const projectId = projectHelper.getIdd(project)
    const urlData = uriDataCreator(1, PM_API_RESOURCE_TYPE.PROJECT, projectId)
    linkSubject.next({ urlData, source: SOURCES.MOVE_ITEM_MODAL })
  }, [history, project])

  const moveItems = async () => {
    if (!items) {
      return
    }
    const { project, quadrant } = state
    setState({ loading: true })
    const response = await dispatch(getNewItemsMovingItemsTo(items, project, quadrant))
    const { error, payload } = response
    const errorMessage = error ? payload.message : null
    const showAlertModal = !error
    const movedItem = !error ? fromJS(payload) : null

    setState({
      loading: false,
      showAlertModal,
      errorMessage,
      movedItem,
    })
  }

  const openMovedItem = () => {
    const { project, movedItem } = state
    onDismiss()

    if (onMoveItems) {
      onMoveItems(movedItem, project)
    } else {
      onMoveItemsByDefault(movedItem)
    }
  }

  const onProjectChanged = useCallback(
    project => {
      setState(prevState => ({
        project,
        quadrant: project ? (prevState.quadrant ?? DEFAULT_QUADRANT) : null,
      }))
    },
    [setState]
  )

  const onQuadrantChanged = useCallback(
    quadrant => {
      setState({ quadrant })
    },
    [setState]
  )

  const hideAlertModal = useCallback(() => {
    setState({ showAlertModal: false })
  }, [setState])

  const doNotOpenCurrentItem = () => {
    onDismiss()
  }

  const projectName = projectHelper.getName(project) ?? ''
  const alertTitleSuccess = t('modal.move_items_alert_title_success')
  const alertSubtitleSuccess =
    project === 'keep'
      ? undefined
      : project
        ? t('modal.move_item_alert_subtitle_success', { projectName })
        : t('modal.move_item_alert_subtitle_success_inbox')
  const alertOnPrimaryActionClick = project === 'keep' ? onDismiss : openMovedItem
  const alertPrimaryActionText = project === 'keep' ? undefined : t('modal.open')
  const alertDefaultActionText = project === 'keep' ? undefined : t('modal.not_now')
  const alertShowDefaultActionButton = project !== 'keep'

  const primaryButtonDisabled = loading || (project === 'keep' && quadrant === -1)
  const closeButtonDisabled = loading
  const button = (
    <PrimaryButton
      id="moveItemModal_moveButton"
      key={'moveItemModal_moveButton'}
      onClick={moveItems}
      disabled={primaryButtonDisabled}
    >
      {t('modal.move')}
    </PrimaryButton>
  )
  return (
    <SimpleModal
      title={t('modal.move_items_title')}
      loading={loading}
      errorMessage={errorMessage}
      buttons={button}
      closeButtonProps={{
        disabled: closeButtonDisabled,
        text: t('modal.no_thanks'),
      }}
      styles={modalStyles}
      {...rest}
    >
      {hasTouchScreen() ? (
        <>
          <Label>{t('project.project')}</Label>
          <ProjectSelectorDrawer
            onSelectProject={(evt, project) => onProjectChanged(project)}
            selectedProject={project}
            className="w-full"
            showKeepSameOption
          />
        </>
      ) : (
        <SProjectsDropdown
          id="moveItemModal_destinationProjectDropdown"
          label={t('project.project')}
          selectedProject={project}
          onProjectChanged={onProjectChanged}
          enableInbox
          enableKeepSame
        />
      )}
      <QuadrantsDropdown
        id="moveItemModal_quadrantDropdown"
        label={t('project.quadrant')}
        project={project}
        quadrant={quadrant}
        onQuadrantChanged={onQuadrantChanged}
        enableEmpty
        textEmpty={t('item.multiple_selection.move.keep_same_quadrant')}
      />
      <AlertModal
        title={alertTitleSuccess}
        subText={alertSubtitleSuccess}
        open={showAlertModal}
        onDismiss={hideAlertModal}
        primaryActionText={alertPrimaryActionText}
        defaultActionText={alertDefaultActionText}
        onPrimaryActionClick={alertOnPrimaryActionClick}
        onDefaultActionClick={doNotOpenCurrentItem}
        showDefaultActionButton={alertShowDefaultActionButton}
      />
    </SimpleModal>
  )
}
