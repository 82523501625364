import React, { useMemo } from 'react'
import { AlertModalContext } from '../../contexts'
import { AlertModal } from '../../components/modal/AlertModal'
import { useMergeState } from '../../common/src/hooks/enhancedHooks'

export const AlertModalSingleton = ({ children }) => {
  const [props, setProps] = useMergeState({ open: false })
  const contextValue = useMemo(() => ({ props, setProps }), [props, setProps])

  const dismissHandler = () => {
    setProps({ open: false })
  }

  return (
    <AlertModalContext.Provider value={contextValue}>
      <AlertModal {...props} onDismiss={dismissHandler} />
      {children}
    </AlertModalContext.Provider>
  )
}
