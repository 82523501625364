import { useCallback } from 'react'
import { useFetch } from '../common/src/hooks/networkingHooks'
import { useDispatch } from 'react-redux'
import { getItemConflicts } from '../common/src/actions/conflictsAPI'

export const useItemConflicts = ({ itemId, required = true }) => {
  const dispatch = useDispatch()
  const fetchConflicts = useCallback(() => dispatch(getItemConflicts(itemId)), [dispatch, itemId])
  const { data, loading, error } = useFetch(required ? fetchConflicts : null)

  return { conflicts: data, loading, error }
}
