import styled from 'styled-components'
import { dateHelper } from '../../../common/src/helpers'
import { Row } from '../../../components/layout/FlexContainer'

export const VIEW_KEYS = {
  TASKS: 'tasks',
  EFFORT: 'Effort',
  HOURS: 'Hours',
}

export const keyToDate = dateHelper.fromYYYYMMDD
export const dateToKey = dateHelper.getYYYYMMDD
export const MS_PER_H = 60 * 60 * 1000
export const TOTALS = 'Totals'

export const Table = styled.table`
  margin: 12px;
  & > tbody > tr:last-child > td {
    border-top: 3px solid ${p => p.theme.palette.black};
  }
`

export const Header = styled.tr`
  th {
    padding-bottom: 10px;
  }
  th:first-of-type {
    text-align: left;
  }
`

export const CellContainer = styled.td`
  width: 4em;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  height: 30px;
`

export const Avatar = styled.img`
  border-radius: 100px;
  height: 24px;
  width: 24px;
`

export const UserCellContent = styled.div`
  display: flex;
  text-align: left;
  align-items: center;
  gap: 8px;
  height: 32px;
`
export const TableRow = styled.tr`
  &:nth-child(even) {
    background: ${p => p.theme.palette.neutralQuaternaryAlt};
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: ${p => p.theme.palette.highlight};
    }
  }
`

export const TopBar = styled(Row)`
  justify-content: flex-end;
  width: 100%;
  height: 70px;
  gap: 4px;

  &.no-height {
    height: auto;
    margin-top: 12px;
    align-items: center;
  }
`

export const ModalTopBar = styled(TopBar)`
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
`
