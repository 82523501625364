import React, { useMemo, memo } from 'react'
import { Coachmark } from '@fluentui/react/lib/Coachmark'
import { TeachingBubbleContent } from '@fluentui/react/lib/TeachingBubble'
import { DirectionalHint } from '@fluentui/react/lib/Callout'
import { useTranslation } from 'react-i18next'

export const OnboardingCoachmark = memo(
  ({
    stepKey,
    target,
    positioningContainerProps = {
      directionalHint: DirectionalHint.bottomCenter,
    },
    onDismiss,
    children,
    ...rest
  }) => {
    const { t } = useTranslation()
    const stepStrings = useMemo(() => {
      return {
        title: t(`onboarding_step.${stepKey}.title`),
        tipTitle: t(`onboarding_step.${stepKey}.tipTitle`),
        tipMessage: t(`onboarding_step.${stepKey}.tipMessage`),
        completedTitle: t(`onboarding_step.${stepKey}.completedTitle`),
        completedMessage: t(`onboarding_step.${stepKey}.completedMessage`),
      }
    }, [stepKey, t])
    const title = stepStrings.tipTitle
    const message = stepStrings.tipMessage
    return (
      <Coachmark target={target} positioningContainerProps={positioningContainerProps} {...rest}>
        <TeachingBubbleContent headline={title} hasCloseButton onDismiss={onDismiss}>
          {message}
        </TeachingBubbleContent>
      </Coachmark>
    )
  }
)
