import { useInfiniteQuery } from '@tanstack/react-query'
import { appendQueryParamsToURL, getCredentialsConfig } from '../common/src/helpers/requestHelper'
import { PaginatedResponse } from '../types/apiTypes'
import { useStore } from 'react-redux'
import type { UserGroup } from '../types/userGroup'

export const useUserGroups = () => {
  const store = useStore()

  return useInfiniteQuery({
    queryKey: ['userGroups'],
    queryFn: async ({ pageParam = '' }) => {
      const response = await fetch(appendQueryParamsToURL(pageParam || '/api/v1/group/')(store.getState()), {
        credentials: getCredentialsConfig(),
      })
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return (await response.json()) as PaginatedResponse<UserGroup>
    },
    getNextPageParam: lastPage => lastPage.meta.next,
    initialPageParam: '',
    select: data => data.pages.flatMap(page => page.objects),
  })
}
