import _ from 'lodash'
import React from 'react'
import { BrowserRouter, MemoryRouter } from 'react-router-dom'
import { BASENAME } from './common/src/environment'
import { getPathnameKeepingQuerySearch } from './helpers/routeHelper'
import * as queryParamsHelper from './helpers/queryParamsHelper'

const isEmbedded = queryParamsHelper.isEmbedded()
const firstPath = '/' + (_.get(window.location.pathname.split(BASENAME), 1) || '')
const firstRoute = getPathnameKeepingQuerySearch(firstPath)
const initialEntries = [firstRoute]

export const CustomRouter = props => {
  if (isEmbedded) {
    return <MemoryRouter initialEntries={initialEntries} initialIndex={0} {...props} />
  }
  return <BrowserRouter basename={BASENAME} {...props} />
}
