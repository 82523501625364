import { useContext, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { toDate } from '../../../common/src/helpers/dateHelper'
import { ICON_NAMES } from '../../../common/src/msIcons'
import { ActionButton } from '@fluentui/react/lib/Button'
import { AMPLITUDE_ACTION_TYPES, EVENT_EXTRA } from '../../../common/src/eventTracking/amplitudeEvents'
import { NewItem1On1Panel } from '../../../components/item/NewItem1On1Panel'
import { OneOnOneContext } from '../../../contexts'
import { formatDate } from '../../../utils/datefns'

const TimeSection = styled.div`
  margin-top: 20px;
  order: ${p => p.order || 'unset'};
`

const Time = styled.p`
  font-size: 11px;
  margin: 0;
`

const oneMinute = 60 //secs

const openButtonProps = {
  iconName: ICON_NAMES.OpenInNewWindow,
}

const createItemIconButtonProps = {
  iconName: ICON_NAMES.Add,
}

const SortableActionButton = styled(ActionButton)`
  order: ${p => p.order || 'unset'};
`

export const ExtraCommonInfo = ({ resource, onItemCreated }) => {
  const dispatch = useDispatch()
  const { creationDate, lastModifiedTimestamp, link } = resource
  const { selectedCollaborator } = useContext(OneOnOneContext) || {}
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const creationText = formatDate('LongNormal')(toDate(creationDate))
  const shouldDisplayModifiedDate = creationDate + oneMinute < lastModifiedTimestamp
  const modifiedText = shouldDisplayModifiedDate && formatDate('LongNormal')(toDate(lastModifiedTimestamp))
  const onClick = () => {
    dispatch({
      type: AMPLITUDE_ACTION_TYPES.OPEN_GRAPH_RES_ON_OFFICE365,
    })
  }
  const openAction = link && (
    <SortableActionButton iconProps={openButtonProps} onClick={onClick} href={link} target="_blank" order="100">
      Open in Microsoft 365
    </SortableActionButton>
  )

  const onClickCreateItem = () => {
    setIsPanelOpen(true)
  }

  const onDismiss = () => {
    setIsPanelOpen(false)
  }

  return (
    <>
      {openAction}
      <SortableActionButton iconProps={createItemIconButtonProps} onClick={onClickCreateItem} order="101">
        Send to Priority Matrix
      </SortableActionButton>
      <TimeSection order="102">
        <Time>Created on {creationText}</Time>
        {shouldDisplayModifiedDate && <Time>Modified on {modifiedText}</Time>}
      </TimeSection>
      <NewItem1On1Panel
        isOpen={isPanelOpen}
        onDismiss={onDismiss}
        collaborator={selectedCollaborator}
        graphResource={resource}
        mode={EVENT_EXTRA.CREATE_ITEM.MODE.GRAPH}
        onItemCreated={onItemCreated}
      />
    </>
  )
}
