import { type FC, type RefCallback } from 'react'
import type { Project } from '@/types'
import { ProjectGridCell } from '@/components/projectCell/ProjectGridCell'
import type { AllProjectCountersWithOverdue } from '@/queries/projectCounters'
import { projectHelper } from '@/common/src/helpers'
import { useTranslation } from 'react-i18next'
import { StarFilled } from '@fluentui/react-icons'

interface IProps {
  projects: Project[]
  counts?: AllProjectCountersWithOverdue
  fromProjectId?: number
}

const scrollCellIntoView: RefCallback<HTMLAnchorElement> = element =>
  element?.scrollIntoView({ behavior: 'instant', block: 'center' })

export const GridMode: FC<IProps> = ({ projects, counts, fromProjectId }) => {
  const { t } = useTranslation()
  const starred = projects.filter(p => projectHelper.isStarred(p))
  const other = projects.filter(p => !projectHelper.isStarred(p))
  const mapProject = (p: Project) => (
    <ProjectGridCell
      ref={p.get('idd') === fromProjectId ? scrollCellIntoView : undefined}
      key={p.get('idd')}
      project={p!}
      counts={counts?.[p.get('idd')]}
      className={p.get('idd') === fromProjectId ? 'border border-solid border-neutral-400 dark:border-neutral-500' : ''}
    />
  )
  const starredCells = starred.map(mapProject)
  const otherCells = other.map(mapProject)
  return (
    <div className="flex flex-col gap-3 md:mx-2">
      {!!starredCells.length && (
        <div className="flex flex-col gap-1">
          <span className="ml-3  flex items-center font-semibold text-black dark:text-white">
            <StarFilled className="text-md ml-0.5 mr-2 text-yellow-400" />
            {t('project_list.starred')}
          </span>
          <div className="mb-2 flex flex-row flex-wrap items-center justify-evenly md:justify-start">
            {starredCells}
          </div>
        </div>
      )}
      {!!otherCells.length && (
        <div className="flex flex-col gap-1">
          {!!starredCells.length && (
            <span className="ml-3  flex items-center font-semibold text-black dark:text-white">
              {t('project_list.rest_of_projects')}
            </span>
          )}
          <div className="mb-2 flex flex-row flex-wrap items-center justify-evenly md:justify-start">{otherCells}</div>
        </div>
      )}
    </div>
  )
}
