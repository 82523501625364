import React from 'react'
import styled from 'styled-components'
import { FlexContainer } from '../components/layout/FlexContainer'
import { useTranslation } from 'react-i18next'

const Title = styled.h4`
  color: ${p => p.theme.palette.black};
`

const Container = styled(FlexContainer)`
  box-sizing: border-box;
  padding: 24px;
`

const PlaceholderView_ = ({ prefix, children }) => {
  const { t } = useTranslation()
  return (
    <Container>
      <Title>{t(prefix + '.title')}</Title>
      <p>{t(prefix + '.subtitle')}</p>
      {children}
    </Container>
  )
}
export const PlaceholderView = React.memo(PlaceholderView_)
