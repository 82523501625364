import _ from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { stateHelper, itemHelper } from '../helpers'
import { OBJECT_ERROR_STATUS, ObjectError } from '../errors/errors'
import { resourceURICreator } from '../helpers/URLHelper'
import { PM_API_RESOURCE_TYPE } from '../constants'
import { fromJS } from 'immutable'
import { postItem } from '../actions/itemsAPI'
import { setDefaultFilterItems } from '../actions/filtersActions'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent as trackEvent } from '../eventTracking/amplitudeEvents'
import { useItemFilter } from './filtersHooks'

export const useAttentionNeededInSession = () => {
  return useSelector(state => {
    const idSet = stateHelper.getAttentionNeededIdSetInCurrentSession(state)
    return stateHelper.getItemsFromIDs(state, idSet)
  })
}

export const useCreateNewItem = () => {
  const dispatch = useDispatch()
  const store = useStore()
  const itemFilter = useItemFilter()

  return useCallback(
    async ({
      name,
      ownerEmail,
      notes,
      link,
      quadrant,
      dueDate,
      startDate,
      allDay,
      projectID,
      copiedFromID,
      clearingFilters = true,
      eventProps,
    }) => {
      const getSuggestedIndex = _.partial(stateHelper.getSuggestedIndexForNewItem, store.getState(), _, _)
      if (_.isEmpty(name)) {
        const errorObject = new ObjectError(OBJECT_ERROR_STATUS.ITEM_EMPTY_NAME, `The name cannot be empty`)
        return { error: true, payload: errorObject }
      }

      const pseudoItem = {
        [itemHelper.KEYS.NAME]: name,
        [itemHelper.KEYS.OWNER_USERNAME]: ownerEmail,
        [itemHelper.KEYS.DESCRIPTION_TEXT]: notes,
        [itemHelper.KEYS.QUADRANT]: quadrant,
        [itemHelper.KEYS.MAC_RESOURCE_URL]: link,
      }

      if (dueDate) {
        pseudoItem[itemHelper.KEYS.DUE_DATE] = dueDate
      }

      if (startDate) {
        pseudoItem[itemHelper.KEYS.START_DATE] = startDate
      }

      if (allDay !== undefined) {
        pseudoItem[itemHelper.KEYS.ALL_DAY] = allDay
      }

      if (projectID) {
        const uri = resourceURICreator(1, PM_API_RESOURCE_TYPE.PROJECT, projectID)
        pseudoItem.index = getSuggestedIndex(projectID, quadrant)
        pseudoItem.projects = [uri]
      }

      const immutablePseudoItem = fromJS(pseudoItem)
      const response = await dispatch(postItem(immutablePseudoItem, copiedFromID))
      const { error, payload } = response
      if (error) {
        return response
      }

      const item = fromJS(payload)
      // Clear filters
      if (clearingFilters && !itemFilter(item)) {
        dispatch(setDefaultFilterItems())
      }

      // Create event
      dispatch(trackEvent(AMPLITUDE_ACTION_TYPES.CREATE_ITEM, eventProps))

      return { payload: item }
    },
    [dispatch, itemFilter, store]
  )
}
