import React from 'react'
import _ from 'lodash'
import { MessageContent } from './content/MessageContent'
import { GRAPH_RESOURCE_TYPE } from '../../common/src/helpers/graph/graphResourceBase'
import { FileContent } from './content/FileContent'
import { EventContent } from './content/EventContent'
import { OutlookTaskContent } from './content/OutlookTaskContent'
import { PlannerTaskContent } from './content/PlannerTaskContent'

const componentMap = {
  [GRAPH_RESOURCE_TYPE.MESSAGE]: MessageContent,
  [GRAPH_RESOURCE_TYPE.FILE]: FileContent,
  [GRAPH_RESOURCE_TYPE.EVENT]: EventContent,
  [GRAPH_RESOURCE_TYPE.OUTLOOK_TASK]: OutlookTaskContent,
  [GRAPH_RESOURCE_TYPE.PLANNER_TASK]: PlannerTaskContent,
}

export const Content = props => {
  const type = _.get(props, 'resource.type')
  const Component = componentMap[type]
  if (!Component) {
    return false
  }
  return <Component {...props} />
}
