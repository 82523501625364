import { RSAA } from 'redux-api-middleware'
import { PM_API_RESOURCES, PM_API_URLS } from '../constants'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'
import { appendQueryParamsToURL, extendPMHeaders, getCredentialsConfig } from '../helpers/requestHelper'
import { SEARCH_REDUCER_KEYS } from '../reducers/searchKeys'
import { createPaginatedAPIAction } from '../helpers/paginationHelper'

export const SEARCH_MODELS = {
  ITEM: 'item',
  PROJECT: 'project',
}

const itemDefaultParams = {
  model: SEARCH_MODELS.ITEM,
  summaries: 1,
  state__lte: 1,
  limit: 50,
}

export const getSearchItem =
  (params, section = SEARCH_REDUCER_KEYS.SECTION_SEARCH) =>
  dispatch => {
    const finalParams = { ...itemDefaultParams, ...params }
    if (finalParams.summaries === 0) {
      delete finalParams.summaries
    }
    return dispatch(_getSinglePageSearch(finalParams, section))
  }

export const getPaginatedSearchItem = createPaginatedAPIAction(getSearchItem)

const _getSinglePageSearch = (params, section = SEARCH_REDUCER_KEYS.SECTION_SEARCH) => {
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(PM_API_URLS.SEARCH, params),
      credentials: getCredentialsConfig(),
      method: 'GET',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.SEARCH, { params, section }),
      headers: extendPMHeaders(),
    },
  }
}

export const SEARCH_ACTION_TYPES = {
  INSERT: 'SEARCH/INSERT',
}

export const insertObjectInSearchWithID = (
  id,
  section = SEARCH_REDUCER_KEYS.SECTION_SEARCH,
  objectType = SEARCH_REDUCER_KEYS.ITEMS
) => ({
  type: SEARCH_ACTION_TYPES.INSERT,
  payload: { id, section, objectType },
})
