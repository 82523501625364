import { MainLayout } from '../../components/layout/MainLayout'
import { RightDetailView } from '../rightDetailView/RightDetailView'
import { PMGlobalGantt } from './PMGlobalGantt'

export const PMGlobalGanttView = () => {
  const components = {
    header: null,
    main: <PMGlobalGantt />,
    rightSide: <RightDetailView />,
  }
  return <MainLayout {...components} />
}
