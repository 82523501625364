import { useEffect, useRef } from 'react'

interface Config {
  timeout: number
  ignoreInitialCall: boolean
}

const DEFAULT_CONFIG: Config = {
  timeout: 0,
  ignoreInitialCall: false,
} as const

type ConfigParam = number | Config
type Callback = Parameters<typeof useEffect>[0]
type ClearFunc = ReturnType<Callback>
interface DebounceData {
  firstTime: boolean
  clearFunc?: ClearFunc
}

export function useDebouncedEffect(callback: Callback, config: ConfigParam, deps: any[] = []) {
  let currentConfig: Config
  if (typeof config === 'object') {
    currentConfig = {
      ...DEFAULT_CONFIG,
      ...config,
    }
  } else {
    currentConfig = {
      ...DEFAULT_CONFIG,
      timeout: config,
    }
  }
  const { timeout, ignoreInitialCall } = currentConfig
  const data = useRef<DebounceData>({ firstTime: true })
  useEffect(() => {
    const { firstTime, clearFunc } = data.current

    if (firstTime && ignoreInitialCall) {
      data.current.firstTime = false
      return
    }

    const handler = setTimeout(() => {
      if (clearFunc && typeof clearFunc === 'function') {
        clearFunc()
      }
      data.current.clearFunc = callback()
    }, timeout)

    return () => {
      clearTimeout(handler)
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout, ...deps])
}

export default useDebouncedEffect
