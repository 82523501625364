import _ from 'lodash'
import { createGraphResourceBase } from './graphResourceBase'
import { ICON_NAMES } from '../../msIcons'
import { GRAPH_RESOURCE_TYPE } from './graphResourceBase'
import { MicrosoftDateTimeTimeZone, dateToTimestampInSeconds } from '../dateHelper'
import { isWithinInterval, isBefore, isAfter } from 'date-fns'

const helper = {}

helper.create = event => {
  return createGraphResourceBase(event, {
    name: event.subject || event.bodyPreview || 'No name',
    icon: ICON_NAMES.Event,
    iconColor: '#0073c6',
    link: event.webLink,
    type: GRAPH_RESOURCE_TYPE.EVENT,
  })
}

helper.isEvent = m => m.type === GRAPH_RESOURCE_TYPE.EVENT

helper.getHTMLBody = event => {
  const body = event.resource.body
  if (!body) {
    return void 0
  }
  const { content, contentType } = body
  if (contentType === 'html') {
    return content
  }
  return void 0
}

helper.getBodyPreview = event => event.resource.bodyPreview

helper.getStartDate = event => {
  const dateTimeTimeZone = event.resource.start
  return MicrosoftDateTimeTimeZone.toDate(dateTimeTimeZone)
}

helper.getEndDate = event => {
  const dateTimeTimeZone = event.resource.end
  return MicrosoftDateTimeTimeZone.toDate(dateTimeTimeZone)
}

helper.getRange = event => [helper.getStartDate(event), helper.getEndDate(event)]

helper.getStartDateTimestamp = event => dateToTimestampInSeconds(helper.getStartDate(event))
helper.getEndDateTimestamp = event => dateToTimestampInSeconds(helper.getEndDate(event))

helper.getICalUId = event => _.get(event, 'resource.iCalUId', '')
helper.getJoinUrl = event => _.get(event, 'resource.onlineMeeting.joinUrl', '')
helper.isLinkedToTeamsChannel = (event, channelId) => {
  const url = helper.getJoinUrl(event)
  const encodedChannelId = encodeURIComponent(channelId)
  const components = _.split(url, '/')
  return _.includes(components, encodedChannelId)
}
helper.isProvidedByTeams = event => event?.resource?.onlineMeetingProvider === 'teamsForBusiness'

helper.occursInInterval = (event, [start, end]) => {
  const [eStart, eEnd] = helper.getRange(event)
  const interval = { start, end }
  return (
    isWithinInterval(eStart, interval) ||
    isWithinInterval(eEnd, interval) ||
    (isBefore(eStart, start) && isAfter(eEnd, end))
  )
}

export default helper
