import React, { forwardRef, memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button'
import { graphStateHelper } from '../../../common/src/helpers'
import { Panel } from 'react-resizable-panels'
import { Office365Title } from '../../../components/title/Office365Title'
import { SERVER_URLS } from '../../../common/src/constants'
import { OfficeSectionBody, OfficeSectionHeader } from './OfficeSectionSubcomponents'
import { GraphItemVirtualizedTable } from '../../../components/table/GraphItemVirtualizedTable'
import { useTranslation } from 'react-i18next'

const ShowOfficeButton = styled(PrimaryButton).attrs(p => ({
  text: p.t('general.show'),
}))`
  min-width: 60px;
  height: 24px;
  padding: 0 4px;
`
const HideOfficeButton = styled(DefaultButton).attrs(p => ({
  text: p.t('general.hide'),
}))`
  min-width: 60px;
  height: 24px;
  padding: 0 4px;
`

export const OfficeHeader = ({ isBottomView, onToggleBottomSection, show }) => {
  const { t } = useTranslation()
  return (
    <OfficeSectionHeader>
      <Office365Title />
      {isBottomView && (
        <>
          {show ? (
            <HideOfficeButton t={t} onClick={onToggleBottomSection} />
          ) : (
            <ShowOfficeButton t={t} onClick={onToggleBottomSection} />
          )}
        </>
      )}
    </OfficeSectionHeader>
  )
}

export const OfficeSection = memo(
  forwardRef(
    (
      {
        onSelectItem,
        items = [],
        selectedItem,
        loading = false,
        placeholder,
        onToggleBottomSection,
        onRenderLastItem,
        isBottomView,
        graphPermissionLink = `${SERVER_URLS.GRAPH_PERMISSIONS}?next=${window.location.pathname}`,
        onExpand,
        onCollapse,
      },
      ref
    ) => {
      const isGraphEnabled = useSelector(graphStateHelper.isGraphEnabled)
      const { t } = useTranslation()
      const appName = isBottomView ? 'Priority Matrix' : '1 on 1 Hub'

      const graphPermissionPlaceholder = {
        title: (
          <div>
            <DefaultButton href={graphPermissionLink}>{t('office.connect')}</DefaultButton>
          </div>
        ),
        message: <p>{t('office.connect_message', { appName })}</p>,
        type: 0,
      }

      const finalPlaceholder = !isGraphEnabled ? graphPermissionPlaceholder : placeholder

      const onRowClick = useCallback(
        ({ rowData }) => {
          onSelectItem(rowData)
        },
        [onSelectItem]
      )

      return (
        <Panel
          ref={ref}
          collapsible={isBottomView}
          minSize={10}
          className="flex flex-col"
          onExpand={onExpand}
          onCollapse={onCollapse}
        >
          <OfficeHeader isBottomView={isBottomView} onToggleBottomSection={onToggleBottomSection} show />
          <OfficeSectionBody>
            <GraphItemVirtualizedTable
              items={items}
              selectedItem={selectedItem}
              onRowClick={onRowClick}
              loading={loading}
              placeholder={finalPlaceholder}
            />
          </OfficeSectionBody>
        </Panel>
      )
    }
  )
)
