import _ from 'lodash'
import { safeMapFnCreator } from './immutableHelpers'
import { resourceURIParser } from './URLHelper'

let helper = {}

const STORAGE_TYPE = {
  AWS: 0,
  ONEDRIVE_PERSONAL: 1,
  ONEDRIVE_ADMIN: 2,
}

const KEYS = {
  FILENAME: 'filename',
  ID: 'id',
  ITEM_RESOURCE_URI: 'item',
  MD5SUM: 'md5sum',
  RESOURCE_URI: 'resource_uri',
  SIZE: 'size',
  STORAGE: 'storage',
  TIMESTAMP: 'timestamp',
  URL: 'url',
}
helper.KEYS = KEYS

helper.getID = f => f.get(KEYS.ID)
helper.getItemURI = f => f.get(KEYS.ITEM_RESOURCE_URI)
helper.getFilename = f => f.get(KEYS.FILENAME)
helper.getURI = f => f.get(KEYS.RESOURCE_URI)
helper.getMD5 = f => f.get(KEYS.MD5SUM)
helper.getSize = f => f.get(KEYS.SIZE)
helper.getTimestamp = f => f.get(KEYS.TIMESTAMP)
helper.getURL = f => f.get(KEYS.URL)
helper.getStorageType = f => f.get(KEYS.STORAGE)
helper.isOneDriveStorage = f => {
  const s = helper.getStorageType(f)
  return _.some([STORAGE_TYPE.ONEDRIVE_PERSONAL, STORAGE_TYPE.ONEDRIVE_ADMIN], t => t === s)
}

helper.getItemID = f => {
  const itemURI = helper.getItemURI(f)
  const r = resourceURIParser(itemURI)
  const serverID = r && r.id
  return serverID ? serverID : 0
}

// In the server, the name is the last component of the URL of the file,
// To create the URL the spaces are changed by _,
// so the check needs to be done with the substitution
export const safeNameFromFile = name => _.replace(name, / /g, '_') // All occurrences

helper = _.mapValues(helper, f => (_.isFunction(f) ? safeMapFnCreator(f) : f))

export default helper
