import React from 'react'
import _ from 'lodash'
import { cn } from '@appfluence/classnames'
import styled from 'styled-components'
import { TooltipHost } from '@/components/tooltip/TooltipHost'
import { projectHelper } from '../../../common/src/helpers'

const Container = styled.div`
  display: flex;
  height: ${p => p.size}px;
  width: ${p => p.size}px;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Quadrant = styled.div`
  background: ${p => p.theme.palette.neutralLight};
  height: 46%;
  width: 46%;
  &.selected {
    background: ${p => p.theme.palette.themePrimary};
  }
  &.bottom {
    align-self: flex-end;
  }
  &.click {
    cursor: pointer;
  }
`

const arr = _.range(4)
const clGetter = (i, selected, onClick) =>
  cn({
    selected: i === selected,
    bottom: i > 1,
    click: !!onClick,
  })

const OfficeLikeQuadrantSelector_ = ({ size = 40, selected = 0, project, className, onClickQuadrant }) => {
  const generateOnClick = i => () => onClickQuadrant?.(i)
  const Quadrants = _.map(arr, i => (
    <Quadrant className={clGetter(i, selected, onClickQuadrant)} key={i} onClick={generateOnClick(i)} />
  ))
  const matrix = (
    <Container size={size} className={className}>
      {Quadrants}
    </Container>
  )
  const qName = projectHelper.getQuadrantName(project, selected)
  if (qName) {
    return <TooltipHost content={qName}>{matrix}</TooltipHost>
  }
  return matrix
}

export const OfficeLikeQuadrantSelector = React.memo(OfficeLikeQuadrantSelector_)
