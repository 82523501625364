import React, { memo, useCallback, useMemo } from 'react'
import { ContainerDrawer } from '@/components/panels/ContainerDrawer'
import { PanelView } from '../PanelView'
import { ItemRecurrencePanelPart } from './ItemRecurrencePanelPart'
import { LocalizedDatePickerV9 } from '../../pickers/LocalizedDatePicker'
import { useDidChangeDate } from '../../../hooks/dateHooks'
import { useTranslation } from 'react-i18next'
import { Button, Field, Switch, Textarea } from '@fluentui/react-components'
import { reminderHelper } from '../../../common/src/helpers'
import { TimePickerCombo } from '../../timepicker/TimePickerCombo'

const REMINDER_FREQUENCY_KEYS = [
  reminderHelper.FREQUENCY_TYPE.ONCE,
  reminderHelper.FREQUENCY_TYPE.WEEKDAYS,
  reminderHelper.FREQUENCY_TYPE.DAILY,
  reminderHelper.FREQUENCY_TYPE.WEEKLY,
  reminderHelper.FREQUENCY_TYPE.BIWEEKLY,
  reminderHelper.FREQUENCY_TYPE.MONTHLY,
  reminderHelper.FREQUENCY_TYPE.YEARLY,
]

const ReminderPanelView = memo(
  ({
    reminderDate,
    allFollowers,
    onChangeReminderDate,
    onToggleAllFollowers,
    reminderFrequency,
    onChangeReminderFrequency,
    reminderNotes,
    onChangeReminderNotes,
    reminderEnabled,
    onChangeReminderEnabled,
    ...rest
  }) => {
    const onClearDate = useCallback(() => {
      onChangeReminderDate(0)
      onChangeReminderEnabled(false)
    }, [onChangeReminderDate, onChangeReminderEnabled])
    const { t } = useTranslation()

    const didChangeDateHandlerCreator = useDidChangeDate()

    const _handleChangeReminderDate = useCallback(
      value => {
        if (value) {
          onChangeReminderDate(value)
        } else {
          onClearDate()
        }
      },
      [onChangeReminderDate, onClearDate]
    )

    const handleChangeReminderDate = useMemo(() => {
      return didChangeDateHandlerCreator(reminderDate, _handleChangeReminderDate)
    }, [didChangeDateHandlerCreator, reminderDate, _handleChangeReminderDate])

    const onBlurNotes = useCallback(
      ev => {
        onChangeReminderNotes(ev.target.value)
      },
      [onChangeReminderNotes]
    )

    const onChangeAllFollowers = useCallback(
      (ev, data) => {
        onToggleAllFollowers(data.checked)
      },
      [onToggleAllFollowers]
    )

    const headerChildren = (
      <Button appearance="transparent" className="!min-w-0" disabled={!reminderEnabled} onClick={onClearDate}>
        {t('general.clear')}
      </Button>
    )
    return (
      <PanelView headerChildren={headerChildren} {...rest}>
        <LocalizedDatePickerV9
          label={t('item.reminder.reminder_date')}
          value={reminderEnabled ? reminderDate : null}
          onSelectDate={handleChangeReminderDate}
        />
        {reminderDate && reminderEnabled && (
          <TimePickerCombo id="startTimePicker" value={reminderDate} onChange={onChangeReminderDate} />
        )}
        {!!onToggleAllFollowers && reminderDate && reminderEnabled && (
          <Switch label={t('item.reminder.all_followers')} checked={allFollowers} onChange={onChangeAllFollowers} />
        )}
        {reminderDate && reminderEnabled && (
          <ItemRecurrencePanelPart
            frequency={reminderFrequency}
            onChangeFrequency={onChangeReminderFrequency}
            frequencyKeys={REMINDER_FREQUENCY_KEYS}
            isReminder={true}
          />
        )}
        {reminderDate && reminderEnabled && (
          <Field label={t('item.notes')}>
            <Textarea
              id="itemReminder_notesTextField"
              onBlur={onBlurNotes}
              placeholder={t('item.reminder.notes_placeholder')}
              defaultValue={reminderNotes}
              textarea={{ className: '!min-h-20' }}
            />
          </Field>
        )}
      </PanelView>
    )
  }
)

export const ItemReminderPanel = memo(
  ({
    reminderDate,
    headerText,
    allFollowers,
    onChangeReminderDate,
    onToggleAllFollowers,
    reminderFrequency,
    onChangeReminderFrequency,
    onDismiss,
    reminderNotes,
    onChangeReminderNotes,
    reminderEnabled,
    onChangeReminderEnabled,
    ...rest
  }) => {
    const { t } = useTranslation()
    const ht = headerText ?? t('item.reminder.edit_reminder')
    return (
      <ContainerDrawer headerText={ht} onDismiss={onDismiss} {...rest}>
        <ReminderPanelView
          reminderDate={reminderDate}
          allFollowers={allFollowers}
          onChangeReminderDate={onChangeReminderDate}
          onToggleAllFollowers={onToggleAllFollowers}
          reminderFrequency={reminderFrequency}
          onChangeReminderFrequency={onChangeReminderFrequency}
          reminderNotes={reminderNotes}
          onChangeReminderNotes={onChangeReminderNotes}
          reminderEnabled={reminderEnabled}
          onChangeReminderEnabled={onChangeReminderEnabled}
        />
      </ContainerDrawer>
    )
  }
)
