import { PanelView } from '../panels/PanelView'
import { ContainerDrawer } from '@/components/panels/ContainerDrawer'
import { useTranslation } from 'react-i18next'
import { itemHelper } from '../../common/src/helpers'
import { customEffortFormatter, EFFORT_TRANSLATION_KEYS } from './commonEffort'
import { Field, Radio, RadioGroup } from '@fluentui/react-components'

const CUSTOM_KEY = 'CUSTOM'

export const EffortSelectionView = ({ effort = 0, estimatedEffort = 0, onChange }) => {
  const { t } = useTranslation()

  const customOption = (() => {
    if (estimatedEffort === itemHelper.DEFAULT_ESTIMATED_EFFORT[effort]) return null
    const { unit, value } = customEffortFormatter(estimatedEffort)
    return <Radio value={CUSTOM_KEY} label={t(`item.effort.long.custom_${unit}`, { count: value })} />
  })()

  return (
    <PanelView>
      <Field label={t('item.effort.title')}>
        <RadioGroup value={customOption ? CUSTOM_KEY : `${effort}`} onChange={(evt, data) => onChange?.(+data.value)}>
          {Object.keys(EFFORT_TRANSLATION_KEYS).map(key => (
            <Radio key={key} value={`${key}`} label={t(EFFORT_TRANSLATION_KEYS[key])} />
          ))}
          {customOption}
        </RadioGroup>
      </Field>
      <p className="text-xs text-pm-neutral-tertiary">{t('item.effort.note')}</p>
    </PanelView>
  )
}

export const EffortSelectionPanel = ({ effort, estimatedEffort, onChange, ...rest }) => {
  return (
    <ContainerDrawer {...rest}>
      <EffortSelectionView effort={effort} estimatedEffort={estimatedEffort} onChange={onChange} />
    </ContainerDrawer>
  )
}
