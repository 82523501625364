import { Record, Map } from 'immutable'
import _ from 'lodash'
import {
  API_EVENT,
  isPMApiDetailedAction,
  parseApiActionType,
  PERSIST_REHYDRATE_ACTION_TYPE,
} from '../helpers/reducerHelper'
import { PM_API_RESOURCES } from '../constants'
import { GENERIC_ACTION_TYPE } from '../actions/genericActions'
import { USER_GROUP_REDUCER_KEYS as KEYS } from './userGroupsReducerKeys'
import { userGroupHelper } from '../helpers'

const Base = Record({
  [KEYS.BY_ID]: Map(),
})

const rehydrate = userGroups => {
  const byId = userGroups.get(KEYS.BY_ID)
  const mappedById = byId.map(g => userGroupHelper.create(g))
  userGroups = userGroups.set(KEYS.BY_ID, mappedById)
  return Base(userGroups)
}

const storeUserGroups = (state, groups) => {
  return state.withMutations(_st => {
    _st.setIn([KEYS.BY_ID], Map())
    _.each(groups, group => {
      _st.setIn([KEYS.BY_ID, group.id], userGroupHelper.create(group))
    })
    return _st
  })
}

export const userGroups = (state = Base(), action) => {
  const { type, payload } = action
  const apiAction = parseApiActionType(type)
  if (isPMApiDetailedAction(apiAction)) {
    const { resource, event } = apiAction
    if (event === API_EVENT.SUCCESS) {
      switch (resource) {
        case PM_API_RESOURCES.USER_GROUPS:
          state = storeUserGroups(state, payload.objects)
      }
    }
  } else {
    switch (type) {
      case PERSIST_REHYDRATE_ACTION_TYPE:
        {
          if (payload.userGroups) {
            state = rehydrate(payload.userGroups)
          }
        }
        break
      case GENERIC_ACTION_TYPE.CLEAR_ALL:
        {
          state = Base()
        }
        break
    }
  }

  return state
}
