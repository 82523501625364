import { type ReactNode, useCallback, useMemo, useState } from 'react'
import { ItemDatesPanelContext, OnSaveItemDates } from '../../typedContexts'
import { ItemDatesPanel } from '../../components/panels/item/ItemDatesPanel'

export const DatesPanelSingleton = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false)
  const [onSaveDates, setOnSaveDates] = useState<OnSaveItemDates | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [dueDate, setDueDate] = useState<Date | null>(null)
  const [allDay, setAllDay] = useState(true)
  const [frequency, setFrequency] = useState<string | null>(null)
  const [untilDate, setUntilDate] = useState<Date | null>(null)

  const onChangeStartDate = useCallback(
    (startDate: Date) => {
      setStartDate(startDate)
      if (dueDate && startDate > dueDate) {
        setDueDate(startDate)
      }
    },
    [dueDate]
  )
  const onChangeDueDate = useCallback(
    (dueDate: Date) => {
      setDueDate(dueDate)
      if (startDate && startDate > dueDate) {
        setStartDate(dueDate)
      }
    },
    [startDate]
  )
  const handleDismiss = useCallback(() => {
    setOpen(false)
  }, [])

  const openPanel = useCallback((onSaveDates: OnSaveItemDates) => {
    setOpen(true)
    setOnSaveDates(() => onSaveDates)
    setDueDate(null)
    setStartDate(null)
    setAllDay(true)
    setFrequency(null)
    setUntilDate(null)
  }, [])
  const contextValue = useMemo(() => ({ openPanel }), [openPanel])

  const handleSaveDates = useCallback(() => {
    if (onSaveDates) {
      onSaveDates({ startDate, dueDate, allDay, frequency, untilDate })
    }
    setOpen(false)
  }, [onSaveDates, startDate, dueDate, allDay, frequency, untilDate])

  return (
    <ItemDatesPanelContext.Provider value={contextValue}>
      {children}
      <ItemDatesPanel
        // @ts-ignore
        startDate={startDate}
        dueDate={dueDate}
        allDay={allDay}
        frequency={frequency}
        untilDate={untilDate}
        onChangeStartDate={onChangeStartDate}
        onChangeDueDate={onChangeDueDate}
        onToggleAllDay={setAllDay}
        onChangeFrequency={setFrequency}
        onChangeUntilDate={setUntilDate}
        onSave={handleSaveDates}
        isOpen={open}
        onDismiss={handleDismiss}
      />
    </ItemDatesPanelContext.Provider>
  )
}
