import React, { useCallback, useEffect } from 'react'
import { Button, Spinner } from '@fluentui/react-components'
import { SimpleModal } from '../modal/SimpleModal'
import { QuadrantsDropdown } from '../dropdown/QuadrantsDropdown'
import { projectHelper } from '../../common/src/helpers'
import { useMergeState } from '../../common/src/hooks/enhancedHooks'
import { useTranslation } from 'react-i18next'
import { useMe } from '../../common/src/hooks/usersHooks'
import { copyToClipboard } from '../../common/src/helpers/clipboardUtils'
import { useShowToastDidCopyLinkToClipboard } from '../../hooks/clipboardHooks'
import { OwnersOfProjectDropdown } from '../dropdown/OwnersOfProjectDropdown'
import { useDispatch } from 'react-redux'
import { getProjectSecretEmails } from '../../common/src/actions/projectsAPI'
import styled from 'styled-components'

const modalStyles = {
  title: {
    fontWeight: 'bold',
    minWidth: '300px',
  },
}

const Text = styled.p`
  color: ${p => p.theme.palette.neutralSecondary};
  margin-top: 4px;
  margin-bottom: 10px;
`

export const ProjectCopySecretEmailModal = ({ project, isOpen, onDismiss, ...rest }) => {
  const { t } = useTranslation()
  const me = useMe()
  const [modalState, setModalState] = useMergeState({
    user: me,
    quadrant: 0,
    errorMessage: null,
    movedItem: null,
    showAlertModal: false,
    differentProject: false,
    loading: false,
  })
  const showToastDidCopyLinkToClipboard = useShowToastDidCopyLinkToClipboard()
  const dispatch = useDispatch()
  const projectID = projectHelper.getIdd(project)

  useEffect(() => {
    if (!isOpen) return
    const fetchAddresses = async () => {
      setModalState({ loading: true })
      await dispatch(getProjectSecretEmails(projectID))
      setModalState({ loading: false })
    }
    fetchAddresses()
  }, [dispatch, isOpen, projectID, setModalState])

  const { user, quadrant, errorMessage, loading } = modalState

  const onSetLoading = useCallback(
    loading => {
      setModalState({ loading })
    },
    [setModalState]
  )

  const createOnClickCopy = useCallback(async () => {
    onSetLoading(true)
    const link = projectHelper.getSecretEmailAddress(project, quadrant, user)
    copyToClipboard(link)
    showToastDidCopyLinkToClipboard()
    onSetLoading(false)
    onDismiss()
  }, [onSetLoading, project, quadrant, user, showToastDidCopyLinkToClipboard, onDismiss])

  const onUserChanged = useCallback(
    user => {
      setModalState({ user })
    },
    [setModalState]
  )

  const onQuadrantChanged = useCallback(
    quadrant => {
      setModalState({ quadrant })
    },
    [setModalState]
  )

  const button = (
    <>
      {loading && <Spinner size="extra-small" className="absolute left-6" />}
      <Button
        appearance="primary"
        id="copySecretEmailModal_copyButton"
        key={'copySecretEmailModal_copyButton'}
        onClick={createOnClickCopy}
        disabled={loading}
      >
        {t('project_detail.copy')}
      </Button>
    </>
  )

  return (
    <SimpleModal
      title={t('project_detail.get_secret_email')}
      errorMessage={errorMessage}
      buttons={button}
      styles={modalStyles}
      isOpen={isOpen}
      onDismiss={onDismiss}
      closeButtonProps={{
        disabled: loading,
      }}
    >
      <Text>{t('project_detail.secret_inbox_explain')}</Text>
      <OwnersOfProjectDropdown
        id="copySecretEmailModal_userDropdown"
        label={t('project_detail.owner_for_emailed_items')}
        user={user}
        project={project}
        onUserChanged={onUserChanged}
        disabled={loading}
      />
      <QuadrantsDropdown
        id="copySecretEmailModal_quadrantsDropdown"
        label={t('project.quadrant')}
        project={project}
        quadrant={quadrant}
        onQuadrantChanged={onQuadrantChanged}
        disabled={loading}
      />
    </SimpleModal>
  )
}
