import { useCallback, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { tagHelper } from '../../common/src/helpers'
import { validateTag } from '../../common/src/helpers/stringHelper'
import { renameTag } from '../../common/src/actions/tagsAPI'
import { KEY_CODE } from '../../common/src/helpers/keyboardHelper'
import { useTimeout } from '../../common/src/hooks/timeoutHooks'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Input,
  Spinner,
} from '@fluentui/react-components'

export const RenameTagModal = ({ tag, model, hidden, onDismiss, ...rest }) => {
  const [state, localDispatch] = useReducer(reducer, { ...initialState })
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { newTagName, isValid, inProgress } = state
  const tagName = tagHelper.getName(tag)

  const onChangeText = useCallback((ev, data) => {
    localDispatch({ type: ACTION_TYPE.SET_NEW_TAG_NAME, payload: data.value })
  }, [])

  const { set: setTimeout } = useTimeout()
  const rename = useCallback(async () => {
    localDispatch({ type: ACTION_TYPE.SET_IN_PROGRESS, payload: true })
    await dispatch(renameTag({ tag, newTagName, model }))
    setTimeout(() => {
      localDispatch({ type: ACTION_TYPE.CLEAR })
      onDismiss()
    }, 1500)
  }, [dispatch, tag, newTagName, model, setTimeout, onDismiss])

  const onKeyDown = useCallback(
    event => {
      if (event.charCode === KEY_CODE.ENTER) {
        event.preventDefault()
        event.stopPropagation()
        rename()
      }
    },
    [rename]
  )

  return (
    <Dialog
      open={!hidden}
      onOpenChange={(ev, data) => {
        if (!data.open) onDismiss()
      }}
    >
      <DialogSurface className="!max-w-sm">
        <DialogBody>
          <DialogTitle className="flex gap-4">
            {t('item.tag.rename_modal_title', { name: tagName })} {inProgress && <Spinner size="small" />}
          </DialogTitle>
          <DialogContent className="flex flex-col">
            <span className="mb-4 text-inherit">{t('item.tag.rename_modal_subtext')}</span>
            <Input autoFocus value={newTagName} onKeyDown={onKeyDown} onChange={onChangeText} label />
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary" disabled={inProgress}>
                {t('general.cancel')}
              </Button>
            </DialogTrigger>
            <Button appearance="primary" onClick={rename} disabled={inProgress || !isValid}>
              {t('general.rename')}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}

const ACTION_TYPE = {
  SET_NEW_TAG_NAME: 'SET_NEW_TAG_NAME',
  SET_IN_PROGRESS: 'SET_IN_PROGRESS',
  CLEAR: 'CLEAR',
}

const initialState = {
  newTagName: '',
  isValid: false,
  inProgress: false,
}

export const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case ACTION_TYPE.SET_NEW_TAG_NAME:
      state.newTagName = payload
      state.isValid = validateTag(payload)
      break
    case ACTION_TYPE.SET_IN_PROGRESS:
      state.inProgress = payload
      break
    case ACTION_TYPE.CLEAR:
      state = initialState
      break
    default:
      break
  }
  return { ...state }
}
