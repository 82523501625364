import { Set } from 'immutable'
import { MEETING_REDUCER_KEYS as KEYS } from '../reducers/meetingReducerKeys'
import { stateHelper } from '../helpers'

export const getMeetingsMap = state => {
  return state.getIn([KEYS.REDUCER, KEYS.BY_ID])
}

export const getMeeting = id => state => {
  return state.getIn([KEYS.REDUCER, KEYS.BY_ID, id])
}

export const getSelectedMeeting = state => {
  return state.getIn([KEYS.REDUCER, KEYS.SELECTED])
}

export const getMeetingItems = (state, id) => {
  const set = state.getIn([KEYS.REDUCER, KEYS.ITEMS_SET_BY_ID, id]) || Set()
  return stateHelper.getItemsFromIDs(state, set).toList()
}

export const getTeamsTab = id => state => state.getIn([KEYS.REDUCER, KEYS.TEAMS_TAB_BY_ID, id])
