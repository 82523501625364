import { useMemo } from 'react'
import { cn } from '@appfluence/classnames'
import { OptionsMenuButton } from '../buttons/OptionsMenuButton'
import { getSignOut } from '../../common/src/actions/authAPI'
import { useDispatch } from 'react-redux'
import { MainHeader } from './MainHeader'
import { Spinner } from '../basic/Loading'
import { useTranslation } from 'react-i18next'
import { SignOutRegular } from '@fluentui/react-icons'

export const useTeamsStaticTabMenuProps = () => {
  const dispatch = useDispatch()
  return useMemo(() => {
    return {
      items: [
        {
          key: 'signOut',
          text: 'Sign Out',
          onClick: () => {
            dispatch(getSignOut())
          },
          icon: <SignOutRegular />,
        },
      ],
    }
  }, [dispatch])
}

export const TeamsHeader = ({ title, productName, loading = false }) => {
  const menuProps = useTeamsStaticTabMenuProps()
  const { t } = useTranslation()
  const loadingCN = cn({
    pm_hidden: !loading,
  })
  return (
    <MainHeader title={title} productName={productName}>
      <Spinner className={loadingCN} label={t('general.loading')} labelPosition="right" size="medium" />
      <OptionsMenuButton menuProps={menuProps} />
    </MainHeader>
  )
}
