import React, { forwardRef, useMemo } from 'react'
import _ from 'lodash'
import { CircleInput } from '../input/CircleInput'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { ArrowSyncFilled } from '@fluentui/react-icons'
import { intervalToDuration } from 'date-fns'

const REMINDER_SET = 100
const REMINDER_NOT_SET = 0

export const ReminderCircle_ = forwardRef(
  ({ date, readOnly = false, onClick, isRecurrent, isEnabled, ...rest }, ref) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const existsDate = !!date && isEnabled

    const timeStrings = useMemo(
      () => ({
        years: t('date.shorted_time_left.years'),
        months: t('date.shorted_time_left.months'),
        days: t('date.shorted_time_left.days'),
        hours: t('date.shorted_time_left.hours'),
        minutes: t('date.shorted_time_left.minutes'),
        seconds: t('date.shorted_time_left.seconds'),
        milliseconds: t('date.shorted_time_left.milliseconds'),
      }),
      [t]
    )

    const formatDateToNumberAndChar = timePaired => {
      const arrayOfDates = []
      _.forEachRight(timePaired, d => {
        // d is a pair of timeString, timeNumber like [seconds, 18]
        const timeString = d[0]
        const timeNumber = d[1]
        if (timeNumber !== 0) {
          arrayOfDates.push(timeNumber.toString() + timeStrings[timeString])
        }
      })
      // returns the higher date format string
      return arrayOfDates[0]
    }

    const timeLeft = existsDate ? intervalToDuration({ start: new Date(), end: date }) : null
    const formatedTimeLeft = formatDateToNumberAndChar(_.toPairs(timeLeft).reverse())

    const isPastDate = _.includes(formatedTimeLeft, '-')

    const progress = existsDate && !isPastDate ? REMINDER_SET : REMINDER_NOT_SET
    const colorForCircle = existsDate && !isPastDate ? theme.palette.orangeLighter : theme.palette.neutralLight

    const baseText = formatedTimeLeft ?? '..'
    const text = isPastDate ? '!' : baseText

    const localOnClick = () => {
      if (!readOnly) {
        onClick?.()
      }
    }

    return (
      <div ref={ref} className="relative flex" {...rest}>
        {isEnabled && isRecurrent && date && (
          <div className="pointer-events-none absolute flex h-4 w-4 items-center justify-center rounded-full bg-pm-orange ">
            <ArrowSyncFilled className="text-xs" />
          </div>
        )}
        <CircleInput text={text} value={progress} onClick={localOnClick} color={colorForCircle} />
      </div>
    )
  }
)

export const ReminderCircle = React.memo(ReminderCircle_)
