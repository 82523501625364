import { useCreateItemModal } from '@/hooks/useCreateItemModal'
import { isClearSelectionHotKey, isCreateItemHotKey, isGoToHomeHotKey, useHotkey } from '../hooks/useHotkey'
import { useDispatch } from 'react-redux'
import { clearItemMultipleSelection, setMultipleSelectionOn } from '../actions/uiActions'
import { useHistory } from 'react-router'
import { getRelativeURLKeepingQuerySearch } from '@/helpers/routeHelper'

export const ShortcutListener = () => {
  const createItemModal = useCreateItemModal()
  const dispatch = useDispatch()
  const history = useHistory()

  const openModal = () => {
    createItemModal({
      displayProjectSelector: true,
      open: true,
    })
  }
  const clearSelection = () => {
    dispatch(clearItemMultipleSelection())
    dispatch(setMultipleSelectionOn(false))
  }

  const goToHome = () => {
    history.push(getRelativeURLKeepingQuerySearch.homeSection())
  }

  useHotkey(isCreateItemHotKey, openModal)
  useHotkey(isClearSelectionHotKey, clearSelection)
  useHotkey(isGoToHomeHotKey, goToHome, true)

  return false
}
