import { objToQueryParamsString } from './functionalUtils'
import { teamsAppId, teamsPublisher } from '../selectors/configSelectors'

const prepareContext = context => {
  if (!context) {
    return {}
  }
  return {
    context: JSON.stringify(context),
  }
}

const createDeepLink =
  ({ appId, entityId }) =>
  context =>
    `https://teams.microsoft.com/l/entity/${appId}/${entityId}?${objToQueryParamsString(prepareContext(context))}`

export const SUB_ENTITY_TYPES = {
  ITEM: 'item',
  PROJECT: 'project',
  ONE_ON_ONE: 'oneonone',
  ONBOARDING: 'onboarding',
  SEARCH: 'search',
}

export const getDeeplink = store => {
  const state = store.getState()
  return getDeeplinkWithAppInfo({
    appId: teamsAppId(state),
    publisher: teamsPublisher(state),
  })
}

export const getDeeplinkWithAppInfo = appInfo => {
  const TEAMS_APP_ID = appInfo.appId
  const TEAMS_ENTITY_ID = appInfo.publisher + '.teams.webapp'
  const create = createDeepLink({ appId: TEAMS_APP_ID, entityId: TEAMS_ENTITY_ID })
  return {
    toItem: id =>
      create({
        subEntityId: `${SUB_ENTITY_TYPES.ITEM}-${id}`,
      }),
    toProject: id =>
      create({
        subEntityId: `${SUB_ENTITY_TYPES.PROJECT}-${id}`,
      }),
    toOneOnOne: email =>
      create({
        subEntityId: `${SUB_ENTITY_TYPES.ONE_ON_ONE}-${email}`,
      }),
  }
}

// this is an example of a prod deeplink
//https://teams.microsoft.com/l/entity/5be2b320-a5b7-4221-893c-dee506e4e365/com.appfluence.teams.webapp?context=%7B%22subEntityId%22%3A%20%22item-671483300%22%7D
