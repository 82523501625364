import { Button, Field, Spinner, Textarea } from '@fluentui/react-components'
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Send } from '../../components/BundledIcons'
import { useDispatch } from 'react-redux'
import { createProjectFromGoal, getGoalExamples } from '../../common/src/actions/projectsAPI'
import { API_EVENT, parseApiActionType } from '../../common/src/helpers/reducerHelper'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { useRouter } from '../../hooks/useRouterHook'
import { useTranslation } from 'react-i18next'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { useConfig } from '../../queries/config'
import { usePublicProjectTemplateAISearch } from '@/queries/projectTemplates'
import { ProjectTemplateList } from '@/views/newProjectModal/templates/ProjectTemplateList'
import { PROJECT_TEMPLATE_TYPE } from '@/views/newProjectModal/ProjectTemplateType'
import type { PublicTemplate } from '@/types/projectTemplate'

const projectPurposeExamples = [
  { title: 'Launch an E-commerce Store', prompt: 'Start and grow an online store for various products' },
  {
    title: 'Become a Certified Project Manager',
    prompt: 'Obtain certification and skills to effectively manage projects',
  },
  { title: 'Improve work-life balance', prompt: 'Achieve a healthier balance between work and personal life' },
  {
    title: "Optimize the company's supply chain",
    prompt: 'Streamline processes to enhance product delivery and reduce costs',
  },
  {
    title: "Elevate the company's IT infrastructure",
    prompt: 'Upgrade technology systems for improved efficiency and security',
  },
] satisfies GoalExample[]

type GoalExample = { title: string; prompt: string }

const useGoalExamples = () => {
  const dispatch = useDispatch()
  const [examples, setExamples] = useState<GoalExample[]>([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const fetchExamples = async () => {
      setLoading(true)
      const response = (await dispatch(getGoalExamples() as any)) as any
      const parsed = parseApiActionType(response.type)
      const event = parsed?.event
      if (event === API_EVENT.SUCCESS) {
        const data: any = response.payload
        setExamples(Array.isArray(data) ? data : projectPurposeExamples)
      } else {
        setExamples(projectPurposeExamples)
      }
      setLoading(false)
    }
    fetchExamples()
  }, [dispatch])
  return { examples, loading }
}

type Step = 'idle' | 'loading' | 'error' | 'success' | 'template_search'

interface ViewState {
  step: Step
  goal: string
}

export type CreateProjectWithAIProps = {
  onSelectTemplate: (template: PublicTemplate) => void
}

export const CreateProjectWithAI = ({ onSelectTemplate }: CreateProjectWithAIProps) => {
  const { examples, loading: examplesLoading } = useGoalExamples()
  const { t } = useTranslation()
  const { data: config } = useConfig()
  const aiEnabledByAccount = config?.ai
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const { history, location } = useRouter()
  const dispatch = useDispatch()
  const [step, setStep] = useState<Step>('idle')
  const [error, setError] = useState<string | null>(null)
  const [goal, setGoal] = useState('')
  const initialized = useRef(false)

  // we just want to exec this code once when the component mounts
  useLayoutEffect(() => {
    if (!initialized.current && location.state?.step && location.state?.goal) {
      initialized.current = true
      setStep(location.state.step)
      setGoal(location.state.goal)
    }
  }, [setStep, setGoal, location.state?.step, location.state?.goal])

  const onTemplateSearchError = useCallback((error: string) => {
    setStep('error')
    setError(error)
  }, [])

  const submitGoal = useCallback(
    async (goal: string) => {
      setStep('loading')
      dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.AI_CREATE_PROJECT))
      const response = (await dispatch(createProjectFromGoal(goal) as any)) as any
      const parsed = parseApiActionType(response.type)
      const event = parsed?.event
      if (event === API_EVENT.SUCCESS) {
        setStep('success')
      } else {
        setStep('error')
      }
    },
    [dispatch]
  )

  if (!aiEnabledByAccount) {
    return <PlaceholderAIDisabled />
  }

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const target = e.target as typeof e.target & { goal: { value: string } }
    const goal = target.goal.value
    setGoal(goal)
    setStep('template_search')
    e.preventDefault()
  }

  const closeNewProjectModal = () => {
    const from = location?.state?.from || getRelativeURLKeepingQuerySearch.projects()
    history.push(from)
  }

  const onSelectTemplateWithState = (template: PublicTemplate) => {
    history.replace({ ...history.location }, {
      step,
      goal,
    } satisfies ViewState)
    onSelectTemplate(template)
  }

  return (
    <div className="mx-auto flex w-11/12 flex-1 flex-col items-center py-8">
      {step === 'template_search' ? (
        <TemplateSearch
          goal={goal}
          submitGoal={submitGoal}
          onSelectTemplate={onSelectTemplateWithState}
          onTemplateSearchError={onTemplateSearchError}
        />
      ) : step === 'success' ? (
        <>
          <span className="text-xl text-pm-black">{t('new_project.create_with_ai.success_title')}</span>
          <span className="mt-4 text-base text-pm-black">{t('new_project.create_with_ai.success_subtitle_1')}</span>
          <span className="text-base text-pm-black">{t('new_project.create_with_ai.success_subtitle_2')}</span>
          <Button appearance="primary" className="!mt-8" onClick={closeNewProjectModal}>
            {t('general.close')}
          </Button>
        </>
      ) : (
        <>
          <span className="text-xl text-pm-black">{t('new_project.create_with_ai.idle_title')}</span>
          {step === 'loading' ? (
            <Spinner size="small" label="Loading..." className="mt-2" />
          ) : (
            <span className="text-base text-pm-black">{t('new_project.create_with_ai.idle_subtitle')}</span>
          )}
          <form className="mt-6 flex w-full max-w-xs flex-col gap-3" onSubmit={onFormSubmit}>
            <Field
              validationState={step === 'error' ? 'warning' : undefined}
              validationMessage={step === 'error' ? (error ?? t('new_project.create_with_ai.error')) : undefined}
            >
              <div className="flex gap-1">
                <Textarea
                  ref={textareaRef}
                  value={goal}
                  onChange={(event, data) => setGoal(data.value)}
                  name="goal"
                  autoFocus
                  className="h-20 flex-1"
                  appearance="outline"
                  placeholder={t('new_project.create_with_ai.goal_input_placeholder')}
                  required
                  disabled={step === 'loading'}
                />
                <Button disabled={step === 'loading'} type="submit" icon={<Send />} />
              </div>
            </Field>
            {examplesLoading && <Spinner label={t('new_project.create_with_ai.loading_examples')} />}
            {!examplesLoading &&
              examples.map((example, index) => (
                <Button
                  key={index}
                  type="button"
                  onClick={() => {
                    setGoal(example.prompt)
                    textareaRef.current?.focus()
                  }}
                  disabled={step === 'loading'}
                >
                  {example.title}
                </Button>
              ))}
          </form>
        </>
      )}
    </div>
  )
}

const PlaceholderAIDisabled = () => {
  const { t } = useTranslation()
  return (
    <div className="mx-auto flex w-11/12 flex-col items-center py-8">
      <span className="text-xl">{t('new_project.create_with_ai.disabled_title')}</span>
      <span className="mt-4 text-base">{t('new_project.create_with_ai.disabled_subtitle')}</span>
    </div>
  )
}

type TemplateSearchProps = {
  goal: string
  submitGoal: (goal: string) => void
  onSelectTemplate: (template: PublicTemplate) => void
  onTemplateSearchError: (error: string) => void
}
const TemplateSearch = ({ goal, submitGoal, onSelectTemplate, onTemplateSearchError }: TemplateSearchProps) => {
  const { t } = useTranslation()
  const { data, isLoading, error } = usePublicProjectTemplateAISearch({ text: goal })

  useEffect(() => {
    if (error) {
      onTemplateSearchError(error.message)
    }
    if (!isLoading && !error && (!data || data.length === 0)) {
      submitGoal(goal)
    }
  }, [data, error, goal, isLoading, onTemplateSearchError, submitGoal])

  if (isLoading || !data) {
    return <Spinner />
  }

  return (
    <div className="flex w-full flex-1 flex-col items-center">
      <span>{t('new_project.create_with_ai.use_template_instead')}</span>
      <ProjectTemplateList
        templates={data}
        type={PROJECT_TEMPLATE_TYPE.PUBLIC}
        onSelect={onSelectTemplate}
        className="flex-1"
        loading={undefined}
        onDelete={undefined}
      />
      <Button onClick={() => submitGoal(goal)}>{t('new_project.create_with_ai.continue_using_ai')}</Button>
    </div>
  )
}
