import { fromJS } from 'immutable'
import { DEFAULT_TEAMS_LOCATION, TEAMS_KEYS } from './teamsKeys'
import { PERSIST_REHYDRATE_ACTION_TYPE } from '../helpers/reducerHelper'
import { ACTION_TYPES } from '../actions/teamsActions'
import { GENERIC_ACTION_TYPE } from '../actions/genericActions'

const base = fromJS({
  [TEAMS_KEYS.LOCATION]: DEFAULT_TEAMS_LOCATION, //chatId or channelId. Default in case it's a browser tab
  [TEAMS_KEYS.CONVERSATION_ID]: '',
  [TEAMS_KEYS.TENANT_ID]: '',
  [TEAMS_KEYS.MEETING_ID]: '',
  [TEAMS_KEYS.SUB_ENTITY_ID]: '',
  [TEAMS_KEYS.DID_LOAD_CONTEXT]: false,
  [TEAMS_KEYS.DID_CONSUME_DEEPLINK]: false,
  [TEAMS_KEYS.RAW_CONTEXT]: undefined,
})

export const teams = (state = base, action) => {
  const { type, payload } = action
  switch (type) {
    case GENERIC_ACTION_TYPE.CLEAR_ALL:
      {
        state = base
      }
      break
    case PERSIST_REHYDRATE_ACTION_TYPE:
      {
        if (payload.teams) {
          state = state.merge(payload.teams)
        }
      }
      break
    case ACTION_TYPES.PARSE_TEAMS_CONTEXT: {
      state = state.withMutations(st => {
        const l = payload.channel || payload.chat
        st.set(TEAMS_KEYS.DID_LOAD_CONTEXT, true)
        st.set(TEAMS_KEYS.SUB_ENTITY_ID, payload.page?.subPageId)
        st.set(TEAMS_KEYS.LOCATION, l)
        st.set(TEAMS_KEYS.CONVERSATION_ID, payload.channel || '') //api is not working with chatId
        st.set(TEAMS_KEYS.TENANT_ID, payload.user?.tenant?.id)
        st.set(TEAMS_KEYS.MEETING_ID, payload.meeting)
        st.set(TEAMS_KEYS.RAW_CONTEXT, payload)
        return st
      })
      break
    }
    case ACTION_TYPES.CONSUME_DEEP_LINK:
      state = state.set(TEAMS_KEYS.DID_CONSUME_DEEPLINK, true)
      break
  }
  return state
}
