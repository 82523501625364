import { Map, fromJS } from 'immutable'
import {
  API_EVENT,
  isPMApiDetailedAction,
  parseApiActionType,
  PERSIST_REHYDRATE_ACTION_TYPE,
} from '../helpers/reducerHelper'
import { PM_API_RESOURCES } from '../constants'
import { AUTH_ACTIONS } from '../actions/AuthActions'
import { GENERIC_ACTION_TYPE } from '../actions/genericActions'
import { AUTH_KEYS } from './authKeys'

const base = Map({
  [AUTH_KEYS.AUTHENTICATED]: true,
  [AUTH_KEYS.SIGNED_OUT]: false,
  [AUTH_KEYS.ACCESS_TOKEN]: null, // Used in office-addin
  [AUTH_KEYS.LICENSE_STATUS]: undefined,
})

const successHandlers = {
  [PM_API_RESOURCES.ME]: state => {
    return state.set(AUTH_KEYS.AUTHENTICATED, true)
  },
  [PM_API_RESOURCES.LICENSE_STATUS]: (state, { payload }) => {
    return state.set(AUTH_KEYS.LICENSE_STATUS, fromJS(payload))
  },
}

export const auth = (state = base, action) => {
  const { type } = action
  const apiAction = parseApiActionType(type)
  if (isPMApiDetailedAction(apiAction)) {
    if (apiAction.event === API_EVENT.SUCCESS) {
      const fn = successHandlers[apiAction.resource]
      state = fn ? fn(state, action) : state
    } else if (apiAction.event === API_EVENT.ERROR) {
      switch (apiAction.resource) {
        case PM_API_RESOURCES.ME:
          state = state.set(AUTH_KEYS.AUTHENTICATED, false)
          break
        case PM_API_RESOURCES.SIGN_OUT:
          state = state.set(AUTH_KEYS.AUTHENTICATED, false)
          state = state.set(AUTH_KEYS.SIGNED_OUT, true)
          break
      }
    }
  } else {
    switch (type) {
      case PERSIST_REHYDRATE_ACTION_TYPE:
        {
          if (action.payload.auth) {
            state = state.merge(action.payload.auth)
          }
        }
        break
      case AUTH_ACTIONS.MANUALLY_SET_TOKEN:
        {
          const { accessToken } = action.payload
          state = base.set(AUTH_KEYS.ACCESS_TOKEN, accessToken)
        }
        break
      case AUTH_ACTIONS.INIT_AUTHENTICATED_STATUS:
        {
          const { authenticated } = action.payload
          state = base.set(AUTH_KEYS.AUTHENTICATED, authenticated)
        }
        break
      case GENERIC_ACTION_TYPE.CLEAR_ALL:
        {
          state = base
        }
        break
    }
  }
  return state
}
