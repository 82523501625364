export const PROJECT_TEMPLATE_TYPE = {
  BLANK: 'blank',
  CUSTOM: 'custom',
  PUBLIC: 'public',
  IMPORT_CSV: 'import_csv',
}

const publicProjectTemplateHelper = {
  getCategory: template => template.category,
  getDownloads: template => template.downloads,
  getID: template => template.id,
  getImage: template => template.image || template.image_url,
  getName: template => template.name,
  getNotes: template => template.short_description,
  getDescription: template => template.description,
  getKey: template => template.key,
  getProjectResourceURI: template => template.project,
  getProject: template => template.project,
  getResourceURI: template => template.resource_uri,
  getReadonlyUrl: template => template.readonly,
  sortDescriptorByDownloads: template => template.downloads,
}

const customProjectTemplateHelper = {
  getID: template => template.id,
  getImage: template => template.image,
  getName: template => template.name,
  getNotes: template => template.notes,
  getResourceURI: template => template.resource_uri,
}

export const helperByProjectTemplateType = {
  [PROJECT_TEMPLATE_TYPE.CUSTOM]: customProjectTemplateHelper,
  [PROJECT_TEMPLATE_TYPE.PUBLIC]: publicProjectTemplateHelper,
}
