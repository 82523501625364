import { useToastController } from '@fluentui/react-components'
import { useId } from 'react'
import { FluentToast } from '../components/toast/FluentToast'
import { getCSVExportSelectionURL } from '../common/src/constants'
import { readString } from 'react-papaparse'
import { useTranslation } from 'react-i18next'
import type { ParseResult } from 'papaparse'
import type { ItemFromCSV } from '../types/item'

export const useDownloadItemsCSV = () => {
  const { dispatchToast, dismissToast, updateToast } = useToastController()
  const toastId = useId()
  const { t } = useTranslation()

  return async (itemsIds: number[]) => {
    if (!itemsIds.length) {
      dispatchToast(<FluentToast>{t('item.multiple_selection.download_items_empty')}</FluentToast>, {
        intent: 'error',
        toastId: toastId,
        timeout: 5000,
      })

      return
    }
    const body = {
      items: itemsIds,
    }
    dispatchToast(<FluentToast>{t('item.multiple_selection.email_downloading')}</FluentToast>, {
      intent: 'info',
      toastId: toastId,
    })
    const response = await fetch(getCSVExportSelectionURL(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      updateToast({
        toastId: toastId,
        content: <FluentToast>{t('item.multiple_selection.email_failed')}</FluentToast>,
        intent: 'error',
      })
      return
    }

    dismissToast(toastId)

    const csvText = await response.text()
    const parsedCsv = await new Promise<ParseResult<ItemFromCSV>>(resolve => {
      readString<ItemFromCSV>(csvText, {
        dynamicTyping: true,
        header: true,
        complete: results => {
          resolve(results)
        },
      })
    })
    return parsedCsv.data.slice(0, -1)
  }
}
