import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { getMatchParameter } from '../../common/src/utils'
import { ProjectDetailView } from '../project/ProjectDetail'
import { getProtocolLinkForProjectWithId } from '../../common/src/constants'
import { redirectSubject } from '../../reactions'
import { isInApp } from '../../helpers/routeHelper'
import { useSelectedProject } from '../../hooks/PMHooks'
import { InvalidObjectComponent, useIsObjectInvalidToBeShown } from '../errorViews/InvalidObjectComponent'
import { Loading } from '../../components/basic/Loading'
import { useFetch } from '../../common/src/hooks/networkingHooks'
import { getProjectIfNotPending } from '../../common/src/actions/combinedAPI'
import { SingleLayout } from '../../components/layout/SingleLayout'

export const SingleProjectView = ({ match, history, ...rest }) => {
  const dispatch = useDispatch()
  const id = _.parseInt(getMatchParameter(match, 'pid'))
  const project = useSelectedProject()
  const fetchProject = useCallback(
    () =>
      dispatch(
        getProjectIfNotPending({
          projectID: id,
          memberships: true,
        })
      ),
    [dispatch, id]
  )
  const { error, loading } = useFetch(fetchProject)
  const { pathname } = history.location
  const inApp = isInApp(pathname)

  useEffect(() => {
    if (id && !inApp) {
      const pmURL = getProtocolLinkForProjectWithId(id)
      redirectSubject.next(pmURL)
    }
  }, [id, inApp])

  return <SingleProjectPrivate id={id} project={project} loading={loading} error={error} history={history} {...rest} />
}

const SingleProjectPrivate = ({ id, project, loading = false, error, ...rest }) => {
  const isObjectInvalidToBeShown = useIsObjectInvalidToBeShown()

  const renderProjectDetail = useCallback(() => {
    if (loading) {
      return <Loading />
    }

    if (isObjectInvalidToBeShown(project, error)) {
      return <InvalidObjectComponent object={project} error={error} />
    }

    return <ProjectDetailView key="singleProject" />
  }, [project, loading, error, isObjectInvalidToBeShown])

  const main = (
    <div className="flex h-full min-h-[1px] justify-center">
      <main className="min-w-[300px] max-w-[600px] flex-1">{renderProjectDetail()}</main>
    </div>
  )

  return <SingleLayout object={project} main={main} {...rest} />
}
