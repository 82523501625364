import React from 'react'
import { MainLayout } from '../../components/layout/MainLayout'
import { RightDetailView } from '../rightDetailView/RightDetailView'
import { HomeMain } from './HomeMain'

export const HomeView = ({ match, history }) => {
  const components = {
    main: <HomeMain match={match} history={history} />,
    rightSide: <RightDetailView />,
  }
  return <MainLayout {...components} />
}
