import React, { memo, useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import _ from 'lodash'
import { IconButton } from '@fluentui/react/lib/Button'
import { PROJECT_MODE, projectModesProps } from '../../utils/projectModesUtils'
import { useTranslation } from 'react-i18next'

const displayName = 'ProjectModeButton'

const StyledIconButton = styled(IconButton)`
  color: ${p => p.theme.palette.themePrimary};
  fill: ${p => p.theme.palette.themePrimary};
`

const useMenuItems = (onClick = _.identity, readOnly = false) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const menuItems = useMemo(() => {
    return _.map(projectModesProps(t), p => {
      p.key = `${displayName}_${p.mode}Item`
      p.iconProps = {
        iconName: p.iconName,
        styles: {
          root: {
            fill: theme.palette.themePrimary,
          },
        },
      }
      return p
    })
  }, [t, theme])
  const filteredItems = readOnly ? _.filter(menuItems, item => item.readOnly === readOnly) : menuItems
  _.forEach(filteredItems, item => {
    item.onClick = () => onClick(item.mode)
  })
  return filteredItems
}

export const ProjectModeButton = memo(
  ({ selectedMode = PROJECT_MODE.MATRIX, onClick = _.identity, readOnly = false, ...rest }) => {
    const items = useMenuItems(onClick, readOnly)
    const selectedItem = _.find(items, item => item.mode === selectedMode) || items[0]
    const selectedItemIconProps = selectedItem.iconProps
    return <StyledIconButton menuProps={{ items }} iconProps={selectedItemIconProps} {...rest} />
  }
)
