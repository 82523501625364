import { Label } from '../text/Text'
import { cn } from '@/modules/classnames'
import { forwardRef } from 'react'

const styles = {
  label: { fontSize: '10px' },
}

export const FieldDetail = forwardRef(
  ({ children = false, contentStyle = {}, name, className, styleLabel = {}, ...rest }, ref) => {
    return (
      <div ref={ref} className={cn('relative flex min-w-0 flex-col items-start', className)} {...rest}>
        <div style={styleLabel}>
          <Label style={styles.label}>{name}</Label>
        </div>
        <div style={contentStyle} className="flex flex-col items-center justify-center">
          {children}
        </div>
      </div>
    )
  }
)
