import React, { memo, useCallback, useMemo } from 'react'
import _ from 'lodash'
import { cn } from '@appfluence/classnames'
import { mergeStyleSets } from '@fluentui/react/lib/Styling'
import {
  quadrantsColorsTemplatesByKey,
  quadrantsColorsTemplatesKeys,
} from '../../../templates/quadrantsColorsTemplates'
import { TileStructure } from '../../tile/TileStructure'
import { QuadrantSelector } from './QuadrantSelector'
import { useTheme } from 'styled-components'

const classNames = {
  container: 'QuadrantsThemesCollection-container',
  tile: 'QuadrantsThemesCollection-tile',
  selectedTile: 'QuadrantsThemesCollection-selectedTile',
  tileChildrenContainer: 'QuadrantsThemesCollection-tileChildrenContainer',
  card: 'QuadrantsThemesCollection-card',
}

const quadrantSelectorSize = 32
const tileSizePX = `${quadrantSelectorSize + 12}px !important`

const useClassNames = () => {
  const theme = useTheme()
  return useMemo(() => {
    return mergeStyleSets({
      container: [
        classNames.container,
        {
          display: 'flex',
          alignItems: 'center',
          overflowX: 'auto',
          selectors: {
            [`.${classNames.tile}`]: { marginLeft: 12 },
            [`.${classNames.tile}:first-child`]: { marginLeft: 0 },
          },
        },
      ],
      tile: [
        classNames.tile,
        {
          boxSizing: 'content-box !important',
          width: tileSizePX,
          minWidth: tileSizePX,
          height: tileSizePX,
          padding: '4px !important',
          borderRadius: '5px !important',
          cursor: 'pointer  !important',
        },
      ],
      selectedTile: [
        classNames.selectedTile,
        {
          backgroundColor: theme.palette.neutralLight,
        },
      ],
      tileChildrenContainer: [
        classNames.tileChildrenContainer,
        {
          width: tileSizePX,
          height: tileSizePX,
        },
      ],
      card: [
        classNames.card,
        {
          width: '100%',
          height: '100%',
          fontSize: '5em',
        },
      ],
    })
  }, [theme])
}

export const QuadrantsThemesCollection = memo(
  ({ selectedKey = quadrantsColorsTemplatesKeys.classic, onSelect, onClick, className, ...rest }) => {
    const createOnSelect = useCallback(
      theme => () => {
        onSelect(theme)
      },
      [onSelect]
    )

    const { container, tile, selectedTile, tileChildrenContainer } = useClassNames()
    return (
      <div className={cn(container, className)} {...rest}>
        {_.map(quadrantsColorsTemplatesByKey, theme => {
          const colors = theme.quadrantColors.map(c => `rgba(${c.r}, ${c.g}, ${c.b}, ${c.a})`)
          const selected = theme.key === selectedKey
          return (
            <TileStructure
              key={theme.key}
              className={cn(tile, { [selectedTile]: selected })}
              childrenContainerClassName={tileChildrenContainer}
              onClick={createOnSelect(theme)}
            >
              <QuadrantSelector size={quadrantSelectorSize} quadrantsColors={colors} style={{ margin: 'auto' }} />
            </TileStructure>
          )
        })}
      </div>
    )
  }
)
