import React, { forwardRef, memo, Suspense, useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'
import { cn } from '@appfluence/classnames'
import { Set } from 'immutable'
import { getSearchItem } from '../../common/src/actions/searchAPI'
import { useParamsForItemsFilter } from '../../common/src/hooks/filtersHooks'
import { FILTER_REDUCER_KEYS } from '../../common/src/reducers/filtersKeys'
import { Loading } from '../basic/Loading'
import { getSearchedItems } from '../../common/src/selectors/searchSelectors'
import { SearchBox } from '@fluentui/react/lib/SearchBox'
import { FilterButton } from '../buttons/FilterButton'
import { getItemsTextFilter, isFilteringItems } from '../../common/src/selectors/filtersSelectors'
import { setItemsTextFilter } from '../../common/src/actions/filtersActions'
import { SearchFiltersPanel } from '../../views/filters/SearchFiltersPanel'
import { ItemVirtualizedList } from './ItemVirtualizedList'
import { itemHelper } from '../../common/src/helpers'
import { useMergeState } from '../../common/src/hooks/enhancedHooks'
import { extenders, teamsClassName } from '../../style/teamsExtenders'
import { SEARCH_REDUCER_KEYS } from '../../common/src/reducers/searchKeys'
import { useTranslation } from 'react-i18next'
import { LazyIViewPlaceholder } from '../placeholder/LazyIViewPlaceholder'
import { FlexColumn } from '../layout/FlexContainer'

const SEARCH_SECTION = SEARCH_REDUCER_KEYS.SECTION_PANEL

const defaultLimit = 50

const Container = styled(FlexColumn)`
  height: 100%;

  margin-left: 16px;
  margin-right: 20px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 12px;
`

const SSearchBox = extenders.searchBox(styled(SearchBox)`
  flex: 1;
`)

const SFilterButton = styled(FilterButton).attrs({
  size: 16,
})`
  margin-left: 12px;
`

export const SearchItems = memo(
  forwardRef(({ hideItemId, getInitialItems, getInitialItemsFromState, onSelectItem, onAddItem, ...rest }, ref) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [state, setState] = useMergeState({
      fetchingInitialItems: false,
      searching: false,
      addedItemsSet: Set(), // item ids
      localText: '',
      isOpenFP: false,
    })
    const { fetchingInitialItems, searching, addedItemsSet } = state

    const offsetRef = useRef(0)
    const isFiltering = useSelector(state => isFilteringItems(state, FILTER_REDUCER_KEYS.RESOURCES_PANEL))
    const textFilter = useSelector(state => getItemsTextFilter(state, FILTER_REDUCER_KEYS.RESOURCES_PANEL))
    const setTextFilter = useCallback(
      text => {
        return dispatch(setItemsTextFilter(text, FILTER_REDUCER_KEYS.RESOURCES_PANEL))
      },
      [dispatch]
    )
    const debouncedSetTextFilterRef = useRef(_.debounce(setTextFilter, 250, { trailing: true }))

    const showFP = useCallback(() => {
      setState({ isOpenFP: true })
    }, [setState])

    const initialItems_ = useSelector(getInitialItemsFromState)
    const initialItems = useMemo(() => initialItems_.toArray(), [initialItems_])
    const showInitialItems = !isFiltering && (fetchingInitialItems || !_.isEmpty(initialItems))
    const fetchInitialItems = useCallback(async () => {
      setState({ fetchingInitialItems: true })
      await dispatch(getInitialItems())
      setState({ fetchingInitialItems: false })
    }, [dispatch, getInitialItems, setState])

    useEffect(() => {
      fetchInitialItems()
    }, [fetchInitialItems])

    const updateOffsetRef = useCallback(
      hasNext => {
        if (hasNext) {
          offsetRef.current = offsetRef.current + defaultLimit
        } else {
          offsetRef.current = -1
        }
      },
      [offsetRef]
    )

    const search = useCallback(
      async params => {
        const finalParams = {
          ...params,
          offset: offsetRef.current < 0 ? 0 : offsetRef.current,
          limit: defaultLimit,
        }
        setState({ searching: true })
        try {
          const result = await dispatch(getSearchItem(finalParams, SEARCH_SECTION))
          const hasNext = !!_.get(result, 'payload.meta.next')
          updateOffsetRef(hasNext)
        } finally {
          setState({ searching: false })
        }
      },
      [dispatch, setState, offsetRef, updateOffsetRef]
    )
    const debouncedSearch = useMemo(() => _.debounce(search, 250, { trailing: true }), [search])

    const searchedItems = useSelector(state => getSearchedItems(state, SEARCH_SECTION))
    const items = useMemo(() => {
      return searchedItems ? searchedItems.filter(item => itemHelper.getId(item) !== hideItemId).toArray() : []
    }, [hideItemId, searchedItems])
    const params = useParamsForItemsFilter(FILTER_REDUCER_KEYS.RESOURCES_PANEL)

    useEffect(() => {
      offsetRef.current = 0
      debouncedSearch(params)
    }, [offsetRef, params, debouncedSearch])

    const endReached = useCallback(() => {
      if (searching || offsetRef.current < 0) {
        return
      }
      search(params)
    }, [searching, offsetRef, search, params])

    const noRowsRenderer = useCallback(() => {
      const placeholder = {
        title: t('search.placeholder_0.title'),
        message: t('search.placeholder_0.message'),
        type: 1,
      }
      return (
        <Suspense fallback={<Loading />}>
          <LazyIViewPlaceholder {...placeholder} />
        </Suspense>
      )
    }, [t])

    const onChangeText = useCallback(
      (evt, text) => {
        setState({
          localText: text,
        })
        debouncedSetTextFilterRef.current(text)
      },
      [setState]
    )

    const onSearch = useCallback(
      text => {
        setTextFilter(text)
      },
      [setTextFilter]
    )

    const onDismissFiltersPanel = useCallback(() => {
      setState({
        isOpenFP: false,
        localText: textFilter,
      })
    }, [setState, textFilter])

    const didAddItem = useCallback(
      item => {
        const serverID = itemHelper.getId(item)
        setState(prevState => ({ addedItemsSet: prevState.addedItemsSet.add(serverID) }))
        onAddItem(item)
      },
      [onAddItem, setState]
    )

    const searchCN = cn(teamsClassName)
    const searchTextValue = state.localText || textFilter

    const filterButtonTooltip = t('search_panel.filter_button_tooltip')

    return (
      <Container ref={ref} {...rest}>
        <Header>
          <SSearchBox
            defaultValue={searchTextValue}
            placeholder={t('search_panel.search_bar_placeholder')}
            onChange={onChangeText}
            onSearch={onSearch}
            className={searchCN}
          />
          <SFilterButton tooltip={filterButtonTooltip} isFiltering={isFiltering} onClick={showFP} />
          <SearchFiltersPanel
            filtersType={FILTER_REDUCER_KEYS.RESOURCES_PANEL}
            isOpen={state.isOpenFP}
            onDismiss={onDismissFiltersPanel}
          />
        </Header>
        {showInitialItems ? (
          <ItemVirtualizedList
            items={initialItems}
            addedItems={addedItemsSet}
            onSelectItem={onSelectItem}
            onAddItem={didAddItem}
            noRowsRenderer={noRowsRenderer}
            loading={fetchingInitialItems}
          />
        ) : (
          <ItemVirtualizedList
            items={items}
            addedItems={addedItemsSet}
            onSelectItem={onSelectItem}
            onAddItem={didAddItem}
            noRowsRenderer={noRowsRenderer}
            loading={searching}
            endReached={endReached}
            increaseViewportBy={200}
          />
        )}
      </Container>
    )
  })
)
