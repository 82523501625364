import React from 'react'
import { MainLayout } from '../../components/layout/MainLayout'
import { FeedView } from './FeedView'
import { RightDetailView } from '../rightDetailView/RightDetailView'
import { TeamsHeader } from '../../components/layout/TeamsHeader'
import { useTranslation } from 'react-i18next'

export const FeedTeamsWrapper = () => {
  const { t } = useTranslation()
  const content = {
    header: <TeamsHeader title={t('common_localized_strings.global_feed')} />,
    main: <FeedView projectID={0} />,
    rightSide: <RightDetailView />,
  }
  return <MainLayout {...content} />
}
