import _ from 'lodash'
import { createDelayedPromise } from './promiseHelpers'

/**
 * Method to create function managing
 * single click and double click using onClick
 *
 * @param queue The queue needs to be "CancellablePromiseQueue"
 * @param singleClick
 * @param doubleClick
 * @param delay
 * @param prevent Specifies if singleClick action should be postponed while waiting to the delay
 * @returns {Function}
 */
export const handleSingleAndDoubleClick =
  (queue, singleClick, doubleClick, { delay = 300, prevent = true } = {}) =>
  evt => {
    if (doubleClick && queue.count() > 0) {
      // Double click
      // Clear pending single clicks
      queue.clear()
      doubleClick(evt)
      return
    }

    // Single click
    if (!doubleClick) {
      singleClick(evt)
      return
    }

    if (!prevent) {
      singleClick(evt)
    }

    // Create the cancelable promise
    const waitForClick = queue.new(createDelayedPromise(delay))
    waitForClick.promise.then(() => {
      // Remove it from the queue
      queue.remove(waitForClick)

      // If the promise wasn't cancelled, executing code
      if (!waitForClick.isCanceled && singleClick && prevent) {
        singleClick(evt)
      }
    })
  }

const getLowercaseTagName = _.flow([_.partial(_.get, _, 'target.tagName'), _.toLower])

const targetIsContentEditable = evt => evt.target.contentEditable === 'true'

export const targetIsKindOfTextInput = evt => {
  const blacklist = ['input', 'textarea', 'select']
  const targetTag = getLowercaseTagName(evt)
  return _.indexOf(blacklist, targetTag) >= 0 || targetIsContentEditable(evt)
}

export const checkEventPath = (evt, iteratee) => {
  const p = evt.path || (evt.composedPath && evt.composedPath()) || []
  return _.some(p, iteratee)
}

export const eventPathContainsClassName = (evt, className) =>
  checkEventPath(evt, el => _.some(el.classList, cl => cl === className))
