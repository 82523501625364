import { useCallback, useEffect, useState } from 'react'
import { MainLayout } from '../../components/layout/MainLayout'
import { ListMode } from './ListMode'
import { ProjectDetailView } from '../project/ProjectDetail'
import { projectHelper } from '../../common/src/helpers'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { linkSubject } from '../../reactions/linkSubject'
import { uriDataCreator } from '../../common/src/helpers/URLHelper'
import { useDispatch, useSelector } from 'react-redux'
import { uiSelectors } from '@/selectors'
import { ProjectsListTopBar } from './ProjectsListTopBar'
import { GridMode } from './GridMode'
import { useFilteredAndSortedProjectsArray } from '@/common/src/hooks/projectHooks'
import { useAllProjectCounters } from '@/queries/projectCounters'
import { projectsView as init } from '@/actions/viewInitializationActions'

export const ProjectsListView = ({ history }) => {
  const { data: counts } = useAllProjectCounters()
  const mode = useSelector(uiSelectors.getProjectsDisplayMode)
  const projects = useFilteredAndSortedProjectsArray()
  const onSelectProject = useCallback(
    project => {
      const id = projectHelper.getIdd(project)
      const path = getRelativeURLKeepingQuerySearch.projects(id)
      history.push(path)
    },
    [history]
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(init())
  }, [dispatch])

  const [fromProjectId] = useState(() => +new URLSearchParams(history.location.search).get('fromProject'))

  useEffect(() => {
    if (fromProjectId) {
      const currentSearch = new URLSearchParams(history.location.search)
      currentSearch.delete('fromProject')
      history.replace({ search: currentSearch.toString() })
    }
  }, [fromProjectId, history])

  const navigateToProject = useCallback(project => {
    const id = projectHelper.getIdd(project)
    const urlData = uriDataCreator(1, 'project', id)
    linkSubject.next({ urlData })
  }, [])

  const main =
    mode === 'Grid' ? (
      <GridMode projects={projects} onSelectProject={onSelectProject} counts={counts} fromProjectId={fromProjectId} />
    ) : (
      <ListMode
        projects={projects}
        onSelectProject={onSelectProject}
        navigateToProject={navigateToProject}
        counts={counts}
        fromProjectId={fromProjectId}
      />
    )
  const detail = <ProjectDetailView />
  const topBar = <ProjectsListTopBar mode={mode} />
  return <MainLayout main={main} rightSide={detail} topBar={topBar} />
}
