export const ACTIONS = {
  SET_PROJECT_CENTER: 'PROJECT/SET_PROJECT_CENTER',
}

export const setProjectCenter = (serverID, center) => {
  return {
    type: ACTIONS.SET_PROJECT_CENTER,
    payload: { serverID, center },
  }
}
