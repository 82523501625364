import _ from 'lodash'
import { List } from 'immutable'
import { pickPredicate, safeMapFnCreator } from './immutableHelpers'
import { toDate } from './dateHelper'

let _export = {}

const KEYS = {
  ID: 'id',
  EXTERNAL_ID: 'external_id',

  TITLE: 'title',
  NOTES: 'notes',
  PRIVATE_NOTES: 'private_notes',

  START_DATE: 'start_date',
  END_DATE: 'end_date',
  IS_ALL_DAY: 'is_all_day',
  REMINDER_DATE: 'reminder_date',

  PM_PARTICIPANTS: 'pm_participants',
  OTHER_PARTICIPANTS: 'other_participants',

  IS_CANCELLED: 'is_cancelled',
  RESOURCE_URI: 'resource_uri',
  SOURCE: 'source',

  NEXT_MEETING_IN_SERIE_ID: 'next_meeting_in_serie_id',
  PREVIOUS_MEETING_IN_SERIE_ID: 'previous_meeting_in_serie_id',

  MEETING_URL: 'meeting_url',
  LINK: 'link',
}
_export.KEYS = KEYS

_export.getID = r => r.get(KEYS.ID)
_export.getExternalID = r => r.get(KEYS.EXTERNAL_ID)
_export.getTitle = r => r.get(KEYS.TITLE)
_export.getNotes = r => r.get(KEYS.NOTES).toJS()
_export.getPrivateNotes = r => r.get(KEYS.PRIVATE_NOTES).toJS()

_export.getStartDate = m => toDate(m.get(KEYS.START_DATE))
_export.getEndDate = m => toDate(m.get(KEYS.END_DATE))
_export.getIsAllDay = m => m.get(KEYS.IS_ALL_DAY)
_export.isAllDay = _export.getIsAllDay
_export.getReminderDate = m => toDate(m.get(KEYS.REMINDER_DATE))

_export.getPMParticipants = m => m.get(KEYS.PM_PARTICIPANTS)
_export.getOtherParticipants = m => m.get(KEYS.OTHER_PARTICIPANTS)
_export.getParticipants = m => {
  if (!m) {
    return List()
  }
  const pm = _export.getPMParticipants(m) || List()
  const others = _export.getOtherParticipants(m) || List()
  return pm.concat(others)
}

_export.getIsCancelled = m => m.get(KEYS.IS_CANCELLED)
_export.isCancelled = _export.getIsCancelled
_export.getResourceURI = m => m.get(KEYS.RESOURCE_URI)
_export.getSource = m => m.get(KEYS.SOURCE)
_export.getNextMeetingId = m => m.get(KEYS.NEXT_MEETING_IN_SERIE_ID)
_export.getPrevMeetingId = m => m.get(KEYS.PREVIOUS_MEETING_IN_SERIE_ID)
_export.getMeetingUrl = m => m.get(KEYS.MEETING_URL)
_export.getLink = m => m.get(KEYS.LINK)

_export.sortDescriptorByName = m => _.toLower(_export.getTitle(m))

_export.getPMParticipantIndexById = (m, participantId) => {
  const list = _export.getPMParticipants(m).sortBy(p => p.get('id'))
  return list.findIndex(p => p.get('id') === participantId)
}

const SEND_WHITELIST = [
  KEYS.ID,
  KEYS.EXTERNAL_ID,

  KEYS.TITLE,
  KEYS.NOTES,
  KEYS.PRIVATE_NOTES,

  KEYS.START_DATE,
  KEYS.END_DATE,
  KEYS.ALL_DAY,
  KEYS.REMINDER_DATE,

  KEYS.PM_PARTICIPANTS,
  KEYS.OTHER_PARTICIPANTS,

  KEYS.IS_CANCELLED,
  KEYS.RESOURCE_URI,
  KEYS.SOURCE,
]

_export.prepareForSend = r => {
  const obj = r.filter(pickPredicate(...SEND_WHITELIST)).toJS()
  return JSON.stringify(obj)
}
_export = _.mapValues(_export, f => (_.isFunction(f) ? safeMapFnCreator(f) : f))

export default _export
