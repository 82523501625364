import _ from 'lodash'
import { PM_API_RESOURCES, PM_API_URLS } from '../constants'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'
import { createAction } from 'redux-api-middleware'
import { appendQueryParamsToURL, extendPMHeaders, getCredentialsConfig } from '../helpers/requestHelper'

export const getItemConflicts = (itemID, params = {}) => {
  const _url = _.template(PM_API_URLS.ITEM_CONFLICTS)({ id: itemID })
  const types = generateActionTypesForPMAPI(PM_API_RESOURCES.ITEM_CONFLICTS, { itemID })
  return createAction({
    endpoint: appendQueryParamsToURL(_url, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: types,
    headers: extendPMHeaders(),
  })
}
