import { createAction } from 'redux-api-middleware'
import _ from 'lodash'
import { appendQueryParamsToURL, extendPMHeaders, getCredentialsConfig } from '../helpers/requestHelper'
import { PM_API_RESOURCES, PM_API_URLS } from '../constants'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'

export const getLinks = (itemID, params) => {
  const url = _.template(PM_API_URLS.ASSOCIATED_LINKS)({ id: itemID })
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.ASSOCIATED_LINKS, { itemID }),
    headers: extendPMHeaders(),
  })
}

export const updateLinks = (itemID, links) => {
  const url = _.template(PM_API_URLS.ASSOCIATED_LINKS)({ id: itemID })
  const body = JSON.stringify({ objects: links })
  return createAction({
    endpoint: appendQueryParamsToURL(url),
    credentials: getCredentialsConfig(),
    method: 'PATCH',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.ASSOCIATED_LINKS, { itemID }),
    headers: extendPMHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
    }),
    body,
  })
}

export const deleteLink = (itemID, linkID, params = {}) => {
  const url = _.template(PM_API_URLS.DELETE_ASSOCIATED_LINK)({ id: itemID, linkID: linkID })
  return createAction({
    endpoint: appendQueryParamsToURL(url, params),
    credentials: getCredentialsConfig(),
    method: 'DELETE',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.DELETE_ASSOCIATED_LINKS, { itemID, linkID }),
    headers: extendPMHeaders(),
  })
}
