import { useState } from 'react'
import { UserGroupCalloutMessage } from '../users/UserGroupCalloutMessage'
import { InfoIconCallout } from './InfoIconCallout'
import { IconButton } from '@fluentui/react/lib/Button'
import { Spinner } from '@fluentui/react/lib/Spinner'
import { useUserGroups } from '../../queries/userGroups'

const reloadButtonIconProps = {
  iconName: 'Refresh',
}

export const UserGroupInfoIconCallout = ({ className, showReloadWhenClickLink = false }) => {
  const [showReloadButton, setShowReloadButton] = useState(false)
  const { refetch, isRefetching } = useUserGroups()
  const onClickLink = showReloadWhenClickLink ? () => setShowReloadButton(true) : null
  const onClickReload = async () => {
    await refetch()
  }

  return (
    <>
      <InfoIconCallout className={className} message={<UserGroupCalloutMessage onClickLink={onClickLink} />} />
      {showReloadButton &&
        (isRefetching ? <Spinner /> : <IconButton iconProps={reloadButtonIconProps} onClick={onClickReload} />)}
    </>
  )
}
