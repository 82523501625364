import _ from 'lodash'
import { ACTION_TYPES } from '../actions/viewInitializationActions'
import {
  getCollaborators,
  getCollaboratorsDebounced,
  getLicenseStatus,
  getMsCollaborators,
  getAllMsCollaborators,
} from '../common/src/actions/usersAPI'
import { syncTZOffset } from '../common/src/actions/syncAPI'
import { getProject } from '../common/src/actions/projectsAPI'
import {
  getAllProjectsIfNeeded,
  getAllProjectSummariesIfNecessary,
  getFollowedItemsInProjectIfNecessary,
  getItemSummariesInProjectIfNecessary,
  getProjectIfNotPending,
} from '../common/src/actions/combinedAPI'
import { getAllPlannerTasks, getResourcesByText } from '../common/src/actions/graphAPI'
import { API_EVENT, generateActionTypeString, parseApiActionType } from '../common/src/helpers/reducerHelper'
import { APIS, PM_API_RESOURCES } from '../common/src/constants'
import { stateHelper } from '../common/src/helpers'
import { clearAll, GENERIC_ACTION_TYPE } from '../common/src/actions/genericActions'
import { getLastSeenCommentsForCurrentUser } from '../common/src/actions/commentsAPI'
import { isInitializedRequestForPMResource } from '../common/src/selectors/requestStateSelectors'
import { RECENTLY_VISITED_LIST_SIZE } from '../environment'
import { getFetchFollowedItemsCondition } from '../common/src/selectors/filtersSelectors'

//Effects should return a promise
const effects = {
  [ACTION_TYPES.SEARCH]: (action, store) => {
    const { dispatch } = store
    return Promise.all([dispatch(getResourcesByText('')), dispatch(getAllPlannerTasks())])
  },
  [ACTION_TYPES.PROJECTS]: (action, store) => {
    const { dispatch } = store
    dispatch(getAllProjectsIfNeeded({ limit: 500, skip_tag: null }))
    return dispatch(getAllProjectSummariesIfNecessary({ skip_tag: null }))
  },
  [ACTION_TYPES.ONE_ON_ONE]: (action, store) => {
    const { dispatch } = store
    return Promise.all([
      dispatch(getCollaboratorsDebounced()),
      dispatch(getAllProjectsIfNeeded({ limit: 500 })),
      dispatch(getAllPlannerTasks()),
    ])
  },
  [ACTION_TYPES.PROJECT_HANDLER_INAPP]: (action, store) => {
    const { dispatch } = store
    const projectID = action.payload
    if (!projectID) {
      return Promise.resolve()
    }
    return Promise.all([
      dispatch(getProjectIfNotPending({ projectID, memberships: true })),
      dispatch(getItemSummariesInProjectIfNecessary({}, projectID)).then(() => {
        const state = store.getState()
        const followedFilterEnabled = getFetchFollowedItemsCondition(state)
        if (followedFilterEnabled) {
          return dispatch(getFollowedItemsInProjectIfNecessary(projectID))
        }
        return Promise.resolve()
      }),
    ])
  },
  [ACTION_TYPES.ONE_PROJECT_HANDLER]: (action, store) => {
    const { dispatch } = store
    const { projectID, fastToken } = action.payload
    if (!projectID) {
      return Promise.reject()
    }
    const promises = [dispatch(getProject(projectID, true, fastToken))]
    const params = fastToken ? { fastproject: fastToken } : {}
    promises.push(dispatch(getItemSummariesInProjectIfNecessary(params, projectID)))
    return Promise.all(promises).then(results => {
      const state = store.getState()
      const followedFilterEnabled = getFetchFollowedItemsCondition(state)
      if (followedFilterEnabled) {
        return dispatch(getFollowedItemsInProjectIfNecessary(projectID))
      }
      return Promise.resolve(results[0])
    })
  },
  [ACTION_TYPES.PROJECT_DETAIL]: (action, store) => {
    const { dispatch } = store
    return Promise.all([dispatch(getCollaboratorsDebounced())])
  },
  [ACTION_TYPES.ITEM_DETAIL]: (action, store) => {
    const { dispatch } = store
    return Promise.all([dispatch(getCollaboratorsDebounced())])
  },
  [ACTION_TYPES.MS_COLLABORATORS]: (action, { dispatch }) => {
    if (!didFetchAllMsCollaborators) {
      didFetchAllMsCollaborators = true
      return dispatch(getAllMsCollaborators())
    }
    return Promise.resolve()
  },
  [ACTION_TYPES.RECENTLY_VISITED]: (action, { dispatch, getState }) => {
    const state = getState()
    const initialized = isInitializedRequestForPMResource(state, PM_API_RESOURCES.RECENTLY_VISITED_COMMENTS)
    return initialized ? Promise.resolve() : dispatch(getLastSeenCommentsForCurrentUser(RECENTLY_VISITED_LIST_SIZE))
  },
}

let didFetchMe = false
let didFetchAllMsCollaborators = false
let pending = []

const ME_SUCCESS = generateActionTypeString(APIS.PM, PM_API_RESOURCES.ME, API_EVENT.SUCCESS)
const ME_ERROR = generateActionTypeString(APIS.PM, PM_API_RESOURCES.ME, API_EVENT.ERROR)

const resetAppIfNeeded = (action, { dispatch, getState }) => {
  const state = getState()
  const { payload } = action
  const oldEmail = stateHelper.getMeEmail(state)
  const newEmail = payload.email
  const reset = oldEmail && oldEmail !== newEmail
  if (reset) {
    dispatch(clearAll())
  }
  return reset
}

const triggerInitialRequests = store => {
  _.each(
    [getLicenseStatus()],
    reqData =>
      setTimeout(
        () => {
          store.dispatch(reqData)
        },
        _.random(30, 100)
      ) //fast
  )
  _.each([getCollaborators(), getMsCollaborators({ limit: 1 }), syncTZOffset(new Date())], reqData => {
    setTimeout(
      () => {
        store.dispatch(reqData)
      },
      _.random(2000, 5000)
    ) //slow
  })
}

export const viewInitializationMiddleware = store => next => action => {
  const { type } = action
  const effect = effects[type]
  if (_.isFunction(effect)) {
    // So, actions caught here don't reach our reducers
    return effect(action, store)
  }

  if (type === GENERIC_ACTION_TYPE.INIT_ALL) {
    triggerInitialRequests(store)
  } else if (type === ME_SUCCESS || type === ME_ERROR) {
    const reset = resetAppIfNeeded(action, store)
    // Launch pending actions
    next(action)
    setTimeout(
      () => {
        didFetchMe = true
        _.each(pending, p => {
          const res = next(p.action)
          p.resolve(res)
        })
        pending = []
      },
      reset ? 10 : 0
    )
    return action
  }

  // mark as pending in case we didn't fetch /me yet
  const actionType = parseApiActionType(type)
  if (actionType && actionType.event === API_EVENT.SUCCESS && !didFetchMe) {
    return new Promise(resolve => {
      pending.push({ action, resolve })
    })
  }
  return next(action)
}
