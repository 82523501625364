import userHelper from './userHelper'
import _ from 'lodash'

export const usersMapExcludingUsers = (usersMap, ...args) => {
  const users = _.compact(_.flatten(args))
  if (usersMap && users && users.length > 0) {
    return usersMap.withMutations(st =>
      users.forEach(user => {
        const email = userHelper.getEmail(user)
        st.delete(email)
      })
    )
  }
  return usersMap
}

export const usersListExcludingUser = (usersList, ...args) => {
  const users = _.compact(_.flatten(args))

  if (usersList && users && users.length > 0) {
    return usersList.withMutations(st => {
      users.forEach(user => {
        const email = userHelper.getEmail(user)
        const index = usersList.findIndex(user => {
          return email === userHelper.getEmail(user)
        })
        if (index !== -1) {
          st.delete(index)
        }
      })
    })
  }
  return usersList
}

export const getEmailsJoinByComma = _.flow([
  _.partialRight(_.map, u => userHelper.getEmail(u)),
  _.partialRight(_.join, ','),
])
