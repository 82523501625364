import styled from 'styled-components'
import _ from 'lodash'
import { memo, Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMeetingsInInterval } from '../../common/src/actions/meetingsAPI'
import { useExtendedHistory } from '../../hooks/useExtendedHistory'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { MeetingVirtualizedList } from './MeetingVirtualizedList'
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button'
import { Label, Text } from '../../components/text/Text'
import { FlexContainer } from '../../components/layout/FlexContainer'
import { Loading } from '../../components/basic/Loading'
import { useBoolean } from '../../common/src/hooks/useBoolean'
import { Icon } from '@fluentui/react/lib/Icon'
import { MeetingLayout } from '../../components/layout/MeetingLayout'
import { useFetch } from '../../common/src/hooks/networkingHooks'
import meetingHelper from '../../common/src/helpers/meetingHelper'
import { datePredicateGenerator } from '../../common/src/helpers/filtersHelper'
import { UsersCollection } from '../../components/users/usersCollection/UsersCollection'
import { meetingsSelectors, teamsSelectors } from '../../common/src/selectors'
import { useDebugHandler } from '../../hooks/useDebugToast'
import { queryParamsHelper } from '../../helpers'
import { useTabInfo } from '../../hooks/msTeamsHooks'
import { IVIEW_PLACEHOLDER_TYPES } from '../../components/placeholder/IViewPlaceholderTypes'
import { resourceURIParser } from '../../common/src/helpers/URLHelper'
import { Redirect } from 'react-router'
import { BackButton, ForwardButton } from '../../components/buttons/NavButtons'
import { useTranslation } from 'react-i18next'
import { LazyIViewPlaceholder } from '../../components/placeholder/LazyIViewPlaceholder'
import { datefns, formatDate } from '../../utils/datefns'

const getPeriod = () => {
  const now = new Date()
  const start = datefns.startOfISOWeek(now)
  const end = datefns.add({ days: 15 }, start)
  return [start, end]
}
const usePeriod = () => useMemo(() => getPeriod(), [])

const getNextMeeting = (meetings, nowDate) => {
  const nowUnix = nowDate.getTime()
  return _.minBy(meetings, m => {
    const start = meetingHelper.getStartDate(m).getTime()
    return Math.abs(nowUnix - start)
  })
}

const useMeetings = period => {
  const meetingMap = useSelector(meetingsSelectors.getMeetingsMap)
  return useMemo(() => {
    const [start, end] = period
    const datePredicate = datePredicateGenerator(start, end)
    return meetingMap
      .filter(m => {
        const startDate = meetingHelper.getStartDate(m)
        return datePredicate(startDate)
      })
      .sortBy(meetingHelper.getStartDate)
      .toArray()
  }, [meetingMap, period])
}

const useNow = () => useMemo(() => new Date(), [])

const useSelectedIndex = meetings => {
  const [selectedIndex, setSelectedIndex] = useState(null)
  const fns = useMemo(
    () => ({
      onBack: () => setSelectedIndex(p => p - 1),

      onNext: () => setSelectedIndex(p => p + 1),

      onSelect: meeting => {
        const index = _.indexOf(meetings, meeting)
        if (index !== -1) {
          setSelectedIndex(index)
        }
      },
    }),
    [meetings]
  )
  return {
    selectedIndex,
    ...fns,
  }
}

export const TeamsMeetingRedirectView = memo(() => {
  const context = useSelector(teamsSelectors.getRawContext)
  //todo use different URLs instead of if-else
  if (queryParamsHelper.isEmbeddedOnTeams()) {
    return <TabBasedRedirection context={context} />
  }
  return <MeetingSelectionView context={context} />
})

const TabBasedRedirection = memo(({ context }) => {
  const { loading, tab } = useTabInfo(context)
  if (loading) {
    const placeholder = {
      title: 'Getting meeting info...',
      type: IVIEW_PLACEHOLDER_TYPES.REDIRECT,
    }
    return (
      <Suspense fallback={<Loading />}>
        <LazyIViewPlaceholder {...placeholder} />
      </Suspense>
    )
  }

  //todo if there's an associated meeting, fetch and navigate

  const meetingResourceUri = tab?.get?.('pm_meeting')
  if (meetingResourceUri) {
    const { id } = resourceURIParser(meetingResourceUri)
    const url = getRelativeURLKeepingQuerySearch.meeting(id)
    return <Redirect to={url} />
  }

  //else
  return <MeetingSelectionView context={context} />
})

const MeetingSelectionView = memo(({ context }) => {
  const [isShowingSuggestions, { setTrue: showSuggestions, setFalse: hideSuggestions }] = useBoolean(true)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { history } = useExtendedHistory()
  const period = usePeriod()
  const nowDate = useNow()
  const fetchMeetings = useCallback(() => {
    const [start, end] = period
    const params = { limit: 100 }
    return dispatch(getMeetingsInInterval(params, start, end))
  }, [dispatch, period])
  const { loading, error: errorFetching } = useFetch(fetchMeetings)
  const meetings = useMeetings(period)
  const { selectedIndex, onBack, onNext, onSelect } = useSelectedIndex(meetings)
  const isEmpty = _.isEmpty(meetings)
  const hasPrevious = !isEmpty && selectedIndex > 0
  const hasNext = !isEmpty && selectedIndex < _.size(meetings) - 1
  const selectedMeeting = meetings[selectedIndex]

  useDebugHandler({ context, selectedMeeting })

  useEffect(() => {
    if (loading || selectedIndex) {
      return
    }
    const nextMeeting = getNextMeeting(meetings, nowDate)
    if (nextMeeting) {
      onSelect(nextMeeting)
    }
  }, [loading, meetings, nowDate, onSelect, selectedIndex])

  const onOpen = useCallback(
    async meeting => {
      const id = meetingHelper.getID(meeting)
      if (id) {
        const url = getRelativeURLKeepingQuerySearch.meeting(id)
        history.push(url)
      }
    },
    [history]
  )

  if (loading) {
    return <Loading />
  }

  if (!selectedMeeting || errorFetching) {
    const placeholder = {
      title: t('meeting.not_found_next_meeting_placeholder.title'),
      message: t('meeting.not_found_next_meeting_placeholder.message'),
      type: IVIEW_PLACEHOLDER_TYPES.NOT_FOUND,
    }
    return (
      <Suspense fallback={<Loading />}>
        <LazyIViewPlaceholder {...placeholder} />
      </Suspense>
    )
  }

  const backProps = {
    title: t('meeting.prev_meeting_tooltip'),
    disabled: !hasPrevious,
    onClick: onBack,
    style: { marginRight: 8 },
  }
  const nextProps = {
    title: t('meeting.next_meeting_tooltip'),
    disabled: !hasNext,
    onClick: onNext,
  }

  const main = isShowingSuggestions ? (
    <SuggestionView
      meeting={selectedMeeting}
      backButtonProps={backProps}
      forwardButtonProps={nextProps}
      onChoose={hideSuggestions}
      onOpen={onOpen}
    />
  ) : (
    <SelectionView
      onBack={showSuggestions}
      meetings={meetings}
      selectedMeeting={selectedMeeting}
      onSelect={onSelect}
      onOpen={onOpen}
    />
  )

  return <MeetingLayout main={main} />
})

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  height: 100%;
`

const SubContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 0;
  box-sizing: border-box;
  justify-content: start;
  align-items: start;
  flex-direction: column;

  height: 100%;
  max-width: 480px;
`

const TopSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: initial;
  box-sizing: border-box;
  flex: 0;
`

const Title = styled(Text).attrs({
  variant: 'large',
  nowrap: true,
  block: true,
})`
  font-weight: 600;
  color: ${p => p.theme.palette.black};
  margin-top: 12px;
  margin-bottom: 12px;
`

const Span = styled.span`
  color: ${p => p.theme.palette.neutralTertiaryAlt};
`

const SuggestRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 8px;
  color: ${p => p.theme.palette.black};
`

const SuggestTitleRow = memo(({ iconName, iconColor, name }) => {
  return (
    <SuggestRowContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {iconName && <SIcon iconName={iconName} style={iconColor && { color: iconColor }} />}
        <Span>{name}</Span>
      </div>
    </SuggestRowContainer>
  )
})

const SuggestDetailsRow = memo(({ name, children }) => {
  return (
    <SuggestRowContainer>
      <Label>{name}</Label>
      {children}
    </SuggestRowContainer>
  )
})

const SuggestButtonContainer = styled(FlexContainer)`
  height: initial;
  flex-direction: row;
`

const suggestButtonStyles = () => `
  flex: 1;
  margin: 10px 0;
`

const SuggestButton = styled(PrimaryButton)`
  ${suggestButtonStyles()}
`
const SuggestButtonWhite = styled(DefaultButton)`
  ${suggestButtonStyles()}
`

const SIcon = styled(Icon).attrs({
  side: 24,
})`
  font-size: 24px;
  margin-right: 8px;
  user-select: none;
  color: ${p => p.theme.palette.themePrimary};
`

const SuggestionView = memo(
  ({
    meeting,
    showBackButton = true,
    backButtonProps,
    showForwardButton = true,
    forwardButtonProps,
    onOpen,
    onChoose,
  }) => {
    const { t } = useTranslation()
    const title = meetingHelper.getTitle(meeting)
    const startDate = meetingHelper.getStartDate(meeting)
    const endDate = meetingHelper.getEndDate(meeting)
    const [startText, endText] = useMemo(() => {
      const startText = startDate ? formatDate('ShortenedNormal')(startDate) : ''
      const endText = endDate ? formatDate('ShortenedNormal')(endDate) : ''
      return [startText, endText]
    }, [startDate, endDate])
    const participants = useMemo(() => (meeting ? meetingHelper.getParticipants(meeting).toArray() : []), [meeting])

    const onClickOpen = useCallback(() => {
      onOpen(meeting)
    }, [meeting, onOpen])

    return (
      <Container>
        <SubContainer>
          <TopSection>
            <Title>{title}</Title>
            {(showBackButton || showForwardButton) && (
              <div>
                {showBackButton && <BackButton {...backButtonProps} />}
                {showForwardButton && <ForwardButton {...forwardButtonProps} />}
              </div>
            )}
          </TopSection>
          <SuggestTitleRow header={t('meeting.next_meeting_title')} name={title} iconName={'EventDate'} />
          <SuggestDetailsRow name={t('meeting.start_date_title')}>
            <Span>{startText}</Span>
          </SuggestDetailsRow>
          <SuggestDetailsRow name={t('meeting.end_date_title')}>
            <Span>{endText}</Span>
          </SuggestDetailsRow>
          <SuggestDetailsRow name={t('meeting.participants')}>
            <UsersCollection max={10} users={participants} showAddCell={false} readOnly={true} />
          </SuggestDetailsRow>
          <SuggestButtonContainer>
            <SuggestButton onClick={onClickOpen}>{t('general.open')}</SuggestButton>
          </SuggestButtonContainer>
          <SuggestButtonContainer>
            <SuggestButtonWhite onClick={onChoose}>{t('meeting.choose_other_meeting_button')}</SuggestButtonWhite>
          </SuggestButtonContainer>
        </SubContainer>
      </Container>
    )
  }
)

const TableSection = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  position: relative;
`

const OpenButton = styled(PrimaryButton)`
  margin-left: 20px;
`

const SelectionView = memo(({ onBack, onOpen, meetings, selectedMeeting, onSelect, ...rest }) => {
  const { t } = useTranslation()
  const onClickOpen = useCallback(() => {
    onOpen(selectedMeeting)
  }, [onOpen, selectedMeeting])
  return (
    <Container {...rest}>
      <SubContainer>
        <TopSection>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BackButton onClick={onBack} />
            <Title>{t('meeting.select_meeting_title')}</Title>
          </div>
          <OpenButton onClick={onClickOpen}>{t('general.open')}</OpenButton>
        </TopSection>
        <TableSection>
          <MeetingVirtualizedList meetings={meetings} selectedMeeting={selectedMeeting} onSelect={onSelect} />
        </TableSection>
      </SubContainer>
    </Container>
  )
})
