export const icons = [
  'empty_orange128',
  'blue_thumbsup128',
  'blue_thumbsdown128',
  'blue_heart128',
  'blue_lightbulb128',
  'blue_world128',
  'blue_home128',
  'blue_clock128',
  'blue_telephone128',
  'blue_airplane128',
  'blue_bicyclist128',
  'blue_car128',
  'blue_coffee128',
  'blue_dining128',
  'blue_fruit128',
  'blue_cocktail128',
  'blue_santaclaus128',
  'blue_hospital128',
  'blue_scale128',
  'blue_euro128',
  'blue_pound128',
  'blue_creditcard128',
  'blue_cabinet128',
  'blue_printer128',
  'blue_envelope128',
  'blue_document128',
  'blue_businessdoc128',
  'blue_book128',
  'blue_businessbox128',
  'blue_gift128',
  'blue_key128',
  'blue_diamond128',
  'blue_ship128',
  'blue_stop128',
  'blue_shovel128',
  'blue_wrench128',
  'blue_paperclip128',
  'blue_pencil128',
  'green_pen128',
  'green_cellphone128',
  'green_laptop128',
  'green_envelope128',
  'green_filecabinet128',
  'green_house128',
  'green_smiley128',
  'green_world128',
  'green_text128',
  'green_circle128',
  'green_checkmark128',
  'green_exclamation128',
  'green_questionmark128',
  'orange_brain128',
  'orange_studying128',
  'orange_headphones128',
  'orange_briefcase128',
  'orange_fire128',
  'orange_star128',
  'orange_island128',
  'orange_tree128',
  'orange_bubble128',
  'orange_camera128',
  'orange_girl128',
  'orange_male128',
  'orange_family128',
  'orange_stroller128',
  'orange_diamond128',
  'orange_prescription128',
  'orange_pill128',
  'orange_information128',
  'orange_percentage128',
  'orange_00128',
  'orange_01128',
  'orange_02128',
  'orange_03128',
  'orange_04128',
  'orange_05128',
  'orange_06128',
  'orange_07128',
  'orange_08128',
  'orange_09128',
  'orange_10128',
  'red_0128',
  'red_1128',
  'red_2128',
  'red_3128',
  'red_4128',
  'red_5128',
  'red_6128',
  'red_7128',
  'red_8128',
  'red_9128',
  'red_10128',
  'red_idea128',
  'red_fire128',
  'red_dollar128',
  'red_shoppingcart128',
  'red_clock128',
  'red_graph128',
  'red_ipod128',
  'red_musicnote128',
  'red_piano128',
  'red_tools128',
  'red_asterisk128',
  'red_exclamation128',
  'red_greater128',
  'red_smaller128',
  'red_x128',
  'blackcircletransparent128',
  'plainredcircle128',
  'plainyellowcircle128',
  'plaingreencircle128',
  'plainwhitecircle128',
  'plaingraycircle128',
  'plainpurplecircle128',
  'plainmagentacircle128',
  'plainbluecircle128',
]

export default icons
