import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllNotificationsIfNeeded } from '../../common/src/actions/combinedAPI'
import { getNotificationsMap } from '../../common/src/selectors/notificationSelectors'
import { notificationHelper } from '../../common/src/helpers'
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar'
import { patchNotification } from '../../common/src/actions/notificationsAPI'
import { MessageBarButton } from '@fluentui/react/lib/Button'
import { fromJS } from 'immutable'
import { queryParamsHelper } from '../../helpers'
import { MESSAGE_TYPE } from '../../common/src/helpers/notificationHelper'
import { useTheme } from 'styled-components'
import { manageLink } from '../../utils/externalLinkHandler'
import { linkSubject, SOURCES } from '../../reactions/linkSubject'
import { useTranslation } from 'react-i18next'

const MESSAGE_TYPE_TO_FLUENT = {
  [MESSAGE_TYPE.INFO]: MessageBarType.info,
  [MESSAGE_TYPE.SUCCESS]: MessageBarType.success,
  [MESSAGE_TYPE.WARNING]: MessageBarType.warning,
  [MESSAGE_TYPE.DANGER]: MessageBarType.severeWarning,
  [MESSAGE_TYPE.CUSTOM]: MessageBarType.info,
}
const DEFAULT_MESSAGE_TYPE = MessageBarType.info

const useSelectNotification = () => {
  const allNotifications = useSelector(getNotificationsMap)
  const queryParamNotificationId = +queryParamsHelper.getCommercialNotificationId()
  return useMemo(() => {
    if (queryParamNotificationId) {
      const selected = allNotifications.get(queryParamNotificationId)
      if (selected && notificationHelper.shouldBeShown(selected)) {
        return selected
      }
    }
    return allNotifications.find(n => notificationHelper.shouldBeShown(n))
  }, [queryParamNotificationId, allNotifications])
}

export const NotificationMessageBar = () => {
  const dispatch = useDispatch()
  const selectedNotification = useSelectNotification()
  const theme = useTheme()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(getAllNotificationsIfNeeded({ state: notificationHelper.NOTIFICATION_STATE.UNREAD }))
  }, [dispatch])

  if (!selectedNotification) return false

  const handleDismiss = () => {
    if (notificationHelper.getState(selectedNotification) === notificationHelper.NOTIFICATION_STATE.ACCEPTED) {
      return
    }
    const newState = notificationHelper.canBeAccepted(selectedNotification)
      ? notificationHelper.NOTIFICATION_STATE.DISMISSED
      : notificationHelper.NOTIFICATION_STATE.READ
    dispatch(
      patchNotification(
        notificationHelper.getId(selectedNotification),
        fromJS({
          [notificationHelper.KEYS.STATE]: newState,
        })
      )
    )
  }

  const linkUrl = notificationHelper.getLinkUrl(selectedNotification)

  const handleClickLink = evt => {
    if (linkUrl) {
      const managed = manageLink(urlData => {
        linkSubject.next({ urlData, source: SOURCES.INAPP_NOTIFICATION })
      })(linkUrl)
      if (managed) {
        evt.preventDefault()
      }
    }
    const newState = notificationHelper.canBeAccepted(selectedNotification)
      ? notificationHelper.NOTIFICATION_STATE.ACCEPTED
      : notificationHelper.NOTIFICATION_STATE.READ
    dispatch(
      patchNotification(
        notificationHelper.getId(selectedNotification),
        fromJS({
          [notificationHelper.KEYS.STATE]: newState,
        })
      )
    )
  }

  const messageBarType =
    MESSAGE_TYPE_TO_FLUENT[notificationHelper.getMessageType(selectedNotification)] ?? DEFAULT_MESSAGE_TYPE

  const messageBarStyles = {
    innerText: { '& span': { color: `${theme.palette.always_black} !important` } },
  }
  const linkButtonProps = linkUrl ? { href: linkUrl, target: '_blank' } : {}

  return (
    <MessageBar
      messageBarType={messageBarType}
      onDismiss={handleDismiss}
      styles={messageBarStyles}
      actions={
        <div>
          <MessageBarButton onClick={handleClickLink} {...linkButtonProps}>
            {linkUrl ? notificationHelper.getLinkText(selectedNotification) : t('general.ok')}
          </MessageBarButton>
        </div>
      }
    >
      <strong>{notificationHelper.getTitle(selectedNotification)}</strong>
      <br />
      {notificationHelper.getBody(selectedNotification)}
    </MessageBar>
  )
}
