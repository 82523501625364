import React, { useCallback, useState } from 'react'
import { ActionButton } from '@fluentui/react/lib/Button'
import { TextField } from '@fluentui/react/lib/TextField'
import { graphMessageHelper } from '../../../common/src/helpers'
import { ICON_NAMES } from '../../../common/src/msIcons'
import { GraphEmailModal } from './graphEmailModal'

const readButtonProps = {
  iconName: ICON_NAMES.Read,
}

export const MessageContent = ({ resource }) => {
  const [isReaderOpen, setIsReaderOpen] = useState(false)
  const onDismiss = useCallback(() => setIsReaderOpen(false), [setIsReaderOpen])
  const openReader = () => setIsReaderOpen(true)
  const preview = graphMessageHelper.getBodyPreview(resource)
  return (
    <>
      <TextField value={preview} label="Preview" borderless multiline readOnly autoAdjustHeight />
      <ActionButton iconProps={readButtonProps} onClick={openReader}>
        View Full Message
      </ActionButton>
      <GraphEmailModal resource={resource} isOpen={isReaderOpen} onDismiss={onDismiss} />
    </>
  )
}
