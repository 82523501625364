import { css, keyframes } from 'styled-components'

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const fadeInDelayed = keyframes`
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0
  }
  100% {
    opacity: 1;
  }
`

export const fadeInStyle = css`
  animation: ${fadeIn} 0.5s ease-out;
`
