import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { CopyToClipboardModal } from '../modal/CopyToClipboardModal'
import { getReadOnlyToken } from '../../common/src/actions/projectsAPI'
import { useDispatch } from 'react-redux'
import { useMergeState } from '../../common/src/hooks/enhancedHooks'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import { BaseFlexRow } from '../layout/FlexContainer'
import { embedProjectView } from '../../common/src/constants'
import { simulateLinkClick } from '../../common/src/utils'
import { useTranslation } from 'react-i18next'

const initialState = {
  loading: false,
  link: null,
  errorMessage: null,
}

const P = styled.p`
  color: ${p => p.theme.palette.neutralSecondary};
`

const Row = styled(BaseFlexRow)`
  justify-content: space-between;
  margin-top: 5px;
`

export const ProjectReadOnlyLinkModal = ({ projectID, projectName, hidden, ...rest }) => {
  const { onDismiss } = rest
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [state, setState] = useMergeState(initialState)
  const { loading, link, errorMessage } = state

  useEffect(() => {
    setState(initialState)
  }, [projectID, setState])

  const getReadOnlyProjectLink = useCallback(async () => {
    if (!projectID) {
      return
    }
    setState({ loading: true })
    const { payload, error } = await dispatch(getReadOnlyToken(projectID))
    const errorMessage = error ? payload.message : null
    const newLink = error ? null : payload.link
    setState({ loading: false, link: newLink, errorMessage })
  }, [dispatch, projectID, setState])

  useEffect(() => {
    if (hidden || link) {
      return
    }
    getReadOnlyProjectLink()
  }, [hidden, link, getReadOnlyProjectLink])

  const onClickEmbed = () => {
    const externalLink = embedProjectView({
      title: projectName,
      link: link,
    })
    simulateLinkClick(externalLink, true)
    onDismiss()
  }

  return (
    <CopyToClipboardModal
      title={t('project.share_in_readonly_title')}
      subText={t('project.share_in_readonly_subtitle')}
      loading={loading}
      link={link}
      errorMessage={errorMessage}
      hidden={hidden}
      {...rest}
    >
      {link && (
        <Row>
          <P>{t('project.share_in_readonly_embed')}</P>
          <PrimaryButton onClick={onClickEmbed}>Embed</PrimaryButton>
        </Row>
      )}
    </CopyToClipboardModal>
  )
}
