import { useCallback, useDeferredValue, useMemo } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import _ from 'lodash'
import { stateHelper, projectsFiltersHelper, projectHelper, itemHelper } from '../helpers'
import { PROJECTS_SHOW_ARCHIVED_OPTIONS, PROJECTS_SORT_TYPE, setDefaultFilterProjects } from '../actions/filtersActions'
import { useProjectFilter, useProjectSortDescriptor } from './filtersHooks'
import { OBJECT_ERROR_STATUS, ObjectError } from '../errors/errors'
import { fromJS } from 'immutable'
import { importProjectCSV, postProject } from '../actions/projectsAPI'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent as trackEvent } from '../eventTracking/amplitudeEvents'
import { useRecentItems } from '../../../hooks/itemHooks'
import { getProjectsShowArchivedFilter } from '../selectors/filtersSelectors'
import { FILTER_REDUCER_KEYS } from '../reducers/filtersKeys'

export const useCreateNewProject = () => {
  const dispatch = useDispatch()
  const projectFilter = useProjectFilter()

  return useCallback(
    async ({ clearingFilters = true, eventProps, ...projectProps }) => {
      const { name } = projectProps
      if (_.isEmpty(name)) {
        const errorObject = new ObjectError(OBJECT_ERROR_STATUS.PROJECT_EMPTY_NAME, `The name cannot be empty`)
        return { error: true, payload: errorObject }
      }

      const immutablePseudoProject = fromJS(projectProps)
      const response = await dispatch(postProject(immutablePseudoProject))
      const { error, payload } = response
      if (error) {
        return response
      }

      const project = fromJS(payload)
      // Clear filters
      if (clearingFilters && !projectFilter(project)) {
        dispatch(setDefaultFilterProjects())
      }

      // Create event
      dispatch(trackEvent(AMPLITUDE_ACTION_TYPES.CREATE_PROJECT, eventProps))

      return { payload: project }
    },
    [dispatch, projectFilter]
  )
}

export const useCreateNewProjectFromCSV = () => {
  const dispatch = useDispatch()
  const projectFilter = useProjectFilter()

  return useCallback(
    async ({ file, clearingFilters = true, eventProps }) => {
      if (!file) {
        const errorObject = new ObjectError(OBJECT_ERROR_STATUS.EMPTY_FILE, `The file cannot be null`)
        return { error: true, payload: errorObject }
      }

      const response = await dispatch(importProjectCSV(file))
      const { error, payload } = response
      if (error) {
        return response
      }

      const project = fromJS(payload)
      // Clear filters
      if (clearingFilters && !projectFilter(project)) {
        dispatch(setDefaultFilterProjects())
      }

      // Create event
      dispatch(trackEvent(AMPLITUDE_ACTION_TYPES.IMPORT_FROM_CSV, eventProps))

      return { payload: project }
    },
    [dispatch, projectFilter]
  )
}

export const useImportCSVToProject = () => {
  const dispatch = useDispatch()

  return useCallback(
    async ({ file, eventProps, projectID }) => {
      if (!file) {
        const errorObject = new ObjectError(OBJECT_ERROR_STATUS.EMPTY_FILE, `The file cannot be null`)
        return { error: true, payload: errorObject }
      }

      const response = await dispatch(importProjectCSV(file, projectID))
      const { error, payload } = response
      if (error) {
        return response
      }

      const project = fromJS(payload)

      // Create event
      dispatch(trackEvent(AMPLITUDE_ACTION_TYPES.IMPORT_FROM_CSV_TO_EXISTING_PROJECT, eventProps))

      return { payload: project }
    },
    [dispatch]
  )
}

export const useProjectsMap = (checkArchivedFilter = false, mode = FILTER_REDUCER_KEYS.PROJECTS) => {
  const showArchivedSync = useSelector(state => getProjectsShowArchivedFilter(state, mode))
  const showArchived = useDeferredValue(showArchivedSync)
  const projectsMap = useSelector(stateHelper.getAllProjects)
  return useMemo(() => {
    if (checkArchivedFilter && showArchived === PROJECTS_SHOW_ARCHIVED_OPTIONS.ONLY_ARCHIVED) {
      return projectsMap.filter(projectHelper.isArchived)
    } else if (checkArchivedFilter && showArchived === PROJECTS_SHOW_ARCHIVED_OPTIONS.ONLY_ACTIVE) {
      return projectsMap.filterNot(projectHelper.isArchived)
    }
    return projectsMap
  }, [checkArchivedFilter, projectsMap, showArchived])
}

export const useFilteredAndSortedProjectsArray = mode => {
  const projectsMap = useProjectsMap(true, mode)
  const projectFilterSync = useProjectFilter(mode)
  const projectFilter = useDeferredValue(projectFilterSync)
  const projectSortDescriptorSync = useProjectSortDescriptor(mode)
  const projectSortDescriptor = useDeferredValue(projectSortDescriptorSync)
  return useMemo(() => {
    const filteredProjects = projectsMap.filter(projectFilter)
    return filteredProjects.sort(projectSortDescriptor).toArray()
  }, [projectsMap, projectFilter, projectSortDescriptor])
}

export const useRecentlyModified = (max, favoritesFirst = false) => {
  const projectsSortPredicateByTimestamp = projectsFiltersHelper.PROJECTS_SORT_PREDICATES[PROJECTS_SORT_TYPE.TIMESTAMP]
  const projectsSortPredicateByStarred = projectsFiltersHelper.PROJECTS_SORT_PREDICATES[PROJECTS_SORT_TYPE.STARRED]
  const projectMap = useProjectsMap()
  const sorted = projectMap
    .toIndexedSeq()
    .sort((a, b) => (favoritesFirst && projectsSortPredicateByStarred(a, b)) || projectsSortPredicateByTimestamp(a, b))
  const limited = max === undefined ? sorted : sorted.take(max)
  return limited.toList()
}

export const useRecentlyVisitedProjects = max => {
  const store = useStore()
  const state = store.getState()
  const recentlyVisitedItems = useRecentItems()
  const recentlyVisitedProjectIdds = new Set()
  const recentlyVisitedProjects = []

  for (const item of recentlyVisitedItems) {
    const projectIdd = itemHelper.getProjectIdd(item)
    // Avoid including Inbox as recent project
    if (projectIdd > 0 && !recentlyVisitedProjectIdds.has(projectIdd)) {
      recentlyVisitedProjectIdds.add(projectIdd)
      const project = stateHelper.getProject(state, projectIdd)
      if (project) {
        recentlyVisitedProjects.push(project)
        if (recentlyVisitedProjects.length === max) {
          return recentlyVisitedProjects
        }
      }
    }
  }

  // If we don't have enough recently visited projects, we add the most recently modified ones
  return stateHelper
    .getAllProjects(state)
    .toIndexedSeq()
    .sortBy(p => p.get(projectHelper.KEYS.TIMESTAMP))
    .reverse()
    .take(max)
    .toArray()
}
