import _ from 'lodash'
import { css } from 'styled-components'

export const createMediaQueriesForSizes = sizes => {
  return _.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
      @media (max-width: ${sizes[label]}px) {
        ${css(...args)}
      }
    `
    return acc
  }, {})
}

export const withCyAttr = custom => p => {
  const customAsObj = _.isFunction(custom) ? custom(p) : custom ?? {}
  return {
    ...customAsObj,
    'data-automation-id': p.cy,
  }
}

export const DEFAULT_DEVICE_SIZES = {
  tinyDevices: 320,
  extraSmallDevices: 480,
  smallDevices: 768,
  mediumDevices: 992,
  largeDevices: 1200,
}

export const defaultMediaQueries = createMediaQueriesForSizes(DEFAULT_DEVICE_SIZES)
