import styled from 'styled-components'
import { forwardRef, useCallback } from 'react'
import { DatePicker } from '@fluentui/react/lib/DatePicker'
import { ActionButton } from '@fluentui/react/lib/Button'
import { Field } from '@fluentui/react-components'
import { DatePicker as DatePickerV9 } from '@fluentui/react-datepicker-compat'
import { useTranslation } from 'react-i18next'
import { useDatePickerStrings } from '../../hooks/dateHooks'
import { getWeekStartByLocale } from 'weekstart'
import { ICON_NAMES } from '../../common/src/msIcons'
import { formatDate } from '../../utils/datefns'
import { Button } from '@fluentui/react-components'
import { Dismiss } from '../BundledIcons'

const onFormatDate = date => (date ? formatDate('ShortenedAllDay')(date) : '')

const ClearDateButton = styled(ActionButton).attrs({
  iconProps: {
    iconName: ICON_NAMES.Cancel,
  },
})`
  height: auto;
  background: rgba(0, 0, 0, 0);
  position: relative;
  margin-right: 30px;
  color: ${p => p.theme.palette.black};
`

const ItemDatePickerBase = styled(DatePicker).attrs(p => ({
  showMonthPickerAsOverlay: true,
  isMonthPickerVisible: false,
  placeholder: p.placeholder ?? p.t('general.select_date'),
  firstDayOfWeek: getWeekStartByLocale(p.language),
  formatDate: onFormatDate,
  calendarProps: {
    styles: {
      goTodayButton: {
        ':disabled': {
          color: p.theme.palette.neutralTertiary,
        },
      },
    },
    calendarDayProps: {
      styles: {
        dayIsToday: {
          '& span': {
            color: `${p.theme.palette.white} !important`,
          },
        },
      },
    },
  },
}))``

export const LocalizedDatePicker = ({ showClearButton = true, ...props }) => {
  const { t, i18n } = useTranslation()
  const datePickerStringsGetter = useDatePickerStrings()
  const strings = props.strings ?? datePickerStringsGetter()
  const { onSelectDate } = props
  const onClearDate = useCallback(() => {
    onSelectDate(undefined)
  }, [onSelectDate])
  const value = props.value ?? null
  const disableClear = !(showClearButton && value)
  const today = new Date()
  return (
    <ItemDatePickerBase
      t={t}
      language={i18n.language}
      strings={strings}
      textField={{
        onRenderSuffix: () => (
          <ClearDateButton title={t('date.click_to_clear')} onClick={onClearDate} disabled={disableClear} />
        ),
        styles: {
          suffix: {
            padding: '0 0px',
            background: 'rgba(0, 0, 0, 0)',
            opacity: disableClear ? 0 : 1,
            display: props.disabled ? 'none' : 'flex',
          },
        },
      }}
      today={today}
      {...props}
    />
  )
}

export const LocalizedDatePickerV9 = forwardRef(({ showClearButton = true, label, ...props }, ref) => {
  const { t, i18n } = useTranslation()
  const { onSelectDate } = props
  const datePickerStringsGetter = useDatePickerStrings()
  const strings = props.strings ?? datePickerStringsGetter()
  const firstDayOfWeek = getWeekStartByLocale(i18n.language)
  const today = new Date()
  const value = props.value ?? null
  const onClearDate = useCallback(
    ev => {
      ev.stopPropagation()
      onSelectDate(undefined)
    },
    [onSelectDate]
  )
  const displayClear = showClearButton && value
  return (
    <Field label={label} ref={ref}>
      <DatePickerV9
        showGoToToday
        showMonthPickerAsOverlay
        isMonthPickerVisible={false}
        today={today}
        formatDate={onFormatDate}
        firstDayOfWeek={firstDayOfWeek}
        placeholder={props.placeholder ?? t('general.select_date')}
        contentAfter={
          displayClear && (
            <Button
              title={t('date.click_to_clear')}
              onClick={onClearDate}
              appearance="transparent"
              size="small"
              icon={<Dismiss />}
              className="!mr-[-2px]"
            />
          )
        }
        {...props}
        strings={strings}
        value={value}
        calendar={{ calendarDayProps: { className: '[&_span:not([class])]:text-inherit' } }}
      />
    </Field>
  )
})
