import React from 'react'
import styled from 'styled-components'
import { cn } from '@appfluence/classnames'
import { useDispatch } from 'react-redux'
import { Icon } from '@fluentui/react/lib/Icon'
import { itemHelper, projectHelper } from '../../common/src/helpers'
import { addStarredTag, removeStarredTag } from '../../common/src/actions/tagsAPI'
import { ICON_NAMES } from '../../common/src/msIcons'
import { FlexColumn } from '../layout/FlexContainer'

const Box = styled(FlexColumn)`
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.readOnly {
    cursor: inherit;
  }
`

const Star = styled(Icon).attrs({
  iconName: ICON_NAMES.FavoriteStarFill,
})`
  color: #ffea00;
  font-size: 80%;
`

const StarUnset = styled(Star).attrs({
  iconName: ICON_NAMES.FavoriteStar,
})`
  color: ${p => p.theme.palette.neutralLight};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${p => p.theme.palette.themePrimary};
    }
    &.readOnly:hover {
      color: ${p => p.theme.palette.neutralLight};
    }
  }
`

const StarredCell_ = React.memo(({ object, readOnly = false, className }) => {
  const dispatch = useDispatch()
  const isStarred = itemHelper.isItem(object) ? itemHelper.isStarred(object) : projectHelper.isStarred(object)
  const readOnlyClassName = cn({ readOnly })
  const boxClassName = cn(className, readOnlyClassName)
  const onClick = () => {
    if (readOnly) return
    const action = isStarred ? removeStarredTag : addStarredTag
    dispatch(action({ object }))
  }

  const Cell = isStarred ? Star : StarUnset
  return (
    <Box className={boxClassName}>
      <Cell id="starredCell_starCell" className={readOnlyClassName} onClick={onClick} />
    </Box>
  )
})

export const StarredCell = React.memo(StarredCell_)
