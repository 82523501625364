import { useMe } from '../common/src/hooks/usersHooks'
import { userHelper } from '../common/src/helpers'
import { SERVER_URLS } from '../common/src/constants'

export const withBusiness = ChildComponent => props => {
  const me = useMe()
  const premiumLevel = userHelper.getPremiumLevel(me)
  if (premiumLevel >= 2) {
    return <ChildComponent {...props} />
  }
  const url = new URL(SERVER_URLS.BUSINESS_REQUIRED)
  url.searchParams.append('paywall_next', window.location.href)
  return <iframe title="business required" className="h-full w-full" src={url}></iframe>
}
