import React from 'react'
import { ItemsFiltersPanel } from './ItemsFiltersPanel'
import { OneOnOneFiltersView } from './OneOnOneFiltersView'

export const OneOnOneFiltersPanel = ({ ...rest }) => {
  return (
    <ItemsFiltersPanel {...rest}>
      <OneOnOneFiltersView />
    </ItemsFiltersPanel>
  )
}
