import { JSONContent } from '@tiptap/react'

const paragraph2PM = (paragraph: JSONContent): string => paragraph.content?.[0]?.text ?? ''
const listItem2PM = (listItem: JSONContent, depth: number): string[] => {
  let lines: string[] = []
  if (!listItem.content) {
    return lines
  }
  for (const block of listItem.content) {
    if (block.type === 'paragraph') {
      const char = listItem.attrs?.checked === undefined ? '*' : listItem.attrs.checked ? '☑' : '☐'
      lines.push('\t'.repeat(depth) + char + ' ' + paragraph2PM(block))
    } else if (block.type === 'bulletList' || block.type === 'taskList') {
      lines = lines.concat(list2PM(block, depth + 1))
    }
  }
  return lines
}

const list2PM = (list: JSONContent, depth: number): string[] => {
  let lines: string[] = []
  if (!list.content) {
    return lines
  }
  for (const item of list.content) {
    lines = lines.concat(listItem2PM(item, depth))
  }
  return lines
}

export const TipTap2PM = (content: JSONContent[]): string => {
  let lines: string[] = []
  for (const block of content) {
    if (block.type === 'paragraph') {
      lines.push(paragraph2PM(block))
    } else if (block.type === 'bulletList' || block.type === 'taskList') {
      lines = lines.concat(list2PM(block, 1))
    }
  }
  return lines.join('\n')
}
