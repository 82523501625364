import { APP_NAME } from '../constants/constants'
import { PMLogo } from './Logo'
import { useTranslation } from 'react-i18next'
import { forwardRef } from 'react'
import { cn } from '@appfluence/classnames'

const BANNER_LINK = 'https://appfluence.com'

export type PrintableComponentProps = {
  children: React.ReactNode
  pageName: string
  className?: string
}

export const PrintableComponent = forwardRef<HTMLDivElement, PrintableComponentProps>(
  ({ children, pageName, className }, ref) => {
    const { t } = useTranslation()
    return (
      <div className={cn('flex h-full w-full flex-col font-system', className)} ref={ref}>
        <div className="m-0.5 hidden w-full items-center justify-center print:flex">
          <a className="m-[2mm] text-pm-always-primary no-underline" href={BANNER_LINK}>
            {t('promotions.print_banner')}
          </a>
        </div>
        <header className="mb-[5mm] hidden items-center justify-between print:flex">
          <h2>
            {APP_NAME} - {pageName}
          </h2>
          <PMLogo className="h-12 w-12" />
        </header>
        <div className="flex h-full w-full flex-col">{children}</div>
      </div>
    )
  }
)
