import { useLayoutEffect, useMemo, forwardRef } from 'react'
import { useMergeState } from '../../common/src/hooks/enhancedHooks'
import { TimePicker, TimePickerProps, formatDateToTimeString } from '@fluentui/react-timepicker-compat'
import { Field } from '@fluentui/react-components'
import { localeUses24HourTime } from '../../utils/time'

interface IProps {
  label?: string
  className?: string
  value: Date
  onChange?: (date: Date) => void
  disabled?: boolean
}

interface IState {
  value: string
  selectedTime: Date
}

const onDateUpdated = (date: Date, hourCycle: HourCycle): IState => {
  if (date) {
    return {
      value: formatDateToTimeString(date, { hourCycle }),
      selectedTime: date,
    }
  }
  return {
    value: '',
    selectedTime: date,
  }
}

const useDateState = (date: Date, hourCycle: HourCycle) => {
  const stateArray = useMergeState<IState>(() => onDateUpdated(date, hourCycle))
  const [, setState] = stateArray
  useLayoutEffect(() => {
    setState(onDateUpdated(date, hourCycle))
  }, [date, setState, hourCycle])
  return stateArray
}

type HourCycle = TimePickerProps['hourCycle']

const useHourCycle = (): HourCycle => useMemo(() => (localeUses24HourTime() ? 'h23' : 'h12'), [])

export const TimePickerCombo = forwardRef<HTMLDivElement, IProps>(
  ({ label, value: date, onChange, className, disabled = false }, ref) => {
    const hourCycle = useHourCycle()
    const [state, setState] = useDateState(date, hourCycle)
    const { value, selectedTime } = state
    const onTimeChange: TimePickerProps['onTimeChange'] = (_ev, { selectedTime }) => {
      if (selectedTime === null) {
        return
      }
      onChange?.(selectedTime)
      setState(onDateUpdated(date, hourCycle))
    }
    const onInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
      setState({ value: ev.target.value })
    }

    return (
      <Field label={label} ref={ref}>
        <TimePicker
          freeform
          hourCycle={hourCycle}
          increment={15}
          onTimeChange={onTimeChange}
          onInput={onInput}
          selectedTime={selectedTime}
          value={value}
          className={className}
          disabled={disabled}
        />
      </Field>
    )
  }
)
