import React, { useEffect, useState } from 'react'
import { AutoFocusTextField } from '../textFields/AutoFocusTextField'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import { SimpleModal } from './SimpleModal'
import { KEY_CODE } from '../../common/src/helpers/keyboardHelper'
import { useTranslation } from 'react-i18next'

export const LinkModal = ({ link, onChange, onDismiss, ...rest }) => {
  const [text, setText] = useState(1)
  const { t } = useTranslation()

  // Set link as initial value
  useEffect(() => {
    setText(link)
  }, [link])

  const onChangeText = (ev, newText) => {
    setText(newText)
  }

  const onDismissed = () => {
    // Set link again as initial value
    setText(link)
  }

  const onDone = () => {
    onChange(text)
    onDismiss()
  }

  const onKeyPress = event => {
    if (event.charCode === KEY_CODE.ENTER) {
      event.preventDefault()
      event.stopPropagation()
      onDone()
    }
  }

  const button = (
    <PrimaryButton key={'done'} onClick={onDone}>
      {t('general.done')}
    </PrimaryButton>
  )
  return (
    <SimpleModal
      title={t('links_section.change_link')}
      buttons={button}
      onDismiss={onDismiss}
      onDismissed={onDismissed}
      {...rest}
    >
      <AutoFocusTextField onChange={onChangeText} value={text} onKeyPress={onKeyPress} />
    </SimpleModal>
  )
}
