import React from 'react'
import _ from 'lodash'
import { MainLayout } from '../../components/layout/MainLayout'
import { RightDetailView } from '../rightDetailView/RightDetailView'
import { useRouteId } from '../../hooks/useRouteId'
import { ROUTE_ID } from '../../routes/routeIdList'
import { BASE_URL } from '../../common/src/constants'
import { captureLinkClick } from '../../utils/externalLinkHandler'
import { linkSubject, SOURCES } from '../../reactions/linkSubject'
import { isInternetExplorer } from '../../common/src/helpers/platformHelper'
import { IENotSupportedView } from '../IENotSupportedView'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { ReportsMobilePlaceholder } from './ReportsMobilePlaceholder'

export const PMGlobalReportsView = ({ match }) => {
  const narrowWidth = useNarrowWidth()
  const routeId = useRouteId()
  const displayOptionsMenu = routeId !== ROUTE_ID.CALENDAR_TEAMS
  const components = {
    main: isInternetExplorer() ? <IENotSupportedView /> : narrowWidth ? <ReportsMobilePlaceholder /> : <Reports />,
    rightSide: <RightDetailView displayOptionsMenu={displayOptionsMenu} />,
  }
  return <MainLayout {...components} />
}

const url = `${BASE_URL}reports/report_list/`
const baseReports = `${BASE_URL}reports/`

const observeDeeperIframe = contentWindow => {
  try {
    const targetNode = contentWindow.document.getElementsByTagName('body')[0]
    const config = { childList: true, subtree: true }
    const callback = function (mutationsList, observer) {
      _.each(mutationsList, mutation => {
        if (mutation.type === 'childList') {
          _.each(mutation.addedNodes, node => {
            const iframe = node.querySelectorAll?.('iframe')?.[0]
            if (iframe) {
              const nestedWindow = iframe.contentWindow
              nestedWindow.onload = () => {
                addNavigationListener(nestedWindow)
              }
            }
          })
        }
      })
    }
    const observer = new MutationObserver(callback)
    observer.observe(targetNode, config)
  } catch (err) {
    console.log(err)
  }
}

const addNavigationListener = contentWindow => {
  const customHandleExternalLink = href => {
    try {
      return href.startsWith(baseReports)
    } catch (err) {
      return false
    }
  }
  const listener = captureLinkClick(
    urlData => {
      linkSubject.next({ urlData, source: SOURCES.REPORTS })
    },
    customHandleExternalLink,
    false
  )
  contentWindow.addEventListener('click', listener, true)
}

const Reports = () => {
  const ref = React.useRef()
  const onLoad = React.useCallback(() => {
    const contentWindow = ref.current?.contentWindow
    observeDeeperIframe(contentWindow)
    addNavigationListener(contentWindow)
  }, [])
  return (
    <div className="h-full w-full overflow-y-hidden">
      <iframe className="h-full w-full border-none" src={url} ref={ref} onLoad={onLoad} title="Reports" />
    </div>
  )
}
