import { REQUEST_REDUCER_KEYS } from '../reducers/requestReducerKeys'
let helper = {}

helper.isInitializedRequest = status => {
  if (!status) {
    return false
  }
  return status.get(REQUEST_REDUCER_KEYS.INITIALIZED)
}

helper.isPendingRequest = status => {
  if (!status) {
    return false
  }
  return status.get(REQUEST_REDUCER_KEYS.PENDING_COUNT) > 0
}

helper.isErrorRequest = status => {
  if (!status) {
    return false
  }
  return status.get(REQUEST_REDUCER_KEYS.ERROR)
}

helper.isNeededRequest = status => {
  if (!status) {
    return true
  }
  if (helper.isPendingRequest(status)) {
    return false
  }
  return !helper.isInitializedRequest(status)
}

export default helper
