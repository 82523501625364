import { Suspense, useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { useSelector } from 'react-redux'
import { Text } from '../../components/text/Text'
import { motion } from 'framer-motion'
import { ProjectCell } from '../../components/projectCell/ProjectCell'
import { projectHelper } from '../../common/src/helpers'
import { PROJECTS_SORT_TYPE } from '../../common/src/actions/filtersActions'
import { useMobile } from '../../helpers/responsiveHelpers'
import { RecentProjectsSection } from '../../components/project/RecentProjectsSection'
import { getProjectsSortType, isFilteringProjects } from '../../common/src/selectors/filtersSelectors'
import { ShimmerVirtualizedList } from '../../components/list/ShimmerVirtualizedList'
import { GhostProjectCell } from '../../components/projectCell/GhostProjectCell'
import { isInitializedRequestForPMResource } from '../../common/src/selectors/requestStateSelectors'
import { PM_API_RESOURCES } from '../../common/src/constants'
import { Loading } from '../../components/basic/Loading'
import { useRandomValueFromArray } from '../../hooks/arrayHooks'
import { useTranslation } from 'react-i18next'
import { IVIEW_PLACEHOLDER_TYPES } from '../../components/placeholder/IViewPlaceholderTypes'
import { LazyIViewPlaceholder } from '../../components/placeholder/LazyIViewPlaceholder'
import { useSelectedProjectId } from '../../hooks/PMHooks'
import { useOnProjectDragEnd } from '../../hooks/projectHooks'
import { usePositioningMouseTarget } from '@fluentui/react-positioning'
import { ProjectOptionsMenu } from '../project/ProjectOptionsButton.jsx'
import { useNarrowWidth } from '@/hooks/useNarrowWidth'

const recentSectionVariants = {
  hidden: {
    margin: 0,
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.1,
    },
  },
  visible: {
    margin: '16px 0',
    opacity: 1,
    height: 'auto',
    transition: {
      duration: 0.1,
    },
  },
}

const RecentSection = props => <motion.div variants={recentSectionVariants} initial="hidden" {...props} />

export const ListMode = ({ onSelectProject, navigateToProject, projects, counts, fromProjectId }) => {
  const isFiltering = useSelector(isFilteringProjects)
  const loading = useSelector(
    state => !isInitializedRequestForPMResource(state, PM_API_RESOURCES.ALL_PROJECT_SUMMARIES)
  )
  const selectedProjectID = useSelectedProjectId()
  const sortType = useSelector(getProjectsSortType)
  const isDropDisabled = loading || isFiltering || sortType !== PROJECTS_SORT_TYPE.INDEX
  const isMobile = useMobile()
  const [currentProjects, setCurrentProjects] = useState(projects)
  const { t } = useTranslation()

  // Set current projects synchronously after
  // all DOM mutations, before the browser has
  // a chance to paint to avoid a flicker.
  useLayoutEffect(() => {
    setCurrentProjects(projects)
  }, [projects, setCurrentProjects])

  const openProject = useCallback(
    project => {
      navigateToProject(project)
    },
    [navigateToProject]
  )

  const handleProjectSelection = useCallback(
    project => {
      if (isMobile) {
        openProject(project)
      } else {
        onSelectProject(project)
      }
    },
    [isMobile, openProject, onSelectProject]
  )

  const handleClickForProject = useCallback(
    project => () => {
      handleProjectSelection(project)
    },
    [handleProjectSelection]
  )

  const handleDoubleClickForProject = useCallback(
    project =>
      isMobile
        ? undefined
        : evt => {
            if (evt) {
              evt.stopPropagation()
            }
            openProject(project)
          },
    [isMobile, openProject]
  )

  const renderClone = useCallback(
    (provided, snapshot, rubric) => (
      <ProjectCell
        project={currentProjects[rubric.source.index]}
        isDragging={snapshot.isDragging}
        provided={provided}
        readOnly={true}
      />
    ),
    [currentProjects]
  )

  const [contextualMenuOpen, setContextualMenuOpen] = useState(false)
  const [contextualMenuTarget, setContextualMenuTarget] = usePositioningMouseTarget()
  const [contextualMenuProject, setContextualMenuProject] = useState(null)

  const onContextMenuOpenChange = useCallback((ev, { open }) => {
    setContextualMenuOpen(open)
  }, [])

  const rowRenderer = useCallback(
    index => {
      const project = currentProjects[index]
      const serverID = projectHelper.getIdd(project)
      if (!serverID) {
        return
      }
      const draggableID = serverID.toString()
      const selected = isMobile ? false : serverID === selectedProjectID
      const onClick = handleClickForProject(project)
      const onDoubleClick = handleDoubleClickForProject(project)
      return (
        <Draggable draggableId={draggableID} index={index} isDragDisabled={isDropDisabled}>
          {(provided, snapshot) => (
            <ProjectCell
              index={index}
              project={project}
              selected={selected}
              onClick={onClick}
              onDoubleClick={onDoubleClick}
              onClickInSubcomponents={onDoubleClick}
              isDragging={snapshot.isDragging}
              provided={provided}
              onContextMenu={ev => {
                ev.preventDefault()
                setContextualMenuOpen(true)
                setContextualMenuTarget(ev)
                setContextualMenuProject(project)
              }}
              counts={counts?.[serverID]}
              scrollIntoView={serverID === fromProjectId}
              className={serverID === fromProjectId ? 'border border-solid border-neutral-500' : ''}
            />
          )}
        </Draggable>
      )
    },
    [
      currentProjects,
      isMobile,
      selectedProjectID,
      handleClickForProject,
      handleDoubleClickForProject,
      isDropDisabled,
      counts,
      fromProjectId,
      setContextualMenuTarget,
    ]
  )

  const onDragEnd = useOnProjectDragEnd({ currentProjects, setCurrentProjects })

  const placeholders = useMemo(() => {
    return [
      {
        title: t('project.placeholder_0.title'),
        message: t('project.placeholder_0.message'),
      },
      {
        title: t('project.placeholder_1.title'),
        message: t('project.placeholder_1.message'),
      },
      {
        title: t('project.placeholder_2.title'),
        message: t('project.placeholder_2.message'),
      },
    ]
  }, [t])

  const filterPlaceholder = useMemo(() => {
    return {
      title: t('project.filtering_placeholder.title'),
      message: t('project.filtering_placeholder.message'),
      type: IVIEW_PLACEHOLDER_TYPES.NOT_FOUND,
    }
  }, [t])

  const narrowWidth = useNarrowWidth()
  const placeholder = useRandomValueFromArray(placeholders)
  const noRowsRenderer = useCallback(() => {
    const finalPlaceholder = isFiltering ? filterPlaceholder : placeholder
    return (
      <Suspense fallback={<Loading />}>
        <LazyIViewPlaceholder {...finalPlaceholder} />
      </Suspense>
    )
  }, [filterPlaceholder, isFiltering, placeholder])

  const showRecentSection = !narrowWidth && !isFiltering && !loading && sortType !== PROJECTS_SORT_TYPE.TIMESTAMP
  return (
    <div className="box-border flex h-full w-full flex-1 flex-col bg-pm-white px-5 py-3">
      <RecentSection animate={showRecentSection ? 'visible' : 'hidden'}>
        <Text variant="large" nowrap block className="mb-4 font-semibold text-pm-black">
          {t('project_top_bar.recently_modified')}
        </Text>
        <div className="flex max-h-28 flex-wrap gap-2 overflow-hidden">
          <RecentProjectsSection />
        </div>
      </RecentSection>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="projectsView_droppable" renderClone={renderClone} isDropDisabled={isDropDisabled}>
          {droppableProvided => (
            <ShimmerVirtualizedList
              rows={currentProjects}
              rowRenderer={rowRenderer}
              noRowsRenderer={noRowsRenderer}
              innerListRef={droppableProvided.innerRef}
              loading={loading}
              shimmerComponent={GhostProjectCell}
              computeItemKey={(idx, project) => projectHelper.getIdd(project) ?? `idx-${idx}`}
            />
          )}
        </Droppable>
      </DragDropContext>
      <ProjectOptionsMenu
        open={contextualMenuOpen}
        onOpenChange={onContextMenuOpenChange}
        positioning={{ target: contextualMenuTarget }}
        project={contextualMenuProject}
      />
    </div>
  )
}
