import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useMediaQuery } from 'react-responsive'
import { useDispatch, useSelector } from 'react-redux'
import { fromJS } from 'immutable'
import * as queryParamsHelper from '../../helpers/queryParamsHelper'
import { Button } from '@fluentui/react-components'
import { itemHelper } from '../../common/src/helpers'
import { getMatchParameter, simulateLinkClick } from '../../common/src/utils'
import { ItemDetailView } from '../ItemDetail/ItemDetail'
import { useFetch } from '../../common/src/hooks/networkingHooks'
import { getRelatedItems } from '../../common/src/actions/itemsAPI'
import { Loading } from '../../components/basic/Loading'
import { SearchAPITextField } from '../../components/textFields/SearchAPITextField'
import {
  getProtocolLinkForItemWithId,
  PM_API_RESOURCE_TYPE,
  PM_API_RESOURCES,
  SERVER_URLS,
} from '../../common/src/constants'
import { redirectSubject } from '../../reactions/'
import { useSelectedItem } from '../../hooks/PMHooks'
import { getRelativeURL, isInApp } from '../../helpers/routeHelper'
import { InvalidObjectComponent, useIsObjectInvalidToBeShown } from '../errorViews/InvalidObjectComponent'
import { getSearchedItems } from '../../common/src/selectors/searchSelectors'
import { ItemCell } from '../../components/itemCell/ItemCell'
import { ShimmerVirtualizedList } from '../../components/list/ShimmerVirtualizedList'
import { GhostItemCell } from '../../components/itemCell/GhostItemCell'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { useRouter } from '../../hooks/useRouterHook'
import { SingleLayout } from '../../components/layout/SingleLayout'
import { useTranslation } from 'react-i18next'
import { openPurchaseExperienceAction } from '../../utils/teams'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { getItemIfNeeded } from '../../common/src/actions/combinedAPI'
import {
  isErrorRequestForPMResource,
  isPendingRequestForPMResource,
} from '@/common/src/selectors/requestStateSelectors'
import { useShouldDisplayUpgrade } from '@/hooks/userHooks'
import { useItemParams } from '@/hooks/itemHooks'

const BANNER = 'BANNER'

export const SingleItemView = ({ match, history, ...rest }) => {
  const dispatch = useDispatch()
  const id = _.parseInt(getMatchParameter(match, 'id'))
  const item = useSelectedItem()
  const params = useItemParams(rest.readOnly)
  const fetchItem = useCallback(() => dispatch(getItemIfNeeded(id, params)), [dispatch, id, params])
  useFetch(id ? fetchItem : null)
  const loading = useSelector(state => isPendingRequestForPMResource(state, PM_API_RESOURCES.ITEM, id))
  const error = useSelector(state => isErrorRequestForPMResource(state, PM_API_RESOURCES.ITEM, id))
  const { pathname } = history.location
  const inApp = isInApp(pathname)

  useEffect(() => {
    if (id) {
      if (!inApp) {
        const pmURL = getProtocolLinkForItemWithId(id)
        redirectSubject.next(pmURL)
      }
    }
  }, [id, inApp])

  return <SingleItemPrivate item={item} loading={loading} error={error} history={history} {...rest} />
}

const LeftPanel = ({ children }) => (
  <div className="box-border h-full w-1/5 min-w-[300px] border-r border-solid border-pm-neutral-light">{children}</div>
)

const Main = ({ children }) => <main className="min-w-[300px] flex-1 overflow-auto">{children}</main>

const parser = payload => {
  const l = _.get(payload, ['objects'])
  return _.map(l, i => fromJS(i))
}

const SingleItemPrivate = ({ item, loading = false, error, ...rest }) => {
  const dispatch = useDispatch()
  const { readOnly } = rest
  const [text, setText] = useState('')
  const [searching, setSearching] = useState(false)
  const isObjectInvalidToBeShown = useIsObjectInvalidToBeShown(PM_API_RESOURCE_TYPE.ITEM)
  const [appendBanner, setAppendBanner] = useState(false)
  const { t } = useTranslation()
  const hide_chrome = queryParamsHelper.hideChrome()
  const wide = useMediaQuery({ query: '(min-width: 600px)' })
  const showRelatedItems = wide && !readOnly

  const itemID = itemHelper.getId(item)
  const fetchRelatedItems = useCallback(() => dispatch(getRelatedItems(itemID)), [dispatch, itemID])
  const { loading: loadingRelatedItems, data: relatedItems = [] } = useFetch(
    itemID && showRelatedItems ? fetchRelatedItems : null,
    parser
  )
  const searchedItemsFromState = useSelector(getSearchedItems)
  const searchedItems = useMemo(() => {
    const list = searchedItemsFromState.toArray()
    if (appendBanner) {
      list.push(BANNER)
    }
    return list
  }, [searchedItemsFromState, appendBanner])
  const onChangeShouldDisplayBanner = value => setAppendBanner(value)

  const relatedSection = useMemo(() => {
    if (!showRelatedItems) {
      return false
    }
    let itemList
    if (text) {
      itemList = <ItemList items={searchedItems} loading={searching} placeholder={t('item_detail.no_matching_items')} />
    } else {
      itemList = (
        <ItemList items={relatedItems} loading={loadingRelatedItems} placeholder={t('item_detail.no_related_items')} />
      )
    }

    return (
      <div className="box-border flex h-full flex-col px-3.5 pb-0 pt-3.5 font-light">
        <SearchAPITextField
          className="mb-2"
          onChange={setText}
          onLoading={setSearching}
          onChangeShouldDisplayBanner={onChangeShouldDisplayBanner}
        />
        {itemList}
      </div>
    )
  }, [showRelatedItems, text, searchedItems, searching, t, relatedItems, loadingRelatedItems])

  const itemDetail = useMemo(() => {
    if (loading) {
      return <Loading />
    }

    if (isObjectInvalidToBeShown(item, error)) {
      return <InvalidObjectComponent type={PM_API_RESOURCE_TYPE.ITEM} object={item} error={error} />
    }

    return <ItemDetailView key="singleItem" readOnly={readOnly} />
  }, [loading, isObjectInvalidToBeShown, item, error, readOnly])

  const content = useMemo(() => {
    if (!showRelatedItems) {
      return <Main>{itemDetail}</Main>
    }

    return (
      <>
        {!hide_chrome && (
          <LeftPanel>
            <div className="h-full w-full">{relatedSection}</div>
          </LeftPanel>
        )}

        <Main>{itemDetail}</Main>
      </>
    )
  }, [showRelatedItems, hide_chrome, relatedSection, itemDetail])

  const main = <div className="flex h-full">{content}</div>

  return <SingleLayout object={item} main={main} hide_chrome={hide_chrome} {...rest} />
}

const redirectToItemUsingHistory = (itemID, history) => {
  const { pathname } = history.location
  const getNextPathname = isInApp(pathname) ? getRelativeURL.itemViewInAppForID : getRelativeURL.singleItemViewForID
  if (itemID && history) {
    const to = {
      pathname: getNextPathname(itemID),
      search: queryParamsHelper.getSearchParamsWithNoRedirect(),
    }
    history.push(to)
  }
}

const Banner = React.memo(({ style }) => {
  const finalStyles = {
    ...style,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    fontWeight: 'bolder',
  }
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const shouldDisplayUpgrade = useShouldDisplayUpgrade()
  const onClickUpgrade = () => {
    dispatch(openPurchaseExperienceAction(EVENT_EXTRA.TRIAL_UPGRADE_PRESSED.MODE.SEARCH_LOOKBACK))
  }
  const onClickAccount = () => {
    simulateLinkClick(SERVER_URLS.MANAGE_ACCOUNT, true)
  }
  const upgradeButton = (
    <Button appearance="primary" onClick={onClickUpgrade}>
      {t('general.upgrade')}
    </Button>
  )
  const accountButton = (
    <Button appearance="primary" onClick={onClickAccount}>
      {t('general.account')}
    </Button>
  )
  return (
    <div style={finalStyles}>
      <p style={{ flex: 1 }}>{t('paywall_strings.more_items')}</p>
      {shouldDisplayUpgrade ? upgradeButton : accountButton}
    </div>
  )
})

const ItemList = memo(({ items, loading, placeholder }) => {
  const { history } = useRouter()
  const { narrow } = useNarrowWidth()
  const onSelectItem = useCallback(
    item => {
      const itemID = itemHelper.getId(item)
      redirectToItemUsingHistory(itemID, history)
    },
    [history]
  )
  const rowRenderer = useCallback(
    index => {
      const item = items[index]
      if (item === BANNER) {
        return <Banner />
      }
      return <ItemCell item={item} idx={index} onClick={onSelectItem} narrow={narrow} />
    },
    [items, onSelectItem, narrow]
  )

  return (
    <ShimmerVirtualizedList
      rows={items}
      rowRenderer={rowRenderer}
      placeholder={placeholder}
      loading={loading}
      shimmerComponent={GhostItemCell}
      computeItemKey={(idx, item) => (item === BANNER ? BANNER : itemHelper.getId(item) ?? `idx-${idx}`)}
    />
  )
})
