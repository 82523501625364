import { useCallback, useMemo } from 'react'
import { useSelector, useStore } from 'react-redux'
import { List, Map } from 'immutable'
import { projectHelper, stateHelper, userHelper, projectMembershipHelper } from '../helpers'
import { userFrom } from '../helpers/transformerHelpers'
import { usersMapExcludingUsers } from '../helpers/usersUtils'
import { createSelector } from 'reselect'

const emptyList = List()

export const useMe = () => {
  return useSelector(stateHelper.getMe)
}

export const useMeUserHash = () => {
  const me = useMe()
  return userHelper.getUserHash(me)
}

export const useMeEmail = () => {
  return useSelector(stateHelper.getMeEmail)
}

export const useCollaboratorsMap = () => {
  return useSelector(stateHelper.getCollaboratorsMap)
}

export const useCollaboratorsMapMinusMe = () => {
  const map = useCollaboratorsMap()
  const me = useMe()
  return useMemo(() => {
    return usersMapExcludingUsers(map, me)
  }, [map, me])
}

export const useCollaboratorsMinusMe = () => {
  const map = useCollaboratorsMapMinusMe()
  return useMemo(() => map.toList(), [map])
}

export const useTeamMap = () => {
  const collaboratorsMap = useCollaboratorsMap()
  return useMemo(() => collaboratorsMap.filter(userHelper.getTeammate), [collaboratorsMap])
}

export const useNoTeamMap = () => {
  const collaboratorsMap = useCollaboratorsMap()
  return useMemo(() => collaboratorsMap.filterNot(userHelper.getTeammate), [collaboratorsMap])
}

export const useUserForEmail = email => {
  const user = useSelector(state => stateHelper.getUserWithEmail(state, email))
  return useMemo(() => {
    return user ? user : userFrom(email)
  }, [user, email])
}

const getUsersForEmails = createSelector(
  stateHelper.getCollaboratorsMap,
  (state, emails) => emails,
  (collaboratorsMap, emails) => {
    return emails.map(email => collaboratorsMap.get(email) ?? userFrom(email))
  }
)

export const useUsersForEmails = emails => useSelector(state => getUsersForEmails(state, emails))

export const useGetUserByEmail = () => {
  const store = useStore()
  return useCallback(
    email => {
      if (!email) {
        return null
      }
      const user = stateHelper.getUserWithEmail(store.getState(), email)
      return user ? user : userFrom(email)
    },
    [store]
  )
}

// Graph
export const useGraphCollaboratorsMap = () => {
  return useSelector(stateHelper.getGraphCollaboratorsMap)
}

export const useGraphCollaboratorsMapMinusMe = () => {
  const graphMap = useGraphCollaboratorsMap()
  const me = useMe()
  return useMemo(() => {
    return usersMapExcludingUsers(graphMap, me)
  }, [graphMap, me])
}

export const useGraphCollaboratorsMinusMe = () => {
  const map = useGraphCollaboratorsMapMinusMe()
  return useMemo(() => map.toList(), [map])
}

// Teams
export const useTeamsCollaboratorsMap = () => {
  return useSelector(stateHelper.getTeamsCollaboratorsMap)
}

export const useTeamsCollaboratorsMapMinusMe = () => {
  const teamsMap = useTeamsCollaboratorsMap()
  const me = useMe()
  return useMemo(() => {
    return usersMapExcludingUsers(teamsMap, me)
  }, [teamsMap, me])
}

export const useTeamsCollaboratorsMinusMe = () => {
  const map = useTeamsCollaboratorsMapMinusMe()
  return useMemo(() => map.toList(), [map])
}

// Project

export const useOwnersForProject = project => {
  const collaborators = useCollaboratorsMap()
  const ownerIDs = project ? projectHelper.getOwnersIDs(project) : emptyList
  const memberships = projectHelper.getMemberships(project)
  return useMemo(() => {
    if (memberships) {
      const current = memberships.filterNot(projectMembershipHelper.isDeleted)
      return current.map(m => {
        const mail = projectMembershipHelper.getUsername(m)
        const collab = collaborators.get(mail)
        return collab ?? projectMembershipHelper.toPseudoUser(m)
      })
    }
    return collaborators.filter(user => ownerIDs.some(uri => uri === userHelper.getID(user))).toList()
  }, [collaborators, ownerIDs, memberships])
}

export const useSharingRequestsForProject = project => {
  const sharingRequestsMap = useSelector(state => stateHelper.getSharingRequestsMapForProject(state, project))
  return useMemo(() => (sharingRequestsMap ? sharingRequestsMap.toList() : emptyList), [sharingRequestsMap])
}

export const useOwnersAndRequestsForProject = project => {
  const owners = useOwnersForProject(project)
  const requests = useSharingRequestsForProject(project)

  return useMemo(() => {
    return owners.concat(requests)
  }, [owners, requests])
}

export const useProjectMembersForProject = ({ project, sortDescriptor, populateIsAdmin = false, asArray = false }) => {
  const ownersAndRequests = useOwnersAndRequestsForProject(project)
  return useMemo(() => {
    const members = ownersAndRequests.map(item => {
      const isAdmin = populateIsAdmin && userHelper.isUser(item) && projectHelper.isAdminUser(project, item)
      return userFrom(item, isAdmin)
    })
    const sortedMembers = sortDescriptor ? members.sortBy(sortDescriptor) : members
    return asArray ? sortedMembers.toArray() : sortedMembers
  }, [ownersAndRequests, sortDescriptor, asArray, populateIsAdmin, project])
}

export const useProjectMembersForProjectMap = ({ project, sortDescriptor, populateIsAdmin = false }) => {
  const projectMembers = useProjectMembersForProject({ project, sortDescriptor, populateIsAdmin })
  return useMemo(() => {
    return Map(projectMembers.map(user => [userHelper.getEmail(user), user]))
  }, [projectMembers])
}
