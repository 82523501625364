import _ from 'lodash'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'
import { RSAA } from 'redux-api-middleware'
import { PM_API_RESOURCES, PM_API_URLS } from '../constants'
import { appendQueryParamsToURL, extendPMHeaders, getCredentialsConfig } from '../helpers/requestHelper'

const ACTION_TYPE_BASE = 'TRACKING'
export const EVENTS = {
  SOURCE: 'SOURCE',
}
export const ACTION_TYPES = _.mapValues(EVENTS, (v, k) => `${ACTION_TYPE_BASE}/${k}`)
export const TRACKING_ACTION_TYPES = ACTION_TYPES

export const trackSource = () => {
  return {
    type: ACTION_TYPES.SOURCE,
    payload: { source: document.referrer },
  }
}

export const track = params => {
  return {
    [RSAA]: {
      endpoint: appendQueryParamsToURL(PM_API_URLS.TRACK, params),
      credentials: getCredentialsConfig(),
      method: 'GET',
      types: generateActionTypesForPMAPI(PM_API_RESOURCES.TRACKING),
      headers: extendPMHeaders(),
    },
  }
}
